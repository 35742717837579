import {
  PIPELINE_DATA_ATTR_KEYS as PIPELINE_KEYS,
  PIPELINE_DATA_ATTR_VALUES as PIPELINE_VALUES,
} from 'modules/browse-menu-tree-gql/constants'
import React, { useEffect, useState } from 'react'
import { AddNewNodeProps } from './types'
import CreateNodeButton from './CreateNodeButton'
import { NODE_TYPE } from 'modules/browse-menu-tree-gql/types'
import NodeInEditState from './NodeInEditState'
import { StyledAddNewNode } from './styles'
import { StyledSpacer } from '../Node/styles'
import noop from 'lodash/noop'
import setNodeFocus from 'modules/browse-menu-tree-gql/utils/setNodeFocus'

const getIsSectionNode = (nodeType: NODE_TYPE) => nodeType === NODE_TYPE.SECTION

const AddNewNode = ({
  depth,
  id,
  isLevelEmpty,
  nodeType = NODE_TYPE.NODE,
  onEditStateTransition = noop,
  onCancel = noop,
  onSave,
  parentNodeId,
  refreshPipelines = noop,
}: AddNewNodeProps): JSX.Element => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [pipelineProps, setPipelineProps] = useState<{
    [PIPELINE_KEYS.pipelineId]: string
    [PIPELINE_KEYS.parentNodeId]: string
  }>()

  useEffect(() => {
    setIsEditMode(isLevelEmpty)
    setPipelineProps(
      parentNodeId && nodeType !== NODE_TYPE.SUBNODE
        ? {
            [PIPELINE_KEYS.pipelineId]: PIPELINE_VALUES.getPipelineId({
              showPipeline: true,
              depth,
            }),
            [PIPELINE_KEYS.parentNodeId]: parentNodeId,
          }
        : null
    )
  }, [parentNodeId, isLevelEmpty, depth, nodeType])

  useEffect(() => {
    getIsSectionNode(nodeType) && setIsEditMode(true)
    refreshPipelines()
  }, [nodeType, refreshPipelines])

  useEffect(() => {
    isEditMode && setNodeFocus(`#${id}`)
    refreshPipelines()
  }, [isEditMode, refreshPipelines, id])

  useEffect(() => {
    onEditStateTransition(isEditMode)
  }, [isEditMode, onEditStateTransition])

  const isSection = getIsSectionNode(nodeType)

  return (
    <StyledAddNewNode
      className='add-new-node-container'
      data-testid={`add-new-node-container-${id}`}
    >
      {isEditMode ? (
        <>
          {isSection && !isLevelEmpty && <StyledSpacer />}
          <NodeInEditState
            id={id}
            nodeType={nodeType}
            onCancel={() => {
              setIsEditMode(false)
              onCancel()
            }}
            onSave={async (name: string) => {
              setIsEditMode(false)
              await onSave({
                depth,
                name,
                nodeType,
                nodeParentId: parentNodeId,
              })
            }}
            pipelineProps={pipelineProps || {}}
          />
        </>
      ) : (
        <CreateNodeButton
          nodeType={nodeType}
          depth={depth}
          onClick={() => {
            setIsEditMode(true)
          }}
        />
      )}
    </StyledAddNewNode>
  )
}

export default AddNewNode
