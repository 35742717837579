import API, { API_URL } from 'src/business-layer-client'
import React, { Fragment, useEffect, useState } from 'react'
import {
  StyledEditableForm,
  StyledEditableInput,
  StyledListItem,
  StyledListItemText,
} from './styles'
import { get, noop } from 'lodash'
import EditItemDialog from 'modules/menu/components/EditItemDialog'
import IconCheckmark from 'assets/icons/IconCheckmark'
import IconClose from 'assets/icons/IconClose'
import MenuDropdown from 'modules/menu/components/MenuDropdown'
import PropTypes from 'prop-types'
import { SortableElement } from 'react-sortable-hoc'
import { StyledIconWrapper } from 'modules/menu/styles'
import { TOAST_MESSAGE_TYPES } from 'src/constants'
import httpStatusCodes from 'http-status-codes'
import { showToast } from 'components/ToastSnackbarContainer'
import theme from 'styles/theme'
import useClickOutside from 'hooks/useClickOutside'

const SortableItem = SortableElement(props => {
  const {
    menu,
    level,
    selectedMenu,
    handleMenuItemClick,
    handleMenuItemUpdate,
    handleMenuItemDelete,
    handleMenuItemDisable,
  } = props
  const ENTER_KEYCODE = 13

  const [label, setLabel] = useState(menu.label)
  const [isEditable, setIsEditable] = useState(false)
  const [showEditSettingDialog, setShowEditSettingDialog] = useState(false)

  const clickRef = React.useRef()

  useEffect(() => {
    setLabel(menu.label)
  }, [menu.label])

  const handleSubmit = async e => {
    e && e.preventDefault()

    if (menu.label !== label) {
      try {
        const endpoint = API_URL.MENU_UPDATE({ id: menu.id })
        const response = await API.xpm.patch(endpoint, {
          label,
        })

        if (response.data.status === httpStatusCodes.OK) {
          handleMenuItemUpdate({ level, id: menu.id, payload: { label } })
          setIsEditable(false)
        }
      } catch (err) {
        setLabel(menu.label)
        const message = get(err, 'response.data.message', err.message)
        showToast({
          message,
          kind: TOAST_MESSAGE_TYPES.ALERT,
        })
      }
    }
  }

  const handleClose = () => {
    setLabel(menu.label)
    setIsEditable(false)
  }

  const handleCheckmarkClick = e => {
    if (label) {
      handleSubmit(e)
      setIsEditable(false)
    }
  }

  useClickOutside(clickRef, async () => {
    setIsEditable(!isEditable)
    setLabel(menu.label)
  })

  const selected = selectedMenu.includes(menu.id)
  const fillColor = theme.colors.gray[400]

  if (isEditable) {
    return (
      <StyledEditableForm
        onSubmit={handleSubmit}
        ref={clickRef}
        data-testid='editable-form'
      >
        <StyledIconWrapper onClick={handleClose}>
          <IconClose width='18px' height='18px' fill={fillColor} />
        </StyledIconWrapper>
        <StyledEditableInput
          name='label'
          value={label}
          autoFocus
          onChange={e => setLabel(e.target.value)}
          onKeyDown={e => e.keyCode === ENTER_KEYCODE && handleSubmit(e)}
          data-testid='editable-input'
        />
        <StyledIconWrapper
          onClick={handleCheckmarkClick}
          data-testid='icon-checkmark-wrapper'
        >
          <IconCheckmark width='14px' height='14px' fill={fillColor} />
        </StyledIconWrapper>
      </StyledEditableForm>
    )
  }

  return (
    <Fragment>
      {showEditSettingDialog && (
        <EditItemDialog
          menu={menu}
          level={level}
          handleMenuItemUpdate={handleMenuItemUpdate}
          handleClose={() => setShowEditSettingDialog(false)}
        />
      )}
      <StyledListItem selected={selected} data-testid='sortable-item'>
        <StyledListItemText
          isActive={menu.isActive}
          onClick={() => handleMenuItemClick(menu, level)}
          data-testid='sortable-item-text'
        >
          {label}
        </StyledListItemText>
        <MenuDropdown
          menu={menu}
          level={level}
          fillColor={fillColor}
          setIsEditable={setIsEditable}
          setShowEditSettingDialog={setShowEditSettingDialog}
          handleMenuItemDelete={handleMenuItemDelete}
          handleMenuItemDisable={handleMenuItemDisable}
        />
      </StyledListItem>
    </Fragment>
  )
})

SortableItem.defaultProps = {
  handleMenuItemClick: noop,
  handleMenuItemUpdate: noop,
  handleMenuItemDelete: noop,
  handleMenuItemDisable: noop,
}

SortableItem.propTypes = {
  menu: PropTypes.object.isRequired,
  level: PropTypes.number.isRequired,
  selectedMenu: PropTypes.array.isRequired,
  handleMenuItemClick: PropTypes.func.isRequired,
  handleMenuItemUpdate: PropTypes.func.isRequired,
  handleMenuItemDelete: PropTypes.func.isRequired,
  handleMenuItemDisable: PropTypes.func.isRequired,
}

export default SortableItem
