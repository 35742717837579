import { StyledItem, StyledLabel } from './styles'
import IconArrowDropDown from 'assets/icons/IconArrowDropDown'
import IconArrowRight from 'assets/icons/IconArrowRight'
import IconDelete from 'assets/icons/IconDelete'
import PropTypes from 'prop-types'
import React from 'react'
import { Tooltip } from '@teamfabric/copilot-ui'
import { noop } from 'lodash'

const AttributeItemLabel = props => {
  const { isExpanded, label, handleOnLabelClick, hasEditAccess } = props

  if (!label) return null

  return (
    <StyledItem
      data-is-expanded={isExpanded}
      onClick={handleOnLabelClick}
      data-testid='attribute-item-label-wrapper'
      shouldApplyBorderBottomZero={props.shouldApplyBorderBottomZero}
    >
      {isExpanded ? <IconArrowDropDown /> : <IconArrowRight />}

      <div>
        <StyledLabel data-testid='attribute-item-label'>{label}</StyledLabel>
        <Tooltip data-testid='attribute-item-label-tooltip' size='small'>
          {label}
        </Tooltip>
      </div>

      {props.isDeletable && hasEditAccess && (
        <IconDelete
          className='icon-delete'
          onClick={props.handleOnDeleteClick}
        />
      )}
    </StyledItem>
  )
}

AttributeItemLabel.defaultProps = {
  isExpanded: false,
  isDeletable: false,
  label: '',
  handleOnLabelClick: noop,
  handleOnDeleteClick: noop,
  shouldApplyBorderBottomZero: false,
}

AttributeItemLabel.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  isDeletable: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  handleOnLabelClick: PropTypes.func.isRequired,
  handleOnDeleteClick: PropTypes.func.isRequired,
  shouldApplyBorderBottomZero: PropTypes.bool.isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
}

export default AttributeItemLabel
