import { BROWSE_MENU_TREE_DEFAULT_NAME as DEFAULT_LABELS } from 'src/constants'
import { HierarchyTreeCard } from '@teamfabric/copilot-ui'
import { NODE_TYPE } from 'modules/browse-menu-tree-gql/types'
import React from 'react'
import { getActiveLanguage } from 'store/i18n/selectors'
import { useSelector } from 'react-redux'

const NodeInEditState = ({
  id,
  nodeType,
  onSave,
  onCancel,
  pipelineProps,
}: {
  id: string
  nodeType: NODE_TYPE
  onSave: (name: string) => Promise<void>
  onCancel: () => void
  pipelineProps: { [key: string]: string }
}): JSX.Element => {
  const activeLang = useSelector(getActiveLanguage)
  let isSection = false
  let placeholderText: string

  switch (nodeType) {
    case NODE_TYPE.SECTION:
      placeholderText = DEFAULT_LABELS.section
      isSection = true
      break
    case NODE_TYPE.SUBNODE:
      placeholderText = DEFAULT_LABELS.subnode
      break
    case NODE_TYPE.NODE:
    default:
      placeholderText = DEFAULT_LABELS.node
  }

  return (
    <HierarchyTreeCard
      id={id}
      title={DEFAULT_LABELS.emptyTitle}
      placeholder={placeholderText}
      className='new-node-blank no-margin'
      tabIndex='0'
      showArrow={isSection}
      rename={true}
      onSave={onSave}
      onCancel={onCancel}
      localeCode={activeLang}
      {...pipelineProps}
    />
  )
}

export default NodeInEditState
