import { FORM_CONSTANTS, MODAL_ACTION, OK } from 'src/constants'

interface HeaderProps {
  viewMode: boolean
  submitButtonText: string
  submitDisabled: boolean
  onSubmit: () => void
  onCancel: () => void
}

// TODO: Move to components/ContentModalModule/utils.tsx when we transition to ContentModalModule
export const getModeConfigurationForHeader = ({
  mode,
  onSubmitHandler,
  onCloseHandler,
  submitDisabled,
}: {
  mode: string
  onSubmitHandler: () => void
  onCloseHandler: () => void
  submitDisabled: boolean
}): Partial<HeaderProps> => {
  const headerProps: Partial<HeaderProps> = {}
  if (mode === MODAL_ACTION.VIEW) {
    headerProps.viewMode = true
    headerProps.submitButtonText = OK
    headerProps.submitDisabled = false
    headerProps.onSubmit = onCloseHandler
    headerProps.onCancel = onCloseHandler
  } else {
    headerProps.viewMode = false
    headerProps.submitButtonText = FORM_CONSTANTS.ACTIONS.submit
    headerProps.submitDisabled = submitDisabled
    headerProps.onSubmit = onSubmitHandler
    headerProps.onCancel = onCloseHandler
  }
  return headerProps
}
