import { EMPTY_CONFIG_SCREEN } from 'src/constants'
import EmptyScreen from 'components/EmptyScreen'
import React from 'react'

const EmptyConfigScreen = (): JSX.Element => {
  return (
    <EmptyScreen
      primaryText={EMPTY_CONFIG_SCREEN.primaryText}
      secondaryText={EMPTY_CONFIG_SCREEN.secondaryText}
    />
  )
}

export default EmptyConfigScreen
