import { ArrayTypeProps, StateProps } from './types'
import {
  EDITOR_INSERT_COMPONENT,
  EDITOR_IS_DELETING,
  EDITOR_REORDER_COMPONENT,
} from 'modules/editor/actions'
import React, { useEffect, useState } from 'react'
import {
  StyledIconWrapper,
  StyledItemLabel,
  StyledItemLabelWrapper,
  StyledItemWrapper,
  StyledWrapper,
} from './styles'
import { connect, useDispatch, useSelector } from 'react-redux'
import { get, isEmpty, startCase } from 'lodash'
import AddComponent from 'modules/editor/components/AddComponent'
import { CuratedComponentProps } from 'store/types'
import { DELAY_TO_DRAG } from 'modules/editor/constants'
import { FF_NAMES } from 'src/constants'
import IconArrowDown from 'assets/icons/IconArrowDropDown'
import IconArrowRight from 'assets/icons/IconArrowRight'
import IconPlus from 'assets/icons/IconPlus'
import SortableArrayContainer from './SortableArrayContainer'
import { ThunkDispatch } from 'redux-thunk'
import { Tooltip } from '@teamfabric/copilot-ui'
import { VALUE_PROP_DEFAULTS } from '../constants'
import arrayMove from 'array-move'
import { bindActionCreators } from 'redux'
import { getCuratedComponents } from 'modules/editor/selectors'
import getValueFromType from 'lib/getValueFromType'
import pluralize from 'pluralize'
import { removeElementByAttribute } from 'lib/removeElementByAttribute'
import rfdc from 'rfdc'
import sortPrimitiveValues from 'lib/sortPrimitiveValues'
import { updateCuratedComponents } from './utils'
import { useFlag } from '@unleash/proxy-client-react'

const allowDelete = (isNested, value) => isNested || value.length > 1

// eslint-disable-next-line sonarjs/cognitive-complexity
const ArrayType = (props: ArrayTypeProps): React.ReactNode | null => {
  const { hasEditAccess } = props

  const ALLOW_NESTING_IN_ARRAYS = useFlag(
    FF_NAMES.unleashFFs.ALLOW_NESTING_IN_ARRAYS
  )
  const deepClone = rfdc() as (
    component: CuratedComponentProps
  ) => CuratedComponentProps
  const NO_INDEX = -1
  const dispatch = useDispatch()
  const [isExpanded, setIsExpanded] = useState(false)
  const [expandedItem, setExpandedItem] = useState(NO_INDEX)
  const descriptors = useSelector<StateProps, Record<string, unknown>[]>(
    state => state.editor.descriptors
  )
  const flyoutId = 'sortable-component-children-flyout'

  useEffect(() => {
    if (props.level === 1 && props.expandedProperty) {
      const propertyKey = `__${props.paths.join('.')}__`.toUpperCase()
      setIsExpanded(propertyKey === props.expandedProperty)
    }
  }, [props.expandedProperty, props.paths, props.level])

  if (isEmpty(props.descriptor)) {
    return null
  }

  const fieldDescriptorMap = props.descriptor.children?.children

  const getFieldValue = (component, field, fieldType) =>
    component[field] || (fieldType && VALUE_PROP_DEFAULTS[fieldType])

  const toggleIsExpanded = () => {
    if (props.level === 1) {
      const propertyKey = `__${props.paths.join('.')}__`.toUpperCase()

      if (propertyKey === props.expandedProperty) {
        props.setExpandedProperty('')
        setIsExpanded(false)
      } else {
        props.setExpandedProperty(propertyKey)
        setIsExpanded(true)
      }
    } else {
      setIsExpanded(expanded => !expanded)
    }
  }

  const toggleExpandedItem = (idx = NO_INDEX) => {
    if (expandedItem !== idx) {
      setExpandedItem(idx)
    } else {
      setExpandedItem(NO_INDEX)
    }
  }

  const handleOnPlusIconClick = (e: Event) => {
    e.stopPropagation()

    props.onChange({
      paths: props.paths,
      value: (props?.value ?? []).concat(
        getValueFromType(props.descriptor) as CuratedComponentProps[]
      ),
    })
  }

  const handleOnDeleteIconClick = (e: Event, idx: number) => {
    e.stopPropagation()

    if (props.value.length <= 1 && !props.descriptor.allowNestedComponents) {
      return
    }

    const nextValue = [].concat(
      props.value.slice(0, idx),
      props.value.slice(idx + 1, props.value.length)
    )

    dispatch(
      EDITOR_IS_DELETING({
        isDeletingComponent: true,
        deletePayload: {
          paths: props.paths,
          value: nextValue,
          label: `${startCase(props.descriptor.label)} ${idx + 1}`,
          isParentComponent: false,
          deleteIndex: idx,
        },
      })
    )
  }

  const handleOnInsertItem = (idx: number) => {
    removeElementByAttribute(`[data-flyoutid=${flyoutId}]`)

    const component = get(props, props.paths) as CuratedComponentProps
    const targetComponentCopy = deepClone(
      component[idx] as CuratedComponentProps
    )

    const updateComponents = (
      components: CuratedComponentProps[],
      componentToAdd: CuratedComponentProps
    ) => {
      const startIndex = idx + 1
      components.splice(startIndex, 0, componentToAdd)
      return components
    }

    dispatch(
      EDITOR_INSERT_COMPONENT({
        component: targetComponentCopy,
        callback: updateComponents,
        paths: props.paths,
        index: idx,
      })
    )
  }

  type IndexProps = {
    oldIndex: number
    newIndex: number
  }

  const onSortEnd = (
    { oldIndex, newIndex }: IndexProps,
    _,
    _props: ArrayTypeProps
  ) => {
    const updated: CuratedComponentProps[] = arrayMove(
      _props.value,
      oldIndex,
      newIndex
    )
    props.handleOnReorderComponent({
      curatedComponents: updateCuratedComponents(
        _props,
        updated
      ) as CuratedComponentProps[],
      arrayTypeInfo: {
        paths: _props.paths,
        oldIndex,
        newIndex,
      },
    })
  }

  return (
    <StyledWrapper data-testid='array-type-wrapper'>
      <StyledItemWrapper
        level={props.level}
        isExpanded={isExpanded}
        onClick={toggleIsExpanded}
      >
        <StyledIconWrapper>
          {isExpanded ? <IconArrowDown /> : <IconArrowRight />}
        </StyledIconWrapper>
        <StyledItemLabelWrapper>
          <StyledItemLabel
            level={props.level}
            data-testid='array-attribute-item-label'
          >
            {pluralize(props.descriptor?.label)}
          </StyledItemLabel>
          <Tooltip
            size='small'
            className='custom-tooltip'
            data-testid='array-attribute-item-tooltip'
          >
            {pluralize(props.descriptor?.label)}
          </Tooltip>
        </StyledItemLabelWrapper>
        {hasEditAccess && (
          <StyledIconWrapper
            onClick={handleOnPlusIconClick as () => void}
            data-testid='icon-plus'
          >
            <IconPlus width='18px' height='18px' />
          </StyledIconWrapper>
        )}
      </StyledItemWrapper>
      <SortableArrayContainer
        lockAxis='y'
        onSortEnd={(indexObj, event) => onSortEnd(indexObj, event, props)}
        pressDelay={DELAY_TO_DRAG as number}
        isExpanded={isExpanded}
        descriptors={descriptors}
        expandedItem={expandedItem}
        fieldDescriptorMap={fieldDescriptorMap}
        toggleExpandedItem={toggleExpandedItem}
        handleOnDeleteIconClick={handleOnDeleteIconClick}
        handleOnInsertItem={handleOnInsertItem}
        allowDelete={allowDelete}
        sortPrimitiveValues={sortPrimitiveValues}
        getFieldValue={getFieldValue}
        flyoutId={flyoutId}
        props={props}
        hasEditAccess={hasEditAccess}
      />
      {isExpanded &&
        hasEditAccess &&
        props.descriptor?.allowNestedComponents &&
        ALLOW_NESTING_IN_ARRAYS && (
          <AddComponent buttonType='text-only' paths={props.paths} />
        )}
    </StyledWrapper>
  )
}

const mapStateToProps = state => ({
  curatedComponents: getCuratedComponents(state) as CuratedComponentProps[],
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: ThunkDispatch<unknown, never, any>) =>
  bindActionCreators(
    {
      handleOnReorderComponent: EDITOR_REORDER_COMPONENT as () => void,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ArrayType)
