import { EDITOR_OPTIONS, MODAL_ACTION } from 'src/constants'
import React, { useCallback } from 'react'
import IconDotsVertical from 'assets/icons/IconDotsVertical'
import List from 'components/List'
import Menu from 'components/Menu'
import PropTypes from 'prop-types'
import { StyledListItem } from './styles'
import { theme as themeUi } from '@teamfabric/copilot-ui'
import { useUserPermissions } from 'contexts/userPermissions'

const EditorOptions = ({ onEditorOptionClick }) => {
  const { hasEditorPermissions } = useUserPermissions()
  const style = {
    width: '200px',
    position: 'absolute',
    left: '25px',
    top: '-5px',
  }

  const onClickViewModal = useCallback(() => {
    onEditorOptionClick(MODAL_ACTION.VIEW)
  }, [onEditorOptionClick])

  const onClickEditModal = useCallback(() => {
    onEditorOptionClick(MODAL_ACTION.EDIT)
  }, [onEditorOptionClick])

  const onClickCreateModal = useCallback(() => {
    onEditorOptionClick(MODAL_ACTION.CREATE)
  }, [onEditorOptionClick])

  const onClickCopyModal = useCallback(() => {
    onEditorOptionClick(MODAL_ACTION.COPY)
  }, [onEditorOptionClick])

  return (
    <Menu theme='default' align='right'>
      <Menu.Icon>
        <IconDotsVertical
          height={16}
          className='icon'
          fill={themeUi.palette.brand.primary.charcoal}
        />
      </Menu.Icon>
      <Menu.Content style={style}>
        <List>
          <StyledListItem
            onClick={onClickViewModal}
            data-testid='editor-options-view-version-details'
          >
            <div className='flexi'>
              <span className='label'>{EDITOR_OPTIONS.VIEW}</span>
            </div>
          </StyledListItem>
          {hasEditorPermissions && (
            <>
              <StyledListItem
                onClick={onClickEditModal}
                data-testid='editor-options-edit-version-details'
              >
                <div className='flexi'>
                  <span className='label'>{EDITOR_OPTIONS.EDIT}</span>
                </div>
              </StyledListItem>
              <StyledListItem
                onClick={onClickCreateModal}
                data-testid='editor-options-create-new-version'
              >
                <div className='flexi'>
                  <span className='label'>{EDITOR_OPTIONS.CREATE}</span>
                </div>
              </StyledListItem>
              <StyledListItem
                onClick={onClickCopyModal}
                data-testid='editor-options-make-a-copy'
              >
                <div className='flexi'>
                  <span className='label'>{EDITOR_OPTIONS.DUPLICATE}</span>
                </div>
              </StyledListItem>
            </>
          )}
        </List>
      </Menu.Content>
    </Menu>
  )
}

EditorOptions.propTypes = {
  onEditorOptionClick: PropTypes.func.isRequired,
}

export default EditorOptions
