import { StyledDtContainer, StyledDtLabel } from './styles'
import PropTypes from 'prop-types'
import React from 'react'

const Dt = ({ label, content, ...rest }) => {
  return (
    <StyledDtContainer className='dt-container' {...rest}>
      <StyledDtLabel className='dt-label'>{label}</StyledDtLabel>
      {content}
    </StyledDtContainer>
  )
}

Dt.propTypes = {
  label: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
}

export default Dt
