export const VALUE_PROP_DEFAULTS = {
  Boolean: false,
  Enum: '',
  Image: {},
  MultiLineString: '',
  Number: '',
  RichText: '',
  Size: {
    value: '',
    unit: 'auto',
  },
  String: '',
}
