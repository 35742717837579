import {
  ContentModalOptionsProps,
  FormModalProps,
  FormProps,
  ModalType,
} from 'components/ContentModalModule/types'
import { CONTEXT_MENU_OPTIONS } from 'components/ContentContextMenuModule/constants'
import { Channel } from 'store/channels/types'
import { I18nLocale } from 'store/i18n/types'
import { MultiSelectTextChipsOptions } from 'lib/getMultiSelectTextChipsOptions/types'

export interface FeaturesList {
  i18n: { enabled: boolean; locales: I18nLocale[] }
  multiChannel: { enabled: boolean; channels: Channel[] }
}

export type ModalContext = {
  context: {
    localesChipsOptions: MultiSelectTextChipsOptions[]
    channelsChipsOptions: MultiSelectTextChipsOptions[]
  }
}

export interface Modal {
  modalType: ModalType.Form
  size: ContentModalOptionsProps['size']
  title: string
  mode: ContentModalOptionsProps['mode']
  forms: FormProps[]
  onSubmit: FormModalProps['onSubmit']
  onClose: FormModalProps['onClose']
}

export interface ContextOption {
  label: CONTEXT_MENU_OPTIONS
  onClick: () => void
}

export interface ModalCommonInput<I, V> extends ModalContext {
  mode?: ModalMode
  initialValues?: I
  onModalClose?: () => void
  onSubmit: (values: V) => Promise<void> | void
}

export enum ModalMode {
  COPY = 'COPY',
  CREATE = 'CREATE',
}
