import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

const CARD_SIZE = `400px`

export const StyledContainer = styled.div`
  display: grid;
  place-items: center;
  height: 100vh;
`

export const StyledRow = styled.div`
  display: grid;
  place-items: center;
  max-width: ${CARD_SIZE};
  width: 100%;
  text-align: center;
`

export const StyledStatusCode = styled.h1`
  margin: 0;
  font-family: ${props => props.theme.typography.fontFamily.sans};
  font-weight: ${props => props.theme.typography.fontWeight.semibold};
  font-size: ${props => props.theme.typography.fontSize['6xl']};
  color: ${themeUi.palette.ui.neutral.grey4};
`

export const StyledHeading = styled.h1`
  margin: ${props => props.theme.space.space_5} 0;
  font-family: ${props => props.theme.typography.fontFamily.sans};
  font-weight: ${props => props.theme.typography.fontWeight.semibold};
  font-size: ${props => props.theme.typography.fontSize['2xl']};
  color: ${themeUi.palette.brand.primary.charcoal};
`

export const StyledErrorMessage = styled.p`
  margin: 0;
  margin-bottom: ${props => props.theme.space.space_5};
  font-family: ${props => props.theme.typography.fontFamily.sans};
  font-weight: ${props => props.theme.typography.fontWeight.medium};
  font-size: ${props => props.theme.typography.fontSize.sm};
  letter-spacing: ${props => props.theme.typography.letterSpacing.wide};
  color: ${themeUi.palette.brand.primary.charcoal};
`
