import { FF_NAMES } from 'src/constants'
import React from 'react'
import SortableArrayItem from './SortableArrayItem'
import { SortableContainer } from 'react-sortable-hoc'
import { useFlag } from '@unleash/proxy-client-react'

const SortableArrayContainer = SortableContainer(
  ({
    isExpanded,
    descriptors,
    expandedItem,
    fieldDescriptorMap,
    toggleExpandedItem,
    handleOnDeleteIconClick,
    handleOnInsertItem,
    allowDelete,
    sortPrimitiveValues,
    getFieldValue,
    props,
    flyoutId,
  }) => {
    const { value, descriptor, hasEditAccess } = props
    const ALLOW_NESTING_IN_ARRAYS = useFlag(
      FF_NAMES.unleashFFs.ALLOW_NESTING_IN_ARRAYS
    )

    let label
    return (
      <div data-testid='sortable-array-container'>
        {isExpanded &&
          Array.isArray(value) &&
          value.map((val, idx) => {
            const allowsNesting =
              descriptor?.allowNestedComponents && ALLOW_NESTING_IN_ARRAYS
            if (allowsNesting) {
              const nestedDescriptor = descriptors.find(
                desc => desc.id === val.id
              )
              if (!nestedDescriptor) {
                return null
              }
              label = nestedDescriptor?.label
            } else {
              label = `${descriptor.label} ${idx + 1}`
            }
            const itemIsExpanded = expandedItem === idx

            const canDelete = allowDelete(allowsNesting, value)

            return (
              <SortableArrayItem
                key={`item-${idx}`}
                index={idx}
                idx={idx}
                itemIsExpanded={itemIsExpanded}
                label={label}
                canDelete={canDelete}
                toggleExpandedItem={toggleExpandedItem}
                handleOnDeleteIconClick={handleOnDeleteIconClick}
                handleOnInsertItem={handleOnInsertItem}
                isNested={allowsNesting}
                fieldDescriptorMap={fieldDescriptorMap}
                sortPrimitiveValues={sortPrimitiveValues}
                getFieldValue={getFieldValue}
                value={val}
                descriptors={descriptors}
                props={props}
                flyoutId={flyoutId}
                hasEditAccess={hasEditAccess}
              />
            )
          })}
      </div>
    )
  }
)

export default SortableArrayContainer
