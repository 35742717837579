import { getAccountId } from 'lib/cookieUtils'
import { useEffect } from 'react'
import { useUnleashContext as useUnleash } from '@unleash/proxy-client-react'

const useUnleashContext = (): void => {
  const updateContext = useUnleash()

  useEffect(() => {
    const tenantId = getAccountId()
    void updateContext({ userId: tenantId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useUnleashContext
