import React, { memo, useMemo, useRef } from 'react'
import { StyledEditorPanel, StyledIconClose, StyledRichTextBox } from './styles'
import { StyledLabel, StyledWrapper } from 'components/type-components/styles'
import { theme as themeUi, useLocaleDirection } from '@teamfabric/copilot-ui'
import { CKEditor } from 'ckeditor4-react'
import Icon from 'assets/icons/IconPencil'
import Menu from 'components/Menu'
import PropTypes from 'prop-types'
import { VALUE_PROP_DEFAULTS } from '../constants'
import ckEditorConfig from './ckEditorConfig'
import { getActiveLanguage } from 'store/i18n/selectors'
import isEqual from 'react-fast-compare'
import { useSelector } from 'react-redux'

const RichTextType = props => {
  const { descriptor, paths, onChange, value, hasEditAccess } = props
  const wrapperRef = useRef(null)
  const activeLang = useSelector(getActiveLanguage)
  const textDirection = useLocaleDirection(activeLang)

  const styles = useMemo(
    () => ({
      width: '500px',
      position: 'fixed',
      left: '80px',
      top: '70px',
    }),
    []
  )

  if (!Object.keys(descriptor).length) return null

  const handleCloseEditor = e => {
    e?.stopPropagation()

    if (wrapperRef.current) {
      const menuIconElement = wrapperRef.current.querySelector('#menu-icon')
      menuIconElement?.click()
    }
  }

  const handleInstanceReady = ({ editor }) => {
    editor.setData(value)
  }

  return (
    <StyledWrapper ref={wrapperRef} data-testid='rich-text-type-wrapper'>
      <StyledLabel>{descriptor.label}</StyledLabel>
      <Menu align='right' className='editor-popup' backdrop={false}>
        <Menu.Icon id='menu-icon' data-testid='menu-icon'>
          <StyledRichTextBox>
            <Icon
              width={12}
              height={12}
              fill={themeUi.palette.brand.primary.gray}
            />
          </StyledRichTextBox>
        </Menu.Icon>
        <Menu.Content style={styles}>
          <StyledEditorPanel data-testid='editor-panel'>
            <StyledIconClose onClick={handleCloseEditor} />
            <h3
              className='field-title'
              data-testid='rich-text-type-field-title'
            >
              {descriptor.label}
            </h3>
            <CKEditor
              config={ckEditorConfig({
                textDirection,
                isReadOnly: !hasEditAccess,
              })}
              initData=''
              onInstanceReady={handleInstanceReady}
              onChange={e => onChange({ paths, value: e.editor.getData() })}
            />
          </StyledEditorPanel>
        </Menu.Content>
      </Menu>
    </StyledWrapper>
  )
}

RichTextType.defaultProps = {
  descriptor: {},
  value: VALUE_PROP_DEFAULTS.RichText,
}

RichTextType.propTypes = {
  descriptor: PropTypes.object.isRequired,
  paths: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
}

export default memo(RichTextType, isEqual)
