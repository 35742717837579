import styled, { keyframes } from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const slideInRight = keyframes`
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`

export const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  animation: ${slideInRight} 200ms ease-out;
`

export const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  background: ${themeUi.palette.brand.primary.white};
  user-select: none;
  margin-bottom: ${props => props.theme.space.space_5};
`

export const StyledItem = styled.li`
  display: grid;
  max-width: 229px;
  grid-template-columns: 24px 1fr 24px;
  align-items: center;
  padding: ${props => props.theme.space.space_5};
  background: ${themeUi.palette.brand.primary.white};
  cursor: pointer;
  border-top: 1px solid ${themeUi.palette.ui.neutral.grey4};
  border-bottom: ${({ shouldApplyBorderBottomZero }) =>
    shouldApplyBorderBottomZero
      ? 0
      : `1px solid ${themeUi.palette.ui.neutral.grey4}`};
  border-left: 4px solid
    ${({ ['data-is-expanded']: isExpanded }) =>
      isExpanded ? themeUi.palette.ui.states.active : 'transparent'};
  position: relative;

  & svg {
    fill: ${themeUi.palette.brand.primary.gray};
  }

  & svg.icon-delete {
    width: 18px;
    height: 18px;
    display: none;
  }

  &:hover {
    background: ${themeUi.palette.ui.neutral.grey5};
    opacity: 0.8;
    & svg.icon-dots-vertical {
      display: grid;
    }
    & svg.icon-delete {
      display: block;
    }
  }
`

export const StyledItemTypeExpandView = styled.div`
  border-bottom: 1px solid ${themeUi.palette.ui.neutral.grey4};
  background-color: ${themeUi.palette.ui.neutral.grey5};
`

export const StyledItemTypeComponent = styled.div`
  animation: ${fadeIn} 0.5s ease-in;
`

// https://css-tricks.com/snippets/css/truncate-string-with-ellipsis/
export const StyledLabel = styled.div`
  ${props => props.theme.typography.body};
  font-weight: ${props => props.theme.typography.fontWeight.medium};
  line-height: ${props => props.theme.typography.lineHeight.normal};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  display: block;
  align-items: center;
  width: 150px;
  height: 100%;
`
export const StyledAttributeItemLabelWrapper = styled.div`
  position: relative;
  z-index: 1;
`
