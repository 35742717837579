import React, { memo } from 'react'

const IconTrash = props => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='20'
      viewBox='0 0 18 20'
      {...props}
    >
      <defs>
        <linearGradient id='a' x1='9.5%' x2='92.081%' y1='0%' y2='100.952%'>
          <stop offset='0%' stopColor='#929292' />
          <stop offset='99.952%' stopColor='#929292' />
        </linearGradient>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <path fillRule='nonzero' d='M-3-2h24v24H-3z' />
        <path
          fill='url(#a)'
          d='M7 6V5a3 3 0 013-3h4a3 3 0 013 3v1h3a1 1 0 010 2v11a3 3 0 01-3 3H7a3 3 0 01-3-3V8a1 1 0 110-2h3zm2 0h6V5a1 1 0 00-1-1h-4a1 1 0 00-1 1v1zm9 2H6v11a1 1 0 001 1h10a1 1 0 001-1V8zm-9 3a1 1 0 012 0v6a1 1 0 01-2 0v-6zm4 0a1 1 0 012 0v6a1 1 0 01-2 0v-6z'
          transform='translate(-3 -2)'
          {...props}
        />
      </g>
    </svg>
  )
}

export default memo(IconTrash)
