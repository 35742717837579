import {
  ADD_DAM_ASSET_MODAL,
  DEFAULT_PAGINATION,
  DEFAULT_SECTIONS_COUNT,
  FORM_CONSTANTS,
  MESSAGES,
  SUPPORTED_OPERATIONS,
  TOAST_MESSAGE_TYPES,
} from 'src/constants'
import { AssetModal, Modal, MultiSelectTextChips } from '@teamfabric/copilot-ui'
import {
  AssetModalProps,
  AssetPageInfo,
  AssetProps,
  AssetsResponse,
  DamResponse,
  SectionsResponse,
  SelectedSections,
} from './types'
import {
  FETCH_ASSETS,
  FETCH_ASSETS_LABELS_SECTIONS,
  FETCH_SECTIONS,
} from 'services/graphql/dam'
import React, { useCallback, useEffect, useState } from 'react'
import { getActiveLanguage, getLocales } from 'store/i18n/selectors'
import {
  getFormattedAssets,
  getFormattedItems,
  getFormattedSections,
  handleErrors,
} from './utils'
import getMultiSelectTextChipsOptions, {
  OptionsType,
} from 'lib/getMultiSelectTextChipsOptions'
import { useDispatch, useSelector } from 'react-redux'
import { useLazyQuery, useQuery } from '@apollo/client'
import { EDITOR_SET_LOCALIZED_ASSETS } from 'modules/editor/actions'
import { MultiSelectDropdownOptions } from 'src/types'
import { SelectedRouteProps } from 'store/types'
import Spinner from 'components/Spinner'
import { getSelectedRoute } from 'modules/editor/selectors'
import { getVersionLocaleList } from 'components/EditorContentHeader/EditorFilters/utils'
import { showToast } from 'components/ToastSnackbarContainer'
import useDidMountEffect from 'hooks/useDidMountEffect'
const { VIEW_ASSET, UPLOAD_FROM_DAM } = SUPPORTED_OPERATIONS
const { cancelText, noLabelFilterText, saveText } = ADD_DAM_ASSET_MODAL
const chipsContainerWidth = '70%'
const DAMAssetModal = ({
  visible = true,
  onClose,
  value,
  paths,
  onChange,
  assetUrlsToIgnore = [],
}: // eslint-disable-next-line sonarjs/cognitive-complexity
AssetModalProps): JSX.Element => {
  const [assetsInfo, setAssetsInfo] = useState<null | AssetPageInfo>(null)
  const [sectionInfo, setSectionInfo] = useState<null | AssetPageInfo>(null)
  const [assetOptions, setAssetOptions] = useState([])
  const [sectionOptions, setSectionOptions] = useState([])
  const [labelOptions, setLabelOptions] = useState([])
  let assignedLocales = []
  const [selectedSections, setSelectedSections] = useState<null | string[]>(
    null
  )
  const [selectedLabel, setSelectedLabel] = useState<null | string[]>(null)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const [showLocalesModal, setShowLocalesModal] = useState(false)
  const [showAssetModal, setAssetModalVisibility] = useState(true)
  const locales = useSelector(getLocales)
  const selectedRoute = useSelector(getSelectedRoute) as SelectedRouteProps
  const [selectedAsset, setSelectedAsset] = useState<AssetProps>(null)
  const activeLang = useSelector(getActiveLanguage)
  const dispatch = useDispatch()

  if (selectedRoute?.locales && locales) {
    assignedLocales = getVersionLocaleList(locales, selectedRoute?.locales)
  }
  const multiSelectOptions = getMultiSelectTextChipsOptions(
    assignedLocales,
    OptionsType.LOCALES
  )
  const [selectedLocalesForAsset, setSelectedLocalesForAsset] = useState(
    multiSelectOptions
  )
  const { data: assetsLabelsSections, loading } = useQuery<DamResponse>(
    FETCH_ASSETS_LABELS_SECTIONS,
    {
      variables: {
        firstAssets: DEFAULT_PAGINATION.ITEMS_PER_PAGE,
        firstSection: DEFAULT_SECTIONS_COUNT,
      },
      onError: err => handleErrors(err, 'assets'),
    }
  )

  const [getAssets] = useLazyQuery<AssetsResponse>(FETCH_ASSETS, {
    onError: err => handleErrors(err, 'assets'),
  })

  const [getSections] = useLazyQuery<SectionsResponse>(FETCH_SECTIONS, {
    onError: err => handleErrors(err, 'sections'),
  })

  const retrieveFormattedAssets = async ({
    after = null,
  }: {
    after?: string
  }) => {
    const { data } = await getAssets({
      variables: {
        input: {
          sections: selectedSections,
          labels: selectedLabel,
        },
        first: DEFAULT_PAGINATION.ITEMS_PER_PAGE,
        after,
      },
    })
    setAssetsInfo(data?.assets?.pageInfo)

    return getFormattedAssets(data?.assets)
  }

  useDidMountEffect(() => {
    const fetchAssets = async () => {
      setIsModalLoading(true)
      const updatedAssets = await retrieveFormattedAssets({})
      setAssetOptions(updatedAssets)
      setIsModalLoading(false)
    }

    setAssetsInfo(null)
    setAssetOptions([])
    void fetchAssets()
  }, [selectedSections, selectedLabel])

  useEffect(() => {
    const { assets, sections, labels } = getFormattedItems(assetsLabelsSections)

    // Add option 'All' to remove filter by labels
    labels.unshift(noLabelFilterText)

    setAssetOptions(assets)
    setSectionOptions(sections)
    setLabelOptions(labels)
    setAssetsInfo(assetsLabelsSections?.assets?.pageInfo)
    setSectionInfo(assetsLabelsSections?.sections?.pageInfo)
  }, [assetsLabelsSections])

  const handleFetchMoreAssets = useCallback(async () => {
    const updatedAssets = await retrieveFormattedAssets({
      after: assetsInfo?.endCursor,
    })
    setAssetOptions((prevAssets: AssetProps[]) => [
      ...prevAssets,
      ...updatedAssets,
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetsInfo])

  const handleOnChange = (asset: AssetProps) => {
    onChange({
      paths,
      value: asset,
    })
  }

  const handleFetchMoreSections = async () => {
    const { data } = await getSections({
      variables: {
        first: 5,
        after: sectionInfo?.endCursor,
      },
    })

    const newSections = getFormattedSections(data?.sections)
    setSectionOptions((prevSections: string[]) => [
      ...prevSections,
      ...newSections,
    ])
    setSectionInfo(data?.sections?.pageInfo)
  }

  const handleSectionChange = (_selectedSections: SelectedSections) => {
    const { isSelected, label } = _selectedSections
    setSelectedSections(isSelected ? [label] : null)
  }

  const handleLabelChange = (_selectedLabel: string) => {
    if (_selectedLabel === noLabelFilterText) {
      setSelectedLabel(null)
    } else {
      setSelectedLabel([_selectedLabel])
    }
  }
  const handleOnSave = (asset: AssetProps) => {
    if (assignedLocales.length > 1) {
      setSelectedAsset(asset)
      setShowLocalesModal(true)
      setAssetModalVisibility(false)
    } else {
      handleOnChange(asset)
      onClose()
    }
  }
  const saveLocalizedAssets = () => {
    if (
      selectedLocalesForAsset.filter(asset => asset.label === activeLang)
        .length > 0
    ) {
      handleOnChange(selectedAsset)
    }
    const assetLocalesData = {
      selectedLocalesForAsset,
      selectedAsset,
      paths,
    }
    dispatch(EDITOR_SET_LOCALIZED_ASSETS(assetLocalesData))
    void showToast({
      message: MESSAGES.getSavedSuccess('Localized Asset'),
      kind: TOAST_MESSAGE_TYPES.SUCCESS,
    })
    onClose()
  }

  const handleLocalesChange = locale => {
    setSelectedLocalesForAsset(locale as MultiSelectDropdownOptions[])
  }
  const renderModalContent = () => {
    return (
      <>
        <div data-testid='content-list-module-alert-modal-content'>
          <div>Select locales you want to associate image(s) with.</div>
          <br></br>
          <MultiSelectTextChips
            label={FORM_CONSTANTS.VERSION_DETAILS_DIALOG.locales}
            dropdownOptions={multiSelectOptions}
            onChange={handleLocalesChange}
            value={selectedLocalesForAsset}
            width={chipsContainerWidth}
          />
        </div>
      </>
    )
  }
  const onLocalesModalClose = () => {
    setShowLocalesModal(false)
  }
  const renderModal = () => {
    return (
      <Modal
        headerText='Localise the asset'
        size='medium'
        showCloseButton
        onClose={onLocalesModalClose}
        onBackdropClick={onLocalesModalClose}
        headerButtons={[]}
        padding='45px 20px'
        footerButtons={[
          {
            'onClick': onLocalesModalClose,
            'text': 'Cancel',
            'isPrimary': false,
            'disabled': false,
            'data-testid': 'close-locales-modal-btn',
          },
          {
            'onClick': saveLocalizedAssets,
            'text': 'Add',
            'isPrimary': true,
            'disabled': !selectedLocalesForAsset?.length,
            'data-testid': 'save-localized-asset-btn',
          },
        ]}
        render={renderModalContent}
      />
    )
  }

  const hasSelectedAsset = !!(value && value?.url)

  const supportedOperations = [
    {
      title: VIEW_ASSET.title,
      operationType: VIEW_ASSET.operationType,
      selectedAsset: hasSelectedAsset ? value : null,
      onSubmit: handleOnSave,
    },
    {
      title: UPLOAD_FROM_DAM.title,
      operationType: UPLOAD_FROM_DAM.operationType,
      onSubmit: handleOnSave,
      onSectionSelect: handleSectionChange,
      onLabelSelect: handleLabelChange,
    },
  ]

  if (!visible) {
    return null
  }

  return (
    <>
      {loading ? (
        <Spinner variant='fullScreen' />
      ) : (
        showAssetModal && (
          <AssetModal
            supportedOperations={supportedOperations}
            options={{
              assetOptions: assetOptions.filter(
                asset => !assetUrlsToIgnore.includes(asset.url as string)
              ),
              sectionOptions: sectionOptions,
              labelOptions: labelOptions,
            }}
            customTextLabels={{
              cancelText,
              saveText,
            }}
            onClose={onClose}
            onBackdropClick={onClose}
            isAssetsLoading={isModalLoading}
            hasMoreAssets={assetsInfo?.hasNextPage ?? false}
            fetchMoreAssets={handleFetchMoreAssets}
            hasMoreSections={sectionInfo?.hasNextPage ?? false}
            fetchMoreSections={handleFetchMoreSections}
          />
        )
      )}
      {showLocalesModal && renderModal()}
    </>
  )
}

export default DAMAssetModal
