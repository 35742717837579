import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

const StyledErrorMessage = styled.div`
  ${props => props.theme.typography.body};
  font-size: ${props => props.theme.typography.fontSize.xs};
  color: ${themeUi.palette.ui.cta.red};
`

export default StyledErrorMessage
