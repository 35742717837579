import {
  ApolloCache,
  DefaultContext,
  MutationFunctionOptions,
  OperationVariables,
} from '@apollo/client'
import { Channels } from 'modules/editor/components/ChannelPreviewDropdown/types'
import { FormikProps } from 'formik'
import { LocaleValue } from 'components/LocalesField/types'

export enum VersionTypesEnum {
  PAGES,
  GLOBAL_ELEMENTS,
  MENUS,
}

// ======================= Initial Value Props =======================
export interface CommonInitialValueProps {
  versionName: string
  description: string
  name: string
  dropdownOptions?: unknown[]
  locales?: LocaleValue[]
  channels?: Channels[]
}

export interface PageInitialValueProps extends CommonInitialValueProps {
  url?: string
}

export type GEInitialValueProps = CommonInitialValueProps

export type MenuInitialValueProps = CommonInitialValueProps

// ======================= Required Value Props =======================
export interface CommonRequiredValueProps {
  versionName?: string
}

// ======================= Parent Element Props =======================
export interface CommonElementProps {
  name?: string
  description?: string
  channels?: Channels[]
  versionId?: string
  id?: string
  locales?: string[]
}

export interface PageParentElementProps extends CommonElementProps {
  pageUrl: string
  id: string
}

export interface GEParentElementProps extends CommonElementProps {
  id: string
}

export interface MenuParentElementProps extends CommonElementProps {
  id: string
}

// ======================= Input Field Props =======================
export interface CommonInputFieldProps {
  type: string
  label: string
  name: string
  dataTestId: string
  value: unknown
  disabled?: boolean
  dropdownOptions?: Array<unknown>
  autoFocus?: boolean
  placeholder?: string
  className?: string
  locale?: string
  localeCode?: string
  forceEnabled?: boolean
  customOnChange?: (
    selected: Record<string, unknown>[],
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => void
  seoFieldAction?: never
}

// ======================= Dynamic Dispatch Function Props =======================
export type ContentTypeGraphQLMutation = (
  options?: MutationFunctionOptions<
    unknown,
    OperationVariables,
    DefaultContext,
    ApolloCache<unknown>
  >
) => Promise<void>

export interface EndpointProps {
  parentId: string
  versionId?: string
  contentType?: VersionTypesEnum
}

export interface ContentTypeFunctionProps {
  getContentType: () => VersionTypesEnum
  getInitialValues: () => {
    url?: string
    name: string
    channels?: Channels[]
    versionName: string
    description: string
    locales?: LocaleValue[]
  }
  getVersionId: () => string
  getParentId: () => string
  getInputFields: (
    values: PageInitialValueProps | GEInitialValueProps
  ) => Array<CommonInputFieldProps>
  getGraphQLMutation?: (
    contentType: VersionTypesEnum
  ) => ContentTypeGraphQLMutation // TODO This is optional for now, but should be required once we have a GraphQL mutation for update Global Element version in XM-6810
  getRESTEndpoint: ({
    // TODO We can remove this once we move away to GraphQL in XM-6810
    parentId,
    versionId,
    contentType,
  }: EndpointProps) => string
  getRedirectPath: ({ parentId, versionId }: EndpointProps) => string
}

export interface ModeActionFunctionProps {
  getMode: () => string
  getInputVersionName: (name: string) => string
  getRESTEndpoint?: ({
    parentId,
    versionId,
    contentType,
  }: EndpointProps) => string
  getGraphQLMutation?: (
    contentType: VersionTypesEnum
  ) => ContentTypeGraphQLMutation | undefined
  submit?: (data: FormSubmitActionProps) => Promise<void | unknown>
}

// ======================= Dialog Modal Sections =======================
export interface DialogModalSectionProps {
  'general-details': {
    key: string
    title: string
    render: () => JSX.Element
  }
}

// ======================= Custom Formik Props =======================
export interface CustomFormikProps
  extends FormikProps<PageInitialValueProps | GEInitialValueProps> {
  contentElement: ContentTypeFunctionProps
  mode: string
}

export type FormikValuesObjectProps = {
  [key: string]: unknown
}

// ======================= Form Submit Action Props =======================
export interface FormSubmitActionProps {
  shouldUseGraphQL: boolean
  graphQLOperation: ContentTypeGraphQLMutation
  restEndpoint: string
  versionName: string
  description: string
  versionId: string
  parentId: string
  parentName: string
  contentType: VersionTypesEnum
  channels?: Channels[]
  locales?: {
    id: string
  }[]
}
