import {
  COPY_MENU_VARIANT,
  COPY_PAGE_VARIANT,
  CREATE_GE_VERSION,
  CREATE_MENU_VARIANT,
  UPDATE_MENU_VARIANT_DETAILS,
  UPDATE_PAGE_VERSION_BY_ID,
} from 'services/graphql'
import {
  ContentTypeGraphQLMutation,
  EndpointProps,
  FormSubmitActionProps,
  ModeActionFunctionProps,
  VersionTypesEnum,
} from '../types'
import {
  EDITOR_CREATE_NEW_VERSION,
  EDITOR_DUPLICATE_VERSION_DETAILS,
  EDITOR_UPDATE_VERSION_DETAILS,
} from './actions'
import { MODAL_ACTION } from 'src/constants'
import { useMutation } from '@apollo/client'

const { EDIT, COPY, CREATE, VIEW } = MODAL_ACTION

const commonGetMutationUtil = (pageMutation, geMutation, menuMutation) => (
  contentType: VersionTypesEnum
): ContentTypeGraphQLMutation => {
  switch (contentType) {
    case VersionTypesEnum.PAGES:
      return pageMutation
    case VersionTypesEnum.GLOBAL_ELEMENTS:
      return geMutation
    case VersionTypesEnum.MENUS:
      return menuMutation
    default:
      return null
  }
}

export const getBaseUrlFromContentType = (
  contentType: VersionTypesEnum
): string => {
  switch (contentType) {
    case VersionTypesEnum.PAGES:
      return 'page'
    case VersionTypesEnum.GLOBAL_ELEMENTS:
      return 'global-component'
    case VersionTypesEnum.MENUS:
      return 'menu'
    default:
      return null
  }
}

const commonGetContentVersionUrl = ({
  parentId,
  versionId,
  contentType,
}: EndpointProps): string =>
  `/${getBaseUrlFromContentType(contentType)}/${parentId}/version/${versionId}`

// Get current mode actions/functions
export const GetModeActions = (mode: string): ModeActionFunctionProps => {
  const [updatePageVersionById] = useMutation(UPDATE_PAGE_VERSION_BY_ID)
  const [copyPageVersion] = useMutation(COPY_PAGE_VARIANT)
  const [createGeVersion] = useMutation(CREATE_GE_VERSION)
  const [updateMenuVersionById] = useMutation(UPDATE_MENU_VARIANT_DETAILS)
  const [createMenuVersion] = useMutation(CREATE_MENU_VARIANT)
  const [copyMenuVersion] = useMutation(COPY_MENU_VARIANT)

  switch (mode) {
    case EDIT:
      return EditModeAction({
        pageMutation: updatePageVersionById,
        geMutation: null,
        menuMutation: updateMenuVersionById,
      })
    case COPY:
      return DuplicateModeAction({
        pageMutation: copyPageVersion,
        geMutation: null,
        menuMutation: copyMenuVersion,
      })
    case VIEW:
      return ViewModeAction()
    case CREATE:
      return CreateModeAction({
        pageMutation: null,
        geMutation: createGeVersion,
        menuMutation: createMenuVersion,
      })
    default:
      return
  }
}

// Create Mode
const CreateModeAction = ({ pageMutation, geMutation, menuMutation }) => {
  return {
    getMode: () => CREATE,
    getInputVersionName: () => '',
    getRESTEndpoint: ({ parentId, contentType }: EndpointProps): string =>
      `/${getBaseUrlFromContentType(contentType)}/${parentId}`,
    getGraphQLMutation: commonGetMutationUtil(
      pageMutation,
      geMutation,
      menuMutation
    ),
    submit: (data: FormSubmitActionProps) => EDITOR_CREATE_NEW_VERSION(data),
  }
}

// EDIT Mode
const EditModeAction = ({ pageMutation, geMutation, menuMutation }) => {
  return {
    getMode: () => EDIT,
    getInputVersionName: name => name,
    getRESTEndpoint: ({
      parentId,
      versionId,
      contentType,
    }: EndpointProps): string =>
      commonGetContentVersionUrl({ parentId, versionId, contentType }),
    getGraphQLMutation: commonGetMutationUtil(
      pageMutation,
      geMutation,
      menuMutation
    ),
    submit: (data: FormSubmitActionProps) =>
      EDITOR_UPDATE_VERSION_DETAILS(data),
  }
}

// DUPLICATE Mode
const DuplicateModeAction = ({ pageMutation, geMutation, menuMutation }) => {
  return {
    getMode: () => COPY,
    getInputVersionName: (name: string): string => `${name} copy`,
    getRESTEndpoint: ({
      parentId,
      versionId,
      contentType,
    }: EndpointProps): string =>
      commonGetContentVersionUrl({ parentId, versionId, contentType }),
    getGraphQLMutation: commonGetMutationUtil(
      pageMutation,
      geMutation,
      menuMutation
    ),
    submit: (data: FormSubmitActionProps) =>
      EDITOR_DUPLICATE_VERSION_DETAILS(data),
  }
}

// VIEW Mode
const ViewModeAction = () => {
  return {
    getMode: () => VIEW,
    getInputVersionName: name => name,
  }
}
