import { MENU_CONTEXT_OPTIONS, MENU_DELETE_MODAL } from 'src/constants'
import React, { Fragment, memo, useState } from 'react'
import {
  StyledDropdownList,
  StyledDropdownListItem,
  StyledDropdownListItemLabel,
  StyledIconDotsVertical,
} from './styles'
import ConfirmDialog from 'components/ConfirmDialog'
import IconPencil from 'assets/icons/IconPencil'
import IconSettings from 'assets/icons/IconSettings'
import IconToggle from 'assets/icons/IconToggle'
import IconTrash from 'assets/icons/IconTrash'
import Menu from 'components/Menu'
import PropTypes from 'prop-types'
import { StyledIconWrapper } from 'modules/menu/styles'
import noop from 'lodash/noop'

const { RENAME, EDIT_ITEM, DELETE, DISABLE, ENABLE } = MENU_CONTEXT_OPTIONS

const MenuDropdown = props => {
  const {
    menu,
    level,
    setIsEditable,
    setShowEditSettingDialog,
    handleMenuItemDelete,
    handleMenuItemDisable,
    fillColor,
  } = props

  const svgWidth = '12px'
  const [confirmDialog, setConfirmDialog] = useState({ isVisible: false })

  const dropdownList = [
    {
      label: RENAME,
      icon: () => (
        <IconPencil width={svgWidth} height={svgWidth} fill={fillColor} />
      ),
      onClick: () => setIsEditable(true),
    },
    {
      label: EDIT_ITEM,
      icon: () => (
        <IconSettings width={svgWidth} height={svgWidth} fill={fillColor} />
      ),
      onClick: () => setShowEditSettingDialog(true),
    },
    {
      label: DELETE,
      icon: () => (
        <IconTrash width={svgWidth} height={svgWidth} fill={fillColor} />
      ),
      onClick: () => {
        setConfirmDialog({
          isVisible: true,
          title: MENU_DELETE_MODAL.getTitle(level, menu.label),
          content: MENU_DELETE_MODAL.content,
          cancelText: MENU_DELETE_MODAL.cancelText,
          onCancel: () => setConfirmDialog({ isVisible: false }),
          submitText: MENU_DELETE_MODAL.submitText,
          onSubmit: () => handleMenuItemDelete({ id: menu.id, level }),
        })
      },
    },
    {
      label: menu.isActive ? DISABLE : ENABLE,
      icon: () => (
        <IconToggle width={svgWidth} height={svgWidth} fill={fillColor} />
      ),
      onClick: () =>
        handleMenuItemDisable({
          id: menu.id,
          level,
          isActive: !menu.isActive,
        }),
    },
  ]

  const { isVisible = false } = confirmDialog
  const LEVEL_LIMIT = 6

  return (
    <Fragment>
      {isVisible && <ConfirmDialog {...confirmDialog} />}
      <Menu align={level > LEVEL_LIMIT ? 'left' : 'right'}>
        <Menu.Icon>
          <StyledIconDotsVertical />
        </Menu.Icon>
        <Menu.Content style={{ width: '200px' }}>
          <StyledDropdownList>
            {dropdownList.map((listItem, idx) => {
              const { icon: Icon, label, onClick } = listItem
              return (
                <StyledDropdownListItem
                  key={idx}
                  onClick={onClick}
                  data-testid='list-item'
                >
                  <StyledIconWrapper>
                    <Icon />
                  </StyledIconWrapper>
                  <StyledDropdownListItemLabel data-testid='list-item-label'>
                    {label}
                  </StyledDropdownListItemLabel>
                </StyledDropdownListItem>
              )
            })}
          </StyledDropdownList>
        </Menu.Content>
      </Menu>
    </Fragment>
  )
}

MenuDropdown.defaultProp = {
  menu: {},
  level: -1,
  setIsEditable: noop,
  setShowEditSettingDialog: noop,
  handleMenuItemDelete: noop,
  handleMenuItemDisable: noop,
  fillColor: '',
}

MenuDropdown.propTypes = {
  menu: PropTypes.object.isRequired,
  level: PropTypes.number.isRequired,
  setIsEditable: PropTypes.func.isRequired,
  setShowEditSettingDialog: PropTypes.func.isRequired,
  handleMenuItemDelete: PropTypes.func.isRequired,
  handleMenuItemDisable: PropTypes.func.isRequired,
  fillColor: PropTypes.string.isRequired,
}

export default memo(MenuDropdown)
