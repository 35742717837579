import {
  ButtonWithIcon,
  Dropdown,
  Input,
  Textarea,
} from '@teamfabric/copilot-ui'
import {
  EMPTY_SELECTED_LOCALE,
  generateNewMetadata,
  getInitialSelectedLocale,
} from '../utils/utils'
import { LOCALES, PAGE_MODAL_LABEL } from 'src/constants'
import {
  LocalizedSeoFieldAction,
  SeoFieldAction,
} from '../utils/SeoFieldAction'
import { PageInitialValueProps, SeoFieldActionFunctionProps } from '../types'
import React, { useState } from 'react'
import { StyledSEOMetaDataLabel, StyledSEOMetaDataWrapper } from '../styles'
import { FormikProps } from 'formik'
import { I18nLocale } from 'store/i18n/types'
import { StyledFormField } from 'components/FormDialog/components/styles'
import VersionDetailHeader from 'components/FormDialog/components/VersionDetailHeader'
import { getModeConfigurationForHeader } from 'components/FormDialog/common/utils/getModeConfigurationForHeader'
import isEmpty from 'lodash/isEmpty'
import produce from 'immer'
import some from 'lodash/some'
import useDidMountEffect from 'hooks/useDidMountEffect'

const {
  SEO_TITLE,
  SEO_DESCRIPTION,
  METADATA,
  METADATA_CONTENT,
  METADATA_NAME,
} = PAGE_MODAL_LABEL

const ATTRIBUTES_MAX_LENGTH = 400
const DESCRIPTION_MAX_LENGTH = 400
const SEO_TITLE_MAX_LENGTH = 91
const TEXT_AREA_ROWS = 2

interface SeoAndMetadataProps {
  formik: FormikProps<PageInitialValueProps>
  mode: string
  handleClose: () => void
  locales: I18nLocale[]
}

export const SeoMetadataList = ({
  disabled = false,
  handleBlur,
  localeCode,
  seoFieldAction,
  setFieldValue,
}: {
  disabled: boolean
  handleBlur: (e: React.FocusEvent) => void
  localeCode?: string
  seoFieldAction: SeoFieldActionFunctionProps
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
}): JSX.Element => {
  const [mouseHoverIndex, setMouseHoverIndex] = useState(0)
  const metadata = seoFieldAction.getMetadata()

  const handleMetaDataChange = ({
    value,
    recordIdx,
    field,
  }: {
    value: string
    recordIdx: number
    field: string
  }) => {
    const nextSEOMetaData = produce(metadata, draftState => {
      draftState[recordIdx][field] = value
    })

    setFieldValue(seoFieldAction.getMetadataFieldName(), nextSEOMetaData, true)
  }

  const handleAddSEOMetaData = () => {
    const createdMetadata = generateNewMetadata()
    const nextMetadata = produce(metadata, draftState => {
      draftState.unshift(createdMetadata)
    })

    setFieldValue(seoFieldAction.getMetadataFieldName(), nextMetadata, true)
  }

  const handleRemoveSEOMetaData = (idx: number) => {
    // Cannot remove last metadata entry
    if (seoFieldAction.getMetadata().length === 1) {
      return
    }
    const nextMetadata = produce(metadata, draftState => {
      draftState.splice(idx, 1)
    })

    setFieldValue(seoFieldAction.getMetadataFieldName(), nextMetadata, true)
  }

  return (
    <>
      <StyledSEOMetaDataLabel>{METADATA}</StyledSEOMetaDataLabel>
      {seoFieldAction.getMetadata()?.map((record, idx) => {
        return (
          <StyledSEOMetaDataWrapper
            key={record.id}
            id={`seo-meta-data-wrapper-${idx}`}
            data-testid='seo-meta-data-wrapper'
            onMouseLeave={() => {
              setMouseHoverIndex(0)
            }}
            onMouseEnter={() => {
              setMouseHoverIndex(idx)
            }}
          >
            <Input
              className='primary'
              label={METADATA_NAME}
              isFloatedLabel
              inputProps={{
                'disabled': disabled,
                'value': record.name,
                'data-testid': `metadata-name-${idx}`,
                'name': `metadata-name-${idx}`,
                'type': 'text',
                'onBlur': handleBlur,
                'onChange': (e: React.ChangeEvent<HTMLInputElement>) =>
                  handleMetaDataChange({
                    value: e.target.value,
                    recordIdx: idx,
                    field: 'name',
                  }),
                'maxLength': ATTRIBUTES_MAX_LENGTH,
              }}
              localeCode={localeCode}
            />
            <Input
              className='primary'
              label={METADATA_CONTENT}
              isFloatedLabel
              inputProps={{
                'disabled': disabled,
                'value': record.content,
                'data-testid': `metadata-content-${idx}`,
                'name': `metadata-content-${idx}`,
                'type': 'text',
                'onChange': (e: React.ChangeEvent<HTMLInputElement>) =>
                  handleMetaDataChange({
                    value: e.target.value,
                    recordIdx: idx,
                    field: 'content',
                  }),
                'maxLength': ATTRIBUTES_MAX_LENGTH,
              }}
              localeCode={localeCode}
            />
            {!disabled && idx === 0 && (
              <ButtonWithIcon
                icon='Add'
                isRoundIcon
                iconSize={14}
                size='small'
                data-testid='metadata-plus-icon'
                onClick={handleAddSEOMetaData}
                type='button'
              />
            )}
            {!disabled && idx > 0 && mouseHoverIndex === idx && (
              <ButtonWithIcon
                icon='Close'
                isPrimary={false}
                data-testid={`metadata-delete-icon-${idx}`}
                iconSize={10}
                isRoundIcon
                size='small'
                theme='light'
                onClick={() => handleRemoveSEOMetaData(idx)}
                type='button'
              />
            )}
          </StyledSEOMetaDataWrapper>
        )
      })}
    </>
  )
}

const SeoAndMetadataTab = ({
  formik,
  mode,
  handleClose,
  locales,
}: SeoAndMetadataProps): JSX.Element => {
  const { localizedSeoFields, locales: inputLocales } = formik.values
  const hasAssignedLocales = some(locales) && some(inputLocales)

  const [selectedLocale, setSelectedLocale] = useState(
    hasAssignedLocales
      ? getInitialSelectedLocale(inputLocales)
      : EMPTY_SELECTED_LOCALE
  )

  useDidMountEffect(() => {
    setSelectedLocale(getInitialSelectedLocale(inputLocales))
  }, [inputLocales])

  const seoFieldAction = hasAssignedLocales
    ? LocalizedSeoFieldAction({ localizedSeoFields, selectedLocale })
    : SeoFieldAction({ values: formik.values })

  const localeCode = selectedLocale.name

  return (
    <>
      <VersionDetailHeader
        {...getModeConfigurationForHeader({
          mode,
          onSubmitHandler: formik.handleSubmit,
          onCloseHandler: handleClose,
          submitDisabled: !isEmpty(formik.errors),
        })}
      />
      <form onSubmit={formik.handleSubmit}>
        <div data-testid='seo-section-content'>
          {hasAssignedLocales && (
            <StyledFormField>
              <Dropdown
                onSelect={setSelectedLocale}
                options={inputLocales.map(loc => ({
                  id: loc.id,
                  name: loc.id,
                }))}
                titleLabel={LOCALES.localesSeoFieldLabel}
                value={selectedLocale}
                data-testid='seo-locales-field'
              />
            </StyledFormField>
          )}
          <StyledFormField>
            <Input
              label={SEO_TITLE}
              isFloatedLabel
              inputProps={{
                'disabled': false,
                'value': seoFieldAction.getSeoTitle().value,
                'onChange': formik.handleChange,
                'data-testid': 'seo-title-field',
                'name': seoFieldAction.getSeoTitle().name,
                'type': 'text',
                'onBlur': formik.handleBlur,
                'maxLength': SEO_TITLE_MAX_LENGTH,
              }}
              localeCode={localeCode}
            />
            <small style={{ textAlign: 'right' }}>
              {SEO_TITLE_MAX_LENGTH - seoFieldAction.getSeoTitle().value.length}
            </small>
          </StyledFormField>
          <StyledFormField>
            <Textarea
              key={selectedLocale.id}
              className='multiline'
              label={SEO_DESCRIPTION}
              textareaProps={{
                'limit': DESCRIPTION_MAX_LENGTH,
                'rows': TEXT_AREA_ROWS,
                'value': seoFieldAction.getDescription().value,
                'onChange': formik.handleChange,
                'onBlur': formik.handleBlur,
                'name': seoFieldAction.getDescription().name,
                'data-testid': 'seo-description-field',
              }}
              localeCode={localeCode}
            />
          </StyledFormField>
          <SeoMetadataList
            handleBlur={formik.handleBlur}
            seoFieldAction={seoFieldAction}
            setFieldValue={formik.setFieldValue}
            localeCode={localeCode}
            disabled={false}
          />
        </div>
      </form>
    </>
  )
}

export default SeoAndMetadataTab
