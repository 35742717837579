import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 24px);
  place-items: center;
  place-content: center;
  grid-column-gap: 1rem;
`

export const StyledIcon = styled.div`
  width: 36px;
  height: 36px;
  background-color: ${themeUi.palette.brand.primary.white};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
    fill: ${({ 'data-selected': selected }) =>
      selected
        ? themeUi.palette.ui.states.active
        : themeUi.palette.brand.primary.gray};
  }
  svg.icon-preview-arrow {
    transform: rotate(145deg);
  }
`
