import React, { memo, useCallback, useMemo } from 'react'
import { StyledLabel, StyledWrapper } from 'components/type-components/styles'
import Field from 'components/Field'
import PropTypes from 'prop-types'
import { VALUE_PROP_DEFAULTS } from '../constants'
import isEqual from 'react-fast-compare'

const BooleanType = props => {
  const { descriptor, paths, value, onChange, hasEditAccess } = props

  const handleOnChange = useCallback(
    e => onChange({ paths, value: e.target.checked }),
    [paths, onChange]
  )

  const name = useMemo(() => paths.join('.'), [paths])

  if (!Object.keys(descriptor).length) return null

  return (
    <StyledWrapper data-testid='boolean-type-wrapper'>
      <StyledLabel>{descriptor.label}</StyledLabel>
      <Field
        id={name}
        name={name}
        size='small'
        value={value}
        type='checkbox'
        checked={value}
        onChange={handleOnChange}
        data-testid='boolean-type-input'
        disabled={!hasEditAccess}
      />
    </StyledWrapper>
  )
}

BooleanType.defaultProps = {
  descriptor: {},
  value: VALUE_PROP_DEFAULTS.Boolean,
}

BooleanType.propTypes = {
  descriptor: PropTypes.object.isRequired,
  paths: PropTypes.array.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
}

export default memo(BooleanType, isEqual)
