function validateType(type) {
  const validTypes = [
    'String',
    'Number',
    'Boolean',
    'Array',
    'Shape',
    'Enum',
    'Size',
    'MultiLineString',
    'Image',
    'RichText',
    'Component',
  ]

  return validTypes.includes(type)
}

function getKeys(element) {
  return Object.keys(element.children)
    .map(key => {
      return {
        [key]: getValueFromType(element.children[key]),
      }
    })
    .reduce(
      (acc, x) => {
        for (const key in x) acc[key] = x[key]
        return acc
      },
      { components: [] }
    )
}

const getValueFromType = element => {
  switch (element.type) {
    case 'String':
    case 'Number':
    case 'Enum':
    case 'MultiLineString':
      return element.default || ''

    case 'Boolean':
      return element.default || false

    case 'Size':
      return {
        value: '',
        unit: 'auto',
      }

    case 'Array': {
      if (!element.children) {
        return ['']
      }

      return [getValueFromType(element.children)]
    }

    case 'Image':
      return {
        url: '',
        altText: '',
      }
    case 'RichText': {
      return element.default || ''
    }
    case 'Component': {
      const attributeKeys = Object.keys(element.attributes)
      const returnValue = {}

      for (const key of attributeKeys) {
        returnValue[key] = getValueFromType(element.attributes[key])
      }

      return returnValue
    }
    default: {
      if (!validateType(element.type)) return null

      if (!element.children.type) {
        return getKeys(element)
      }
      break
    }
  }
}

export default getValueFromType
