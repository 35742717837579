import styled from 'styled-components'

export const StyledSelect = styled.select`
  display: block;
  width: 100%;
  height: 30px;
  padding: 0.25rem 0.75rem;
  margin: 0.25rem 0;
  font-size: ${props => props.theme.typography.fontSize.sm};
  font-weight: ${props => props.theme.typography.fontWeight.normal};
  border: 1px solid ${({ theme }) => theme.colors.gray[300]};
  border-radius: ${props => props.theme.borderRadius.sm};
  background-color: ${({ theme }) => theme.colors.white};
  outline: none;
  appearance: none;

  &:focus-within {
    border-color: ${({ theme }) => theme.colors.gray[500]};
  }
`

export const StyledRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 75px;
  align-items: center;
  grid-gap: 5px;
`
