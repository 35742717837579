import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledButton = styled.button`
  ${props => props.theme.typography.label};
  cursor: pointer;
  text-decoration: none;
  border-radius: ${props => props.theme.borderRadius.sm};
  user-select: none;
  display: inline-block;
  font-family: ${props => props.theme.typography.fontFamily.sans};
  font-weight: ${props => props.theme.typography.fontWeight.medium};
  outline: none;

  // default color === 'primary'
  background-color: ${themeUi.palette.ui.states.active};
  color: ${themeUi.palette.brand.primary.white};
  border: 1px solid ${themeUi.palette.ui.states.active};

  ${props =>
    props.color === 'secondary' &&
    `
    background-color: ${themeUi.palette.brand.primary.white};
    color: ${themeUi.palette.brand.primary.charcoal};
    border: 1px solid transparent;
  `};

  padding: 12px 24px;

  ${props =>
    props.size === 'small' &&
    `
    padding: 8px 16px;
  `} ${props =>
    props.size === 'large' &&
    `
    padding: 14px 32px;
  `} ${props =>
    props.variant === 'block' &&
    `
    display: block;
    width: 100%;
  `} ${props =>
    props.href &&
    props.href !== '' &&
    `
    background: transparent !important;
    color: ${themeUi.palette.brand.primary.charcoal};
    border: none !important;
    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled {
      color: ${themeUi.palette.brand.primary.gray} !important;
      pointer-events: none;
    }
    &:hover {
      color: ${themeUi.palette.brand.primary.charcoal} !important;
    }
    &:focus {
      color: ${themeUi.palette.brand.primary.charcoal} !important;
    }
  `} &:disabled {
    background-color: ${themeUi.palette.ui.neutral.grey7};
    color: ${themeUi.palette.brand.primary.gray};
    border: 1px solid ${themeUi.palette.ui.neutral.grey7};
    pointer-events: none;
  }
  &:hover:not([disabled]) {
    background-color: ${({ color }) =>
      color === 'secondary'
        ? themeUi.palette.brand.primary.white
        : themeUi.palette.ui.states.hover};
    color: ${({ color }) =>
      color === 'secondary'
        ? themeUi.palette.brand.primary.charcoal
        : themeUi.palette.brand.primary.white};
    border: 1px solid
      ${({ color }) =>
        color === 'secondary' ? 'none' : themeUi.palette.ui.states.hover};
  }

  ${props => props.variant.block && `width: 100%;`};

  .button-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    > * {
      margin: 0;
    }
  }
`

export const StyledButtonLink = StyledButton.withComponent('a')
