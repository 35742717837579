import handleResponse from 'lib/handleResponse'
import { saveComponents } from './utils'

class Component {
  async save(
    { pageId, versionId },
    payload,
    shouldUseGraphQL,
    updatePageVersionComponents
  ) {
    try {
      const response = await saveComponents(
        pageId,
        versionId,
        payload,
        shouldUseGraphQL,
        updatePageVersionComponents
      )
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }
}

export default new Component()
