import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
`

const inputSize = {
  small: '35px',
  medium: '40px',
  large: '45px',
}

export const StyledInput = styled.input`
  ${props => props.theme.typography.body};
  display: block;
  height: ${({ size, type }) => {
    if (['checkbox', 'radio'].includes(type)) {
      return 'auto'
    }

    return inputSize[size] ?? '35px'
  }};
  border: 1px solid ${themeUi.palette.ui.neutral.grey4};
  border-radius: ${props => props.theme.borderRadius.sm};
  padding: 0.25rem 0.75rem;
  margin: 0.25rem 0;
  background: transparent;
  outline: none;

  &:focus-within {
    border-color: ${themeUi.palette.brand.primary.gray};
  }
  &:disabled {
    background: ${themeUi.palette.ui.neutral.grey4};
  }
`

export const StyledLabel = styled.label`
  ${props => props.theme.typography.labelSmall};
  display: block;
  width: 100%;
  color: ${themeUi.palette.brand.primary.gray};
`

export const StyledErrorMessage = styled.div`
  ${props => props.theme.typography.body};
  font-size: ${props => props.theme.typography.fontSize.xs};
  color: ${themeUi.palette.ui.cta.red};
`

export const StyledCaption = styled.div`
  ${props => props.theme.typography.body};
  font-size: ${props => props.theme.typography.fontSize.xs};
  color: ${themeUi.palette.brand.primary.gray};
`
