import React from 'react'
import isFunction from 'lodash/isFunction'

// copied form https://www.30secondsofcode.org/react/s/use-click-outside
const useClickOutside = (ref, callback) => {
  const handleClick = e => {
    if (
      isFunction(callback) &&
      ref.current &&
      !ref.current.contains(e.target)
    ) {
      callback()
    }
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  })
}

export default useClickOutside
