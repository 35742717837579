import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > p {
    ${themeUi.typography.subtitle3}
  }
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > button {
    margin-left: ${({ theme }) => theme.space.space_5};
  }
`
