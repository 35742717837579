import API, { API_URL, extractData } from 'src/business-layer-client'
import { MENU_EDIT_MODAL, MESSAGES, TOAST_MESSAGE_TYPES } from 'src/constants'
import React, { memo, useEffect, useState } from 'react'
import {
  StyledButtonWrapper,
  StyledEditSettingForm,
  StyledIconClose,
  StyledInlineInput,
  StyledLabel,
  StyledRowLeft,
  StyledSwitchWrapper,
} from './styles'
import { get, isEmpty, noop, size } from 'lodash'
import Button from 'components/Button'
import IconPlus from 'assets/icons/IconPlus'
import Input from 'components/Input'
import Modal from 'components/Modal'
import PropTypes from 'prop-types'
import { StyledIconWrapper } from 'modules/menu/styles'
import Switch from 'components/Switch'
import getErrorMessage from 'modules/menu/utils/getErrorMessage'
import httpStatusCodes from 'http-status-codes'
import rfdc from 'rfdc'
import { showToast } from 'components/ToastSnackbarContainer'
import theme from 'styles/theme'
import useAsync from 'hooks/useAsync'
import validation from './validation'

const deepClone = rfdc()

const EditSettingDialog = props => {
  const { menu, level, handleMenuItemUpdate, handleClose } = props

  const initialQueryParameters =
    size(menu.params) > 0 ? deepClone(menu.params) : [{ kind: '', value: '' }]

  const [isActive, setIsActive] = useState(menu.isActive)
  const [label, setLabel] = useState(menu.label)
  const [path, setPath] = useState(menu.path)
  const [queryParameters, setQueryParameters] = useState(initialQueryParameters)
  const [validationsErrors, setValidationsErrors] = useState(null)

  useEffect(() => {
    const errors = validation({ label, path, queryParameters })

    if (size(errors)) {
      setValidationsErrors(errors)
    } else {
      setValidationsErrors(null)
    }
  }, [label, path, queryParameters])

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      const response = await API.xpm.patch(
        API_URL.MENU_UPDATE({ id: menu.id }),
        {
          label,
          path,
          isActive,
          params: queryParameters,
        }
      )
      const data = extractData(response)

      if (response.data.status === httpStatusCodes.OK && !isEmpty(data)) {
        handleMenuItemUpdate(
          {
            level,
            id: menu.id,
            payload: {
              isActive,
              label,
              path: path,
              params: queryParameters,
            },
          },
          () => {
            showToast({
              message: MESSAGES.getSavedSuccess(`Menu ${label}`),
              kind: TOAST_MESSAGE_TYPES.SUCCESS,
            })
            handleClose()
          }
        )
      }
    } catch (err) {
      showToast({
        message: getErrorMessage(err),
        kind: TOAST_MESSAGE_TYPES.SUCCESS,
      })
    }
  }

  const submission = useAsync(handleSubmit)

  const handleCloseModal = e => {
    e.preventDefault()
    handleClose()
  }

  return (
    <Modal
      isVisible
      backdrop={false}
      width='420px'
      headerText=''
      title={MENU_EDIT_MODAL.HEADER}
      onClose={handleClose}
    >
      <StyledEditSettingForm onSubmit={submission.run}>
        <StyledRowLeft>
          <StyledSwitchWrapper>
            <StyledLabel>{isActive ? 'Enabled' : 'Disabled'}</StyledLabel>
            <Switch
              checked={isActive}
              onClick={() => setIsActive(!isActive)}
              data-testid='enable-switch'
            />
          </StyledSwitchWrapper>
        </StyledRowLeft>
        <Input
          label={MENU_EDIT_MODAL.ITEM_NAME}
          inputProps={{
            'value': label,
            'onChange': e => setLabel(e.target.value),
            'data-testid': 'category-name',
          }}
          errorProps={{
            hasError: !!get(validationsErrors, 'label'),
            errorMessage: get(validationsErrors, 'label'),
          }}
        />
        <Input
          label={MENU_EDIT_MODAL.ITEM_PARENT}
          inputProps={{
            'value': menu.parentName || 'no parent',
            'disabled': true,
            'data-testid': 'parent-name',
          }}
        />
        <Input
          label={MENU_EDIT_MODAL.URL}
          inputProps={{
            'value': path,
            'placeholder': 'Enter url here',
            'onChange': e => setPath(e.target.value),
            'data-testid': 'url-path',
          }}
          errorProps={{
            hasError: !!get(validationsErrors, 'path'),
            errorMessage: get(validationsErrors, 'path'),
          }}
        />
        <StyledLabel>{MENU_EDIT_MODAL.QUERY_PARAM}</StyledLabel>
        {queryParameters.map((qp, idx) => {
          return (
            <StyledInlineInput key={idx}>
              <StyledIconWrapper
                onClick={() => {
                  const nextQueryParameters = [
                    ...queryParameters,
                    { kind: '', value: '' },
                  ]
                  setQueryParameters(nextQueryParameters)
                }}
              >
                <IconPlus
                  style={{ cursor: 'pointer' }}
                  width='14px'
                  height='14px'
                  fill={theme.colors.gray[400]}
                />
              </StyledIconWrapper>
              <Input
                inputProps={{
                  value: qp.kind,
                  placeholder: 'Enter parameter',
                  onChange: e => {
                    const { value } = e.target
                    const nextQueryParameters = [...queryParameters]
                    nextQueryParameters[idx].kind = value.trim()
                    setQueryParameters(nextQueryParameters)
                  },
                }}
              />
              <Input
                inputProps={{
                  value: qp.value,
                  placeholder: 'Enter value',
                  onChange: e => {
                    const nextQueryParameters = [...queryParameters]
                    nextQueryParameters[idx].value = e.target.value.trim()
                    setQueryParameters(nextQueryParameters)
                  },
                }}
              />
              <StyledIconWrapper
                onClick={() => {
                  if (queryParameters.length > 1) {
                    const nextQueryParameters = queryParameters.filter(
                      (el, i) => idx !== i
                    )
                    setQueryParameters(nextQueryParameters)
                  }
                }}
              >
                <StyledIconClose
                  width='18px'
                  height='18px'
                  fill={theme.colors.gray[400]}
                />
              </StyledIconWrapper>
            </StyledInlineInput>
          )
        })}
        <StyledButtonWrapper>
          <Button
            color='secondary'
            onClick={handleCloseModal}
            data-testid='cancel-button'
          >
            Cancel
          </Button>
          <Button
            type='submit'
            color='primary'
            data-testid='submit-button'
            disabled={submission.loading || size(validationsErrors) > 0}
          >
            Save
          </Button>
        </StyledButtonWrapper>
      </StyledEditSettingForm>
    </Modal>
  )
}

EditSettingDialog.propTypes = {
  menu: {},
  level: -1,
  handleMenuItemUpdate: noop,
  handleClose: noop,
}

EditSettingDialog.propTypes = {
  menu: PropTypes.object.isRequired,
  level: PropTypes.number.isRequired,
  handleMenuItemUpdate: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default memo(EditSettingDialog)
