import { ListItem } from 'components/List'
import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledHeader = styled.section`
  height: ${props => props.theme.constants.barHeight};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${themeUi.palette.brand.primary.charcoal};
  ${themeUi.typography.caption};
  letter-spacing: ${props => props.theme.typography.letterSpacing.wider};
  padding: 0 16px;
  box-sizing: border-box;

  & .title {
    display: flex;
    align-items: center;
  }

  & .navBack {
    display: inline-flex;
    margin-right: 6px;
  }
`

export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${themeUi.typography.h4};
  padding: 10px 16px;
`

export const StyledStatus = styled.div`
  padding: 0 16px;
  color: ${themeUi.palette.brand.primary.gray};
  ${themeUi.typography.h6};
  text-transform: capitalize;

  .circle {
    margin-right: 10px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
  }

  .live {
    background-color: ${themeUi.palette.ui.cta.green};
  }

  .scheduled {
    background-color: ${themeUi.palette.ui.states.hover};
  }

  .ended {
    background-color: ${themeUi.palette.ui.neutral.grey7};
  }

  .draft {
    background-color: ${themeUi.palette.brand.primary.gray};
  }
`

export const StyledLabel = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const StyledBreadCrumb = styled.div`
  li {
    ${themeUi.typography.caption};
  }
`

export const StyledListItem = styled(ListItem)`
  display: block;
  font-weight: ${props => props.theme.typography.fontWeight.semibold};
  color: ${themeUi.palette.brand.primary.charcoal};
  cursor: pointer;
  ${props => props.theme.typography.content};

  &.special-item {
    border-bottom: 1px solid ${themeUi.palette.ui.neutral.grey4};
  }
  .flexi {
    display: flex;
    align-items: center;
    position: relative;
    padding: 12px 16px;
    &.disabled {
      color: ${themeUi.palette.ui.neutral.grey5};
      pointer-events: none;
    }
    > .to-right {
      position: absolute;
      right: 16px;
    }
  }
  .label {
    margin-left: 12px;
    ${props => props.theme.typography.h5};
  }
  &:hover {
    background: ${themeUi.palette.ui.neutral.grey5};
  }
`
export const StyledButtonWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 0 !important;
  display: flex;
  justify-content: flex-end;

  & > button:first-child {
    margin-right: ${props => props.theme.space.space_5};
  }
`

export const StyledComponent = styled.div`
  border-right: 1px solid ${themeUi.palette.ui.neutral.grey4};
  .sidebar-attribute-list {
    margin-bottom: 0px;
  }

  input:focus {
    border: 1px solid ${themeUi.palette.ui.states.active};
  }
`
