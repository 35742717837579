import React, { FunctionComponent } from 'react'
import { Button } from '@teamfabric/copilot-ui'
import { QuickLinkProps } from './types'

const QuickLink: FunctionComponent<QuickLinkProps> = props => {
  const { title, handler, isChild } = props
  return isChild && title && handler ? (
    <Button
      data-testid={'quick-link-button'}
      text={title}
      onClick={handler}
      size={'small'}
      isPrimary={false}
    />
  ) : (
    <></>
  )
}

export default QuickLink
