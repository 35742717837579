import {
  FF_NAMES,
  MAX_RETRY_COUNT,
  SHADOW_RELEASE_STAGE,
  STAGE_NAME,
  UNLEASH_CONFIG_FAILOVER,
  tobyBaseApiPath,
} from 'src/constants'
import {
  getAccountId,
  getCookieValue,
  isKeyInCookieString,
} from 'lib/cookieUtils'
import { UnleashClient } from 'unleash-proxy-client'
import axios from 'axios'
import get from 'lodash/get'
import graphQLProxy from 'src/graphql-proxy'
import httpStatusCodes from 'http-status-codes'
import isBrowseMenuRequest from 'lib/isBrowseMenuRequest'
import { redirectToSignInPage } from './utils'

let retryCount = 0
const isLocal = process.env.NODE_ENV === 'development'

const XM_API_STAGE_NAME = 'api-xpm'
const EXPERIMENTAL_COOKIES = [
  // add experimental cookie flags here
  SHADOW_RELEASE_STAGE,
]

export const isUnleashEnvSetup =
  !!process.env.REACT_APP_UNLEASH_PROXY_URL &&
  !!process.env.REACT_APP_UNLEASH_PROXY_CLIENT_KEYS

export const unleashConfig = {
  url: process.env.REACT_APP_UNLEASH_PROXY_URL ?? UNLEASH_CONFIG_FAILOVER.URL,
  clientKey:
    process.env.REACT_APP_UNLEASH_PROXY_CLIENT_KEYS ??
    UNLEASH_CONFIG_FAILOVER.CLIENTKEYS,
  appName: 'xm',
  refreshInterval: 60, // how often (in seconds) the client should poll the proxy for updates.
  disableRefresh: !isUnleashEnvSetup,
}

export const unleashClient = new UnleashClient(unleashConfig)

export const API_URL = Object.freeze({
  /** ***** Versions related apis ******* */
  VERSION_LIST: ({ pageId, isArchived }) =>
    `/page/${pageId}/versions?isArchived=${isArchived}`,
  VERSION_DETAILS: ({ pageId, versionId }) =>
    `/page/${pageId}/version/${versionId}`,
  VERSION_UPDATE: ({ pageId, versionId }) =>
    `/page/${pageId}/version/${versionId}`,
  VERSION_CREATE: ({ pageId }) => `/page/${pageId}`,

  /** ** Global Elements Versions related  API's ***/
  GLOBAL_COMPONENT_VERSION_DETAILS: ({ gcId, versionId }) =>
    `/global-component/${gcId}/version/${versionId}`,
  GLOBAL_COMPONENT_VERSION_CREATE: ({ globalComponentId }) =>
    `/global-component/${globalComponentId}`,
  GLOBAL_COMPONENT_VERSION_UPDATE: ({ globalComponentId, versionId }) =>
    `/global-component/${globalComponentId}/version/${versionId}`,

  /** ** User identity related API's ****/
  AUTH_REFRESH_TOKEN: () => 'auth/local/refresh',

  /** ***** components related apis ******* */
  COMPONENT_SAVE: ({ pageId, versionId }) =>
    `page/${pageId}/version/${versionId}/components`,
  COMPONENT_REMOVE: ({ pageId, versionId, componentId }) =>
    `page/${pageId}/version/${versionId}/component/${componentId}`,

  // menu related apis
  MENU_GET: () => `/menu`,
  MENU_POST: () => `/menu`,
  MENU_UPDATE: ({ id }) => `/menu/${id}`,
  MENU_TREE_GET: ({ id }) => `/menu/tree/${id}`,
  MENU_DELETE: ({ id }) => `/menu/${id}`,
  MENU_ORDER: () => `/menu/order`,

  // v2 browse menu related apis
  V2_MENU_UPDATE: ({ menuId }) => `/v2/menu/${menuId}`,
})

export const extractData = response => {
  return get(response, 'data.data', {})
}

export const extractError = response => {
  return get(response, 'data.error', null)
}

const getStageName = () => {
  // extract first part of hostname and used to get stage name
  const domain = new URL(window.location.origin).hostname?.split('.')[0]

  return STAGE_NAME[domain]
}

const axiosInstance = (port, domain) => {
  let path
  if (domain === 'xpm') {
    path = isLocal
      ? tobyBaseApiPath
      : process.env.REACT_APP_TOBY_URL + tobyBaseApiPath
  } else {
    path = `/api-${domain}`
  }

  let baseURL
  if (domain === 'i18n') {
    baseURL = process.env.REACT_APP_I18N_URL
  } else {
    baseURL = isLocal ? `http://localhost:${port}${path}/` : path
  }

  return axios.create({
    baseURL,
    responseType: 'json',
  })
}

export const domainInstance = {
  identity: axiosInstance('5001', 'identity'),
  xpm: axiosInstance('8080', 'xpm'),
  image: axiosInstance('5005', 'image'),
  pim: axiosInstance('5010', 'pim2'),
  category: axiosInstance('5004', 'category'),
  i18n: axiosInstance(null, 'i18n'),
}

const retryRequest = request => {
  if (request.baseURL.includes('identity')) {
    return domainInstance.identity.request(request)
  } else if (request.baseURL.includes('xm')) {
    return domainInstance.xpm.request(request)
  } else if (request.baseURL.includes('category')) {
    return domainInstance.category.request(request)
  } else if (request.baseURL.includes('image')) {
    return domainInstance.image.request(request)
  } else if (request.baseURL.includes('pim')) {
    return domainInstance.pim.request(request)
  }
}

const ErrorResponse = async error => {
  if (retryCount >= MAX_RETRY_COUNT) {
    redirectToSignInPage()
    return
  }
  if (
    retryCount < MAX_RETRY_COUNT &&
    error &&
    error.response &&
    error.response.status === httpStatusCodes.FORBIDDEN
  ) {
    try {
      retryCount = retryCount + 1
      const { data } = await domainInstance.identity.post(
        API_URL.AUTH_REFRESH_TOKEN(),
        {
          refreshToken: localStorage.getItem('refreshToken'),
        }
      )
      if (data.accessToken) {
        const { accessToken, refreshToken } = data
        localStorage.setItem('refreshToken', refreshToken)
        sessionStorage.setItem('accessToken', accessToken)
        const request = {
          ...error.config,
          headers: {
            ...error.config.headers,
            Authorization: accessToken,
          },
        }
        retryRequest(request)
        return
      } else {
        return Promise.reject(error)
      }
    } catch (err) {
      redirectToSignInPage()
    }
  }
  return Promise.reject(error)
}

const getCookieConfiguration = () => {
  if (!document?.cookie) {
    return null
  }

  const cookieConfig = {}
  for (const cookieName of EXPERIMENTAL_COOKIES) {
    if (isKeyInCookieString(cookieName)) {
      cookieConfig[cookieName] = getCookieValue(cookieName)
    }
  }
  return cookieConfig
}

const modifyRequestPerCookie = (
  request,
  experimentalCookie,
  experimentalCookieValue
) => {
  const modifiedRequest = { ...request }
  if (experimentalCookie === SHADOW_RELEASE_STAGE) {
    const newBaseUrl = request.baseURL.replace(
      XM_API_STAGE_NAME,
      experimentalCookieValue
    )
    console.log('------------------ENTERING SHADOW MODE------------------')
    console.log(`Testing ${newBaseUrl}`)
    console.log('****To restore, remove shadowReleaseStage from cookies****')
    modifiedRequest.baseURL = newBaseUrl
  }

  return modifiedRequest
}

const interceptorsRequest = axiosDomainInstance => {
  axiosDomainInstance.interceptors.request.use(async request => {
    // ===== START: REST to GraphQL Proxy =====
    const { headers, url } = request
    request.metadata = { startTime: new Date() }
    if (
      unleashClient.isEnabled(FF_NAMES.unleashFFs.USE_GRAPHQL) ||
      unleashClient.isEnabled(FF_NAMES.unleashFFs.I18N)
    ) {
      const graphQLFnId = graphQLProxy.fetchGraphQLFnId(headers)
      if (graphQLFnId) {
        console.log(`Routing REST request to GraphQL for: ${url}`)
        try {
          request = graphQLProxy.transformRestToGraphQlRequest(
            request,
            graphQLFnId
          )
          console.log('-------')
        } catch (e) {
          console.log(`Error routing ${url} to GraphQL, defaulting to REST`)
          console.error(e)
          console.log('-------')
        }
      }
    }
    // ===== END: REST to GraphQL Proxy =====

    const cookieConfig = getCookieConfiguration()
    if (cookieConfig) {
      Object.keys(cookieConfig).forEach(experimentalCookie => {
        request = modifyRequestPerCookie(
          request,
          experimentalCookie,
          cookieConfig[experimentalCookie]
        )
      })
    }

    if (window?.sessionStorage.getItem('accessToken')) {
      request.headers.Authorization = window?.sessionStorage.getItem(
        'accessToken'
      )
    } else if (isLocal) {
      request.headers.Authorization = 'local'
    }

    // Browse Menu using old endpoint
    // Can be removed once browse menu is live in toby
    if (isBrowseMenuRequest(request?.url)) {
      request.baseURL = isLocal ? 'http://localhost:5004/api-xpm' : `/api-xpm`
    }

    const account = getAccountId()
    request.headers['x-site-context'] = JSON.stringify({
      channel: 12,
      account,
      stage: getStageName(),
    })
    return request
  })
}
const interceptorsResponse = axiosDomainInstance => {
  axiosDomainInstance.interceptors.response.use(response => {
    try {
      const { config } = response
      if (config.headers.ReroutedToGraphQL) {
        const requestData = JSON.parse(get(response, 'config.data'))
        const hasGraphQLErrors = !!get(response, 'data.errors')
        if (!hasGraphQLErrors) {
          const responseData = get(response, 'data.data')
          const { variables } = requestData
          const { transformOperationKey } = variables
          console.log(
            'Receiving response from GraphQL for:',
            transformOperationKey
          )
          const newData = graphQLProxy.transformGraphqlToRestRes(
            responseData,
            variables,
            transformOperationKey
          )
          response.data = newData
          console.log(
            'Successfully rerouted GraphQL response to REST response for:',
            transformOperationKey
          )
          console.log('-------')
        }
      }
      response.config.metadata.endTime = new Date()
      response.duration =
        response.config.metadata.endTime - response.config.metadata.startTime
    } catch (e) {
      console.log(`Error routing to GraphQL, defaulting to REST`)
      console.error(e)
      console.log('-------')
    }
    return response
  }, ErrorResponse)
}

Object.keys(domainInstance).forEach(domain => {
  interceptorsRequest(domainInstance[domain])
  interceptorsResponse(domainInstance[domain])
})

export default domainInstance
