export const XM_EDIT_DESCRIPTORS_ROLE = [
  'xm:v1:universalDescriptors:create',
  'xm:v1:universalDescriptors:update',
  'xm:v1:universalDescriptors:delete',
]

export const XM_EDIT_PAGE_ROLE = [
  'xm:v1:pages:create',
  'xm:v1:pages:update',
  'xm:v1:pages:delete',
  'xm:v1:pages:archive',
]

export const XM_EDIT_GLOBAL_ELEMENTS = [
  'xm:v1:globalElements:delete',
  'xm:v1:globalElements:archive',
]

export const XM_EDIT_MENU_ROLE = [
  'xm:v1:menus:create',
  'xm:v1:menus:update',
  'xm:v1:menus:delete',
]

export const XM_VIEWER_ROLE = [
  'xm:v1:pages:read',
  'xm:v1:globalElements:read',
  'xm:v1:menus:read',
  'xm:v1:universalDescriptors:read',
]

export const XM_EDITOR_ROLE = [
  'xm:v1:pages:create',
  'xm:v1:menus:create',
  'xm:v1:globalElements:create',
  'xm:v1:pages:update',
  'xm:v1:menus:update',
  'xm:v1:globalElements:update',
  'xm:v1:pages:delete',
  'xm:v1:menus:delete',
  'xm:v1:globalElements:delete',
  'xm:v1:pages:archive',
  'xm:v1:menus:archive',
  'xm:v1:globalElements:archive',
]

export const XM_PUBLISHER_ROLE = [
  'xm:v1:universalDescriptors:create',
  'xm:v1:universalDescriptors:update',
  'xm:v1:pages:publish',
  'xm:v1:globalElements:publish',
  'xm:v1:menus:publish',
]
