import IconBack from 'assets/icons/IconBack'
import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledWrapperHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  column-gap: 1rem;

  [data-testid='link-go-back'] {
    display: flex;
    align-items: center;
    width: fit-content;
    font-size: ${({ theme }) => theme.typography.fontSize.xs};
    line-height: 14px;
    cursor: pointer;
  }
`

export const StyledIconBack = styled(IconBack)`
  width: 16px;
  height: 16px;
  cursor: pointer;
`

export const StyledIconCaretDownWrapper = styled.span`
  background: #121213;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  display: grid;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg.icon-filled-arrow-down {
    width: 8px;
    height: 8px;
    fill: ${themeUi.palette.brand.primary.white};
    margin-top: 2px;
  }
  &.active {
    background: ${themeUi.palette.ui.cta.blue};
  }
`
export const StyledTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${themeUi.typography.h4};
  padding: 24px 0px;
`

export const StyledStatusWrapper = styled.div`
  display: column;
`

export const StyledStatus = styled.div`
  color: ${themeUi.palette.brand.primary.gray};
  ${themeUi.typography.h6};
  background-color: ${themeUi.palette.ui.neutral.grey4};
  border: 1px solid ${themeUi.palette.ui.neutral.grey3};
  border-radius: 60px;
  padding: 4px 8px;
  align-items: center;
  display: inline-block;
  margin-top: 15px;

  .circle {
    margin-right: 5px;
    height: ${({ theme }) => theme.space.space_3};
    width: ${({ theme }) => theme.space.space_3};
    border-radius: ${({ theme }) => theme.borderRadius.rounded};
    display: inline-block;
  }

  .live {
    background-color: ${themeUi.palette.ui.cta.green};
  }

  .scheduled {
    background-color: ${themeUi.palette.ui.states.hover};
  }

  .ended {
    background-color: ${themeUi.palette.ui.neutral.grey7};
  }

  .draft {
    background-color: tranparent;
    border: 1.5px solid ${themeUi.palette.ui.neutral.grey1};
  }
`

export const StyledStatusNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${themeUi.typography.h4};
  padding: 10px 0px;
`
