import {
  EDITOR_IS_DELETING,
  EDITOR_ONCHANGE,
  EDITOR_REMOVE_COMPONENT,
  EDITOR_REORDER_COMPONENT,
  EDITOR_UPDATE_COMPONENT,
} from 'modules/editor/actions'
import { StyledBreadCrumb, StyledComponent } from './styles'
import { connect, useDispatch, useSelector } from 'react-redux'
import { findIndex, isEmpty, isPlainObject } from 'lodash'
import {
  getCuratedComponents,
  getDeletePayload,
  getDescriptors,
  getIsDeletingComponent,
  getSelectedComponent,
  getSelectedDescriptor,
  getSelectedRoute,
} from 'modules/editor/selectors'
import AddComponent from '../AddComponent'
import ConfirmDialog from 'components/ConfirmDialog'
import { EDITOR_DELETE_MODAL } from 'src/constants'
import EditingPanelBreadcrumb from 'components/EditingPanelBreadcrumb'

import PropTypes from 'prop-types'
import React from 'react'
import SidebarHeader from './SidebarHeader'
import SortableComponent from 'components/SortableComponent'

import { bindActionCreators } from 'redux'
import { getActiveLanguage } from 'store/i18n/selectors'
import getHasEditAccessBasedOnVersionStatus from 'lib/getHasEditAccessBasedOnVersionStatus'
import { isGcEditorPage } from 'modules/editor/utils'
import noop from 'lodash/noop'
import { useLocation } from '@reach/router'
import { useUserPermissions } from 'contexts/userPermissions'

const SideBarComponents = props => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const {
    descriptor,
    descriptors,
    selectedRoute,
    versionStatus,
    versionName,
    selectedComponent,
    curatedComponents,
    onChange,
    onEditorOptionClick,
    handleOnUpdateComponent,
    handleOnReorderComponent,
    handleOnDeleteComponent,
    isEditorLoading,
  } = props

  const key = selectedComponent?.key
  const componentIdx = findIndex(curatedComponents, { key })
  const isGCEditorPagePath = isGcEditorPage(pathname)

  const isDeletingComponent = useSelector(getIsDeletingComponent)
  const deletePayload = useSelector(getDeletePayload)
  const activeLang = useSelector(getActiveLanguage)
  const locales = selectedRoute?.locales ?? null
  const hasMultipleLocales = locales && locales?.length > 1

  const { hasEditorPermissions, hasPublisherPermissions } = useUserPermissions()
  const hasEditAccess = getHasEditAccessBasedOnVersionStatus(
    { hasEditorPermissions, hasPublisherPermissions },
    versionStatus
  )

  const {
    getTitle,
    content,
    cancelText,
    submitText,
    localesContent,
  } = EDITOR_DELETE_MODAL

  const handleCloseModal = () => {
    dispatch(
      EDITOR_IS_DELETING({
        isDeletingComponent: false,
        deletePayload: null,
      })
    )
  }

  const handleOnDeleteClick = () => {
    if (deletePayload.isParentComponent) {
      // Remove Parent Component
      handleOnDeleteComponent({
        id: deletePayload.id,
        order: deletePayload.order,
      })
    } else {
      // Remove Child Array Component or Nested Component
      onChange({
        paths: deletePayload.paths,
        value: deletePayload.value,
        deleteIndex: deletePayload?.deleteIndex,
        activeLang,
      })
    }
    handleCloseModal()
  }

  const showAddComponent =
    !isEditorLoading &&
    (typeof selectedComponent === 'undefined' ||
      descriptor?.allowNestedComponents)

  return (
    <>
      {isDeletingComponent && (
        <ConfirmDialog
          isVisible={true}
          title={getTitle(deletePayload.label)}
          content={content + (hasMultipleLocales ? ` ${localesContent}` : '')}
          submitText={submitText}
          onSubmit={handleOnDeleteClick}
          cancelText={cancelText}
          onCancel={handleCloseModal}
        />
      )}
      <SidebarHeader
        versionStatus={versionStatus}
        versionName={versionName}
        onEditorOptionClick={onEditorOptionClick}
        selectedRoute={selectedRoute}
        isGCEditorPagePath={isGCEditorPagePath}
      />
      <StyledBreadCrumb>
        <EditingPanelBreadcrumb
          selectedComponent={selectedComponent}
          handleOnUpdateComponent={handleOnUpdateComponent}
        />
      </StyledBreadCrumb>
      <StyledComponent>
        <SortableComponent
          descriptor={descriptor}
          descriptors={descriptors}
          selectedComponent={selectedComponent}
          curatedComponents={curatedComponents}
          isGlobalComponentEditor={isGCEditorPagePath}
          handleOnUpdateComponent={handleOnUpdateComponent}
          handleOnReorderComponent={handleOnReorderComponent}
          paths={['curatedComponents']}
          value={curatedComponents}
          onChange={payload => onChange({ ...payload, activeLang })}
        />
      </StyledComponent>
      {showAddComponent && (hasEditAccess || hasPublisherPermissions) && (
        <AddComponent
          curatedComponents={curatedComponents}
          isGCEditorPagePath={isGCEditorPagePath}
          paths={
            isPlainObject(selectedComponent) && !isEmpty(selectedComponent)
              ? ['curatedComponents', componentIdx, 'params', 'components']
              : ['curatedComponents']
          }
        />
      )}
    </>
  )
}

SideBarComponents.defaultProps = {
  selectedRoute: {},
  onEditorOptionClick: noop,
  curatedComponents: [],
  descriptor: {},
  descriptors: [],
  handleOnUpdateComponent: noop,
  handleOnReorderComponent: noop,
  handleOnDeleteComponent: noop,
  onChange: noop,
  versionStatus: '',
  versionName: '',
  location: {},
  navigate: noop,
  isEditorLoading: true,
}

SideBarComponents.propTypes = {
  selectedRoute: PropTypes.object.isRequired,
  onEditorOptionClick: PropTypes.func.isRequired,
  curatedComponents: PropTypes.array.isRequired,
  descriptor: PropTypes.object.isRequired,
  descriptors: PropTypes.array.isRequired,
  selectedComponent: PropTypes.object,
  handleOnUpdateComponent: PropTypes.func.isRequired,
  handleOnReorderComponent: PropTypes.func.isRequired,
  handleOnDeleteComponent: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  versionStatus: PropTypes.string,
  versionName: PropTypes.string,
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isEditorLoading: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  selectedRoute: getSelectedRoute(state),
  curatedComponents: getCuratedComponents(state),
  selectedComponent: getSelectedComponent(state),
  descriptors: getDescriptors(state),
  descriptor: getSelectedDescriptor(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onChange: EDITOR_ONCHANGE,
      handleOnUpdateComponent: EDITOR_UPDATE_COMPONENT,
      handleOnReorderComponent: EDITOR_REORDER_COMPONENT,
      handleOnDeleteComponent: EDITOR_REMOVE_COMPONENT,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(SideBarComponents)
