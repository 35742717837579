import { gql } from '@apollo/client'

export const SCHEDULE_VARIANT = gql`
  mutation SchedulePublish($input: SchedulePublishInput!) {
    schedulePublish(input: $input) {
      contentId
      variantId
      status
    }
  }
`
