import {
  ManageNodeFormValues,
  ManageNodeGraphQLInputVariables,
} from 'modules/browse-menu-tree-gql/components/ManageNodeView/types'
import { ListMenuResponse } from 'services/graphql/types'
import { MenuTreeNode } from 'modules/browse-menu-tree-gql/types'

export const transformManageNodeFormVariables = (
  formValues: ManageNodeFormValues
): ManageNodeGraphQLInputVariables => {
  const {
    nodeId,
    variantId,
    parentId,
    nodeName,
    attributes,
    url,
    images,
  } = formValues
  return {
    input: {
      variantId,
      parentId,
      nodeId,
      name: nodeName,
      url,
      images: (images ?? []).map(i => ({
        url: i.url,
        altText: i.altText,
        locales: i.locales,
        description: i?.description,
        name: i?.name,
      })),
      attributes: (attributes ?? []).map(attr => ({
        kind: attr.kind,
        value: attr.value,
      })),
    },
  } as ManageNodeGraphQLInputVariables
}

export const isActiveNode = ({
  activeNodes,
  nodeId,
}: {
  activeNodes: MenuTreeNode[]
  nodeId: string
}): boolean => {
  return !!activeNodes.find(activeNode => activeNode._id === nodeId)
}

export const getCurrentMenuLocales = ({
  data,
  menuId,
}: {
  data: ListMenuResponse
  menuId: string
}): string[] => {
  return data?.menus?.edges?.find(menu => menu.node.id === menuId)?.node
    ?.locales
}
