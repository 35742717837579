import { PublishMenuVariantResponse } from 'modules/browse-menu-tree-gql/types'

export enum BROWSE_MENU_TREE_MODAL_ID {
  GRAPH_CONFLICT = 'GRAPH_CONFLICT',
  PUBLISH = 'PUBLISH',
}

export type BrowseMenuTreeModuleContext = {
  menuId: string
  versionId: string
  closeModal: () => void
  updatedMenuVariant: (
    data: PublishMenuVariantResponse['immediatePublish']
  ) => void
}

export type PublishOperationInput = {
  variables: {
    menuId: string
    versionId: string
  }
}

export type BrowseMenuTreeOperations = {
  publishMenu: (input: PublishOperationInput) => Promise<void>
}
