import { DEFAULT_TOAST_DURATION } from 'src/constants'
import React from 'react'
import ReactDOM from 'react-dom'
import { TOAST_CLASS_NAME } from './constants'
import ToastSnackbar from 'components/ToastSnackbar/index.js'
import { nanoid } from 'nanoid'

export const showToast = ({
  message,
  kind,
}: {
  message: string
  kind: string
}): Promise<void> => {
  const div = document.createElement('div')
  const toastId = nanoid()
  div.className = TOAST_CLASS_NAME
  div.id = toastId

  ReactDOM.render(
    <ToastSnackbar
      message={message}
      kind={kind}
      visible={true}
      toastId={toastId}
    />,
    document.body.appendChild(div)
  )

  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, DEFAULT_TOAST_DURATION)
  })
}
