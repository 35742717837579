import { theme as themeUi } from '@teamfabric/copilot-ui'
/* eslint-disable */
const theme = {
  colors: {
    white: '#ffffff',
    black: {
      100: '#6c6b89',
      200: '#545275',
      300: '#3b3961',
      400: '#23214e',
      500: '#0c053d', // russian-violet-dark
      600: '#090734',
      700: '#08062e',
      800: '#070629',
      900: '#060523',
    },
    gray: {
      100: '#f3f3f5',
      200: '#e6e8eb',
      300: '#c4c9d1',
      400: '#8994a3',
      500: '#5b687a',
      600: '#525e6e',
      700: '#495362',
      800: '#404955',
      900: '#373e49',
    },
    red: {
      100: '#ff8e99',
      101: '#ff8e9966',
      200: '#ff7b88',
      300: '#ff6977',
      400: '#ff5666',
      500: '#ff4355',
      600: '#e63c4d',
      700: '#cc3644',
      800: '#b32f3b',
      900: '#992833',
    },
    blue: {
      100: '#8794e0',
      200: '#7382db',
      300: '#5f70d6',
      400: '#4b5ed1',
      500: '#374CCC',
      600: '#3244b8',
      700: '#2c3da3',
      800: '#27358f',
      900: '#212e7a',
    },
    green: {
      100: '#66e8cb',
      200: '#4de4c2',
      300: '#33e1b9',
      400: '#1addb1',
      500: '#00D9A8',
      600: '#00c397',
      700: '#00ae86',
      800: '#009876',
      900: '#008265',
    },
  },
  boxShadow: {
    'xs': '0 0 0 1px rgba(0, 0, 0, 0.05)',
    'sm': '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    'default':
      '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    'md':
      '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    'lg':
      '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    'lgSpread': 'rgba(115, 127, 143, 0.1) 0px 5px 20px',
    'xl':
      '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
    'inner': 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    'outline': '0 0 0 3px rgba(66, 153, 225, 0.5)',
    'none': 'none',
  },
  typography: {
    fontFamily: {
      sans: `'Gilroy', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'`,
      serif: `'Georgia', 'Cambria', '"Times New Roman"', 'Times', 'serif'`,
      mono: `'Menlo', 'Monaco', 'Consolas', '"Liberation Mono"', '"Courier New"', 'monospace'`,
    },
    fontSize: {
      'xs': '0.75rem', // 12px
      'sm': '0.875rem', // 14px
      'base': '1rem', // 16px
      'lg': '1.125rem', // 18px
      'xl': '1.25rem', // 20px
      '2xl': '1.5rem', // 24px
      '3xl': '1.875rem', // 30px
      '4xl': '2.25rem', // 36px
      '5xl': '3rem', // 48px
      '6xl': '4rem', // 64px
    },
    fontWeight: {
      hairline: '100',
      thin: '200',
      light: '300',
      normal: '400',
      medium: '500',
      semibold: '600',
      bold: '700',
      extrabold: '800',
      black: '900',
    },
    fontStyle: {
      normal: 'normal',
      italic: 'italic',
      oblique: 'oblique',
    },
    letterSpacing: {
      tighter: '-0.05em',
      tight: '-0.025em',
      normal: '0',
      wide: '0.025em',
      wider: '0.05em',
      widest: '0.1em',
    },
    lineHeight: {
      none: '1',
      tight: '1.25',
      snug: '1.375',
      normal: '1.5',
      relaxed: '1.625',
      loose: '2',
      3: '.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      7: '1.75rem',
      8: '2rem',
      9: '2.25rem',
      10: '2.5rem',
    },
    get h1() {
      return {
        fontSize: this.fontSize['5xl'],
        fontWeight: this.fontWeight.extrabold,
      }
    },
    get h2() {
      return {
        fontSize: this.fontSize['4xl'],
        fontWeight: this.fontWeight.semibold,
      }
    },
    get h3() {
      return {
        fontSize: this.fontSize['2xl'],
        fontWeight: this.fontWeight.semibold,
        lineHeight: this.lineHeight[2],
      }
    },
    get h4() {
      return {
        fontSize: this.fontSize.lg,
        fontWeight: this.fontWeight.semibold,
        lineHeight: this.lineHeight[6],
      }
    },
    get h5() {
      return {
        fontSize: this.fontSize.sm, // 14px
        fontWeight: this.fontWeight.semibold,
        lineHeight: this.lineHeight[5], // 20px
      }
    },
    get h6() {
      return {
        fontSize: this.fontSize.xs,
        fontWeight: this.fontWeight.semibold,
        lineHeight: this.lineHeight.tight,
      }
    },
    get bodyLarge() {
      return {
        fontSize: this.fontSize.base,
        fontWeight: this.fontWeight.light,
      }
    },
    get body() {
      return {
        fontSize: this.fontSize.sm,
        lineHeight: this.lineHeight.normal,
      }
    },
    get bodySmall() {
      return {
        fontSize: this.fontSize.xs,
      }
    },
    get labelLarge() {
      return {
        fontSize: this.fontSize.lg,
      }
    },
    get label() {
      return {
        fontSize: this.fontSize.sm,
        fontWeight: this.fontWeight.semibold,
      }
    },
    get labelSmall() {
      return {
        fontSize: this.fontSize.xs,
        fontWeight: this.fontWeight.semibold,
      }
    },
    get content() {
      return {
        fontSize: this.fontSize.base,
      }
    },
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  zIndex: {
    basic: 1,
    hoverOver: 1000,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  borderRadius: {
    none: '0rem', // 0px
    sm: '0.125rem', // 2px
    default: '0.25rem', // 4px
    md: '0.375rem', // 6px
    lg: '0.5rem', // 8px
    xl: '1rem', // 16px
    huge: '1.5rem', // 24px
    rounded: '50%',
  },
  space: {
    space_1: '0rem', // 0px
    space_2: '0.25rem', // 4px
    space_3: '0.50rem', // 8px
    space_4: '0.75rem', // 12px
    space_5: '1rem', // 16px
    space_6: '2rem', // 32px
    space_7: '3rem', // 48px
    space_8: '4rem', // 64px
    space_9: '5rem', // 80px
  },
  constants: {
    barHeight: '55px',
    sideEditorWidth: '463px',
    sidebarHugeWidth: '320px',
    sidebarExpandedWidth: '244px',
    sidebarCollapsedWidth: '48px',
    dialogWidth: '466px',
    menuTreeContextMenu: '198px',
  },
  snackbar: {
    success: themeUi.palette.brand.primary.charcoal,
    error: themeUi.palette.ui.cta.red,
    width: '640px',
  },
  button: {
    theme: {
      light: 'light',
      dark: 'dark',
    },
  },
}

export default theme
