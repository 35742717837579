import React, { memo } from 'react'
import {
  StyledModal,
  StyledModalBody,
  StyledModalClose,
  StyledModalContent,
  StyledModalDialog,
  StyledModalTitle,
} from './styles'
import IconClose from 'assets/icons/IconClose'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { noop } from 'lodash'
import { theme as themeUi } from '@teamfabric/copilot-ui'

const Modal = props => {
  const {
    backdrop,
    children,
    isVisible,
    title,
    width,
    onClose,
    portalize,
    portalTarget,
    showClose,
    customClass,
  } = props

  React.useEffect(() => {
    const keydownHandler = ({ key }) => {
      if (key === 'Escape' && isVisible) onClose()
    }

    document.addEventListener('keydown', keydownHandler)
    return () => document.removeEventListener('keydown', keydownHandler)
  })

  const renderContent = () => {
    return (
      <StyledModal
        onClick={backdrop ? onClose : null}
        className={customClass}
        data-testid='modal'
      >
        <StyledModalDialog
          width={width}
          onClick={e => e.stopPropagation()}
          className='modal-dialog'
        >
          {showClose && (
            <StyledModalClose onClick={onClose}>
              <IconClose fill={themeUi.palette.brand.primary.gray} />
            </StyledModalClose>
          )}
          <StyledModalBody>
            <StyledModalContent className='modal-content'>
              {title && (
                <StyledModalTitle data-testid='modal-title'>
                  {title}
                </StyledModalTitle>
              )}
              {children}
            </StyledModalContent>
          </StyledModalBody>
        </StyledModalDialog>
      </StyledModal>
    )
  }

  if (!isVisible) {
    return null
  }

  if (typeof window !== 'undefined' && portalize) {
    const targetNode = document.querySelector(portalTarget)
    return createPortal(renderContent(), targetNode)
  }

  return renderContent()
}

Modal.defaultProps = {
  backdrop: true,
  children: null,
  isVisible: false,
  title: '',
  width: '520px',
  onClose: noop,
  portalize: false,
  portalTarget: 'body',
  showClose: true,
  customClass: '',
}

Modal.propTypes = {
  backdrop: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool.isRequired,
  title: PropTypes.string,
  width: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  portalize: PropTypes.bool,
  portalTarget: PropTypes.string,
  showClose: PropTypes.bool,
  customClass: PropTypes.string,
}

export default memo(Modal)
