import styled from 'styled-components'
import { theme } from '@teamfabric/copilot-ui'

export const StyledWrapper = styled.div`
  padding: ${props => props.theme.space.space_6} 0;
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: 80% 1fr;
  .no-search-results:before {
    content: 'No results found';
    ${theme.typography.h6.css};
  }
  label.input__parent {
    padding-top: 0px !important;
  }
  [name='page-search'] {
    padding-left: 35px !important;
  }
  .ac-padding {
    padding-right: 1em;
  }
  .input__wrapper {
    border-bottom: 0 !important;

    .icon {
      position: absolute !important;
      padding-left: 0px !important;
      top: 30% !important;
    }
  }
`

export const StyledNoContentWrapper = styled.div`
  text-align: center;
  width: 500px;
  height: 500px;
  margin: 66px auto 0 auto;
`
export const StyledTitle = styled.div`
  margin-bottom: 32px;
  color: ${theme.palette.brand.primary.gray};
`
export const StyledMessage = styled.p`
  margin: 0 auto;
  ${theme.typography.subtitle2.css};
  color: ${theme.palette.brand.primary.charcoal};
  margin-bottom: 46px;
`
