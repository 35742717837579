import { DropdownChips } from '@teamfabric/copilot-ui/dist/organisms'
import { FilterByProps } from './types'
import React from 'react'
import { StyledWrapper } from './styles'

const DROPDOWN_CHIP_WIDTH = '200px'

const FilterBy: React.FC<FilterByProps> = props => {
  const {
    chipsName,
    multiSelectOptions,
    showClearButton = false,
    onChange,
    onClear,
  } = props

  const dataTestId = chipsName?.replace(/\s+/g, '-').toLowerCase()

  return (
    <StyledWrapper data-testid={`filter-by-${dataTestId}`}>
      <DropdownChips
        chipsType='multi-select'
        placement='bottomCenter'
        chipsName={chipsName}
        multiSelectOptions={multiSelectOptions}
        showClearButton={showClearButton}
        flyoutWidth={DROPDOWN_CHIP_WIDTH}
        width={DROPDOWN_CHIP_WIDTH}
        onChange={onChange}
        onClear={onClear}
      />
    </StyledWrapper>
  )
}

export default FilterBy
