import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'
export const StyledConfirmDialog = styled.div``

export const StyledConfirmDialogContent = styled.div`
  ${themeUi.typography.h5.css};
  margin: ${props => props.theme.space.space_6} 0;
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  & > button:first-child {
    margin-right: ${props => props.theme.space.space_5};
  }
`

export const StyledModalTitle = styled.h3`
  margin: 0;
  ${themeUi.typography.h4.css};
  font-weight: bold;
  margin-bottom: 16px;
`
