import { StyledMessage, StyledNoContentWrapper, StyledTitle } from './styles'

import { Link } from '@teamfabric/copilot-ui'
import { PropTypes } from 'prop-types'
import React from 'react'

export const EmptyTableView = ({
  columns,
  primaryText,
  secondaryText,
  buttonProps,
}) => {
  return (
    <tbody>
      <tr>
        <td colSpan={columns.length + 1}>
          <StyledNoContentWrapper data-testid='empty-table-view'>
            <StyledTitle>{primaryText}</StyledTitle>
            <StyledMessage>{secondaryText}</StyledMessage>
            {buttonProps && (
              <Link
                {...{
                  type: 'primary',
                  ...buttonProps,
                }}
              />
            )}
          </StyledNoContentWrapper>
        </td>
      </tr>
    </tbody>
  )
}

EmptyTableView.defaultProps = {
  className: '',
  secondaryText: '',
  buttonProps: null,
  footerContent: null,
}

EmptyTableView.propTypes = {
  columns: PropTypes.array.isRequired,
  primaryText: PropTypes.string,
  className: PropTypes.string,
  secondaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  buttonProps: PropTypes.object,
  footerContent: PropTypes.any,
}
export default EmptyTableView
