import {
  ADD_LOCALIZED_TITLE,
  MANAGE_NODE_SIDEBAR_LABEL,
  NODE_INPUT_CLASSNAME,
} from 'src/constants'
import { FORM_FIELD_NAMES, ManageNodeFormValues } from './types'
import React, { FunctionComponent } from 'react'
import {
  StyledNodeInfoHeaderWrapper,
  StyledNodeInfoSectionWrapper,
} from './styles'
import { CommonInputFieldProps } from 'components/FormDialog/common/versions/VersionDetailsDialog/types'
import { DEFAULT_LOCALE } from 'modules/browse-menu-tree-gql/constants'
import ExpandableCard from 'components/ExpandableCard'
import { FormikProps } from 'formik'
import { getFormComponents } from 'components/ContentModalModule/utils'
import { getVersionStatus } from 'modules/editor/selectors'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import { useVersionBasedEditAccess } from 'hooks/useVersionBasedEditAccess'
const { LINK, ROOT_NODE } = MANAGE_NODE_SIDEBAR_LABEL

type NodeInformationSectionTypes = {
  title: string
  locales: Array<string>
  selectedLocale: string
}
const NodeInformationSection: FunctionComponent<
  FormikProps<ManageNodeFormValues> & NodeInformationSectionTypes
> = props => {
  const { values, title, locales, selectedLocale } = props
  const isLocalizationEnabled = !isEmpty(locales)
  const menuVersionStatus = useSelector(getVersionStatus) as string
  const hasEditAccess = useVersionBasedEditAccess(menuVersionStatus)
  const formInputFields: Array<CommonInputFieldProps> = [
    {
      type: 'Input',
      label: ROOT_NODE,
      className: NODE_INPUT_CLASSNAME,
      value: values[FORM_FIELD_NAMES.rootNodeName][selectedLocale],
      dataTestId: FORM_FIELD_NAMES.rootNodeName,
      name: FORM_FIELD_NAMES.rootNodeName,
      disabled: true,
    },
    {
      type: 'Input',
      label: LINK,
      className: NODE_INPUT_CLASSNAME,
      value: values[FORM_FIELD_NAMES.url],
      dataTestId: FORM_FIELD_NAMES.url,
      name: FORM_FIELD_NAMES.url,
      disabled: !hasEditAccess,
    },
  ]

  if (!isLocalizationEnabled) {
    formInputFields.unshift({
      type: 'Input',
      label: MANAGE_NODE_SIDEBAR_LABEL.NODE_TITLE,
      className: NODE_INPUT_CLASSNAME,
      value: values[FORM_FIELD_NAMES.nodeName][DEFAULT_LOCALE],
      dataTestId: `${DEFAULT_LOCALE}-node-name-input`,
      name: `${FORM_FIELD_NAMES.nodeName}.${DEFAULT_LOCALE}`,
      disabled: !hasEditAccess,
    })
  }

  return (
    <>
      <StyledNodeInfoHeaderWrapper>
        <div className='node-name'>{title.toUpperCase()}</div>
      </StyledNodeInfoHeaderWrapper>
      <StyledNodeInfoSectionWrapper>
        <div>{values[FORM_FIELD_NAMES.nodeName][selectedLocale]}</div>
        {isLocalizationEnabled && (
          <ExpandableCard heading={ADD_LOCALIZED_TITLE}>
            {getFormComponents({
              components: locales.map(locale => ({
                type: 'Input',
                label: MANAGE_NODE_SIDEBAR_LABEL.NODE_TITLE,
                className: NODE_INPUT_CLASSNAME,
                value: values[FORM_FIELD_NAMES.nodeName][locale],
                dataTestId: `${locale}-node-name-input`,
                name: `${FORM_FIELD_NAMES.nodeName}.${locale}`,
                disabled: !hasEditAccess,
                locale: locale,
                localeCode: locale,
              })),
              mode: null,
              ...props,
            })}
          </ExpandableCard>
        )}
        {getFormComponents({
          components: formInputFields,
          mode: null,
          ...props,
        })}
      </StyledNodeInfoSectionWrapper>
    </>
  )
}

export default NodeInformationSection
