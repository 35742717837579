import { CuratedComponentProps } from 'store/types'
import { VersionInfoPayloadProps } from 'modules/editor/types'
import eventTypes from './eventTypes'
import { isIFrame } from './utils'
import throttle from 'lodash/throttle'

const WAIT_DURATION = 250

export const sendPreviewEvent = ({
  type,
  payload,
  versionInfoPayload,
}: {
  type: string
  versionInfoPayload?: VersionInfoPayloadProps
  payload?: CuratedComponentProps | CuratedComponentProps[]
}): void => {
  const iframe = document.getElementById('previewIframe')
  const isValidEventType = Object.values(eventTypes).includes(type)

  console.group('sending preview event')
  console.log('[iframe]', iframe)
  console.log('[type]', type)
  let payloadToSend
  if (payload) {
    console.log('[payload]', payload)
    payloadToSend = payload
  } else if (versionInfoPayload) {
    console.log('[payload]', versionInfoPayload)
    payloadToSend = versionInfoPayload
  }
  console.groupEnd()

  if (iframe && isIFrame(iframe) && isValidEventType) {
    const $window = iframe.contentWindow
    const message = JSON.stringify({
      type,
      payload: payloadToSend as CuratedComponentProps[],
    })
    $window.postMessage(message, '*')
  }
}

export const sendPreviewEventThrottled = throttle(
  sendPreviewEvent,
  WAIT_DURATION
)
