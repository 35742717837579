import { useEffect, useState } from 'react'
import { FF_NAMES } from 'src/constants'
import { rbac } from '@teamfabric/copilot-utilities'
import { useFlag } from '@unleash/proxy-client-react'

export const useRbacPermission = (roles: string[]): boolean => {
  const useRbac = useFlag(FF_NAMES.unleashFFs.USE_RBAC)
  const [userHasAccess, setHasAccess] = useState<boolean>(false)

  useEffect(() => {
    if (!useRbac) {
      setHasAccess(true)
      return
    }

    const { hasAccess } = rbac.checkPermission({
      allowedPermissions: roles,
    }) as { hasAccess: boolean }

    setHasAccess(useRbac && hasAccess)
  }, [roles, useRbac])
  return userHasAccess
}
