import { Icon, Tooltip, theme as themeUi } from '@teamfabric/copilot-ui'
import { ADD_NODE_TOOLTIP } from 'src/constants'
import { ActionButtonProps } from './types'
import { DEFAULT_ICON_SIZE } from '../../constants'
import React from 'react'
import { StyledActionButton } from './styles'
import { ThemeProps } from 'store/types'

const ActionButton = ({
  type,
  id,
  depth,
  size = DEFAULT_ICON_SIZE,
  handleOnClickActionButton,
}: ActionButtonProps): JSX.Element => {
  return (
    <StyledActionButton
      data-testid={`action-button-${depth}-${type}`}
      onClick={() => handleOnClickActionButton(id, depth, 'node')}
    >
      <Icon
        iconName={type}
        size={size}
        fill={themeUi.palette.brand.primary.charcoal as ThemeProps}
      />
      <Tooltip text={ADD_NODE_TOOLTIP} size={'small'} offsetY={35} />
    </StyledActionButton>
  )
}

export default ActionButton
