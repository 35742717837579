import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { configInit } from 'store/configuration/actions'
import configService from 'services/api/config'
import { getAccountId } from 'lib/cookieUtils'
import { getConfiguration } from 'store/configuration/selectors'

const useLoadConfig = () => {
  const dispatch = useDispatch()
  const { loaded, accountId } = useSelector(getConfiguration)

  const fetchConfigAndDispatch = useCallback(async () => {
    const configFromDB = await configService.get()
    if (configFromDB.ok) {
      const newConfig = { config: configFromDB.ok[0] || {} }
      newConfig.config.accountId = getAccountId()

      if (configFromDB.status) {
        newConfig.config.status = configFromDB.status
      }

      dispatch(configInit(newConfig))
    } else {
      dispatch(
        configInit({
          config: {
            status: configFromDB.status,
          },
        })
      )
    }
  }, [dispatch])

  React.useEffect(() => {
    if (!loaded || getAccountId() !== accountId) {
      fetchConfigAndDispatch()
    }
  }, [dispatch, loaded, accountId, fetchConfigAndDispatch])
}

export default useLoadConfig
