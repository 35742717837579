import { CONFIG_RESET } from 'store/configuration/actions'
import store from 'src/store'

export const redirectToSignInPage = (): void => {
  store.dispatch({ type: CONFIG_RESET })
  const redirectTo = `${location.origin}/auth/login`
  location.replace(redirectTo)
}

/* Different Fetch policies as defined by Apollo - https://www.apollographql.com/docs/react/data/queries/#setting-a-fetch-policy */
export const APOLLO_FETCH_POLICIES = {
  CACHE_FIRST: 'cache-first',
  CACHE_ONLY: 'cache-only',
  CACHE_AND_NETWORK: 'cache-and-network',
  NETWORK_ONLY: 'network-only',
  NO_CACHE: 'no-cache',
  STANDBY: 'standby',
}
