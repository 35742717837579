import { FF_NAMES, NAVIGATION } from 'src/constants'
import React, { createContext, useMemo } from 'react'
import { ConfigurationProps } from 'store/types'
import { getConfiguration } from 'store/configuration/selectors'
import { useFlag } from '@unleash/proxy-client-react'
import { useSelector } from 'react-redux'

// Basepath
export const BASEPATH = '/xpm'
export const NEW_NAV_BASEPATH = '/experiences'

// Pages
export const PAGES_PATH = '/xpm/pages'

// Global Elements
export const GE_PATH = '/xpm/global-elements'

// Menu
export const MENU_V1_PATH = '/xpm/menu'
export const MENU_V2_PATH = '/xpm/browse-menu'
export const BMT_PATH = '/xpm/browse-menu-tree'

// Settings
export const SETTINGS_PATH = '/xpm/settings'

// New Navigation routes
export const NEW_NAV_PAGES_PATH = '/experiences/pages'
export const NEW_GE_PATH = '/experiences/global-elements'
export const NEW_MENU_V1_PATH = '/experiences/menu'
export const NEW_MENU_V2_PATH = '/experiences/browse-menu'
export const NEW_BMT_PATH = '/experiences/browse-menu-tree'
export const NEW_SETTINGS_PATH = '/experiences/settings'

export const useNewNavigation = () => {
  return useFlag(FF_NAMES.unleashFFs.NAVIGATION_NAME_CHANGE) ?? false
}

export const getBasePath = (flag: boolean): string => {
  return flag ? NEW_NAV_BASEPATH : BASEPATH
}

export enum NAV_LINK_ORDER {
  PAGES = 0,
  GLOBAL_ELEMENTS = 1,
  MENU = 2,
  // eslint-disable-next-line no-magic-numbers
  SETTINGS = 3,
}

const {
  unleashFFs: { USE_HYDRA, NEW_BROWSE_MENU },
} = FF_NAMES

interface NavMenuLink {
  label: string
  textLabel: string
  link: string
}

interface NavigationLinksContextProps {
  navMenuItems: NavMenuLink[]
}

const NavigationLinksContext = createContext<NavigationLinksContextProps>(null)

export const NavigationLinksProvider: React.FunctionComponent<{
  children: React.ReactNode
}> = ({ children }) => {
  const { loaded } = useSelector(getConfiguration) as ConfigurationProps
  const shouldShowSettingsUi = useFlag(USE_HYDRA) ?? false
  const shouldNavigateToNewMenu = useFlag(NEW_BROWSE_MENU) ?? false
  const navNameChange =
    useFlag(FF_NAMES.unleashFFs.NAVIGATION_NAME_CHANGE) ?? false

  const providerValue: NavigationLinksContextProps = useMemo(
    () => ({
      navMenuItems: fetchNavItems({
        loaded,
        shouldShowSettingsUi,
        shouldNavigateToNewMenu,
        navNameChange,
      }),
    }),
    [loaded, shouldShowSettingsUi, shouldNavigateToNewMenu]
  )

  return (
    <NavigationLinksContext.Provider value={providerValue}>
      {children}
    </NavigationLinksContext.Provider>
  )
}

const fetchNavItems = ({
  loaded,
  shouldShowSettingsUi,
  shouldNavigateToNewMenu,
  navNameChange,
}: {
  loaded: boolean
  shouldShowSettingsUi: boolean
  shouldNavigateToNewMenu: boolean
  navNameChange: boolean
}): NavMenuLink[] => {
  if (!loaded) {
    return []
  }

  const navItems = [
    {
      label: NAVIGATION.PAGES,
      textLabel: NAVIGATION.PAGES,
      link: navNameChange ? NEW_NAV_PAGES_PATH : PAGES_PATH,
    },
    {
      label: NAVIGATION.GLOBAL_ELEMENTS,
      textLabel: NAVIGATION.GLOBAL_ELEMENTS,
      link: navNameChange ? NEW_GE_PATH : GE_PATH,
    },
    {
      label: NAVIGATION.MENU,
      textLabel: NAVIGATION.MENU,
      link: getNavigationMenuPath(navNameChange, shouldNavigateToNewMenu),
    },
  ]

  if (shouldShowSettingsUi) {
    navItems.push({
      label: NAVIGATION.SETTINGS,
      textLabel: NAVIGATION.SETTINGS,
      link: navNameChange ? NEW_SETTINGS_PATH : SETTINGS_PATH,
    })
  }
  return navItems
}

export const useNavigationLinksContext = (): NavigationLinksContextProps => {
  const navLinks = React.useContext(NavigationLinksContext)
  if (!navLinks) {
    throw new Error(
      'Application must be wrapped within the NavigationLinksProvider to use the useNavigationLinksContext hook.'
    )
  }
  return navLinks
}

const getNavigationMenuPath = (navNameChange, shouldNavigateToNewMenu) => {
  if (navNameChange) {
    return shouldNavigateToNewMenu ? NEW_MENU_V2_PATH : NEW_MENU_V1_PATH
  } else {
    return shouldNavigateToNewMenu ? MENU_V2_PATH : MENU_V1_PATH
  }
}
