import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledActionButton = styled.button`
  background: ${themeUi.palette.brand.primary.white};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.space.space_2};
  border-radius: 18px;
  height: 36px;
  width: 36px;

  &:hover {
    cursor: pointer !important;
  }

  &:focus {
    outline: none;
  }
`
