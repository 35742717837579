import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

// TODO: Move to components/ContentModalModule/ when ModalSections is no longer used.
export const StyledScrollContainer = styled.div`
  overflow: auto;
  height: 600px;

  padding: 2em;
  padding-bottom: 4em;

  scrollbar-width: thin !important;
  scrollbar-color: #aaa;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  input:disabled {
    opacity: 1;
    color: ${themeUi.palette.brand.primary.charcoal} !important;
  }

  textarea:disabled {
    color: ${themeUi.palette.brand.primary.charcoal};
  }
`
export const StyledColumns = {
  Grid: styled.div`
    width: 100%;
    max-height: 600px;
    display: grid;
    grid-template-columns: 30% 1fr;
  `,
  MenuSection: styled.div`
    background: #737f8f22;
    padding-left: 2em;
    padding-top: 2em;
    padding-bottom: 4em;
  `,

  ContentSection: styled.div`
    padding: 0px 2px;
    overflow: hidden;
  `,
}

export const StyledMenu = {
  Title: styled.div`
    display: flex;
    align-items: center;
    height: 4em;
    margin-bottom: 1em;
    p {
      ${themeUi.typography.h4.css};
      font-weight: bold;
    }
  `,
  MenuItem: styled.div`
    margin: 0px 0px 2em auto;
    padding-left: 1em;
    cursor: pointer;
    ${themeUi.typography.link.css};
    color: ${props => (props.active ? '#0d62ff' : 'initial')};
  `,
}
