import React, { FunctionComponent } from 'react'
import ContextMenu from 'components/ContextMenu'
import { ITEM_CONTEXT_MENU_OPTIONS } from 'src/constants'
import { StyledContextMenu } from './styles'

const QueryParamsContextMenu: FunctionComponent<{
  handleOnClickEdit: (index: number) => void
  handleOnClickDelete: (index: number) => void
}> = props => {
  const { handleOnClickEdit, handleOnClickDelete } = props

  return (
    <StyledContextMenu data-testid='query-params-context-menu'>
      <ContextMenu align='left'>
        <ContextMenu.Item
          onClick={handleOnClickEdit}
          data-testid='query-param-edit'
        >
          {ITEM_CONTEXT_MENU_OPTIONS.EDIT}
        </ContextMenu.Item>
        <ContextMenu.Item
          onClick={handleOnClickDelete}
          data-testid='query-param-delete'
        >
          {ITEM_CONTEXT_MENU_OPTIONS.DELETE}
        </ContextMenu.Item>
      </ContextMenu>
    </StyledContextMenu>
  )
}

export default QueryParamsContextMenu
