import {
  ACTIONS_THAT_REQUIRE_REFETCH,
  DEFAULT_PAGINATION,
  TOAST_MESSAGE_TYPES,
} from 'src/constants'
import { createActionPrefix } from 'redux-nano'
import { filterName } from 'components/Filters/constants'
import pageService from 'services/api/page'
import { showToast } from 'components/ToastSnackbarContainer'

const { channels: filterChannels, pageTypes, status, locales } = filterName

const initialFilters = {
  [filterChannels]: [],
  [pageTypes]: [],
  [status]: [],
  [locales]: [],
}

const EXECUTION_DELAY = 150

const requiresBackendRefetch = actionType => {
  return Object.keys(ACTIONS_THAT_REQUIRE_REFETCH).includes(actionType)
}

const fetchAllPagesFromBackend = ({ dispatch, filters, paginationLimit }) => {
  setTimeout(() => {
    dispatch(
      PAGES_REQUEST({
        filters,
        limit: paginationLimit,
      })
    )

    dispatch(
      PAGES_ARCHIVED_REQUEST({
        filters,
        limit: paginationLimit,
      })
    )
  }, EXECUTION_DELAY)
}

const createAction = createActionPrefix('PAGES')

const PAGES_REQUEST_SUBMIT = createAction('PAGES_REQUEST_SUBMIT')
export const PAGES_REQUEST_SUCCESS = createAction('PAGES_REQUEST_SUCCESS')
export const ARCHIVED_REQUEST_SUCCESS = createAction('ARCHIVED_REQUEST_SUCCESS')
export const PAGES_REQUEST_ERROR = createAction('PAGES_REQUEST_ERROR')
export const PAGES_RESET = createAction('PAGES_RESET')

const PAGE_UPDATE_VERSION = ({
  pageId,
  versionId,
  isArchived = null,
  startDate = null,
  startImmediate = null,
  description = null,
  versionName = null,
  actionType = '',
  paginationLimit = DEFAULT_PAGINATION.ITEMS_PER_PAGE,
  filters = initialFilters,
}) => async dispatch => {
  try {
    const payload = {
      pageId,
      versionId,
      ...(isArchived !== null && {
        isArchived,
      }),
      ...(startDate !== null && {
        startDate,
      }),
      ...(startImmediate !== null && {
        startImmediate,
      }),
      ...(description !== null && {
        description,
      }),
      ...(versionName !== null && {
        versionName,
      }),
    }

    await pageService.updateVersion(
      {
        pageId,
        versionId,
      },
      payload
    )

    if (requiresBackendRefetch(actionType)) {
      fetchAllPagesFromBackend({
        dispatch,
        filters,
        paginationLimit,
      })
    }
  } catch (err) {
    const errMsg = `Error updating page ${pageId}: ${err}`
    console.error(errMsg)
    showToast({
      message: errMsg,
      kind: TOAST_MESSAGE_TYPES.ALERT,
    })
  }
}

export const PAGE_UPDATE = ({
  pageId,
  isArchived = null,
  isActive = null,
  channels = null,
  name = null,
  pageUrl = null,
  typeId = null,
  typeName = null,
  seoFields = null,
  actionType = '',
  paginationLimit = DEFAULT_PAGINATION.ITEMS_PER_PAGE,
  filters = initialFilters,
}) => async dispatch => {
  try {
    const payload = {
      pageId,
      ...(isArchived !== null && {
        isArchived,
      }),
      ...(isActive !== null && {
        isActive,
      }),
      ...(channels !== null && {
        channels,
      }),
      ...(name !== null && {
        name,
      }),
      ...(pageUrl !== null && {
        pageUrl,
      }),
      ...(typeId !== null && {
        typeId,
      }),
      ...(typeName !== null && {
        typeName,
      }),
      ...(seoFields !== null && {
        seoFields,
      }),
    }

    await pageService.update(
      {
        pageId,
      },
      payload
    )

    if (requiresBackendRefetch(actionType)) {
      fetchAllPagesFromBackend({
        dispatch,
        filters,
        paginationLimit,
      })
    }
  } catch (err) {
    const errMsg = `Error updating page ${pageId}: ${err}`
    console.error(errMsg)
    showToast({
      message: errMsg,
      kind: TOAST_MESSAGE_TYPES.ALERT,
    })
  }
}

export const PAGES_REQUEST = ({
  filters,
  offset = 0,
  limit = DEFAULT_PAGINATION.ITEMS_PER_PAGE,
  channels = '',
  isArchived = false,
  isActive = true,
}) => async dispatch => {
  dispatch(PAGES_REQUEST_SUBMIT())
  try {
    const data = await pageService.list({
      offset,
      limit,
      channels,
      isArchived,
      filters,
      isActive,
    })

    const payload = {
      pages: data.pages,
      query: data.query,
    }
    dispatch(PAGES_REQUEST_SUCCESS(payload))
  } catch (err) {
    showToast({
      message: typeof err === 'string' ? err : err.message,
      kind: TOAST_MESSAGE_TYPES.ALERT,
    })
    dispatch(PAGES_REQUEST_ERROR(err))
  }
}

const PAGES_ARCHIVED_REQUEST = ({
  offset = 0,
  limit = DEFAULT_PAGINATION.ITEMS_PER_PAGE,
  channels = '',
  filters = {},
}) => async dispatch => {
  try {
    dispatch(PAGES_REQUEST_SUBMIT())
    const data = await pageService.list({
      offset,
      limit,
      channels,
      isArchived: true,
      filters,
    })

    const payload = {
      pages: data.pages,
      query: data.query,
    }
    dispatch(ARCHIVED_REQUEST_SUCCESS(payload))
  } catch (error) {
    dispatch(PAGES_REQUEST_ERROR(error))
  }
}

const PAGE_VERSIONS_REQUEST_SUCCESS = createAction(
  'PAGE_VERSIONS_REQUEST_SUCCESS'
)

const TOGGLE_ACTIVE_PAGE = createAction('TOGGLE_ACTIVE_PAGE')
const MAKE_A_COPY_OF_VERSION = createAction('MAKE_A_COPY_OF_VERSION')
const EDIT_PAGE_DETAILS = createAction('EDIT_PAGE_DETAILS')
export const SET_SEARCH_TERM = createAction('SET_SEARCH_TERM')
const SET_PAGINATION_RESET = createAction('SET_PAGINATION_RESET')

const SET_CONFIRM_DIALOG = createAction('SET_CONFIRM_DIALOG')
const SET_CREATE_VERSION_DIALOG = createAction('SET_CREATE_VERSION_DIALOG')
const SET_CREATE_PAGE_DIALOG = createAction('SET_CREATE_PAGE_DIALOG')
const SET_CONTENT_DETAILS_DIALOG = createAction('SET_CONTENT_DETAILS_DIALOG')
const SET_MAKE_A_COPY_DIALOG = createAction('SET_MAKE_A_COPY_DIALOG')

export const SET_FILTERS = createAction('SET_FILTERS')
const RESET_FILTERS = createAction('RESET_FILTERS')

export const CREATE_PAGE = createAction('CREATE_PAGE')

export const ACTIONS = {
  PAGES_REQUEST_SUBMIT,
  PAGES_REQUEST_SUCCESS,
  ARCHIVED_REQUEST_SUCCESS,
  PAGES_REQUEST_ERROR,
  PAGES_RESET,
  PAGES_REQUEST,
  PAGES_ARCHIVED_REQUEST,
  PAGE_VERSIONS_REQUEST_SUCCESS,
  SET_SEARCH_TERM,
  SET_PAGINATION_RESET,
  SET_CONFIRM_DIALOG,
  SET_CREATE_VERSION_DIALOG,
  SET_CREATE_PAGE_DIALOG,
  SET_CONTENT_DETAILS_DIALOG,
  SET_MAKE_A_COPY_DIALOG,
  SET_FILTERS,
  RESET_FILTERS,
  TOGGLE_ACTIVE_PAGE,
  EDIT_PAGE_DETAILS,
  MAKE_A_COPY_OF_VERSION,
  CREATE_PAGE,
  PAGE_UPDATE,
  PAGE_UPDATE_VERSION,
}
