import formatDuration from 'lib/formatDuration'

const ONE_MINUTE = 60000
const getSaveTimeDuration = ({ time, timer, versionName }) => {
  if (!time) return

  const fd = formatDuration(timer - time, ['second', 'millisecond'])

  if ((!timer && timer - time < ONE_MINUTE) || !fd) return 'Saved'

  return `Saved ${fd} ago in ${versionName}`
}

export default getSaveTimeDuration
