import {
  APPLY_MODES,
  createDefaultAttribute,
  shouldDisableApplyCTA,
} from './utils'
import { AttributesSectionProps, ParamsProps } from './types'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { StyledAddAttributeSection, StyledParameterList } from './styles'
import ApplyAttributeView from './ApplyAttributeView'
import ExpandableCard from 'components/ExpandableCard'
import { MANAGE_NODE_SIDEBAR_LABEL } from 'src/constants'
import QueryParamsContextMenu from './QueryParamsContextMenu'
import { getVersionStatus } from 'modules/editor/selectors'
import isEmpty from 'lodash/isEmpty'
import { nanoid } from 'nanoid'
import { useSelector } from 'react-redux'
import { useVersionBasedEditAccess } from 'hooks/useVersionBasedEditAccess'

const AttributesSection: FunctionComponent<AttributesSectionProps> = props => {
  const { values, setValues } = props
  const [attributeToEdit, setAttributeToEdit] = useState<ParamsProps>(null)
  const [attributeToAdd, setAttributeToAdd] = useState<ParamsProps>(null)
  const menuVersionStatus = useSelector(getVersionStatus) as string
  const hasEditAccess = useVersionBasedEditAccess(menuVersionStatus)
  useEffect(() => {
    if (values.attributes?.length) {
      /* This function ensures that every attribute has a unique ID,
    this ID will be removed before the Save request is sent to the backend */
      setValues(oldValues => ({
        ...oldValues,
        attributes: oldValues.attributes.map(attr => ({
          ...attr,
          _id: nanoid(),
        })),
      }))
    }
    // This only needs to happen on initial render, so not putting any dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    /* This useEffect ensures that the value of 'attributes' always exists as an array */
    if (!values.attributes) {
      setValues({
        ...values,
        attributes: [],
      })
    }
  }, [values, setValues])

  useEffect(() => {
    /* This useEffect ensures that Edit and Add modes are not on at the same time */
    if (attributeToEdit) {
      setAttributeToAdd(null)
    } else if (attributeToAdd) {
      setAttributeToEdit(null)
    }
  }, [attributeToAdd, attributeToEdit])

  return (
    <ExpandableCard heading={MANAGE_NODE_SIDEBAR_LABEL.ATTRIBUTES}>
      {attributeToAdd && (
        <ApplyAttributeView
          onApply={() => {
            const newAttributes = values.attributes.slice()
            newAttributes.push({
              ...attributeToAdd,
              _id: nanoid(),
            })
            setValues({
              ...values,
              attributes: newAttributes,
            })
            setAttributeToAdd(createDefaultAttribute())
          }}
          onCancel={() => setAttributeToAdd(null)}
          attribute={attributeToAdd}
          setAttribute={setAttributeToAdd}
          applyCTADisabled={shouldDisableApplyCTA(
            attributeToAdd,
            values.attributes,
            APPLY_MODES.ADD_MODE
          )}
        />
      )}
      {attributeToEdit && (
        <ApplyAttributeView
          onApply={() => {
            const newAttributes = values.attributes.map(attr => {
              if (attr._id === attributeToEdit._id) {
                return attributeToEdit
              }
              return attr
            })
            setValues({
              ...values,
              attributes: newAttributes,
            })
            setAttributeToEdit(null)
          }}
          onCancel={() => setAttributeToEdit(null)}
          attribute={attributeToEdit}
          setAttribute={setAttributeToEdit}
          applyCTADisabled={shouldDisableApplyCTA(
            attributeToEdit,
            values.attributes,
            APPLY_MODES.EDIT_MODE
          )}
        />
      )}
      {!attributeToEdit && !attributeToAdd && hasEditAccess && (
        <StyledAddAttributeSection>
          <span
            className='manage-node-link-cta'
            data-testid='add-query-param-button'
            onClick={() => setAttributeToAdd(createDefaultAttribute())}
          >
            {MANAGE_NODE_SIDEBAR_LABEL.CTA.ADD_ATTRIBUTE}
          </span>
        </StyledAddAttributeSection>
      )}

      {!isEmpty(values.attributes) &&
        values.attributes.map((attribute, index) => {
          const { _id, kind } = attribute

          return (
            <StyledParameterList key={`attribute-${kind}`}>
              <div className='query-param-each'>
                <div data-testid={`query-param-each-${index}`}>
                  <span data-testid={`query-param-kind-${index}`}>{kind}</span>
                </div>
                {hasEditAccess && (
                  <QueryParamsContextMenu
                    handleOnClickEdit={() => {
                      setAttributeToEdit({ ...attribute })
                    }}
                    handleOnClickDelete={() => {
                      const newAttributes = values.attributes.filter(
                        attr => attr._id !== _id
                      )
                      setValues({
                        ...values,
                        attributes: newAttributes,
                      })
                    }}
                  />
                )}
              </div>
            </StyledParameterList>
          )
        })}
    </ExpandableCard>
  )
}

export default AttributesSection
