import { useEffect, useState } from 'react'

/**
 * React useDebounce hook
 */
export const useDebounce = (value: string, duration: number): string => {
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(value)
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(value)
    }, duration)
    return () => {
      clearTimeout(handler)
    }
  }, [value, duration])

  return debouncedSearchTerm
}
