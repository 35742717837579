import React, { ReactElement } from 'react'
import { CONTEXT_MENU_WIDTH } from './constants'
import { ComponentContextMenuProps } from './types'
import ContextMenu from 'components/ContextMenu'

const ComponentContextMenu = (
  props: ComponentContextMenuProps
): ReactElement => {
  const { handleOnClickDuplicate, flyoutId } = props

  return (
    <ContextMenu
      className='component-context-menu'
      placement='bottomLeft'
      width={`${CONTEXT_MENU_WIDTH}px`}
      flyoutId={flyoutId}
    >
      <ContextMenu.Item
        onClick={(e: Event) => {
          e.stopPropagation()
          handleOnClickDuplicate(e)
        }}
        data-testid='component-context-menu-duplicate'
      >
        Duplicate
      </ContextMenu.Item>
    </ContextMenu>
  )
}

export default ComponentContextMenu
