import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 50px;
  margin-top: ${({ theme }) => theme.space.space_7};
  margin-bottom: ${({ theme }) => theme.space.space_5};
  align-items: center;

  h4 {
    ${themeUi.typography.body};
    cursor: pointer;
    color: ${themeUi.palette.brand.primary.charcoal};
  }
`

export const StyledCaretDownWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  .expandable-caret-down {
    width: ${({ theme }) => theme.space.space_5};
    height: ${({ theme }) => theme.space.space_5};
    user-select: none;
    cursor: pointer;
    transition: all 300ms ease-in-out;

    &.caret-rotate-180 {
      transform: rotate(180deg);
    }
  }
`

export const StyledExpandedContent = styled.div`
  margin-bottom: ${({ theme }) => theme.space.space_5};
`
