import {
  BROWSE_MENU_TREE_CONTEXT_MENU_ITEMS,
  BROWSE_MENU_TREE_DELETE_MODAL,
  BROWSE_MENU_TREE_DEFAULT_NAME as DEFAULT_NAME,
  EMPTY_STRING,
  MESSAGES,
  VIEW_DETAILS_LABEL,
} from 'src/constants'
import { ButtonLoader, HierarchyTreeCard } from '@teamfabric/copilot-ui'
import { FORM_FIELD_NAMES, ManageNodeFormValues } from '../ManageNodeView/types'
import {
  MenuTreeNode,
  NODE_TYPE,
  ReactDnDProvidedProps,
} from 'modules/browse-menu-tree-gql/types'
import {
  PIPELINE_DATA_ATTR_KEYS as PIPELINE_KEYS,
  PIPELINE_DATA_ATTR_VALUES as PIPELINE_VALUES,
  SUBNODE_ORDER_INCREMENT,
} from 'modules/browse-menu-tree-gql/constants'
import React, { useEffect, useState } from 'react'
import { StyledNodeContainer, StyledSpacer, StyledWrapper } from './styles'
import {
  handleCardMouseEnter,
  handleCardMouseLeave,
} from 'modules/browse-menu-tree-gql/utils/dragEnd'
import AddNewNode from '../AddNewNode'
import { AddNewNodeSaveProps } from '../AddNewNode/types'
import ConfirmDialog from 'components/ConfirmDialog'
import { Draggable } from 'react-beautiful-dnd'
import ManageNodeView from '../ManageNodeView'
import { NodeProps } from './types'
import { UPDATE_MENU_NODE } from 'services/graphql'
import { UpdateMenuNodeResponse } from 'src/modules/browse-menu-tree-gql/types'
import cn from 'classnames'
import extract from 'lib/extract'
import { getActiveLanguage } from 'store/i18n/selectors'
import { getVersionStatus } from 'modules/editor/selectors'
import { handleMutation } from 'src/data/services'
import noop from 'lodash/noop'
import { transformManageNodeFormVariables } from 'src/data/browse-menu-tree-gql/utils'
import { useLocation } from '@reach/router'
import { useMutation } from '@apollo/client'
import { useSelector } from 'react-redux'
import { useVersionBasedEditAccess } from 'hooks/useVersionBasedEditAccess'

const {
  getManageTitle,
  RENAME,
  REMOVE,
  SHOW,
  HIDE,
} = BROWSE_MENU_TREE_CONTEXT_MENU_ITEMS

const { getContent, getSubmitText, getTitle } = BROWSE_MENU_TREE_DELETE_MODAL

const getNewNodeOrder = (sectionTailNode: MenuTreeNode) =>
  sectionTailNode.order + SUBNODE_ORDER_INCREMENT

const Node = ({
  node,
  nodeType,
  index,
  depth,
  handleCreate,
  handleOnClickCard,
  handleRemoveItem,
  activeNodes,
  isActiveOrExpanded,
  isSectionTail,
  showNode,
  hoveredSectionId,
  setExpandedSection,
  setHoveredSectionId,
  handleNodeVisibility,
  dragDisabled,
  draggingId,
  parentNode,
  onUpdateNode,
  locales,
  selectedLocale,
  shouldFetchNodesOnClick,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
NodeProps): JSX.Element => {
  const [toggle, setToggle] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [isParentDisabled, setIsParentDisabled] = useState(false)
  const [isAddingSubnode, setIsAddingSubnode] = useState(false)
  const [isShowingChildren, setIsShowingChildren] = useState(isActiveOrExpanded)
  const [shouldFetchNodes, setShouldFetchNodes] = useState(
    shouldFetchNodesOnClick
  )
  const [isInRenameState, setIsInRenameState] = useState(false)
  const menuVersionStatus = useSelector(getVersionStatus) as string
  const hasEditAccess = useVersionBasedEditAccess(menuVersionStatus)

  const activeLang = useSelector(getActiveLanguage)
  useEffect(() => {
    const isSection = nodeType === NODE_TYPE.SECTION

    if (isSection) {
      setExpandedSection(node._id, isShowingChildren)
    }
    return () => {
      // Reset the entry in the expandedSectionMap for the target section
      // when a section is unmounted (ie. when a level is removed from the tree)
      isSection && setExpandedSection(node._id, undefined)
    }
  }, [isShowingChildren, nodeType, node._id, setExpandedSection])

  useEffect(() => {
    if (nodeType !== NODE_TYPE.SECTION) {
      setIsShowingChildren(activeNodes[depth]?._id === node._id)
    }
  }, [node._id, activeNodes, depth, nodeType, setIsShowingChildren])

  const itemCategory = node.section ? 'section' : 'node'
  const isSectionChild = nodeType === NODE_TYPE.SUBNODE
  const contextMenuOptionTitle = hasEditAccess
    ? getManageTitle(itemCategory)
    : VIEW_DETAILS_LABEL
  const [updateMenuNode] = useMutation(UPDATE_MENU_NODE)

  const cardPlaceholder = node.section
    ? DEFAULT_NAME.section
    : DEFAULT_NAME.node

  const { search } = useLocation()

  useEffect(() => {
    if (!parentNode) {
      return
    }

    setIsParentDisabled(parentNode.disabled)
  }, [parentNode])

  const saveSubnode = async (props: AddNewNodeSaveProps) => {
    await handleCreate({
      ...props,
      order: getNewNodeOrder(node),
    })
  }

  const handleNodeClick = async () => {
    if (isInRenameState) {
      return
    }
    await handleOnClickCard({
      node,
      depth,
      shouldShowChildren: !isShowingChildren,
      shouldFetchNodes,
    })

    setIsShowingChildren(!isShowingChildren)

    if (node.section) {
      // We only need to fetch nodes once for a section. After that, the nodes
      // will be added to the menu tree object on the frontend
      shouldFetchNodes && setShouldFetchNodes(false)
    }
  }

  const cancelNodeRename = () => {
    setIsInRenameState(false)
  }

  const manageNodeOption = {
    name: contextMenuOptionTitle,
    onClickHandler: () => setToggle(true),
  }
  const renameNodeOption = {
    name: RENAME,
    onClickHandler: () => {
      setIsInRenameState(true)
    },
  }
  const showHideNodeOption = {
    name: node.disabled ? SHOW : HIDE,
    id: isParentDisabled ? 'hide_cta' : '',
    onClickHandler: () => handleNodeVisibility(node, !node.disabled, depth),
  }
  const removeNodeOption = {
    name: REMOVE,
    onClickHandler: () => setShowConfirmDialog(true),
  }
  const fullOptions = hasEditAccess
    ? [manageNodeOption, renameNodeOption, showHideNodeOption, removeNodeOption]
    : [manageNodeOption]

  const isPlaceholder = node._id === 'placeholder'
  const withParentDisabledOptions = fullOptions.filter(
    option => option.id !== 'hide_cta'
  )
  const options = node.imported
    ? withParentDisabledOptions.slice(0, 1)
    : withParentDisabledOptions

  const isHoveringOverExpandedSection =
    node.parent === hoveredSectionId ||
    (node._id === hoveredSectionId && isShowingChildren)

  const shouldShowAddSubnodeOption =
    isAddingSubnode || (isSectionTail && isHoveringOverExpandedSection)

  const handleSaveClick = (name: string) => {
    setIsInRenameState(false)
    if (!name) {
      return
    }

    const { url, images, attributes, _id } = node

    const formValues = {
      nodeName: {
        ...node.name,
        [selectedLocale]: name,
      },
      nodeId: _id,
      url,
      attributes,
      images,
    }

    void handleUpdateNode(formValues)
  }

  const handleUpdateNode = async (formValues: ManageNodeFormValues) => {
    const { menuId, versionId } = extract.queryParams(search)
    // Need to inject information about parentId and variantId to the backend
    formValues[FORM_FIELD_NAMES.parentId] = menuId
    formValues[FORM_FIELD_NAMES.variantId] = versionId
    const transformedVariables = transformManageNodeFormVariables(formValues)
    await handleMutation<UpdateMenuNodeResponse>({
      mutation: updateMenuNode,
      mutationOptions: {
        variables: transformedVariables,
      },
      onSuccess: response => {
        setToggle(false)
        const {
          data: { updateNode },
        } = response
        onUpdateNode(updateNode, depth)
      },
      snackbarProps: {
        successMessage: MESSAGES.getUpdatedSuccess(
          formValues.nodeName[selectedLocale]
        ),
        errorMessage: MESSAGES.getUpdatedError(
          formValues.nodeName[selectedLocale]
        ),
      },
    })
  }

  const pipelineProps = {
    [PIPELINE_KEYS.pipelineId]: PIPELINE_VALUES.getPipelineId({
      showPipeline: !isSectionChild,
      depth,
    }),
    [PIPELINE_KEYS.parentNodeId]: node.parent,
  }

  return (
    <StyledWrapper
      showNode={showNode}
      isSectionChild={isSectionChild}
      className={cn({ 'hide-placeholder': isPlaceholder })}
      data-testid={`parent-${node.parent}-node-wrapper`}
      disabled={node.disabled}
    >
      <Draggable
        draggableId={node._id}
        index={index}
        isDragDisabled={dragDisabled}
      >
        {(
          provided: ReactDnDProvidedProps,
          snapshot: { isDragging: boolean }
        ) => {
          return (
            <>
              {node.section && node.order >= 1 && <StyledSpacer></StyledSpacer>}
              <StyledNodeContainer
                onMouseEnter={() =>
                  handleCardMouseEnter(
                    node,
                    nodeType,
                    setHoveredSectionId,
                    snapshot.isDragging
                  )
                }
                onMouseLeave={() =>
                  handleCardMouseLeave(
                    node,
                    nodeType,
                    setHoveredSectionId,
                    snapshot.isDragging
                  )
                }
                id={node._id}
                className={cn({
                  'is-dragging': snapshot.isDragging,
                  'is-drag-disabled': dragDisabled,
                })}
                {...pipelineProps}
                data-order={node.order}
                data-active={activeNodes[depth]?._id === node._id || toggle}
                data-nodeid={node._id}
                pimTail={isSectionTail && node.imported}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                {dragDisabled && node._id === draggingId ? (
                  <ButtonLoader
                    className='button-loader'
                    theme='dark'
                    width='100%'
                  />
                ) : (
                  <HierarchyTreeCard
                    title={node?.name?.[selectedLocale] || EMPTY_STRING}
                    placeholder={cardPlaceholder}
                    className='tree-node'
                    tabIndex='0'
                    localeCode={activeLang}
                    showArrow={!!node.section}
                    handleOptionClick={noop}
                    isEnabled={
                      nodeType === NODE_TYPE.SECTION && isShowingChildren
                    }
                    options={options}
                    rename={isInRenameState}
                    customOnClick={async () => {
                      await handleNodeClick()
                    }}
                    onSave={handleSaveClick}
                    onCancel={cancelNodeRename}
                  />
                )}
                <span className='tooltip-drop-warning'>
                  Nodes cannot be dropped on existing entries/sections
                </span>
                {shouldShowAddSubnodeOption && hasEditAccess && (
                  <AddNewNode
                    depth={depth}
                    id={`new-subnode-${depth}-${getNewNodeOrder(node)
                      .toString()
                      .replace('.', '-')}`}
                    nodeType={NODE_TYPE.SUBNODE}
                    parentNodeId={isSectionChild ? node.parent : node._id}
                    onEditStateTransition={setIsAddingSubnode}
                    onSave={saveSubnode}
                  />
                )}
              </StyledNodeContainer>
            </>
          )
        }}
      </Draggable>

      {toggle && (
        <ManageNodeView
          node={node}
          onCancel={() => setToggle(false)}
          onSave={handleUpdateNode}
          visible
          locales={locales}
          selectedLocale={selectedLocale}
        />
      )}

      {showConfirmDialog && (
        <ConfirmDialog
          isVisible={true}
          title={getTitle(itemCategory)}
          content={getContent(itemCategory)}
          submitText={getSubmitText(itemCategory)}
          onSubmit={async () => {
            await handleRemoveItem(node, depth)
            setShowConfirmDialog(false)
          }}
          cancelText='Cancel'
          onCancel={() => setShowConfirmDialog(false)}
        />
      )}
    </StyledWrapper>
  )
}

export default Node
