import React, { memo, useCallback, useRef, useState } from 'react'
import { get, noop } from 'lodash'
import Field from 'components/Field'
import IconAlignCenter from 'assets/icons/IconAlignCenter'
import IconAlignLeft from 'assets/icons/IconAlignLeft'
import IconAlignRight from 'assets/icons/IconAlignRight'
import PropTypes from 'prop-types'
import { StyleContainer } from './style'
import isEqual from 'react-fast-compare'
import useClickOutside from 'hooks/useClickOutside'

const StyleOptions = props => {
  const { id, path, curatedComponent, show, onEdit, onBlur, wrapperRef } = props
  const containerRef = useRef(null)
  const [alignment, setAlignment] = useState(
    get(curatedComponent, `rules.${path}.style['text-align']`, 'auto')
  )
  useClickOutside(wrapperRef, onBlur)

  const onAlign = useCallback(
    e => {
      onEdit({
        id,
        path: `${path}.style["text-align"]`,
        value: e.target.value,
        order: curatedComponent.order,
        componentPath: 'rules',
      })
      setAlignment(e.target.value)
    },
    [id, path, curatedComponent.order, onEdit]
  )

  if (!show) return null
  if (!curatedComponent || !Object.keys(curatedComponent).length) return null

  const alignments = {
    left: 'left',
    right: 'right',
    center: 'center',
  }

  return (
    <StyleContainer ref={containerRef} data-testid='style-options-wrapper'>
      <Field
        type='radio'
        name={`${props.id}-${props.path}-text-align`}
        value={alignments.left}
        onChange={onAlign}
        checked={alignment === alignments.left}
        data-testid='style-options-left-align'
        label={<IconAlignLeft />}
      />
      <Field
        type='radio'
        name={`${props.id}-${props.path}-text-align`}
        value={alignments.center}
        onChange={onAlign}
        checked={alignment === alignments.center}
        data-testid='style-options-center-align'
        label={<IconAlignCenter />}
      />
      <Field
        type='radio'
        name={`${props.id}-${props.path}-text-align`}
        value={alignments.right}
        onChange={onAlign}
        checked={alignment === alignments.right}
        data-testid='style-options-right-align'
        label={<IconAlignRight />}
      />
    </StyleContainer>
  )
}

StyleOptions.defaultProps = {
  curatedComponent: {},
  descriptor: {},
  id: '',
  onEdit: noop,
  onBlur: noop,
  path: '',
  value: '',
  show: false,
  wrapperRef: {},
}

StyleOptions.propTypes = {
  curatedComponent: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  show: PropTypes.bool.isRequired,
  wrapperRef: PropTypes.object.isRequired,
}

export default memo(StyleOptions, isEqual)
