import { DEFAULT_TEXTAREA_VALUES, MODAL_ACTION } from 'src/constants'
import {
  Dropdown,
  Input,
  MultiSelectTextChips,
  Textarea,
} from '@teamfabric/copilot-ui'
import { AnyObjectSchema } from 'yup'
import { CommonInputFieldProps } from 'components/FormDialog/common/versions/VersionDetailsDialog/types'
import { FormikProps } from 'formik'
import PageTypeField from 'components/PageTypeField'
import React from 'react'
import { SeoFieldActionFunctionProps } from 'components/FormDialog/common/content/ContentDetailsDialog/types'
import { SeoMetadataList } from 'components/FormDialog/common/content/ContentDetailsDialog/tabs/SeoAndMetadataTab'
import StyledErrorMessage from 'components/Common/StyledErrorMessage'
import { StyledFormField } from 'components/FormDialog/common/versions/VersionDetailsDialog/styles'
import noop from 'lodash/noop'

export type SeoMetadataListProps = {
  type: string
  dataTestId: string
  disabled?: boolean
  seoFieldAction: SeoFieldActionFunctionProps
  localeCode?: string
  label?: never
  name?: never
  value?: never
  dropdownOptions?: never
  autoFocus?: never
  placeholder?: never
  forceEnabled?: never
  className?: never
  customOnChange?: never
  locale?: never
}

export type FormComponentListProps = (
  | CommonInputFieldProps
  | SeoMetadataListProps
)[]

// TODO: Replace 'any' with an explicit type once Value type is identified (see types.ts)
interface GetFormComponentsProps extends FormikProps<any> {
  components: FormComponentListProps
  mode: string
}

export const getFormComponents = ({
  components,
  handleBlur,
  handleChange,
  setFieldTouched,
  setFieldValue,
  errors,
  touched,
  mode,
}: GetFormComponentsProps): JSX.Element => {
  return (
    <>
      {components.map(component => {
        const {
          type,
          label,
          name,
          dataTestId,
          value,
          disabled = false,
          autoFocus = false,
          placeholder = '',
          className = '',
          locale = '',
          localeCode = '',
          customOnChange = noop,
          seoFieldAction,
        } = component
        const isDisabledByMode = !!(mode === MODAL_ACTION.VIEW
          ? true
          : disabled)
        switch (type) {
          case 'Input':
            return (
              <StyledFormField key={dataTestId} className={className}>
                <Input
                  label={label}
                  inputProps={{
                    'name': name,
                    'value': value,
                    'data-testid': dataTestId,
                    'onChange': e => {
                      setFieldTouched(name, true, false)
                      handleChange(e)
                    },
                    'type': 'text',
                    'onBlur': handleBlur,
                    'disabled': isDisabledByMode,
                    'autoFocus': autoFocus,
                    'placeholder': placeholder,
                  }}
                  locale={locale}
                  localeCode={localeCode}
                />
                {errors[name] && touched[name] && (
                  <StyledErrorMessage className={`${name}-field-error`}>
                    {errors[name]}
                  </StyledErrorMessage>
                )}
              </StyledFormField>
            )
          case 'SingleSelect':
            return (
              <StyledFormField className={className}>
                <Dropdown
                  onSelect={v => {
                    setFieldValue(name, v, true)
                    setFieldTouched(name, true, false)
                    customOnChange(v, setFieldValue)
                  }}
                  titleLabel={label}
                  value={value}
                  name={name}
                  disabled={component.forceEnabled ? false : isDisabledByMode}
                  options={component.dropdownOptions?.map(
                    (o: Record<string, unknown>) => ({
                      id: o?.id,
                      name: o?.label,
                    })
                  )}
                  {...{ 'data-testid': dataTestId }}
                />
                {errors[name] && touched[name] && (
                  <StyledErrorMessage className={`${name}-field-error`}>
                    {errors[name]}
                  </StyledErrorMessage>
                )}
              </StyledFormField>
            )
          case 'Textarea':
            return (
              <StyledFormField key={dataTestId} className={className}>
                <Textarea
                  key={name}
                  label={label}
                  textareaProps={{
                    'limit': DEFAULT_TEXTAREA_VALUES.LIMIT,
                    'rows': DEFAULT_TEXTAREA_VALUES.ROWS,
                    'value': value,
                    'onChange': handleChange,
                    'name': name,
                    'data-testid': dataTestId,
                    'onBlur': handleBlur,
                  }}
                  disabled={isDisabledByMode}
                />
                {errors[name] && touched[name] && (
                  <StyledErrorMessage className={`${name}-field-error`}>
                    {errors[name]}
                  </StyledErrorMessage>
                )}
              </StyledFormField>
            )
          case 'MultiSelectTextChips':
            return isDisabledByMode ? (
              <StyledFormField key={dataTestId} className={className}>
                <Input
                  label={label}
                  inputProps={{
                    'name': name,
                    'value': (value as Array<{ label: string }>)
                      ?.map(v => v.label)
                      .join(', '),
                    'data-testid': dataTestId,
                    'type': 'text',
                    'onBlur': handleBlur,
                    'disabled': true,
                    'autoFocus': autoFocus,
                    'placeholder': placeholder,
                  }}
                  locale={locale}
                  localeCode={localeCode}
                />
              </StyledFormField>
            ) : (
              // TODO: Once CPU-6402 is complete, this field component can inherit the 'disabled' attribute
              <StyledFormField key={dataTestId} className={className}>
                <MultiSelectTextChips
                  onChange={e => {
                    setFieldValue(name, e, true)
                    setFieldTouched(name, true, false)
                    customOnChange(e, setFieldValue)
                  }}
                  label={label}
                  value={value}
                  name={name}
                  dropdownOptions={component.dropdownOptions}
                />
                {errors[name] && touched[name] && (
                  <StyledErrorMessage className={`${name}-field-error`}>
                    {errors[name]}
                  </StyledErrorMessage>
                )}
              </StyledFormField>
            )
          case 'PageTypeField':
            return (
              <StyledFormField
                style={{ marginTop: '1.5rem' }}
                key={dataTestId}
                className={className}
              >
                <PageTypeField
                  pageTypeId={value as string}
                  setPageType={selected => {
                    setFieldValue(name, selected._id, true)
                    setFieldTouched(name, true, false)
                  }}
                  disabled={isDisabledByMode}
                />
              </StyledFormField>
            )
          case 'SeoMetadataList':
            return (
              <StyledFormField key={dataTestId}>
                <SeoMetadataList
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  seoFieldAction={seoFieldAction}
                  disabled={disabled}
                  localeCode={localeCode}
                />
              </StyledFormField>
            )
          default:
            return null
        }
      })}
    </>
  )
}

export function mergeYupSchemas(schemas: AnyObjectSchema[]): AnyObjectSchema {
  const filteredSchemas = schemas?.length
    ? schemas.filter(s => !!s?.concat)
    : []

  const [first, ...rest] = filteredSchemas
  return rest.reduce(
    (mergedSchemas, schema) => mergedSchemas.concat(schema),
    first
  )
}
