import { VALIDATION_ERRORS } from 'src/constants'
import filterBooleanObject from 'lib/filterBooleanObject'

const validation = (fields = {}) => {
  let isValidQueryParameter = true

  fields.queryParameters.forEach(qp => {
    if (qp.kind === '' && qp.value === '') {
      isValidQueryParameter = true
    } else if (qp.kind === '' || qp.value === '') {
      isValidQueryParameter = false
    }
  })

  return filterBooleanObject({
    label: !fields.label ? VALIDATION_ERRORS.EMPTY_LABEL : undefined,
    path: !fields.path ? VALIDATION_ERRORS.EMPTY_PATH : undefined,
    queryParameters: !isValidQueryParameter
      ? VALIDATION_ERRORS.EMPTY_QUERY_PARAMS
      : undefined,
  })
}

export default validation
