import { BACK_TO_MENUS, STATUS } from 'src/constants'
import { Link, useLocation } from '@reach/router'
import React, { useEffect, useState } from 'react'
import {
  StyledIconBack,
  StyledStatus,
  StyledStatusNameWrapper,
  StyledStatusWrapper,
  StyledTitle,
  StyledWrapperHeader,
} from './styles'
import { BROWSE_MENU_TREE_MODAL_ID } from 'data/browse-menu-tree-gql/types'
import { CONTENT_TYPE } from 'components/ContentEditorCTA/types'
import ContentEditorCTA from 'components/ContentEditorCTA'
import EditorOptions from 'modules/editor/components/SideBarComponents/EditorOptions'
import { HeaderProps } from './types'
import { MENU_V2_PATH, NEW_MENU_V2_PATH } from 'contexts/navigationLinksContext'
import VersionDetailsDialog from 'components/FormDialog/common/versions/VersionDetailsDialog'
import VersionPublishDialog from 'components/FormDialog/page/VersionPublishDialog'
import { VersionTypesEnum } from 'components/FormDialog/common/versions/VersionDetailsDialog/types'
import extract from 'lib/extract'
import { getLocales } from 'store/i18n/selectors'
import { getVersionStatus } from 'modules/editor/selectors'
import noop from 'lodash/noop'
import { useSelector } from 'react-redux'
import { useUserPermissions } from 'contexts/userPermissions'
import { useVersionBasedEditAccess } from 'hooks/useVersionBasedEditAccess'
import { useNewNavigation } from 'src/contexts/navigationLinksContext'

const Header = ({
  menuId,
  menuVersionId,
  menuVariant,
  locales,
  selectedLocale,
  showModal,
  setMenu,
}: HeaderProps): JSX.Element => {
  const [showScheduleModal, setShowScheduleModal] = useState(false)
  const [isLive, setIsLive] = useState(false)
  const applicationLocales = useSelector(getLocales)
  const localesSet = new Set(locales)
  const { search } = useLocation()
  const { reschedule } = extract.queryParams(search)
  const [versionStatus, setVersionStatus] = useState<string>('')
  const { hasPublisherPermissions, hasEditorPermissions } = useUserPermissions()
  const menuVersionStatus = useSelector(getVersionStatus) as string
  const hasEditAccess = useVersionBasedEditAccess(menuVersionStatus)
  const [versionName, setVersionName] = useState<string>('')
  const [versionDescription, setVersionDescription] = useState<string>('')
  const [showModalByType, setShowModalByType] = useState<string>('')
  const navNameChange = useNewNavigation()

  const isLiveStatus = () => {
    if (menuVariant?.status === STATUS.LIVE) return true
    try {
      const currentDate = new Date()
      return (
        menuVariant &&
        menuVariant.status === STATUS.SCHEDULED &&
        new Date(menuVariant.startDate) <= currentDate
      )
    } catch (err) {
      console.log(err)
      return false
    }
  }

  const updateStatus = (status: string) => {
    setVersionStatus(status)
  }

  useEffect(() => {
    const isVersionLive = isLiveStatus()
    const variantStatus = isVersionLive ? STATUS.LIVE : menuVariant?.status
    setIsLive(isVersionLive)
    if (menuVariant) {
      setVersionName(menuVariant?.versionName)
      setVersionDescription(menuVariant?.description)
      setVersionStatus(variantStatus)
    }
  }, [menuVariant])

  useEffect(() => {
    const isValidReschedule =
      hasPublisherPermissions &&
      versionStatus === STATUS.SCHEDULED &&
      reschedule === 'true'

    if (isValidReschedule) {
      setShowScheduleModal(true)
    }
  }, [hasEditorPermissions, hasPublisherPermissions, reschedule, versionStatus])

  const menu = { id: menuId }
  const version = { id: menuVersionId }

  const openSelectedModal = () => {
    const parentElement = {
      id: menuId,
    }
    const versionElement = {
      id: menuVersionId,
      name: versionName,
      description: versionDescription,
    }

    if (showModalByType) {
      return (
        <VersionDetailsDialog
          isVisible
          handleClose={() => setShowModalByType('')}
          mode={showModalByType}
          parentElement={parentElement}
          versionElement={versionElement}
          contentType={VersionTypesEnum.MENUS}
          setVersionName={setVersionName}
          setVersionDescription={setVersionDescription}
          setVersionStatus={setVersionStatus}
          setMenu={setMenu}
        />
      )
    }
  }
  return (
    <>
      {showModalByType && openSelectedModal()}
      <StyledWrapperHeader>
        <Link
          to={navNameChange ? NEW_MENU_V2_PATH : MENU_V2_PATH}
          data-testid='link-go-back'
        >
          <StyledIconBack />
          {BACK_TO_MENUS}
        </Link>
        <StyledTitle>
          <StyledStatusWrapper>
            <StyledStatusNameWrapper>
              <span style={{ marginRight: '20px' }}>{versionName}</span>
              <EditorOptions onEditorOptionClick={setShowModalByType} />
            </StyledStatusNameWrapper>
            <StyledStatus>
              <span className={`circle ${versionStatus?.toLowerCase()}`}></span>
              <span data-testid='menu-version-status'>
                {versionStatus?.toUpperCase()}
              </span>
            </StyledStatus>
          </StyledStatusWrapper>
          <ContentEditorCTA
            locales={applicationLocales?.filter(locale =>
              localesSet.has(locale.code)
            )}
            selectedLocale={selectedLocale}
            contentType={CONTENT_TYPE.MENU}
            isContentLive={isLive}
            lastUpdatedDateString={menuVariant?.updatedAt}
            shouldShowLockIcon={!hasEditAccess}
            saveButtonProps={{
              isVisible: false,
              isDisabled: false,
              hasTooltip: false,
              onClick: noop,
            }}
            publishButtonProps={{
              isVisible: hasPublisherPermissions,
              isDisabled: false,
              hasTooltip: false,
              onClick: () => {
                showModal(BROWSE_MENU_TREE_MODAL_ID.PUBLISH)
              },
            }}
            onScheduleOptionClicked={() => setShowScheduleModal(true)}
          />
        </StyledTitle>

        {showScheduleModal && (
          <VersionPublishDialog
            handleClose={() => {
              setShowScheduleModal(false)
            }}
            menu={menu}
            version={version}
            updateStatus={updateStatus}
          />
        )}
      </StyledWrapperHeader>
    </>
  )
}

export default Header
