import React, { FunctionComponent, useState } from 'react'
import {
  StyledImageSelection,
  StyledThumbnail,
  StyledThumbnailContainer,
} from 'components/type-components/ImageType/styles'
import { ButtonWithIcon } from '@teamfabric/copilot-ui'
import { ImageProps } from './types'
import { getVersionStatus } from 'modules/editor/selectors'
import { useSelector } from 'react-redux'
import { useVersionBasedEditAccess } from 'hooks/useVersionBasedEditAccess'

const THUMBNAIL_SIZE = '32'
const BRAND_FOLDER_DOMAIN = 'bfldr'

const AssetGridImage: FunctionComponent<
  ImageProps & {
    handleSelectAsset: (url: string) => void
    handleRemoveAsset: (url: string) => void
  }
> = props => {
  const { url, altText, handleRemoveAsset, handleSelectAsset } = props
  const [showDeleteCTA, setShowDeleteCTA] = useState(false)
  const menuVersionStatus = useSelector(getVersionStatus) as string
  const hasEditAccess = useVersionBasedEditAccess(menuVersionStatus)
  return url ? (
    <StyledThumbnailContainer data-testid='thumbnail-container'>
      <StyledImageSelection
        onMouseEnter={() => {
          setShowDeleteCTA(true)
        }}
        onMouseLeave={() => {
          setShowDeleteCTA(false)
        }}
      >
        <StyledThumbnail>
          <div
            className='styled-thumbnail-img-container'
            {...(hasEditAccess && {
              onClick: () => handleSelectAsset(url),
            })}
          >
            <img
              src={
                url.includes(BRAND_FOLDER_DOMAIN)
                  ? `${url}?fit=scale&width=${THUMBNAIL_SIZE}&height=${THUMBNAIL_SIZE}`
                  : url
              }
              alt={altText?.default}
              loading='lazy'
              data-testid='bm-thumbnail-image'
            />
          </div>
          {showDeleteCTA && hasEditAccess && (
            <div className='delete-asset'>
              <ButtonWithIcon
                emphasis='high'
                icon='Close'
                isPrimary={false}
                isRoundIcon
                onClick={() => {
                  handleRemoveAsset(url)
                }}
                type='button'
                iconSize={10}
                data-testid='delete-asset-button'
                theme='light'
                className='close-button'
              />
            </div>
          )}
        </StyledThumbnail>
      </StyledImageSelection>
    </StyledThumbnailContainer>
  ) : null
}

export default AssetGridImage
