import { gql } from '@apollo/client'

export const LIST_MENUS = gql`
  query ListMenus($limit: Int, $offset: Int, $input: ContentFilterInput!) {
    menus(
      input: $input
      offset: $offset
      limit: $limit
      orderBy: { field: "updatedAt", direction: DESC }
    ) {
      totalCount
      edges {
        node {
          id
          name
          updatedAt
          locales
          isArchived
          channels {
            id
            name
            label
          }
          variants {
            id
            versionName
            status
            updatedAt
            startDate
          }
        }
      }
    }
  }
`

export const CREATE_MENU = gql`
  mutation CreateMenu($input: CreateMenuInput!) {
    createMenu(input: $input) {
      id
      variants {
        id
      }
    }
  }
`

export const MENU_VARIANT_BY_ID = gql`
  query MenuVariantById($variantId: ID!, $parentId: ID!) {
    menuVariantById(variantId: $variantId, parentId: $parentId) {
      id
      versionName
      description
      status
      startDate
      endDate
      updatedAt
      isArchived
      version
      graph
    }
  }
`

export const MENU_NODES_AS_LIST = gql`
  query MenuNodesAsList($input: MenuNodeInput) {
    menuNodesAsList(input: $input) {
      id
      name
      url
      images {
        locales
        url
        altText
      }
      attributes {
        kind
        value
      }
    }
  }
`

export const CREATE_MENU_NODE = gql`
  mutation CreateMenuNode($input: CreateMenuNodeInput!) {
    createNode(input: $input) {
      graph
      updatedNode {
        id
        name
        url
      }
      version
    }
  }
`

export const UPDATE_MENU_NODE = gql`
  mutation UpdateMenuNode($input: UpdateMenuNodeInput!) {
    updateNode(input: $input) {
      id
      name
      url
      images {
        locales
        url
        altText
        description
        name
      }
      attributes {
        kind
        value
      }
    }
  }
`

export const UPDATE_MENU_GRAPH = gql`
  mutation UpdateMenuGraph($input: UpdateMenuGraphInput!) {
    updateMenuGraph(input: $input) {
      version
      graph
      updatedNode {
        id
        name
        url
        images {
          locales
          url
          altText
        }
        attributes {
          kind
          value
        }
      }
    }
  }
`

export const DELETE_MENU = gql`
  mutation DeleteMenu($menuId: ID!) {
    deleteMenuAsync(contentId: $menuId) {
      status
      message
    }
  }
`

export const DELETE_MENU_VERSION = gql`
  mutation DeleteMenuVersion($versionId: ID!, $menuParentId: ID!) {
    deleteMenuVariant(variantId: $versionId, parentId: $menuParentId) {
      id
      versionName
    }
  }
`

export const DELETE_MENU_NODE = gql`
  mutation DeleteMenuNode($input: MenuNodeInput) {
    deleteNodes(input: $input) {
      version
    }
  }
`

export const DELETE_MENU_NODE_WITH_RESPONSE = gql`
  mutation DeleteMenuNodeWithResponse($input: MenuNodeInput) {
    deleteNodes(input: $input) {
      graph
      updatedNode {
        id
        name
        url
        images {
          locales
          url
          altText
        }
        attributes {
          kind
          value
        }
      }
      version
    }
  }
`

export const MENU_VARIANT_AND_NODE_LIST = gql`
  query MenuVariantAndNodeList($variantId: ID!, $parentId: ID!) {
    menuVariantById(variantId: $variantId, parentId: $parentId) {
      id
      versionName
      description
      status
      startDate
      endDate
      updatedAt
      isArchived
      version
      graph
    }
    menuNodesAsList(
      input: { variantId: $variantId, parentId: $parentId, nodeId: $variantId }
    ) {
      id
      name
      url
      images {
        locales
        url
        altText
        name
        description
      }
      attributes {
        kind
        value
      }
    }
  }
`

export const CREATE_MENU_VARIANT = gql`
  mutation CreateMenuVariant(
    $parentContentId: ID!
    $name: String!
    $description: String
  ) {
    createMenuVariant(
      input: {
        parentContentId: $parentContentId
        name: $name
        description: $description
      }
    ) {
      id
      versionName
      description
      status
      isArchived
      graph
      version
      startDate
      updatedAt
    }
  }
`

export const UPDATE_MENU_VARIANT_DETAILS = gql`
  mutation UpdateMenuVariantDetails($input: UpdateContentVariantInput!) {
    updateMenuVariantDetails(input: $input) {
      versionName
      status
    }
  }
`

export const UPDATE_MENU_DETAILS = gql`
  mutation UpdateMenuDetails(
    $id: ID!
    $name: String!
    $channels: [String!]
    $locales: [String!]
  ) {
    updateMenuDetails(
      input: { id: $id, name: $name, channels: $channels, locales: $locales }
    ) {
      id
      channels {
        name
      }
      name
      isArchived
      variants {
        versionName
        id
      }
      locales
    }
  }
`

export const SCHEDULE_MENU_VARIANT = gql`
  mutation SchedulePublish($input: SchedulePublishInput!) {
    schedulePublish(input: $input) {
      contentId
      variantId
      status
    }
  }
`

export const PUBLISH_MENU_VARIANT = gql`
  mutation ImmediatePublish($contentId: ID!, $variantId: ID!) {
    immediatePublish(contentId: $contentId, variantId: $variantId) {
      contentId
      variantId
      status
      updatedAt
      startDate
    }
  }
`

export const COPY_MENU = gql`
  mutation CopyMenu($input: CopyContentMetaInput!) {
    copyMenu(input: $input) {
      id
      name
      updatedAt
      isArchived
      isActive
      locales
      channels {
        id
      }
      variants {
        id
        description
        versionName
        status
        version
        graph
      }
    }
  }
`

export const COPY_MENU_ASYNC = gql`
  mutation CopyMenu($input: CopyContentMetaInput!) {
    copyMenuAsync(input: $input) {
      status
      message
    }
  }
`

export const COPY_MENU_VARIANT = gql`
  mutation CopyMenuVariant($input: CopyContentVariantInput!) {
    copyMenuVariant(input: $input) {
      id
      versionName
      description
    }
  }
`
