import {
  StyledAddComponentForm,
  StyledCancelAction,
  StyledComponent,
  StyledTitle,
} from './styles'
import Modal from 'components/Modal'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { getDescriptors } from 'modules/editor/selectors'
import { useLocation } from '@reach/router'

const ComponentDialog = props => {
  const { open, onClose, onChoose, descriptors } = props
  const { pathname } = useLocation()

  return (
    <StyledAddComponentForm data-testid='component-dialog-form'>
      <Modal
        isVisible={open}
        width='322px'
        showClose={false}
        headerText=''
        customClass='add-component'
      >
        <StyledTitle>
          <span data-testid='component-dialog-title'>Add new</span>
          <StyledCancelAction
            onClick={onClose}
            data-testid='component-dialog-cancel-button'
          >
            Cancel
          </StyledCancelAction>
        </StyledTitle>
        <div data-testid='component-dialog-label-list'>
          {Array.isArray(descriptors) &&
            descriptors
              .filter(descriptor => {
                const { isGlobal } = descriptor
                if (isGlobal && pathname === '/xpm/editor') {
                  return false
                }
                return true
              })
              .map(descriptor => (
                <StyledComponent
                  key={descriptor.id}
                  onClick={() => {
                    onChoose(descriptor)
                    onClose()
                  }}
                  className='component-dialog-label'
                  data-testid={`component-dialog-label-${descriptor.id}`}
                >
                  {descriptor.label}
                </StyledComponent>
              ))}
        </div>
      </Modal>
    </StyledAddComponentForm>
  )
}

ComponentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  descriptors: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onChoose: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  descriptors: getDescriptors(state),
})

export default connect(mapStateToProps)(ComponentDialog)
