import { NODE_WIDTH } from 'modules/browse-menu-tree-gql/constants'
import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

interface ThemeProps {
  theme: {
    space: Record<string, string>
  }
}

export const StyledWrapper = styled.div`
  .new-node-blank {
    color: ${themeUi.palette.brand.primary.gray};
  }
`

export const StyledLevel = styled.div`
  margin: ${({ theme }: ThemeProps) =>
    `${theme.space.space_5} ${theme.space.space_6}`};
  width: ${NODE_WIDTH};

  &:first-child {
    margin-left: 0;
  }
`

export const StyledLevelHeader = styled.div`
  margin-bottom: ${({ theme }) => theme.space.space_4};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  .level-name {
    font-size: ${({ theme }) => theme.typography.fontSize.xs};
  }

  div[data-testid='menu-content'] {
    border: 1px solid #b9bfc7;
    border-radius: 4px;
    width: ${({ theme }) => theme.constants.menuTreeContextMenu};

    div {
      display: block;
      padding: 22px 26px;
      text-align: left;
    }
  }

  div[data-testid='menu-icon'] {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${themeUi.palette.brand.primary.white};
    border-radius: 24px;
    height: 36px;
    width: 36px;
    ${themeUi.shadows.light.level1}
  }
`
