export enum TABLE_COLUMN_OPTIONS {
  NAME = 'name',
  LOCALE = 'locale',
  CHANNEL = 'channel',
  STATUS = 'status',
  SCHEDULED_AT = 'scheduledAt',
  UPDATED_AT = 'updatedAt',
  CONTEXT_MENU = 'contextMenu',
  ROW_EXPAND_CARET = 'childCaret',
  PAGE_COUNT = 'pageCount',
  PREFIX = 'prefix',
  QUICK_LINK = 'quickLink',
}

export const TABLE_COLUMN_TITLES = {
  [TABLE_COLUMN_OPTIONS.NAME]: 'Name',
  [TABLE_COLUMN_OPTIONS.LOCALE]: 'Locales',
  [TABLE_COLUMN_OPTIONS.CHANNEL]: 'Channels',
  [TABLE_COLUMN_OPTIONS.STATUS]: 'Status',
  [TABLE_COLUMN_OPTIONS.SCHEDULED_AT]: 'Scheduled',
  [TABLE_COLUMN_OPTIONS.UPDATED_AT]: 'Last update',
  [TABLE_COLUMN_OPTIONS.PAGE_COUNT]: '# of pages',
  [TABLE_COLUMN_OPTIONS.PREFIX]: 'Prefix',
  [TABLE_COLUMN_OPTIONS.QUICK_LINK]: '',
  [TABLE_COLUMN_OPTIONS.CONTEXT_MENU]: '',
  [TABLE_COLUMN_OPTIONS.ROW_EXPAND_CARET]: '',
}

export const NULL_ENTRY = ''

export const ROW_CLASS_NAME = 'content-list-row-class'
