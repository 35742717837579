import API from 'src/business-layer-client'
import handleResponse from 'lib/handleResponse'

const DEFAULT_LIMIT = 20
class ChannelService {
  async list({ offset = 0, limit = DEFAULT_LIMIT }) {
    try {
      const endpoint = `/v1/channel?offset=${offset}&limit=${limit}`
      const response = await API.xpm.get(endpoint)
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }
}

export default new ChannelService()
