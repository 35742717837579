import {
  AllGraphQLResponseTypes,
  AllVariableTypes,
  allTransformOperations,
} from './common'
import { PATH_PARAMS_HEADER, PROXY_HEADER } from 'src/graphql-proxy/common'
import {
  geFilterRESTToGQLTransformer,
  geRESTToGQLTransformer,
  geVariantArchiveRESTToGQLTransformer,
  geVariantComponentRESTToGQLTransformer,
  geVariantDuplicateRESTToGQLTransformer,
  geVariantRESTToGQLTransformer,
  geVariantUnarchiveRESTToGQLTransformer,
} from './transformations/globalElements'
import {
  pageArchiveRESTToGQLTransformer,
  pageCopyRESTToGQLTransformer,
  pageFilterRESTToGQLTransformer,
  pageRESTToGQLTransformer,
  pageUnarchiveRESTToGQLTransformer,
} from './transformations/page'
import {
  pageVersionArchiveRESTToGQLTransformer,
  pageVersionRESTToGQLTransformer,
  pageVersionUnarchiveRESTToGQLTransformer,
} from './transformations/page-version'
import { AxiosRequestConfig } from 'axios'
import { GE_OPERATION_OPTIONS } from 'services/graphql'
import { PAGE_OPERATION_OPTIONS } from 'services/graphql/page'
import { PAGE_VERSION_OPERATION_OPTIONS } from 'services/graphql/pageVersion'
import extract from 'lib/extract'
import rfdc from 'rfdc'

const deepClone = rfdc() as (request: AxiosRequestConfig) => AxiosRequestConfig

interface RestOperationInfo {
  [k: string]: {
    gqlTemplate: string
    generateVariables: (arg: Record<string, unknown>) => AllVariableTypes
  }
}

class GraphQLProxy {
  transformMap: Record<string, RestOperationInfo>

  constructor() {
    this.transformMap = {
      [PAGE_OPERATION_OPTIONS.LIST_PAGES]: pageRESTToGQLTransformer,
      [PAGE_OPERATION_OPTIONS.COPY_PAGE]: pageCopyRESTToGQLTransformer,
      [GE_OPERATION_OPTIONS.CREATE_GE_VERSION]: geVariantRESTToGQLTransformer,
      [PAGE_OPERATION_OPTIONS.UPDATE_PAGE]: pageRESTToGQLTransformer,
      [PAGE_OPERATION_OPTIONS.ARCHIVE_PAGE]: pageArchiveRESTToGQLTransformer,
      [PAGE_OPERATION_OPTIONS.UNARCHIVE_PAGE]: pageUnarchiveRESTToGQLTransformer,
      [PAGE_OPERATION_OPTIONS.CREATE_PAGE]: pageRESTToGQLTransformer,
      [GE_OPERATION_OPTIONS.UPDATE_GE_VERSION]: geVariantRESTToGQLTransformer,
      [GE_OPERATION_OPTIONS.ARCHIVE_GE_VERSION]: geVariantArchiveRESTToGQLTransformer,
      [GE_OPERATION_OPTIONS.UNARCHIVE_GE_VERSION]: geVariantUnarchiveRESTToGQLTransformer,
      [GE_OPERATION_OPTIONS.DUPLICATE_GE_VERSION]: geVariantDuplicateRESTToGQLTransformer,
      [PAGE_VERSION_OPERATION_OPTIONS.CREATE_PAGE_VERSION]: pageVersionRESTToGQLTransformer,
      [PAGE_VERSION_OPERATION_OPTIONS.ARCHIVE_PAGE_VERSION]: pageVersionArchiveRESTToGQLTransformer,
      [PAGE_VERSION_OPERATION_OPTIONS.UNARCHIVE_PAGE_VERSION]: pageVersionUnarchiveRESTToGQLTransformer,
      [PAGE_OPERATION_OPTIONS.FILTER_PAGE]: pageFilterRESTToGQLTransformer,
      [GE_OPERATION_OPTIONS.LIST_GE]: geRESTToGQLTransformer,
      [GE_OPERATION_OPTIONS.FILTER_GE]: geFilterRESTToGQLTransformer,
      [GE_OPERATION_OPTIONS.UPDATE_GE_COMPONENT]: geVariantComponentRESTToGQLTransformer,
    }
  }

  fetchGraphQLFnId = (headers?: { [PROXY_HEADER]?: string }): string => {
    let graphQlFnId = null
    const graphQLTransformId = headers ? headers[PROXY_HEADER] : null
    if (graphQLTransformId in this.transformMap) {
      graphQlFnId = graphQLTransformId
    }
    return graphQlFnId
  }

  getGraphQLRawData = (
    graphQlFnId: string,
    method: string,
    queryParamValues: Record<string, unknown>,
    bodyParamValues: Record<string, unknown>,
    pathParamValues: Record<string, unknown>
  ) => {
    const { gqlTemplate, generateVariables } = this.transformMap[graphQlFnId][
      method
    ]

    const variables = generateVariables({
      queryParamValues,
      bodyParamValues,
      pathParamValues,
    })

    return {
      query: gqlTemplate,
      variables,
    }
  }

  transformRestToGraphQlRequest = (
    request: AxiosRequestConfig<Record<string, unknown>>,
    graphQlFnId: string
  ) => {
    const {
      data: bodyParamValues = null,
      baseURL,
      url,
      method,
      headers = {},
    } = request

    const queryParamValues = extract.queryParams(`${baseURL}${url}`, true)
    const pathParamValues = (headers[PATH_PARAMS_HEADER] ??
      (null as unknown)) as Record<string, unknown>

    delete headers[PATH_PARAMS_HEADER]
    delete headers[PROXY_HEADER]

    const newRequest = deepClone(request)
    newRequest.method = 'post'
    newRequest.url = 'graphql'
    newRequest.data = this.getGraphQLRawData(
      graphQlFnId,
      method,
      queryParamValues,
      bodyParamValues,
      pathParamValues
    )
    // This custom header is so we can tell whether this request was rerouted or not
    newRequest.headers.ReroutedToGraphQL = true
    return newRequest
  }

  transformGraphqlToRestRes = (
    responseData: AllGraphQLResponseTypes,
    variables: AllVariableTypes,
    transformOperationKey: string
  ) => {
    return allTransformOperations[transformOperationKey](
      responseData,
      variables
    )
  }
}

export default new GraphQLProxy()
