import {
  StyledFadeIn,
  StyledIconWrapper,
  StyledItemLabel,
  StyledItemLabelWrapper,
  StyledItemWrapper,
} from '../styles'
import AnyType from 'components/type-components/AnyType'
import ComponentContextMenu from 'components/SortableComponent/ComponentContextMenu'
import IconArrowDown from 'assets/icons/IconArrowDropDown'
import IconArrowRight from 'assets/icons/IconArrowRight'
import IconDelete from 'assets/icons/IconDelete'
import React from 'react'
import { SortableElement } from 'react-sortable-hoc'
import { Tooltip } from '@teamfabric/copilot-ui'

const SortableArrayItem = SortableElement(
  ({
    idx,
    itemIsExpanded,
    label,
    canDelete,
    toggleExpandedItem,
    handleOnDeleteIconClick,
    handleOnInsertItem,
    isNested,
    fieldDescriptorMap,
    sortPrimitiveValues,
    getFieldValue,
    value,
    descriptors,
    props,
    flyoutId,
    hasEditAccess,
  }) => {
    return (
      <>
        <StyledItemWrapper
          level={props.level + 1}
          onClick={() => toggleExpandedItem(idx)}
          data-testid={`sortable-array-item-${idx}`}
        >
          <StyledIconWrapper>
            {itemIsExpanded ? <IconArrowDown /> : <IconArrowRight />}
          </StyledIconWrapper>
          <StyledItemLabelWrapper>
            <StyledItemLabel
              level={props.level + 1}
              data-testid={`array-attribute-child-label-${idx + 1}`}
            >
              {label}
            </StyledItemLabel>
            <Tooltip
              size='small'
              className='custom-tooltip'
              data-testid={`array-attribute-child-tooltip-${idx + 1}`}
            >
              {label}
            </Tooltip>
          </StyledItemLabelWrapper>
          {hasEditAccess && (
            <>
              <ComponentContextMenu
                handleOnClickDuplicate={() => handleOnInsertItem(idx)}
                flyoutId={flyoutId}
              />
              {canDelete && (
                <StyledIconWrapper
                  onClick={e => handleOnDeleteIconClick(e, idx)}
                  data-testid='icon-delete'
                >
                  <IconDelete
                    className='icon-delete'
                    width='18px'
                    height='18px'
                  />
                </StyledIconWrapper>
              )}
            </>
          )}
        </StyledItemWrapper>
        {itemIsExpanded && (
          <>
            {!isNested &&
              fieldDescriptorMap &&
              sortPrimitiveValues(fieldDescriptorMap)
                .filter(key => key !== 'components')
                .map(key => {
                  const fieldDescriptor = fieldDescriptorMap[key]
                  return (
                    <StyledFadeIn key={key}>
                      <AnyType
                        type={fieldDescriptor}
                        level={props.level + 2}
                        descriptor={fieldDescriptor}
                        paths={props.paths?.concat(idx, key)}
                        value={getFieldValue(value, key, fieldDescriptor.type)}
                        onChange={props.onChange}
                      />
                    </StyledFadeIn>
                  )
                })}
            {isNested && (
              <AnyType
                key={value.key}
                type={{ type: 'Component' }}
                level={props.level + 1}
                isDeleteable={false}
                descriptor={descriptors.find(d => d.id === value.id)}
                expandedProperty={props.expandedProperty}
                setExpandedProperty={props.setExpandedProperty}
                paths={props.paths?.concat(idx, 'params')}
                value={value.params}
                onChange={props.onChange}
              />
            )}
          </>
        )}
      </>
    )
  }
)

export default SortableArrayItem
