import {
  ARCHIVE_PAGE_VERSION_STRING,
  CREATE_PAGE_VERSION_STRING,
  PAGE_VERSION_OPERATION_OPTIONS,
  UNARCHIVE_PAGE_VERSION_STRING,
} from 'services/graphql/pageVersion'
import {
  AllGraphQLResponseTypes,
  AllVariableTypes,
} from 'src/graphql-proxy/common'
import {
  PageVersionArchiveGraphQLResponse,
  PageVersionArchiveRESTResponse,
  PageVersionArchiveVariables,
  PageVersionCreateGqlResponse,
  PageVersionCreateGraphQLResponse,
  PageVersionCreateVariables,
  PageVersionUnarchiveGraphQLResponse,
  PageVersionUnarchiveRESTResponse,
  PageVersionUnarchiveVariables,
} from './types'

export const pageVersionRESTToGQLTransformer = {
  post: {
    gqlTemplate: CREATE_PAGE_VERSION_STRING,
    generateVariables: ({
      bodyParamValues: { versionName, description },
      pathParamValues: { pageId },
    }: {
      bodyParamValues: { versionName: string; description: string }
      pathParamValues: { pageId: string }
    }): PageVersionCreateVariables => {
      return {
        input: {
          parentContentId: pageId,
          name: versionName,
          description,
        },
        transformOperationKey:
          PAGE_VERSION_OPERATION_OPTIONS.CREATE_PAGE_VERSION,
      }
    },
  },
}

export const pageVersionTransformOperations: Record<
  string,
  (
    arg1: AllGraphQLResponseTypes,
    arg2: AllVariableTypes
  ) => { version: PageVersionCreateGqlResponse }
> = {
  [PAGE_VERSION_OPERATION_OPTIONS.CREATE_PAGE_VERSION]: (
    responseData: PageVersionCreateGraphQLResponse
  ): { version: PageVersionCreateGqlResponse } => {
    const { id: versionId, ...rest } = responseData.createPageVariant

    return {
      version: { ...rest, versionId },
    }
  },
  [PAGE_VERSION_OPERATION_OPTIONS.ARCHIVE_PAGE_VERSION]: (
    responseData: PageVersionArchiveGraphQLResponse
  ): PageVersionArchiveRESTResponse => {
    const {
      versionName,
      isArchived,
      ...rest
    } = responseData.archiveContentVariant
    return {
      version: { ...rest, versionName, isArchived, archived: isArchived },
    }
  },
  [PAGE_VERSION_OPERATION_OPTIONS.UNARCHIVE_PAGE_VERSION]: (
    responseData: PageVersionUnarchiveGraphQLResponse
  ): PageVersionUnarchiveRESTResponse => {
    const {
      versionName,
      isArchived,
      ...rest
    } = responseData.unarchiveContentVariant

    return {
      version: { ...rest, versionName, isArchived, archived: isArchived },
    }
  },
}

export const pageVersionArchiveRESTToGQLTransformer = {
  put: {
    pathParamAttributes: ['versionId', 'pageId'],
    gqlTemplate: ARCHIVE_PAGE_VERSION_STRING,
    generateVariables: ({
      pathParamValues,
    }: {
      pathParamValues: {
        versionId: string
        pageId: string
      }
    }): PageVersionArchiveVariables => {
      const { versionId, pageId } = pathParamValues
      return {
        contentVariantId: versionId,
        parentContentId: pageId,
        transformOperationKey:
          PAGE_VERSION_OPERATION_OPTIONS.ARCHIVE_PAGE_VERSION,
      }
    },
  },
}

export const pageVersionUnarchiveRESTToGQLTransformer = {
  put: {
    pathParamAttributes: ['versionId', 'pageId'],
    gqlTemplate: UNARCHIVE_PAGE_VERSION_STRING,
    generateVariables: ({
      pathParamValues,
    }: {
      pathParamValues: {
        versionId: string
        pageId: string
      }
    }): PageVersionUnarchiveVariables => {
      const { versionId, pageId } = pathParamValues
      return {
        contentVariantId: versionId,
        parentContentId: pageId,
        transformOperationKey:
          PAGE_VERSION_OPERATION_OPTIONS.UNARCHIVE_PAGE_VERSION,
      }
    },
  },
}
