import Header from 'components/Header'
import Menu from 'modules/menu'
import React from 'react'

const MenuPage = () => {
  return (
    <>
      <Header />
      <Menu />
    </>
  )
}

export default MenuPage
