import { MESSAGES, TOAST_MESSAGE_TYPES } from 'src/constants'
import ValidUrl from 'valid-url'
import join from 'url-join'
import normalizeURL from 'normalize-url'
import { showToast } from 'components/ToastSnackbarContainer'

export const getIframeSource = (payload = {}) => {
  const { baseURL = null, type = 'EDITOR' } = payload

  let urlToReturn = 'about:blank'

  if (
    !baseURL ||
    !ValidUrl.isUri(baseURL) ||
    !type ||
    !['EDITOR', 'GLOBAL_EDITOR'].includes(type)
  ) {
    showToast({
      message: MESSAGES.EMPTY_CHANNEL_URL_ERROR,
      kind: TOAST_MESSAGE_TYPES.ALERT,
    })
    return urlToReturn
  }

  try {
    const route = type === 'GLOBAL_EDITOR' ? '/cms/preview-gc' : '/cms/preview'
    const url = join(baseURL, route)
    urlToReturn = normalizeURL(url, { stripWWW: false })
  } catch (e) {
    console.error(`Error getting iframe source: ${e}`)
  }
  return urlToReturn
}
