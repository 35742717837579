import {
  Attribute,
  Image,
  MenuNodesAsList,
  MenuTreeNode,
  MenuVariantById,
  Names,
} from 'modules/browse-menu-tree-gql/types'

export function constructInitialMenuTree({
  menuNodesAsList,
  menuVariantById,
  versionId,
}: {
  menuNodesAsList: MenuNodesAsList[]
  menuVariantById: MenuVariantById
  versionId: string
}): MenuTreeNode[][] {
  const { graph } = menuVariantById

  const rootNodesIdList = graph[versionId].edges

  const nodeIdMap = new Map<
    string,
    { url: string; name: Names; attributes: Attribute[]; images: Image[] }
  >()
  menuNodesAsList.forEach(menuNode => {
    const { url, name, attributes, images } = menuNode
    nodeIdMap.set(menuNode.id, { url, name, attributes, images })
  })

  return [
    rootNodesIdList.map((rootNodeId, ind) => {
      const rootNodeGraphData = graph[rootNodeId]
      const rootNodeIdElement = nodeIdMap.get(rootNodeId)

      return {
        _id: rootNodeId,
        parent: versionId,
        name: rootNodeIdElement.name,
        rootNodeName: rootNodeIdElement.name,
        section: rootNodeGraphData.section,
        url: rootNodeIdElement.url,
        disabled: !rootNodeGraphData.isActive,
        order: ind,
        images: rootNodeIdElement.images,
        attributes: rootNodeIdElement.attributes,
      }
    }),
  ]
}
