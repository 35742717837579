import { StyledButtonWrapper, StyledHeader } from './styles'
import { DescriptorHeaderProps } from './types'
import React from 'react'
import SingleDescriptorButtonGroup from './SingleDescriptorButtonGroup'

const DescriptorHeader: React.FunctionComponent<DescriptorHeaderProps> = ({
  mode,
  setMode,
  label,
  handleSubmit,
  handleCancel,
  submitDisabled,
  showDeleteModal,
}) => {
  return (
    <StyledHeader>
      <p data-testid='descriptor-title'>{label}</p>
      <StyledButtonWrapper>
        <SingleDescriptorButtonGroup
          mode={mode}
          setMode={setMode}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          submitDisabled={submitDisabled}
          showDeleteModal={showDeleteModal}
        />
      </StyledButtonWrapper>
    </StyledHeader>
  )
}

export default DescriptorHeader
