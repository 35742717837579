import { generateTableColumns, getEmptyTableMessage } from './utils'
import { ContentTableProps } from './types'
import EmptyTableView from 'components/SearchField/EmptyTableView'
import { NO_RECORDS_FOUND } from 'src/constants'
import NoSearchResults from 'components/SearchField/NoSearchResults'
import { ROW_CLASS_NAME } from './constants'
import React from 'react'
import { StyledContentTableWrapper } from './styles'
import { Table } from '@teamfabric/copilot-ui'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'

const ContentTableModule = React.memo<ContentTableProps>(
  ({
    data,
    columns,
    loading,
    paginationProps: {
      itemsPerPage,
      currentPage,
      totalItems,
      showPagination,
      handlePagination,
    },
    noResults,
  }) => {
    if (isEmpty(columns)) {
      return null
    }

    const tableCompatibleColumns = generateTableColumns(columns)

    const noResultsTable = () => {
      const {
        searchTerm = '',
        onClearSearch = noop,
        isArchivedTab = false,
        contentType,
      } = noResults
      const emptyTableMessage = getEmptyTableMessage({
        contentType,
        isArchivedTab,
      })

      if (!loading && searchTerm !== '' && isEmpty(data)) {
        return <NoSearchResults columns={columns} resetSearch={onClearSearch} />
      }

      if (!loading && isEmpty(data)) {
        return (
          <EmptyTableView
            columns={columns}
            primaryText={NO_RECORDS_FOUND}
            secondaryText={emptyTableMessage}
          />
        )
      }
      return null
    }

    return (
      <StyledContentTableWrapper>
        <Table
          columns={tableCompatibleColumns}
          data={data ?? []}
          loading={loading}
          rowClassName={ROW_CLASS_NAME}
          perPage={itemsPerPage}
          activePageNumber={currentPage}
          showPagination={showPagination}
          totalRecords={totalItems}
          handlePagination={handlePagination}
          {...(noResults && { render: noResultsTable })}
        />
      </StyledContentTableWrapper>
    )
  }
)

export default ContentTableModule
