import {
  GEContent,
  GEVariantGraphQLResponse,
} from 'src/graphql-proxy/transformations/globalElements/types'
import { GEModalDetails, GE_MODAL_ID } from 'data/global-elements-list/types'
import {
  getContentVariantEditorContextOptions,
  getIsScheduledOrLive,
} from 'data/utils'
import { CONTEXT_MENU_OPTIONS } from 'components/ContentContextMenuModule/constants'
import { ContextOption } from 'data/types'
import { navigateToGEVariantEditor } from './modal-operations'
import { useCallback } from 'react'
import { useNavigate } from '@reach/router'
import { useUserPermissions } from 'contexts/userPermissions'

export const useGEVersionCtxOptions = ({
  handlers: { showTableModal },
}: {
  handlers: { showTableModal: (details: GEModalDetails) => void }
}): ((
  item: GEContent,
  version: GEVariantGraphQLResponse
) => ContextOption[]) => {
  const navigate = useNavigate()
  const userPermissions = useUserPermissions()
  return useCallback(
    (item: GEContent, version: GEVariantGraphQLResponse) => {
      const versionId = version?.id
      const globalElementId = item.id

      const showModal = (id: string) => {
        return showTableModal({
          visibleModalId: id,
          targetItem: { contentId: versionId, parentId: item.id },
          globalElement: item,
        })
      }

      const deleteOption = {
        label: CONTEXT_MENU_OPTIONS.DELETE,
        onClick: () => {
          showModal(GE_MODAL_ID.DELETE)
        },
      }

      const unarchiveOption = {
        label: CONTEXT_MENU_OPTIONS.UNARCHIVE,
        onClick: () => {
          showModal(GE_MODAL_ID.UNARCHIVE)
        },
      }

      const editOption = {
        label: CONTEXT_MENU_OPTIONS.EDIT,
        onClick: () =>
          navigateToGEVariantEditor(globalElementId, versionId, navigate),
      }

      const makeACopyOption = {
        label: CONTEXT_MENU_OPTIONS.MAKE_A_COPY,
        onClick: () => {
          showModal(GE_MODAL_ID.MAKE_A_COPY_VERSION)
        },
      }

      const previewOption = {
        label: CONTEXT_MENU_OPTIONS.PREVIEW,
        onClick: () =>
          navigateToGEVariantEditor(globalElementId, versionId, navigate, {
            preview: true,
          }),
      }

      const archiveOption = {
        label: CONTEXT_MENU_OPTIONS.ARCHIVE,
        onClick: () => {
          showModal(GE_MODAL_ID.ARCHIVE)
        },
      }

      const isViewer = !userPermissions.hasEditorPermissions
      if (isViewer) {
        return version.isArchived ? [] : [previewOption]
      }

      const isEditor = !userPermissions.hasPublisherPermissions
      if (isEditor) {
        return getContentVariantEditorContextOptions(version, {
          unarchiveOption,
          deleteOption,
          makeACopyOption,
          previewOption,
          archiveOption,
        })
      }

      if (version.isArchived) {
        return [unarchiveOption, deleteOption]
      }

      return [
        ...(getIsScheduledOrLive(version?.status) ? [editOption] : []),
        makeACopyOption,
        previewOption,
        archiveOption,
        deleteOption,
      ]
    },
    [userPermissions, showTableModal, navigate]
  )
}

export const useGEPageCtxOptions = ({
  handlers: { showTableModal },
}: {
  handlers: { showTableModal: (details: GEModalDetails) => void }
}): ((item: GEContent) => ContextOption[]) => {
  const userPermissions = useUserPermissions()

  return useCallback(
    (item: GEContent) => {
      const isPublisher = userPermissions.hasPublisherPermissions
      const isDraftOrEnded = item?.variants?.every(
        variant => variant?.status === 'DRAFT' || variant.status === 'ENDED'
      )
      const isEditor = userPermissions.hasEditorPermissions

      const showModal = (id: string) => {
        return showTableModal({
          visibleModalId: id,
          targetItem: { parentId: item.id },
          globalElement: item,
        })
      }
      const deletePage = {
        label: CONTEXT_MENU_OPTIONS.DELETE,
        onClick: () => {
          showModal(GE_MODAL_ID.DELETE_PARENT_GE)
        },
      }

      const makeACopyOption = {
        label: CONTEXT_MENU_OPTIONS.MAKE_A_COPY,
        onClick: () => {
          showModal(GE_MODAL_ID.MAKE_A_COPY)
        },
      }

      const newVersion = {
        label: CONTEXT_MENU_OPTIONS.NEW_VERSION,
        onClick: () => {
          showModal(GE_MODAL_ID.CREATE_NEW_VERSION)
        },
      }
      if (isPublisher) {
        return [deletePage, newVersion, makeACopyOption]
      }
      if (isEditor) {
        return isDraftOrEnded
          ? [deletePage, newVersion, makeACopyOption]
          : [newVersion, makeACopyOption]
      }
      return []
    },
    [userPermissions, showTableModal]
  )
}
