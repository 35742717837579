import { Filters, OptionsListTypes } from './types'
import { MultiSelectOptions } from 'src/components/FilterBy/types'
import { filterName } from './constants'

const pagesPath = '/pages'

export const isPagesPath = (pathname: string): boolean => {
  return pathname.includes(pagesPath)
}

export const getMultiSelectOptions = (
  optionsList: OptionsListTypes,
  filters: Filters,
  optionType: string
): MultiSelectOptions[] => {
  if (
    !Array.isArray(optionsList) ||
    !Object.keys(filterName).includes(optionType)
  ) {
    return []
  }
  const { status = [], channels = [], pageTypes = [], locales = [] } = filters
  return optionsList.map(option => {
    let id = '',
      name = '',
      isChecked = false
    switch (optionType) {
      case filterName.status:
        id = option as string
        name = option as string
        isChecked = status.includes(option as string)
        break
      case filterName.channels:
        id = option.id as string
        name = option.label as string
        isChecked = channels.includes(option.id as string)
        break
      case filterName.pageTypes:
        id = option._id as string
        name = option.name as string
        isChecked = pageTypes.includes(option._id as string)
        break
      case filterName.locales:
        id = option.code as string
        name = option.code as string
        isChecked = locales.includes(option.code as string)
        break
      default:
        break
    }
    return {
      id,
      name,
      isChecked,
    }
  })
}
