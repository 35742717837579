import { ALT_TEXT_LABEL, COMPONENT_IMAGE_TYPE, FF_NAMES } from 'src/constants'
import React, { memo, useCallback, useState } from 'react'
import { StyledImageSelection, StyledThumbnailContainer } from './styles'
import { StyledLabel, StyledWrapper } from 'components/type-components/styles'
import ConfirmDialog from 'components/ConfirmDialog'
import DAMAssetModal from './DAMAssetModal'
import { Icon } from '@teamfabric/copilot-ui'
import ImageButton from 'components/type-components/ImageType/components/image-button'
import ImageModal from './ImageModal'
import PropTypes from 'prop-types'
import StringType from '../StringType'
import { VALUE_PROP_DEFAULTS } from '../constants'
import cn from 'classnames'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'react-fast-compare'
import { useFlag } from '@unleash/proxy-client-react'

const {
  unleashFFs: { USE_DAM },
} = FF_NAMES
const { CONFIRMATION, EDIT, DELETE } = COMPONENT_IMAGE_TYPE

const ImageType = props => {
  const { descriptor, paths, value, onChange, hasEditAccess } = props
  const shouldUseDAM = useFlag(USE_DAM) ?? false

  const [showImageModal, setShowImageModal] = useState(false)
  const [showIcons, setShowIcons] = useState(false)
  const [showImageDeleteModal, setShowImageDeleteModal] = useState(false)

  const renderImageModal = () => {
    if (!showImageModal) {
      return null
    }

    if (shouldUseDAM) {
      return (
        <DAMAssetModal
          value={value}
          onClose={onClose}
          paths={paths}
          onChange={onChange}
        />
      )
    }

    return (
      <ImageModal
        isVisible={showImageModal}
        onClose={onClose}
        descriptor={descriptor}
        paths={paths}
        value={value}
        onChange={onChange}
      />
    )
  }

  const onClose = useCallback(() => {
    setShowImageModal(false)
  }, [])

  const toggleImageModal = useCallback(() => {
    setShowImageModal(showModal => !showModal)
    setShowIcons(false)
  }, [])

  const handleHover = onHover => {
    const show = imageUrl.length && onHover
    setShowIcons(show)
  }

  const handleDeleteImage = () => {
    setShowImageDeleteModal(false)
    onChange({
      paths,
      value: {
        altText: '',
        url: '',
      },
    })
  }

  const deleteDialog = {
    isVisible: true,
    title: CONFIRMATION.heading,
    content: CONFIRMATION.text,
    cancelText: CONFIRMATION.cancelText,
    submitText: CONFIRMATION.submitText,
    onCancel: () => setShowImageDeleteModal(false),
    onSubmit: handleDeleteImage,
  }

  const imageUrl = props.value?.url ?? ''
  const altText = props.value?.altText ?? ''
  return (
    <>
      <StyledWrapper data-testid='image-type-wrapper' isImageType>
        <div className='label-padding'>
          <StyledLabel>{descriptor.label}</StyledLabel>
        </div>
        <StyledThumbnailContainer
          data-testid='thumbnail-container'
          onMouseEnter={() => handleHover(true)}
          onMouseLeave={() => handleHover(false)}
        >
          {renderImageModal()}
          <StyledImageSelection
            onClick={hasEditAccess && toggleImageModal}
            data-testid='image-modal-button'
            hasEditAccess={hasEditAccess}
          >
            <ImageButton
              imageUrl={imageUrl}
              altText={altText}
              onHover={showIcons}
            />
            {hasEditAccess && (
              <Icon
                iconName={EDIT}
                size={16}
                className={cn('edit-icon', { show: showIcons })}
              />
            )}
          </StyledImageSelection>
          {hasEditAccess && (
            <Icon
              iconName={DELETE}
              size={24}
              className={cn('trash-icon', { show: showIcons })}
              onClick={() => setShowImageDeleteModal(true)}
            />
          )}
        </StyledThumbnailContainer>
        {showImageDeleteModal && <ConfirmDialog {...deleteDialog} />}
      </StyledWrapper>
      {shouldUseDAM && !isEmpty(value?.url) && (
        <StyledWrapper isImageType>
          <StringType
            descriptor={descriptor?.altText ?? ALT_TEXT_LABEL}
            paths={[...paths, 'altText']}
            onChange={onChange}
            value={value?.altText}
            hasEditAccess={hasEditAccess}
          />
        </StyledWrapper>
      )}
    </>
  )
}

ImageType.defaultProps = {
  descriptor: {},
  value: VALUE_PROP_DEFAULTS.Image,
}

ImageType.propTypes = {
  descriptor: PropTypes.object.isRequired,
  paths: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
}

export default memo(ImageType, isEqual)
