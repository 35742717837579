import { VALIDATION_ERRORS } from 'src/constants'
import filterBooleanObject from 'lib/filterBooleanObject/filterBooleanObject'

const validation = (fields = {}) => {
  const errorMessage = VALIDATION_ERRORS.EMPTY_VERSION_NAME
  return filterBooleanObject({
    versionName: !fields.versionName ? errorMessage : undefined,
  })
}

export default validation
