import { MESSAGES, TOAST_MESSAGE_TYPES } from 'src/constants'
import PageTypeService from 'services/api/pageType'
import { createActionPrefix } from 'redux-nano'
import { showToast } from 'components/ToastSnackbarContainer'

const createAction = createActionPrefix('PAGE_TYPE')

export const PAGE_TYPES_INIT = 'PAGE_TYPES_INIT'
export const GET_PAGE_TYPES = 'GET_PAGE_TYPES'
export const PAGE_TYPE_REQUEST_SUBMIT = 'PAGE_TYPE_REQUEST_SUBMIT'
export const PAGE_TYPE_REQUEST_SUCCESS = createAction(
  'PAGE_TYPE_REQUEST_SUCCESS'
)
export const PAGE_TYPE_REQUEST_ERROR = 'PAGE_TYPE_REQUEST_ERROR'

const DEFAULT_OFFSET = 0
const DEFAULT_ALL_PAGETYPE_LIMIT = 1000

export const fetchAllPageTypes = async (
  offset = DEFAULT_OFFSET,
  limit = DEFAULT_ALL_PAGETYPE_LIMIT
) => {
  try {
    const res = await PageTypeService.list(offset, limit)
    return res.pageTypes
  } catch (err) {
    console.error(err)
    showToast({
      message: MESSAGES.getFetchingError('page types'),
      kind: TOAST_MESSAGE_TYPES.ALERT,
    })
  }
}

export const pageTypesInit = payload => {
  return {
    type: PAGE_TYPES_INIT,
    payload,
  }
}
