import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledImageSelection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  cursor: ${({ hasEditAccess }) => hasEditAccess && 'pointer'};
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  border-radius: ${props => props.theme.borderRadius.sm};
  background-color: ${({ theme }) => theme.colors.white};
  margin: 0.25rem 0;
  position: relative;

  &:hover {
    border-color: ${({ theme, hasEditAccess }) =>
      hasEditAccess && theme.colors.gray[300]};
  }
  svg > g > path:nth-child(2) {
    fill: ${({ theme }) => theme.colors.gray[500]};
  }
`

export const StyledThumbnail = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  overflow: hidden;

  .styled-thumbnail-img-container {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: auto;

    &.blurred {
      opacity: 0.5;
    }
  }

  .delete-asset {
    right: 0;
    top: 0;
    position: absolute;
    border-radius: 60px;
    background: ${themeUi.palette.brand.primary.white};
    width: 24px;
    height: 24px;

    .close-button {
      width: 100%;
      height: 100%;
      padding: 5px;
      border: unset;
      box-shadow: ${({ theme }) => theme.boxShadow.default};
    }
  }
`

export const StyledThumbnailContainer = styled.div`
  align-items: center;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 0;
  display: flex;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.space.space_3};

  &:hover {
    background: ${themeUi.palette.ui.neutral.grey4};
    border-color: ${themeUi.palette.ui.neutral.grey3};
  }

  .edit-icon {
    position: absolute;
  }

  .edit-icon,
  .trash-icon {
    display: none;

    &:hover {
      cursor: pointer;
    }

    &.show {
      display: block;
    }
  }
`
