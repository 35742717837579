import IconBack from 'assets/icons/IconBack'
import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledContainer = styled.div`
  padding: 2.5rem ${({ theme }) => theme.space.space_6};
`

export const StyledIconBack = styled(IconBack)`
  width: 16px;
  height: 16px;
`

export const StyledBackButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${themeUi.palette.brand.primary.charcoal};
  font-size: ${({ theme }) => theme.typography.fontSize.xs};
  line-height: 14px;
  width: fit-content;
`

export const StyledDescriptorWrapper = styled.div`
  width: 100%;
  background: ${themeUi.palette.brand.primary.white};
  padding: ${({ theme }) => theme.space.space_5};
  margin-top: ${({ theme }) => theme.space.space_6};
`
