import API from 'src/src/business-layer-client'
import { I18nLocaleChannel } from 'store/i18n/types'
import handleResponse from 'lib/handleResponse'

const MERCHANT_CONFIG_PATH = 'merchant-configurations'

class Internationalization {
  async getDefaultChannel(): Promise<I18nLocaleChannel> {
    try {
      const response = await API.i18n.get(
        `/${MERCHANT_CONFIG_PATH}/channels/default`
      )
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }
}

export default new Internationalization()
