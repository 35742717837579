import {
  LEFT_SIDE_BAR_WIDTH,
  ROOT_BREADCRUMB_WIDTH,
} from 'components/AppLayout/constants'
import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledNav = styled.nav`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledItems = styled.ol`
  margin: 0;
  padding-left: 0;
  list-style: none;
  padding: 1rem;
`

export const StyledItem = styled.li`
  display: inline-block;
  font-size: ${props => props.theme.typography.fontSize.sm};
  font-weight: ${props => props.theme.typography.fontWeight.medium};
  color: ${({ isActive }) =>
    isActive
      ? themeUi.palette.brand.primary.charcoal
      : themeUi.palette.brand.primary.gray};
  cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};

  &.active-component-name {
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    width: calc(${LEFT_SIDE_BAR_WIDTH}px - ${ROOT_BREADCRUMB_WIDTH}px);
  }

  & + &::before {
    content: '';
    display: inline-block;
    transform: rotate(15deg);
    border-right: 1px solid ${themeUi.palette.brand.primary.gray};
    height: 1em;
    margin: 0 8px -0.2em;
  }
`
