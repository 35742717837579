import 'react-datepicker/dist/react-datepicker.css'
import {
  Calendar,
  ErrorMessage,
  Modal,
  Timepicker,
} from '@teamfabric/copilot-ui'
import { EDITOR_TOAST_MESSAGES, VERSION_STATUS } from 'modules/editor/constants'
import { FF_NAMES, TOAST_MESSAGE_TYPES } from 'src/constants'
import React, { useEffect, useState } from 'react'
import {
  SCHEDULE_VARIANT,
  UPDATE_GLOBAL_ELEMENTS_AND_SCHEDULE,
} from 'services/graphql'
import {
  StyledDatePickerWrapper,
  StyledInputWrapper,
  StyledLabel,
  StyledModalContent,
  StyledTimePickerWrapper,
} from './styles'
import { getDate, getMonth, getYear, set } from 'date-fns'
import {
  getErrorMessageOnInvalidStartDate,
  getErrorMessageOnInvalidStartTime,
} from 'lib/validation/schedulePublishValidator'
import { isEditorPage, isGcEditorPage } from 'modules/editor/utils'
import { retrieveTitle, retrieveToastMessage } from './utils'
import Alert from 'components/Alert'
import PropTypes from 'prop-types'
import { SHOW_TOAST_MESSAGE } from 'modules/toast-messages/actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import globalElementService from 'services/api/globalElement'
import { handleFormError } from 'components/FormDialog/utils'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import pageService from 'services/api/page'
import useAsync from 'hooks/useAsync'
import { useFlag } from '@unleash/proxy-client-react'
import { useLocation } from '@reach/router'
import { useMutation } from '@apollo/client'
const {
  unleashFFs: { I18N },
} = FF_NAMES
const VersionPublishDialog = props => {
  const {
    handleClose,
    updateStatus,
    globalElement,
    page,
    menu,
    version,
    onSuccess,
    showToastMessage,
    handleOnSaveButtonClick,
    updatedComponents,
    updatedLocalizedComponents,
  } = props
  const shouldUseGraphQL = useFlag(FF_NAMES.unleashFFs.USE_GRAPHQL) ?? false
  const [startDate, setStartDate] = useState(new Date())
  const [startTime, setStartTime] = useState(new Date())
  const [validationErrorMessages, setValidationErrorMessages] = useState({})
  const location = useLocation()

  const [scheduleVariant] = useMutation(SCHEDULE_VARIANT)
  const [updateGlobalElementsAndSchedule] = useMutation(
    UPDATE_GLOBAL_ELEMENTS_AND_SCHEDULE
  )
  const isPage = isEditorPage(location.pathname)
  const isGlobal = isGcEditorPage(location.pathname)
  const isI18n = useFlag(I18N)
  useEffect(() => {
    setValidationErrorMessages({
      datePicker: getErrorMessageOnInvalidStartDate(startDate),
      timePicker: getErrorMessageOnInvalidStartTime(startDate, startTime),
    })
  }, [startDate, startTime])

  const datePickerErrorMessage = validationErrorMessages.datePicker
  const timePickerErrorMessage = validationErrorMessages.timePicker
  const hasErrors = !!(datePickerErrorMessage || timePickerErrorMessage)
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleSubmit = async () => {
    try {
      const scheduleDateAndTime = set(startTime, {
        year: getYear(startDate),
        month: getMonth(startDate),
        date: getDate(startDate),
      })

      const isPastErrorMessage = getErrorMessageOnInvalidStartTime(
        scheduleDateAndTime
      )

      if (isPastErrorMessage) {
        setValidationErrorMessages({ timePicker: isPastErrorMessage })

        return
      }

      let response
      const shouldHandleSave = isPage || (!shouldUseGraphQL && isGlobal)
      if (shouldHandleSave) {
        await handleOnSaveButtonClick()
      }
      if (shouldUseGraphQL || !isEmpty(menu)) {
        if (isGlobal) {
          const contentId = globalElement?.id
          response = await updateGlobalElementsAndSchedule({
            variables: {
              input: {
                contentId,
                variantId: version?.id,
                ...(!isI18n && { components: updatedComponents }),
                ...(isI18n && {
                  localizedComponents: updatedLocalizedComponents,
                }),
                startDate: scheduleDateAndTime.toISOString(),
              },
            },
          })
          showToastMessage({
            message: EDITOR_TOAST_MESSAGES.GLOBAL_ELEMENT_PUBLISH_SUCCESS,
            kind: TOAST_MESSAGE_TYPES.MESSAGE,
          })
        } else {
          const contentId = menu?.id ?? page?.pageId ?? globalElement?.id
          const { data } = await scheduleVariant({
            variables: {
              input: {
                contentId,
                variantId: version.id,
                startDate: scheduleDateAndTime.toISOString(),
              },
            },
          })
          response = data.schedulePublish
        }
      } else if (isPage) {
        response = await pageService.updateVersion(
          {
            pageId: page.pageId,
            versionId: version.versionId,
          },
          {
            startDate: scheduleDateAndTime.toISOString(),
          }
        )
      } else if (isGlobal) {
        response = await globalElementService.updateVersion(
          {
            globalElementId: globalElement.id,
            versionId: version.id,
          },
          {
            startDate: scheduleDateAndTime.toISOString(),
          },
          true
        )
      }

      updateStatus(VERSION_STATUS.SCHEDULED)
      showToastMessage({
        message: retrieveToastMessage(isPage, isGlobal),
        kind: TOAST_MESSAGE_TYPES.SUCCESS,
      })

      if (onSuccess) onSuccess()
      handleClose()
      return response
    } catch (err) {
      handleFormError(err)
    }
  }

  const submission = useAsync(handleSubmit)

  const renderModalContent = () => {
    return (
      <StyledModalContent>
        {submission.error && <Alert>{submission.error.message}</Alert>}
        <h4 data-testid='modal-title'>{retrieveTitle(isPage, isGlobal)}</h4>
        <StyledInputWrapper>
          <StyledDatePickerWrapper>
            <StyledLabel data-testid='styled-label'>Start date</StyledLabel>
            <Calendar
              dateFormat='MMM. dd yyyy'
              minDate={new Date()}
              customInput={({ value }) => setStartDate(value)}
              onDateChange={newDate => setStartDate(newDate)}
              popperPlacement='bottom-start'
            />
            {datePickerErrorMessage && (
              <ErrorMessage
                text={datePickerErrorMessage}
                data-testid='date-picker-error'
              />
            )}
          </StyledDatePickerWrapper>
          <StyledTimePickerWrapper>
            <StyledLabel>Start time</StyledLabel>
            <Timepicker
              date={startTime}
              onChange={newTime => setStartTime(newTime)}
              width='188px'
              canUserTypeIntoInput={true}
            />
            {timePickerErrorMessage && (
              <ErrorMessage
                text={timePickerErrorMessage}
                data-testid='time-picker-error'
              />
            )}
          </StyledTimePickerWrapper>
        </StyledInputWrapper>
      </StyledModalContent>
    )
  }

  return (
    <Modal
      headerButtons={[]}
      headerText=''
      footerButtons={[
        {
          onClick: handleClose,
          text: 'Cancel',
          isPrimary: false,
        },
        {
          'onClick': submission.run,
          'text': 'Save',
          'isPrimary': true,
          'disabled': hasErrors || submission.loading,
          'data-testid': 'submit-button',
        },
      ]}
      size='small'
      showCloseButton={true}
      onBackdropClick={handleClose}
      onClose={handleClose}
      render={renderModalContent}
    />
  )
}

VersionPublishDialog.defaultProps = {
  page: {},
  globalElement: {},
  menu: {},
  version: {},
  updatedComponents: [],
  updatedLocalizedComponents: [],
  handleClose: noop,
  updateStatus: noop,
  handleOnSaveButtonClick: noop,
}

VersionPublishDialog.propTypes = {
  page: PropTypes.shape({
    pageId: PropTypes.number,
    name: PropTypes.string,
    pageUrl: PropTypes.string,
  }),
  globalElement: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  menu: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  version: PropTypes.shape({
    versionId: PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired,
    ]),
    id: PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired,
    ]),
    name: PropTypes.string.isRequired,
  }),
  handleClose: PropTypes.func.isRequired,
  updateStatus: PropTypes.func,
  onSuccess: PropTypes.func,
  showToastMessage: PropTypes.func.isRequired,
  handleOnSaveButtonClick: PropTypes.func.isRequired,
  updatedComponents: PropTypes.shape([
    {
      id: PropTypes.string,
      allowNestedComponents: PropTypes.bool,
      isGlobal: PropTypes.bool,
      isSelected: PropTypes.bool,
      key: PropTypes.string,
      label: PropTypes.string,
      order: PropTypes.number,
      params: PropTypes.shape({}),
    },
  ]),
  updatedLocalizedComponents: PropTypes.shape([
    {
      locale: PropTypes.string,
      components: PropTypes.shape([
        {
          id: PropTypes.string,
          allowNestedComponents: PropTypes.bool,
          isGlobal: PropTypes.bool,
          isSelected: PropTypes.bool,
          key: PropTypes.string,
          label: PropTypes.string,
          order: PropTypes.number,
          params: PropTypes.shape({}),
        },
      ]),
    },
  ]),
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showToastMessage: SHOW_TOAST_MESSAGE,
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(VersionPublishDialog)
