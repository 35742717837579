import React, { memo, useCallback, useMemo, useRef, useState } from 'react'
import {
  StyledLabel,
  StyledLabelWrapper,
  StyledWrapper,
} from 'components/type-components/styles'
import { Input } from '@teamfabric/copilot-ui'
import PropTypes from 'prop-types'
import StyleOptions from 'components/StyleOptions'
import { StyledErrorMessage } from '../styles'
import { VALUE_PROP_DEFAULTS } from '../constants'
import { getActiveLanguage } from 'store/i18n/selectors'
import isEqual from 'react-fast-compare'
import noop from 'lodash/noop'
import { useSelector } from 'react-redux'

const StringType = props => {
  const { descriptor, error, paths, value, onChange, hasEditAccess } = props
  const [showStyleOptions, setShowStyleOptions] = useState(false)
  const wrapperRef = useRef(null)
  const activeLang = useSelector(getActiveLanguage)

  const handleOnChange = useCallback(
    e => {
      onChange({ paths, value: e.target.value })
    },
    [paths, onChange]
  )

  const handleOnFocus = useCallback(() => {
    setShowStyleOptions(true)
  }, [])

  const handleOnBlur = useCallback(() => {
    setShowStyleOptions(false)
  }, [])

  const name = useMemo(() => paths.join('.'), [paths])

  if (!Object.keys(descriptor).length) return null

  const errorMessage = () => {
    if (error) {
      return (
        <StyledErrorMessage>
          <span data-testid='validation-message'>&lt;{error}&gt;</span>
        </StyledErrorMessage>
      )
    }
  }

  return (
    <StyledWrapper ref={wrapperRef} data-testid='string-type-wrapper'>
      <StyledLabelWrapper>
        <StyledLabel>{descriptor.label}</StyledLabel>
        <StyleOptions
          wrapperRef={wrapperRef}
          show={showStyleOptions}
          onBlur={handleOnBlur}
          {...props}
        />
      </StyledLabelWrapper>
      <Input
        inputProps={{
          'defaultValue': value,
          'onFocus': handleOnFocus,
          'onChange': handleOnChange,
          'type': 'text',
          'value': value,
          'disabled': !hasEditAccess,
          'id': name,
          'name': name,
          'data-testid': 'string-type-input',
        }}
        localeCode={activeLang}
      />
      {errorMessage()}
    </StyledWrapper>
  )
}

StringType.defaultProps = {
  descriptor: {},
  paths: [],
  value: VALUE_PROP_DEFAULTS.String,
  error: '',
  onChange: noop,
}

StringType.propTypes = {
  descriptor: PropTypes.object.isRequired,
  paths: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  hasEditAccess: PropTypes.bool.isRequired,
}

export default memo(StringType, isEqual)
