const iframeSize = Object.freeze({
  mobile: {
    device: 'mobile',
    width: '360px',
    height: '640px',
  },
  tablet: {
    device: 'tablet',
    width: '768px',
    height: '1024px',
  },
  desktop: {
    'device': 'desktop',
    'max-width': '1366px',
    'width': '100%',
    'height': '768px',
  },
})

export default iframeSize
