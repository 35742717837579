import styled from 'styled-components'
import { theme } from '@teamfabric/copilot-ui'

export const SidebarContainer = styled.div`
  padding: 20px 0 0 30px;
  [class*='Navigationstyles__StyledLI'] {
    a:focus-visible {
      outline: ${theme.palette.ui.states.active} solid 1px;
    }
  }
`
