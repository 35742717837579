import API from 'src/business-layer-client'
import { getAccountId } from 'lib/cookieUtils'
import handleResponse from 'lib/handleResponse'

class Image {
  constructor(file, fabricServiceName) {
    this.fabricServiceName = fabricServiceName
    this.file = file
    this.uploadError = new Error(`The file upload was not successful.`)
  }

  async getPresignedUrl() {
    const accountId = getAccountId()
    try {
      const response = await API.image.post(
        'v2/image/get-save-url',
        JSON.stringify({
          'filename': this.file.name,
          'fabric-service-name': `${accountId}/${this.fabricServiceName}`,
          'file-type': this.file.type,
        })
      )
      return handleResponse.success(response)
    } catch (err) {
      throw new Error()
    }
  }

  async upload() {
    let presignedUrl = null
    try {
      presignedUrl = await this.getPresignedUrl()
    } catch (err) {
      throw this.uploadError
    }

    if (presignedUrl && presignedUrl.uploadUrl) {
      try {
        await fetch(presignedUrl.uploadUrl, {
          method: 'PUT',
          body: this.file,
        })
        return
      } catch (err) {
        throw this.uploadError
      }
    }
    throw this.uploadError
  }
}

export default Image

export async function uploadImageUrl({ imageUrl, fabricServiceName }) {
  const accountId = getAccountId()
  try {
    const response = await API.image.post('image/upload-from-url', {
      'url': imageUrl,
      'fabric-service-name': `${accountId}/${fabricServiceName}`,
    })
    // Response.data is the filename of uploaded file
    return response.data
  } catch (err) {
    console.error(err)
    throw new Error(`The file upload was not successful.`)
  }
}
