import styled from 'styled-components'

export const StyledButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 20px;
  button:not(:last-child) {
    margin-right: 1em;
  }
`
