import React, { ReactElement } from 'react'
import ContextMenu from 'components/ContextMenu'
import { DescriptorProps } from 'store/types'
import { Icon } from '@teamfabric/copilot-ui'
import { SETTINGS } from 'src/constants'
import { SETTINGS_MENU } from '../types'
import { StyledContextMenu } from './styles'
import { XM_EDIT_DESCRIPTORS_ROLE } from 'src/rbac-constants'
import { useRbacPermission } from 'hooks/useRbacPermission'

const DescriptorContextMenu = ({
  descriptor,
  onClickItem,
}: {
  descriptor: DescriptorProps
  onClickItem: (desc: DescriptorProps, mode: SETTINGS_MENU) => void
}): ReactElement => {
  const hasAccess = useRbacPermission(XM_EDIT_DESCRIPTORS_ROLE)
  const retrieveUdOption = () => {
    if (hasAccess) {
      return (
        <StyledContextMenu>
          <ContextMenu.Item
            onClick={() => onClickItem(descriptor, SETTINGS_MENU.VIEW)}
            data-testid='descriptor-context-view'
            className='descriptor-context-item'
          >
            <Icon iconName='Eye' />
            <span>{SETTINGS.CONTEXT.VIEW}</span>
          </ContextMenu.Item>
          <ContextMenu.Item
            onClick={() => onClickItem(descriptor, SETTINGS_MENU.EDIT)}
            data-testid='descriptor-context-edit'
            className='descriptor-context-item'
          >
            <Icon iconName='Edit' />
            <span>{SETTINGS.CONTEXT.EDIT}</span>
          </ContextMenu.Item>
          <ContextMenu.Item
            onClick={() => onClickItem(descriptor, SETTINGS_MENU.DELETE)}
            data-testid='descriptor-context-delete'
            className='descriptor-context-item'
          >
            <Icon iconName='Delete' size='16' />
            <span>{SETTINGS.CONTEXT.DELETE}</span>
          </ContextMenu.Item>
        </StyledContextMenu>
      )
    }
    return (
      <StyledContextMenu>
        <ContextMenu.Item
          onClick={() => onClickItem(descriptor, SETTINGS_MENU.VIEW)}
          data-testid='descriptor-context-view'
          className='descriptor-context-item'
        >
          <Icon iconName='Eye' />
          <span>{SETTINGS.CONTEXT.VIEW}</span>
        </ContextMenu.Item>
      </StyledContextMenu>
    )
  }

  return (
    <ContextMenu
      className='item-context-menu'
      data-testid='item-context-menu'
      placement='bottomRight'
    >
      {retrieveUdOption()}
    </ContextMenu>
  )
}

export default DescriptorContextMenu
