import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

const FLYOUT_WIDTH = '200px'

export const StyledDropdown = styled.div`
  position: relative;
  border-right: 2px solid ${themeUi.palette.ui.neutral.grey3};
  height: fit-content;

  button[data-testid='preview-channels-button'] {
    background: transparent;
    border: none;
    color: ${themeUi.palette.ui.neutral.grey1};
    display: flex;
    gap: ${({ theme }) => theme.space.space_5};
    white-space: nowrap;
    padding: 0.625rem 0.75rem;
    margin: 0;

    .icon,
    path {
      fill: inherit;
    }
  }
`

export const StyledDropdownList = styled.ul`
  margin: 0;
  padding: 0;
  position: absolute;
  list-style: none;
  align-items: center;
  width: ${FLYOUT_WIDTH};
  ${themeUi.shadows.light.level2}
  top: 40px;
  border: 1px solid ${themeUi.palette.ui.neutral.grey2};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background: ${themeUi.palette.brand.primary.white};
  ${themeUi.typography.h6}
`

export const StyledDropdownListItem = styled.li`
  padding: 1rem 1.2rem;
  cursor: pointer;

  &:hover {
    background-color: ${themeUi.palette.ui.neutral.grey5};
  }

  &:first-child {
    border-top-left-radius: ${({ theme }) => theme.borderRadius.default};
    border-top-right-radius: ${({ theme }) => theme.borderRadius.default};
  }
  &:last-child {
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius.default};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.default};
  }
`
