/* eslint-disable brace-style */
import {
  Graph,
  MenuNodesAsList,
  MenuTreeNode,
  Names,
} from 'modules/browse-menu-tree-gql/types'
import { SUBNODE_ORDER_INCREMENT } from 'modules/browse-menu-tree-gql/constants'
import rfdc from 'rfdc'

const deepClone = rfdc()

export const removeNodeFromTree = ({
  menuTree,
  depth,
  removalNodeId,
  activeNodes,
  setActiveNodes,
}: {
  menuTree: MenuTreeNode[][]
  depth: number
  removalNodeId: string
  activeNodes: MenuTreeNode[]
  setActiveNodes: React.Dispatch<React.SetStateAction<MenuTreeNode[]>>
}): MenuTreeNode[][] => {
  //if removed node or any subnodes of removed section are active, remove all levels beyond and make next level empty array
  const removedNodeIsActive = activeNodes?.[depth]?._id === removalNodeId

  const removedSectionHasActiveSubnodes =
    activeNodes?.[depth]?.parent === removalNodeId

  if (removedNodeIsActive || removedSectionHasActiveSubnodes) {
    menuTree = menuTree.slice(0, depth + 1)
    setActiveNodes(prevActiveNodes =>
      deepClone(prevActiveNodes.slice(0, depth))
    )
  }

  //if also a section, remove subnodes
  menuTree[depth] = menuTree[depth].filter(sameLevelNode => {
    return (
      sameLevelNode.parent !== removalNodeId &&
      sameLevelNode._id !== removalNodeId
    )
  })

  return deepClone(menuTree)
}

export const getRootNodeName = (
  nodeId: string,
  depth: number,
  menuTree: MenuTreeNode[][]
): Names => {
  if (!menuTree[depth]) {
    return
  }

  const parentNode = menuTree[depth].find(node => node._id === nodeId)
  return parentNode.rootNodeName
}

export const getNodesToAppend = ({
  menuNodesAsList,
  menuTree,
  nodeId,
  depth,
  graph,
}: {
  menuNodesAsList: MenuNodesAsList[]
  menuTree: MenuTreeNode[][]
  nodeId: string
  depth: number
  graph: Graph
}): MenuTreeNode[] => {
  const nodeOrderMap = new Map<string, number>()
  graph[nodeId].edges.forEach((id, ind) => {
    nodeOrderMap.set(id, ind)
  })

  return (
    menuNodesAsList?.map(menuNode => {
      const { id, name: nodeName, url = '/', attributes, images } = menuNode
      return {
        _id: id,
        rootNodeName: getRootNodeName(nodeId, depth, menuTree),
        name: nodeName,
        url,
        disabled: !graph[id]?.isActive,
        attributes,
        images,
        parent: nodeId,
        section: graph[id]?.section,
        order: nodeOrderMap.get(id),
      }
    }) ?? []
  )
}

const reestablishOrderInLevel = ({
  treeLevel,
  sectionInLevelIdSet,
}: {
  treeLevel: MenuTreeNode[]
  sectionInLevelIdSet: Set<string>
}) => {
  //reestablish order or all nodes/sections
  let curOrder = 0
  treeLevel.forEach(node => {
    const isNotSubnode = !sectionInLevelIdSet.has(node.parent)
    if (isNotSubnode) {
      node.order = curOrder
      ++curOrder
    }
  })

  //get the new order of all sections
  const sectionOrderMap = new Map<string, number>()
  treeLevel.forEach(node => {
    node.section && sectionOrderMap.set(node._id, node.order)
  })

  //re-establish the order of all subnodes
  treeLevel.forEach(node => {
    const nodeIsSubnode = sectionInLevelIdSet.has(node.parent)
    if (nodeIsSubnode) {
      const subnodeOrder =
        sectionOrderMap.get(node.parent) + SUBNODE_ORDER_INCREMENT
      node.order = subnodeOrder
      sectionOrderMap.set(node.parent, subnodeOrder)
    }
  })
}

const getParentEdges = ({
  treeLevel,
  sectionInLevelIdSet,
}: {
  treeLevel: MenuTreeNode[]
  sectionInLevelIdSet: Set<string>
}): string[] => {
  return treeLevel
    .filter(node => !sectionInLevelIdSet.has(node.parent))
    .map(node => {
      return node._id
    })
}

const getSectionEdges = ({
  treeLevel,
  sectionId,
}: {
  treeLevel: MenuTreeNode[]
  sectionId: string
}): string[] => {
  return treeLevel
    .filter(node => node.parent === sectionId)
    .map(node => {
      return node._id
    })
}

export const getMenuTreeAfterDragAndDrop = ({
  menuTree,
  destDroppableId,
  srcIndex,
  destIndex,
}: {
  menuTree: MenuTreeNode[][]
  destDroppableId: number
  srcIndex: number
  destIndex: number
}): {
  updatedMenuTree: MenuTreeNode[][]
  newEdges: { [key: string]: string[] }
  // eslint-disable-next-line sonarjs/cognitive-complexity
} => {
  //set up initial variables to be used later
  const droppedNode = menuTree[destDroppableId][srcIndex]
  const treeLevel = menuTree[destDroppableId]
  const { section: isSection } = droppedNode
  const sectionInLevelIdSet = new Set<string>()
  treeLevel.forEach(el => {
    el.section && sectionInLevelIdSet.add(el._id)
  })
  const isSubnode = sectionInLevelIdSet.has(droppedNode.parent)

  //move the node/section/subnode to its new position in level array
  treeLevel.splice(srcIndex, 1)
  treeLevel.splice(destIndex, 0, droppedNode)

  //FIRST CASE: if droppedNode is a section
  //Move the section to its new order, then move the subnodes as well
  //the edges that need to be updated are the section's parent's edges
  if (isSection) {
    //re-establish the order of all nodes/sections/subnodes
    reestablishOrderInLevel({ treeLevel, sectionInLevelIdSet })

    //get the new edges of the section's parent
    const newParentEdges = getParentEdges({ treeLevel, sectionInLevelIdSet })

    return {
      updatedMenuTree: deepClone(menuTree),
      newEdges: {
        [droppedNode.parent]: newParentEdges,
      },
    }
  }

  //SECOND CASE: if droppedNode is a normal node
  //It can end up in another order in the level, or inside of a section
  //If it's not a section or subnode, then it can only be a node
  //If moving to another order in the level, then only the parent's edges need to be updated
  //If moving into a section, then both the parent and the section's edges need to be updated
  else if (!isSubnode) {
    //check if node is being dragged into a section
    //it should have a section above it, and a subnode of that section below it
    const nodeBelow = treeLevel[destIndex + 1]
    const hasDraggedIntoSection =
      nodeBelow && nodeBelow.parent !== droppedNode.parent
    if (hasDraggedIntoSection) {
      let indexOfDestSection
      treeLevel.forEach((node, ind) => {
        if (node.section && ind < destIndex) indexOfDestSection = ind
      })
      //change the droppedNode's parent to the section
      const destSection = treeLevel[indexOfDestSection]
      droppedNode.parent = destSection._id

      //re-establish the order of all nodes/subnodes/sections
      reestablishOrderInLevel({
        treeLevel,
        sectionInLevelIdSet,
      })

      //create the new edges under the parent
      const newParentEdges = getParentEdges({ treeLevel, sectionInLevelIdSet })

      //create the new edges under the section
      const newSectionEdges = getSectionEdges({
        treeLevel,
        sectionId: destSection._id,
      })

      return {
        updatedMenuTree: deepClone(menuTree),
        newEdges: {
          [destSection.parent]: newParentEdges,
          [destSection._id]: newSectionEdges,
        },
      }
    }

    //otherwise it is simply moving a node from a order in the level to another
    else {
      //re-establish the order of all non-subnodes in level
      reestablishOrderInLevel({
        treeLevel,
        sectionInLevelIdSet,
      })

      //create the new edges under the parent
      const newParentEdges = getParentEdges({ treeLevel, sectionInLevelIdSet })

      return {
        updatedMenuTree: deepClone(menuTree),
        newEdges: {
          [droppedNode.parent]: newParentEdges,
        },
      }
    }
  }

  //THIRD and FINAL CASE: if droppeNode is a subnode
  //it can end up in the level, the same section, or another section
  //if moving to another order in the same section, only section's edges need to be updated
  //if moving into the level, the parent's edges and the section's edges need to be updated,
  //  the droppedNode's parent is updated to level parent
  //if moving into another section, both the origin section and destination section's edges need to be updated,
  //  the droppedNode's parent is updated to destination section
  else {
    //first we check if the subnode stays in the section, which is when it has either a sibling subnode or its section above it
    const nodeAboveSubnodeIsItsSection =
      treeLevel?.[destIndex - 1]?._id === droppedNode.parent
    const nodeAboveSubnodeIsSibling =
      treeLevel?.[destIndex - 1]?.parent === droppedNode.parent
    const subnodeStaysInSection =
      nodeAboveSubnodeIsItsSection || nodeAboveSubnodeIsSibling
    if (subnodeStaysInSection) {
      const sectionOfSubnode = treeLevel.find(
        node => node._id === droppedNode.parent
      )
      //re-establish the order of all nodes/subnodes/sections
      reestablishOrderInLevel({
        treeLevel,
        sectionInLevelIdSet,
      })

      //create the new edges under the section
      const newSectionEdges = getSectionEdges({
        treeLevel,
        sectionId: sectionOfSubnode._id,
      })

      return {
        updatedMenuTree: deepClone(menuTree),
        newEdges: {
          [sectionOfSubnode._id]: newSectionEdges,
        },
      }
    }

    //otherwise, we have dragged the subnode out of its original section
    //either into the level or into another section
    else {
      //we need to check if it's dragged into a section or a level
      //if dragged into section, it should have a section above it, and a subnode of that section below it
      const originSection = treeLevel.find(
        node => node._id === droppedNode.parent
      )
      const nodeBelow = treeLevel[destIndex + 1]
      const hasDraggedIntoAnotherSection =
        nodeBelow && nodeBelow.parent !== originSection.parent
      if (hasDraggedIntoAnotherSection) {
        let indexOfDestSection
        treeLevel.forEach((node, ind) => {
          if (node.section && ind < destIndex) indexOfDestSection = ind
        })
        const destSection = treeLevel[indexOfDestSection]
        //update droppedNode's parent to new section
        droppedNode.parent = destSection._id

        //re-establish the order of all nodes/subnodes/sections
        reestablishOrderInLevel({
          treeLevel,
          sectionInLevelIdSet,
        })

        //create new edges of origin section
        const newOriginSectionEdges = getSectionEdges({
          treeLevel,
          sectionId: originSection._id,
        })

        //create new edges of destination section
        const newDestSectionEdges = getSectionEdges({
          treeLevel,
          sectionId: destSection._id,
        })

        return {
          updatedMenuTree: deepClone(menuTree),
          newEdges: {
            [originSection._id]: newOriginSectionEdges,
            [destSection._id]: newDestSectionEdges,
          },
        }
      }

      //otherwise, we dragged subnode into level
      else {
        //update droppedNode's parent to level's parent
        droppedNode.parent = originSection.parent

        //re-establish the order of all nodes/subnodes/sections
        reestablishOrderInLevel({
          treeLevel,
          sectionInLevelIdSet,
        })

        //create new edges of level's parent
        const newParentEdges = getParentEdges({
          treeLevel,
          sectionInLevelIdSet,
        })

        //create new edges of origin section
        const newSectionEdges = getSectionEdges({
          treeLevel,
          sectionId: originSection._id,
        })

        return {
          updatedMenuTree: deepClone(menuTree),
          newEdges: {
            [originSection.parent]: newParentEdges,
            [originSection._id]: newSectionEdges,
          },
        }
      }
    }
  }
}

const setNodeAndDescendantVisibility = ({
  toggleTo,
  updatedMenuTree,
  depth,
  node,
}: {
  toggleTo: boolean
  updatedMenuTree: MenuTreeNode[][]
  depth: number
  node: MenuTreeNode
}) => {
  node.disabled = toggleTo
  let evalDepth = depth + 1
  const nextLevelAreChildren =
    updatedMenuTree[depth + 1] &&
    updatedMenuTree[depth + 1][0]?.parent === node._id
  if (nextLevelAreChildren) {
    while (updatedMenuTree[evalDepth]) {
      updatedMenuTree[evalDepth].forEach(childNode => {
        childNode.disabled = toggleTo
      })
      evalDepth++
    }
  }
}

export const toggleNodeVisibility = ({
  depth,
  menuTree,
  _id,
  toggleTo,
  isSection,
}: {
  depth: number
  menuTree: MenuTreeNode[][]
  _id: string
  toggleTo: boolean
  isSection: boolean
}): MenuTreeNode[][] => {
  const updatedMenuTree = deepClone(menuTree)
  const editNodeIndex = updatedMenuTree[depth].findIndex(
    node => node._id === _id
  )

  if (isSection) {
    updatedMenuTree[depth][editNodeIndex].disabled = toggleTo
    updatedMenuTree[depth].forEach(node => {
      if (node.parent === _id) {
        setNodeAndDescendantVisibility({
          toggleTo,
          updatedMenuTree,
          depth,
          node,
        })
      }
    })
  } else {
    const node = updatedMenuTree[depth][editNodeIndex]
    setNodeAndDescendantVisibility({ toggleTo, updatedMenuTree, depth, node })
  }

  return updatedMenuTree
}

export const toggleNodeVisibilityInGraph = ({
  graph,
  _id,
  toggleTo,
}: {
  graph: Graph
  _id: string
  toggleTo: boolean
}): Graph => {
  const updatedGraph = deepClone(graph)

  const toggleList = [_id]

  while (toggleList.length) {
    const evalNodeId = toggleList.pop()
    updatedGraph[evalNodeId].isActive = toggleTo
    toggleList.push(...updatedGraph[evalNodeId].edges)
  }
  return updatedGraph
}
