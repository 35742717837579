import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledInputWrapper = styled.div`
  &.disabled {
    opacity: 0.4;
  }
`
export const StyledInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${props => props.theme.borderRadius.none};
  width: 100%;
  > .input_parent {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    flex: 0 1 auto;
  }
  > .input_parent > .input_label {
    ${props => props.theme.typography.labelSmall};
    margin-bottom: 9px !important;
    color: ${themeUi.palette.ui.neutral.grey5};
    background: ${themeUi.palette.brand.primary.white};
    display: block;
  }
`

export const StyledInput = styled.input`
  ${props => props.theme.typography.body};
  border: 1px solid ${themeUi.palette.ui.neutral.grey4};
  border-radius: ${props => props.theme.borderRadius.sm};
  padding: 4px 8px;
  height: 40px;
  background: transparent;
  outline: none;
  flex: 0 1 auto;
  width: 100%;
  &::-webkit-input-placeholder,
  &&::-ms-input-placeholder,
  &&::-moz-placeholder {
    color: ${themeUi.palette.brand.primary.gray};
  }
  &:focus-within {
    border-color: ${themeUi.palette.brand.primary.gray};
  }
  &:disabled {
    background: ${themeUi.palette.ui.neutral.grey4};
  }
`

export const StyledError = styled.div`
  ${props => props.theme.typography.bodySmall};
  color: ${themeUi.palette.ui.cta.red};
  margin-top: 4px;
  margin-left: 1px;
  position: relative;
  display: flex;
  align-items: flex-start;
  > svg {
    width: 15px;
    height: 15px;
    margin-right: 4px;
    flex: none;
  }
`
export const StyledCaption = styled.div`
  ${props => props.theme.typography.h4};
  color: ${themeUi.palette.brand.primary.gray};
  margin-top: 2px;
  text-align: center;
`
