import React, { createContext, useMemo } from 'react'
import {
  XM_EDITOR_ROLE,
  XM_PUBLISHER_ROLE,
  XM_VIEWER_ROLE,
} from 'src/rbac-constants'
import { FF_NAMES } from 'src/constants'
import { checkPermission } from './utils'
import { unleashClient } from 'src/src/business-layer-client'
export interface UserPermissions {
  hasPublisherPermissions: boolean
  hasEditorPermissions: boolean
  hasViewerPermissions: boolean
}

const UserPermissionsContext = createContext<UserPermissions>({
  hasPublisherPermissions: false,
  hasEditorPermissions: false,
  hasViewerPermissions: false,
})

export const UserPermissionsProvider: React.FunctionComponent<{
  children: React.ReactNode
}> = ({ children }) => {
  const value: UserPermissions = useMemo(() => {
    return {
      hasViewerPermissions: checkPermissions(XM_VIEWER_ROLE),
      hasEditorPermissions: checkPermissions(XM_EDITOR_ROLE),
      hasPublisherPermissions: checkPermissions(XM_PUBLISHER_ROLE),
    }
  }, [])

  return (
    <UserPermissionsContext.Provider value={value}>
      {children}
    </UserPermissionsContext.Provider>
  )
}

const checkPermissions = (permissions: string[]): boolean => {
  if (!unleashClient.isEnabled(FF_NAMES.unleashFFs.USE_RBAC)) {
    return true
  }

  const { hasAccess } = checkPermission({
    allowedPermissions: permissions,
  })
  return hasAccess
}

export const useUserPermissions = (): UserPermissions => {
  const permissions = React.useContext(UserPermissionsContext)
  if (!permissions) {
    throw new Error(
      'Application must be wrapped within the UserPermissionsProvider to use the useUserPermissions hook.'
    )
  }
  return permissions
}
