import React, { FunctionComponent, useState } from 'react'
import { ContentModalOptionsProps } from './types'
import { Modal } from '@teamfabric/copilot-ui'

const AlertModal: FunctionComponent<ContentModalOptionsProps> = ({
  title,
  size,
  config,
}) => {
  const [isConfirming, setIsConfirming] = useState(false)
  const { content, confirmButton, cancelButton } = config

  const handleConfirmClick = (e: Event) => {
    const promise = confirmButton.onClick(e) as Promise<unknown>
    const isPromise = !!promise?.then
    if (isPromise) {
      setIsConfirming(true)
      promise.finally(() => {
        setIsConfirming(false)
      })
    }
  }

  return (
    <Modal
      headerText={title}
      size={size}
      showCloseButton
      onClose={cancelButton.onClick}
      onBackdropClick={cancelButton.onClick}
      headerButtons={[]}
      footerButtons={[
        {
          ...cancelButton,
          'isPrimary': false,
          'data-testid': 'alert-modal-cancel-button',
        },
        {
          ...confirmButton,
          'isPrimary': true,
          'onClick': handleConfirmClick,
          'disabled': isConfirming,
          'data-testid': 'alert-modal-confirm-button',
        },
      ]}
      render={() => (
        <div data-testid='content-list-module-alert-modal-content'>
          {content}
        </div>
      )}
    />
  )
}

export default AlertModal
