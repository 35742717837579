import {
  ContentColumnProps,
  ContentDataItemProps,
  ContentTableItemProps,
} from './types'
import {
  GE_TABLE_EMPTY_ARCHIVE_MESSAGE,
  GE_TABLE_EMPTY_MESSAGE,
  MENU_TABLE_EMPTY_MESSAGE,
  NAVIGATION,
  NOT_SCHEDULED,
  PAGES_TABLE_EMPTY_ARCHIVE_MESSAGE,
  PAGES_TABLE_EMPTY_MESSAGE,
  STATUS,
} from 'src/constants'
import {
  NULL_ENTRY,
  TABLE_COLUMN_OPTIONS,
  TABLE_COLUMN_TITLES,
} from './constants'
import React, { Fragment } from 'react'
import ChipsList from 'components/ChipsList'
import ContentContextMenuModule from 'components/ContentContextMenuModule'
import QuickLink from 'components/QuickLink'
import Status from 'components/Status'
import formatDateTime from 'lib/formatDateTime'
import { noop } from 'lodash'
import rfdc from 'rfdc'
import statusMapping from 'components/DetailsTable3.0/statusMapping'

const deepClone = rfdc()

export const getEmptyTableMessage = ({
  contentType,
  isArchivedTab,
}: {
  contentType: string
  isArchivedTab?: boolean
}): string => {
  let emptyTableMessage = ''
  switch (contentType) {
    case NAVIGATION.PAGES:
      emptyTableMessage = isArchivedTab
        ? PAGES_TABLE_EMPTY_ARCHIVE_MESSAGE
        : PAGES_TABLE_EMPTY_MESSAGE
      break
    case NAVIGATION.GLOBAL_ELEMENTS:
      emptyTableMessage = isArchivedTab
        ? GE_TABLE_EMPTY_ARCHIVE_MESSAGE
        : GE_TABLE_EMPTY_MESSAGE
      break
    case NAVIGATION.MENU:
      emptyTableMessage = MENU_TABLE_EMPTY_MESSAGE
      break
    default:
      break
  }
  return emptyTableMessage
}

/** Sort versions according to last updated */
const getSortedVersions = (versions): ContentTableItemProps[] => {
  const _versions = deepClone(versions) as ContentTableItemProps[]
  return Array.isArray(_versions)
    ? _versions.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    : []
}

export const generateTableColumns = (
  columnOptions: TABLE_COLUMN_OPTIONS[]
): ContentColumnProps[] => {
  const columnObjects = columnOptions.map(columnOption => ({
    title: TABLE_COLUMN_TITLES[columnOption],
    accessor: columnOption,
    isSortable: false,
    className: `${columnOption}-column-class`,
    children: null,
  }))
  // copilot-ui Table component requires the first column to have children column mapping
  columnObjects[0].children = deepClone(columnObjects)
  return columnObjects
}

export const generateTableEntries = (
  entryOptions: ContentTableItemProps[]
): ContentDataItemProps[] => {
  if (!entryOptions) {
    return null
  }
  return entryOptions.map(entryOption => {
    const {
      id,
      name,
      locales = null,
      channels = null,
      pageCount = null,
      prefix = null,
      status,
      scheduledAt,
      updatedAt,
      contextMenuOptions,
      children,
      quickLinkTitle = '',
      quickLinkHandler = noop,
      pageTypeName,
    } = entryOption
    const uid = name?.replace(/[^a-z0-9]/gi, '').toLowerCase()

    const tableData = {
      uid,
      id,
      [TABLE_COLUMN_OPTIONS.NAME]: (
        <Fragment>
          {name}
          {pageTypeName ? (
            <div className='type-name' data-testid='type-name'>
              {pageTypeName}
            </div>
          ) : null}
        </Fragment>
      ),
      [TABLE_COLUMN_OPTIONS.LOCALE]: <ChipsList chips={locales} />,
      [TABLE_COLUMN_OPTIONS.PAGE_COUNT]: getPageCountField(pageCount),
      [TABLE_COLUMN_OPTIONS.CHANNEL]: Array.isArray(channels)
        ? channels.join(', ')
        : NULL_ENTRY,
      [TABLE_COLUMN_OPTIONS.STATUS]: <Status status={status} />,
      [TABLE_COLUMN_OPTIONS.SCHEDULED_AT]: formatDateTime(
        scheduledAt,
        NOT_SCHEDULED
      ),
      [TABLE_COLUMN_OPTIONS.PREFIX]: prefix ?? NULL_ENTRY,
      [TABLE_COLUMN_OPTIONS.UPDATED_AT]: formatDateTime(updatedAt, NULL_ENTRY),
      [TABLE_COLUMN_OPTIONS.QUICK_LINK]: (
        <QuickLink
          isChild={children === undefined}
          title={quickLinkTitle}
          handler={quickLinkHandler}
        />
      ),
      [TABLE_COLUMN_OPTIONS.CONTEXT_MENU]: (
        <ContentContextMenuModule menuOptions={contextMenuOptions} />
      ),
      children: children && generateTableEntries(getSortedVersions(children)),
    }

    if (!children) {
      delete tableData.children
    }

    return tableData
  })
}

export const getPageCountField = (pageCount: number): string => {
  if (!Number.isFinite(pageCount)) {
    return NULL_ENTRY
  }
  return `${pageCount} page${pageCount > 1 ? 's' : ''}`
}

export const getTableItemStatus = ({
  isActive,
}: {
  isActive: boolean
}): string => {
  return statusMapping[isActive ? STATUS.ACTIVE : STATUS.INACTIVE]
}
