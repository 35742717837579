import React, { memo, useCallback } from 'react'
import { StyledIcon, StyledWrapper } from './styles'
import IconArrow from 'assets/icons/IconArrowBack'
import IconDesktop from 'assets/icons/IconDesktop'
import IconMobile from 'assets/icons/IconMobile'
import IconTablet from 'assets/icons/IconTablet'
import PropTypes from 'prop-types'
import iframeSize from './iframeSize'
import isEqual from 'react-fast-compare'
import { noop } from 'lodash'
import { useNavigate } from '@reach/router'

const PreviewControls = props => {
  const { previewUrl, selectedDevice, handleOnFrameChange } = props
  const navigate = useNavigate()
  const handleOpenPagePreview = useCallback(() => {
    navigate(previewUrl)
  }, [navigate, previewUrl])

  const handleOnToggleDevice = useCallback(
    deviceName => {
      const iframe = iframeSize[deviceName]
      handleOnFrameChange(iframe)
    },
    [handleOnFrameChange]
  )

  return (
    <StyledWrapper data-testid='preview-controls-wrapper'>
      <StyledIcon
        data-selected={selectedDevice === 'mobile'}
        data-testid='preview-controls-mobile'
        onClick={useCallback(() => handleOnToggleDevice('mobile'), [
          handleOnToggleDevice,
        ])}
      >
        <IconMobile width='24' height='24' />
      </StyledIcon>
      <StyledIcon
        data-selected={selectedDevice === 'tablet'}
        data-testid='preview-controls-tablet'
        onClick={useCallback(() => handleOnToggleDevice('tablet'), [
          handleOnToggleDevice,
        ])}
      >
        <IconTablet width='24' height='24' data-icon-name='tablet' />
      </StyledIcon>
      <StyledIcon
        data-selected={selectedDevice === 'desktop'}
        data-testid='preview-controls-desktop'
        onClick={useCallback(() => handleOnToggleDevice('desktop'), [
          handleOnToggleDevice,
        ])}
      >
        <IconDesktop width='24' height='24' data-icon-name='desktop' />
      </StyledIcon>
      <StyledIcon>
        <IconArrow
          width='24'
          height='24'
          className='icon-preview-arrow'
          onClick={handleOpenPagePreview}
          data-testid='preview-controls-preview'
        />
      </StyledIcon>
    </StyledWrapper>
  )
}

PreviewControls.defaultProps = {
  selectedDevice: 'desktop',
  previewUrl: '/',
  handleOnFrameChange: noop,
}

PreviewControls.propTypes = {
  selectedDevice: PropTypes.string.isRequired,
  previewUrl: PropTypes.string.isRequired,
  handleOnFrameChange: PropTypes.func.isRequired,
}

export default memo(PreviewControls, isEqual)
