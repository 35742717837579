import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledMenu = styled.div`
  position: relative;
`

export const StyledMenuIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const StyledMenuContent = styled.div`
  box-shadow: ${({ theme }) => theme.boxShadow.default};
  background-color: ${themeUi.palette.brand.primary.white};
  ${themeUi.shadows.light.level1}
  overflow: hidden;
  position: absolute;
  z-index: ${props => props.theme.zIndex.hoverOver};
  ${props =>
    props.align === 'left'
      ? `
      right: 0;
    `
      : `left: 0;`};
`
