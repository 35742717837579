import {
  StyledDropdownList as BaseDropdownList,
  StyledDropdownListItem as BaseDropdownListItem,
} from 'components/StyledDropdown/styles'
import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledDropdownButtonWrapper = styled.div`
  border-right: 2px solid ${themeUi.palette.ui.neutral.grey3};
  display: flex;
  position: relative;
  height: fit-content;

  .dropdown-button {
    background: transparent;
    border: none;
    color: ${themeUi.palette.ui.neutral.grey1};
    display: flex;
    gap: ${({ theme }) => theme.space.space_5};
    white-space: nowrap;
    padding: 0.625rem 0.75rem;
    margin: 0;

    .icon,
    path {
      fill: inherit;
    }
  }
`

export const StyledDropdownList = styled(BaseDropdownList)`
  z-index: 1;

  &.is-right-col {
    top: 40px;
    transform: translateX(1rem);
  }
`

export const StyledDropdownListItem = styled(BaseDropdownListItem)`
  &.has-space {
    display: flex;
    justify-content: space-between;

    .right {
      color: ${themeUi.palette.brand.primary.gray};
    }
  }
`
