import { PipelinesProps, SvgPathsProps } from './types'
import { PIPELINE_WIDTH } from '../../constants'
import React from 'react'
import { StyledSvg } from './styles'
import cn from 'classnames'
import size from 'lodash/size'

const Pipelines: React.FC<PipelinesProps> = ({ svgPaths, activeNodes }) => {
  const getUpdatedPaths = (path, nodes): SvgPathsProps => {
    const breadcrumb = {} as { foundAt: number }

    nodes.forEach((node, i) => {
      if (!size(breadcrumb) && path.pId === node._id) {
        breadcrumb.foundAt = i as number
      }
      if (
        size(breadcrumb) &&
        breadcrumb.foundAt + 1 === i &&
        path.cId === node._id
      ) {
        path.active = true
      }
    })

    return path
  }

  return svgPaths.map(path => {
    const p = getUpdatedPaths(path, activeNodes)

    return (
      <StyledSvg
        key={p.draw}
        className={cn({ 'is-active': p.active })}
        data-testid={`svg-pipeline-${p.draw}`}
      >
        <path d={p.draw} strokeWidth={PIPELINE_WIDTH} fill='transparent' />
      </StyledSvg>
    )
  })
}

export default Pipelines
