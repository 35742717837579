import { ContentModalInfoProps, ModalType } from './types'
import React, { FunctionComponent } from 'react'
import AlertModal from './AlertModal'
import FormModal from './FormModal'

const ContentModalModule: FunctionComponent<ContentModalInfoProps> = ({
  visibleModalId,
  modalOptions,
}) => {
  const modalConfig = modalOptions[visibleModalId]

  if (!modalConfig) {
    return null
  }

  return modalConfig.modalType === ModalType.Alert ? (
    <AlertModal {...modalConfig} />
  ) : (
    <FormModal {...modalConfig} />
  )
}

export default ContentModalModule
