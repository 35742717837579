import {
  BROWSE_MENU_MODAL_ID,
  getBrowseMenuModalOptions,
  getPageFilterEntries,
} from 'src/data/utils'
import {
  BrowseMenuFeaturesList,
  ModalDetailProps,
  VisibleModalObjProps,
} from 'src/data/browse-menu-gql/types'
import {
  COPY_MENU_ASYNC,
  COPY_MENU_VARIANT,
  CREATE_MENU,
  CREATE_MENU_VARIANT,
  DELETE_MENU,
  DELETE_MENU_VERSION,
  LIST_MENUS,
  UPDATE_MENU_DETAILS,
} from 'services/graphql'
import {
  DEFAULT_PAGINATION,
  EMPTY_BROWSE_MENU_MODAL,
  EMPTY_STRING,
  FF_NAMES,
  NAVIGATION,
} from 'src/constants'
import { OperationVariables, useLazyQuery, useMutation } from '@apollo/client'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  constructEntriesList,
  generateCTAProps,
  generateTableProps,
  useBrowseMenuSearchResults,
} from 'src/data/browse-menu-gql'
import useTableData, {
  ACTIONS,
} from 'components/ContentTableModule/useTableData'
import ContentListModule from 'src/modules/content-list-module'
import ContentModalModule from 'components/ContentModalModule'
import { ContentSearchModuleProps } from 'components/ContentSearchModule/types'
import EmptyScreen from 'components/EmptyScreen'
import { Filters } from 'components/Filters/types'
import { ListMenuResponse } from 'services/graphql/types'
import { OPERATIONS } from 'src/data/services'
import { StyledEmptyScreenWrapper } from 'src/data/browse-menu-gql/styles'
import { buildMutationHandler } from 'src/data/browse-menu-gql/services'
import { getChannels } from 'store/channels/selectors'
import { getLocales } from 'store/i18n/selectors'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import some from 'lodash/some'
import { useFlag } from '@unleash/proxy-client-react'
import { useNavigate } from '@reach/router'
import { useSelector } from 'react-redux'
import { useUserPermissions } from 'contexts/userPermissions'
import { useNewNavigation } from 'src/contexts/navigationLinksContext'

const BrowseMenuGQLPage = (): JSX.Element => {
  const navNameChange = useNewNavigation()
  const navigate = useNavigate()
  const initialFilters: Filters = useMemo(() => {
    return {
      channels: [],
      status: [],
      locales: [],
      searchTerm: '',
    }
  }, [])
  const i18n = useFlag(FF_NAMES.unleashFFs.I18N) || false
  const locales = useSelector(getLocales)
  const { channels } = useSelector(getChannels)
  const multiChannel = some(channels)
  const [filters, setFilters] = useState<Filters>(initialFilters)
  const [searchTerm, setSearchTerm] = useState('')
  const userPermissions = useUserPermissions()

  const featuresList: BrowseMenuFeaturesList = useMemo(() => {
    return {
      i18n,
      multiChannel,
    }
  }, [i18n, multiChannel])

  const [modalDetails, setModalDetails] = useState<ModalDetailProps>({
    visibleModalId: null,
    targetItemId: null,
    targetItemName: null,
  })
  const [createMenu] = useMutation(CREATE_MENU)
  const [createMenuVariant] = useMutation(CREATE_MENU_VARIANT)
  const [deleteMenu] = useMutation(DELETE_MENU)
  const [deleteVersion] = useMutation(DELETE_MENU_VERSION)
  const [updateMenuDetails] = useMutation(UPDATE_MENU_DETAILS)
  const [copyMenuVersion] = useMutation(COPY_MENU_VARIANT)
  const [copyMenu] = useMutation(COPY_MENU_ASYNC)
  const [fetchMenus, { data, loading }] = useLazyQuery<ListMenuResponse>(
    LIST_MENUS
  )
  const fetch = useCallback(
    (limit: number, offset: number) => {
      return fetchMenus({
        variables: {
          limit,
          offset,
          input: {
            isArchived: false,
            filter: {
              searchTerm: filters?.searchTerm ?? '',
              channels: filters?.channels ?? [],
              locales: filters?.locales ?? [],
              status: filters?.status.map(s => s.toUpperCase()) ?? [],
            },
          },
        },
      })
    },
    [fetchMenus, filters]
  )
  const tableEntries = useMemo(
    () =>
      constructEntriesList({
        menuList: data?.menus?.edges,
        isArchived: false,
        showModal: setModalDetails,
        navigate,
        featuresList,
        userPermissions,
        navNameChange,
      }),
    [userPermissions, data, navigate]
  )
  const [
    tableData,
    dispatchTableAction,
    pagination,
    handlePagination,
  ] = useTableData({
    entries: tableEntries,
    totalCount: data?.menus?.totalCount ?? DEFAULT_PAGINATION.TOTAL_ITEMS,
    fetchQuery: fetch,
    initialFilters,
    filters,
  })

  const closeModal = useCallback(() => {
    setModalDetails({ visibleModalId: null })
  }, [])

  const commonMutationBuilderArgs = useMemo(
    () => ({
      modalDetails,
      dispatchTableAction,
      closeModal,
      navigate,
    }),
    [modalDetails, dispatchTableAction, closeModal, navigate]
  )

  const modalOperations = useMemo(() => {
    const { visibleModalId } = modalDetails

    // Creates mutation mapper for each operation and attach respective mutations
    const MODAL_ID_MUTATION_MAP = {
      [BROWSE_MENU_MODAL_ID.DELETE_VERSION]: {
        handler: 'handleDeleteVersion',
        mutation: deleteVersion,
        operation: OPERATIONS.DELETE_VERSION,
      },
      [BROWSE_MENU_MODAL_ID.DELETE]: {
        handler: 'handleDelete',
        mutation: deleteMenu,
        operation: OPERATIONS.DELETE,
      },
      [BROWSE_MENU_MODAL_ID.COPY]: {
        handler: 'handleCopy',
        mutation: copyMenu,
        operation: OPERATIONS.COPY,
      },
      [BROWSE_MENU_MODAL_ID.NEW_VERSION]: {
        handler: 'handleCreateMenuVariant',
        mutation: createMenuVariant,
        operation: OPERATIONS.CREATE_MENU_VARIANT,
      },
      [BROWSE_MENU_MODAL_ID.CREATE]: {
        handler: 'handleCreateMenu',
        mutation: createMenu,
        operation: OPERATIONS.CREATE,
      },
      [BROWSE_MENU_MODAL_ID.EDIT_DETAILS]: {
        handler: 'handleUpdateMenuDetails',
        mutation: updateMenuDetails,
        operation: OPERATIONS.EDIT_MENU_DETAILS,
      },
      [BROWSE_MENU_MODAL_ID.VIEW_DETAILS]: {
        operation: OPERATIONS.VIEW_MENU_DETIALS,
      },
      [BROWSE_MENU_MODAL_ID.COPY_VERSION]: {
        operation: OPERATIONS.COPY_VERSION,
        mutation: copyMenuVersion,
        handler: 'handleCopyMenuVersion',
      },
    }
    // Extract visible modal from mapper obj
    const visibleModalObj = MODAL_ID_MUTATION_MAP[
      visibleModalId
    ] as VisibleModalObjProps

    return {
      closeModal,
      handlers: visibleModalId && {
        [visibleModalObj.handler]: (values: OperationVariables) =>
          buildMutationHandler({
            navNameChange,
            mutation: visibleModalObj.mutation,
            operation: visibleModalObj.operation,
            values,
            ...commonMutationBuilderArgs,
          }),
      },
      modalDetails,
    }
  }, [
    commonMutationBuilderArgs,
    modalDetails,
    closeModal,
    deleteVersion,
    deleteMenu,
    createMenu,
    createMenuVariant,
    updateMenuDetails,
    copyMenuVersion,
    copyMenu,
  ])

  const browseMenuTableProps = useMemo(
    () =>
      generateTableProps({
        data: tableData,
        featuresList,
        paginationProps: {
          ...pagination,
          showPagination: true,
          handlePagination,
        },
      }),
    [tableData, featuresList, pagination, handlePagination]
  )
  const browseMenuFilterProps = useMemo(
    () => ({
      entries: getPageFilterEntries({
        locales,
        channels,
        filters,
        onFilterChange: (selectedFilters, _filterName) =>
          setFilters(prevFilters => ({
            ...prevFilters,
            searchTerm: '',
            [_filterName]: selectedFilters,
          })),
      }),
    }),
    [locales, filters, channels]
  )

  const {
    dropDownValues,
    clearSearchDropdown,
    isSearching,
  } = useBrowseMenuSearchResults(searchTerm)

  const onSearch = useCallback(() => {
    setFilters(() => ({
      channels: [],
      status: [],
      locales: [],
      searchTerm,
    }))
    clearSearchDropdown()
  }, [clearSearchDropdown, searchTerm])

  const onClearSearch = useCallback(() => {
    setSearchTerm('')
    setFilters(prevFilters => ({
      ...prevFilters,
      searchTerm: '',
    }))
  }, [])

  useEffect(() => {
    if (searchTerm === EMPTY_STRING) onClearSearch()
  }, [searchTerm, onClearSearch])

  const searchModuleProps: ContentSearchModuleProps = useMemo(() => {
    return {
      searchTerm,
      isSearching,
      dropdownItems: { results: dropDownValues },
      onSearch,
      onClearSearch,
      onSelect: onSearch,
      onInputChange: setSearchTerm,
    }
  }, [dropDownValues, isSearching, onSearch, searchTerm, onClearSearch])

  const noResults = useMemo(() => {
    return {
      isArchivedTab: false,
      onClearSearch,
      searchTerm,
      contentType: NAVIGATION.MENU,
    }
  }, [onClearSearch, searchTerm])

  const browseMenuCTAProps = useMemo(
    () =>
      generateCTAProps(setModalDetails, userPermissions.hasEditorPermissions),
    [userPermissions]
  )

  const browseMenuModalProps = useMemo(() => {
    const { visibleModalId } = modalDetails

    return {
      visibleModalId,
      modalOptions: visibleModalId
        ? getBrowseMenuModalOptions(modalOperations, i18n, locales, channels)
        : {},
    }
  }, [modalDetails, modalOperations])

  const showEmptyScreen = useMemo(
    () => !loading && isEqual(filters, initialFilters) && isEmpty(tableEntries),
    [filters, initialFilters, loading, tableEntries]
  )

  if (showEmptyScreen) {
    return (
      <>
        <StyledEmptyScreenWrapper>
          <EmptyScreen
            buttonProps={{
              size: 'small',
              text: EMPTY_BROWSE_MENU_MODAL.BUTTON,
              onClick: () =>
                setModalDetails({
                  visibleModalId: BROWSE_MENU_MODAL_ID.CREATE,
                }),
              disabled: !userPermissions.hasEditorPermissions,
            }}
            className='empty-menus'
            primaryText={EMPTY_BROWSE_MENU_MODAL.PRIMARY_TEXT}
            secondaryText={EMPTY_BROWSE_MENU_MODAL.SECONDARY_TEXT}
          />
        </StyledEmptyScreenWrapper>
        <ContentModalModule {...browseMenuModalProps} />
      </>
    )
  }
  const {
    data: browseMenuTableData,
    columns,
    paginationProps,
  } = browseMenuTableProps
  return (
    <ContentListModule
      ctaProps={browseMenuCTAProps}
      filterProps={browseMenuFilterProps}
      modalProps={browseMenuModalProps}
      tabProps={null} // No Tabs for Browse Menu page
      tableProps={{
        data: browseMenuTableData,
        loading,
        columns,
        paginationProps,
        noResults,
      }}
      searchProps={searchModuleProps}
    />
  )
}

export default BrowseMenuGQLPage
