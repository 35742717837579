import API from 'src/business-layer-client'
import handleResponse from 'lib/handleResponse'

class Descriptor {
  async get({ appurl = null } = {}) {
    if (!appurl)
      throw new Error(
        'An appurl with the URL of the commerce frontend is required'
      )

    try {
      const response = await API.xpm.get(`/descriptors?url=${appurl}`)

      if (!Array.isArray(response.data)) {
        throw new Error('Error occurred while fetching descriptors')
      }

      return response.data
    } catch (err) {
      throw handleResponse.error(err)
    }
  }
}

export default new Descriptor()
