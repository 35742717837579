import Icon from 'assets/icons/IconAdd'
import PropTypes from 'prop-types'
import React from 'react'
import { StyledThumbnail } from 'components/type-components/ImageType/styles'
import cn from 'classnames'

const ImageButton = ({ imageUrl, altText, onHover }) => {
  if (imageUrl) {
    return (
      <StyledThumbnail>
        <img
          src={`${imageUrl}?fit=scale&w=48&h=48`}
          className={cn('position-absolute-centered', { blurred: onHover })}
          alt={altText}
          loading='lazy'
        />
      </StyledThumbnail>
    )
  }

  return <Icon width={12} height={12} />
}

ImageButton.defaultProps = {
  imageUrl: '',
  altText: '',
  onHover: false,
}

ImageButton.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  onHover: PropTypes.bool.isRequired,
}

export default ImageButton
