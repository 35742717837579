import React, { memo } from 'react'

const IconPlus = props => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      {...props}
    >
      <path d='M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z'></path>
      <path fill='none' d='M0 0h24v24H0z'></path>
    </svg>
  )
}

export default memo(IconPlus)
