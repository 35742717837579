import PropTypes from 'prop-types'
import React from 'react'
import { StyledSwitch } from './styles'
import { noop } from 'lodash'

const Switch = props => {
  const { disabled, onClick, checked, ...rest } = props
  return (
    <StyledSwitch disabled={disabled} {...rest} data-testid='switch'>
      <input
        type='checkbox'
        checked={checked}
        disabled={disabled}
        tabIndex={0}
        onChange={onClick}
        data-testid='switch-checkbox'
      />
      <span className='slider' />
    </StyledSwitch>
  )
}

Switch.defaultProps = {
  disabled: false,
  checked: false,
  color: 'green',
  onClick: noop,
}

Switch.propTypes = {
  disabled: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  color: PropTypes.oneOf(['blue', 'green', 'red']),
  onClick: PropTypes.func.isRequired,
}

export default Switch
