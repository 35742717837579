import { STATUS } from 'src/constants'

const RBAC_DEFAULT_EDIT_ALLOWED_VERSION_STATUS = [STATUS.DRAFT, STATUS.ENDED]

const getHasEditAccessBasedOnVersionStatus = (
  {
    hasEditorPermissions,
    hasPublisherPermissions,
  }: {
    hasEditorPermissions: boolean
    hasPublisherPermissions: boolean
  },
  versionStatus: string,
  customAllowedVersionStatus: string[] = RBAC_DEFAULT_EDIT_ALLOWED_VERSION_STATUS
): boolean => {
  // hasPublisherPermissions is true if RBAC FF disabled
  if (hasPublisherPermissions) {
    return true
  }

  return (
    hasEditorPermissions && customAllowedVersionStatus.includes(versionStatus)
  )
}

export default getHasEditAccessBasedOnVersionStatus
