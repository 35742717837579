import { ContextOption, FeaturesList } from 'src/data/types'
import {
  DEFAULT_PAGINATION,
  EMPTY_STRING,
  MESSAGES,
  NO_RESULTS_FOUND,
  QUICK_LINK,
  STATUS,
} from 'src/constants'
import { NavigateFn, useNavigate } from '@reach/router'
import { OperationVariables, gql } from '@apollo/client/core'
import {
  Page,
  PageType,
  PageTypeResponse,
  PageVersion,
  PagesModuleTableData,
  SearchDropdown,
  UsePageTablePropsInput,
  UseSearchResults,
} from './types'
import {
  UserPermissions,
  useUserPermissions,
} from 'src/contexts/userPermissions'
import {
  getContentScheduledAt,
  populateVariantQuickLinkTitle,
  tranformToSearchResults,
} from 'src/data/utils'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ContentTableItemProps } from 'components/ContentTableModule/types'
import { LIST_PAGES_STRING } from 'services/graphql/page'
import { LazyQueryResult } from '@apollo/client/react/types/types'
import { PageListGraphQLResponse } from 'src/graphql-proxy/transformations/page/types'
import { TABLE_COLUMN_OPTIONS } from 'components/ContentTableModule/constants'
import { getTableItemStatus } from 'components/ContentTableModule/utils'
import { navigateToEditor } from './context-options'
import pageType from 'services/api/pageType'
import { showSnackbar } from 'data/services'
import statusMapping from 'components/DetailsTable3.0/statusMapping'
import useAsync from 'hooks/useAsync'
import { useDebounce } from 'hooks/useDebounce'
import { useLazyQuery } from '@apollo/client/react/hooks/useLazyQuery'

export const usePagesTableProps = ({
  filters,
  features,
  getPageCtxOptions,
  getPageVersionCtxOptions,
  basepath,
}: UsePageTablePropsInput): PagesModuleTableData => {
  const [fetchPages, { data: pagesData, loading: pagesLoading }] = useLazyQuery<
    PageListGraphQLResponse
  >(gql(LIST_PAGES_STRING))

  const navigate = useNavigate()

  const pagesInput = useMemo(() => {
    if (!filters) {
      return {}
    }
    const isArchived = !!filters.isArchived
    return {
      isArchived: isArchived,
      filter: {
        searchTerm: filters?.searchTerm ?? '',
        channels: filters?.channels ?? [],
        locales: filters?.locales ?? [],
        status: filters?.status.map(s => s.toUpperCase()) ?? [],
        groups:
          filters?.pageTypes?.map(pt => ({
            pageTypeId: pt,
          })) ?? [],
      },
    }
  }, [filters])

  const pagesfetchQuery = useCallback(
    (limit: number, offset: number) =>
      fetchPages({
        variables: {
          input: pagesInput,
          limit,
          offset,
          orderBy: { field: 'updatedAt', direction: 'DESC' },
        },
      }),
    [fetchPages, pagesInput]
  )

  const pages = useMemo(
    () =>
      pagesData?.pages?.edges?.length
        ? pagesData.pages.edges.map(edge => edge?.node)
        : [],
    [pagesData]
  )

  const userPermissions = useUserPermissions()
  const constructTableEntries = useMemo((): ContentTableItemProps[] => {
    return pagesDtoToTableEntries(pages, {
      archived: pagesInput.isArchived,
      getPageTypeCtxOptions: getPageCtxOptions,
      getPageVersionCtxOptions: getPageVersionCtxOptions,
      userPermissions: userPermissions,
      navigate,
      basepath,
    })
  }, [
    pages,
    pagesInput,
    getPageCtxOptions,
    getPageVersionCtxOptions,
    navigate,
    userPermissions,
  ])

  return useMemo(() => {
    return {
      entries: constructTableEntries,
      columns: constructPagesTableColumns(features),
      totalCount:
        pagesData?.pages?.totalCount ?? DEFAULT_PAGINATION.TOTAL_ITEMS,
      fetchQuery: pagesfetchQuery,
      isLoading: pagesLoading,
    }
  }, [
    constructTableEntries,
    pagesfetchQuery,
    pagesLoading,
    pagesData,
    features,
    userPermissions,
  ])
}

export const usePageTypesTableProps = ({
  getPageTypeCtxOptions,
  searchTerm,
}: {
  getPageTypeCtxOptions: (item: PageType) => ContextOption[]
  searchTerm: string | null
}): PagesModuleTableData => {
  const getPageTypes = useCallback(
    (limit: number, offset: number) =>
      pageType.list(offset, limit) as Promise<PageTypeResponse>,
    []
  )

  const getPageTypesSearch = useCallback(
    () => pageType.search(searchTerm) as Promise<PageTypeResponse>,
    [searchTerm]
  )

  const {
    run: pageTypesFetchQuery,
    value: pageTypesData,
    loading: isLoading,
  } = useAsync(searchTerm ? getPageTypesSearch : getPageTypes) as {
    value: PageTypeResponse
    loading: boolean
    run: (limit: number, offset: number) => Promise<void>
  }

  const pageTypes = useMemo(
    () => pageTypesData?.pageTypes ?? ([] as PageTypeResponse['pageTypes']),
    [pageTypesData]
  )

  const tableEntries = useMemo(
    () => pageTypesDtoToTableEntries(pageTypes, { getPageTypeCtxOptions }),
    [pageTypes, getPageTypeCtxOptions]
  )

  return useMemo(() => {
    return {
      entries: tableEntries,
      columns: constructPageTypesTableColumns(),
      totalCount: pageTypesData?.query?.count ?? DEFAULT_PAGINATION.TOTAL_ITEMS,
      fetchQuery: pageTypesFetchQuery,
      isLoading: isLoading,
    }
  }, [tableEntries, pageTypesFetchQuery, isLoading, pageTypesData])
}

function constructPagesTableColumns(
  featuresFlags: FeaturesList
): TABLE_COLUMN_OPTIONS[] {
  return [
    TABLE_COLUMN_OPTIONS.NAME,
    featuresFlags.multiChannel.enabled && TABLE_COLUMN_OPTIONS.CHANNEL,
    featuresFlags.i18n.enabled && TABLE_COLUMN_OPTIONS.LOCALE,
    TABLE_COLUMN_OPTIONS.STATUS,
    TABLE_COLUMN_OPTIONS.SCHEDULED_AT,
    TABLE_COLUMN_OPTIONS.UPDATED_AT,
    TABLE_COLUMN_OPTIONS.QUICK_LINK,
    TABLE_COLUMN_OPTIONS.CONTEXT_MENU,
  ]
}

const populateQuickLinkHandler = ({
  versionId,
  navigate,
  pageId,
  reschedule = false,
  basepath,
}: {
  versionId: string
  navigate: NavigateFn
  pageId: string
  reschedule?: boolean
  basepath: string
}) => {
  return () =>
    navigateToEditor({ pageId, versionId, reschedule }, navigate, basepath)
}

function pagesDtoToTableEntries(
  pages: Page[],
  {
    archived,
    getPageTypeCtxOptions,
    getPageVersionCtxOptions,
    userPermissions,
    navigate,
    basepath,
  }: {
    archived: boolean
    getPageTypeCtxOptions: (item: Page) => ContextOption[]
    getPageVersionCtxOptions: (
      item: Page,
      version: PageVersion
    ) => ContextOption[]
    userPermissions: UserPermissions
    navigate: NavigateFn
    basepath: string
  }
): ContentTableItemProps[] {
  if (!pages?.length) {
    return []
  }

  return pages.map(page => {
    return {
      name: page?.name,
      id: page?.id,
      uid: page?.id,
      scheduledAt: getContentScheduledAt(page?.variants ?? []),
      status: archived
        ? (statusMapping[STATUS.ARCHIVED] as string)
        : getTableItemStatus({ isActive: page?.isActive }),
      updatedAt: page?.updatedAt,
      contextMenuOptions: getPageTypeCtxOptions(page),
      locales: page?.locales ?? [],
      channels: page?.channels?.map(c => c?.label ?? '') ?? [],
      pageTypeName: page?.group?.pageTypeName,
      children: page?.variants?.map(v => {
        return {
          id: v?.id,
          uid: v?.id,
          name: v?.versionName,
          status: (archived
            ? statusMapping[STATUS.ARCHIVED]
            : statusMapping[v?.status]) as string,
          scheduledAt: v?.startDate ?? '',
          contextMenuOptions: getPageVersionCtxOptions(page, v),
          updatedAt: v?.updatedAt,
          quickLinkTitle: populateVariantQuickLinkTitle(userPermissions, v, {
            content: QUICK_LINK.VIEW_PAGE,
          }),
          quickLinkHandler: populateQuickLinkHandler({
            versionId: v?.id,
            navigate: navigate,
            pageId: page?.id,
            reschedule:
              v?.status === STATUS.SCHEDULED &&
              userPermissions.hasPublisherPermissions,
            basepath,
          }),
        }
      }),
    }
  })
}

const constructPageTypesTableColumns = (): TABLE_COLUMN_OPTIONS[] => {
  return [
    TABLE_COLUMN_OPTIONS.NAME,
    TABLE_COLUMN_OPTIONS.PAGE_COUNT,
    TABLE_COLUMN_OPTIONS.PREFIX,
    TABLE_COLUMN_OPTIONS.CONTEXT_MENU,
  ]
}

const pageTypesDtoToTableEntries = (
  pageTypes: PageType[],
  {
    getPageTypeCtxOptions,
  }: {
    getPageTypeCtxOptions: (item: PageType) => ContextOption[]
  }
): ContentTableItemProps[] => {
  if (!pageTypes?.length) {
    return []
  }

  return pageTypes.map(type => {
    return {
      name: type?.name,
      id: type?.id,
      uid: type?._id,
      pageCount: type?.pageCount,
      prefix: type?.urlPrefix,
      contextMenuOptions: getPageTypeCtxOptions(type),
      status: '',
      updatedAt: type?.updatedAt,
      scheduledAt: '',
    }
  })
}

export const usePopulateSearchResults = (
  searchTerm: string
): UseSearchResults => {
  const [dropDownValues, setDropDownValues] = useState<Array<SearchDropdown>>(
    []
  )
  const DEBOUNCE_DURATION_MS = 500
  const SEARCH_LIMIT = 4
  const cancelDropdownUpdate = useRef<boolean>(false)

  const [isSearching, setIsSearching] = useState<boolean>(false)

  const noSearchResults = useMemo(() => {
    return {
      name: NO_RESULTS_FOUND,
      tags: '',
      _id: 'no_search_id',
    }
  }, [])

  const debouncedSearchTerm = useDebounce(searchTerm, DEBOUNCE_DURATION_MS)

  const getPageTypes = async (search: string) =>
    (await pageType.search(search)) as Promise<PageTypeResponse>

  const [fetchPages] = useLazyQuery<PageListGraphQLResponse>(
    gql(LIST_PAGES_STRING)
  )

  const clearSearchDropdown = () => {
    setDropDownValues([])
    cancelDropdownUpdate.current = true
  }

  const getSearchResults = useCallback(async () => {
    if (debouncedSearchTerm === EMPTY_STRING) {
      setDropDownValues([])
      return
    }
    setIsSearching(true)
    const results = await Promise.allSettled([
      getPageTypes(debouncedSearchTerm),
      fetchPages({
        variables: {
          input: {
            isArchived: false,
            filter: {
              searchTerm: debouncedSearchTerm,
            },
          },
          orderBy: { field: 'updatedAt', direction: 'DESC' },
        },
      }),
      fetchPages({
        variables: {
          input: {
            isArchived: true,
            filter: {
              searchTerm: debouncedSearchTerm,
            },
          },
          orderBy: { field: 'updatedAt', direction: 'DESC' },
        },
      }),
    ])
    setIsSearching(false)

    const isRejected = results.find(result => result.status === 'rejected')

    if (isRejected) {
      showSnackbar(MESSAGES.ERROR_SEARCH, false)
    }

    const pageTypes = (results[0].status === 'fulfilled'
      ? results[0]
      : []) as PromiseFulfilledResult<PageTypeResponse>
    const pages = (results[1].status === 'fulfilled'
      ? results[1]
      : []) as PromiseFulfilledResult<
      LazyQueryResult<PageListGraphQLResponse, OperationVariables>
    >
    const archived = (results[2].status === 'fulfilled'
      ? results[2]
      : []) as PromiseFulfilledResult<
      LazyQueryResult<PageListGraphQLResponse, OperationVariables>
    >

    const searchResults = tranformToSearchResults(
      pageTypes.value.pageTypes,
      pages.value.data.pages.edges,
      archived.value.data.pages.edges
    )

    if (cancelDropdownUpdate.current) {
      return
    }

    if (searchResults.length) {
      setDropDownValues(searchResults.slice(0, SEARCH_LIMIT))
      return
    }

    setDropDownValues([noSearchResults])
  }, [fetchPages, noSearchResults, debouncedSearchTerm])

  useEffect(() => {
    void getSearchResults()
  }, [debouncedSearchTerm, getSearchResults])

  useEffect(() => {
    cancelDropdownUpdate.current = false
  }, [searchTerm])

  return { dropDownValues, clearSearchDropdown, isSearching }
}
