import { NOT_FOUND_INDEX, STATUS } from 'src/constants'
import { ACTIONS } from './actions'
import FormLifecycle from 'form-lifecycle'
import { createReducer } from 'redux-nano'
import { filterName } from 'components/Filters/constants'
import pick from 'lodash/pick'
import produce from 'immer'
import rfdc from 'rfdc'

const deepClone = rfdc()
const pagesLifecycleForm = FormLifecycle.atObjectPath('pagesLifecycleForm')
const { channels, pageTypes, status, locales } = filterName

export const initialState = {
  showArchivedView: false, // user has a default view and an an option to view archived pages
  pagesLifecycleForm: FormLifecycle.create(),
  pages: [],
  archivedPages: [],
  isPageTypesActive: false,
  loaded: false,
  searchTerm: '',
  isPaginationReset: false,
  query: {
    limit: 10,
    offset: 0,
    isArchived: false,
    isActive: true,
    count: 0,
  },
  archivedQuery: {
    limit: 10,
    offset: 0,
    isArchived: false,
    isActive: true,
    count: 0,
  },
  filters: {
    [channels]: [],
    [pageTypes]: [],
    [status]: [],
    [locales]: [],
  },
}

/**
 * Updates the list of pages by removing the versions with the specified
 * archive status
 *
 * @param {Object} props The object holding list of pages and the archive status
 * of the versions to remove.
 * @param {Object[]} props.pages The list of pages to modify.
 * @param {boolean} props.isArchived The archive status of the versions to
 * remove.
 * @return {Object[]} The updated list of pages with the appropriate versions
 * removed.
 */
const removeVersionsBasedOnArchiveStatus = ({ pages, isArchived }) => {
  if (!pages) {
    return
  }
  const pagesCopy = deepClone(pages)
  pagesCopy.forEach(page => {
    page.versions = page.versions.filter(
      version => version.isArchived !== isArchived
    )
  })

  return pagesCopy
}

const deactivateVersionStatuses = versions => {
  return versions.map(v => {
    if (!v.status) {
      return v
    }
    switch (v.status) {
      case STATUS.LIVE:
        v.status = STATUS.ENDED
        break
      case STATUS.SCHEDULED:
        v.status = STATUS.DRAFT
        break
      default:
        break
    }
    return v
  })
}

export default createReducer(initialState, {
  [ACTIONS.PAGES_REQUEST_SUBMIT]: state => pagesLifecycleForm.submit(state),
  [ACTIONS.PAGES_REQUEST_SUCCESS]: (state, action) => {
    const stateAfterSuccess = pagesLifecycleForm.success(state)
    const newState = { ...stateAfterSuccess }
    const { pages, query } = action.payload

    const updatedPageList = removeVersionsBasedOnArchiveStatus({
      pages,
      isArchived: true,
    })

    return produce(newState, draftState => {
      draftState.pages = updatedPageList
      draftState.query = query
      draftState.loaded = true
      return draftState
    })
  },
  [ACTIONS.ARCHIVED_REQUEST_SUCCESS]: (state, action) => {
    const stateAfterSuccess = pagesLifecycleForm.success(state)
    const newState = { ...stateAfterSuccess }
    const { pages, query } = action.payload

    const updatedArchivedPageList = removeVersionsBasedOnArchiveStatus({
      pages,
      isArchived: false,
    })

    return produce(newState, draftState => {
      draftState.archivedPages = updatedArchivedPageList
      draftState.archivedQuery = query
      return draftState
    })
  },
  [ACTIONS.PAGES_REQUEST_ERROR]: (state, action) => {
    const stateAfterError = pagesLifecycleForm.error(state, action.payload)

    return {
      ...stateAfterError,
      loaded: true,
    }
  },
  [ACTIONS.PAGE_VERSIONS_REQUEST_SUCCESS]: (state, action) => {
    const { pageId } = action.payload
    const updatedPagesList = state.pages.map(page => {
      if (page.pageId === pageId) {
        const versions = formatPageVersions({
          versions: action.payload,
          page: page,
        })
        return {
          ...page,
          versions: versions,
        }
      }
      return page
    })
    return {
      ...state,
      pages: updatedPagesList,
    }
  },
  [ACTIONS.TOGGLE_ACTIVE_PAGE]: (state, action) => {
    const { pageId, isNowActive } = action.payload

    const updatedPagesList = deepClone(state.pages).map(page => {
      if (page?.pageId && page.pageId === pageId && page.versions) {
        if (!isNowActive) {
          page.versions = deactivateVersionStatuses(page.versions)
        }
        return {
          ...page,
          isActive: isNowActive,
        }
      }
      return page
    })
    return {
      ...state,
      pages: updatedPagesList,
    }
  },
  [ACTIONS.PAGES_RESET]: () => initialState,
  [ACTIONS.SET_SEARCH_TERM]: (state, action) => {
    return {
      ...state,
      searchTerm: action.payload,
    }
  },
  [ACTIONS.SET_PAGINATION_RESET]: (state, action) => {
    return {
      ...state,
      isPaginationReset: action.payload,
    }
  },

  [ACTIONS.SET_CONFIRM_DIALOG]: (state, action) => {
    return {
      ...state,
      confirmDialog: action.payload,
    }
  },
  [ACTIONS.SET_CREATE_VERSION_DIALOG]: (state, action) => {
    return {
      ...state,
      createVersionDialog: action.payload,
    }
  },
  [ACTIONS.SET_CREATE_PAGE_DIALOG]: (state, action) => {
    return {
      ...state,
      createPageDialog: action.payload,
    }
  },
  [ACTIONS.SET_CONTENT_DETAILS_DIALOG]: (state, action) => {
    return {
      ...state,
      contentDetailsDialog: action.payload,
    }
  },
  [ACTIONS.SET_MAKE_A_COPY_DIALOG]: (state, action) => {
    return {
      ...state,
      makeACopyDialog: action.payload,
    }
  },
  [ACTIONS.SET_FILTERS]: (state, action) => {
    return {
      ...state,
      filters: action.payload,
    }
  },
  [ACTIONS.RESET_FILTERS]: state => {
    return {
      ...state,
      filters: {
        [channels]: [],
        [pageTypes]: [],
        [status]: [],
      },
    }
  },
  [ACTIONS.MAKE_A_COPY_OF_VERSION]: (state, action) => {
    const { version } = action.payload
    const newVersion = pick(version, [
      'updatedAt',
      'versionId',
      '_id',
      'endDate',
      'isArchived',
      'name',
      'status',
      'description',
    ])

    return produce(state, draftState => {
      draftState.pages
        .find(pg => pg.pageId === version.pageId)
        ?.versions?.unshift(newVersion)
    })
  },
  [ACTIONS.CREATE_PAGE]: (state, action) => {
    const { response } = action.payload
    const { page } = response

    const newPages = deepClone(state.pages)
    newPages.unshift(page)

    return {
      ...state,
      pages: newPages,
    }
  },
  [ACTIONS.EDIT_PAGE_DETAILS]: (state, action) => {
    const {
      pageId,
      newChannels,
      name,
      pageUrl,
      typeId,
      typeName,
      seoFields,
    } = action.payload

    return produce(state, draftState => {
      const idx = draftState.pages.findIndex(page => page.pageId === pageId)
      if (idx !== NOT_FOUND_INDEX) {
        const targetPage = deepClone(draftState.pages[idx])
        targetPage.name = name

        targetPage.pageUrl = pageUrl

        if (newChannels) {
          targetPage.channels = newChannels
        }

        if (seoFields) {
          targetPage.seoFields = seoFields
        }

        if (typeId) {
          targetPage.typeId = typeId
        }

        if (typeName) {
          targetPage.typeName = typeName
        }

        draftState.pages = draftState.pages.map(item => {
          if (item.pageId === pageId) {
            return targetPage
          }
          return item
        })
      }
    })
  },
})

const formatPageVersions = ({ versions = {}, page = {} }) => {
  const potentialLiveVersions = []
  const currentDate = new Date()
  const { query = {}, list = [] } = versions
  list.forEach(v => {
    const startDate = new Date(v.startDate)
    if (v.isArchived) {
      v.status = 'ARCHIVED'
    } else if (startDate && startDate <= currentDate) {
      potentialLiveVersions.push(v)
    }
  })
  if (potentialLiveVersions.length) {
    potentialLiveVersions
      .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
      .reverse()
    potentialLiveVersions[0].status = page.isActive ? 'LIVE' : 'INACTIVE'
    potentialLiveVersions
      .filter(v => v.status !== 'LIVE' && v.status !== 'INACTIVE')
      .map(v => (v.status = 'ENDED'))
  }
  return {
    query,
    list,
  }
}
