import channels from './channels/reducer'
import { combineReducers } from 'redux'
import configuration from './configuration/reducer'
import editor from 'modules/editor/reducer'
import i18n from './i18n/reducer'
import pageTypes from 'modules/pages/page-types/reducer'
import pages from 'modules/pages/reducer'
import toastMessages from 'modules/toast-messages/reducer'

export default combineReducers({
  pages,
  pageTypes,
  editor,
  configuration,
  toastMessages,
  channels,
  i18n,
})
