import {
  CommonElementProps,
  GEParentElementProps,
  PageParentElementProps,
} from 'components/FormDialog/common/versions/VersionDetailsDialog/types'
import {
  LocalizedComponents,
  PageVersionProps,
  PayloadProps,
  PreviewEventProps,
  VersionDetailsFromGqlResponse,
  VersionInfoPayloadProps,
} from './types'
import { editorPath, editorPreviewType, gcEditorPath } from 'src/constants'
import sendPreviewEvent, { eventTypes } from 'lib/sendPreviewEvent'
import { Channels } from './components/ChannelPreviewDropdown/types'
import { SelectedRouteProps } from 'store/types'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

export const isEditorPage = (pathname: string): boolean => {
  return pathname.indexOf(editorPath) >= 0
}

export const isGcEditorPage = (pathname: string): boolean => {
  return pathname.indexOf(gcEditorPath) >= 0
}

export const isEditorType = (kind: string): boolean => {
  return kind === editorPreviewType
}

export const buildVersionInfoPayload = (
  props: PreviewEventProps
): PayloadProps => {
  const {
    featuresList: { multiChannel, locales, activeLang },
    queryParams: { pageId, versionId, gcId },
    selectedRoute,
  } = props

  return {
    versionId,
    ...(pageId && {
      pageId,
    }),
    ...(gcId && {
      gcId,
    }),
    ...(multiChannel && {
      channels: selectedRoute?.page?.channels ?? selectedRoute?.channels ?? [],
    }),
    ...(locales && {
      activeLocale: activeLang ?? '',
    }),
  }
}

export const prepAndSendPreviewEvent = ({
  featuresList,
  queryParams,
  selectedRoute,
}: PreviewEventProps): void => {
  const versionInfoPayload: VersionInfoPayloadProps = buildVersionInfoPayload({
    featuresList,
    queryParams,
    selectedRoute,
  })
  sendPreviewEvent({
    type: eventTypes.XM_VERSION_INFO,
    versionInfoPayload,
  })
}

const getOriginOfUrl = (str: string): string => {
  try {
    const url = new URL(str)
    return url.origin
  } catch {
    console.error('Error fetching origin of URL')
    return null
  }
}

export const handleMessageEvents = ({
  event,
  featuresList,
  iframeSource,
  queryParams,
  selectedRoute,
}: PreviewEventProps): void => {
  try {
    console.log('Handling window message...', event)
    const iframeSrcBase = getOriginOfUrl(iframeSource)
    const origin = event?.origin
    if (!origin || origin !== iframeSrcBase) {
      console.log(`Event origin ${origin} is not expected, quitting...`)
      return
    }
    if (typeof event?.data !== 'string' || !event?.data.length) return
    if (event?.data === eventTypes.XM_REQUEST_LAYOUT) {
      console.log('Receiving request to return event payload', event)
      prepAndSendPreviewEvent({
        featuresList,
        queryParams,
        selectedRoute,
      })
    }
  } catch (err) {
    console.error('Error occurred while processing incoming events:', err)
  }
}

export const retrieveSelectedRouteFirstChannel = (
  selectedRoute: SelectedRouteProps
): Channels => {
  return selectedRoute?.page?.channels?.[0] ?? selectedRoute?.channels?.[0]
}

export const getParentElement = (
  data: SelectedRouteProps,
  isGlobal: boolean,
  pageSelectedRoute: PageVersionProps
): PageParentElementProps | GEParentElementProps => {
  if (isGlobal) {
    return {
      id: data.globalComponentId,
      name: data.globalComponentId,
    }
  }

  return {
    name: pageSelectedRoute.getPageName(),
    pageUrl: pageSelectedRoute.getPageUrl(),
    id: pageSelectedRoute.getPageId(),
  }
}

export const getVersionElement = (
  data: SelectedRouteProps,
  isGlobal: boolean,
  pageSelectedRoute: PageVersionProps
): CommonElementProps => {
  if (isGlobal) {
    return {
      id: data.versionId,
      channels: data.channels,
      name: data.name,
      description: data.description,
      locales: data.locales,
    }
  }

  return {
    id: pageSelectedRoute.getVersionId(),
    name: pageSelectedRoute.getVersionName(),
    description: pageSelectedRoute.getVersionDescription(),
  }
}

export const getSelectedRouteFromGqlResponse = (
  versionDetails: VersionDetailsFromGqlResponse
): SelectedRouteProps => {
  let selectedRoute = get(
    versionDetails,
    'data.globalElementVariantById.variants[0]',
    {}
  ) as SelectedRouteProps

  selectedRoute = {
    ...selectedRoute,
    description: selectedRoute.description ?? '',
    globalComponentId: versionDetails.data?.globalElementVariantById?.id,
  }

  return selectedRoute
}

export const getLocalizedComponents = (
  localizedComponents: LocalizedComponents[],
  locales: string[]
): LocalizedComponents[] => {
  if (isEmpty(localizedComponents)) {
    return locales.map(locale => ({
      locale,
      components: [],
    }))
  }

  return localizedComponents
}

export const extractLocaleInput = (
  localizedComponents: LocalizedComponents[]
): { locales: string[]; localizedComponents: LocalizedComponents[] } => {
  return localizedComponents?.reduce(
    (acc, cur) => {
      acc.locales.push(cur.locale)
      acc.localizedComponents.push({
        locale: cur.locale,
        components: cur.components,
      })
      return acc
    },
    { locales: [], localizedComponents: [] }
  )
}
