import { FF_NAMES, PUBLISH_MODAL } from 'src/constants'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import {
  StyledChannelItem,
  StyledContainer,
  StyledContainerItem,
  StyledRightColumn,
  StyledSaveTimeDurationText,
} from './styles'
import {
  getDescriptorMismatchState,
  getSelectedRoute,
} from 'modules/editor/selectors'
import { CONTENT_TYPE } from 'components/ContentEditorCTA/types'
import ChannelPreviewDropdown from 'modules/editor/components/ChannelPreviewDropdown'
import ConfirmDialog from 'components/ConfirmDialog'
import ContentEditorCTA from 'components/ContentEditorCTA'
import PreviewControls from 'components/PreviewControls'
import PropTypes from 'prop-types'
import { VERSION_STATUS } from 'modules/editor/constants'
import { getActiveLanguage } from 'store/i18n/selectors'
import getHasEditAccessBasedOnVersionStatus from 'lib/getHasEditAccessBasedOnVersionStatus'
import getSaveTimeDuration from './getSaveTimeDuration'
import { getVersionLocaleList } from './EditorFilters/utils'
import noop from 'lodash/noop'
import { useFlag } from '@unleash/proxy-client-react'
import { useSelector } from 'react-redux'
import { useUserPermissions } from 'contexts/userPermissions'

export const EDITOR_TYPES = Object.freeze({
  GLOBAL_COMPONENT: 'gcEditor',
  PAGE: 'pageEditor',
})

const {
  unleashFFs: { USE_GRAPHQL, I18N },
} = FF_NAMES
// eslint-disable-next-line sonarjs/cognitive-complexity
const EditorContentHeader = props => {
  const {
    saveTime,
    versionName,
    versionStatus,
    updatedAt,
    isParentActive,
    handleOnPublishButtonClick,
    selectedDevice,
    hasCuratedComponents,
    previewUrl,
    handleOnFrameChange,
    handleOnSaveButtonClick,
    handleOnScheduleButtonClick,
    type,
    multiChannel,
    isEditorLoading,
    locales,
  } = props

  const selectedRoute = useSelector(getSelectedRoute)
  const [selectedLocale, setSelectedLocale] = useState('')
  const activeLang = useSelector(getActiveLanguage)

  let assignedLocales = useMemo(() => {
    return []
  }, [])

  if (selectedRoute?.locales && locales) {
    assignedLocales = getVersionLocaleList(locales, selectedRoute.locales)
  }

  useEffect(() => {
    if (assignedLocales.find(({ code }) => code === activeLang)) {
      setSelectedLocale(activeLang)
    } else {
      setSelectedLocale(assignedLocales[0]?.code)
    }
  }, [assignedLocales, activeLang])

  const hasDescriptorMismatch = useSelector(getDescriptorMismatchState)
  const [timer, setTimer] = useState(null)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const duration = 30000
  const isLiveVersion = versionStatus === VERSION_STATUS.LIVE
  const isPublishDisabled =
    hasDescriptorMismatch || !hasCuratedComponents || !isParentActive
  const contentType =
    type === EDITOR_TYPES.GLOBAL_COMPONENT
      ? CONTENT_TYPE.GLOBAL_ELEMENT
      : CONTENT_TYPE.PAGE

  const { hasPublisherPermissions, hasEditorPermissions } = useUserPermissions()
  const hasEditAccess = getHasEditAccessBasedOnVersionStatus(
    { hasEditorPermissions, hasPublisherPermissions },
    versionStatus
  )
  const isI18n = useFlag(I18N)
  const useGraphql = useFlag(USE_GRAPHQL)
  const isGraphQlEnabled = isI18n || useGraphql
  const isSaveEnabled = !isLiveVersion && hasEditAccess

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(Date.now())
    }, duration)

    return () => clearInterval(interval)
  }, [])

  const handleConfirmDialogSubmit = async () => {
    if (!isGraphQlEnabled) {
      await handleOnSaveButtonClick()
    }

    handleOnPublishButtonClick()
    setShowConfirmationDialog(false)
  }

  const handleConfirmDialogCancel = () => {
    setShowConfirmationDialog(false)
  }

  const saveTimeDuration = useMemo(
    () =>
      getSaveTimeDuration({
        time: saveTime,
        timer,
        versionName: versionName,
      }),
    [saveTime, timer, versionName]
  )

  return (
    <Fragment>
      {showConfirmationDialog && (
        <ConfirmDialog
          isVisible={true}
          title={PUBLISH_MODAL.title}
          content={PUBLISH_MODAL.getContent(contentType)}
          submitText={PUBLISH_MODAL.submitText}
          onSubmit={handleConfirmDialogSubmit}
          cancelText={PUBLISH_MODAL.cancelText}
          onCancel={handleConfirmDialogCancel}
        />
      )}
      <StyledContainer>
        <StyledContainerItem>
          <StyledSaveTimeDurationText data-testid='save-time-duration-text'>
            {saveTimeDuration}
          </StyledSaveTimeDurationText>
        </StyledContainerItem>
        <StyledContainerItem>
          <PreviewControls
            selectedDevice={selectedDevice}
            previewUrl={previewUrl}
            handleOnFrameChange={handleOnFrameChange}
          />
        </StyledContainerItem>
        <StyledRightColumn>
          <StyledChannelItem>
            {!isEditorLoading && multiChannel && <ChannelPreviewDropdown />}
          </StyledChannelItem>
          {!isEditorLoading && (
            <ContentEditorCTA
              locales={assignedLocales}
              selectedLocale={selectedLocale}
              contentType={contentType}
              isContentLive={isLiveVersion}
              lastUpdatedDateString={updatedAt}
              onScheduleOptionClicked={handleOnScheduleButtonClick}
              publishButtonProps={{
                hasTooltip: !hasCuratedComponents,
                isDisabled: isPublishDisabled,
                isVisible: hasPublisherPermissions,
                onClick: () => {
                  setShowConfirmationDialog(true)
                },
              }}
              saveButtonProps={{
                hasTooltip: !hasCuratedComponents,
                isDisabled: !hasCuratedComponents || hasDescriptorMismatch,
                isVisible: isSaveEnabled,
                onClick: handleOnSaveButtonClick,
                isSavePrimary: hasEditAccess && !hasPublisherPermissions,
              }}
              shouldShowLockIcon={!hasEditAccess}
            />
          )}
        </StyledRightColumn>
      </StyledContainer>
    </Fragment>
  )
}

EditorContentHeader.defaultProps = {
  saveTime: null,
  versionName: '',
  versionStatus: '',
  updatedAt: '',
  isParentActive: false,
  previewUrl: '',
  selectedDevice: 'desktop',
  hasCuratedComponents: false,
  handleOnSaveButtonClick: noop,
  handleOnScheduleButtonClick: noop,
  handleOnPublishButtonClick: noop,
  handleOnFrameChange: noop,
  type: EDITOR_TYPES.PAGE,
  multiChannel: false,
  isEditorLoading: true,
  isUsingToby: false,
  locales: null,
}

EditorContentHeader.propTypes = {
  saveTime: PropTypes.number,
  versionName: PropTypes.string.isRequired,
  versionStatus: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  isParentActive: PropTypes.bool.isRequired,
  previewUrl: PropTypes.string.isRequired,
  selectedDevice: PropTypes.string.isRequired,
  hasCuratedComponents: PropTypes.bool.isRequired,
  handleOnSaveButtonClick: PropTypes.func.isRequired,
  handleOnScheduleButtonClick: PropTypes.func.isRequired,
  handleOnPublishButtonClick: PropTypes.func.isRequired,
  handleOnFrameChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  multiChannel: PropTypes.bool.isRequired,
  isEditorLoading: PropTypes.bool.isRequired,
  locales: PropTypes.array,
}

export default EditorContentHeader
