import App from './app'
import PropTypes from 'prop-types'
import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import store from 'store'

const Root = () => (
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>
)

Root.propTypes = {
  name: PropTypes.string,
}

export default Root
