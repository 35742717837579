import { DEFAULT_TOAST_DURATION, TOAST_MESSAGE_TYPES } from 'src/constants'
import React, { useEffect } from 'react'
import { Snackbar, theme as themeUi } from '@teamfabric/copilot-ui'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import theme from 'styles/theme'

const ToastSnackbar = props => {
  const {
    isDismissable,
    isFloating,
    isHighEmphasis,
    visible,
    onDismiss,
    message,
    position,
    width,
    kind,
    toastId,
  } = props

  useEffect(() => {
    const toast = document.getElementById(toastId)
    const timeoutId = setTimeout(() => {
      toast?.remove()
      clearTimeout(timeoutId)
    }, DEFAULT_TOAST_DURATION)

    if (kind === TOAST_MESSAGE_TYPES.ALERT) {
      console.error(message)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [toastId, kind, message])

  return (
    visible && (
      <Snackbar
        dismissable={isDismissable}
        isFloating={isFloating}
        isHighEmphasis={isHighEmphasis}
        show={visible}
        onDismiss={onDismiss}
        label={message}
        position={position}
        width={width}
        kind={kind}
        textColor={themeUi.palette.brand.primary.white}
        data-testid='snackbar-toast-message'
        className='snackbar_container'
      />
    )
  )
}

ToastSnackbar.propTypes = {
  isDismissable: PropTypes.bool,
  isFloating: PropTypes.bool,
  isHighEmphasis: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func,
  message: PropTypes.string.isRequired,
  position: PropTypes.string,
  width: PropTypes.string,
  kind: PropTypes.string.isRequired,
  toastId: PropTypes.string.isRequired,
}

ToastSnackbar.defaultProps = {
  visible: false,
  isDismissable: true,
  onDismiss: noop,
  isFloating: true,
  isHighEmphasis: true,
  width: theme.snackbar.width,
  position: 'top-center',
  kind: 'message',
  toastId: '',
}

export default ToastSnackbar
