import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

const StyledSwitchOpacity = 0.3

export const StyledSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
  opacity: ${({ disabled }) => (disabled ? StyledSwitchOpacity : 1)};

  > input {
    display: none;
  }
  > span.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${themeUi.palette.brand.primary.gray};
    transition: 0.4s;
    border-radius: ${props => props.theme.borderRadius.lg};
  }
  > span.slider:before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: ${themeUi.palette.brand.primary.white};
    transition: 0.4s;
    border-radius: ${props => props.theme.borderRadius.rounded};
  }
  > input:checked + span.slider {
    background-color: ${({ color }) => themeUi.palette.ui.cta[color]};
  }
  > input:checked + span.slider:before {
    transform: translateX(16px);
  }
`
