import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

interface StyledInputFieldProps {
  isDisabled: boolean
}

export const StyledContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: ${({ theme }) => theme.space.space_5};

  & > div {
    border: 1px solid ${themeUi.palette.ui.neutral.grey3};
    padding: ${({ theme }) => theme.space.space_5};
  }

  & > div:first-child {
    min-width: 200px;
  }

  & pre {
    ${themeUi.typography.h5}
  }

  .descriptor-description,
  .descriptor-attributes {
    .has-content {
      border-bottom: none;
    }
    .container_focus {
      .has-content {
        border: 1px solid ${themeUi.palette.brand.primary.charcoal};
      }
    }
  }

  .descriptor-attributes {
    &:not(.container_focus) {
      .textarea__label {
        padding-bottom: 1.5rem;
      }
      .descriptor-attributes-textarea {
        margin-left: 0.75rem;
      }
    }
    .container_focus {
      .descriptor-attributes-textarea {
        margin-left: 0;
      }
    }
  }

  .descriptor-attributes-textarea {
    line-height: ${({ theme }) => theme.typography.lineHeight.snug};
  }
`

export const StyledInputField = styled.div<StyledInputFieldProps>`
  margin: ${({ theme }) => theme.space.space_6} 0;

  .textarea_parent > div,
  .input__wrapper {
    border: ${({ isDisabled }) => isDisabled && `none !important;`};
  }

  .textarea__label {
    padding-top: 0;
  }

  input:disabled {
    opacity: 1;
    color: ${themeUi.palette.brand.primary.charcoal} !important;
  }

  textarea {
    background: none;
  }

  textarea:disabled {
    color: ${themeUi.palette.brand.primary.charcoal};
  }
`
