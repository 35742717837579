import { Button } from '@teamfabric/copilot-ui'
import { DESCRIPTOR_DETAILS } from 'src/constants'
import { DescriptorHeaderProps } from './types'
import EditComponentMode from 'modules/settings/utils/EditComponentMode'
import React from 'react'
import { StyledButtonWrapper } from './styles'
import { XM_EDIT_DESCRIPTORS_ROLE } from 'src/rbac-constants'
import { useRbacPermission } from 'hooks/useRbacPermission'

const {
  CTA: { CANCEL, SAVE, EDIT, DELETE },
} = DESCRIPTOR_DETAILS

const SingleDescriptorButtonGroup: React.FunctionComponent<Omit<
  DescriptorHeaderProps,
  'label'
>> = ({
  mode,
  setMode,
  handleSubmit,
  handleCancel,
  showDeleteModal,
  submitDisabled,
}) => {
  const hasAccess = useRbacPermission(XM_EDIT_DESCRIPTORS_ROLE)
  const canEditUd = () => {
    if (hasAccess) {
      return (
        <StyledButtonWrapper>
          {mode.isEditMode() ? (
            <>
              <Button
                size='small'
                isPrimary={false}
                text={CANCEL}
                onClick={handleCancel}
                data-testid='descriptor-cta-cancel'
              />
              {mode.shouldShowDeleteButton() && (
                <Button
                  size='small'
                  isPrimary={false}
                  text={DELETE}
                  onClick={showDeleteModal}
                  data-testid='descriptor-cta-delete'
                />
              )}
              <Button
                size='small'
                text={SAVE}
                onClick={handleSubmit}
                data-testid='descriptor-cta-save'
                disabled={submitDisabled}
              />
            </>
          ) : (
            <Button
              size='small'
              isPrimary={false}
              text={EDIT}
              onClick={() => setMode(EditComponentMode)}
              data-testid='descriptor-cta-edit'
            />
          )}
        </StyledButtonWrapper>
      )
    }
    return <></>
  }

  return canEditUd()
}

export default SingleDescriptorButtonGroup
