import { gql } from '@apollo/client'

export const PAGE_VERSION_OPERATION_OPTIONS = {
  CREATE_PAGE_VERSION: 'CreatePageVersion',
  ARCHIVE_PAGE_VERSION: 'ArchivePageVersion',
  UNARCHIVE_PAGE_VERSION: 'UnarchivePageVersion',
  COPY_PAGE_VERSION: 'CopyPageVariant',
}

export const CREATE_PAGE_VERSION_STRING = `
  mutation CreatePageVersion($input: CreateContentVariantInput!) {
    createPageVariant(input: $input) {
      id
    }
  }
`

export const GET_PAGE_VERSION_BY_ID = gql`
  query GetPageVersionById(
    $contentVariantId: ID!
    $pageContentId: ID!
    $locale: String
  ) {
    pageVariantById(
      contentVariantId: $contentVariantId
      pageContentId: $pageContentId
      locale: $locale
    ) {
      id
      name
      url
      isActive
      locales
      channels {
        url
        id
        label
      }
      variants {
        id
        components
        description
        versionName
        status
        locales
        updatedAt
        startDate
        endDate
        isArchived
        localizedComponents {
          locale
          components
        }
      }
    }
  }
`

export const UPDATE_PAGE_VERSION_BY_ID = gql`
  mutation UpdatePageVersionById($input: UpdateContentVariantInput!) {
    updatePageVariantDetails(input: $input) {
      id
      versionName
      description
      status
      startDate
      endDate
      updatedAt
      isArchived
      components
    }
  }
`

export const UPDATE_PAGE_VERSION_COMPONENTS = gql`
  mutation UpdatePageVersionComponents($input: UpdateComponentsInput!) {
    updatePageComponents(input: $input) {
      id
      components
    }
  }
`

export const UPDATE_PAGE_VERSION_LOCALIZED_COMPONENTS = gql`
  mutation UpdatePageVersionLocalizedComponents(
    $input: UpdateComponentsByLocaleInput!
  ) {
    updatePageLocalizedComponents(input: $input) {
      id
    }
  }
`

export const ARCHIVE_PAGE_VERSION_STRING = `
  mutation ArchivePageVersion($contentVariantId: ID!, $parentContentId: ID!) {
    archiveContentVariant(
      contentVariantId: $contentVariantId
      parentContentId: $parentContentId
    ) {
      id
      versionName
      description
      status
      startDate
      endDate
      updatedAt
      isArchived
    }
  }
`

export const UNARCHIVE_PAGE_VERSION_STRING = `
  mutation UnarchivePageVersion($contentVariantId: ID!, $parentContentId: ID!) {
    unarchiveContentVariant(
      contentVariantId: $contentVariantId
      parentContentId: $parentContentId
    ) {
      id
      versionName
      description
      status
      startDate
      endDate
      updatedAt
      isArchived
    }
  }
`

export const COPY_PAGE_VARIANT = gql`
  mutation CopyPageVariant($input: CopyPageVariantInput!) {
    copyPageVariant(input: $input) {
      id
      isArchived
      versionName
      updatedAt
      description
    }
  }
`

export const UPDATE_PAGE_COMPONENTS_AND_PUBLISH = gql`
  mutation UpdatePageComponentsAndPublish(
    $input: UpdateComponentsAndPublishInput!
  ) {
    updatePageComponentsAndPublish(input: $input) {
      contentId
      variantId
      status
      updatedAt
      startDate
      endDate
      channels
      components
      localizedComponents
    }
  }
`
export const ACTIVATE_PAGE_STRING = `
  mutation ActivatePage($contentMetaId: ID!) {
    activateContentMeta(
      contentMetaId: $contentMetaId
    ) {
        id
        updatedAt
        isActive
    }
  }
`

export const DEACTIVATE_PAGE_STRING = `
  mutation DeactivatePage($contentMetaId: ID!) {
    deactivateContentMeta(
      contentMetaId: $contentMetaId
    ) {
        id
        updatedAt
        isActive
    }
  }
`
