import {
  CREATE_UNIVERSAL_DESCRIPTOR,
  DELETE_SINGLE_DESCRIPTOR_BY_ID,
  GET_UNIVERSAL_DESCRIPTORS,
  UPDATE_UNIVERSAL_DESCRIPTOR_BY_ID,
} from 'services/graphql/universalDescriptors'
import {
  InvalidContextProps,
  SETTINGS_MENU,
  UniversalDescriptorsProps,
} from './types'
import { MESSAGES, TOAST_MESSAGE_TYPES } from 'src/constants'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import {
  getDescriptorsWithDuplicateIDs,
  getListToCreateAndToUpdate,
  handleUpload,
  onFileInputChange,
  validateUploadData,
} from './utils'
import {
  handleAscendingSort,
  handleDescendingSort,
} from 'components/DetailsTable3.0/utils'
import { useLazyQuery, useMutation } from '@apollo/client'
import AddComponentMode from 'modules/settings/utils/AddComponentMode'
import AllComponentsMode from 'modules/settings/utils/AllComponentsMode'
import ComponentViewMode from 'modules/settings/utils/ComponentViewMode'
import ConfirmDeleteModal from './modals/DeleteDescriptorModal'
import ConfirmUploadModal from './modals/ConfirmUploadModal'
import DescriptorDetails from './descriptor-details/DescriptorDetails'
import { DescriptorProps } from 'store/types'
import DuplicateDescriptorsModal from './modals/DuplicateDescriptorsModal'
import EditComponentMode from './utils/EditComponentMode'
import Header from './header'
import InvalidUploadDataModal from './modals/InvalidUploadDataModal'
import NoComponents from './no-components'
import { SHOW_TOAST_MESSAGE } from 'modules/toast-messages/actions'
import { StyledWrapper } from './styles'
import { Table } from '@teamfabric/copilot-ui'
import ViewComponentMode from 'modules/settings/utils/ViewComponentMode'
import { getColumns } from './columns'
import isObject from 'lodash/isObject'
import orderBy from 'lodash/orderBy'
import { showToast } from 'components/ToastSnackbarContainer'
import size from 'lodash/size'
import { useDispatch } from 'react-redux'
import { useUserPermissions } from 'contexts/userPermissions'

// eslint-disable-next-line sonarjs/cognitive-complexity
const Settings = (): ReactElement => {
  const userPermissions = useUserPermissions()
  const [getUniversalDescriptors, { loading, data }] = useLazyQuery<
    UniversalDescriptorsProps
  >(GET_UNIVERSAL_DESCRIPTORS)
  const [allDescriptors, setAllDescriptors] = useState<DescriptorProps[]>(null)
  const [createUniversalDescriptor] = useMutation(CREATE_UNIVERSAL_DESCRIPTOR, {
    update(cache, { data: updatedData }) {
      const {
        universalDescriptors,
      }: { universalDescriptors: DescriptorProps[] } = cache.readQuery({
        query: GET_UNIVERSAL_DESCRIPTORS,
      })
      cache.writeQuery({
        query: GET_UNIVERSAL_DESCRIPTORS,
        data: {
          universalDescriptors: [
            ...universalDescriptors,
            updatedData.createUniversalDescriptor,
          ],
        },
      })
    },
  })

  const [updateUniversalDescriptor] = useMutation<DescriptorProps>(
    UPDATE_UNIVERSAL_DESCRIPTOR_BY_ID
  )
  const [storedDescriptors, setStoredDescriptors] = useState<DescriptorProps[]>(
    []
  )
  const [descriptorsToCreate, setDescriptorsToCreate] = useState([])
  const [descriptorsToUpdate, setDescriptorsToUpdate] = useState([])
  const [selected, setSelected] = useState<{
    descriptor: DescriptorProps
  } | null>(null)
  const [duplicateDescriptorIds, setDuplicateDescriptorIds] = useState<
    Set<string>
  >(null)
  const [
    isConfirmUploadModalVisible,
    setIsConfirmUploadModalVisible,
  ] = useState(false)
  const dispatch = useDispatch()
  const [isDuplicateIdModalVisible, setIsDuplicateIdModalVisible] = useState(
    false
  )
  const [deleteSingleDescriptor] = useMutation(DELETE_SINGLE_DESCRIPTOR_BY_ID)
  const [
    invalidDataContext,
    setInvalidDataContext,
  ] = useState<null | InvalidContextProps>(null)
  const [mode, setMode] = useState<ComponentViewMode>(AllComponentsMode)
  const [descriptorIds, setDescriptorIds] = useState([])
  const [showDeleteModal, setShowDeteleModal] = useState<boolean>(false)
  const handleFetchDescriptors = useCallback(async (): Promise<void> => {
    try {
      await getUniversalDescriptors()
    } catch (err) {
      console.error(err)
      void showToast({
        message: MESSAGES.ERROR_GET_DESCRIPTORS,
        kind: TOAST_MESSAGE_TYPES.ALERT,
      })
    }
  }, [getUniversalDescriptors])

  const handleClickAddComponent = () => {
    setMode(AddComponentMode)
  }

  useEffect(() => {
    void handleFetchDescriptors()
  }, [handleFetchDescriptors])

  useEffect(() => {
    if (data) {
      const desc = data.universalDescriptors
      const ids = desc.map(nested => nested.id)
      setDescriptorIds(ids)
    }
  }, [data])

  useEffect(() => {
    setAllDescriptors(
      orderBy(
        data?.universalDescriptors,
        [item => item.label.toLowerCase()],
        ['asc']
      )
    )
  }, [data?.universalDescriptors])

  useEffect(() => {
    const isUploadDataValid = validateUploadData(
      storedDescriptors,
      setInvalidDataContext
    )

    if (isUploadDataValid && size(storedDescriptors)) {
      const descriptorsWithDuplicateIDs: Set<string> = getDescriptorsWithDuplicateIDs(
        storedDescriptors
      )
      if (descriptorsWithDuplicateIDs.size) {
        setDuplicateDescriptorIds(descriptorsWithDuplicateIDs)
        setIsDuplicateIdModalVisible(true)
      } else {
        const { toCreate, toUpdate } = getListToCreateAndToUpdate(
          allDescriptors,
          storedDescriptors
        )
        setDescriptorsToCreate(toCreate)
        setDescriptorsToUpdate(toUpdate)

        if (size(toUpdate)) {
          setIsConfirmUploadModalVisible(true)
        } else {
          void handleUpload({
            storedDescriptors,
            descriptorsToCreate: toCreate,
            descriptorsToUpdate: toUpdate,
            createUniversalDescriptor,
            updateUniversalDescriptor,
          })
          setStoredDescriptors([])
        }
      }
    }
  }, [
    storedDescriptors,
    allDescriptors,
    createUniversalDescriptor,
    updateUniversalDescriptor,
  ])

  const handleConfirmUploadModalClose = () => {
    setIsConfirmUploadModalVisible(false)
    setStoredDescriptors([])
  }

  const handleDelete = useCallback(async () => {
    try {
      await deleteSingleDescriptor({
        variables: {
          id: selected?.descriptor?.id,
        },
      })
      dispatch(
        SHOW_TOAST_MESSAGE({
          message: MESSAGES.getDeletedSuccess(selected?.descriptor?.label),
          kind: TOAST_MESSAGE_TYPES.SUCCESS,
        })
      )
      mode.retrieveOnNavigateBack(setMode, setSelected)()
      await handleFetchDescriptors()
    } catch (err) {
      dispatch(
        SHOW_TOAST_MESSAGE({
          message: MESSAGES.getDeletedError(selected?.descriptor?.label),
          kind: TOAST_MESSAGE_TYPES.ALERT,
        })
      )
    }
  }, [deleteSingleDescriptor, selected, dispatch])

  const handleDuplicateDescriptorsModalClose = () => {
    setIsDuplicateIdModalVisible(false)
    setDuplicateDescriptorIds(null)
    setStoredDescriptors([])
  }

  const handleInvalidUploadDataModalClose = () => {
    setInvalidDataContext(null)
    setStoredDescriptors([])
  }

  const handleModalClose = () => setShowDeteleModal(false)

  return (
    <>
      {showDeleteModal && (
        <ConfirmDeleteModal
          handleModalClose={handleModalClose}
          handleDelete={handleDelete}
        />
      )}
      {mode !== AllComponentsMode ? (
        <>
          <DescriptorDetails
            mode={mode}
            setMode={setMode}
            id={mode.retrieveId(selected?.descriptor?.id)}
            onNavigateBack={mode.retrieveOnNavigateBack(setMode, setSelected)}
            descriptorIds={descriptorIds}
            showDeleteModal={() => setShowDeteleModal(true)}
          />
        </>
      ) : (
        <StyledWrapper>
          <Header
            onFileInputChange={({ target }: { target: HTMLInputElement }) => {
              onFileInputChange({
                target,
                setStoredDescriptors,
                setInvalidDataContext,
              })
            }}
            handleFetchDescriptors={handleFetchDescriptors as () => void}
            handleClickAddComponent={handleClickAddComponent}
            loading={loading}
          />
          <Table
            columns={getColumns({
              onClickItem: (descriptor, settingMode) => {
                setSelected({ descriptor })
                if (settingMode !== SETTINGS_MENU.DELETE) {
                  setMode(
                    settingMode === SETTINGS_MENU.EDIT
                      ? EditComponentMode
                      : ViewComponentMode
                  )
                } else {
                  setShowDeteleModal(true)
                }
              },
              userPermissions,
            })}
            data={allDescriptors ?? []}
            render={() => {
              if (!loading && !allDescriptors?.length) {
                return <NoComponents />
              }
              return null
            }}
            handleAscendingSort={handleAscendingSort}
            handleDescendingSort={handleDescendingSort}
            loading={loading}
            enableSort={true}
          />
          {isConfirmUploadModalVisible && (
            <ConfirmUploadModal
              handleModalClose={handleConfirmUploadModalClose}
              storedDescriptors={storedDescriptors}
              descriptorsToUpdate={descriptorsToUpdate}
              descriptorsToCreate={descriptorsToCreate}
              createUniversalDescriptor={createUniversalDescriptor}
              updateUniversalDescriptor={updateUniversalDescriptor}
            />
          )}

          {isObject(invalidDataContext) && (
            <InvalidUploadDataModal
              onClose={handleInvalidUploadDataModalClose}
              context={invalidDataContext}
            />
          )}

          {isDuplicateIdModalVisible && (
            <DuplicateDescriptorsModal
              onClose={handleDuplicateDescriptorsModalClose}
              duplicateIds={duplicateDescriptorIds}
            />
          )}
        </StyledWrapper>
      )}
    </>
  )
}

export default Settings
