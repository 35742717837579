import React, { FunctionComponent } from 'react'
import {
  StyledButtonBlock,
  StyledContent,
  StyledPrimaryText,
  StyledSecondaryText,
  StyledWrapper,
} from './styles'
import { Button } from '@teamfabric/copilot-ui'

interface ButtonProps {
  onClick: () => void
  size: string
  text: string
  disabled?: boolean
}

interface EmptyScreenProps {
  className?: string
  primaryText: string
  secondaryText: string
  buttonProps?: ButtonProps
  footerContent?: FunctionComponent
}

const EmptyScreen: FunctionComponent<EmptyScreenProps> = ({
  className,
  primaryText,
  secondaryText,
  buttonProps,
  footerContent,
}) => {
  return (
    <>
      <StyledWrapper className={className}>
        <StyledContent>
          {primaryText && <StyledPrimaryText>{primaryText}</StyledPrimaryText>}
          {secondaryText && (
            <StyledSecondaryText>{secondaryText}</StyledSecondaryText>
          )}
          {buttonProps && (
            <StyledButtonBlock data-testid='empty-screen-cta'>
              <Button {...buttonProps} />
            </StyledButtonBlock>
          )}
          {footerContent && <div>{footerContent}</div>}
        </StyledContent>
      </StyledWrapper>
    </>
  )
}

export default EmptyScreen
