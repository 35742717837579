import { Tab, TabItem } from '@teamfabric/copilot-ui'
import { ContentTabProps } from './types'
import React from 'react'
import { StyledTabsWrapper } from './styles'
import isEmpty from 'lodash/isEmpty'

const ContentTabModule = React.memo<ContentTabProps>(
  ({ activeTabIndex, setActiveTabIndex, tabs }) =>
    !isEmpty(tabs) && (
      <StyledTabsWrapper>
        <Tab
          data-testid='tab-wrapper'
          customSetActiveIndex={setActiveTabIndex}
          customActiveIndex={activeTabIndex}
        >
          {tabs.map(tab => (
            <TabItem
              key={tab.id}
              title={tab.title}
              data-testid={tab.id}
            ></TabItem>
          ))}
        </Tab>
      </StyledTabsWrapper>
    )
)

export default ContentTabModule
