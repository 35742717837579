import { CONFIG_INIT, CONFIG_RESET } from './actions'
import httpStatusCodes from 'http-status-codes'
import produce from 'immer'

const initialState = {
  ecommerceAppUrl: '',
  imageUrl: '',
  loaded: false,
  accountId: null,
  status: httpStatusCodes.OK,
  features: {},
}

export const init = (state, { config }) => {
  return produce(state, draftState => {
    return Object.assign(draftState, {
      ...config,
      features: {
        ...(config?.features ?? {}),
      },
      loaded: true,
    })
  })
}

const reducer = (state = initialState, action) => {
  switch (action?.type) {
    case CONFIG_INIT:
      return init(state, action)
    case CONFIG_RESET:
      return initialState
    default:
      return state
  }
}

export default reducer
