import React, { FunctionComponent } from 'react'
import ContentCTAModule from 'components/ContentCTAModule'
import ContentFilterModule from 'components/ContentFilterModule'
import { ContentListModuleProps } from './types'
import { ContentListModuleWrapper } from './styles'
import ContentModalModule from 'components/ContentModalModule'
import ContentSearchModule from 'components/ContentSearchModule'
import ContentTabModule from 'components/ContentTabModule'
import ContentTableModule from 'components/ContentTableModule'

const ContentListModule: FunctionComponent<ContentListModuleProps> = ({
  ctaProps,
  filterProps,
  modalProps,
  tabProps,
  tableProps,
  searchProps,
}) => (
  <ContentListModuleWrapper>
    <ContentModalModule {...modalProps} />
    <ContentCTAModule {...ctaProps} />
    <ContentSearchModule {...searchProps} />
    <ContentTabModule {...tabProps} />
    <ContentFilterModule {...filterProps} />
    <ContentTableModule {...tableProps} />
  </ContentListModuleWrapper>
)

export default ContentListModule
