import { FOUND_INDEX, NOT_FOUND_INDEX } from 'src/constants'

const POSSIBLE_ORDERS = {
  ASC: 'ascending',
  DESC: 'descending',
}

const handleSort = (
  order: string,
  accessor: string,
  _data: unknown[],
  callback: (result: unknown[]) => void
): void => {
  const result = [..._data].sort((a, b) => {
    let isGreater
    switch (accessor) {
      case 'updatedAt':
      case 'createdAt':
        isGreater =
          new Date(a[accessor] as string) > new Date(b[accessor] as string)
        break
      case 'pageCount':
        isGreater = a[accessor] > b[accessor]
        break
      case 'name':
      case 'urlPrefix':
      case 'pageUrl':
      case 'label':
        isGreater = a[accessor]?.toLowerCase() > b[accessor]?.toLowerCase()
        break
      default:
        isGreater = false
        break
    }
    if (order === POSSIBLE_ORDERS.ASC) {
      return isGreater ? FOUND_INDEX : NOT_FOUND_INDEX
    }
    return isGreater ? NOT_FOUND_INDEX : FOUND_INDEX
  })
  callback(result)
}

export const handleAscendingSort = (
  accessor: string,
  _data: unknown[],
  callback: (result: unknown[]) => void
): void => {
  handleSort(POSSIBLE_ORDERS.ASC, accessor, _data, callback)
}

export const handleDescendingSort = (
  accessor: string,
  _data: unknown[],
  callback: (result: unknown[]) => void
): void => {
  handleSort(POSSIBLE_ORDERS.DESC, accessor, _data, callback)
}
