import { DescriptorProps } from 'store/types'

export default abstract class ComponentViewMode {
  abstract retrieveOnNavigateBack(
    method1: (mode: ComponentViewMode) => void,
    method2: (
      value: {
        descriptor: DescriptorProps
      } | null
    ) => void
  ): () => void

  abstract retrieveId(id: string): string
  abstract isEditMode(): boolean
  abstract showDescriptorLabelError(
    descriptorLabelLength: number,
    labelChanged: boolean
  ): boolean
  abstract retrieveHeader(label: string): string
  abstract setModeAfterSave(setMode: (mode: ComponentViewMode) => void): void
  abstract showDescriptorIdError(id: string, idChanged: boolean): boolean
  abstract shouldHideSubmitButton(
    singleDescriptor: DescriptorProps | null,
    idChanged: boolean
  ): boolean

  abstract shouldShowDeleteButton(): boolean
}
