import { CANCEL_TEXT, OK, SAVE_TEXT, VIEW_DETAILS_LABEL } from 'src/constants'
import React, { FunctionComponent } from 'react'
import { Button } from '@teamfabric/copilot-ui'
import { ManageNodeViewHeaderProps } from './types'
import { StyledManageNodeHeader } from './styles'
import { getVersionStatus } from 'modules/editor/selectors'
import { useSelector } from 'react-redux'
import { useVersionBasedEditAccess } from 'hooks/useVersionBasedEditAccess'

export const CANCEL_CTA_ID = 'manage-node-cancel-cta'
export const SAVE_CTA_ID = 'manage-node-save-cta'

const ManageNodeViewHeader: FunctionComponent<ManageNodeViewHeaderProps> = ({
  title,
  onCancel,
  onSave,
}) => {
  const menuVersionStatus = useSelector(getVersionStatus) as string
  const hasEditAccess = useVersionBasedEditAccess(menuVersionStatus)
  const headerTitle = hasEditAccess ? title : VIEW_DETAILS_LABEL
  const closeBtnText = hasEditAccess ? CANCEL_TEXT : OK
  return (
    <StyledManageNodeHeader>
      <div>{headerTitle}</div>

      <div
        className='manage-node-cta-buttons'
        data-testid='manage-node-cta-buttons'
      >
        <Button
          data-testid={CANCEL_CTA_ID}
          onClick={onCancel}
          text={closeBtnText}
          size='small'
          isPrimary={false}
        />
        {hasEditAccess && (
          <Button
            data-testid={SAVE_CTA_ID}
            onClick={onSave}
            text={SAVE_TEXT}
            size='small'
            isPrimary={true}
            type='submit'
          />
        )}
      </div>
    </StyledManageNodeHeader>
  )
}

export default ManageNodeViewHeader
