import React, { memo } from 'react'

const IconAlignLeft = props => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='8'
      height='5'
      viewBox='0 0 8 5'
      {...props}
    >
      <g fill='none' fillRule='evenodd' stroke='#FFF' strokeLinecap='square'>
        <path d='M.5.5L7.5.5M.5 2.5L3.5 2.5M.5 4.5L7.5 4.5' />
      </g>
    </svg>
  )
}

export default memo(IconAlignLeft)
