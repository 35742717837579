import { Button, Modal } from '@teamfabric/copilot-ui'
import React, { memo, useState } from 'react'
import {
  StyledButtonWrapper,
  StyledConfirmDialog,
  StyledConfirmDialogContent,
  StyledModalTitle,
} from './styles'

import PropTypes from 'prop-types'
import { noop } from 'lodash'

const ConfirmDialog = props => {
  const {
    isVisible,
    title,
    content,
    submitText,
    cancelText,
    onCancel,
    onSubmit,
    submitButtonProps,
    cancelButtonProps,
  } = props

  const [shouldDisableCTA, setDisableCTA] = useState(false)

  if (!content || !isVisible || !title) {
    return null
  }

  const handleSubmit = () => {
    setDisableCTA(true)
    onSubmit()
  }
  return (
    <Modal
      headerButtons={[]}
      footerButtons={[]}
      headerText=''
      onBackdropClick={onCancel}
      onClose={onCancel}
      padding='20px 24px 20px 24px'
      showCloseButton
      size='small'
      render={() => {
        return (
          <StyledConfirmDialog
            data-testid='confirm-dialog'
            className='confirm-dialog'
          >
            <StyledModalTitle data-testid='modal-title'>
              {title}
            </StyledModalTitle>
            <StyledConfirmDialogContent
              data-testid='confirm-dialog-content'
              className='confirm-dialog-content'
            >
              {content}
            </StyledConfirmDialogContent>
            <StyledButtonWrapper className='confirm-dialog-button-bar'>
              <Button
                size='small'
                isPrimary={false}
                text={cancelText}
                onClick={onCancel}
                className='confirm-dialog-cancel-button'
                data-testid='confirm-dialog-cancel-button'
                {...cancelButtonProps}
              />
              <Button
                size='small'
                text={submitText}
                className='confirm-dialog-submit-button'
                data-testid='confirm-dialog-submit-button'
                onClick={handleSubmit}
                disabled={shouldDisableCTA}
                {...submitButtonProps}
              />
            </StyledButtonWrapper>
          </StyledConfirmDialog>
        )
      }}
    />
  )
}

ConfirmDialog.defaultProps = {
  isVisible: false,
  title: '',
  content: '',
  submitText: 'okay',
  cancelText: 'cancel',
  onCancel: noop,
  onSubmit: noop,
  submitButtonProps: {},
  cancelButtonProps: {},
}

ConfirmDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  submitText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButtonProps: PropTypes.object,
  cancelButtonProps: PropTypes.object,
}

export default memo(ConfirmDialog)
