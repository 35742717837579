import styled from 'styled-components'

export const StyledForm = styled.form`
  max-width: 85%;
  overflow: visible;
`
export const StyledFormField = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;

  textarea {
    ${({ viewMode }) =>
      viewMode &&
      `
        pointer-events: none;
        opacity: 0.5;
    `}
  }

  .url__label .input__label {
    position: relative;
  }
`

export const StyledButtonWrapper = styled.div`
  margin-bottom: 1.5rem !important;
  display: flex !important;
  justify-content: flex-end;

  & > button:first-child {
    margin-right: ${props => props.theme.space.space_5};
    border: 1px solid transparent;
  }
`
