import React, { memo } from 'react'
import { StyledButton, StyledButtonLink } from './styles'
import PropTypes from 'prop-types'

const Button = props => {
  const {
    color,
    href,
    size,
    disabled,
    variant,
    pending,
    children,
    ...rest
  } = props

  if (href) {
    return (
      <StyledButtonLink
        block={variant.block}
        href={href}
        color={color}
        size={size}
        variant={variant}
        disabled={disabled || pending}
        data-testid='button'
        {...rest}
      >
        {children}
      </StyledButtonLink>
    )
  }

  return (
    <StyledButton
      block={variant.block}
      color={color}
      size={size}
      variant={variant}
      disabled={disabled || pending}
      data-testid='button'
      {...rest}
    >
      {children}
    </StyledButton>
  )
}

Button.defaultProps = {
  children: '',
  color: 'primary',
  disabled: false,
  href: '',
  pending: false,
  size: 'medium',
  variant: 'inline-block',
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(['primary', 'secondary']),
  disabled: PropTypes.bool,
  href: PropTypes.string,
  pending: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['block', 'inline-block']),
}

export default memo(Button)
