import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
} from '@apollo/client'
import { DEFAULT_CHANNEL, MAX_RETRY_COUNT } from 'src/constants'
import domainInstance, { API_URL } from './business-layer-client'
import { getAccountId } from 'src/lib/cookieUtils'
import getUri from 'lib/getUri'
import httpStatusCodes from 'http-status-codes'
import { redirectToSignInPage } from './utils'
import { setContext } from '@apollo/client/link/context'

interface PrevContextProps {
  headers: Record<string, unknown>
}
const isLocal = process.env.NODE_ENV === 'development'
const defaultUri = getUri(isLocal)
const initialRetryDelay = 300 // 300ms

const authLink = setContext((_, { headers }: PrevContextProps) => {
  const token = window?.sessionStorage.getItem('accessToken')
  const account = getAccountId() || 'local'
  return {
    headers: {
      ...headers,
      ['Authorization']: token,
      ['x-site-context']: JSON.stringify({
        date: new Date().toISOString(),
        channel: DEFAULT_CHANNEL,
        account,
      }),
    },
  }
})

const initApolloClient = (uri: string): ApolloClient<NormalizedCacheObject> => {
  const abortController = new AbortController()
  // https://www.apollographql.com/docs/react/networking/authentication/
  const httpLink = createHttpLink({
    uri,
    fetch: async (requestURI: string, options: Request): Promise<Response> => {
      const response = await fetch(requestURI, options)

      //if the call results in a 401 or 403 we refetch/reset tokens and retry the call up to 3 times
      if (
        response.status === httpStatusCodes.FORBIDDEN ||
        response.status === httpStatusCodes.UNAUTHORIZED
      ) {
        //fetch and set new tokens, redirect to sign in page if token fetch fails
        try {
          const {
            data: { accessToken, refreshToken },
          }: {
            data: { accessToken: string; refreshToken: string }
          } = await domainInstance.identity.post(API_URL.AUTH_REFRESH_TOKEN(), {
            refreshToken: localStorage.getItem('refreshToken'),
          })
          localStorage.set('refreshToken', refreshToken)
          sessionStorage.set('accessToken', accessToken)
          options.headers.set('authorization', accessToken)
        } catch (e) {
          redirectToSignInPage()
        }

        //retry call with new accessToken up to 3 times
        let attempts = 0
        while (attempts < MAX_RETRY_COUNT) {
          attempts++
          //delays for retry attempts are 0-300ms, 0-600ms, and 0-1200ms
          await new Promise(r =>
            setTimeout(r, attempts * initialRetryDelay * Math.random())
          )
          const retryResponse = await fetch(requestURI, options)
          if (retryResponse.ok) {
            return Promise.resolve(retryResponse)
          }
        }
        redirectToSignInPage()
      } else {
        //if the initial call was successful, return re-wrapped original response
        return Promise.resolve(response)
      }
    },
    fetchOptions: {
      mode: 'cors',
      signal: abortController.signal,
    },
  })

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })
}

export default initApolloClient(defaultUri)
