import {
  GEListGraphQlResponse,
  GEListRequestVariables,
  GEListRestResponse,
  GEVariantArchiveGraphQLResponse,
  GEVariantArchiveRESTResponse,
  GEVariantArchiveVariables,
  GEVariantComponentSaveRESTResponse,
  GEVariantComponentUpdateVariables,
  GEVariantCreateGraphQLResponse,
  GEVariantCreateRESTResponse,
  GEVariantDuplicateGraphQLResponse,
  GEVariantDuplicateRESTResponse,
  GEVariantDuplicateVariables,
  GEVariantUnarchiveGraphQLResponse,
  GEVariantUnarchiveRESTResponse,
  GEVariantUnarchiveVariables,
  GEVariantUpdateGraphQLResponse,
  GEVariantUpdateRESTResponse,
  GEVersionComponentSaveGraphQlResponse,
  GlobalElementVariantCreateVariables,
  GlobalElementVariantUpdateVariables,
} from '../transformations/globalElements/types'
import {
  PageArchiveGraphQLResponse,
  PageArchiveVariables,
  PageCopyGraphQLResponse,
  PageCopyRESTResponse,
  PageCopyVariables,
  PageCreateGraphQLResponse,
  PageCreateRESTResponse,
  PageCreateVariables,
  PageListGraphQLResponse,
  PageListRESTResponse,
  PageListVariables,
  PageUnarchiveGraphQLResponse,
  PageUnarchiveVariables,
  PageUpdateGraphQLResponse,
  PageUpdateVariables,
} from '../transformations/page/types'
import {
  PageVersionArchiveGraphQLResponse,
  PageVersionArchiveRESTResponse,
  PageVersionArchiveVariables,
  PageVersionCreateGraphQLResponse,
  PageVersionCreateVariables,
  PageVersionUnarchiveGraphQLResponse,
  PageVersionUnarchiveRESTResponse,
  PageVersionUnarchiveVariables,
} from '../transformations/page-version/types'
import { geTransformOperations } from '../transformations/globalElements'
import { pageTransformOperations } from '../transformations/page'
import { pageVersionTransformOperations } from '../transformations/page-version'

export type ChannelGraphQLResponse = {
  id: string
  name: string
  url: string
  label: string
  channelURL: string
}

export const PROXY_HEADER = 'graphQL-transform-id'
export const PATH_PARAMS_HEADER = 'pathParams'

export type AllVariableTypes =
  | PageListVariables
  | GlobalElementVariantCreateVariables
  | PageCreateVariables
  | PageUpdateVariables
  | PageArchiveVariables
  | PageUnarchiveVariables
  | PageVersionCreateVariables
  | GlobalElementVariantUpdateVariables
  | GEVariantArchiveVariables
  | GEVariantDuplicateVariables
  | GEVariantUnarchiveVariables
  | PageVersionArchiveVariables
  | PageVersionUnarchiveVariables
  | GEListRequestVariables
  | GEVariantComponentUpdateVariables
  | PageCopyVariables

export type AllGraphQLResponseTypes =
  | PageListGraphQLResponse
  | GEVariantCreateGraphQLResponse
  | GEVariantDuplicateGraphQLResponse
  | PageUpdateGraphQLResponse
  | PageArchiveGraphQLResponse
  | PageUnarchiveGraphQLResponse
  | PageCreateGraphQLResponse
  | GEVariantUpdateGraphQLResponse
  | GEVariantArchiveGraphQLResponse
  | GEVariantUnarchiveGraphQLResponse
  | PageVersionCreateGraphQLResponse
  | PageVersionArchiveGraphQLResponse
  | PageVersionUnarchiveGraphQLResponse
  | GEListGraphQlResponse
  | GEVersionComponentSaveGraphQlResponse
  | PageCopyGraphQLResponse

export type AllPageTransformOperationsTypes =
  | PageListRESTResponse
  | PageCreateRESTResponse
  | PageUpdateGraphQLResponse
  | PageVersionArchiveRESTResponse
  | PageVersionUnarchiveRESTResponse
  | GEVariantUpdateRESTResponse
  | GEVariantArchiveRESTResponse
  | GEVariantDuplicateRESTResponse
  | GEVariantUnarchiveRESTResponse
  | PageArchiveGraphQLResponse
  | PageUnarchiveGraphQLResponse
  | GEListRestResponse
  | GEVariantCreateRESTResponse
  | GEVariantComponentSaveRESTResponse
  | PageCopyGraphQLResponse
  | PageCopyRESTResponse

export const allTransformOperations = {
  ...pageTransformOperations,
  ...pageVersionTransformOperations,
  ...geTransformOperations,
}
