import API from 'src/business-layer-client'
import handleResponse from 'lib/handleResponse'

class PageType {
  async list(offset, limit, channels = '') {
    try {
      const response = await API.xpm.get(
        `page-type?offset=${offset}&limit=${limit}&channels=${channels}`
      )
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }

  async create(payload) {
    try {
      const response = await API.xpm.post(`/page-type`, payload)
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }

  async search(searchTerm) {
    try {
      const response = await API.xpm.get(
        `page-type/search?searchTerm=${encodeURI(searchTerm)}`
      )
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }

  async update(id, payload) {
    try {
      const response = await API.xpm.patch(`/page-type/${id}`, payload)
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }

  async delete(id) {
    try {
      const response = await API.xpm.delete(`/page-type/${id}`)
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }
}

export default new PageType()
