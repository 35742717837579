/* The default configuration, which includes the toolbar configuration, is
currently loaded from: https://cdn.ckeditor.com/4.6.2/standard/config.js. We
override the 'removeButtons' property to exclude 'Underline' from the value.
This allows the underline button to appear in the toolbar of the editor, as part
of the 'basicstyles' toolbar group. */
const ckEditorConfig = ({ textDirection = 'ltr', isReadOnly = false }) => ({
  removeButtons: 'Subscript,Superscript',
  removePlugins: 'about',
  extraPlugins: 'justify',
  readOnly: isReadOnly,
  extraAllowedContent: [
    'a(*){*}[style,id,name,role,target,rel,!href]',
    'b[role]',
    'big[role]',
    'br[role]',
    'blockquote(*){*}[style,id,name,role]',
    'center[role]',
    'cite[role]',
    'code(*){*}[style,id,name,role]',
    'col[role]',
    'colgroup[role]',
    'del[role]',
    'div(*){*}[style,id,name,role]',
    'em[role]',
    'embed(*){*}[style,id,name,role]',
    'h1(*){*}[style,id,name,role]',
    'h2(*){*}[style,id,name,role]',
    'h3(*){*}[style,id,name,role]',
    'h4(*){*}[style,id,name,role]',
    'h5(*){*}[style,id,name,role]',
    'h6(*){*}[style,id,name,role]',
    'hr[role]',
    'i[role]',
    'ins[role]',
    'iframe(*){*}[!src,style,id,name,role,src]',
    'img(*){*}[!src,alt,width,height,role]',
    'kbd[role]',
    'label(*){*}[style,id,name,role]',
    'li[role]',
    'ol(*){*}[style,id,name,role]',
    'option[role]',
    'optgroup[role]',
    'p(*){*}[style,id,name,role]',
    'pre(*){*}[style,id,name,role]',
    'q[role]',
    's[role]',
    'samp[role]',
    'section(*){*}[style,id,name,role]',
    'small[role]',
    'span(*){*}[style,id,name,role,dir]',
    'strong[role]',
    'style[role]',
    'sup[role]',
    'sub[role]',
    'table(*){*}[style,id,name,role]',
    'tbody(*){*}[style,id,name,role]',
    'td(*){*}[style,id,name,role]',
    'th(*){*}[style,id,name,role]',
    'tfoot(*){*}[style,id,name,role]',
    'thead(*){*}[style,id,name,role]',
    'title(*){*}[style,id,name,role]',
    'tr(*){*}[style,id,name,role]',
    'tt[role]',
    'u[role]',
    'ul(*){*}[style,id,name,role]',
    'var[role]',
    'video(*){*}[style,id,name,role]',
  ],
  contentsLangDirection: textDirection,
  on: {
    pluginsLoaded: event => {
      event.editor.dataProcessor.dataFilter.addRules({
        comment: () => false,
      })
    },
  },
})

export default ckEditorConfig
