import {
  LazyQueryResult,
  QueryLazyOptions,
} from '@apollo/client/react/types/types'
import { MenuNodesAsListResponse } from 'modules/browse-menu-tree-gql/types'
import { OperationVariables } from '@apollo/client'

export const menuNodesAsList = async ({
  menuNodesAsListQuery,
  parentId,
  variantId,
  nodeId,
}: {
  menuNodesAsListQuery: (
    options?: QueryLazyOptions<OperationVariables>
  ) => Promise<LazyQueryResult<MenuNodesAsListResponse, OperationVariables>>
  parentId: string
  variantId: string
  nodeId: string
}): Promise<MenuNodesAsListResponse> => {
  const { data } = await menuNodesAsListQuery({
    variables: {
      input: {
        parentId,
        variantId,
        nodeId,
      },
    },
  })

  return data
}
