import { ACTIONS } from './actions'
import { createReducer } from 'redux-nano'

export const initialState = {
  visible: false,
  message: '',
  kind: 'message',
  duration: null,
}

export default createReducer(initialState, {
  [ACTIONS.SHOW_TOAST_MESSAGE]: (_, action) => {
    return {
      visible: true,
      message: action.payload.message,
      kind: action.payload.kind,
      duration: action.payload.duration,
    }
  },
  [ACTIONS.DISMISS_TOAST_MESSAGE]: () => initialState,
  [ACTIONS.FADE_OUT_TOAST_MESSAGE]: () => initialState,
})
