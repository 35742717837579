import styled from 'styled-components'

export const StyledTabsWrapper = styled.div`
  display: inline-block;
  position: relative;
  top: -4px;

  [class*='Tabstyles__StyledTabNavigation'] {
    [class*='Tabstyles__StyledTabItem'] {
      margin-right: 11px;
      padding: 0;
    }
  }
`
