import { NODE_WIDTH } from 'modules/browse-menu-tree-gql/constants'
import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

interface StyledNodeContainerProps {
  isDragging: boolean
  pimTail: boolean
}
export const StyledNodeContainer = styled.div<StyledNodeContainerProps>`
  padding: ${({ theme }) => theme.space.space_2} 0;
  &.dragging-section-child {
    visibility: hidden;

    .add-subnode {
      visibility: hidden !important; // Flag required to override inline-style
    }
  }

  .tree-node {
    margin-bottom: ${({ theme, pimTail }) =>
      pimTail ? theme.space.space_6 : 0};
    border: 2px solid transparent;
    user-select: none;

    &:hover {
      cursor: pointer;
    }
  }

  &.is-drag-disabled {
    > div {
      opacity: 0.65 !important;
    }
  }

  &.is-open {
    input {
      color: ${themeUi.palette.brand.primary.gray};

      &:focus {
        color: ${themeUi.palette.brand.primary.charcoal};
      }
    }
  }

  &.is-dragging {
    .tree-node {
      border: 2px solid ${themeUi.palette.ui.cta.blue};
    }

    button {
      &.add-subnode {
        visibility: hidden;
      }
    }
  }

  &[data-active='true'] {
    .tree-node {
      border: 2px solid ${themeUi.palette.ui.cta.blue};
    }
  }

  .tooltip-drop-warning {
    background: ${themeUi.palette.brand.primary.charcoal};
    color: ${themeUi.palette.brand.primary.white};
    display: none;
    font-size: ${themeUi.typography.caption};
    padding: 3px 9px;
    position: absolute;
    left: 50%;
    line-height: ${({ theme }) => theme.typography.lineHeight.tight};
    top: 65px;
    width: 230px;
  }

  &.overlap {
    position: relative;

    .tooltip-drop-warning {
      display: block;
    }
  }

  .button-loader {
    width: ${NODE_WIDTH};
    height: 50.4px;
    border-radius: 10px;
    margin-bottom: ${({ theme, pimTail }) =>
      pimTail ? theme.space.space_6 : 0};
    border: 2px solid transparent;
    user-select: none;
  }
`

export const StyledSpacer = styled.button`
  height: ${({ theme }) => theme.space.space_5};
  pointer-events: none;
  visibility: hidden;
`

interface StyledWrapperProps {
  showNode: boolean
  isSectionChild: boolean
  disabled: boolean
}
export const StyledWrapper = styled.div<StyledWrapperProps>`
  transition: all 0.5s ease-out, top 0s 0.5s;
  top: ${props => (props.showNode ? '0' : '-9999px')};
  position: ${props => (props.showNode ? 'static' : 'relative')};
  max-height: ${props => (props.showNode ? `${NODE_WIDTH}` : '0px')};
  opacity: ${props => (props.showNode ? '1' : '0')};
  overflow: ${props => (props.showNode ? 'visible' : 'hidden')};
  box-sizing: border-box;
  div[data-testid='tree-card'] {
    opacity: ${props => (props.disabled ? '0.6' : '1')};
  }
  &.hide-placeholder {
    height: 0;
    pointer-events: none;
    visibility: hidden;
  }
  .tree-node {
    padding-left: ${props => (props.isSectionChild ? '38px' : 'auto')};
  }
  .modal-dialog {
    border-radius: 10px;
    h3 {
      color: ${themeUi.palette.brand.primary.charcoal};
    }
    .confirm-dialog-content {
      color: ${themeUi.palette.brand.primary.charcoal};
    }
    .confirm-dialog {
      button {
        border-radius: 20px;
      }
    }
  }
`
