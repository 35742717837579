import { CHANNELS, MESSAGES, TOAST_MESSAGE_TYPES } from 'src/constants'
import { Channel, ChannelsAction } from './types'
import channelService from 'services/api/channel'
import { showToast } from 'components/ToastSnackbarContainer'

export const INIT_CHANNELS_SUCCESS = 'INIT_CHANNELS_SUCCESS'

export const INIT_CHANNELS = () => async (
  dispatch: (arg: { type: string; channels: Channel[] }) => void
): Promise<void> => {
  try {
    const data = (await channelService.list({})) as {
      channels: Channel[]
      count: number
    }
    dispatch(initChannelsSuccess(data))
  } catch (err) {
    console.error(err)
    void showToast({
      message: MESSAGES.getFetchingError(CHANNELS),
      kind: TOAST_MESSAGE_TYPES.ALERT,
    })
  }
}

const initChannelsSuccess = (payload: {
  channels: Channel[]
  count: number
}): ChannelsAction => {
  return {
    type: INIT_CHANNELS_SUCCESS,
    channels: payload.channels,
  }
}
