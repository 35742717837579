/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup'
import {
  BROWSE_MENU_MODAL_LABEL,
  BROWSE_MENU_VERSION_MODAL_LABEL,
  CANCEL_TEXT,
  CREATE_NEW_VERSION,
  DELETE_MENU_MODAL,
  DELETE_MENU_VERSION,
  EDIT_MENU_VERSION,
  EMPTY_STRING,
  FORM_CONSTANTS,
  LOCALES,
  MENU_COPY_MODAL_LABEL,
  MODAL_ACTION,
  MODAL_SECTIONS,
  NOT_SCHEDULED,
  PAGE_MODAL_LABEL,
  QUICK_LINK,
  STATUS,
  TABS_PAGES,
  VALIDATION_ERRORS,
} from 'src/constants'
import {
  CHANNELS_FILTER_LABEL,
  LOCALES_FILTER_LABEL,
  PAGE_TYPE_FILTER_LABEL,
  STATUS_FILTER_LABEL,
  defaultStatus,
  filterName,
} from 'components/Filters/constants'
import {
  ContentModalOptionsProps,
  ModalType,
} from '../components/ContentModalModule/types'
import { Filters, PageTypes } from 'components/Filters/types'
import {
  PageEdge,
  PageVariantGraphQLResponse,
} from 'src/graphql-proxy/transformations/page/types'
import { PageType, SearchDropdown } from './pages-list/types'
import getMultiSelectTextChipsOptions, {
  OptionsType,
} from 'lib/getMultiSelectTextChipsOptions'
import { Channel } from 'store/channels/types'
import { ContentFilterOptionProps } from 'components/ContentFilterModule/types'
import { ContextOption } from './types'
import { I18nLocale } from 'store/i18n/types'
import { LocalizedSeoFields } from 'components/FormDialog/common/content/ContentDetailsDialog/types'
import { ModalDetailProps } from './browse-menu-gql/types'
import { MultiSelectOptions } from 'components/FilterBy/types'
import { MultiSelectTextChipsOptions } from 'lib/getMultiSelectTextChipsOptions/types'
import { OperationVariables } from '@apollo/client'
import { UserPermissions } from 'src/contexts/userPermissions'
import { VERSION_STATUS } from 'modules/editor/constants'
import { Variant } from 'services/graphql/types'
import getHasEditAccessBasedOnVersionStatus from 'lib/getHasEditAccessBasedOnVersionStatus'
import { getMultiSelectOptions } from 'components/Filters/utils'
import some from 'lodash/some'

/** Determine value of SCHEDULED cell in parent row */
export const getContentScheduledAt = (
  versions: { status: string; startDate: string }[]
): string => {
  const versionsSortedByStartDate = versions
    .filter(p => p.status === STATUS.SCHEDULED)
    .map(p => p.startDate)
    .sort((a: string, b: string) => (new Date(a) as any) - (new Date(b) as any))

  if (versionsSortedByStartDate.length) {
    // Return first start date
    return versionsSortedByStartDate[0]
  }

  return NOT_SCHEDULED
}

// Browse Menu ContentModalModule Configurations
export enum BROWSE_MENU_MODAL_ID {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  COPY = 'COPY',
  DELETE_VERSION = 'DELETE_VERSION',
  NEW_VERSION = 'NEW_VERSION',
  EDIT_VERSION = 'EDIT_VERSION',
  COPY_VERSION = 'COPY_VERSION',
  EDIT_DETAILS = 'EDIT_DETAILS',
  VIEW_DETAILS = 'VIEW_DETAILS',
}

const populateBrowseMenuModalInitialValue = (
  targetItemName: string,
  targetItemLocales: I18nLocale[],
  targetItemChannels: Channel[]
) => {
  return {
    name: targetItemName,
    locales:
      getMultiSelectTextChipsOptions(targetItemLocales, OptionsType.LOCALES) ??
      [],
    channels:
      getMultiSelectTextChipsOptions(
        targetItemChannels,
        OptionsType.CHANNELS
      ) ?? [],
  }
}

export const getBrowseMenuModalOptions = (
  {
    closeModal,
    handlers: {
      handleDelete,
      handleCopy,
      handleDeleteVersion,
      handleCreateMenuVariant,
      handleUpdateMenuVariant,
      handleUpdateMenuDetails,
      handleCreateMenu,
      handleCopyMenuVersion,
    },
    modalDetails,
  }: {
    closeModal: () => void
    handlers: {
      handleDelete: () => void
      handleCopy: (values: OperationVariables) => void
      handleDeleteVersion: () => void
      handleCreateMenuVariant: (values: OperationVariables) => void
      handleUpdateMenuVariant: (values: OperationVariables) => void
      handleUpdateMenuDetails: (values: OperationVariables) => void
      handleCreateMenu: (values: OperationVariables) => void
      handleCopyMenuVersion: (values: OperationVariables) => void
    }
    modalDetails: ModalDetailProps
  },
  i18n: boolean,
  locales: I18nLocale[],
  channels: Channel[]
): {
  [key: string]: ContentModalOptionsProps
} => {
  const multiChannel = some(channels)
  const channelsOptionList = multiChannel
    ? getMultiSelectTextChipsOptions(channels, OptionsType.CHANNELS)
    : null
  const localesOptionList = i18n
    ? getMultiSelectTextChipsOptions(locales, OptionsType.LOCALES)
    : null

  const menuVariantsNames = modalDetails.menu?.variants
    ?.map(variant => variant.versionName)
    .join(', ')

  return {
    [BROWSE_MENU_MODAL_ID.CREATE]: {
      title: BROWSE_MENU_MODAL_LABEL.ADD_MENU,
      size: 'medium',
      modalType: ModalType.Form,
      mode: MODAL_ACTION.CREATE,
      onSubmit: handleCreateMenu,
      onClose: closeModal,
      forms: [
        {
          name: MODAL_SECTIONS.GENERAL_DETAILS,
          initialValues: {
            name: '',
            versionName: '',
            channels: [],
            locales: localesOptionList ?? [],
          },
          getComponents: values => [
            ...getLocalesAndChannelsFields(values, {
              i18n,
              multiChannel,
              localesDropdownOptions: localesOptionList,
              channelsDropdownOptions: channelsOptionList,
            }),
            {
              type: 'Input',
              label: BROWSE_MENU_VERSION_MODAL_LABEL.MENU_PARENT_NAME,
              name: 'name',
              disabled: false,
              value: values.name,
              dataTestId: 'menu-name-field',
            },
            {
              type: 'Input',
              label: BROWSE_MENU_VERSION_MODAL_LABEL.MENU_VERSION_NAME,
              name: 'versionName',
              disabled: false,
              value: values.versionName,
              dataTestId: 'menu-version-name-field',
            },
          ],
          validationSchema: Yup.object().shape({
            name: Yup.string().required(VALIDATION_ERRORS.EMPTY_NAME),
            versionName: Yup.string().required(
              VALIDATION_ERRORS.EMPTY_VERSION_NAME
            ),
            ...(i18n && {
              locales: Yup.array().min(
                1,
                VALIDATION_ERRORS.MIN_LOCALE_REQUIRED
              ),
            }),
          }),
        },
      ],
    },
    [BROWSE_MENU_MODAL_ID.COPY]: {
      title: BROWSE_MENU_MODAL_LABEL.COPY_MENU,
      size: 'medium',
      modalType: ModalType.Form,
      mode: MODAL_ACTION.COPY,
      onSubmit: handleCopy,
      onClose: closeModal,
      forms: [
        {
          name: MODAL_SECTIONS.GENERAL_DETAILS,
          initialValues: {
            name: modalDetails.targetItemName,
            copiedName: `${modalDetails.targetItemName} copy`,
            channels: modalDetails.targetItemChannels ?? [],
            menuVersions: menuVariantsNames,
            locales:
              localesOptionList?.filter(o =>
                modalDetails.targetItemLocales?.includes(o?.id)
              ) ?? [],
          },
          getComponents: values => [
            ...getLocalesAndChannelsFields(values, {
              i18n,
              multiChannel,
              localesDropdownOptions: localesOptionList,
              channelsDropdownOptions: channelsOptionList,
            }),
            {
              type: 'Input',
              label: BROWSE_MENU_VERSION_MODAL_LABEL.MENU_PARENT_NAME,
              name: 'name',
              disabled: true,
              value: values.name,
              dataTestId: 'menu-name-field',
            },
            {
              type: 'Input',
              label: MENU_COPY_MODAL_LABEL.MENU_VERSIONS,
              name: 'menuVersions',
              value: values.menuVersions,
              dataTestId: 'input-page-versions',
              disabled: true,
            },
            {
              type: 'Input',
              label: BROWSE_MENU_VERSION_MODAL_LABEL.NEW_MENU_PARENT_NAME,
              name: 'copiedName',
              disabled: false,
              value: values.copiedName,
              dataTestId: 'menu-copied-name-field',
            },
          ],
          validationSchema: Yup.object().shape({
            copiedName: Yup.string().required(VALIDATION_ERRORS.EMPTY_NAME),
            ...(i18n && {
              locales: Yup.array().min(
                1,
                VALIDATION_ERRORS.MIN_LOCALE_REQUIRED
              ),
            }),
          }),
        },
      ],
    },
    [BROWSE_MENU_MODAL_ID.DELETE]: {
      title: DELETE_MENU_MODAL.title,
      size: 'small',
      modalType: ModalType.Alert,
      config: {
        content: DELETE_MENU_MODAL.content,
        cancelButton: {
          text: CANCEL_TEXT,
          onClick: closeModal,
        },
        confirmButton: {
          text: DELETE_MENU_MODAL.submitText,
          onClick: () => handleDelete(),
        },
      },
    },
    [BROWSE_MENU_MODAL_ID.DELETE_VERSION]: {
      title: DELETE_MENU_VERSION.title,
      size: 'small',
      modalType: ModalType.Alert,
      config: {
        content: DELETE_MENU_VERSION.content,
        cancelButton: {
          text: CANCEL_TEXT,
          onClick: closeModal,
        },
        confirmButton: {
          text: DELETE_MENU_VERSION.submitText,
          onClick: () => handleDeleteVersion(),
        },
      },
    },
    [BROWSE_MENU_MODAL_ID.NEW_VERSION]: {
      modalType: ModalType.Form,
      size: 'medium',
      title: CREATE_NEW_VERSION.title,
      mode: MODAL_ACTION.EDIT,
      onSubmit: handleCreateMenuVariant,
      onClose: closeModal,
      forms: [
        {
          name: '',
          initialValues: {
            name: '',
            description: '',
          },
          getComponents: values => [
            {
              type: 'Input',
              label: FORM_CONSTANTS.VERSION_DETAILS_DIALOG.versionName,
              name: 'name',
              disabled: false,
              value: values.name,
              dataTestId: 'input-menu-name',
            },
            {
              type: 'Input',
              label: FORM_CONSTANTS.VERSION_DETAILS_DIALOG.description,
              name: 'description',
              disabled: false,
              value: values.description,
              dataTestId: 'input-menu-description',
            },
          ],
        },
      ],
    },
    [BROWSE_MENU_MODAL_ID.EDIT_VERSION]: {
      modalType: ModalType.Form,
      size: 'medium',
      title: EDIT_MENU_VERSION.title,
      mode: MODAL_ACTION.EDIT,
      onSubmit: handleUpdateMenuVariant,
      onClose: closeModal,
      forms: [
        {
          name: MODAL_SECTIONS.GENERAL_DETAILS,
          initialValues: {
            menuName: '',
            menuDescription: '',
          },
          getComponents: values => [
            {
              type: 'Input',
              label: FORM_CONSTANTS.VERSION_DETAILS_DIALOG.versionName,
              name: 'menuName',
              disabled: false,
              value: values.menuName,
              dataTestId: 'input-menu-name-edit',
            },
            {
              type: 'Input',
              label: FORM_CONSTANTS.VERSION_DETAILS_DIALOG.description,
              name: 'menuDescription',
              disabled: false,
              value: values.menuDescription,
              dataTestId: 'input-menu-description-edit',
            },
          ],
        },
      ],
    },
    [BROWSE_MENU_MODAL_ID.EDIT_DETAILS]: {
      modalType: ModalType.Form,
      size: 'medium',
      title: BROWSE_MENU_MODAL_LABEL.EDIT_MENU,
      mode: MODAL_ACTION.EDIT,
      onSubmit: handleUpdateMenuDetails,
      onClose: closeModal,
      forms: [
        {
          name: MODAL_SECTIONS.GENERAL_DETAILS,
          initialValues: populateBrowseMenuModalInitialValue(
            modalDetails.targetItemName,
            modalDetails.targetItemLocales,
            modalDetails.targetItemChannels
          ),
          getComponents: values => [
            ...getLocalesAndChannelsFields(values, {
              i18n,
              multiChannel,
              localesDropdownOptions: localesOptionList,
              channelsDropdownOptions: channelsOptionList,
            }),
            {
              type: 'Input',
              label: BROWSE_MENU_MODAL_LABEL.MENU_NAME,
              name: 'name',
              disabled: false,
              value: values.name,
              dataTestId: 'input-menu-name-edit-details',
            },
          ],
          validationSchema: Yup.object().shape({
            name: Yup.string().required(VALIDATION_ERRORS.EMPTY_NAME),
            ...(i18n && {
              locales: Yup.array().min(
                1,
                VALIDATION_ERRORS.MIN_LOCALE_REQUIRED
              ),
            }),
          }),
        },
      ],
    },
    [BROWSE_MENU_MODAL_ID.VIEW_DETAILS]: {
      modalType: ModalType.Form,
      size: 'medium',
      title: BROWSE_MENU_MODAL_LABEL.VIEW_MENU,
      mode: MODAL_ACTION.VIEW,
      onClose: closeModal,
      forms: [
        {
          name: MODAL_SECTIONS.GENERAL_DETAILS,
          initialValues: populateBrowseMenuModalInitialValue(
            modalDetails.targetItemName,
            modalDetails.targetItemLocales,
            modalDetails.targetItemChannels
          ),
          getComponents: values => [
            ...getLocalesAndChannelsFields(values, {
              i18n,
              multiChannel,
              localesDropdownOptions: localesOptionList,
              channelsDropdownOptions: channelsOptionList,
            }),
            {
              type: 'Input',
              label: BROWSE_MENU_MODAL_LABEL.MENU_NAME,
              name: 'name',
              disabled: true,
              value: values.name,
              dataTestId: 'input-menu-name-edit-details',
            },
          ],
        },
      ],
    },
    [BROWSE_MENU_MODAL_ID.COPY_VERSION]: {
      modalType: ModalType.Form,
      size: 'medium',
      title: BROWSE_MENU_MODAL_LABEL.COPY_MENU,
      mode: MODAL_ACTION.COPY,
      onSubmit: handleCopyMenuVersion,
      onClose: closeModal,
      forms: [
        {
          name: MODAL_SECTIONS.GENERAL_DETAILS,
          initialValues: {
            name: `${modalDetails.targetItemName} copy`,
            description: '',
          },
          getComponents: values => [
            {
              type: 'Input',
              label: FORM_CONSTANTS.VERSION_DETAILS_DIALOG.versionName,
              value: values.name,
              name: 'name',
              dataTestId: 'input-menu-name',
              disabled: false,
            },
            {
              type: 'Input',
              label: FORM_CONSTANTS.VERSION_DETAILS_DIALOG.description,
              value: values.description,
              name: 'description',
              dataTestId: 'input-menu-description',
              disabled: false,
            },
          ],
          validationSchema: Yup.object().shape({
            name: Yup.string().required(VALIDATION_ERRORS.EMPTY_VERSION_NAME),
          }),
        },
      ],
    },
  }
}

export const getLocalizedSeoFields = (
  localeStringList: string[],
  localizedSeoFields: LocalizedSeoFields
): LocalizedSeoFields => {
  const newLocalizedSeoFields: LocalizedSeoFields = {}
  localeStringList.forEach(localeStr => {
    newLocalizedSeoFields[localeStr] = localizedSeoFields[localeStr]
  })
  return newLocalizedSeoFields
}

export const multiTextChipsOptionsToDTO = (
  options: MultiSelectTextChipsOptions[] = []
): string[] => {
  return options?.map(option => option?.id)
}

export const getPageFilterEntries = ({
  locales,
  channels,
  pageTypes,
  filters,
  onFilterChange,
}: {
  locales: I18nLocale[]
  channels: Channel[]
  pageTypes?: PageTypes[]
  filters: Filters
  onFilterChange: (selectedFilter: string[], filterName: string) => void
}): ContentFilterOptionProps[] => {
  const hasLocales = some(locales)
  const multiChannel = some(channels)

  return [
    ...(hasLocales
      ? [
          {
            id: 'locale-filter',
            label: LOCALES_FILTER_LABEL,
            multiSelectOptions: getMultiSelectOptions(
              locales,
              filters,
              filterName.locales
            ),
            onChange: selectedLocales =>
              onFilterChange(
                selectedLocales.map(s => s.id) as string[],
                filterName.locales
              ),
          },
        ]
      : []),
    ...(multiChannel
      ? [
          {
            id: 'channel-filter',
            label: CHANNELS_FILTER_LABEL,
            multiSelectOptions: getMultiSelectOptions(
              channels,
              filters,
              filterName.channels
            ),
            onChange: selectedChannels =>
              onFilterChange(
                selectedChannels.map(c => c.id) as string[],
                filterName.channels
              ),
          },
        ]
      : []),
    {
      id: 'status-filter',
      label: STATUS_FILTER_LABEL,
      multiSelectOptions: getMultiSelectOptions(
        defaultStatus,
        filters,
        filterName.status
      ),
      onChange: selectedStatus =>
        onFilterChange(
          selectedStatus.map(s => s.name),
          filterName.status
        ),
    },
    ...(pageTypes
      ? [
          {
            id: 'page-type-filter',
            label: PAGE_TYPE_FILTER_LABEL,
            multiSelectOptions: getMultiSelectOptions(
              pageTypes,
              filters,
              filterName.pageTypes
            ),
            onChange: (selectedPageTypes: MultiSelectOptions[]) =>
              onFilterChange(
                selectedPageTypes.map(pt => pt.id),
                filterName.pageTypes
              ),
          },
        ]
      : []),
  ]
}

const getLocalesAndChannelsFields = (
  values: Record<string, string>,
  {
    i18n,
    multiChannel,
    localesDropdownOptions,
    channelsDropdownOptions,
  }: {
    i18n: boolean
    multiChannel: boolean
    localesDropdownOptions: MultiSelectTextChipsOptions[]
    channelsDropdownOptions: MultiSelectTextChipsOptions[]
  }
) => [
  i18n && {
    type: 'MultiSelectTextChips',
    label: LOCALES.localesMultiSelectLabel,
    name: 'locales',
    dataTestId: 'menu-locales-field',
    value: values.locales,
    dropdownOptions: localesDropdownOptions,
    disabled: false,
  },
  multiChannel && {
    type: 'MultiSelectTextChips',
    label: CHANNELS_FILTER_LABEL,
    name: 'channels',
    dataTestId: 'menu-channels-field',
    value: values.channels,
    dropdownOptions: channelsDropdownOptions,
    disabled: false,
  },
]

export const tranformToSearchResults = (
  pageTypes: PageType[],
  pagesEdges: PageEdge[],
  pageEdgesArchived: PageEdge[]
): Array<SearchDropdown> => {
  const pageTypesResult = pageTypes.map(item => ({
    name: item.name,
    tags: PAGE_MODAL_LABEL.PAGE_TYPE,
    _id: item._id,
  }))
  const pagesResult = pagesEdges.concat(pageEdgesArchived).map(item => ({
    name: item.node.name,
    tags: item.node.isArchived ? TABS_PAGES.ARCHIVED : EMPTY_STRING,
    _id: item.node.id,
  }))

  return pagesResult.concat(pageTypesResult)
}

export const populateVariantQuickLinkTitle = (
  userPermissions: UserPermissions,
  variant: { status: string; isArchived?: boolean },
  {
    content,
  }: {
    content: QUICK_LINK.VIEW_PAGE | QUICK_LINK.VIEW_GE | QUICK_LINK.VIEW_MENU
  }
): string => {
  if (variant?.isArchived) {
    return EMPTY_STRING
  }

  const status = variant?.status

  const hasEditAccess = getHasEditAccessBasedOnVersionStatus(
    userPermissions,
    status
  )

  if (hasEditAccess) {
    return getEditorQuickLinkTitle(status, content)
  }

  return getViewerQuickLinkTitle(status, content)
}

const getViewerQuickLinkTitle = (status: string, content: string) => {
  return status === STATUS.SCHEDULED || status === STATUS.LIVE
    ? content
    : QUICK_LINK.VIEW_DRAFT
}

const getEditorQuickLinkTitle = (status: string, content: string) => {
  if (status === STATUS.SCHEDULED) {
    return QUICK_LINK.RESCHEDULE
  }
  if (status === STATUS.LIVE) {
    return content
  }

  return QUICK_LINK.EDIT_DRAFT
}

export const addToArrayIfTrue = <T>(expression: boolean, object: T): T[] => {
  return expression ? [object] : []
}

export const getIsScheduledOrLive = (status: string): boolean => {
  return status === VERSION_STATUS.SCHEDULED || status === VERSION_STATUS.LIVE
}

export const getContentVariantEditorContextOptions = (
  version: {
    status?: string
    isArchived?: boolean
  },
  {
    unarchiveOption,
    deleteOption,
    makeACopyOption,
    previewOption,
    archiveOption,
  }: {
    unarchiveOption?: ContextOption
    deleteOption: ContextOption
    makeACopyOption: ContextOption
    previewOption: ContextOption
    archiveOption?: ContextOption
  }
): ContextOption[] => {
  const versionStatus = version?.status
  const isScheduledOrLive = getIsScheduledOrLive(versionStatus)

  return version.isArchived
    ? [unarchiveOption, deleteOption]
    : [
        makeACopyOption,
        previewOption,
        ...addToArrayIfTrue(!isScheduledOrLive, archiveOption),
        ...addToArrayIfTrue(!isScheduledOrLive, deleteOption),
      ]
}

export const hasLiveOrScheduledVersion = (
  variants: PageVariantGraphQLResponse[] | Variant[]
): boolean => {
  return variants.some(
    variant =>
      variant.status === STATUS.LIVE || variant.status === STATUS.SCHEDULED
  )
}
