import {
  CHANGE_ACTIVE_LANGUAGE,
  FETCH_I18N_LOCALES,
  RESET_ACTIVE_LANGUAGE,
} from 'store/i18n/actions'
import {
  ConfigurationProps,
  CuratedComponentProps,
  DescriptorProps,
  EditorMetadataProps,
  EditorStateProps,
  SelectedRouteProps,
} from 'store/types'
import React, { useCallback, useEffect, useState } from 'react'
import { eventTypes, sendPreviewEventThrottled } from 'lib/sendPreviewEvent'

import {
  getCuratedComponents,
  getDescriptors,
  getEditorMetaData,
  getSelectedRoute,
} from './selectors'
import {
  handleMessageEvents,
  isEditorType,
  prepAndSendPreviewEvent,
  retrieveSelectedRouteFirstChannel,
} from './utils'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { EditorContentProps } from './types'
import { I18nReduxStore } from 'src/store/i18n/types'
import PreviewFrame from 'components/PreviewFrame'
import { StyledPreview } from './styles'
import extract from 'lib/extract'
import { getActiveLanguage } from 'store/i18n/selectors'
import { getChannelUrl } from 'modules/editor/selectors'
import { getConfiguration } from 'store/configuration/selectors'
import { getIframeSource } from 'src/components/PreviewFrame/utils'
import isEmpty from 'lodash/isEmpty'
import { useLocation } from '@reach/router'

const EditorContent: React.FunctionComponent<EditorContentProps> = props => {
  const dispatch = useDispatch()
  const stableDispatch = useCallback(dispatch, [dispatch])
  const { type, multiChannel, i18n, locales } = props
  const [iframeSource, setIFrameSource] = useState<string>('about:blank')
  const selectedRoute = useSelector<EditorStateProps, SelectedRouteProps>(
    getSelectedRoute
  )
  const channelPreviewUrl = useSelector<EditorStateProps, string>(getChannelUrl)
  const editorMetaData = useSelector<EditorStateProps, EditorMetadataProps>(
    getEditorMetaData
  )
  const curatedComponents = useSelector<
    EditorStateProps,
    CuratedComponentProps[]
  >(getCuratedComponents)
  const configuration = useSelector<EditorStateProps, ConfigurationProps>(
    getConfiguration
  )
  const descriptors = useSelector<EditorStateProps, DescriptorProps[]>(
    getDescriptors
  )

  const activeLang = useSelector<{ i18n: I18nReduxStore }, string>(
    getActiveLanguage
  )

  const { search } = useLocation()
  const { pageId = '', versionId, gcId = '' } = extract.queryParams(search)

  const wrappedHandleMessageEvents = useCallback(
    (e: MessageEvent) => {
      handleMessageEvents({
        event: e,
        featuresList: {
          locales,
          multiChannel,
        },
        descriptors,
        iframeSource,
        queryParams: {
          pageId,
          versionId,
          gcId,
        },
        selectedRoute,
      })
    },
    [
      locales,
      multiChannel,
      descriptors,
      selectedRoute,
      iframeSource,
      pageId,
      versionId,
      gcId,
    ]
  )

  useEffect(() => {
    prepAndSendPreviewEvent({
      featuresList: {
        locales,
        multiChannel,
        activeLang,
      },
      queryParams: {
        pageId,
        gcId,
        versionId,
      },
      selectedRoute,
    })
  }, [
    activeLang,
    descriptors,
    gcId,
    locales,
    multiChannel,
    pageId,
    selectedRoute,
    versionId,
  ])

  useEffect(() => {
    if (i18n && !locales) {
      stableDispatch(FETCH_I18N_LOCALES())
    }

    if (i18n && !isEmpty(locales) && !activeLang) {
      const userDefaultLocale =
        locales?.find(localeElement => localeElement.isDefault)?.code ??
        locales[0].code

      stableDispatch(
        (CHANGE_ACTIVE_LANGUAGE(userDefaultLocale) as unknown) as AnyAction
      )
    }

    return () => {
      stableDispatch(RESET_ACTIVE_LANGUAGE())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stableDispatch, i18n])

  useEffect(() => {
    window.addEventListener('message', wrappedHandleMessageEvents)
    return () => {
      window.removeEventListener('message', wrappedHandleMessageEvents)
    }
  }, [wrappedHandleMessageEvents])

  useEffect(() => {
    if (!isEmpty(selectedRoute)) {
      const firstChannel = retrieveSelectedRouteFirstChannel(selectedRoute)
      setIFrameSource(
        getIframeSource({
          baseURL:
            channelPreviewUrl ??
            firstChannel?.url ??
            firstChannel?.channelURL ??
            configuration.ecommerceAppUrl,
          type,
        })
      )
    }
  }, [channelPreviewUrl, configuration, type, selectedRoute])

  return (
    <StyledPreview>
      <PreviewFrame
        type={type}
        iframeSource={iframeSource}
        configuration={configuration}
        iframe={editorMetaData.iframe}
        handleIframeOnLoad={() => {
          const [curatedComponent] = curatedComponents
          const payload:
            | CuratedComponentProps
            | CuratedComponentProps[] = isEditorType(type)
            ? curatedComponents
            : curatedComponent

          sendPreviewEventThrottled({
            type: isEditorType(type)
              ? eventTypes.XPM_COMPONENT
              : eventTypes.XPM_GLOBAL_COMPONENT,
            payload,
          })
        }}
      />
    </StyledPreview>
  )
}

export default EditorContent
