import React, { PureComponent } from 'react'
import { StyledMenu, StyledMenuContent, StyledMenuIcon } from './styles'
import ClickAwayListener from './ClickAwayListener'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

class Menu extends PureComponent {
  state = {
    expand: false,
  }

  static Icon = props => {
    const { children, toggleExpand, ...rest } = props

    return (
      <StyledMenuIcon {...rest} onClick={toggleExpand} data-testid='menu-icon'>
        {children}
      </StyledMenuIcon>
    )
  }

  static Content = props => {
    const { expand, children, align = 'left', ...rest } = props

    if (!expand) return null

    return (
      <StyledMenuContent {...rest} align={align} data-testid='menu-content'>
        {children}
      </StyledMenuContent>
    )
  }

  toggleExpand = e => {
    e && e.stopPropagation()

    this.setState(({ expand }) => ({ expand: !expand }))
  }

  hideExpand = () => {
    this.setState({ expand: false })
  }

  render() {
    return (
      <ClickAwayListener
        onClickAway={this.props.backdrop ? this.hideExpand : noop}
      >
        <StyledMenu {...this.props} data-testid='menu'>
          {React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
              expand: this.state.expand,
              toggleExpand: this.toggleExpand,
              align: this.props.align,
            })
          })}
        </StyledMenu>
      </ClickAwayListener>
    )
  }
}

Menu.defaultProps = {
  children: null,
  align: 'left',
  backdrop: true,
}

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.string,
  backdrop: PropTypes.bool.isRequired,
}

export default Menu
