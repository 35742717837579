/* eslint-disable no-magic-numbers */
import { isArray, omit } from 'lodash'

// copied from https://www.30secondsofcode.org/js/s/format-duration/
const formatDuration = (ms = 0, omitKeys = []) => {
  try {
    if (!isArray(omitKeys)) {
      throw new TypeError('omitKeys is not a array')
    }

    if (ms < 0) ms = -ms
    const time = {
      day: Math.floor(ms / 86400000),
      hour: Math.floor(ms / 3600000) % 24,
      minute: Math.floor(ms / 60000) % 60,
      second: Math.floor(ms / 1000) % 60,
      millisecond: Math.floor(ms) % 1000,
    }

    return Object.entries(omit(time, omitKeys))
      .filter(val => val[1] !== 0)
      .map(([key, val]) => `${val} ${key}${val !== 1 ? 's' : ''}`)
      .join(', ')
  } catch (err) {
    console.error(err)
    throw err
  }
}

export default formatDuration
