import {
  ARCHIVE_GE_VERSION_STRING,
  CREATE_GE_VERSION_STRING,
  DUPLICATE_GE_VERSION_STRING,
  GE_OPERATION_OPTIONS,
  LIST_GE_STRING,
  UNARCHIVE_GE_VERSION_STRING,
  UPDATE_GE_COMPONENT_STRING,
  UPDATE_GE_VERSION_STRING,
} from 'services/graphql'
import {
  AllGraphQLResponseTypes,
  AllPageTransformOperationsTypes,
  AllVariableTypes,
} from 'src/graphql-proxy/common'
import {
  GEFilterBodyParams,
  GEListGraphQlResponse,
  GEListQueryParams,
  GEListRequestVariables,
  GEListRestResponse,
  GEListVariables,
  GEVariantArchiveGraphQLResponse,
  GEVariantArchiveRESTResponse,
  GEVariantArchiveVariables,
  GEVariantComponentSaveRESTResponse,
  GEVariantComponentUpdateVariables,
  GEVariantCreateGraphQLResponse,
  GEVariantCreateRESTResponse,
  GEVariantDuplicateGraphQLResponse,
  GEVariantDuplicateRESTResponse,
  GEVariantDuplicateVariables,
  GEVariantUnarchiveGraphQLResponse,
  GEVariantUnarchiveRESTResponse,
  GEVariantUnarchiveVariables,
  GEVariantUpdateGraphQLResponse,
  GEVariantUpdateRESTResponse,
  GEVersionComponentSaveGraphQlResponse,
  GlobalElementVariantCreateVariables,
  GlobalElementVariantUpdateVariables,
} from './types'
import { JsonValue } from 'src/types'
import { getContentScheduledAt } from 'src/data/utils'

const {
  LIST_GE,
  FILTER_GE,
  ARCHIVE_GE_VERSION,
  UNARCHIVE_GE_VERSION,
  CREATE_GE_VERSION,
  UPDATE_GE_VERSION,
  DUPLICATE_GE_VERSION,
  UPDATE_GE_COMPONENT,
} = GE_OPERATION_OPTIONS

const putPathParamAttributes = ['versionId, globalElementId']

export const geRESTToGQLTransformer = {
  get: {
    gqlTemplate: LIST_GE_STRING,
    generateVariables: ({
      queryParamValues,
    }: {
      queryParamValues: GEListQueryParams
    }): GEListVariables => {
      const { archivedFilter, offset, limit } = queryParamValues

      return {
        input: {
          isArchived: !parseInt(archivedFilter, 10), // 0 is true, 1 is false
        },
        offset: parseInt(offset, 10),
        limit: parseInt(limit, 10),
        orderBy: {
          field: 'updatedAt',
        },
        transformOperationKey: LIST_GE,
      }
    },
  },
}

export const geFilterRESTToGQLTransformer = {
  post: {
    gqlTemplate: LIST_GE_STRING,
    generateVariables: ({
      bodyParamValues,
    }: {
      bodyParamValues: GEFilterBodyParams
    }): GEListVariables => {
      const {
        isArchived,
        offset,
        limit,
        channels,
        status,
        locales,
      } = bodyParamValues

      return {
        input: {
          isArchived,
          filter: {
            channels: channels,
            status: status,
            locales,
          },
        },
        offset: offset,
        limit: limit,
        orderBy: {
          field: 'updatedAt',
        },
        transformOperationKey: GE_OPERATION_OPTIONS.FILTER_GE,
      }
    },
  },
}

export const geVariantComponentRESTToGQLTransformer = {
  put: {
    pathParamAttributes: putPathParamAttributes,
    gqlTemplate: UPDATE_GE_COMPONENT_STRING,
    generateVariables: ({
      pathParamValues,
      bodyParamValues,
    }: {
      bodyParamValues: {
        components: JsonValue
        locale: string
      }
      pathParamValues: {
        globalElementId: string
        versionId: string
      }
    }): GEVariantComponentUpdateVariables => {
      const { versionId, globalElementId } = pathParamValues
      const { components, locale } = bodyParamValues

      return {
        input: {
          contentVariantId: versionId,
          parentContentId: globalElementId,
          components,
          locale,
        },
        transformOperationKey: UPDATE_GE_COMPONENT,
      }
    },
  },
}

export const geVariantRESTToGQLTransformer = {
  post: {
    pathParamAttributes: ['globalComponentId'],
    bodyParamAttributes: ['name', 'description', 'channels', 'locales'],
    gqlTemplate: CREATE_GE_VERSION_STRING,
    generateVariables: ({
      bodyParamValues,
      pathParamValues,
    }: {
      bodyParamValues: {
        name: string
        description: string
        channels: string[]
        locales: string[]
      }
      pathParamValues: {
        globalComponentId: string
      }
    }): GlobalElementVariantCreateVariables => {
      const { globalComponentId } = pathParamValues
      const { name, description, channels, locales } = bodyParamValues
      return {
        input: {
          parentContentId: globalComponentId,
          name,
          description,
          channels,
          locales,
        },
        transformOperationKey: CREATE_GE_VERSION,
      }
    },
  },
  put: {
    pathParamAttributes: putPathParamAttributes,
    gqlTemplate: UPDATE_GE_VERSION_STRING,
    bodyParamAttributes: ['name', 'description', 'channels', 'locales'],
    generateVariables: ({
      pathParamValues,
      bodyParamValues,
    }: {
      bodyParamValues: {
        name: string
        description: string
        channels: string[]
        locales: string[]
      }
      pathParamValues: {
        globalElementId: string
        versionId: string
      }
    }): GlobalElementVariantUpdateVariables => {
      const { versionId, globalElementId } = pathParamValues
      const { name, description, channels, locales } = bodyParamValues
      return {
        input: {
          contentVariantId: versionId,
          parentContentId: globalElementId,
          name,
          description,
          channels,
          locales,
        },
        transformOperationKey: UPDATE_GE_VERSION,
      }
    },
  },
}

const getListGlobalElementTransformation = (
  responseData: GEListGraphQlResponse,
  requestVariables: GEListRequestVariables
): GEListRestResponse => {
  const { input, limit, offset } = requestVariables
  const {
    globalElements: { edges, totalCount },
  } = responseData

  return {
    data: {
      globalComponents: edges.map(edge => {
        const { name, updatedAt, isArchived, isActive, variants } = edge.node

        return {
          id: name,
          pageId: name,
          name,
          updatedAt,
          isActive,
          isArchived,
          scheduledAt: getContentScheduledAt(variants),
          versions: variants.map(v => {
            const { id, versionName, ...rest } = v
            return {
              ...rest,
              versionId: id,
              name: versionName,
            }
          }),
        }
      }),
    },
    query: {
      count: totalCount,
      isArchived: input.isArchived,
      limit,
      offset,
    },
  }
}

export const geTransformOperations: Record<
  string,
  (
    arg1: AllGraphQLResponseTypes,
    arg2: AllVariableTypes
  ) => AllPageTransformOperationsTypes
> = {
  [LIST_GE]: getListGlobalElementTransformation,
  [FILTER_GE]: getListGlobalElementTransformation,
  [CREATE_GE_VERSION]: (
    responseData: GEVariantCreateGraphQLResponse
  ): GEVariantCreateRESTResponse => {
    const {
      createGlobalElementVariant: { id },
    } = responseData

    return {
      version: {
        versionId: id,
      },
    }
  },
  [UPDATE_GE_VERSION]: (
    responseData: GEVariantUpdateGraphQLResponse
  ): GEVariantUpdateRESTResponse => {
    const {
      updateGlobalElementVariantDetails: { versionName },
    } = responseData

    return {
      version: {
        versionName,
      },
    }
  },
  [DUPLICATE_GE_VERSION]: (
    responseData: GEVariantDuplicateGraphQLResponse
  ): GEVariantDuplicateRESTResponse => {
    const {
      copyGlobalElementVariant: { id },
    } = responseData

    return {
      version: {
        versionId: id,
      },
    }
  },
  [ARCHIVE_GE_VERSION]: (
    responseData: GEVariantArchiveGraphQLResponse
  ): GEVariantArchiveRESTResponse => {
    const {
      archiveContentVariant: { isArchived, versionName },
    } = responseData

    return {
      version: {
        archived: isArchived,
        isArchived,
        versionName,
      },
    }
  },
  [UNARCHIVE_GE_VERSION]: (
    responseData: GEVariantUnarchiveGraphQLResponse
  ): GEVariantUnarchiveRESTResponse => {
    const {
      unarchiveContentVariant: { isArchived, versionName },
    } = responseData
    return {
      version: {
        archived: isArchived,
        isArchived,
        versionName,
      },
    }
  },
  [UPDATE_GE_COMPONENT]: (
    responseData: GEVersionComponentSaveGraphQlResponse
  ): GEVariantComponentSaveRESTResponse => {
    const {
      updateGlobalElementComponents: { components, id, updatedAt },
    } = responseData
    return {
      data: {
        version: {
          components,
          versionId: id,
          updatedAt,
        },
      },
    }
  },
}

export const geVariantArchiveRESTToGQLTransformer = {
  put: {
    pathParamAttributes: putPathParamAttributes,
    gqlTemplate: ARCHIVE_GE_VERSION_STRING,
    generateVariables: ({
      pathParamValues,
    }: {
      pathParamValues: {
        versionId: string
        globalElementId: string
      }
    }): GEVariantArchiveVariables => {
      const { versionId, globalElementId } = pathParamValues
      return {
        contentVariantId: versionId,
        parentContentId: globalElementId,
        transformOperationKey: ARCHIVE_GE_VERSION,
      }
    },
  },
}

export const geVariantUnarchiveRESTToGQLTransformer = {
  put: {
    pathParamAttributes: putPathParamAttributes,
    gqlTemplate: UNARCHIVE_GE_VERSION_STRING,
    generateVariables: ({
      pathParamValues,
    }: {
      pathParamValues: {
        versionId: string
        globalElementId: string
      }
    }): GEVariantUnarchiveVariables => {
      const { versionId, globalElementId } = pathParamValues
      return {
        contentVariantId: versionId,
        parentContentId: globalElementId,
        transformOperationKey: UNARCHIVE_GE_VERSION,
      }
    },
  },
}

export const geVariantDuplicateRESTToGQLTransformer = {
  post: {
    pathParamAttributes: putPathParamAttributes,
    bodyParamAttributes: ['versionName', 'description', 'globalComponent'],
    gqlTemplate: DUPLICATE_GE_VERSION_STRING,
    generateVariables: ({
      pathParamValues,
      bodyParamValues,
    }: {
      bodyParamValues: {
        versionName: string
        description: string
        globalComponent: string
      }
      pathParamValues: {
        versionId: string
        globalElementId: string
      }
    }): GEVariantDuplicateVariables => {
      const { versionId } = pathParamValues
      const { versionName, description, globalComponent } = bodyParamValues
      return {
        input: {
          originalContentVariantId: versionId,
          parentContentId: globalComponent,
          name: versionName,
          description,
        },
        transformOperationKey: DUPLICATE_GE_VERSION,
      }
    },
  },
}
