import {
  ApolloError,
  FetchResult,
  MutationFunction,
  MutationFunctionOptions,
  OperationVariables,
} from '@apollo/client'
import { OPERATIONS, handleMutation } from '../services'
import { ACTIONS } from 'components/ContentTableModule/useTableData'
import { BMT_PATH, NEW_BMT_PATH } from 'contexts/navigationLinksContext'
import { Channel } from 'store/channels/types'
import { MESSAGES } from 'src/constants'
import { ModalDetailProps } from './types'
import { NavigateFn } from '@reach/router'

const MENU_REFETCH_TIME = 2000

interface MutationConfig {
  mutationOptions: MutationFunctionOptions
  onSuccess?: (response: FetchResult) => void
  onError?: (error: ApolloError) => void
  snackbarProps?: {
    successMessage: string
    errorMessage?: string
  }
}

interface MutationConfigGetterProps {
  modalDetails: ModalDetailProps
  dispatchTableAction: (
    action: ACTIONS,
    variables?: { targetItemId: string; parentId?: string },
    refetchTime?: number
  ) => void
  closeModal: () => void
  values?: {
    name: string
    channels?: Channel[]
    locales?: Locale[]
  }
}

const getDeleteMenuMutationConfiguration = ({
  modalDetails,
  dispatchTableAction,
  closeModal,
}: MutationConfigGetterProps): MutationConfig => {
  const menuId = modalDetails.targetItemId as string
  const menuName = modalDetails.targetItemName
  return {
    mutationOptions: { variables: { menuId } },
    onSuccess: () => {
      dispatchTableAction(ACTIONS.REFETCH, null, MENU_REFETCH_TIME)
      closeModal()
    },
    onError: closeModal,
    snackbarProps: {
      successMessage: MESSAGES.getDeletedSuccess(menuName),
      errorMessage: MESSAGES.getDeletedError(menuName),
    },
  }
}

const getCopyMenuMutationConfiguration = ({
  modalDetails,
  dispatchTableAction,
  values,
  closeModal,
}: MutationConfigGetterProps & {
  values: { copiedName: string; locales: Locale[]; channels: Channel[] }
}): MutationConfig => {
  const originalId = modalDetails.targetItemId as string
  const copiedName = values.copiedName
  const channels = values.channels.map(c => c.id)
  const locales = values.locales.map(c => c.id)
  const MENU_REFETCH_TIME = 2000
  return {
    mutationOptions: {
      variables: { input: { originalId, copiedName, channels, locales } },
    },
    onSuccess: () => {
      dispatchTableAction(ACTIONS.REFETCH, null, MENU_REFETCH_TIME)
      closeModal()
    },
    onError: closeModal,
    snackbarProps: {
      successMessage: MESSAGES.getCreatedSuccess(copiedName),
      errorMessage: MESSAGES.getMenuCopyError(copiedName),
    },
  }
}

const getDeleteVersionMutationConfiguration = ({
  modalDetails,
  dispatchTableAction,
  closeModal,
}: MutationConfigGetterProps): MutationConfig => {
  const versionName = modalDetails.targetItemName
  const { versionId, parentId: menuId } = modalDetails.targetItemId as {
    versionId: string
    parentId: string
  }

  return {
    mutationOptions: { variables: { versionId, menuParentId: menuId } },
    onSuccess: () => {
      dispatchTableAction(ACTIONS.DELETE_VERSION, {
        targetItemId: versionId,
        parentId: menuId,
      })
      closeModal()
    },
    onError: closeModal,
    snackbarProps: {
      successMessage: MESSAGES.getDeletedSuccess(versionName),
      errorMessage: MESSAGES.getDeletedError(versionName),
    },
  }
}

const getCreateMenuVariantConfiguration = ({
  modalDetails,
  values,
  navigate,
  navNameChange,
}: {
  modalDetails: ModalDetailProps
  values: {
    name: string
    description: string
  }
  navigate: NavigateFn
  navNameChange: boolean
}) => {
  const menuId = modalDetails.targetItemId as string
  return {
    mutationOptions: {
      variables: {
        parentContentId: menuId,
        name: values.name,
        description: values.description,
      },
    },
    onSuccess: response => {
      const versionId = response.data.createMenuVariant.id as string
      void navigate(
        `${
          navNameChange ? NEW_BMT_PATH : BMT_PATH
        }?menuId=${menuId}&versionId=${versionId}`
      )
    },
    snackbarProps: {
      successMessage: MESSAGES.getCreatedSuccess(values.name),
    },
  }
}

const getUpdateMenuVariantConfiguration = ({
  modalDetails,
  closeModal,
}: MutationConfigGetterProps): MutationConfig => {
  const {
    targetItemName: versionName,
    targetItemId: { versionId, parentId: menuId },
  } = modalDetails as {
    targetItemName: string
    targetItemId: { versionId: string; parentId: string }
  }

  return {
    mutationOptions: {
      variables: {
        contentVariantId: versionId,
        parentContentId: menuId,
        name: '',
        channels: [],
        description: '',
      },
    },
    onSuccess: () => {
      closeModal()
    },
    onError: closeModal,
    snackbarProps: {
      successMessage: MESSAGES.getUpdatedSuccess(versionName),
      errorMessage: MESSAGES.getUpdatedError(versionName),
    },
  }
}

const getUpdateMenuDetailsConfiguration = ({
  modalDetails,
  closeModal,
  values,
}: MutationConfigGetterProps): MutationConfig => {
  const menuId = modalDetails.targetItemId as string

  return {
    mutationOptions: {
      variables: {
        id: menuId,
        name: values.name,
        channels: values.channels.map(c => c.id),
        locales: values.locales.map(c => c.id),
      },
    },
    onSuccess: () => {
      closeModal()
    },
    onError: closeModal,
    snackbarProps: {
      successMessage: MESSAGES.getUpdatedSuccess(values.name),
    },
  }
}

const getCreateMenuConfiguration = ({
  navigate,
  values,
  navNameChange,
}: {
  navigate: NavigateFn
  values: {
    name: string
    versionName: string
    channels: Channel[]
    locales: Locale[]
  }
  navNameChange: boolean
}) => {
  return {
    mutationOptions: {
      variables: {
        input: {
          name: values.name,
          versionName: values.versionName,
          channels: values.channels.map(c => c.id),
          locales: values.locales.map(c => c.id),
        },
      },
    },
    onSuccess: response => {
      const versionId = response.data.createMenu.variants[0].id as string
      const menuId = response.data.createMenu.id as string

      void navigate(
        `${
          navNameChange ? NEW_BMT_PATH : BMT_PATH
        }?menuId=${menuId}&versionId=${versionId}`
      )
    },
    snackbarProps: {
      successMessage: MESSAGES.getCreatedSuccess(values.name),
    },
  }
}

const getCopyMenuVariantConfiguration = ({
  navigate,
  values,
  modalDetails,
  navNameChange,
}: {
  navigate: NavigateFn
  values: {
    name: string
    description: string
  }
  modalDetails: ModalDetailProps
  navNameChange: boolean
}) => {
  const { name, description } = values
  const { versionId, parentId: menuId } = modalDetails.targetItemId as {
    versionId: string
    parentId: string
  }

  return {
    mutationOptions: {
      variables: {
        input: {
          originalContentVariantId: versionId,
          parentContentId: menuId,
          name,
          description,
        },
      },
    },
    onSuccess: response => {
      const newVersionId = response.data.copyMenuVariant.id as string

      void navigate(
        `${
          navNameChange ? NEW_BMT_PATH : BMT_PATH
        }?menuId=${menuId}&versionId=${newVersionId}`
      )
    },
    snackbarProps: {
      successMessage: MESSAGES.getCreatedSuccess(values.name),
    },
  }
}

export function buildMutationHandler<T>({
  navNameChange,
  mutation,
  operation,
  values,
  ...configArgs
}: {
  navNameChange: boolean
  mutation: MutationFunction
  operation: OPERATIONS
  values: OperationVariables
  modalDetails: ModalDetailProps
  dispatchTableAction: (
    action: ACTIONS,
    variables?: { targetItemId: string; parentId?: string },
    refetchTime?: number
  ) => void
  closeModal: () => void
}): Promise<void> {
  let configuration: MutationConfig
  let shouldSendAdditionalVariables = true

  switch (operation) {
    case OPERATIONS.DELETE:
      configuration = getDeleteMenuMutationConfiguration(configArgs)
      break
    case OPERATIONS.DELETE_VERSION:
      configuration = getDeleteVersionMutationConfiguration(configArgs)
      break
    case OPERATIONS.CREATE_MENU_VARIANT:
      configuration = getCreateMenuVariantConfiguration({
        ...configArgs,
        values,
        navNameChange,
      })
      break
    case OPERATIONS.EDIT_MENU_VARIANT:
      configuration = getUpdateMenuVariantConfiguration(configArgs)
      break
    case OPERATIONS.EDIT_MENU_DETAILS:
      configuration = getUpdateMenuDetailsConfiguration({
        ...configArgs,
        values,
      })
      shouldSendAdditionalVariables = false
      break
    case OPERATIONS.CREATE:
      configuration = getCreateMenuConfiguration({
        ...configArgs,
        values,
        navNameChange,
      })
      shouldSendAdditionalVariables = false
      break
    case OPERATIONS.COPY_VERSION:
      configuration = getCopyMenuVariantConfiguration({
        ...configArgs,
        values,
        navNameChange,
      })
      shouldSendAdditionalVariables = false
      break
    case OPERATIONS.COPY:
      configuration = getCopyMenuMutationConfiguration({
        ...configArgs,
        values,
      })
      shouldSendAdditionalVariables = false
      break
    default:
      return
  }

  return handleMutation<T>({
    mutation,
    additionalMutationVariables: shouldSendAdditionalVariables && values,
    ...configuration,
  })
}
