import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledListItem = styled.div`
  list-style: none;
  cursor: pointer;
`

export const StyledEmptyDropdown = styled.div`
  li {
    color: ${themeUi.palette.brand.primary.gray};
    margin-top: 2px;
    border: 1px solid ${themeUi.palette.ui.neutral.grey3};
    border-radius: 4px;
    pointer-events: none;
  }
  li,
  ul {
    cursor: default;
  }
`
