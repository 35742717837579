import API, { API_URL, extractData } from 'src/business-layer-client'
import { MENU_STATUS, TOAST_MESSAGE_TYPES } from 'src/constants'
import React, { Component } from 'react'
import { find, findIndex, isEmpty, noop } from 'lodash'
import CategoryCard from 'modules/menu/components/CategoryCard'
import ServerErrorScreen from 'components/TempScreens/ServerErrorScreen'
import SortableList from 'modules/menu/components/SortableList'
import Spinner from 'components/Spinner'
import { StyledWrapper } from 'modules/menu/styles'
import arrayMove from 'array-move'
import getErrorMessage from 'modules/menu/utils/getErrorMessage'
import httpStatusCodes from 'http-status-codes'
import parseMenu from 'modules/menu/utils/parseMenu'
import { showToast } from 'components/ToastSnackbarContainer'

class Menu extends Component {
  state = {
    menuItems: [],
    selectedMenuItems: [],
    status: '',
    statusError: null,
  }

  componentDidMount = async () => {
    try {
      this.setState({ status: MENU_STATUS.LOADING })
      const response = await API.xpm.get(API_URL.MENU_GET())
      this.setState({ status: MENU_STATUS.RESOLVED })

      /* istanbul ignore else */
      if (response.data.status_code === httpStatusCodes.OK) {
        const menuItems = extractData(response).menus || []

        /* istanbul ignore else */
        if (Array.isArray(menuItems)) {
          const nextMenuItems = menuItems
            .sort((a, b) => a.order - b.order)
            .map(parseMenu)
          this.setState({ menuItems: [nextMenuItems] })
        }
      }
    } catch (err) {
      console.error(err)
      this.setState({
        status: MENU_STATUS.REJECTED,
        statusError: err.response.status,
      })
      showToast({
        message: getErrorMessage(err),
        kind: TOAST_MESSAGE_TYPES.ALERT,
      })
    }
  }

  handleSortEvent = async ({ oldIndex, newIndex, collection: level }) => {
    try {
      const menuItems = this.state.menuItems
      arrayMove.mutate(menuItems[level], oldIndex, newIndex)

      this.setState({ menuItems })

      const response = await API.xpm.put(
        API_URL.MENU_ORDER(),
        menuItems[level].map(({ id }, idx) => ({ menuId: id, order: idx + 1 }))
      )

      if (response.data.status_code !== httpStatusCodes.OK) {
        throw new Error('sorting operation failed')
      }
    } catch (err) {
      this.setState(prevState => {
        const menuItems = prevState.menuItems
        arrayMove.mutate(menuItems[level], newIndex, oldIndex)

        return {
          menuItems,
        }
      })
    }
  }

  handleMenuItemClick = async (menu, level = 0) => {
    try {
      const MAX_LEVEL = 7
      const selectedMenuItems = [...this.state.selectedMenuItems]
      selectedMenuItems[level] = menu.id
      selectedMenuItems.length = level <= MAX_LEVEL ? level + 1 : level

      this.setState({ selectedMenuItems })

      /* istanbul ignore else */
      if (level >= MAX_LEVEL) return

      const response = await API.xpm.get(API_URL.MENU_TREE_GET({ id: menu.id }))

      /* istanbul ignore else */
      if (response.data.status_code === httpStatusCodes.OK) {
        let { children } = extractData(response)
        if (!Array.isArray(children)) {
          children = []
        }

        const menuItems = this.state.menuItems
        menuItems[level + 1] = children
          .sort((a, b) => a.order - b.order)
          .map(el =>
            parseMenu({ ...el, parent: menu.id, parentName: menu.label })
          )

        menuItems.length = level + 2

        this.setState({ menuItems })
      }
    } catch (err) {
      showToast({
        message: getErrorMessage(err),
        kind: TOAST_MESSAGE_TYPES.ALERT,
      })
    }
  }

  handleMenuItemCreate = async _param => {
    try {
      let label, parent, level, input
      // If input is of type Event
      if (_param.type) {
        _param.preventDefault()
        parent = _param.target.dataset.parent
        level = _param.target.dataset.level
        input = _param.target.querySelector('input')
        label = input.value.trim()
      } else {
        // If input is of type Object
        try {
          const {
            level: _level,
            parent: _parent,
            label: _label,
            input: _input,
          } = _param
          parent = _parent
          label = _label.trim()
          level = _level
          input = _input
        } catch (e) {
          console.error('Error creating menu item from object', e)
          return
        }
      }

      if (isEmpty(label)) {
        return
      }

      const response = await API.xpm.post(API_URL.MENU_POST(), {
        name: label,
        label,
        ...(parent && { parent }),
      })

      if (response.data.status_code === httpStatusCodes.CREATED) {
        const menuItems = [...this.state.menuItems]
        const item = parseMenu(extractData(response))

        if (level > 0) {
          const _parent = find(menuItems[level - 1], { id: item.parent })
          item.parentName = _parent ? parent.label : null
        }
        menuItems[level] = [...menuItems[level], item]

        this.setState({ menuItems }, () => {
          input.value = ''
        })
      }
    } catch (err) {
      showToast({
        message: getErrorMessage(err),
        kind: TOAST_MESSAGE_TYPES.ALERT,
      })
    }
  }

  handleMenuItemUpdate = ({ level, id, payload }, callback = noop) => {
    this.setState(prevState => {
      const { menuItems } = prevState
      const index = findIndex(menuItems[level], { id })
      menuItems[level][index] = { ...menuItems[level][index], ...payload }

      if (menuItems[level].isActive !== payload.isActive) {
        let levelPointer = level + 1

        while (menuItems.length > levelPointer) {
          menuItems[levelPointer] = menuItems[levelPointer].map(menuItem => {
            return {
              ...menuItem,
              isActive: payload.isActive,
            }
          })
          levelPointer++
        }
      }

      return { menuItems }
    }, callback)
  }

  handleMenuItemDelete = async ({ id, level }) => {
    try {
      const response = await API.xpm.delete(API_URL.MENU_DELETE({ id }))

      if (response.data.status_code === httpStatusCodes.NO_CONTENT) {
        this.setState(prevState => {
          const { menuItems } = prevState
          const isSelected = prevState.selectedMenuItems.includes(id)
          menuItems[level] = menuItems[level].filter(el => el.id !== id)

          if (isSelected) menuItems.length = level + 1

          return { menuItems }
        })
      }
    } catch (err) {
      showToast({
        message: getErrorMessage(err),
        kind: TOAST_MESSAGE_TYPES.ALERT,
      })
    }
  }

  handleMenuItemDisable = async ({ id, isActive, level }) => {
    try {
      const endpoint = API_URL.MENU_UPDATE({ id })
      const response = await API.xpm.patch(endpoint, { isActive })

      if (response.data.status === httpStatusCodes.OK) {
        this.setState(prevState => {
          const { menuItems } = prevState
          const index = findIndex(menuItems[level], { id })
          menuItems[level][index] = { ...menuItems[level][index], isActive }

          let levelPointer = level + 1

          while (menuItems.length > levelPointer) {
            menuItems[levelPointer] = menuItems[levelPointer].map(menuItem => {
              return {
                ...menuItem,
                isActive,
              }
            })
            levelPointer++
          }

          return { menuItems }
        })
      }
    } catch (err) {
      showToast({
        message: getErrorMessage(err),
        kind: TOAST_MESSAGE_TYPES.ALERT,
      })
    }
  }

  render() {
    const { statusError } = this.state
    const isValidError =
      statusError === httpStatusCodes.FORBIDDEN ||
      statusError === httpStatusCodes.UNAUTHORIZED

    return (
      <StyledWrapper>
        {this.state.status === MENU_STATUS.LOADING && (
          <Spinner variant='fullScreen' />
        )}
        {this.state.status === MENU_STATUS.REJECTED && !isValidError && (
          <ServerErrorScreen status={statusError} />
        )}
        {this.state.status === MENU_STATUS.RESOLVED &&
          this.state.menuItems.map((menuItem, level) => {
            const parent = this.state.selectedMenuItems[level - 1] || null
            return (
              <CategoryCard
                key={level}
                level={level}
                parent={parent}
                onSubmit={this.handleMenuItemCreate}
              >
                <SortableList
                  axis='y'
                  lockAxis='y'
                  pressDelay={200}
                  lockToContainerEdges
                  onSortEnd={this.handleSortEvent}
                  collection={level}
                  level={level}
                  menus={menuItem}
                  selectedMenu={this.state.selectedMenuItems}
                  helperClass='dragging-helper-class'
                  handleMenuItemClick={this.handleMenuItemClick}
                  handleMenuItemUpdate={this.handleMenuItemUpdate}
                  handleMenuItemDelete={this.handleMenuItemDelete}
                  handleMenuItemDisable={this.handleMenuItemDisable}
                />
              </CategoryCard>
            )
          })}
      </StyledWrapper>
    )
  }
}

export default Menu
