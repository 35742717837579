/* eslint-disable sonarjs/cognitive-complexity */
import {
  EDITOR_ADD_COMPONENT,
  EDITOR_CONTENT_EDIT,
  EDITOR_INITIALIZE_ERROR,
  EDITOR_INITIALIZE_SUBMIT,
  EDITOR_INITIALIZE_SUCCESS,
  EDITOR_INSERT_COMPONENT,
  EDITOR_IS_DELETING,
  EDITOR_LOCALE_CHANGE,
  EDITOR_META_DATA_SAVE,
  EDITOR_ONCHANGE,
  EDITOR_REMOVE_COMPONENT,
  EDITOR_REORDER_COMPONENT,
  EDITOR_RESET,
  EDITOR_SET_LOCALIZED_ASSETS,
  EDITOR_SET_VERSION_STATUS,
  EDITOR_UPDATE,
  EDITOR_UPDATE_CHANNEL_URL,
  EDITOR_UPDATE_COMPONENT,
  EDITOR_UPDATE_SUCCESS,
} from './actions'
import { MESSAGES, NOT_FOUND_INDEX, TOAST_MESSAGE_TYPES } from 'src/constants'
import { find, get, isEmpty, update } from 'lodash'
import sendPreviewEvent, { eventTypes } from 'lib/sendPreviewEvent'
import arrayMove from 'array-move'
import { createReducer } from 'redux-nano'
import enrichSavedComponents from 'lib/enrichSavedComponents'
import getCuratedComponent from 'lib/getCuratedComponent'
import getNextComponentOrder from 'lib/getNextComponentOrder'
import produce from 'immer'
import rfdc from 'rfdc'
import { showToast } from 'components/ToastSnackbarContainer'
import { updateCuratedComponents } from 'src/components/type-components/ArrayType/utils'

const deepClone = rfdc()

export const initialState = {
  curatedComponents: [],
  localizedCuratedComponents: null,
  descriptors: [],
  selectedRoute: {},
  editorPath: [],
  editorMetaData: {
    saveTime: null,
    iframe: {
      device: 'desktop', // one of mobile, tablet or desktop
      width: '1366px', // mobile: 360x640px, table: 768x1024px, desktop: 1366x768px
      height: '768px',
    },
  },
  isDeletingComponent: false,
  deletePayload: null,
  channelUrl: null,
  hasDescriptorMismatch: false,
  versionStatus: null,
}

export const editorInitializeSuccess = (state, { payload }) => {
  const {
    curatedComponents,
    localizedCuratedComponents,
    descriptors,
    selectedRoute,
    shouldConsumeUds,
  } = payload
  let hasDescriptorMismatch = false

  let enrichedCuratedComponents, enrichedLocalizedCuratedComponents

  if (localizedCuratedComponents) {
    enrichedLocalizedCuratedComponents = localizedCuratedComponents.map(
      comp => {
        return {
          ...comp,
          components: enrichSavedComponents({
            savedComponents: deepClone(comp.components),
            descriptors,
          }),
        }
      }
    )
    enrichedCuratedComponents = enrichedLocalizedCuratedComponents[0].components
  } else {
    enrichedCuratedComponents = enrichSavedComponents({
      savedComponents: deepClone(curatedComponents),
      descriptors,
    })
  }

  if (
    shouldConsumeUds &&
    curatedComponents.length !== enrichedCuratedComponents.length
  ) {
    hasDescriptorMismatch = true
    showToast({
      message: MESSAGES.ERROR_DESCRIPTOR_MISMATCH,
      kind: TOAST_MESSAGE_TYPES.ALERT,
    })
  }

  const editorMetaData = {
    ...state.editorMetaData,
  }

  sendPreviewEvent({
    type: eventTypes.XPM_COMPONENT,
    payload: enrichedCuratedComponents,
  })

  return {
    curatedComponents: enrichedCuratedComponents,
    localizedCuratedComponents: enrichedLocalizedCuratedComponents,
    descriptors,
    selectedRoute,
    editorMetaData,
    hasDescriptorMismatch,
  }
}

export const editorContentEdit = (state, action) => {
  const {
    id,
    path,
    value,
    order,
    componentPath = 'params',
    isArrayType = false,
    indexes = [],
  } = action.payload

  if (isArrayType) {
    const [attributeIdx, componentIdx] = indexes

    const nextState = produce(state, draftState => {
      const selectedComponentIndex = draftState.curatedComponents.findIndex(
        c => c.isSelected === true
      )

      if (selectedComponentIndex !== NOT_FOUND_INDEX) {
        draftState.curatedComponents[selectedComponentIndex].params[path][
          attributeIdx
        ].components[componentIdx].params[action.payload.attribute] = value
      }
    })

    sendPreviewEvent({
      type: eventTypes.XPM_COMPONENT,
      payload: nextState.curatedComponents,
    })

    return nextState
  }
  const newState = produce(state, draftState => {
    draftState.curatedComponents.forEach(component => {
      if (component.id === id && component.order === order) {
        update(component, `${componentPath}.${path}`, () => value)
      }
    })
  })

  sendPreviewEvent({
    type: eventTypes.XPM_COMPONENT,
    payload: newState.curatedComponents,
  })

  return newState
}

export const editorInitializeError = state => {
  return state
}

export function editorAddComponent(state, action) {
  const { id, paths } = action.payload

  const descriptor = find(state.descriptors, { id })
  const curatedComponent = getCuratedComponent(descriptor)

  const localizedPaths = []
  if (state.localizedCuratedComponents) {
    state.localizedCuratedComponents.forEach((_, idx) => {
      if (paths.length === 1) {
        // regular component
        localizedPaths.push(['localizedCuratedComponents', idx, 'components'])
      } else {
        // nested components
        localizedPaths.push([
          'localizedCuratedComponents',
          idx,
          'components',
          ...paths.slice(1),
        ])
      }
    })
  }

  if (paths.length === 1) {
    curatedComponent.order = getNextComponentOrder(state.curatedComponents)
  }

  const nextState = produce(state, function (draftState) {
    update(draftState, paths, function (value) {
      value?.push(curatedComponent)
      return value
    })

    if (localizedPaths.length) {
      localizedPaths.forEach(locPath => {
        update(draftState, locPath, function (value) {
          value?.push(curatedComponent)
          return value
        })
      })
    }
  })

  sendPreviewEvent({
    type: eventTypes.XPM_COMPONENT,
    payload: nextState.curatedComponents,
  })

  return nextState
}

export function editorInsertComponent(state, action) {
  const { callback, paths, component, index = -1 } = action.payload
  const nextState = produce(state, draftState => {
    update(draftState, paths, _val => callback(_val, component))

    // Locales implementation doc: docs/adr/editor-with-localized-components.md
    if (state.localizedCuratedComponents) {
      state.localizedCuratedComponents.forEach((localizedComp, idx) => {
        const currentLocalizedPath = [
          'localizedCuratedComponents',
          idx,
          'components',
          ...paths.slice(1),
        ]

        let updatedComponent
        if (index < 0) {
          // Regular component -> duplicate params of component from each locale
          updatedComponent = deepClone(component)
          // retrive "params" with -2 from component order because
          // 1. "order" begins with 1 at index 0 and 2. component props we received is the duplicated one and already has its order increased by 1.
          const currentParam =
            localizedComp.components[component.order - 2].params
          updatedComponent.params = currentParam
        } else {
          // Array type child -> duplicate index of array type type from each locale
          updatedComponent = get(state, currentLocalizedPath)[index]
        }
        update(draftState, currentLocalizedPath, _val =>
          callback(_val, updatedComponent)
        )
      })
    }

    return draftState
  })

  sendPreviewEvent({
    type: eventTypes.XPM_COMPONENT,
    payload: nextState.curatedComponents,
  })

  return nextState
}

export const editorUpdateComponent = (state, action) => {
  return produce(state, draftState => {
    const matchCondition = component => component.key === action.payload.key
    const idx = draftState.curatedComponents.findIndex(matchCondition)

    if (idx !== NOT_FOUND_INDEX) {
      draftState.curatedComponents[idx] = action.payload.nextComponent
    }
  })
}

const sortReorderedLocalizedComponents = sortArr => (a, b) =>
  sortArr.indexOf(a.key) - sortArr.indexOf(b.key)

export const editorReorderComponent = (state, { payload }) => {
  const curatedComponents = payload.curatedComponents.map(
    (component, index) => ({
      ...component,
      order: index + 1,
    })
  )
  const newOrderArray = curatedComponents.map(c => c.key)

  let newSortedLocalizedComponents
  if (state.localizedCuratedComponents) {
    newSortedLocalizedComponents = deepClone(state.localizedCuratedComponents)
    if ('arrayTypeInfo' in payload) {
      const {
        arrayTypeInfo: { paths, oldIndex, newIndex },
      } = payload
      newSortedLocalizedComponents.forEach(localeComponentList => {
        const pathsWithoutCuratedComponentPrefix = [...paths.slice(1)] // remove 'curatedComponents' from the front of the param list
        // Get the array whose order will change
        const currentArr = get(
          localeComponentList.components,
          pathsWithoutCuratedComponentPrefix
        )
        // Update the array given the new order
        const updatedArr = arrayMove(currentArr, oldIndex, newIndex)
        // Update the references in the localeComponentList.components list
        localeComponentList.components = updateCuratedComponents(
          {
            paths: paths,
            curatedComponents: localeComponentList.components,
          },
          updatedArr
        )
      })
    } else {
      // Reorder the localized curated Components based on the new order array
      // as dictated by the incoming curatedComponents payload
      newSortedLocalizedComponents.forEach(localeComponentList => {
        // Sort components based on key
        localeComponentList.components.sort(
          sortReorderedLocalizedComponents(newOrderArray)
        )
        // Update the order
        localeComponentList.components.forEach((c, idx) => {
          c.order = idx + 1
        })
      })
    }
  }

  sendPreviewEvent({
    type: eventTypes.XPM_COMPONENT,
    payload: curatedComponents,
  })

  return {
    ...state,
    curatedComponents,
    ...(state.localizedCuratedComponents && {
      localizedCuratedComponents: newSortedLocalizedComponents,
    }),
  }
}

export const editorRemoveComponent = (state, action) => {
  const { id, order } = action.payload

  const curatedComponents = state.curatedComponents
    .filter(c => {
      if (c.id === id && c.order === order) {
        return false
      }

      return true
    })
    .map((c, i) => ({ ...c, order: i + 1 }))

  sendPreviewEvent({
    type: eventTypes.XPM_COMPONENT,
    payload: curatedComponents,
  })

  return {
    ...state,
    curatedComponents,
    ...(state.localizedCuratedComponents && {
      localizedCuratedComponents: state.localizedCuratedComponents.map(
        comp => ({
          ...comp,
          components: comp.components
            .filter(c => !(c.id === id && c.order === order))
            .map((c, idx) => ({ ...c, order: idx + 1 })),
        })
      ),
    }),
  }
}

export const editorMetaDataSave = (state, { payload }) => {
  const { saveTime, iframe } = payload

  const editorMetaData = {
    ...state.editorMetaData,
    ...(saveTime && { saveTime }),
    ...(iframe && { iframe }),
  }
  return {
    ...state,
    editorMetaData,
  }
}

export function editorOnChange(state, action) {
  const {
    paths,
    value,
    deleteIndex = NOT_FOUND_INDEX,
    activeLang = null,
  } = action.payload

  const nextState = produce(state, draftState => {
    // Modifies curatedComponents
    if (deleteIndex >= 0 && value === null) {
      update(draftState, paths?.join('.'), _value => {
        _value.splice(deleteIndex, 1)
        return _value
      })
    } else {
      update(draftState, paths?.join('.'), () => value)
    }

    // Set localizedCuratedComponents of focused locale to the modified curatedComponents
    if (draftState?.localizedCuratedComponents) {
      if (Array.isArray(value) || value === null) {
        // `value` is an array when ArrayType child has been added or removed
        // `value` is null when nested components is removed
        const localizedPaths = []
        state.localizedCuratedComponents.forEach((_, idx) => {
          localizedPaths.push([
            'localizedCuratedComponents',
            idx,
            'components',
            ...paths.slice(1),
          ])
        })

        localizedPaths.forEach(locPath => {
          update(draftState, locPath, _val => {
            if (deleteIndex >= 0) {
              _val?.splice(deleteIndex, 1)
            } else {
              _val?.push(value[value.length - 1])
            }
            return _val
          })
        })

        return draftState
      }

      // Other field modified
      draftState.localizedCuratedComponents = draftState.localizedCuratedComponents?.map(
        comp => {
          if (comp.locale === activeLang) {
            return {
              ...comp,
              components: draftState.curatedComponents,
            }
          }

          return comp
        }
      )
    }
  })

  sendPreviewEvent({
    type: eventTypes.XPM_COMPONENT,
    payload: nextState.curatedComponents,
  })

  return nextState
}

export function editorUpdateDeletingPayload(state, action) {
  const { isDeletingComponent, deletePayload } = action.payload
  return {
    ...state,
    isDeletingComponent,
    deletePayload,
  }
}

export function editorUpdatePayload(state, action) {
  const {
    shouldUseGraphQL,
    versionName,
    description,
    channels = null,
    locales = null,
  } = action.payload

  const currentLocales = locales?.map(locale => locale.id)

  const isGlobalElement =
    state.selectedRoute.__typename == 'GlobalElementVariantType'

  let localizedCuratedComponents

  if (isGlobalElement) {
    const currentDescriptor = state.descriptors?.find(descriptor =>
      state.curatedComponents.find(
        curatedComponent => curatedComponent.id === descriptor.id
      )
    )

    const curatedComponent = []

    if (!isEmpty(currentDescriptor)) {
      curatedComponent.push({
        ...getCuratedComponent(currentDescriptor),
        order: state.curatedComponents[0]?.order,
      })
    }
    const isNewLocale = locale => {
      const newLocales = currentLocales.filter(
        currentLocale => !state.selectedRoute.locales.includes(currentLocale)
      )
      return newLocales.includes(locale)
    }

    localizedCuratedComponents =
      locales && state.localizedCuratedComponents
        ? locales.map(locale => {
            if (isNewLocale(locale.id)) {
              return {
                components: curatedComponent,
                locale: locale.id,
                __typename: '__LocalizedComponentType',
              }
            } else {
              return {
                ...state.localizedCuratedComponents.find(comp =>
                  comp.locale == locale.id ? comp.components : []
                ),
                locale: locale.id,
                __typename: '__LocalizedComponentType',
              }
            }
          })
        : null
  }

  if (shouldUseGraphQL) {
    const selectedRouteWithPayload = {
      ...state.selectedRoute,
      variants: state.selectedRoute.variants.map(variant => ({
        ...variant,
        versionName,
        name: versionName,
        description,
        ...(channels && { channels }),
      })),
    }
    return {
      ...state,
      selectedRoute: selectedRouteWithPayload,
    }
  }

  const payload = {
    ...state.selectedRoute,
    versionName,
    name: versionName,
    description,
    ...(channels && { channels }),
    ...(locales && { locales: currentLocales }),
  }

  return {
    ...state,
    ...(localizedCuratedComponents && { localizedCuratedComponents }),
    selectedRoute: payload,
  }
}

export function editorUpdateChannelUrl(state, action) {
  return {
    ...state,
    channelUrl: action.payload,
  }
}

export const editorOnLocaleChange = (state, action) => {
  const { activeLang } = action.payload

  const newCuratedComponents = state.localizedCuratedComponents?.find(
    comp => comp.locale === activeLang
  )?.components

  sendPreviewEvent({
    type: eventTypes.XPM_COMPONENT,
    payload: newCuratedComponents,
  })

  return {
    ...state,
    curatedComponents: newCuratedComponents ?? [],
  }
}

export const editorSetVersionStatus = (state, action) => {
  return {
    ...state,
    versionStatus: action.payload,
  }
}

export const editorSetLocalisedAsset = (state, action) => {
  const { selectedLocalesForAsset, selectedAsset, paths } = action.payload

  const selectedLocaleIds = selectedLocalesForAsset.map(loc => loc.id)
  return produce(state, draftState => {
    state?.localizedCuratedComponents?.forEach((loc, idx) => {
      const isLocaleSelectedForAsset = selectedLocaleIds.includes(loc.locale)

      if (isLocaleSelectedForAsset) {
        const newPaths = [
          'localizedCuratedComponents',
          idx,
          'components',
          ...paths.slice(1),
        ]

        // Update localizedCuratedComponent index
        update(draftState, newPaths, function (val) {
          val = selectedAsset
          return val
        })
      }
    })
  })
}

export default createReducer(initialState, {
  [EDITOR_INITIALIZE_SUBMIT]: state => state,
  [EDITOR_INITIALIZE_SUCCESS]: editorInitializeSuccess,
  [EDITOR_INITIALIZE_ERROR]: editorInitializeError,
  [EDITOR_CONTENT_EDIT]: editorContentEdit,
  [EDITOR_ADD_COMPONENT]: editorAddComponent,
  [EDITOR_UPDATE_COMPONENT]: editorUpdateComponent,
  [EDITOR_REORDER_COMPONENT]: editorReorderComponent,
  [EDITOR_REMOVE_COMPONENT]: editorRemoveComponent,
  [EDITOR_ONCHANGE]: editorOnChange,
  [EDITOR_META_DATA_SAVE]: editorMetaDataSave,
  [EDITOR_RESET]: () => initialState,
  [EDITOR_IS_DELETING]: editorUpdateDeletingPayload,
  [EDITOR_UPDATE]: editorUpdatePayload,
  [EDITOR_UPDATE_SUCCESS]: editorUpdatePayload,
  [EDITOR_INSERT_COMPONENT]: editorInsertComponent,
  [EDITOR_UPDATE_CHANNEL_URL]: editorUpdateChannelUrl,
  [EDITOR_LOCALE_CHANGE]: editorOnLocaleChange,
  [EDITOR_SET_VERSION_STATUS]: editorSetVersionStatus,
  [EDITOR_SET_LOCALIZED_ASSETS]: editorSetLocalisedAsset,
})
