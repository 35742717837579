import { CONTENT_TYPE } from './types'

export const getSaveButtonTooltipText = (contentType: CONTENT_TYPE): string => {
  switch (contentType) {
    case CONTENT_TYPE.PAGE:
      return 'Add content to your page to enable save'
    case CONTENT_TYPE.GLOBAL_ELEMENT:
      return 'Add content to your global element to enable save'
    case CONTENT_TYPE.MENU:
      return 'Add content to your menu to enable save'
    default:
      return 'Add some content to enable save'
  }
}

export const getPublishButtonTooltipText = (
  contentType: CONTENT_TYPE
): string => {
  switch (contentType) {
    case CONTENT_TYPE.PAGE:
      return 'Add content to your page to enable publish & schedule'
    case CONTENT_TYPE.GLOBAL_ELEMENT:
      return 'Add content to your global element to enable publish & schedule'
    case CONTENT_TYPE.MENU:
      return 'Add content to your menu to enable publish & schedule'
    default:
      return 'Add some content to enable publish & schedule'
  }
}

export const getLastUpdatedDateLabel = (formattedDate: string): string =>
  `Last updated ${formattedDate}`
