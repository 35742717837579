import { CuratedComponentProps, DescriptorProps } from 'store/types'
import size from 'lodash/size'

export const isComponentsAndDescriptorsValid = (
  components: CuratedComponentProps | CuratedComponentProps[],
  descriptors: DescriptorProps[]
): boolean => {
  return !!(Array.isArray(components) && components.length && size(descriptors))
}

export const isIFrame = (
  input: HTMLElement | null
): input is HTMLIFrameElement => input !== null && input.tagName === 'IFRAME'
