import API from 'src/business-layer-client'
import { getAccountId } from 'src/lib/cookieUtils'
import handleResponse from 'lib/handleResponse'
import httpStatusCodes from 'http-status-codes'
import isDOMavailable from 'lib/isDOMavailable'
const isLocal = process.env.NODE_ENV === 'development'

class Config {
  async get() {
    const result = {
      ok: null,
      error: 'No accountId',
      status: httpStatusCodes.OK,
    }

    if (isDOMavailable) {
      const accountId = getAccountId()
      if (accountId || isLocal) {
        try {
          const response = await API.xpm.get('config')
          result.ok = handleResponse.success(response)
          result.error = null
          result.status = response?.status
          return result
        } catch (err) {
          if (err?.response?.status) {
            result.status = err.response.status
          } else {
            result.status = httpStatusCodes.INTERNAL_SERVER_ERROR
          }
          console.error('Error fetching configuration:', err)
        }
      }
    }
    return result
  }
}

export default new Config()
