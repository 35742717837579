import React, { Dispatch, FunctionComponent, useRef, useState } from 'react'
import {
  StyledDropdownButtonWrapper,
  StyledDropdownList,
  StyledDropdownListItem,
} from './styles'
import { AnyAction } from 'redux'
import { ButtonWithIcon } from '@teamfabric/copilot-ui'
import { CHANGE_ACTIVE_LANGUAGE } from 'store/i18n/actions'
import { I18nLocale } from 'store/i18n/types'
import useClickOutside from 'hooks/useClickOutside'
import { useDispatch } from 'react-redux'

const LocaleFilter: FunctionComponent<{
  locales: I18nLocale[]
  activeLang: string
}> = ({ locales, activeLang }) => {
  const dropdownRef = useRef(null)
  const dispatch: Dispatch<AnyAction> = useDispatch()
  const [showOptions, setShowOptions] = useState(false)

  useClickOutside(dropdownRef, () => setShowOptions(false))

  const handleOnChangeLocale = (locale: I18nLocale): void => {
    setShowOptions(false)
    dispatch((CHANGE_ACTIVE_LANGUAGE(locale.code) as unknown) as AnyAction)
  }

  return (
    <StyledDropdownButtonWrapper ref={dropdownRef}>
      <ButtonWithIcon
        icon={showOptions ? 'UpArrow' : 'DownArrow'}
        iconPosition='right'
        data-testid='locale-filter'
        className='dropdown-button'
        text={activeLang}
        isPrimary={false}
        theme='light'
        onClick={() => setShowOptions(s => !s)}
        size='small'
      />
      {showOptions && (
        <StyledDropdownList
          className='is-right-col'
          data-testid='locale-options'
        >
          {locales.map((locale, i) => (
            <StyledDropdownListItem
              key={locale.code}
              onClick={() => handleOnChangeLocale(locale)}
              data-testid={`locale-option-${i}`}
              className='has-space'
            >
              <span>{locale.code}</span>
              {locale.isDefault && <span className='right'>Default</span>}
            </StyledDropdownListItem>
          ))}
        </StyledDropdownList>
      )}
    </StyledDropdownButtonWrapper>
  )
}

export default LocaleFilter
