import React, { memo } from 'react'

const IconCaretDown = props => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='8'
      viewBox='0 0 16 8'
      {...props}
    >
      <defs>
        <linearGradient id='a' x1='0%' x2='101.952%' y1='37.5%' y2='62.738%'>
          <stop offset='0%' stopColor='#929292' />
          <stop offset='99.952%' stopColor='#929292' />
        </linearGradient>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <path d='M-4-8h24v24H-4z' />
        <path
          fill='url(#a)'
          d='M12 13.683l6.35-5.442a1 1 0 0 1 1.3 1.518l-7 6a1 1 0 0 1-1.3 0l-7-6a1 1 0 1 1 1.3-1.518L12 13.683z'
          transform='translate(-4 -8)'
        />
      </g>
    </svg>
  )
}

export default memo(IconCaretDown)
