import styled from 'styled-components'

export const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

export const StyledListItem = styled.li`
  display: ${props => (props.display ? props.display : 'inline-block')};
  padding: 0;
`
