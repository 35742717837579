import { DESCRIPTOR_DETAILS } from 'src/constants'
import { DescriptorProps } from 'store/types'
import { EventTargetProps } from './components/descriptor-content/types'
import { JsonValue } from 'src/types'
import isEmpty from 'lodash/isEmpty'

const { INDENTATION } = DESCRIPTOR_DETAILS

export const handleParseToJson = (
  attr: string
): { attributes: JsonValue | null; error: Error | null } => {
  const result = {
    attributes: null,
    error: null,
  }

  try {
    result.attributes = isEmpty(attr.trim())
      ? {}
      : (JSON.parse(attr) as JsonValue)
  } catch (err) {
    result.error = err as Error
  }

  return result
}

export const setInitialData = (
  data: { universalDescriptorById: DescriptorProps },
  setter: (desc: DescriptorProps) => void
): void => {
  setter({
    ...data.universalDescriptorById,
    attributes: JSON.stringify(
      data.universalDescriptorById.attributes,
      null,
      INDENTATION
    ),
  })
}

export const handleChange = (
  target: EventTargetProps,
  descriptor: DescriptorProps,
  setDescriptor: (desc: DescriptorProps) => void
): void => {
  const { name, value } = target
  setDescriptor({ ...descriptor, [name]: value })
}

export const isValidComponentId = (componentId: string): boolean => {
  return componentId.indexOf(' ') < 0
}
