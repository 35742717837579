import * as Yup from 'yup'
import {
  CREATE_NEW_VERSION_LABEL,
  EDITOR_MODAL_LABEL,
  GE_CREATE_MODAL,
  LOCALES,
  MAKE_A_COPY_LABEL,
  MODAL_ACTION,
  MODAL_SECTIONS,
  VALIDATION_ERRORS,
} from 'src/constants'
import { Modal, ModalCommonInput, ModalContext, ModalMode } from 'data/types'
import { CHANNELS_FILTER_LABEL } from 'components/Filters/constants'
import { ModalType } from 'components/ContentModalModule/types'
import { MultiSelectTextChipsOptions } from 'lib/getMultiSelectTextChipsOptions/types'

type NewGlobalElementModalInput = ModalCommonInput<
  null,
  NewGEModalForm['values']
>

export const generateAddNewGEModal = (
  input: NewGlobalElementModalInput
): Modal => {
  return {
    modalType: ModalType.Form,
    size: 'medium',
    title: GE_CREATE_MODAL.title,
    mode: MODAL_ACTION.CREATE,
    onSubmit: input.onSubmit,
    onClose: input.onModalClose,
    forms: [
      generateNewGEModalForm({
        context: input.context,
      }),
    ],
  }
}

type NewGlobalElementVersionModalInput = ModalCommonInput<
  NewGEVersionModalForm['values'],
  NewGEVersionModalForm['values']
>

export const generateAddNewGEVersionModal = (
  input: NewGlobalElementVersionModalInput
): Modal => {
  return {
    modalType: ModalType.Form,
    size: 'medium',
    title:
      input.mode === ModalMode.COPY
        ? MAKE_A_COPY_LABEL
        : CREATE_NEW_VERSION_LABEL,
    mode: MODAL_ACTION.CREATE,
    onSubmit: input.onSubmit,
    onClose: input.onModalClose,
    forms: [
      generateNewGEVersionModalForm({
        initialValues: input.initialValues,
        context: input.context,
        mode: input?.mode,
      }),
    ],
  }
}

type CopylobalElementVersionModalInput = ModalCommonInput<
  CopyGEModalForm['values'],
  CopyGEModalForm['values']
>

export const generateCopyModal = (
  input: CopylobalElementVersionModalInput
): Modal => {
  return {
    modalType: ModalType.Form,
    size: 'medium',
    title: MAKE_A_COPY_LABEL,
    mode: MODAL_ACTION.CREATE,
    onSubmit: input.onSubmit,
    onClose: input.onModalClose,
    forms: [
      generateCopyGEModalForm({
        initialValues: input.initialValues,
        context: input.context,
      }),
    ],
  }
}

/** Forms */

type NewGEModalForm = {
  values: {
    locales?: MultiSelectTextChipsOptions[]
    channels?: MultiSelectTextChipsOptions[]
    name: string
    versionName: string
  }
}

interface NewGEModalFormInput extends ModalContext {
  initialValues?: Partial<NewGEModalForm['values']>
}

const generateNewGEModalForm = (input: NewGEModalFormInput) => {
  const context = input.context
  const localesExists = context.localesChipsOptions?.length
  const channelsExists = context.channelsChipsOptions?.length

  const initialValues: NewGEModalForm['values'] = {
    name: '',
    versionName: '',
    locales: context.localesChipsOptions,
    channels: [],
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(VALIDATION_ERRORS.EMPTY_NAME),
    versionName: Yup.string().required(VALIDATION_ERRORS.EMPTY_VERSION_NAME),
    ...(!!localesExists && {
      locales: Yup.array().min(1, VALIDATION_ERRORS.MIN_LOCALE_REQUIRED),
    }),
  })

  return {
    name: MODAL_SECTIONS.GENERAL_DETAILS,
    initialValues,
    validationSchema,
    getComponents: (values: NewGEModalForm['values']) => [
      ...(localesExists
        ? [
            {
              type: 'MultiSelectTextChips',
              label: LOCALES.localesMultiSelectLabel,
              name: 'locales',
              dataTestId: 'ge-locales-field',
              value: values.locales,
              dropdownOptions: context.localesChipsOptions,
            },
          ]
        : []),
      ...(channelsExists
        ? [
            {
              type: 'MultiSelectTextChips',
              label: CHANNELS_FILTER_LABEL,
              name: 'channels',
              dataTestId: 'ge-channels-field',
              value: values.channels,
              dropdownOptions: context.channelsChipsOptions,
              disabled: false,
            },
          ]
        : []),
      {
        type: 'Input',
        label: GE_CREATE_MODAL.name,
        name: 'name',
        value: values.name,
        dataTestId: 'input-ge-name',
      },
      {
        type: 'Input',
        label: GE_CREATE_MODAL.versionName,
        name: 'versionName',
        value: values.versionName,
        dataTestId: 'input-ge-version-name',
      },
    ],
  }
}

type NewGEVersionModalForm = {
  values: {
    description?: string
    name: string
    versionName: string
    locales?: MultiSelectTextChipsOptions[]
    channels?: MultiSelectTextChipsOptions[]
  }
}

interface NewGEVersionModalFormInput extends ModalContext {
  initialValues?: Partial<NewGEVersionModalForm['values']>
  mode?: ModalMode
}

interface CopyGEModalFormInput extends ModalContext {
  initialValues?: Partial<CopyGEModalForm['values']>
}

const generateNewGEVersionModalForm = (input: NewGEVersionModalFormInput) => {
  const context = input.context
  const initialValues: NewGEVersionModalForm['values'] = {
    name: input.initialValues.name,
    versionName: input.initialValues.versionName,
    description: input.initialValues.description,
    locales: context.localesChipsOptions,
    channels: [],
  }
  const localesExists = context.localesChipsOptions?.length
  const channelsExists = context.channelsChipsOptions?.length
  const modalMode = input?.mode

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(VALIDATION_ERRORS.EMPTY_NAME),
    versionName: Yup.string().required(VALIDATION_ERRORS.EMPTY_VERSION_NAME),
  })

  return {
    name: MODAL_SECTIONS.GENERAL_DETAILS,
    initialValues,
    validationSchema,
    getComponents: (values: NewGEVersionModalForm['values']) => [
      ...(channelsExists && modalMode === ModalMode.CREATE
        ? [
            {
              type: 'MultiSelectTextChips',
              label: CHANNELS_FILTER_LABEL,
              name: 'channels',
              dataTestId: 'ge-channels-field',
              value: values.channels,
              dropdownOptions: context.channelsChipsOptions,
              disabled: false,
            },
          ]
        : []),
      ...(localesExists && modalMode === ModalMode.CREATE
        ? [
            {
              type: 'MultiSelectTextChips',
              label: LOCALES.localesMultiSelectLabel,
              name: 'locales',
              dataTestId: 'ge-locales-field',
              value: values.locales,
              dropdownOptions: context.localesChipsOptions,
            },
          ]
        : []),
      {
        type: 'Input',
        label: GE_CREATE_MODAL.name,
        name: 'name',
        value: values.name,
        dataTestId: 'input-ge-name',
        disabled: true,
      },
      {
        type: 'Input',
        label: GE_CREATE_MODAL.versionName,
        name: 'versionName',
        value: values.versionName,
        dataTestId: 'input-ge-version-name',
      },
      {
        type: 'Input',
        label: EDITOR_MODAL_LABEL.DESCRIPTION,
        name: 'description',
        value: values.description,
        dataTestId: 'input-description',
      },
    ],
  }
}

type CopyGEModalForm = {
  values: {
    name: string
    newGeName: string
    geVersions: string
  }
}

const generateCopyGEModalForm = (input: CopyGEModalFormInput) => {
  const initialValues: CopyGEModalForm['values'] = {
    name: input.initialValues.name,
    newGeName: input.initialValues.newGeName,
    geVersions: input.initialValues.geVersions,
  }

  const validationSchema = Yup.object().shape({
    newGeName: Yup.string().required(VALIDATION_ERRORS.EMPTY_NEW_GE_NAME),
  })

  return {
    name: MODAL_SECTIONS.GENERAL_DETAILS,
    initialValues,
    validationSchema,
    getComponents: (values: CopyGEModalForm['values']) => [
      {
        type: 'Input',
        label: GE_CREATE_MODAL.name,
        name: 'name',
        value: values.name,
        dataTestId: 'input-ge-original-name',
        disabled: true,
      },
      {
        type: 'Input',
        label: GE_CREATE_MODAL.geVersions,
        name: 'geVersions',
        value: values.geVersions,
        dataTestId: 'input-page-geVersion',
        disabled: true,
      },
      {
        type: 'Input',
        label: GE_CREATE_MODAL.newCopiedName,
        name: 'newGeName',
        value: values.newGeName,
        dataTestId: 'input-ge-newname',
      },
    ],
  }
}
