export enum CONTEXT_MENU_OPTIONS {
  DEACTIVATE = 'Deactivate',
  ACTIVATE = 'Activate',
  EDIT_DETAILS = 'Edit details',
  MAKE_A_COPY = 'Make a copy',
  NEW_VERSION = 'New version',
  ARCHIVE = 'Archive',
  DELETE = 'Delete',
  EDIT = 'Edit',
  UNARCHIVE = 'Unarchive',
  PREVIEW = 'Preview',
  VIEW_DETAILS = 'View Details',
  VIEW = 'View',
}

export const CONTEXT_MENU_TEST_ID = 'item-context-menu'
