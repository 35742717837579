import rfdc from 'rfdc'

const deepClone = rfdc()

export const updateCuratedComponents = (properties, updatedArray) => {
  const isLastIteration = (index, _paths) => index === _paths.length - 1
  const { paths } = properties
  if (Array.isArray(paths) && paths.length) {
    const propCopy = deepClone(properties)
    let last = propCopy
    for (const [i, path] of paths.entries()) {
      if (isLastIteration(i, paths)) {
        last[path] = updatedArray
      } else {
        last = last[path]
      }
    }

    return propCopy.curatedComponents
  }
}
