import { CONTENT_TYPE } from 'components/ContentEditorCTA/types'

/* Global Constants */
export const editorPath = '/editor'
export const gcEditorPath = '/ge-editor'
export const tobyBaseApiPath = '/api/management'
export const tobyGraphQlPath = `${tobyBaseApiPath}/graphql`
export const tobyLocalCopilotlUrl = `http://localhost:8080${tobyBaseApiPath}/`
export const editorPreviewRoute = '/cms/preview'
export const MAX_ALLOWED_GE_COMPONENTS = 1
export const NOT_FOUND_INDEX = -1
export const FOUND_INDEX = 1
export const DEFAULT_CHANNEL = 12
export const DEFAULT_TOAST_DURATION = 5000 // 5000ms or 5s
export const MAX_FILE_COUNT = 1
export const TOAST_REMOVAL_TIMING = 650 // 650ms
export const CSS_PROPERTY_TOP_ON_HOVER = 17
export const END_DATE = '2099-12-31T00:00:00.000Z'
export const UPDATED_AT = '2022-03-08T18:10:53.283Z'
export const EMPTY_STRING = ''
export const NO_RECORDS_FOUND = 'No records found'
export const NO_RESULTS_FOUND = 'No results found'
export const NO_RESULTS = 'No results'
export const NO_RESULTS_SEARCH_ID = 'no_search_id'
export const CLEAR_SEARCH = 'Clear search'
export const SEARCH_TEXT = 'Search'
export const CANCEL_TEXT = 'Cancel'
export const SAVE_TEXT = 'Save'
const SAVING_LABEL = 'Saving...'
export const IMPORT_HIERARCHY_LABEL = 'Import hierarchy'
export const SELECT_HIERARCHY_LABEL = 'Select hierarchy'
export const ADD_PAGE_LABEL = 'Add page'
export const ADD_PAGE_TYPE_LABEL = 'Add page type'
export const ADD_GLOBAL_ELEMENT_LABEL = 'Add global element'
export const CREATE_VERSION_LABEL = 'Create version'
export const CREATE_NEW_VERSION_LABEL = 'Create new version'
export const CREATE_PAGE_LABEL = 'Create page'
export const MAKE_A_COPY_LABEL = 'Make a copy'
export const EDIT_DETAILS_LABEL = 'Edit details'
export const VIEW_DETAILS_LABEL = 'View details'
const VIEW_VERSION_DETAILS_LABEL = 'View version details'
const EDIT_VERSION_DETAILS_LABEL = 'Edit version details'
export const ADD_MENU_LABEL = 'Add menu'
export const ADD_MENU_VERSION_LABEL = 'Add menu version'
export const EDIT_MENU_VERSION_LABEL = 'Edit menu version'
export const DUPLICATE_MENU_VERSION_LABEL = 'Duplicate menu version'
export const ADD_IMAGE_LABEL = 'Add image'
export const EDIT_IMAGE_LABEL = 'Edit image'
const ADD_IMAGE_URL = 'Add image URL'
const ADD_LOCAL_IMAGE = 'Choose from desktop'
export const ALT_TEXT_LABEL = 'Alt text'
export const OPTIONAL_DESCRIPTION_LABEL = 'Description (optional)'
export const ADD_SUBNODE_LABEL = 'Add sub-node'
export const ADD_COMPONENTS_LABEL = 'Add components'
export const SCHEDULE_LABEL = 'Schedule'
export const PUBLISH_LABEL = 'Publish'
const URL_LABEL = 'URL'
export const CHANNELS = 'channels'
export const PREVIEW_CHANNELS_LABEL = `Preview ${CHANNELS}`
export const SHADOW_RELEASE_STAGE = 'shadowReleaseStage'
export const OK = 'OK'
export const ALLOW_NESTED_COMPONENTS = 'allowNestedComponents'
export const PAGE = 'page'
export const GLOBAL_ELEMENT = 'global element'
export const NOT_SCHEDULED = 'Not scheduled'
export const DEBOUNCE_DURATION_MS = 500
export const SEARCH_LIMIT = 4
export const CONTENT_SEARCH_DATA_TESTID = 'content-search-dropdown'
export const DIRECTION_DESC = 'DESC'
export const MAX_RETRY_COUNT = 3
// x-site-context "stage" values based on url
export const STAGE_NAME = {
  dev02: 'dev02',
  sandbox: 'sandbox',
  live: 'prod01',
  localhost: 'local',
}

// Holds all labels defined above. Useful in cases where we require more than
// a couple of these. We only need to import this object and have access to the labels needed
export const LABELS = {
  NO_RECORDS_FOUND,
  NO_RESULTS_FOUND,
  NO_RESULTS,
  CLEAR_SEARCH,
  SAVING_LABEL,
  IMPORT_HIERARCHY_LABEL,
  SELECT_HIERARCHY_LABEL,
  ADD_PAGE_LABEL,
  ADD_PAGE_TYPE_LABEL,
  ADD_GLOBAL_ELEMENT_LABEL,
  CREATE_VERSION_LABEL,
  CREATE_NEW_VERSION_LABEL,
  CREATE_PAGE_LABEL,
  MAKE_A_COPY_LABEL,
  EDIT_DETAILS_LABEL,
  VIEW_DETAILS_LABEL,
  VIEW_VERSION_DETAILS_LABEL,
  EDIT_VERSION_DETAILS_LABEL,
  ADD_MENU_LABEL,
  ADD_MENU_VERSION_LABEL,
  EDIT_MENU_VERSION_LABEL,
  DUPLICATE_MENU_VERSION_LABEL,
  ADD_IMAGE_LABEL,
  EDIT_IMAGE_LABEL,
  ADD_IMAGE_URL,
  ADD_LOCAL_IMAGE,
  ALT_TEXT_LABEL,
  OPTIONAL_DESCRIPTION_LABEL,
  ADD_SUBNODE_LABEL,
  ADD_COMPONENTS_LABEL,
  PREVIEW_CHANNELS_LABEL,
}

export const BACK_TO_BROWSE_MENU = 'Back to all browse menu'
export const BACK_TO_MENUS = 'Back to menus'
export const BACK_TO_GLOBAL_ELEMENTS = 'Back to all global elements'
export const BACK_TO_PAGES = 'Back to all pages'
export const ALL_COMPONENTS = 'All components'

export const TOAST_MESSAGE_TYPES = {
  ALERT: 'alert',
  SUCCESS: 'success',
  MESSAGE: 'message',
  NOTIFICATION: 'notification',
  FLOATING: 'floating',
}

export const STATUS = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  DRAFT: 'DRAFT',
  ENDED: 'ENDED',
  INACTIVE: 'INACTIVE',
  LIVE: 'LIVE',
  SCHEDULED: 'SCHEDULED',
}

/* Usage Guide:
  - getCreatedSuccess: Create, Copy, Duplicate
  - getSavedSuccess: Edit, Save, Update
  - getDeletedSuccess: Delete, Remove
*/
export const MESSAGES = {
  getCreatedSuccess: (name: string): string =>
    `${name} was created successfully`,
  getCreatedError: (name: string): string => `Failed to create ${name}`,
  getUpdatedSuccess: (name: string): string =>
    `${name} was updated successfully`,
  getUpdatedError: (name: string): string => `Failed to update ${name}`,
  getSavedSuccess: (name: string): string => `${name} was saved successfully`,
  getDuplicatedSuccess: (name: string): string =>
    `${name} was duplicated successfully`,
  getArchivedSuccess: (name: string): string =>
    `${name} was archived successfully`,
  getUnarchivedSuccess: (name: string): string =>
    `${name} was unarchived successfully`,
  getActivatedSuccess: (name: string): string =>
    `${name} was activated successfully`,
  getDeactivatedSuccess: (name: string): string =>
    `${name} was deactivated successfully`,
  getDeletedSuccess: (name: string): string =>
    `${name} was deleted successfully`,
  getScheduledSuccess: (name: string): string =>
    `${name} was scheduled successfully`,
  getPublishedSuccess: (name: string): string =>
    `${name} was published successfully`,
  deletePageAndVersionsSuccess: (name: string): string =>
    `Page "${name}" and its versions were deleted successfully.`,
  deletePageTitle: (name: string): string => `Deleting page "${name}"`,
  getUploadSuccess: (name: string): string =>
    `${name} was uploaded successfully`,
  getFetchingError: (name = 'data'): string => `Error fetching ${name}`,
  getDeletedError: (name: string): string => `Failed to delete ${name}`,
  getUnarchivedVersionFetchingError: (
    contentType: string,
    name: string
  ): string =>
    `Error when deleting ${contentType} "${name}". Please try again. If the issue persists, please contact support`,
  getNodeShowOrHideMessage: (name: string, disabled: boolean): string =>
    `${name} ${disabled ? 'hidden' : 'is showing'} successfully`,
  getUploadDescriptorsSuccess: (count: number): string =>
    `${count} components have been successfully added`,
  getUploadDescriptorsError: (count: number): string =>
    `${count} descriptor(s) failed to upload`,
  getEmptyAttributeMessage: (attr: string): string =>
    `"${attr}" value is empty`,
  getInvalidAttributeMessage: (attr: string): string =>
    `"${attr}" is not valid`,
  getInvalidBooleanMessage: (key: string): string => `${key} must be a boolean`,
  getMenuCopyError: (name: string): string =>
    `${name} already exists. Menu names must be unique.`,
  SUCCESS_IMAGE_UPDATE: 'Image was edited successfully',
  SUCCESS_IMAGE_CREATE: 'Image was created successfully',
  ERROR_IMAGE_UPDATE: 'Error while editing image',
  ERROR_IMAGE_ADD: 'Error while adding image',
  ERROR_SCHEDULED: 'Error while scheduling',
  ERROR_ARCHIVE_UPDATE: 'Error while updating archive status',
  ERROR_CREATING_GE: 'Error while creating global element',
  ERROR_SAVE_AS_DRAFT: 'Save as draft failure',
  ERROR_PUBLISH: 'Publish failure',
  ERROR_ROOT_MENU_RENAME: 'Failed to rename the root menu',
  ERROR_DESCRIPTOR_MISMATCH: `Some components unavailable due to descriptor file mismatch. Please contact Fabric Support`,
  ERROR_DESCRIPTOR_UPLOAD: 'Error while uploading descriptors',
  ERROR_GET_DESCRIPTORS: 'Error while loading descriptors',
  ERROR_CREATING_PAGE_VERSION: 'Error while creating page version',
  ERROR_SEARCH: 'Error while searching. Please try again',
  NO_PAGE_TYPES: 'No page types available',
  NO_GLOBAL_ELEMENTS:
    '`No global element components have been defined, please add component descriptors`',
  UNABLE_TO_DELETE_PAGE:
    'You cannot delete this page, there are unarchived versions.',
  DELETE_PAGE_ALERT:
    'Deleting this page will delete all its versions too. You cannot undo this action.',
  EMPTY_NODE_NAME_ERROR: 'Please enter a name to save node',
  EMPTY_CHANNEL_URL_ERROR: `Preview unavailable, channel's URL is not provided. Please contact Fabric Support`,
  DESCRIPTORS_UPLOAD_START: 'One moment while we process your uploads..',
  ERROR_OCCURRED: `An error has occurred. If the issue persists, please contact support.`,
  ERROR_FETCHING_PAGE:
    'Error fetching page, try again. If the issue persists, please contact fabric support',
  ERROR_SPECIAL_CHARACTERS: `Special characters {"\\", "/", "?", ":", "@", "=", "#", "[", "]" or "&"} or an empty string are not allowed in content names.`,
  ERROR_FETCHING_LOCALES:
    'Error fetching locales, please try again. If the issue persists, contact fabric support.',
}

export const DEFAULT_TEXTAREA_VALUES = {
  LIMIT: 400,
  ROWS: 2,
}

export const DETAILS_TABLE_LABEL = {
  STATUS: 'Status',
  SCHEDULED: 'Scheduled',
  UPDATED: 'Last update',
  NAME: 'Name',
  CHANNELS: 'Channels',
  I18N_LOCALES: 'Locales',
}

export const DEFAULT_PAGINATION = {
  CURRENT_PAGE: 1,
  ITEMS_PER_PAGE: 20,
  TOTAL_ITEMS: 20,
  OFFSET: 0,
}

export const EDITOR_PUBLISH_MESSAGE = {
  GLOBAL_ELEMENT: 'Global element version will go live immediately.',
  PAGE: 'Page version will go live immediately.',
  BROWSE_MENU: 'BrowseMenu will go live immediately.',
  MENU: 'Menu will go live immediately',
}

export const IMAGE_UPLOAD_MAX_SIZE = 2000000 // 2mb
const megaByte = 1000000

export const VALIDATION_ERRORS = {
  getInvalidMediaType: (name = 'File'): string =>
    `${name} is not valid media file`,
  getInvalidMediaFileSize: (maxSize: number = IMAGE_UPLOAD_MAX_SIZE): string =>
    `File size exceeds max file size: ${maxSize / megaByte}MB`,
  EMPTY_NAME: 'Please enter a name.',
  EMPTY_PAGE_NAME: 'Please enter a page name.',
  EMPTY_NEW_PAGE_NAME: 'Please enter a new page name.',
  EMPTY_NEW_GE_NAME: 'Please enter a new global element name.',
  EMPTY_URL: 'Please enter a url value.',
  EMPTY_VERSION_NAME:
    'Version name field is required. Please provide a version name.',
  EMPTY_PAGE_TYPE_NAME: 'Please enter a page type name.',
  EMPTY_PAGE_TYPE_URL: 'Please enter a url prefix.',
  EMPTY_LABEL: 'Please enter a label.',
  EMPTY_PATH: 'Please enter a path.',
  EMPTY_QUERY_PARAMS: 'Please enter a query parameters.',
  MIN_LOCALE_REQUIRED: 'At least one locale is required.',
  CONFLICT_VERSION_NAME:
    'Version name already exists. Version names must be unique.',
  CONFLICT_GE_NAME: `Global element already exists. Global element name needs to be unique.`,
  UPLOAD_TOO_MANY_FILES: 'Upload file must be a single file',
  IS_PAST_DATE_ERROR_MESSAGE:
    'Scheduled time is in the past, please select a time in the future',
  NO_START_DATE_ERROR_MESSAGE: 'Please enter a start date',
  NO_START_TIME_ERROR_MESSAGE: 'Please enter a start time',
  INVALID_JSON: 'Descriptors must be a valid JSON file',
  SEO_TITLE_LIMIT: 'SEO Title must be at most 91 characters',
}

export const SCHEDULE_OPTIONS = {
  SAVE_AS_DRAFT: 'Save as draft',
  SCHEDULE: SCHEDULE_LABEL,
}

export const ITEM_CONTEXT_MENU_OPTIONS = {
  EDIT_DETAILS: 'Edit details',
  MAKE_A_COPY: MAKE_A_COPY_LABEL,
  NEW_VERSION: 'New version',
  ARCHIVE: 'Archive',
  UNARCHIVE: 'Unarchive',
  ACTIVATE: 'Activate',
  DEACTIVATE: 'Deactivate',
  DELETE: 'Delete',
  EDIT: 'Edit',
}

export enum QUICK_LINK {
  VIEW_MENU = 'View menu',
  VIEW_PAGE = 'View page',
  VIEW_GE = 'View global element',
  VIEW_DRAFT = 'View draft',
  EDIT_DRAFT = 'Edit draft',
  RESCHEDULE = 'Reschedule',
}

export const VERSION_CONTEXT_MENU_OPTIONS = {
  EDIT: 'Edit',
  MAKE_A_COPY: MAKE_A_COPY_LABEL,
  PREVIEW: 'Preview',
  ARCHIVE: 'Archive',
  UNARCHIVE: 'Unarchive',
  DELETE: 'Delete',
}

/* Modals */
export const MODAL_SECTIONS = {
  GENERAL_DETAILS: 'General details',
  SEO_AND_METADATA: 'SEO metadata',
}

export const MODAL_ACTION = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
  COPY: 'COPY',
  DELETE: 'DELETE',
  VIEW: 'VIEW',
  SCHEDULE: 'SCHEDULE',
  REMOVE_ASSET: 'REMOVE_ASSET',
}

export const PAGE_MODAL_LABEL = {
  PAGE_TYPE: 'Page type',
  PAGE_NAME: 'Page name',
  PAGE_VERSION_NAME: 'Page version name',
  PAGE_URL: URL_LABEL,
  SEO_TITLE: 'SEO title',
  SEO_DESCRIPTION: 'SEO description',
  METADATA: 'Additional metadata',
  METADATA_NAME: 'Name',
  METADATA_CONTENT: 'Content',
  COPY_PAGE_VERSIONS: 'Page versions',
  COPY_PAGE_NAME: 'New page name',
}

export const PAGE_TYPE_MODAL_LABEL = {
  PAGE_TYPE_NAME: 'Page type name',
  ADD_PAGE_TYPE: 'Add page type',
  EDIT_PAGE_TYPE: 'Edit page type',
  PREFIX: 'Prefix',
  VIEW_PAGE_TYPE: 'View page type',
}

const GLOBAL_ELEMENT_NAME = 'Global element name'

export const EDITOR_MODAL_LABEL = {
  GE_NAME: GLOBAL_ELEMENT_NAME,
  PAGE_NAME: 'Page name',
  VERSION_NAME: 'Version name',
  URL: URL_LABEL,
  DESCRIPTION: OPTIONAL_DESCRIPTION_LABEL,
}

export const MENU_COPY_MODAL_LABEL = {
  MENU_VERSIONS: 'Menu versions',
}

export const BROWSE_MENU_MODAL_LABEL = {
  ADD_MENU: 'Create menu',
  EDIT_MENU: 'Edit menu details',
  MENU_NAME: 'Menu name',
  CHANNELS: `Select ${CHANNELS}`,
  VIEW_MENU: 'View menu details',
  COPY_MENU: MAKE_A_COPY_LABEL,
}

export const BROWSE_MENU_VERSION_MODAL_LABEL = {
  MENU_PARENT_NAME: 'Menu name',
  NEW_MENU_PARENT_NAME: 'New menu name',
  MENU_VERSION_NAME: 'Menu version name',
  ADD_MENU_VERSION: 'Add menu version',
}

export const PUBLISH_MODAL = {
  title: 'Publish',
  getContent: (contentType: CONTENT_TYPE): string => {
    switch (contentType) {
      case CONTENT_TYPE.GLOBAL_ELEMENT:
        return EDITOR_PUBLISH_MESSAGE.GLOBAL_ELEMENT
      case CONTENT_TYPE.MENU:
        return EDITOR_PUBLISH_MESSAGE.BROWSE_MENU
      case CONTENT_TYPE.PAGE:
      default:
        return EDITOR_PUBLISH_MESSAGE.PAGE
    }
  },
  submitText: 'Publish',
  cancelText: CANCEL_TEXT,
}

/* Pages constants */
export const TABS_PAGES = {
  ALL_PAGES: 'All pages',
  PAGE_TYPES: 'Page types',
  ARCHIVED: 'Archived',
}

const generateEmptyTableMessage = ({
  contentType,
  isArchived,
}: {
  contentType: string
  isArchived: boolean
}) => {
  return `You currently don't have any${
    isArchived ? ' archived' : ''
  } ${contentType}`
}

export const PAGES_TABLE_EMPTY_MESSAGE = generateEmptyTableMessage({
  contentType: 'pages',
  isArchived: false,
})
export const PAGES_TABLE_EMPTY_ARCHIVE_MESSAGE = generateEmptyTableMessage({
  contentType: 'pages',
  isArchived: true,
})

export const GE_TABLE_EMPTY_MESSAGE = generateEmptyTableMessage({
  contentType: 'global elements',
  isArchived: false,
})
export const GE_TABLE_EMPTY_ARCHIVE_MESSAGE = generateEmptyTableMessage({
  contentType: 'global elements',
  isArchived: true,
})

export const MENU_TABLE_EMPTY_MESSAGE = generateEmptyTableMessage({
  contentType: 'menus',
  isArchived: false,
})

export const EMPTY_PAGES_SCREEN = {
  primaryText: 'You have no pages',
  secondaryText: `You can start by creating a new page. If you'd like to create multiple versions, first create a page`,
  buttonText: 'Add page',
}

export const EMPTY_CONFIG_SCREEN = {
  primaryText: 'XM is not configured',
  secondaryText:
    'You must configure XM before you begin. Please contact the fabric support team for configuration assistance.',
}

export const PAGE_TYPE_DETAILS_TABLE_LABEL = {
  PAGE_TYPE_NAME: 'Page types',
  PAGES_COUNT: '# of pages',
  PREFIX: 'Prefix',
  UPDATED: 'Last update',
}

export const DELETE_PAGE_TYPE_MODAL = {
  title: 'Delete page type',
  content:
    'Only page types that do not have pages assigned can be deleted. Please note that this action cannot be undone.',
  submitText: 'Confirm delete',
  cancelText: CANCEL_TEXT,
}

export const VERSION_DELETE_MODAL = {
  title: 'Deleting version',
  submitText: 'Delete',
  cancelText: CANCEL_TEXT,
  getDefaultContent: (name: string): string =>
    `Are you sure you would like to delete this ${name} version?`,
  getLastVersionContent: (name: string): string =>
    `Deleting the final version will also delete the ${name}`,
  liveStatusContent: `Deleted Live version can't be visited by your users`,
}

export const VERSION_UNARCHIVE_MODAL = {
  getTitle: (name: string): string => `Unarchive a version of ${name}`,
  content: 'Unarchiving a version will enable it to online visitors',
  submitText: 'Unarchive',
}

export const VERSION_ARCHIVE_MODAL = {
  getTitle: (name: string): string => `Archive a ${name} version`,
  getContent: (name: string): string =>
    `Archiving a ${name} will disable it from online visitors.`,
  submitText: 'Archive',
}

export const PAGE_ARCHIVE_MODAL = {
  getTitle: (name: string): string => `Archive "${name}"`,
  content: `Archiving a page will disable it from online visitors`,
  submitText: 'Archive',
}
export const PAGE_UNARCHIVE_MODAL = {
  getTitle: (name: string): string => `Unarchive "${name}"`,
  content: `Unarchiving a version will enable it to online visitors`,
  submitText: 'Unarchive',
}
export const PAGE_ACTIVATE_MODAL = {
  getTitle: (name: string): string => `Activate page "${name}"`,
  content: `Activating a page will also activate all its versions`,
  submitText: 'Activate',
}

export const PAGE_DEACTIVATE_MODAL = {
  getTitle: (name: string): string => `Deactivate page "${name}"`,
  content: `Deactivating a page will also deactivate live versions and can't be accessed by online visitors.`,
  submitText: 'Deactivate',
}

export const VERSION = {
  STATUS: {
    LIVE: 'live',
    OTHER: 'other',
  },
  IS_LAST: {
    LAST: 'last',
    NOT_LAST: 'notLast',
  },
}

/* Global Elements constants */
const GLOBAL_ELEMENTS_TAB_ID = 'gc-list-tab'
const ARCHIVED_GLOBAL_ELEMENTS_TAB_ID = 'gc-archived-list-tab'

export const TABS_GE = {
  GLOBAL_ELEMENTS: {
    id: GLOBAL_ELEMENTS_TAB_ID,
    value: 'All global elements',
  },
  ARCHIVED: {
    id: ARCHIVED_GLOBAL_ELEMENTS_TAB_ID,
    value: 'Archived',
  },
  ENUM: {
    0: GLOBAL_ELEMENTS_TAB_ID,
    1: ARCHIVED_GLOBAL_ELEMENTS_TAB_ID,
  },
}

export const GE_CREATE_MODAL = {
  title: 'Create global element',
  name: GLOBAL_ELEMENT_NAME,
  versionName: 'Global element version name',
  geVersions: 'Global element versions',
  newCopiedName: 'Global element new name',
  submitText: SAVE_TEXT,
}

export const GE_EMPTY_SCREEN = {
  primaryText: 'You have no global elements',
  secondaryText: 'Start by adding a Global element',
  footerText: 'Learn more about global elements in our',
  footerLink: 'Knowledge base',
  buttonText: '+ Create global element',
}

/* Browse Menus constants */
export const EMPTY_BROWSE_MENU_MODAL = {
  BUTTON: '+ Create browse menu',
  PRIMARY_TEXT: 'You have no browse menus',
  SECONDARY_TEXT: 'Start by creating a new browse menu below',
  FOOTER_TEXT: 'Learn more about browse menus in our',
  FOOTER_URL: 'Knowledge base',
}

export const DELETE_MENU_MODAL = {
  title: 'Are you sure you want to delete this browse menu?',
  content: `Deleting this browse menu will remove the content for all pages, including live and scheduled.`,
  submitText: 'Delete browse menu',
}

export const DELETE_GE_MODAL = {
  title: 'Are you sure you want to delete this global element?',
  content: `Deleting this global element will delete all versions, including live and scheduled.`,
  submitText: 'Delete',
}

export const CREATE_GE_VERSION = {
  title: 'Create a New Global Element Version',
  content: ` `,
  submitText: 'Create Global Element version',
}

export const CREATE_NEW_VERSION = {
  title: 'Create a New Menu Version',
  content: ` `,
  submitText: 'Create menu version',
}

export const EDIT_MENU_VERSION = {
  title: 'Edit menu Version',
  content: ` `,
  submitText: 'Edit menu version',
}

export const DELETE_MENU_VERSION = {
  title: 'Are you sure you want to delete this menu version?',
  content: ` `,
  submitText: 'Delete menu version',
}

export const VERSION_CONFLICT_MODAL = {
  title: 'Menu data conflict',
  content:
    'The menu version you are viewing is outdated, another user has made an update. Press Refresh to update the menu data',
  submitText: 'Refresh',
  cancelText: CANCEL_TEXT,
}

export const BROWSE_MENU_ACTIONS = {
  EDIT: 'edit',
  DETAILS: 'details',
  DUPLICATE: 'duplicate',
  RENAME: 'rename',
  DELETE: 'delete',
  CREATE_VERSION: 'create_version',
  EDIT_VERSION: 'edit_version',
  DETAILS_VERSION: 'details_version',
  DUPLICATE_VERSION: 'duplicate_version',
  RENAME_VERSION: 'rename_version',
  DELETE_VERSION: 'delete_version',
}

export const BROWSE_MENU_CONTEXT_MENU_ITEMS = {
  EDIT: { name: 'Edit', type: BROWSE_MENU_ACTIONS.EDIT },
  DETAILS: { name: 'Details', type: BROWSE_MENU_ACTIONS.DETAILS },
  DUPLICATE: { name: 'Duplicate', type: BROWSE_MENU_ACTIONS.DUPLICATE },
  RENAME: { name: 'Rename', type: BROWSE_MENU_ACTIONS.RENAME },
  DELETE: { name: 'Delete', type: BROWSE_MENU_ACTIONS.DELETE },
}

export const BROWSE_MENU_CONTEXT_MENU_VERSION_ITEMS = {
  EDIT: { name: 'Edit', type: BROWSE_MENU_ACTIONS.EDIT_VERSION },
  DETAILS: { name: 'Details', type: BROWSE_MENU_ACTIONS.DETAILS_VERSION },
  DUPLICATE: { name: 'Duplicate', type: BROWSE_MENU_ACTIONS.DUPLICATE_VERSION },
  RENAME: { name: 'Rename', type: BROWSE_MENU_ACTIONS.RENAME_VERSION },
  DELETE: { name: 'Delete', type: BROWSE_MENU_ACTIONS.DELETE_VERSION },
}

/* Browse Menu Tree constants */
export const ADD_NODE_TOOLTIP = 'Add a node'
export const NODE_INPUT_CLASSNAME = 'menu-node-input'
export const ADD_LOCALIZED_TITLE = 'Add localized titles'

export const BROWSE_MENU_TREE_DEFAULT_NAME = {
  node: 'Enter node name',
  getLevel: (depth: number): string => `LEVEL ${depth}`,
  section: 'Enter section name',
  subnode: 'Enter sub-node name',
  placeholder: 'placeholder',
  emptyTitle: '',
}

export const BROWSE_MENU_TREE_CONTEXT_SECTION = {
  name: 'Add new section',
  type: 'add-new-section',
}

export const MANAGE_NODE_SIDEBAR_LABEL = {
  getTitle: (name: string): string => `Manage ${name}`,
  CTA: {
    ADD_ATTRIBUTE: '+ Add attribute',
    IMPORT_HIERARCHY: '+ Import hierarchy',
    MORE_IMAGES: 'More images',
    LESS_IMAGES: 'Less images',
  },
  ROOT_NODE: 'Root node',
  LINK: 'Link',
  IMPORTED_HIERARCHY: 'Imported hierarchy',
  IMAGES: 'Images',
  ATTRIBUTES: 'Other attributes',
  ERRORS: {
    NO_SPACES: 'No spaces allowed',
    INVALID_CHARS:
      'Special characters {"[", "]", "{", "}", "|", "%, "~", "#", "<" or ">"} are not allowed in URLs',
  },
  NODE_TITLE: 'Node title',
}

export const BROWSE_MENU_TREE_SCHEDULE_MODAL = {
  TITLE: 'Schedule browse menu',
  DATE: 'Select schedule date',
  TIME: 'Select schedule time',
}

export const BROWSE_MENU_TREE_IMAGE_UPLOAD = {
  HEADER_TITLE: 'New upload',
  UPLOAD_BOX_TEXT: 'Drag and drop your file anywhere',
  UPLOAD_CTA: 'Upload',
  FILENAME: 'File name',
  ALT_TEXT: ALT_TEXT_LABEL,
  RECENTLY_ADDED: 'Recently added',
}

export const BROWSE_MENU_TREE_IMAGE_URL = {
  HEADER_TITLE: 'New URL',
  URL: URL_LABEL,
  ALT_TEXT: ALT_TEXT_LABEL,
}

export const BROWSE_MENU_TREE_CONTEXT_MENU_ITEMS = {
  getManageTitle: (name: string): string => `Manage ${name}`,
  RENAME: 'Rename',
  REMOVE: 'Remove',
  SHOW: 'Show',
  HIDE: 'Hide',
}

export const BROWSE_MENU_TREE_DELETE_MODAL = {
  getTitle: (type: string): string =>
    `Are you sure you want to remove the ${type}?`,
  getContent: (type: string): string =>
    `Removing this ${type} will stop showing the content for all pages, including live and scheduled.`,
  getSubmitText: (type: string): string => `Remove ${type}`,
}

/* Editor constants */
export const NO_CHANNELS_ASSIGNED_TOOLTIP = 'No channels assigned'
export const MAXIMUM_GE_ADDED_TOOLTIP = `You cannot add more than one component to a Global Element at this time`
export const RBAC_LIMITED_EDIT_ACCESS_TOOLTIP = `You do not have the necessary permissions to edit content in XM`
export const RBAC_LIMITED_EDIT_ACCESS_TEXT = 'Locked'
export const EDITOR_OPTIONS = {
  VIEW: VIEW_VERSION_DETAILS_LABEL,
  EDIT: EDIT_VERSION_DETAILS_LABEL,
  CREATE: CREATE_NEW_VERSION_LABEL,
  DUPLICATE: MAKE_A_COPY_LABEL,
  SCHEDULE: SCHEDULE_LABEL,
}

export const EDITOR_DELETE_MODAL = {
  getTitle: (name: string): string => `Deleting ${name}`,
  content: 'Are you sure you would like to delete this component?',
  localesContent: 'It will be removed from all locales.',
  cancelText: CANCEL_TEXT,
  submitText: 'Delete',
}

export const editorPreviewType = 'EDITOR'
export const gcEditorPreviewType = 'GLOBAL_EDITOR'

/* v1 Menu constants */
export const MENU_HEADER = {
  CATEGORY: 'CATEGORY',
  DEPARTMENT: 'DEPARTMENT',
}

export const MENU_CONTEXT_OPTIONS = {
  RENAME: 'Rename',
  EDIT_ITEM: 'Edit item',
  DELETE: 'Delete',
  DISABLE: 'Disable',
  ENABLE: 'Enable',
}

export const MENU_EDIT_MODAL = {
  HEADER: 'Edit item',
  ITEM_NAME: 'Item name',
  ITEM_PARENT: 'Parent',
  URL: 'URL path',
  QUERY_PARAM: 'Add query parameter',
}

export const MENU_STATUS = {
  LOADING: 'LOADING',
  RESOLVED: 'RESOLVED',
  REJECTED: 'REJECTED',
}

export const NAVIGATION = {
  PAGES: 'Pages',
  GLOBAL_ELEMENTS: 'Global elements',
  MENU: 'Menu',
  SETTINGS: 'Settings',
}

export const MENU_DELETE_MODAL = {
  getTitle: (level: number, name: string): string =>
    `Delete ${level === 0 ? 'department' : 'category'} "${name}"`,
  content:
    'Deleting an item will delete it from the website and cannot be accessed by online visitors.',
  cancelText: CANCEL_TEXT,
  submitText: 'Delete',
}

/* Server Error Constants */
export const SERVER_ERROR_LABEL = {
  buttonText: 'Go back',
  primaryText: 'Error',
  secondaryText:
    'There was a problem loading XM. If the issue persists, please contact support@fabric.inc',
}

/* 404 Page Constants */
export const PAGE_NOT_FOUND_HEADING = '404 - Page Not Found'
export const PAGE_NOT_FOUND_CONTENT = `Please try reloading a page. If the issue persists, please contact support@fabric.inc`

/* Settings constants */
export const SETTINGS = {
  HEADER: {
    ADD_COMPONENTS: 'Add components',
    ADD_COMPONENT: 'Add component',
    BULK_UPLOAD: 'Bulk upload',
    XM_SETTINGS: 'XM Settings',
    DEVELOPERS: 'Developers',
    SEARCH: 'Search',
    INPUT_PLACEHOLDER: 'Search components',
    ACCEPT_FILE_TYPES: ['application/json'],
  },
  NO_COMPONENTS: {
    SUBTITLE: 'YOU HAVE NO COMPONENTS ADDED',
    TITLE: 'Start by uploading the descriptor JSON file',
  },
  CONFIRM_UPLOAD_DIALOG: {
    HEADING: 'Update component',
    TO_UPLOAD: 'components will be added.',
    TO_UPDATE:
      "components already exist in this location. Do you want to replace these with the one you're moving?",
    SUBMIT: 'Yes, replace',
    CANCEL: CANCEL_TEXT,
    PADDING: '32px 24px 24px 32px',
  },
  CONFIRM_DELETE_DIALOG: {
    HEADING: 'Delete component',
    SUMMARY:
      'Are you sure you want to delete the selected component? You will not be able to restore it.',
    SUBMIT: 'Yes, delete.',
  },
  DUPLICATE_DESCRIPTORS_DIALOG: {
    HEADING: 'Duplicate IDs found',
    SUMMARY: 'The following IDs are duplicated.',
    CALL_TO_ACTION:
      'Please ensure that every descriptor has a unique ID and try again.',
    NOTE: '*NOTE: Your descriptor(s) will not be processed at this time.',
  },
  CONTEXT: {
    VIEW: 'View',
    EDIT: 'Edit',
    DELETE: 'Delete',
  },
  COLUMNS: {
    NAME: {
      ACCESSOR: 'label',
      TITLE: 'Component name',
    },
  },
  UPLOAD_ERROR: {
    FORMAT: {
      KIND: 'invalidFormat',
      CONTEXT: {
        TITLE: 'Invalid data format',
        DESCRIPTION: 'XM component descriptors must be an array of objects.',
        FOOTER: 'Please ensure your descriptors are in the correct format.',
      },
    },
    REQUIRED_ATTR: {
      KIND: 'requiredAttribute',
      CONTEXT: {
        TITLE: 'Missing required attributes',
        DESCRIPTION: 'Some components do not have the following attributes.',
        FOOTER:
          'Please ensure that every component in your file has the required attributes and try again.',
      },
    },
    INVALID_ATTR: {
      KIND: 'invalidAttribute',
      CONTEXT: {
        TITLE: 'Invalid attributes found',
        DESCRIPTION: 'Some components have the following invalid attributes.',
        FOOTER:
          'Please ensure each component only has valid attributes and try again.',
      },
    },
    INVALID_ATTR_TYPE: {
      KIND: 'invalidAttributeType',
      CONTEXT: {
        TITLE: 'Invalid attribute type found',
        DESCRIPTION:
          'Some components have the following invalid attributes types.',
        FOOTER:
          'Please ensure each component only has valid attributes types and try again.',
      },
    },
    MAX_ATTRIBUTES_DEPTH: {
      KIND: 'maxAttributesDepth',
      CONTEXT: {
        TITLE: 'Maximum nested levels exceeded',
        DESCRIPTION:
          'The following component(s) have too many nested levels. Components can have a maximum of 8 nested levels.',
        FOOTER: 'Please adjust the component(s) to have fewer nested levels',
      },
    },
  },
  ATTRIBUTES: {
    REQUIRED: ['id', 'type', 'label', 'attributes'],
    ALLOWED: [ALLOW_NESTED_COMPONENTS, 'description'],
    BOOLEAN: [ALLOW_NESTED_COMPONENTS],
  },
  VALID_TYPES: [
    'Array',
    'Shape',
    'Boolean',
    'Image',
    'String',
    'Number',
    'Enum',
    'MultiLineString',
    'RichText',
  ],
}

export const FORM_CONSTANTS = {
  VERSION_DETAILS_DIALOG: {
    pageName: 'Page name',
    versionName: 'Version name',
    url: 'URL',
    description: 'Description (optional)',
    geName: GLOBAL_ELEMENT_NAME,
    channels: 'Channels',
    locales: 'Locales associated',
  },
  ACTIONS: {
    submit: 'Submit',
    save: 'Save',
  },
}

export const DESCRIPTOR_DETAILS = {
  HEADING: {
    COMPONENT_DETAILS: 'Component details',
    COMPONENT_ATTRIBUTES: 'Component attributes',
    FORMAT: 'Format type',
    TYPE: 'Type (string)',
    ID: 'Id (string)',
    LABEL: 'Component label',
    ALLOW_NESTING: 'Allow nested components (boolean)',
    DESCRIPTION: 'Description',
    ATTRIBUTES: 'Attributes',
  },
  INPUT_TYPES: {
    TEXT: 'text',
    DROPDOWN: 'dropdown',
    TEXTAREA: 'textarea',
  },
  CTA: {
    EDIT: 'Edit',
    SAVE: SAVE_TEXT,
    CANCEL: CANCEL_TEXT,
    DELETE: 'Delete',
  },
  INDENTATION: 2,
  MESSAGES: {
    SUCCESS: 'Component has been successfully updated',
    ERROR: 'Error updating component, if issue persists please contact support',
    INVALID_ID: 'Invalid ID, spaces not allowed',
    MISSING_ID: 'Please enter an Id',
    MISSING_LABEL: 'Please enter a label.',
    JSON_PARSE_ERROR:
      'Error: Attributes are not in JSON format. Please fix your input and try again.',
  },
  ALLOW_NESTING_OPTIONS: [{ name: 'true' }, { name: 'false' }],
}

/* Feature Flag Names */
export const FF_NAMES = {
  unleashFFs: {
    USE_HYDRA: 'fe_use_hydra',
    USE_DAM: 'fe_use_dam',
    USE_GRAPHQL: 'fe_use_graphql',
    ALLOW_NESTING_IN_ARRAYS: 'fe_allow_nested_components_in_array',
    I18N: 'fe_i18n',
    NEW_BROWSE_MENU: 'fe_new_menu',
    USE_RBAC: 'fe_rbac',
    NEW_PAGES_LIST: 'fe_new_pages_list',
    NEW_GE_LIST: 'fe_new_ge_list',
    NAVIGATION_NAME_CHANGE: 'navigation-name-change',
    DS4: 'fe_ds4',
  },
  cookieFFs: {},
}

export const UNLEASH_CONFIG_FAILOVER = {
  URL: 'http:failover',
  CLIENTKEYS: 'failover_key',
}

export const ACTIONS_THAT_REQUIRE_REFETCH = {
  CREATE_PAGE: 'CREATE_PAGE',
  MAKE_A_PAGE_COPY: 'MAKE_A_PAGE_COPY',
  ARCHIVE_PAGE: 'ARCHIVE_PAGE',
  UNARCHIVE_PAGE: 'UNARCHIVE_PAGE',
  ARCHIVE_PAGE_VERSION: 'ARCHIVE_PAGE_VERSION',
  UNARCHIVE_PAGE_VERSION: 'UNARCHIVE_PAGE_VERSION',
}

/* DAM Asset Modal */
export const DEFAULT_SECTIONS_COUNT = 15

export const ADD_ASSET_CTA = '+ Add asset'

const DAM_OPERATION_TYPES = {
  VIEW_ASSET_DETAIL: 'VIEW_ASSET_DETAIL',
  UPLOAD_FROM_DAM_LIBRARY: 'UPLOAD_FROM_DAM_LIBRARY',
}

export const SUPPORTED_OPERATIONS = {
  VIEW_ASSET: {
    title: 'View Image Detail',
    operationType: DAM_OPERATION_TYPES.VIEW_ASSET_DETAIL,
  },
  UPLOAD_FROM_DAM: {
    title: 'Upload from Brandfolder Library',
    operationType: DAM_OPERATION_TYPES.UPLOAD_FROM_DAM_LIBRARY,
  },
}

export const ADD_DAM_ASSET_MODAL = {
  cancelText: CANCEL_TEXT,
  saveText: SAVE_TEXT,
  noLabelFilterText: 'All',
}

export const DAM_ERRORS = {
  misconfigured:
    'Digital Asset Manager is not configured, please contact fabric support',
}

export const COMPONENT_IMAGE_TYPE = {
  CONFIRMATION: {
    heading: 'Remove Image',
    text: 'Are you sure you would like to remove this image?',
    cancelText: CANCEL_TEXT,
    submitText: 'OK',
  },
  EDIT: 'Edit',
  DELETE: 'Delete',
}

export const LOCALES = {
  localesMultiSelectLabel: 'Locales associated',
  localesSeoFieldLabel: 'Associate a locale',
}
export const SEO_TITLE_MAX_LENGTH = 91

export const USER_ACCESS_SPLASH_PAGE = {
  primaryText: "You don't have permission to view this",
  secondaryText: 'PLEASE REQUEST YOUR ADMIN FOR ACCESS',
}
