import Breadcrumb, { BreadcrumbItem } from 'components/Breadcrumb'
import React, { memo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@teamfabric/copilot-ui'
import isEqual from 'react-fast-compare'
import { noop } from 'lodash'
import produce from 'immer'

const EditingPanelBreadcrumb = props => {
  const [tooltipText, setTooltipText] = useState('')

  const handleOnClickSections = useCallback(() => {
    if (!props.selectedComponent) return

    const nextComponent = produce(props.selectedComponent, draftState => {
      draftState.isSelected = false
    })

    props.handleOnUpdateComponent({
      key: props.selectedComponent.key,
      nextComponent,
    })
  }, [props])

  return (
    <>
      <Tooltip
        size='small'
        data-testid='breadcrumb-tooltip'
        showTooltip={!!tooltipText.length}
      >
        {tooltipText}
      </Tooltip>
      <Breadcrumb>
        <BreadcrumbItem onClick={handleOnClickSections}>
          All components
        </BreadcrumbItem>
        {props.selectedComponent?.label && (
          <BreadcrumbItem
            isActive
            className='active-component-name'
            onMouseEnter={() => setTooltipText(props.selectedComponent.label)}
            onMouseLeave={() => setTooltipText('')}
          >
            {props.selectedComponent.label}
          </BreadcrumbItem>
        )}
      </Breadcrumb>
    </>
  )
}

EditingPanelBreadcrumb.defaultProps = {
  selectedComponent: {},
  handleOnUpdateComponent: noop,
}

EditingPanelBreadcrumb.propTypes = {
  selectedComponent: PropTypes.object.isRequired,
  handleOnUpdateComponent: PropTypes.func.isRequired,
}

export default memo(EditingPanelBreadcrumb, isEqual)
