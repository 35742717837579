import React, { memo } from 'react'

const IconAlignCenter = props => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='8'
      height='5'
      viewBox='0 0 8 5'
      {...props}
    >
      <g fill='none' fillRule='evenodd' stroke='#FFF' strokeLinecap='square'>
        <path
          d='M.5.5L7.5.5M2.5 2.5L5.5 2.5M.5 4.5L7.5 4.5'
          transform='matrix(-1 0 0 1 8 0)'
        />
      </g>
    </svg>
  )
}

export default memo(IconAlignCenter)
