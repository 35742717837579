import React, { useEffect, useState } from 'react'
import { StyledColumns, StyledMenu, StyledScrollContainer } from './styles'
import { Modal } from '@teamfabric/copilot-ui'
import PropTypes from 'prop-types'

// eslint-disable-next-line sonarjs/cognitive-complexity
const ModalSections = ({ isVisible, onClose, ...props }) => {
  const { modalSections, title, selectedSection } = props

  const [modalSection, setModalSection] = useState(
    selectedSection || Object.keys(modalSections)[0]
  )
  useEffect(() => {
    setModalSection(selectedSection)
  }, [selectedSection])

  const renderModalContent = () => {
    return (
      <>
        <StyledColumns.Grid>
          <StyledColumns.MenuSection>
            <StyledMenu.Title>
              <p role='heading' data-testid='modal-title'>
                {title}
              </p>
            </StyledMenu.Title>
            {Object.values(modalSections).map(section => {
              return (
                <StyledMenu.MenuItem
                  key={section.key}
                  active={modalSection === section.key}
                  onClick={() => {
                    setModalSection(section.key)
                  }}
                  data-testid={`modal-section-${section.key}`}
                >
                  {section.title}
                </StyledMenu.MenuItem>
              )
            })}
          </StyledColumns.MenuSection>
          <StyledColumns.ContentSection>
            <StyledScrollContainer>
              {Object.values(modalSections).map(section => (
                <div
                  key={section.key}
                  style={{
                    display: section.key === modalSection ? 'initial' : 'none',
                  }}
                >
                  {section.render()}
                </div>
              ))}
            </StyledScrollContainer>
          </StyledColumns.ContentSection>
        </StyledColumns.Grid>
      </>
    )
  }
  return (
    <Modal
      isVisible={isVisible}
      onBackdropClick={onClose}
      onClose={onClose}
      padding='0px'
      showCloseButton={false}
      headerText=''
      footerButtons={[]}
      headerButtons={[]}
      size='medium'
      render={renderModalContent}
    />
  )
}

ModalSections.defaultProps = {
  title: 'Modal',
  selectedSection: null,
}

ModalSections.propTypes = {
  title: PropTypes.string.isRequired,
  selectedSection: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  modalSections: PropTypes.shape({
    'general-details': PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      render: PropTypes.func,
    }),
  }),
}

export default ModalSections
