import { Channel, ChannelsAction } from './types'
import { INIT_CHANNELS_SUCCESS } from './actions'

const initialState = {
  channels: null,
}

const reducer = (
  state = initialState,
  action: ChannelsAction
): { channels: Channel[] } => {
  if (action.type === INIT_CHANNELS_SUCCESS) {
    return {
      ...state,
      channels: action.channels,
    }
  }
  return state
}

export default reducer
