import React, { memo } from 'react'
import {
  StyledHeader,
  StyledHeaderSpaceHolder,
  StyledList,
  StyledListItem,
  StyledMenuContent,
  StyledWrapper,
} from './styles'
import { find, isArray, isEmpty } from 'lodash'
import IconFilledArrowDown from 'assets/icons/IconFilledArrowDown'
import Menu from 'components/Menu'
import PropTypes from 'prop-types'
import { theme as themeUi } from '@teamfabric/copilot-ui'

const Header = props => {
  const { list, pageTitle } = props

  if (!isArray(list) && !pageTitle) {
    return null
  }

  const selectedList = find(list, { isSelected: true })

  if (isEmpty(selectedList) && !pageTitle) {
    return null
  }

  return (
    <>
      <StyledHeader data-testid='header'>
        <StyledWrapper>
          <div className='title' data-testid='title'>
            {selectedList ? selectedList.label : pageTitle}
            {isArray(list) > 0 && (
              <Menu align='right' className='header-menu'>
                <Menu.Icon data-testid='menu-icon'>
                  <IconFilledArrowDown
                    width={12}
                    height={12}
                    fill={themeUi.palette.brand.primary.gray}
                  />
                </Menu.Icon>
                <Menu.Content>
                  <StyledMenuContent>
                    <StyledList data-testid='list'>
                      {list.map(item => {
                        return (
                          <StyledListItem
                            key={item.label}
                            onClick={item.onClick}
                            data-testid='list-item'
                          >
                            {item.label}
                          </StyledListItem>
                        )
                      })}
                    </StyledList>
                  </StyledMenuContent>
                </Menu.Content>
              </Menu>
            )}
          </div>
        </StyledWrapper>
      </StyledHeader>
      <StyledHeaderSpaceHolder></StyledHeaderSpaceHolder>
    </>
  )
}

Header.defaultProps = {
  pageTitle: '',
}

Header.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      isSelected: PropTypes.bool.isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  pageTitle: PropTypes.node,
}

export default memo(Header)
