import {
  ADD_ASSET_CTA,
  MANAGE_NODE_SIDEBAR_LABEL,
  MODAL_ACTION,
} from 'src/constants'
import { AssetSectionProps, ImageProps } from './types'
import React, { FunctionComponent, useEffect, useState } from 'react'
import AssetGridImage from './AssetGridImage'
import { AssetModalOnChangeProps } from 'components/type-components/ImageType/DAMAssetModal/types'
import { AssetProps } from 'src/components/type-components/ImageType/DAMAssetModal/types'
import DAMAssetModal from 'components/type-components/ImageType/DAMAssetModal'
import { DEFAULT_LOCALE } from 'modules/browse-menu-tree-gql/constants'
import ExpandableCard from 'components/ExpandableCard'
import LocalizedAssetDetailsCard from './LocalizedAssetDetailsCard'
import { StyledAssetSection } from './styles'
import { getVersionStatus } from 'modules/editor/selectors'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import { useVersionBasedEditAccess } from 'hooks/useVersionBasedEditAccess'

const AssetSection: FunctionComponent<AssetSectionProps> = props => {
  const { locales, setCurrentModalConfig, setValues, values } = props
  const [showAssetModal, setShowAssetModal] = useState(false)
  const [selectedAsset, setSelectedAsset] = useState<AssetProps>(null)
  const isLocalizationEnabled = !isEmpty(locales)
  const menuVersionStatus = useSelector(getVersionStatus) as string
  const hasEditAccess = useVersionBasedEditAccess(menuVersionStatus)
  useEffect(() => {
    if (!values.images) {
      setValues({
        ...values,
        images: [],
      })
    }
  }, [values, setValues])

  useEffect(() => {
    if (!selectedAsset || showAssetModal) {
      return
    }

    // If there is a selected asset but we are not showing the asset modal,
    // then we are trying to remove the asset
    setCurrentModalConfig({
      visibleModalId: MODAL_ACTION.REMOVE_ASSET,
      onConfirm: removeAssetFromValues,
      onCancel: () => {
        setSelectedAsset(null)
        setCurrentModalConfig(null)
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAsset, showAssetModal])

  const addAssetToValues = (changedValues: AssetModalOnChangeProps) => {
    const { value } = changedValues

    // If there was an asset selected, that means we should edit the existing asset
    if (selectedAsset) {
      setValues(previousValues => {
        const assetToReplaceIdx = previousValues.images.findIndex(
          img => img.url === selectedAsset.url
        )
        if (!value.url) {
          // The asset was removed on the Asset Modal, so remove it
          previousValues.images.splice(assetToReplaceIdx, 1)
        } else {
          const assetToReplace = previousValues.images[assetToReplaceIdx]
          assetToReplace.url = value.url
          assetToReplace.locales = []
          assetToReplace.description = value?.description
          assetToReplace.name = value?.name
          assetToReplace.altText = { [DEFAULT_LOCALE]: value.altText }
        }
        return previousValues
      })
      return
    } else {
      // Otherwise, if no URL was selected, quit
      if (!value.url) {
        return
      }
    }

    // If there was not an asset selected, that means we should add
    const newImages = values.images.slice()

    const newImageDetails: ImageProps = {
      url: value.url,
      locales: [],
      description: value?.description,
      name: value?.name,
      altText: { [DEFAULT_LOCALE]: value.altText },
    }

    newImages.push(newImageDetails)

    setValues({
      ...values,
      images: newImages,
    })
  }

  const removeAssetFromValues = () => {
    setValues(previousValues => {
      const assetToRemoveIdx = previousValues.images.findIndex(
        img => img.url === selectedAsset.url
      )
      previousValues.images.splice(assetToRemoveIdx, 1)
      return previousValues
    })
    setCurrentModalConfig(null)
    setSelectedAsset(null)
  }

  const handleSelectAsset = (url: string) => {
    const image = values.images.find(img => img.url === url)
    setSelectedAsset({
      url,
      description: image?.description,
      name: image?.name,
      altText: image?.altText[DEFAULT_LOCALE],
    })
    setShowAssetModal(true)
  }

  return (
    <ExpandableCard heading={MANAGE_NODE_SIDEBAR_LABEL.IMAGES}>
      <StyledAssetSection>
        <div className='asset-grid-images'>
          {values?.images &&
            values.images.map(image => (
              <AssetGridImage
                key={image.url}
                {...image}
                handleSelectAsset={handleSelectAsset}
                handleRemoveAsset={url => {
                  setSelectedAsset({
                    url,
                  })
                }}
              />
            ))}
        </div>
        {hasEditAccess && (
          <div
            className='add-asset'
            data-testid='add-asset-cta'
            onClick={() => {
              setShowAssetModal(true)
            }}
          >
            {ADD_ASSET_CTA}
          </div>
        )}
      </StyledAssetSection>
      {isLocalizationEnabled && <LocalizedAssetDetailsCard {...props} />}
      <DAMAssetModal
        assetUrlsToIgnore={(values?.images ?? []).map(img => img.url)}
        visible={showAssetModal}
        onChange={(e: AssetModalOnChangeProps) => {
          addAssetToValues(e)
        }}
        value={selectedAsset}
        onClose={() => {
          setSelectedAsset(null)
          setShowAssetModal(false)
        }}
      />
    </ExpandableCard>
  )
}

export default AssetSection
