import { ALT_TEXT_LABEL, MODAL_ACTION } from 'src/constants'
import { AssetSectionProps, FORM_FIELD_NAMES, ImageProps } from './types'
import {
  IMAGE_SIZE,
  IMAGE_SIZE_PX,
  INPUT_WIDTH_PX,
  LOCALE_DROPDOWN_LABEL,
} from './constants'
import { Icon, Input, MultiSelectDropdown } from '@teamfabric/copilot-ui'
import {
  MultiSelectDropdownOnChangeProp,
  MultiSelectDropdownOptions,
} from 'src/types'
import React, { ChangeEvent, FunctionComponent, useMemo, useState } from 'react'
import {
  StyledAssetDetails,
  StyledAssetDetailsAltTextListItem,
  StyledAssetDetailsCloseIcon,
  StyledAssetDetailsExpandIcon,
  StyledAssetDetailsLocaleDropdownLabel,
  StyledAssetDetailsTile,
} from './styles'
import getMultiSelectTextChipsOptions, {
  OptionsType,
} from 'lib/getMultiSelectTextChipsOptions'
import { DEFAULT_LOCALE } from 'modules/browse-menu-tree-gql/constants'
import get from 'lodash/get'
import { getVersionStatus } from 'modules/editor/selectors'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import { useVersionBasedEditAccess } from 'hooks/useVersionBasedEditAccess'

const TEST_ID_PREFIX = 'localized-asset-details-'

const LocalizedAssetDetailsCard: FunctionComponent<AssetSectionProps> = props => {
  const images = props.values.images

  return (
    <StyledAssetDetails>
      {images.map((image, index) => (
        <LocalizedAssetDetailsTile
          key={`image-${image.url}`}
          asset={image}
          assetOrder={index}
          {...props}
        />
      ))}
    </StyledAssetDetails>
  )
}

const getActiveLocalesForAsset = (
  asset: ImageProps,
  localeOptions: MultiSelectDropdownOptions[]
) => {
  const activeLocales: Array<MultiSelectDropdownOptions> = []

  asset.locales?.forEach(localeCode => {
    const localeToShow = localeOptions.find(
      localeOption => localeOption.label === localeCode
    )

    localeToShow && activeLocales.push(localeToShow)
  })

  return activeLocales
}

const LocalizedAssetDetailsTile: FunctionComponent<
  AssetSectionProps & {
    asset: ImageProps
    assetOrder: number
  }
> = ({
  asset,
  assetOrder,
  locales: menuLocales,
  setCurrentModalConfig,
  ...formikProps
}) => {
  const { url: assetUrl } = asset
  const dropdownOptions = useMemo(
    () => getMultiSelectTextChipsOptions(menuLocales, OptionsType.LOCALES),
    [menuLocales]
  )

  const [assetLocales, setAssetLocales] = useState<
    Array<MultiSelectDropdownOptions>
  >(getActiveLocalesForAsset(asset, dropdownOptions))
  const [isExpanded, setIsExpanded] = useState(false)

  const expandIconName = isExpanded ? 'DownArrow' : 'RightArrow'
  const menuVersionStatus = useSelector(getVersionStatus) as string
  const hasEditAccess = useVersionBasedEditAccess(menuVersionStatus)
  const handleDropdownSelection = (
    selectedOptions: Array<MultiSelectDropdownOnChangeProp>
  ) => {
    formikProps.setValues(previousValues => {
      const assetToUpdate = previousValues.images[assetOrder]
      const activeLocales = selectedOptions.map(option => option.label)
      const localizedAltTextMap = {
        default: assetToUpdate?.altText[DEFAULT_LOCALE],
      } as { [key: string]: string }

      activeLocales.forEach(localeCode => {
        localizedAltTextMap[localeCode] =
          (assetToUpdate.altText?.[localeCode] as string) || ''
      })
      assetToUpdate.locales = activeLocales
      assetToUpdate.altText = localizedAltTextMap

      return previousValues
    })

    setAssetLocales(
      selectedOptions.map(option => {
        const { id, value, label } = option

        return {
          id,
          value,
          label,
        }
      })
    )
  }

  const handleRemoveAsset = () => {
    setCurrentModalConfig({
      visibleModalId: MODAL_ACTION.REMOVE_ASSET,
      onConfirm: () => {
        removeAsset()
        setCurrentModalConfig(null)
      },
      onCancel: () => {
        setCurrentModalConfig(null)
      },
    })
  }

  const removeAsset = () => {
    formikProps.setValues(previousValues => {
      previousValues.images.splice(assetOrder, 1)
      return previousValues
    })
  }

  return (
    <StyledAssetDetailsTile
      data-testid={`${TEST_ID_PREFIX}tile-${assetOrder}`}
      hasLocales={!isEmpty(assetLocales)}
    >
      <div>
        <StyledAssetDetailsExpandIcon
          onClick={() => setIsExpanded(!isExpanded)}
          data-testid={`${TEST_ID_PREFIX}expand-${assetOrder}`}
        >
          <Icon iconName={expandIconName} size={12} />
        </StyledAssetDetailsExpandIcon>
        <div>
          <img
            width={IMAGE_SIZE}
            height={IMAGE_SIZE_PX}
            src={`${assetUrl}?fit=scale&w=${IMAGE_SIZE}&h=${IMAGE_SIZE}`}
            loading='lazy'
            data-testid={`${TEST_ID_PREFIX}image-${assetOrder}`}
          />
        </div>
        <StyledAssetDetailsLocaleDropdownLabel
          data-testid={`${TEST_ID_PREFIX}dropdown-label-${assetOrder}`}
        >
          {LOCALE_DROPDOWN_LABEL}
        </StyledAssetDetailsLocaleDropdownLabel>
        {hasEditAccess && (
          <>
            <MultiSelectDropdown
              label={LOCALE_DROPDOWN_LABEL}
              width={INPUT_WIDTH_PX}
              dropdownWidth={INPUT_WIDTH_PX}
              dropdownPadding='1rem 1.5rem'
              value={assetLocales}
              onChange={handleDropdownSelection}
              dropdownOptions={dropdownOptions}
            />
            <StyledAssetDetailsCloseIcon
              data-testid={`${TEST_ID_PREFIX}delete-${assetOrder}`}
              onClick={() => handleRemoveAsset()}
            >
              <Icon iconName='Close' size={24} />
            </StyledAssetDetailsCloseIcon>
          </>
        )}
      </div>
      {isExpanded &&
        assetLocales.map(locale => {
          const localeCode = locale.label
          const fieldName = `${FORM_FIELD_NAMES.images}[${assetOrder}].altText.${localeCode}`
          const fieldValue = get(formikProps.values, fieldName) as string

          return (
            <StyledAssetDetailsAltTextListItem key={`alt-text-${locale.label}`}>
              <Input
                label={ALT_TEXT_LABEL}
                inputType='Field'
                inputProps={{
                  'name': fieldName,
                  'value': fieldValue,
                  'onChange': (e: ChangeEvent<HTMLInputElement>) => {
                    formikProps.setFieldTouched(fieldName, true, true)
                    formikProps.handleChange(e)
                  },
                  'onBlur': formikProps.handleBlur,
                  'data-testid': `${TEST_ID_PREFIX}input-${locale.label}-${assetOrder}`,
                  'disabled': !hasEditAccess,
                }}
                locale={localeCode}
                localeCode={localeCode}
              />
              <span>{localeCode}</span>
            </StyledAssetDetailsAltTextListItem>
          )
        })}
    </StyledAssetDetailsTile>
  )
}

export default LocalizedAssetDetailsCard
