import React, { useCallback, useMemo } from 'react'
import { StyledLabel, StyledWrapper } from 'components/type-components/styles'
import { StyledRow, StyledSelect } from './styles'
import Field from 'components/Field'
import PropTypes from 'prop-types'
import { VALUE_PROP_DEFAULTS } from '../constants'

const SizeType = props => {
  const { descriptor, paths, value, onChange } = props

  const handleOnChange = useCallback(
    newValue => {
      onChange({
        paths,
        value: newValue,
      })
    },
    [onChange, paths]
  )

  const name = useMemo(() => paths.join('.'), [paths])

  if (!Object.keys(descriptor).length) return null

  return (
    <StyledWrapper data-testid='size-type-wrapper'>
      <StyledLabel>{descriptor.label}</StyledLabel>
      <StyledRow>
        <Field
          id={name}
          name={name}
          type='number'
          size='small'
          value={props.value?.value ?? ''}
          onChange={e =>
            handleOnChange({ value: e.target.value, unit: value.unit })
          }
          defaultValue={descriptor.default?.value ?? ''}
          data-testid='size-type-input'
        />
        <StyledSelect
          value={props.value?.unit ?? ''}
          onChange={e =>
            handleOnChange({ value: value.value, unit: e.target.value })
          }
          data-testid='size-type-select'
          data-selected={props.value?.unit ?? ''}
        >
          <option value='auto'>auto</option>
          <option value='px'>px</option>
          <option value='%'>%</option>
          <option value='vh'>vh</option>
          <option value='vw'>vw</option>
        </StyledSelect>
      </StyledRow>
    </StyledWrapper>
  )
}

SizeType.defaultProps = {
  descriptor: {},
  value: VALUE_PROP_DEFAULTS.Size,
}

SizeType.propTypes = {
  descriptor: PropTypes.object.isRequired,
  paths: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default SizeType
