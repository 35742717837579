// 303 - 230 (input width) - 16 (input label right margin) = 57px
export const ALT_TEXT_LABEL_WIDTH_PX = '57px'
export const IMAGE_SIZE = 32
export const IMAGE_SIZE_PX = '32px'
export const INPUT_WIDTH_PX = '230px'
// 230 (input width) + 41 (locale label width) + 32 (locale label right margin) = 303px
export const INPUT_AND_LABEL_WIDTH_PX = '303px'
export const LOCALE_DROPDOWN_LABEL = 'Locale'
// From figma
export const LOCALE_DROPDOWN_LABEL_WIDTH_PX = '41px'
