import { ApolloError } from '@apollo/client'
import { MESSAGES } from 'src/constants'

type Error = {
  message: string
}

const isGraphQlError = (error: Error | ApolloError): boolean => {
  return error.message?.startsWith('Exception while fetching data (/')
}

// This function will not be necessary when we move over to Formik.
const handleGqlErrorMessage = (error: Error | ApolloError | null): string => {
  if (!error?.message) {
    return MESSAGES.ERROR_OCCURRED
  }

  const index = error.message?.indexOf(':')
  if (isGraphQlError(error) && index >= 0) {
    return error.message.slice(index + 2) || MESSAGES.ERROR_OCCURRED
    // index here points to this string ': ' so + 2 helps to exclude ':' and ' ' as start index for slice method is inclusive
  }
  return error.message
}

export default handleGqlErrorMessage
