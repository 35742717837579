import {
  ApolloError,
  FetchResult,
  MutationFunction,
  MutationFunctionOptions,
  OperationVariables,
} from '@apollo/client'
import {
  DISMISS_TOAST_MESSAGE,
  SHOW_TOAST_MESSAGE,
} from 'modules/toast-messages/actions'
import { isEmpty, isFunction } from 'lodash'
import { TOAST_MESSAGE_TYPES } from 'src/constants'
import handleGqlErrorMessage from 'lib/handleGqlErrorMessage'
import store from 'src/store/'

export const showSnackbar = (message: string, isError: boolean): void => {
  store.dispatch(
    SHOW_TOAST_MESSAGE({
      message,
      kind: isError ? TOAST_MESSAGE_TYPES.ALERT : TOAST_MESSAGE_TYPES.SUCCESS,
    })
  )
}

export const hideSnackbar = (): void => {
  store.dispatch(DISMISS_TOAST_MESSAGE())
}

export enum OPERATIONS {
  CREATE,
  DELETE,
  COPY,
  DELETE_VERSION,
  CREATE_MENU_VARIANT,
  EDIT_MENU_VARIANT,
  EDIT_MENU_DETAILS,
  VIEW_MENU_DETIALS,
  COPY_VERSION,
}

export async function handleMutation<T>({
  mutation,
  mutationOptions,
  additionalMutationVariables,
  onSuccess,
  onError,
  snackbarProps,
}: {
  mutation: MutationFunction<T>
  mutationOptions: MutationFunctionOptions
  additionalMutationVariables?: OperationVariables
  onSuccess?: (response: FetchResult<T>) => void
  onError?: (error: ApolloError) => void
  snackbarProps?: {
    successMessage?: string
    errorMessage?: string
    shouldShowResponseError?: true | never
  }
}): Promise<void> {
  const shouldShowSnackbar = !isEmpty(snackbarProps)

  try {
    const response = await mutation({
      ...mutationOptions,
      variables: {
        ...(mutationOptions?.variables || {}),
        ...(additionalMutationVariables || {}),
      },
    })

    if (shouldShowSnackbar && snackbarProps?.successMessage) {
      showSnackbar(snackbarProps.successMessage, false)
    }

    onSuccess?.(response)
  } catch (error) {
    if (shouldShowSnackbar) {
      const {
        shouldShowResponseError,
        errorMessage: customErrorMessage,
      } = snackbarProps

      const errorMessage =
        shouldShowResponseError || !customErrorMessage
          ? handleGqlErrorMessage(error as ApolloError)
          : customErrorMessage

      showSnackbar(errorMessage, true)
    }

    onError?.(error as ApolloError)
  }
}

type OperationConfig<TD, TE> = {
  onSuccess?: (response: TD) => Promise<void> | void
  onError?: (error: TE) => Promise<void> | void
  snackbarProps?: SnackbarProps | SnackbarConstructor<TD, TE>
}

type SnackbarProps = {
  successMessage?: string
  errorMessage?: string
}

type SnackbarConstructor<TD, TE> = (input: { data?: TD; error?: TE }) => string

export async function handleModalOperation<TD = unknown, TE = unknown>({
  operation,
  config,
}: {
  operation: () => Promise<TD>
  config?: OperationConfig<TD, TE>
}): Promise<void> {
  let data: TD = null
  let error: TE = null

  const operationPromise = operation()

  operationPromise
    .then(result => config?.onSuccess?.(result))
    .catch(() => {
      // TODO: fallback attempt and log this exception
    })

  operationPromise
    .catch(e => config?.onError?.(e as TE))
    .catch(() => {
      // TODO: log this exception
    })

  try {
    data = await operationPromise
  } catch (e) {
    error = e as TE
  }

  if (!config?.snackbarProps) {
    return
  }
  handleOperationSnackbar<typeof config.snackbarProps>(config.snackbarProps, {
    data,
    error,
  })
}

const handleOperationSnackbar = <T>(
  snackbarProps: T,
  { data, error }: { data: unknown; error: unknown }
) => {
  try {
    const isSuccess = !!data
    const messageType = isSuccess ? 'successMessage' : 'errorMessage'
    const message = isFunction(snackbarProps)
      ? (snackbarProps as SnackbarConstructor<unknown, unknown>)({
          error,
          data,
        })
      : (snackbarProps as SnackbarProps)[messageType]

    const toastMessage = message
      ? message
      : handleGqlErrorMessage(error as { message: string })
    showSnackbar(toastMessage, !isSuccess)
  } catch (_e) {
    // TODO: show a fallback error or success message based on the isSuccess condition
  }
}
