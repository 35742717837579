/* eslint-disable @typescript-eslint/no-unsafe-argument */
import API from 'src/src/business-layer-client'
import handleResponse from 'lib/handleResponse'

class Content {
  async duplicateVersion({ endpoint, payload }) {
    try {
      const response = await API.xpm.post(endpoint, payload)
      return handleResponse.success(response)
    } catch (err) {
      return handleResponse.error(err)
    }
  }

  async updateVersion({ endpoint, payload }) {
    try {
      const response = await API.xpm.put(endpoint, payload)
      return handleResponse.success(response)
    } catch (err) {
      return handleResponse.error(err)
    }
  }
}

export default new Content()
