import { I18nLocale } from 'store/i18n/types'

export const getVersionLocaleList = (
  allLocales: I18nLocale[],
  assignedLocaleNames: string[]
): I18nLocale[] => {
  const versionLocales = []

  for (const locale of allLocales) {
    if (assignedLocaleNames.includes(locale.code)) {
      versionLocales.push(locale)
    }
  }

  return versionLocales
}
