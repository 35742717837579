import format from 'date-fns/format'
import isValid from 'date-fns/isValid'

const formatDateTime = (date, defaultValue = 'No date present') => {
  if (!date) {
    return defaultValue
  }
  try {
    const parsedDate = new Date(date)

    if (!isValid(parsedDate)) {
      throw 'invalid date received'
    }

    return format(parsedDate, 'LLL d, yyyy, hh:mm a')
  } catch (err) {
    return defaultValue
  }
}

export default formatDateTime
