import { DEFAULT_TOAST_DURATION } from 'src/constants'
export const toastMessagePromise = (message, kind, toastMessageAction) => {
  return new Promise(resolve => {
    toastMessageAction({
      message,
      kind,
    })
    setTimeout(() => {
      resolve(true)
    }, DEFAULT_TOAST_DURATION)
  })
}
