import React, { memo } from 'react'

const IconClose = props => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      {...props}
    >
      <path d='M0 0h18v18H0zm0 0h18v18H0z' fill='none' />
      <path
        d='M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z'
        fill='#8994a3'
        {...props}
      />
    </svg>
  )
}

export default memo(IconClose)
