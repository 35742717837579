import { Icon, Tooltip, theme as themeUi } from '@teamfabric/copilot-ui'
import React from 'react'
import { StyledIconWrapper } from './styles'

interface LockIconProps {
  tooltipMessage?: string
  message?: string
}

const LockIcon = ({
  tooltipMessage = 'Locked',
  message = 'Locked',
}: LockIconProps): JSX.Element => {
  return (
    <div data-testid='lock-icon'>
      <StyledIconWrapper>
        <Icon
          iconName='Lock'
          size={16}
          fill={themeUi.palette.brand.primary.gray as string}
        />
        {message}
      </StyledIconWrapper>
      <Tooltip
        position='bottom'
        text={tooltipMessage}
        data-testid='edit-content-locked-tooltip'
      />
    </div>
  )
}

export default LockIcon
