import {
  CANCEL_TEXT,
  DELETE_PAGE_TYPE_MODAL,
  MESSAGES,
  PAGE_ACTIVATE_MODAL,
  PAGE_ARCHIVE_MODAL,
  PAGE_DEACTIVATE_MODAL,
  PAGE_UNARCHIVE_MODAL,
  STATUS,
  VERSION_ARCHIVE_MODAL,
  VERSION_DELETE_MODAL,
  VERSION_UNARCHIVE_MODAL,
} from 'src/constants'
import {
  ContentModalOptionsProps,
  ModalType,
} from 'components/ContentModalModule/types'
import {
  PAGES_MODAL_ID,
  Page,
  PageModalDetails,
  PageType,
} from 'src/data/pages-list/types'
import {
  generateAddNewPageModal,
  generateAddNewTypeModal,
  generateCreateNewPageVersionModal,
  generateMakePageCopyModal,
  generatePageEditDetailsModal,
  generatePageViewDetailsModal,
  generateViewTypeModal,
} from './modals'
import getMultiSelectTextChipsOptions, {
  OptionsType,
} from 'lib/getMultiSelectTextChipsOptions'
import { useCallback, useMemo } from 'react'
import { PageNode } from 'src/graphql-proxy/transformations/page/types'
import { PagesModalOperations } from './modal-operations'
import { PagesModuleContext } from './types'
import isObject from 'lodash/isObject'
import { metadataDTOToFormInput } from 'components/FormDialog/common/content/ContentDetailsDialog/utils/utils'
interface PagesModalOptions {
  modalOptions: Record<string, ContentModalOptionsProps>
}

interface PagesModalOptionsProps {
  context: PagesModuleContext
  operations: PagesModalOperations
}

export const usePagesModalOptions = ({
  operations,
  context,
}: PagesModalOptionsProps): PagesModalOptions => {
  const modalContext = useMemo(() => {
    const { featuresList } = context
    const channelsOptionList = featuresList.multiChannel.enabled
      ? getMultiSelectTextChipsOptions(
          featuresList.multiChannel.channels,
          OptionsType.CHANNELS
        )
      : null
    const localesOptionList = featuresList.i18n.enabled
      ? getMultiSelectTextChipsOptions(
          featuresList.i18n.locales,
          OptionsType.LOCALES
        )
      : null

    return {
      localesChipsOptions: localesOptionList,
      channelsChipsOptions: channelsOptionList,
    }
  }, [context])

  const getPageTableEntryModalOptions: () => PagesModalOptions['modalOptions'] = useCallback(() => {
    const { tableModal, closeTableModal } = context
    const { page }: { page: Partial<PageNode> } = tableModal
    if (!page) {
      return {}
    }
    const cancelButton = getAlertCancelButton(closeTableModal)
    const pageId = page?.id ?? ''
    const pageVersions = page?.variants
      ?.map(variant => variant?.versionName)
      ?.join(', ')
    const pageName = page?.name
    return {
      [PAGES_MODAL_ID.ACTIVATE_PAGE]: {
        title: PAGE_ACTIVATE_MODAL.getTitle(pageName),
        size: 'small',
        modalType: ModalType.Alert,
        config: {
          content: PAGE_ACTIVATE_MODAL.content,
          cancelButton: cancelButton,
          confirmButton: {
            text: PAGE_ACTIVATE_MODAL.submitText,
            onClick: () => {
              return operations.activateDeactivatePage({
                variables: {
                  pageId: page.id,
                },
                context: {
                  isActive: false,
                },
              })
            },
          },
        },
      },
      [PAGES_MODAL_ID.DEACTIVATE_PAGE]: {
        title: PAGE_DEACTIVATE_MODAL.getTitle(pageName),
        size: 'small',
        modalType: ModalType.Alert,
        config: {
          content: PAGE_DEACTIVATE_MODAL.content,
          cancelButton: cancelButton,
          confirmButton: {
            text: PAGE_DEACTIVATE_MODAL.submitText,
            onClick: () => {
              return operations.activateDeactivatePage({
                variables: {
                  pageId: page.id,
                },
                context: {
                  isActive: true,
                },
              })
            },
          },
        },
      },
      [PAGES_MODAL_ID.ARCHIVE_PAGE]: {
        title: PAGE_ARCHIVE_MODAL.getTitle(pageName),
        size: 'small',
        modalType: ModalType.Alert,
        config: {
          content: PAGE_ARCHIVE_MODAL.content,
          cancelButton: cancelButton,
          confirmButton: {
            text: PAGE_ARCHIVE_MODAL.submitText,
            onClick: () => {
              return operations.archiveUnarchivePage({
                variables: {
                  pageId: page.id,
                },
                context: {
                  isArchived: false,
                },
              })
            },
          },
        },
      },
      [PAGES_MODAL_ID.UNARCHIVE_PAGE]: {
        title: PAGE_UNARCHIVE_MODAL.getTitle(pageName),
        size: 'small',
        modalType: ModalType.Alert,
        config: {
          content: PAGE_UNARCHIVE_MODAL.content,
          cancelButton: cancelButton,
          confirmButton: {
            text: PAGE_UNARCHIVE_MODAL.submitText,
            onClick: () => {
              return operations.archiveUnarchivePage({
                variables: {
                  pageId: page.id,
                },
                context: {
                  isArchived: true,
                },
              })
            },
          },
        },
      },
      [PAGES_MODAL_ID.EDIT_DETAILS]: generatePageEditDetailsModal({
        initialValues: {
          generalDetailsInitialValues: {
            pageName,
            url: page.url,
            pageType: page.group?.pageTypeId,
            locales: page.locales,
            channels: page.channels,
          },
          seoFormInitialValues: {
            title: page?.seoFields?.title,
            description: page?.seoFields?.description,
            metadata: metadataDTOToFormInput(page?.seoFields?.metadata),
            localizedSeoFields: page?.localizedSeoFields,
          },
        },
        onSubmit: values => {
          return operations.editPageDetails({
            variables: {
              ...values,
              pageId: page.id,
              seoFields: {
                title: values.title,
                description: values.description,
                metadata: values.metadata,
              },
            },
          })
        },
        onModalClose: closeTableModal,
        context: modalContext,
      }),
      [PAGES_MODAL_ID.VIEW_DETAILS]: generatePageViewDetailsModal({
        initialValues: {
          generalDetailsInitialValues: {
            pageName,
            url: page.url,
            pageType: page.group?.pageTypeId,
            locales: page.locales,
            channels: page.channels,
          },
          seoFormInitialValues: {
            title: page?.seoFields?.title,
            description: page?.seoFields?.description,
            metadata: metadataDTOToFormInput(page?.seoFields?.metadata),
            localizedSeoFields: page?.localizedSeoFields,
          },
        },
        onModalClose: closeTableModal,
        context: modalContext,
      }),
      [PAGES_MODAL_ID.NEW_VERSION]: generateCreateNewPageVersionModal({
        initialValues: {
          pageName: page.name,
          url: page.url,
        },
        onSubmit: values => {
          return operations.createPageVersion({
            variables: {
              pageVersionName: values.versionName,
              pageId: page.id,
              description: values.description,
            },
          })
        },
        onModalClose: closeTableModal,
        context: modalContext,
      }),
      [PAGES_MODAL_ID.MAKE_A_COPY_TYPE]: generateMakePageCopyModal({
        initialValues: {
          copyFormInitialValues: {
            pageName,
            pageVersions,
            channels: page.channels,
            locales: page.locales,
          },
          seoFormInitialValues: {
            title: page?.seoFields?.title,
            description: page?.seoFields?.description,
            metadata: metadataDTOToFormInput(page?.seoFields?.metadata),
            localizedSeoFields: page?.localizedSeoFields,
          },
        },
        onSubmit: values => {
          return operations.copyPage({
            variables: {
              ...values,
              pageId,
              seoFields: {
                title: values.title,
                description: values.description,
                metadata: values.metadata,
              },
            },
          })
        },
        context: modalContext,
        onModalClose: closeTableModal,
      }),
      [PAGES_MODAL_ID.DELETE_PAGE]: {
        title: MESSAGES.deletePageTitle(pageName),
        size: 'small',
        modalType: ModalType.Alert,
        config: {
          content: MESSAGES.DELETE_PAGE_ALERT,
          cancelButton: cancelButton,
          confirmButton: {
            text: VERSION_DELETE_MODAL.submitText,
            onClick: () => {
              return operations.deletePage({
                variables: {
                  pageId: page.id,
                  pageName: page.name,
                },
              })
            },
          },
        },
      },
    }
  }, [context, operations, modalContext])

  const getVersionTableEntryModalOptions: () => PagesModalOptions['modalOptions'] = useCallback(() => {
    const { tableModal, closeTableModal } = context
    const {
      page,
      targetItem,
    }: {
      page: Partial<PageNode>
      targetItem: PageModalDetails['targetItem']
    } = tableModal

    if (!page) {
      return {}
    }

    const versionId = isObject(targetItem) ? targetItem?.versionId : null
    const version = findPageVersion(page, versionId)
    const pageId = page?.id ?? ''
    const pageName = page?.name ?? ''
    const url = page?.url ?? ''
    if (!version) {
      return {}
    }
    const isLastVersion = page.variants?.length === 1
    const versionName = version.versionName
    const getVersionDeleteMessage = isLastVersion
      ? VERSION_DELETE_MODAL.getLastVersionContent(`Page ${pageName}`)
      : VERSION_DELETE_MODAL.getDefaultContent(versionName)
    const cancelButton = getAlertCancelButton(closeTableModal)
    const getLiveStatusMessage = isLastVersion
      ? `${
          VERSION_DELETE_MODAL.liveStatusContent
        }. ${VERSION_DELETE_MODAL.getLastVersionContent(`Page ${pageName}`)}`
      : VERSION_DELETE_MODAL.liveStatusContent

    return {
      [PAGES_MODAL_ID.DELETE_VERSION]: {
        title: `${VERSION_DELETE_MODAL.title} "${version?.versionName}"`,
        size: 'small',
        modalType: ModalType.Alert,
        config: {
          content:
            version?.status === STATUS.LIVE
              ? getLiveStatusMessage
              : getVersionDeleteMessage,
          cancelButton: cancelButton,
          confirmButton: {
            text: VERSION_DELETE_MODAL.submitText,
            onClick: () => {
              return operations.deletePageVersion({
                variables: {
                  pageId: page.id,
                  versionId: version.id,
                },
                context: {
                  isLastVersion,
                },
              })
            },
          },
        },
      },
      [PAGES_MODAL_ID.ARCHIVE_VERSION]: {
        title: VERSION_ARCHIVE_MODAL.getTitle(version.versionName),
        size: 'small',
        modalType: ModalType.Alert,
        config: {
          content: VERSION_ARCHIVE_MODAL.getContent(version.versionName),
          cancelButton: cancelButton,
          confirmButton: {
            text: VERSION_ARCHIVE_MODAL.submitText,
            onClick: () => {
              return operations.archiveUnarchiveVersion({
                variables: {
                  pageId: page.id,
                  versionId: version.id,
                },
                context: {
                  isArchived: false,
                  isLastVersion,
                },
              })
            },
          },
        },
      },
      [PAGES_MODAL_ID.UNARCHIVE_VERSION]: {
        title: VERSION_UNARCHIVE_MODAL.getTitle(version.versionName),
        size: 'small',
        modalType: ModalType.Alert,
        config: {
          content: VERSION_UNARCHIVE_MODAL.content,
          cancelButton: cancelButton,
          confirmButton: {
            text: VERSION_UNARCHIVE_MODAL.submitText,
            onClick: () => {
              return operations.archiveUnarchiveVersion({
                variables: {
                  pageId: page.id,
                  versionId: version.id,
                },
                context: {
                  isArchived: true,
                  isLastVersion,
                },
              })
            },
          },
        },
      },
      [PAGES_MODAL_ID.NEW_VERSION]: generateCreateNewPageVersionModal({
        initialValues: {
          pageName,
          url,
          versionName: `${versionName} copy`,
        },
        context: modalContext,
        onSubmit: values => {
          return operations.copyPageVersion({
            variables: {
              pageId,
              versionId,
              pageName,
              description: values?.description,
              versionName: values?.versionName,
            },
          })
        },
        onModalClose: closeTableModal,
      }),
    }
  }, [context, operations, modalContext])

  const getCTAModals: () => PagesModalOptions['modalOptions'] = useCallback(() => {
    const { closeTableModal: onModalClose } = context
    return {
      [PAGES_MODAL_ID.ADD_NEW_PAGE]: generateAddNewPageModal({
        onSubmit: values => {
          return operations.createPage({
            variables: {
              ...values,
              seoFields: {
                title: values.title,
                description: values.description,
                metadata: values.metadata,
              },
            },
          })
        },
        onModalClose,
        context: modalContext,
      }),
      [PAGES_MODAL_ID.ADD_PAGE_TYPE]: generateAddNewTypeModal({
        onSubmit: values => {
          return operations.createPageType({ variables: values })
        },
        onModalClose,
        context: modalContext,
      }),
    }
  }, [context, operations, modalContext])

  const getPageTypeModalOptions: () => PagesModalOptions['modalOptions'] = useCallback(() => {
    const { tableModal, closeTableModal } = context
    const cancelButton = getAlertCancelButton(closeTableModal)
    const pageType: Partial<PageType> = tableModal.page
    if (!pageType) return {}
    return {
      [PAGES_MODAL_ID.VIEW_PAGE_TYPE]: generateViewTypeModal({
        initialValues: {
          name: pageType.name,
          prefix: pageType.urlPrefix,
        },
        onModalClose: closeTableModal,
        context: modalContext,
      }),
      [PAGES_MODAL_ID.EDIT_PAGE_TYPE]: generateAddNewTypeModal(
        {
          initialValues: {
            name: pageType.name,
            prefix: pageType.urlPrefix,
          },
          onSubmit: values => {
            return operations.editPageType({
              variables: {
                ...values,
                id: pageType.id,
              },
            })
          },
          onModalClose: closeTableModal,
          context: modalContext,
        },
        true
      ),
      [PAGES_MODAL_ID.DELETE_PAGE_TYPE]: {
        title: DELETE_PAGE_TYPE_MODAL.title,
        size: 'small',
        modalType: ModalType.Alert,
        config: {
          content: DELETE_PAGE_TYPE_MODAL.content,
          cancelButton: cancelButton,
          confirmButton: {
            text: DELETE_PAGE_TYPE_MODAL.submitText,
            onClick: () => {
              return operations.deletePageType({
                variables: {
                  id: pageType.id,
                  name: pageType.name,
                },
              })
            },
          },
        },
      },
    }
  }, [context, modalContext, operations])

  const modalOptions: PagesModalOptions['modalOptions'] = useMemo(() => {
    return {
      ...getCTAModals(),
      ...getPageTableEntryModalOptions(),
      ...getVersionTableEntryModalOptions(),
      ...getPageTypeModalOptions(),
    }
  }, [
    getCTAModals,
    getPageTableEntryModalOptions,
    getVersionTableEntryModalOptions,
    getPageTypeModalOptions,
  ])

  return { modalOptions }
}

const findPageVersion = (p: Page, variantId: string) => {
  return p?.variants?.find?.(variant => variant?.id === variantId)
}

const getAlertCancelButton = (handler: () => void) => {
  return {
    text: CANCEL_TEXT,
    onClick: handler,
  }
}
