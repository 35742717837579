import {
  ButtonLoader,
  Dropdown,
  theme as themeUi,
} from '@teamfabric/copilot-ui'
import { MESSAGES, PAGE_MODAL_LABEL, TOAST_MESSAGE_TYPES } from 'src/constants'
import React, { useEffect, useMemo, useState } from 'react'
import { StyledEmptyDropdown, StyledListItem } from './styles'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import pageTypeService from 'services/api/pageType'
import { showToast } from 'components/ToastSnackbarContainer'
import theme from 'styles/theme'

const OptionItem = React.memo(({ pageType }) => {
  return (
    <>
      <StyledListItem
        key={pageType._id}
        data-testid={`page-type-field-${pageType._id}`}
      >
        <span>
          <span style={{ marginRight: theme.space.space_2 }}>
            {pageType.name}
          </span>
          <span
            style={{
              color: themeUi.palette.brand.primary.gray,
              fontSize: theme.typography.fontSize.xs,
            }}
          >
            {pageType.urlPrefix}
          </span>
        </span>
      </StyledListItem>
    </>
  )
})

OptionItem.defaultProps = {
  pageType: {},
}

OptionItem.propTypes = {
  pageType: PropTypes.object.isRequired,
}

const PageTypeField = props => {
  const { pageTypeId, setPageType } = props
  const [pageTypes, setPageTypes] = useState([])
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (!pageTypes.length) {
      const QUERY_LIMIT = 1000
      pageTypeService
        .list(0, QUERY_LIMIT)
        .then(response => {
          const result = response.pageTypes
          if (result.length) {
            setPageTypes(result)
          }
          setIsLoaded(true)
        })
        .catch(e => {
          showToast({
            message: e.message,
            kind: TOAST_MESSAGE_TYPES.ALERT,
          })
        })
    }
  }, [pageTypes])
  const options = useMemo(() => {
    return pageTypes.map(pageType => ({
      ...pageType,
      id: pageType._id,
      name: <OptionItem pageType={pageType} />,
    }))
  }, [pageTypes])

  const handlePageTypeIdChange = selectedPage => {
    setPageType(selectedPage)
  }
  const inputValue = useMemo(() => {
    const pageType = pageTypes.find(type => type._id === pageTypeId)
    if (pageType) {
      return pageType.name
    }
  }, [pageTypeId, pageTypes])

  const value = pageTypeId
    ? {
        id: pageTypeId,
        name: inputValue || '-',
      }
    : {
        id: undefined,
        name: 'Select',
      }

  const emptyOptions = [{ name: MESSAGES.NO_PAGE_TYPES }]
  const emptyValue = { id: undefined, name: 'Select' }

  return (
    <>
      {options?.length > 0 ? (
        <Dropdown
          onSelect={handlePageTypeIdChange}
          options={options}
          titleLabel={PAGE_MODAL_LABEL.PAGE_TYPE}
          value={value}
          data-value={pageTypeId}
          data-testid='page-type-field'
          style={{ width: '100%' }}
          disabled={props.disabled}
        />
      ) : (
        <StyledEmptyDropdown>
          {isLoaded ? (
            <Dropdown
              options={emptyOptions}
              titleLabel={PAGE_MODAL_LABEL.PAGE_TYPE}
              value={emptyValue}
              data-testid='empty-page-type-field'
              style={{ width: '100%' }}
              disabled={props.disabled}
            />
          ) : (
            <ButtonLoader theme='light' width='100%' />
          )}
        </StyledEmptyDropdown>
      )}
    </>
  )
}

PageTypeField.defaultProps = {
  pageTypeId: '',
  setPageType: noop,
  disabled: false,
}

PageTypeField.propTypes = {
  pageTypeId: PropTypes.string.isRequired,
  setPageType: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default PageTypeField
