/* eslint-disable react/prop-types */
import React, { useMemo, useRef, useState } from 'react'
import {
  StyledFlyout,
  StyledFlyoutOption,
  StyledMenuIconWrapper,
} from './styles'
import { Dots } from '@teamfabric/copilot-ui/dist/assets/images'
import { FLYOUT_POSITION_OFFSET } from './constants'
import PropTypes from 'prop-types'
import { uniqueId } from 'lodash'
import useClickOutside from 'hooks/useClickOutside'

const ContextMenu = props => {
  const {
    id,
    icon,
    className,
    visible,
    children,
    width = '200px',
    placement = 'leftTop',
    flyoutId = '',
    ...rest
  } = props

  const [show, setShow] = useState(false)
  const flyoutContentRef = useRef(null)

  useClickOutside(
    flyoutContentRef,
    () => {
      setShow(false)
    },
    show
  )

  const menuId = useMemo(() => (id !== undefined ? id : uniqueId()), [id])

  const handleCloseContextMenu = () => setShow(false)

  const handleOnClick = event => {
    event.stopPropagation()
    setShow(_show => !_show)
  }

  return (
    <>
      <div className={className} {...rest}>
        <StyledMenuIconWrapper
          id={menuId}
          onClick={handleOnClick}
          data-testid='menu-icon'
        >
          {(visible === undefined || visible || show) && (
            <div data-testid='menu-icon-svg'>{icon}</div>
          )}
        </StyledMenuIconWrapper>
      </div>
      <StyledFlyout
        show={show}
        id={menuId}
        data-flyoutid={flyoutId}
        offset={FLYOUT_POSITION_OFFSET}
        placement={placement}
        width={width}
        data-testid='menu'
        onClick={handleCloseContextMenu}
      >
        <div
          ref={flyoutContentRef}
          data-testid='menu-content'
          onClick={handleCloseContextMenu}
        >
          {React.Children.map(children, child => React.cloneElement(child))}
        </div>
      </StyledFlyout>
    </>
  )
}

ContextMenu.Item = props => {
  const { children, ...rest } = props
  return <StyledFlyoutOption {...rest}>{children}</StyledFlyoutOption>
}

ContextMenu.Label = props => {
  const { children, ...rest } = props
  return <span {...rest}>{children}</span>
}

ContextMenu.defaultProps = {
  children: null,
  className: '',
  visible: undefined, // default value. different behaviour
  icon: <Dots />,
  id: undefined, // default value. different behaviour
}

ContextMenu.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.any,
  visible: PropTypes.bool,
  id: PropTypes.string,
  width: PropTypes.string,
  placement: PropTypes.string,
}

export default ContextMenu
