import { AssetProps, AssetsInner, DamResponse } from './types'
import { DAM_ERRORS, MESSAGES, TOAST_MESSAGE_TYPES } from 'src/constants'
import { ApolloError } from '@apollo/client'
import httpStatusCodes from 'http-status-codes'
import { showToast } from 'components/ToastSnackbarContainer'

interface FormattedItems {
  assets: AssetProps[]
  sections: string[]
  labels: string[]
}

export const getFormattedAssets = (assets: AssetsInner): AssetProps[] => {
  return (
    assets?.edges.map(asset => ({
      name: asset.node.name,
      description: asset.node.description,
      url: asset.node.url,
      altText: asset.node.altText ?? '',
    })) ?? []
  )
}

export const getFormattedSections = (sections: AssetsInner): string[] => {
  return sections?.edges.map(section => section.node.name) ?? []
}

export const getFormattedItems = (items: DamResponse): FormattedItems => {
  const assets = getFormattedAssets(items?.assets)

  const sections = getFormattedSections(items?.sections)

  const labels = items?.labels.map(o => o.name) ?? []

  return { assets, sections, labels }
}

export const handleErrors = (err: ApolloError, item: string): void => {
  console.error(err?.message)
  let messageToShow: string
  if (
    err?.graphQLErrors?.length &&
    err?.graphQLErrors[0].extensions?.errorCode ===
      httpStatusCodes.INTERNAL_SERVER_ERROR
  ) {
    messageToShow = DAM_ERRORS.misconfigured
  } else {
    messageToShow = MESSAGES.getFetchingError(item)
  }
  void showToast({
    message: messageToShow,
    kind: TOAST_MESSAGE_TYPES.ALERT,
  })
}
