import ArrayType from './ArrayType'
import BooleanType from './BooleanType'
import ComponentType from './ComponentType'
import EnumType from './EnumType'
import ImageType from './ImageType'
import MultiLineStringType from './MultiLineStringType'
import NumberType from './NumberType'
import RichTextType from './RichTextType'
import SizeType from './SizeType'
import StringType from './StringType'

export const typesById = {
  Array: ArrayType,
  Boolean: BooleanType,
  Image: ImageType,
  String: StringType,
  Number: NumberType,
  Enum: EnumType,
  MultiLineString: MultiLineStringType,
  Size: SizeType,
  RichText: RichTextType,
  Component: ComponentType,
}
