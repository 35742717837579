import {
  ACTIVATE_PAGE_STRING,
  ARCHIVE_PAGE_VERSION_STRING,
  DEACTIVATE_PAGE_STRING,
  UNARCHIVE_PAGE_VERSION_STRING,
} from 'services/graphql'
import {
  ARCHIVE_PAGE_STRING,
  COPY_PAGE_ASYNC_STRING,
  COPY_PAGE_VARIANT_STRING,
  CREATE_PAGE_STRING,
  CREATE_PAGE_VARIANT_STRING,
  DELETE_PAGE_ASYNC_MUTATION,
  DELETE_PAGE_VARIANT_MUTATION,
  UNARCHIVE_PAGE_STRING,
  UPDATE_PAGE_STRING,
} from 'services/graphql/page'
import { ApolloError, FetchResult, gql } from '@apollo/client/core'
import {
  CreateVariantGraphQLResponse,
  DeletePageGraphQLResponse,
  DeletePageVariantGraphQLResponse,
  EditPageDetailsGraphQLResponse,
  PageArchiveGraphQLResponse,
  PageCopyAsyncGraphQLResponse,
  PageCreateGraphQLResponse,
  PageUnarchiveGraphQLResponse,
  VersionCopyGraphQLResponse,
} from 'src/graphql-proxy/transformations/page/types'
import { MESSAGES, PAGE_MODAL_LABEL } from 'src/constants'
import {
  PageActivateGraphQLResponse,
  PageDeactivateGraphQLResponse,
  PageVersionArchiveGraphQLResponse,
  PageVersionUnarchiveGraphQLResponse,
} from 'src/graphql-proxy/transformations/page-version/types'
import { getLocalizedSeoFields, multiTextChipsOptionsToDTO } from 'data/utils'
import { ACTIONS } from 'components/ContentTableModule/useTableData'
import { LocalizedSeoFields } from 'components/FormDialog/common/content/ContentDetailsDialog/types'
import { MultiSelectTextChipsOptions } from 'lib/getMultiSelectTextChipsOptions/types'
import { PagesModuleContext } from 'src/data/pages-list/types'
import { handleModalOperation } from 'src/data/services'
import { metadataInput } from 'services/graphql/types'
import pageTypeService from 'services/api/pageType'
import { useApolloClient } from '@apollo/client/react/hooks/useApolloClient'
import { useCallback } from 'react'
import { useNavigate } from '@reach/router'
type CreatePageOperationInput = {
  variables: {
    pageName: string
    url: string
    pageVersionName: string
    pageType: string
    seoFields: {
      title?: string
      description?: string
      metadata?: metadataInput[]
    }
    localizedSeoFields: LocalizedSeoFields
    locales: MultiSelectTextChipsOptions[]
    channels: MultiSelectTextChipsOptions[]
  }
}
type DeletePageVersionOperationsInput = {
  variables: {
    pageId: string
    versionId: string
  }
  context: {
    isLastVersion: boolean
  }
}

type DeletePageOperationInput = {
  variables: {
    pageId: string
    pageName: string
  }
}

type ActivateDeactivatePageOperationInput = {
  variables: {
    pageId: string
  }
  context: {
    isActive: boolean
  }
}

type ArchiveUnarchivePageOperationInput = {
  variables: {
    pageId: string
  }
  context: {
    isArchived: boolean
  }
}

type ArchiveUnarchivePageVersionOperationInput = {
  variables: {
    pageId: string
    versionId: string
  }
  context: {
    isLastVersion: boolean
    isArchived: boolean
  }
}

interface CopyPageOperationsInput {
  variables: {
    pageId?: string
    newPageName?: string
    pageVersions?: string
    newPageURL?: string
    pageType: string
    seoFields: {
      title?: string
      description?: string
      metadata?: metadataInput[]
    }
    channels: MultiSelectTextChipsOptions[]
    locales: MultiSelectTextChipsOptions[]
    localizedSeoFields: LocalizedSeoFields
  }
}

type CreatePageTypeOperationInput = {
  variables: {
    name: string
    prefix: string
  }
}

type CopyVersionOperationInput = {
  variables: {
    versionId: string
    pageId: string
    pageName: string
    description?: string
    versionName?: string
  }
}
type EditPageOperationInput = {
  variables: {
    pageId: string
    pageName: string
    url: string
    pageType: string
    seoFields?: {
      title: string
      description?: string
      metadata?: metadataInput[]
    }
    localizedSeoFields: LocalizedSeoFields
    channels: MultiSelectTextChipsOptions[]
    locales: MultiSelectTextChipsOptions[]
  }
}

type CreatePageVersionOperationInput = {
  variables: {
    pageId: string
    pageVersionName: string
    description?: string
  }
}

type EditPageTypeOperationInput = {
  variables: {
    id: string
    name: string
    prefix: string
  }
}

type DeletePageTypeOperationInput = {
  variables: {
    id: string
    name: string
  }
}

export interface PagesModalOperations {
  createPage: (input: CreatePageOperationInput) => Promise<void>
  deletePage: (input: DeletePageOperationInput) => Promise<void>
  deletePageVersion: (input: DeletePageVersionOperationsInput) => Promise<void>
  activateDeactivatePage: (
    input: ActivateDeactivatePageOperationInput
  ) => Promise<void>
  archiveUnarchivePage: (
    input: ArchiveUnarchivePageOperationInput
  ) => Promise<void>
  archiveUnarchiveVersion: (
    input: ArchiveUnarchivePageVersionOperationInput
  ) => Promise<void>
  createPageType: (input: CreatePageTypeOperationInput) => Promise<void>
  copyPageVersion: (input: CopyVersionOperationInput) => Promise<void>
  copyPage: (input: CopyPageOperationsInput) => Promise<void>
  editPageDetails: (input: EditPageOperationInput) => Promise<void>
  createPageVersion: (input: CreatePageVersionOperationInput) => Promise<void>
  editPageType: (input: EditPageTypeOperationInput) => Promise<void>
  deletePageType: (input: DeletePageTypeOperationInput) => Promise<void>
}

export const usePagesModalOperations = ({
  context,
  basepath,
}: {
  context: PagesModuleContext
  basepath: string
}): PagesModalOperations => {
  const navigate = useNavigate()
  const apolloClient = useApolloClient()
  const PAGE_REFETCH_TIME = 2000
  const createPage = useCallback(
    (input: CreatePageOperationInput) => {
      return handleModalOperation<
        FetchResult<PageCreateGraphQLResponse>,
        ApolloError
      >({
        operation: () =>
          apolloClient.mutate({
            mutation: gql(CREATE_PAGE_STRING),
            variables: {
              input: {
                name: input.variables.pageName,
                url: input.variables.url,
                versionName: input.variables.pageVersionName,
                group: { pageTypeId: input.variables.pageType },
                seoFields: input.variables.seoFields,
                localizedSeoFields: input.variables.localizedSeoFields,
                locales: multiTextChipsOptionsToDTO(input.variables.locales),
                channels: multiTextChipsOptionsToDTO(input.variables.channels),
              },
            },
          }),
        config: {
          onSuccess: response => {
            const data = response?.data?.createPage
            const pageId = data?.id
            const variantId = data?.variants?.[0]?.id
            context.closeTableModal()
            if (pageId && variantId) {
              void navigate(
                `${basepath}/editor?pageId=${pageId}&versionId=${variantId}`
              )
            }
          },
          onError: console.error,
          snackbarProps: ({ data }) => {
            if (data) {
              return MESSAGES.getCreatedSuccess(input.variables.pageName)
            }
          },
        },
      })
    },
    [apolloClient, context, navigate]
  )

  const createPageType = useCallback(
    (input: CreatePageTypeOperationInput) => {
      return handleModalOperation({
        operation: () =>
          pageTypeService.create({
            name: input.variables.name,
            urlPrefix: input.variables.prefix,
          }),
        config: {
          onSuccess: () => {
            context.dispatchTableAction(ACTIONS.REFETCH)
            context.closeTableModal()
          },
          snackbarProps: {
            successMessage: MESSAGES.getCreatedSuccess(
              `${PAGE_MODAL_LABEL.PAGE_TYPE} ${input.variables.name}`
            ),
            errorMessage: MESSAGES.getCreatedError(
              `${PAGE_MODAL_LABEL.PAGE_TYPE} ${input.variables.name}`
            ),
          },
        },
      })
    },
    [context]
  )

  const archiveUnarchivePage = useCallback(
    (input: ArchiveUnarchivePageOperationInput) => {
      const isArchiveOperation = !input.context.isArchived

      const getPage = (
        data: PageArchiveGraphQLResponse | PageUnarchiveGraphQLResponse
      ) => {
        return isArchiveOperation
          ? (data as PageArchiveGraphQLResponse).archiveContentMeta
          : (data as PageUnarchiveGraphQLResponse).unarchiveContentMeta
      }
      return handleModalOperation<
        FetchResult<PageArchiveGraphQLResponse | PageUnarchiveGraphQLResponse>,
        ApolloError
      >({
        operation: () =>
          apolloClient.mutate({
            mutation: gql(
              isArchiveOperation ? ARCHIVE_PAGE_STRING : UNARCHIVE_PAGE_STRING
            ),
            variables: { contentMetaId: input.variables.pageId },
            fetchPolicy: 'no-cache',
          }),
        config: {
          onSuccess: () => {
            context.dispatchTableAction(ACTIONS.DELETE, {
              targetItemId: input.variables.pageId,
            })
            context.closeTableModal()
          },
          snackbarProps: ({ data }) => {
            if (data) {
              const page = getPage(data.data)
              const pageNameText = `Page ${page?.name ?? ''}`
              return isArchiveOperation
                ? MESSAGES.getArchivedSuccess(pageNameText)
                : MESSAGES.getUnarchivedSuccess(pageNameText)
            }
          },
        },
      })
    },
    [apolloClient, context]
  )

  const archiveUnarchiveVersion = useCallback(
    (input: ArchiveUnarchivePageVersionOperationInput) => {
      const isArchiveOperation = !input.context.isArchived

      const getVersion = (
        data:
          | PageVersionUnarchiveGraphQLResponse
          | PageVersionArchiveGraphQLResponse
      ) => {
        return isArchiveOperation
          ? (data as PageVersionArchiveGraphQLResponse).archiveContentVariant
          : (data as PageVersionUnarchiveGraphQLResponse)
              .unarchiveContentVariant
      }

      return handleModalOperation<
        FetchResult<
          | PageVersionUnarchiveGraphQLResponse
          | PageVersionArchiveGraphQLResponse
        >,
        ApolloError
      >({
        operation: () =>
          apolloClient.mutate({
            mutation: gql(
              isArchiveOperation
                ? ARCHIVE_PAGE_VERSION_STRING
                : UNARCHIVE_PAGE_VERSION_STRING
            ),
            variables: {
              contentVariantId: input.variables.versionId,
              parentContentId: input.variables.pageId,
            },
            fetchPolicy: 'no-cache',
          }),
        config: {
          onSuccess: () => {
            context.closeTableModal()
            context.dispatchTableAction(ACTIONS.DELETE_VERSION, {
              targetItemId: input.variables.versionId,
              parentId: input.variables.pageId,
            })

            if (input.context.isLastVersion) {
              context.dispatchTableAction(ACTIONS.DELETE, {
                targetItemId: input.variables.pageId,
              })
            }
          },
          snackbarProps: ({ data }) => {
            if (data) {
              const version = getVersion(data.data)
              const pageNameText = `Version ${version?.versionName ?? ''}`
              return isArchiveOperation
                ? MESSAGES.getArchivedSuccess(pageNameText)
                : MESSAGES.getUnarchivedSuccess(pageNameText)
            }
          },
        },
      })
    },
    [apolloClient, context]
  )

  const deletePageVersion = useCallback(
    (input: DeletePageVersionOperationsInput) => {
      return handleModalOperation<
        FetchResult<DeletePageVariantGraphQLResponse>,
        ApolloError
      >({
        operation: () =>
          apolloClient.mutate({
            mutation: gql(DELETE_PAGE_VARIANT_MUTATION),
            variables: {
              contentVariantId: input.variables.versionId,
              parentContentId: input.variables.pageId,
            },
            fetchPolicy: 'no-cache',
          }),
        config: {
          onSuccess: () => {
            context.dispatchTableAction(ACTIONS.DELETE_VERSION, {
              targetItemId: input.variables.versionId,
              parentId: input.variables.pageId,
            })
            context.closeTableModal()

            if (input.context.isLastVersion) {
              context.dispatchTableAction(ACTIONS.DELETE, {
                targetItemId: input.variables.pageId,
              })
            }
          },
          snackbarProps: ({ data }) => {
            if (data) {
              const versionName = data?.data?.deletePageVariant?.versionName
              return versionName
                ? MESSAGES.getDeletedSuccess(versionName)
                : MESSAGES.getDeletedSuccess('Version')
            }
          },
        },
      })
    },
    [apolloClient, context]
  )

  const deletePage = useCallback(
    (input: DeletePageOperationInput) => {
      return handleModalOperation<
        FetchResult<DeletePageGraphQLResponse>,
        ApolloError
      >({
        operation: () =>
          apolloClient.mutate({
            mutation: gql(DELETE_PAGE_ASYNC_MUTATION),
            variables: {
              contentMetaId: input.variables.pageId,
            },
            fetchPolicy: 'no-cache',
          }),
        config: {
          onSuccess: () => {
            context.closeTableModal()
            context.dispatchTableAction(
              ACTIONS.REFETCH,
              {
                targetItemId: input.variables.pageId,
              },
              PAGE_REFETCH_TIME
            )
          },
          snackbarProps: ({ data }) => {
            if (data) {
              return MESSAGES.deletePageAndVersionsSuccess(
                input.variables.pageName
              )
            }
          },
        },
      })
    },
    [apolloClient, context]
  )
  const activateDeactivatePage = useCallback(
    (input: ActivateDeactivatePageOperationInput) => {
      const isActivateOperation = !input.context.isActive
      return handleModalOperation<
        FetchResult<
          PageActivateGraphQLResponse | PageDeactivateGraphQLResponse
        >,
        ApolloError
      >({
        operation: () =>
          apolloClient.mutate({
            mutation: gql(
              isActivateOperation
                ? ACTIVATE_PAGE_STRING
                : DEACTIVATE_PAGE_STRING
            ),
            variables: {
              contentMetaId: input.variables.pageId,
            },
            fetchPolicy: 'no-cache',
          }),
        config: {
          onSuccess: () => {
            context.closeTableModal()
            context.dispatchTableAction(ACTIONS.REFETCH)
          },
          snackbarProps: ({ data, error }) => {
            if (data) {
              return isActivateOperation
                ? MESSAGES.getActivatedSuccess('Page')
                : MESSAGES.getDeactivatedSuccess('Page')
            }
            return error?.message
          },
        },
      })
    },
    [context, apolloClient]
  )

  const createPageVersion = useCallback(
    (input: CreatePageVersionOperationInput) => {
      return handleModalOperation<
        FetchResult<CreateVariantGraphQLResponse>,
        ApolloError
      >({
        operation: () =>
          apolloClient.mutate({
            mutation: gql(CREATE_PAGE_VARIANT_STRING),
            variables: {
              input: {
                parentContentId: input.variables.pageId,
                name: input.variables.pageVersionName,
                description: input.variables.description,
              },
            },
          }),
        config: {
          onSuccess: ({ data }) => {
            const versionId = data?.createPageVariant?.id
            context.closeTableModal()
            if (versionId) {
              void navigate(
                `${basepath}/editor?pageId=${input.variables.pageId}&versionId=${versionId}`
              )
            }
          },
          snackbarProps: ({ data }) => {
            if (data) {
              return MESSAGES.getCreatedSuccess(input.variables.pageVersionName)
            }
          },
        },
      })
    },
    [apolloClient, context, navigate]
  )
  const copyPage = useCallback(
    (input: CopyPageOperationsInput) => {
      return handleModalOperation<
        FetchResult<PageCopyAsyncGraphQLResponse>,
        ApolloError
      >({
        operation: () => {
          const localeStringList = multiTextChipsOptionsToDTO(
            input.variables.locales
          )
          return apolloClient.mutate({
            mutation: gql(COPY_PAGE_ASYNC_STRING),
            variables: {
              input: {
                originalId: input.variables.pageId,
                copiedName: input.variables.newPageName,
                url: input.variables.newPageURL,
                locales: localeStringList,
                channels: multiTextChipsOptionsToDTO(input.variables.channels),
                seoFields: input.variables.seoFields,
                localizedSeoFields: getLocalizedSeoFields(
                  localeStringList,
                  input.variables.localizedSeoFields
                ),
              },
            },
          })
        },
        config: {
          onSuccess: () => {
            context.closeTableModal()
            context.dispatchTableAction(
              ACTIONS.REFETCH,
              null,
              PAGE_REFETCH_TIME
            )
          },
          snackbarProps: ({ data }) => {
            if (data) {
              return MESSAGES.getCreatedSuccess(input.variables.newPageName)
            }
          },
        },
      })
    },
    [apolloClient, context]
  )

  const editPageDetails = useCallback(
    (input: EditPageOperationInput) => {
      return handleModalOperation<
        FetchResult<EditPageDetailsGraphQLResponse>,
        ApolloError
      >({
        operation: () => {
          const localeStringList = multiTextChipsOptionsToDTO(
            input.variables.locales
          )
          return apolloClient.mutate({
            mutation: gql(UPDATE_PAGE_STRING),
            variables: {
              input: {
                id: input.variables.pageId,
                name: input.variables.pageName,
                url: input.variables.url,
                group: { pageTypeId: input.variables.pageType },
                seoFields: input.variables.seoFields,
                localizedSeoFields: getLocalizedSeoFields(
                  localeStringList,
                  input.variables.localizedSeoFields
                ),
                locales: localeStringList,
                channels: multiTextChipsOptionsToDTO(input.variables.channels),
              },
            },
            fetchPolicy: 'no-cache',
          })
        },
        config: {
          onSuccess: () => {
            context.closeTableModal()
            context.dispatchTableAction(ACTIONS.REFETCH)
          },
          snackbarProps: ({ data }) => {
            if (data) {
              return MESSAGES.getSavedSuccess(
                `Page ${input.variables.pageName}`
              )
            }
          },
        },
      })
    },
    [apolloClient, context]
  )

  const copyPageVersion = useCallback(
    (input: CopyVersionOperationInput) => {
      return handleModalOperation<
        FetchResult<VersionCopyGraphQLResponse>,
        ApolloError
      >({
        operation: () =>
          apolloClient.mutate({
            mutation: gql(COPY_PAGE_VARIANT_STRING),
            variables: {
              input: {
                originalContentVariantId: input.variables.versionId,
                parentContentId: input.variables.pageId,
                name: input.variables.versionName,
                description: input.variables.description,
              },
            },
          }),
        config: {
          onSuccess: ({ data }) => {
            const versionId = data?.copyPageVariant?.id
            context.closeTableModal()
            if (versionId) {
              return navigate(
                `${basepath}/editor?pageId=${input.variables.pageId}&versionId=${versionId}`
              )
            }
          },
          snackbarProps: ({ data }) => {
            if (data) {
              return MESSAGES.getCreatedSuccess(input.variables.versionName)
            }
          },
        },
      })
    },
    [context, navigate, apolloClient]
  )

  const editPageType = useCallback(
    (input: EditPageTypeOperationInput) => {
      return handleModalOperation({
        operation: () =>
          pageTypeService.update(input.variables.id, {
            name: input.variables.name,
            urlPrefix: input.variables.prefix,
          }),
        config: {
          onSuccess: () => {
            context.closeTableModal()
            context.dispatchTableAction(ACTIONS.REFETCH)
          },
          snackbarProps: ({ data, error }) => {
            if (data) {
              return MESSAGES.getSavedSuccess(
                `${PAGE_MODAL_LABEL.PAGE_TYPE} "${input.variables.name}"`
              )
            }
            return (
              error?.message ??
              MESSAGES.getUpdatedError(
                `${PAGE_MODAL_LABEL.PAGE_TYPE} "${input.variables.name}"`
              )
            )
          },
        },
      })
    },
    [context]
  )

  const deletePageType = useCallback(
    (input: DeletePageTypeOperationInput) => {
      return handleModalOperation({
        operation: () => pageTypeService.delete(input.variables.id),
        config: {
          onSuccess: () => {
            context.closeTableModal()
            context.dispatchTableAction(ACTIONS.DELETE, {
              targetItemId: input.variables.id,
            })
          },
          snackbarProps: ({ data, error }) => {
            if (data) {
              return MESSAGES.getDeletedSuccess(
                `${PAGE_MODAL_LABEL.PAGE_TYPE} "${input.variables.name}"`
              )
            }
            return (
              error?.message ??
              MESSAGES.getDeletedError(
                `${PAGE_MODAL_LABEL.PAGE_TYPE} "${input.variables.name}"`
              )
            )
          },
        },
      })
    },
    [context]
  )

  return {
    createPage,
    copyPage,
    deletePage,
    deletePageVersion,
    createPageVersion,
    activateDeactivatePage,
    archiveUnarchivePage,
    archiveUnarchiveVersion,
    createPageType,
    editPageType,
    deletePageType,
    copyPageVersion,
    editPageDetails,
  }
}
