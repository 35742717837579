import React, { memo } from 'react'

const IconAdd = props => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={18}
      height={18}
      viewBox='0 0 18 18'
      {...props}
    >
      <defs>
        <linearGradient id='a' x1='0%' x2='101.952%' y1='0%' y2='100.952%'>
          <stop offset='0%' stopColor='#929292' />
          <stop offset='99.952%' stopColor='#929292' />
        </linearGradient>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <path fillRule='nonzero' d='M-3-3h24v24H-3z' />
        <path
          fill='url(#a)'
          d='M13 11h7a1 1 0 0 1 0 2h-7v7a1 1 0 0 1-2 0v-7H4a1 1 0 0 1 0-2h7V4a1 1 0 0 1 2 0v7z'
          transform='translate(-3 -3)'
        />
      </g>
    </svg>
  )
}

export default memo(IconAdd)
