import styled from 'styled-components'

export const StyledContextMenu = styled.div`
  .item-context-menu {
    display: flex;
    justify-content: center;
  }

  .descriptor-context-item {
    align-items: flex-end;
    display: flex;

    svg {
      margin-right: ${({ theme }) => theme.space.space_4};
    }
  }
`
