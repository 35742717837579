import { StyledItems, StyledNav } from './styles'
import PropTypes from 'prop-types'
import React from 'react'

const Breadcrumb = props => {
  if (!props.children) return null

  return (
    <StyledNav aria-label='Breadcrumb'>
      <StyledItems data-testid='breadcrumb-items'>{props.children}</StyledItems>
    </StyledNav>
  )
}

Breadcrumb.defaultProps = {
  children: null,
}

Breadcrumb.propTypes = {
  children: PropTypes.node,
}

export default Breadcrumb
