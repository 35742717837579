import { PATH_PARAMS_HEADER, PROXY_HEADER } from 'src/graphql-proxy/common'
import API from 'src/business-layer-client'
import { GE_OPERATION_OPTIONS } from 'services/graphql'
import { filterName } from 'components/Filters/constants'
import getIsFilterEnabled from 'lib/getIsFilterEnabled'
import handleResponse from 'lib/handleResponse'

const {
  LIST_GE,
  FILTER_GE,
  CREATE_GE_VERSION,
  UPDATE_GE_VERSION,
  ARCHIVE_GE_VERSION,
  UNARCHIVE_GE_VERSION,
  DUPLICATE_GE_VERSION,
} = GE_OPERATION_OPTIONS

class GlobalElement {
  async getVersions({ globalElementId, isArchived = false }) {
    try {
      const response = await API.xpm.get(
        `/global-component/${globalElementId}/versions?isArchived=${isArchived}&offset=0&limit=1000`
      )
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }

  async updateVersion({ globalElementId, versionId }, payload, routeToRest) {
    const isArchivedFlag = payload?.isArchived
    let globalElementVersionOperation = UPDATE_GE_VERSION
    if (isArchivedFlag === true) {
      globalElementVersionOperation = ARCHIVE_GE_VERSION
    } else if (isArchivedFlag === false) {
      globalElementVersionOperation = UNARCHIVE_GE_VERSION
    }
    try {
      const response = await API.xpm.put(
        `/global-component/${globalElementId}/version/${versionId}`,
        payload,
        {
          headers: {
            [PROXY_HEADER]: routeToRest ? null : globalElementVersionOperation,
            [PATH_PARAMS_HEADER]: {
              globalElementId,
              versionId,
            },
          },
        }
      )
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }

  async createVersion({ globalComponentId }, payload) {
    try {
      const response = await API.xpm.post(
        `/global-component/${globalComponentId}`,
        payload,
        {
          headers: {
            [PROXY_HEADER]: CREATE_GE_VERSION,
            [PATH_PARAMS_HEADER]: {
              globalComponentId,
            },
          },
        }
      )
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }

  async deleteVersion({ globalElementId, versionId }) {
    try {
      const response = await API.xpm.delete(
        `/global-component/${globalElementId}/version/${versionId}`
      )
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }

  async list({ offset, limit, showArchivedItems, descriptorUrl, filters }) {
    try {
      const { channels, status, locales } = filterName
      const isFiltered = getIsFilterEnabled(filters)

      let response
      if (isFiltered) {
        response = await API.xpm.post(
          '/global-component/filter',
          {
            offset,
            limit,
            isArchived: showArchivedItems,
            descriptorUrl,
            status: filters[status].map(s => s.toUpperCase()),
            channels: filters[channels],
            locales: filters[locales],
          },
          {
            headers: {
              [PROXY_HEADER]: FILTER_GE,
            },
          }
        )

        return response.data || {}
      }

      response = await API.xpm.get(
        `/global-component/list?offset=${offset}&limit=${limit}&archivedFilter=${+!showArchivedItems}&descriptorUrl=${descriptorUrl}`,
        {
          headers: {
            [PROXY_HEADER]: LIST_GE,
          },
        }
      )
      return response.data || {}
    } catch (err) {
      handleResponse.error(err)
    }
  }

  async createGlobalElement(payload) {
    try {
      const response = await API.xpm.post(`/global-component`, payload)
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }

  async duplicateVersion({ globalElementId, versionId }, payload) {
    try {
      const response = await API.xpm.post(
        `/global-component/${globalElementId}/version/${versionId}`,
        payload,
        {
          headers: {
            [PROXY_HEADER]: DUPLICATE_GE_VERSION,
            [PATH_PARAMS_HEADER]: {
              globalElementId,
              versionId,
            },
          },
        }
      )
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }
}

export default new GlobalElement()
