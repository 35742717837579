import { Chip, Tooltip } from '@teamfabric/copilot-ui'
import React, { FunctionComponent } from 'react'
import {
  StyledChipItem,
  StyledChipsListWrapper,
  TOOLTIP_CLASS_NAME,
} from './styles'
import { ChipsListProps } from './types'
import { NULL_ENTRY } from 'components/ContentTableModule/constants'
import isEmpty from 'lodash/isEmpty'

export const CHIPS_MAX = 5 // only show this many chips at a time
export const TOOLTIP_TEST_ID = 'chip-list-show-more-tooltip'
export const CHIPS_CLASS_NAME = 'chip-class'
export const LEFTOVER_CHIP_CLASS_NAME = 'leftover-chip-class'

const ChipsList: FunctionComponent<ChipsListProps> = props => {
  const { chips } = props
  if (!Array.isArray(chips)) {
    return <div>{NULL_ENTRY}</div>
  }

  const chipsToShow = chips.slice(0, CHIPS_MAX)
  const chipsLeftOver = chips.slice(CHIPS_MAX)
  return (
    <StyledChipsListWrapper>
      {chipsToShow.map(chip => {
        return (
          <StyledChipItem key={chip}>
            <Chip showRemoveIcon={false} disabled className={CHIPS_CLASS_NAME}>
              {chip}
            </Chip>
          </StyledChipItem>
        )
      })}
      {!isEmpty(chipsLeftOver) && (
        <StyledChipItem moreChips>
          <Chip showRemoveIcon={false} className={LEFTOVER_CHIP_CLASS_NAME}>
            {`+${chipsLeftOver.length}`}
            <Tooltip
              text={chipsLeftOver.join(', ')}
              size='small'
              data-testid={TOOLTIP_TEST_ID}
              className={TOOLTIP_CLASS_NAME}
            />
          </Chip>
        </StyledChipItem>
      )}
    </StyledChipsListWrapper>
  )
}

export default ChipsList
