import { ApolloError, FetchResult, useApolloClient } from '@apollo/client'
import {
  BrowseMenuTreeModuleContext,
  BrowseMenuTreeOperations,
  PublishOperationInput,
} from './types'
import { EDITOR_TOAST_MESSAGES } from 'modules/editor/constants'
import { MESSAGES } from 'src/constants'
import { PUBLISH_MENU_VARIANT } from 'services/graphql'
import { PublishMenuVariantResponse } from 'modules/browse-menu-tree-gql/types'
import { handleModalOperation } from 'data/services'
import { useCallback } from 'react'

export const useBrowseMenuTreeModalOperations = ({
  moduleContext,
}: {
  moduleContext: BrowseMenuTreeModuleContext
}): BrowseMenuTreeOperations => {
  const apolloClient = useApolloClient()

  const publishMenu = useCallback(
    (input: PublishOperationInput): Promise<void> => {
      const { menuId, versionId } = input.variables

      return handleModalOperation<
        FetchResult<PublishMenuVariantResponse>,
        ApolloError
      >({
        operation: () =>
          apolloClient.mutate({
            mutation: PUBLISH_MENU_VARIANT,
            variables: {
              contentId: menuId,
              variantId: versionId,
            },
          }),
        config: {
          onSuccess: data => {
            moduleContext.closeModal()
            moduleContext.updatedMenuVariant(data?.data.immediatePublish)
          },
          snackbarProps: {
            successMessage: EDITOR_TOAST_MESSAGES.MENU_VERSION_PUBLISH_SUCCESS,
            errorMessage: MESSAGES.ERROR_PUBLISH,
          },
        },
      })
    },
    [apolloClient, moduleContext]
  )

  return { publishMenu }
}
