import Image, { uploadImageUrl } from 'services/api/image'
import {
  LABELS,
  MESSAGES,
  TOAST_MESSAGE_TYPES,
  VALIDATION_ERRORS,
} from 'src/constants'
import React, { useState } from 'react'
import {
  StyledButton,
  StyledButtonContainer,
  StyledButtonInput,
  StyledCloseButton,
  StyledDt,
  StyledImageUrlPreview,
  StyledInput,
  StyledModalHeader,
  StyledUploadChoiceContainer,
} from './styles'
import { get, noop } from 'lodash'
import {
  isCompressible,
  isValidUploadType,
  mimeTypes,
  validFilename,
} from 'lib/fileTypes'
import { useDispatch, useSelector } from 'react-redux'
import Close from 'assets/icons/IconClose'
import { EDITOR_TOAST_MESSAGES } from 'modules/editor/constants'
import Modal from 'components/Modal'
import PropTypes from 'prop-types'
import { SHOW_TOAST_MESSAGE } from 'modules/toast-messages/actions'
import Spinner from 'components/Spinner'
import { StyledErrorMessage } from 'components/Field/styles'
import { getAccountId } from 'lib/cookieUtils'
import { getConfiguration } from 'store/configuration/selectors'
import theme from 'styles/theme'

const getError = (hasError, message = '') => ({ hasError, message })

// eslint-disable-next-line sonarjs/cognitive-complexity
const ImageModal = props => {
  const { paths, value, onChange, onClose } = props
  const { ADD_IMAGE_URL, ADD_LOCAL_IMAGE, ALT_TEXT_LABEL } = LABELS

  const [uploadInProgress, setUploadInProgress] = useState(false)
  const [altText, setAltText] = useState(get(value, 'altText', ''))
  const [filename, setFilename] = useState(null)
  const savedImageUrl = get(value, 'url', '')
  const [imageUrl, setImageUrl] = useState(savedImageUrl)
  const [validationErrors, setValidationErrors] = useState(getError(false, ''))
  const fabricServiceName = 'xpm'
  const { imageCdn } = useSelector(getConfiguration)

  const dispatch = useDispatch()

  const [isImageUrlChoice, setIsImageUrlChoice] = React.useState(true)

  const saveAndClose = async () => {
    let newUrl = imageUrl.replace(/\s+/g, '')
    if (savedImageUrl !== newUrl && isImageUrlChoice) {
      newUrl = await uploadFileUrl(newUrl)
    }
    onChange({
      paths,
      value: { url: newUrl, altText },
    })
    onClose()
  }

  const accountId = getAccountId()

  const uploadFileUrl = async url => {
    setUploadInProgress(true)
    try {
      const uploadedFilename = await uploadImageUrl({
        imageUrl: url,
        fabricServiceName,
      })
      const encodedFileName = encodeURIComponent(uploadedFilename)
      const urlParts = [imageCdn, accountId, 'xpm', encodedFileName]
      dispatch(
        SHOW_TOAST_MESSAGE({
          message: MESSAGES.getSavedSuccess(url),
          kind: TOAST_MESSAGE_TYPES.SUCCESS,
        })
      )
      return urlParts.join('/')
    } catch (err) {
      dispatch(
        SHOW_TOAST_MESSAGE({
          message: EDITOR_TOAST_MESSAGES.IMAGE_PROCESS_ERROR,
          kind: TOAST_MESSAGE_TYPES.ALERT,
        })
      )
    } finally {
      setUploadInProgress(false)
    }
  }

  const uploadFile = async (uploadFilename, file) => {
    setUploadInProgress(true)
    const imageUpload = new Image(file, fabricServiceName)
    try {
      await imageUpload.upload()
      const queryParam = isCompressible(uploadFilename) ? '?auto=compress' : ''

      const url = [
        imageCdn,
        accountId,
        'xpm',
        uploadFilename + queryParam,
      ].join('/')
      setImageUrl(url)
    } catch (err) {
      dispatch(
        SHOW_TOAST_MESSAGE({
          message: `Error uploading image file: ${err}`,
          kind: TOAST_MESSAGE_TYPES.ALERT,
        })
      )
    } finally {
      setUploadInProgress(false)
    }
  }

  const handleFileChange = async event => {
    event.preventDefault()

    if (event.target.files[0]) {
      const file = event.target.files[0]
      const imageFile = new File([file], validFilename(file.name), {
        type: file.type,
      })
      const name = imageFile.name
      setFilename(name)

      if (isValidUploadType(imageFile, mimeTypes)) {
        setValidationErrors(getError(false))
      } else {
        setValidationErrors(
          getError(true, VALIDATION_ERRORS.getInvalidMediaType(name))
        )
        return
      }

      uploadFile(name, imageFile)
    }
  }

  const handleClose = event => {
    event.preventDefault()
    saveAndClose()
  }

  const updateAltText = event => {
    setAltText(event.target.value)
  }

  return (
    <Modal
      isVisible={props.isVisible}
      width='430px'
      headerText=''
      onClose={onClose}
      showClose={false}
    >
      <StyledModalHeader>
        <h5 className='modal-title'>Image</h5>
        <div className='btn-wrapper'>
          <StyledCloseButton
            color='secondary'
            className='submit-btn'
            disabled={uploadInProgress}
            size='small'
            onClick={onClose}
            data-testid='image-upload-close-button'
          >
            <Close fill={theme.colors.gray['400']} />
          </StyledCloseButton>
        </div>
      </StyledModalHeader>
      <StyledImageUrlPreview>
        <div className='image-preview' data-testid='image-preview'>
          {uploadInProgress && !isImageUrlChoice && (
            <Spinner small={true} data-testid='image-spinner' />
          )}
          {imageUrl && (!uploadInProgress || isImageUrlChoice) && (
            <img
              data-testid='image-preview-img'
              src={imageUrl}
              alt='Upload or link to an image'
              loading='lazy'
            />
          )}
        </div>
      </StyledImageUrlPreview>
      <StyledUploadChoiceContainer>
        <div
          className={`choice-button ${isImageUrlChoice ? 'active' : ''}`}
          onClick={() => {
            setIsImageUrlChoice(true)
            setImageUrl(savedImageUrl)
          }}
        >
          {ADD_IMAGE_URL}
        </div>
        <div
          className={`choice-button ${!isImageUrlChoice ? 'active' : ''}`}
          onClick={() => {
            setIsImageUrlChoice(false)
            setImageUrl(savedImageUrl)
          }}
        >
          {ADD_LOCAL_IMAGE}
        </div>
      </StyledUploadChoiceContainer>
      <StyledDt
        label={ADD_IMAGE_URL}
        style={{ display: !isImageUrlChoice ? 'none' : '' }}
        data-testid='image-upload-url-dt'
        content={
          <StyledInput
            id='image-url-text'
            required
            data-testid='image-upload-url'
            inputProps={{
              type: 'url',
              onChange: e => setImageUrl(e.target.value),
              value: imageUrl,
            }}
          />
        }
      />
      <StyledDt
        style={{ display: isImageUrlChoice ? 'none' : '' }}
        label={ADD_LOCAL_IMAGE}
        data-testid='image-upload-file-input-dt'
        content={
          <>
            <div>
              <StyledButtonInput>
                <label htmlFor='image-upload-file-input'>Choose</label>
                <input
                  id='image-upload-file-input'
                  data-testid='image-upload-file-input'
                  type='file'
                  onChange={handleFileChange}
                />
                <input
                  type='text'
                  data-testid='image-upload-file-text-input'
                  value={filename ?? ''}
                  onChange={noop}
                />
              </StyledButtonInput>
            </div>
            {validationErrors.hasError && validationErrors.message && (
              <StyledErrorMessage data-testid='image-upload-file-error-section'>
                <span>{validationErrors.message}</span>
              </StyledErrorMessage>
            )}
          </>
        }
      />
      <StyledDt
        label={ALT_TEXT_LABEL}
        data-testid='image-upload-alt-text-dt'
        content={
          <StyledInput
            id='alt-text'
            data-testid='image-upload-alt-text'
            inputProps={{
              type: 'text',
              onChange: updateAltText,
              value: altText,
            }}
          />
        }
      />
      <StyledButtonContainer>
        <StyledButton
          color='secondary'
          size='small'
          disabled={uploadInProgress}
          onClick={onClose}
          data-testid='image-upload-cancel-button'
        >
          Cancel
        </StyledButton>
        <StyledButton
          size='small'
          disabled={uploadInProgress || imageUrl === ''}
          onClick={handleClose}
          data-testid='image-upload-upload-button'
          className='cancel'
        >
          {uploadInProgress ? 'Saving...' : 'Add Image'}
        </StyledButton>
      </StyledButtonContainer>
    </Modal>
  )
}

ImageModal.defaultProps = {
  onClose: noop,
  isVisible: false,
}

ImageModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  paths: PropTypes.array.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ImageModal
