import { PATH_PARAMS_HEADER, PROXY_HEADER } from 'src/graphql-proxy/common'
import API from 'src/business-layer-client'
import { GE_OPERATION_OPTIONS } from 'services/graphql/globalElements'
import handleResponse from 'lib/handleResponse'

class GcComponent {
  async save({ gcId, versionId }, payload) {
    try {
      const response = await API.xpm.put(
        `/global-component/${gcId}/version/${versionId}/component`,
        payload,
        {
          headers: {
            [PROXY_HEADER]: GE_OPERATION_OPTIONS.UPDATE_GE_COMPONENT,
            [PATH_PARAMS_HEADER]: {
              globalElementId: gcId,
              versionId,
            },
          },
        }
      )

      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }

  async update({ gcId, versionId }, payload) {
    try {
      const response = await API.xpm.put(
        `/global-component/${gcId}/version/${versionId}`,
        payload
      )
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }
}

export default new GcComponent()
