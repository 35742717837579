import styled from 'styled-components'
import theme from 'styles/theme'

export const StyledWrapper = styled.div`
  padding: ${theme.space.space_6} 0;
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: 80% 1fr;
  label.input__parent {
    padding-top: 0px !important;
  }
  [name='content-search'] {
    padding-left: 35px !important;
  }
  .ac-padding {
    padding-right: 1em;
  }

  .input__wrapper {
    border-bottom: 0 !important;

    .icon {
      position: absolute !important;
      padding-left: 0px !important;
      top: 30% !important;
    }
  }
`
