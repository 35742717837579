import PropTypes from 'prop-types'
import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import SortableItem from 'modules/menu/components/SortableItem'
import { StyledList } from './styles'
import { nanoid } from 'nanoid'

const SortableList = SortableContainer(props => {
  const { menus, ...rest } = props

  return (
    <StyledList data-testid='sortable-list'>
      {menus.map((menu, index) => (
        <SortableItem key={nanoid()} index={index} menu={menu} {...rest} />
      ))}
    </StyledList>
  )
})

SortableList.propTypes = {
  menus: PropTypes.array.isRequired,
}

export default SortableList
