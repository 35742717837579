import React, { memo } from 'react'

const IconDotsVertical = props => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='6'
      height='19'
      viewBox='0 0 6 19'
      data-testid='icon-dots-vertical'
      {...props}
    >
      <g fill='none' fillRule='nonzero'>
        <path d='M-8-2h23v23H-8z' />
        <path
          fill='#8994a3'
          d='M3.5 11.417a1.917 1.917 0 1 1 0-3.834 1.917 1.917 0 0 1 0 3.834zm0 6.708a1.917 1.917 0 1 1 0-3.833 1.917 1.917 0 0 1 0 3.833zm0-13.417a1.917 1.917 0 1 1 0-3.833 1.917 1.917 0 0 1 0 3.833z'
          {...props}
        />
      </g>
    </svg>
  )
}

export default memo(IconDotsVertical)
