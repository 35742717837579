import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledListItem = styled.li`
  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.gray[100]};
  border-top: none;
  ${props => props.theme.typography.body};
  text-transform: none;
  cursor: pointer;
  background: ${({ selected, theme }) =>
    selected ? theme.colors.gray[100] : theme.colors.white};

  &.dragging-helper-class {
    cursor: grabbing;
    pointer-events: auto !important;
    ${themeUi.shadows.light.level1}
  }
`

export const StyledListItemText = styled.div`
  ${props => props.theme.typography.body};
  padding: ${({ theme }) => `${theme.space.space_3} ${theme.space.space_5}`};
  color: ${({ isActive, theme }) =>
    isActive ? `${theme.colors.black[500]}` : `${theme.colors.gray[500]}`};
  font-weight: ${props => props.theme.typography.fontWeight.medium};
`

export const StyledEditableForm = styled.form`
  display: grid;
  grid-template-columns: 24px 1fr 24px;
  padding: ${({ theme }) => `0 ${theme.space.space_3}`};
  border: 2px solid ${props => props.theme.colors.gray[500]};
  svg {
    cursor: pointer;
  }
`

export const StyledEditableInput = styled.input`
  ${({ theme }) => theme.typography.body};
  height: 2rem;
  padding: ${props => props.theme.space.space_3};
  outline: none;
  background: transparent;
  border: none;
  border-radius: ${props => props.theme.borderRadius.none};
  &:focus {
    border-color: ${props => props.theme.colors.gray[500]};
  }
`
