import React, { useState } from 'react'
import { SortableComponentProps } from './types'
import { SortableContainer } from 'react-sortable-hoc'
import SortableItem from './SortableItem'
import { StyledComponentList } from './styles'
import { Tooltip } from '@teamfabric/copilot-ui/dist/atoms/tooltip/Tooltip'

const SortableList = SortableContainer((props: SortableComponentProps) => {
  const {
    curatedComponents,
    handleOnUpdateComponent,
    isGlobalComponentEditor,
  } = props
  const [tooltipText, setTooltipText] = useState('')

  return (
    <StyledComponentList data-testid='sortable-list'>
      <Tooltip
        className='custom-tooltip'
        size='small'
        data-testid='sortable-list-tooltip'
      >
        {tooltipText}
      </Tooltip>
      {curatedComponents.map(
        (component, idx): React.ReactNode => (
          <SortableItem
            key={component.key}
            index={idx} // This index is consumed by SortableElement and won't be available. Use position props
            position={idx}
            component={component}
            setTooltipText={setTooltipText}
            isGlobalComponentEditor={isGlobalComponentEditor}
            handleOnUpdateComponent={handleOnUpdateComponent}
          />
        )
      )}
    </StyledComponentList>
  )
})

export default SortableList
