import { FILTER_MODULE_ID } from 'src/components/ContentFilterModule/ContentFilterModule'
import styled from 'styled-components'

export const ContentListModuleWrapper = styled.div`
  padding: 40px;

  #${FILTER_MODULE_ID} {
    display: flex;
    padding: 1em 0px 2.5em 0px;
    align-items: center;
    justify-content: flex-end;
  }
`
