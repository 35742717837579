import { FormSubmitActionProps, VersionTypesEnum } from '../types'
import contentService from 'services/api/content'
import globalElementService from 'services/api/globalElement'
import isNil from 'lodash/isNil'
import pageService from 'services/api/page'

export const EDITOR_CREATE_NEW_VERSION = (
  data: FormSubmitActionProps
): Promise<void> => {
  const {
    channels,
    versionName,
    description,
    contentType,
    parentName,
    locales,
    parentId,
    graphQLOperation,
  } = data
  const payload: {
    parentContentId: string
    name: string
    description: string
    channels?: string[]
    locales?: string[]
  } = {
    parentContentId: parentId,
    name: versionName,
    description,
  }

  if (!isNil(channels)) {
    payload.channels = channels.map(channel => channel.id)
  }

  if (!isNil(locales)) {
    payload.locales = locales.map(l => l.id)
  }

  if (contentType === VersionTypesEnum.MENUS) {
    return graphQLOperation({
      variables: {
        ...payload,
      },
    })
  } else {
    // TODO: Check the content type and conditionally call the page service
    return globalElementService.createVersion(
      { globalComponentId: parentName },
      payload
    )
  }
}

export const EDITOR_DUPLICATE_VERSION_DETAILS = ({
  shouldUseGraphQL,
  graphQLOperation,
  parentName,
  versionName,
  description,
  contentType,
  versionId,
  parentId,
  restEndpoint,
}: FormSubmitActionProps): Promise<void> => {
  const payload: {
    versionName: string
    description: string
    pageName?: string
    globalComponent?: string
  } = {
    versionName,
    description,
  }
  if (
    contentType === VersionTypesEnum.PAGES ||
    contentType === VersionTypesEnum.MENUS
  ) {
    if (shouldUseGraphQL) {
      const graphQLInput: {
        originalContentVariantId: string
        parentContentId: string
        name: string
        description: string
      } = {
        originalContentVariantId: versionId,
        parentContentId: parentId,
        name: versionName,
        description,
      }

      return graphQLOperation({
        variables: {
          input: graphQLInput,
        },
      })
    } else {
      payload.pageName = parentName
      //TODO: Create and use a pageService.duplicateVersion() for copying page versions here
      return contentService.duplicateVersion({
        endpoint: restEndpoint,
        payload,
      })
    }
  } else {
    payload.globalComponent = parentName
    return globalElementService.duplicateVersion(
      {
        versionId,
        globalElementId: parentName,
      },
      payload
    )
  }
}

export const EDITOR_UPDATE_VERSION_DETAILS = ({
  shouldUseGraphQL,
  graphQLOperation,
  versionName,
  description,
  versionId,
  parentId,
  contentType,
  channels = null,
  locales = null,
}: FormSubmitActionProps): Promise<void> => {
  if (shouldUseGraphQL && graphQLOperation) {
    const graphQLInput: {
      contentVariantId: string
      parentContentId: string
      name: string
      description: string
      channels?: string[]
    } = {
      contentVariantId: versionId,
      parentContentId: parentId,
      name: versionName,
      description,
    }
    if (!isNil(channels)) {
      graphQLInput.channels = channels.map(channel => channel.id)
    }
    return graphQLOperation({
      variables: {
        input: graphQLInput,
      },
    })
  } else {
    // TODO: This can be removed when we move over to GraphQL
    const rawData: {
      description: string
      versionName?: string // for PUT actions on pages
      name?: string // for PUT actions on global elements
      channels?: string[]
      locales?: string[]
    } = {
      description,
    }

    const pathData: {
      versionId: string
      pageId?: string
      globalElementId?: string
    } = {
      versionId,
    }

    if (!isNil(channels)) {
      rawData.channels = channels.map(channel => channel.id)
    }

    if (!isNil(locales)) {
      rawData.locales = locales.map(locale => locale.id)
    }

    if (contentType === VersionTypesEnum.PAGES) {
      rawData.versionName = versionName
      pathData.pageId = parentId

      return pageService.updateVersion(pathData, rawData)
    } else {
      rawData.name = versionName
      pathData.globalElementId = parentId

      return globalElementService.updateVersion(pathData, rawData)
    }
  }
}
