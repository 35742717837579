import AllComponentsMode from 'modules/settings/utils/AllComponentsMode'
import ComponentViewMode from 'modules/settings/utils/ComponentViewMode'
import { DescriptorProps } from 'store/types'
import ViewComponentMode from './ViewComponentMode'

class EditComponentMode extends ComponentViewMode {
  retrieveId(descriptorId?: string) {
    return descriptorId
  }

  retrieveOnNavigateBack(
    setMode: (mode: ComponentViewMode) => void,
    setSelected: (
      value: {
        descriptor: DescriptorProps
      } | null
    ) => void
  ) {
    return () => {
      setMode(AllComponentsMode)
      setSelected(null)
    }
  }

  isEditMode() {
    return true
  }

  showDescriptorLabelError(descriptorLabelLength: number) {
    return !descriptorLabelLength
  }

  retrieveHeader(label: string) {
    return label
  }

  setModeAfterSave(setMode: (mode: ComponentViewMode) => void) {
    setMode(ViewComponentMode)
  }

  showDescriptorIdError() {
    return false
  }

  shouldHideSubmitButton(singleDescriptor: DescriptorProps) {
    return singleDescriptor?.label.trim() === ''
  }

  shouldShowDeleteButton() {
    return true
  }
}

export default new EditComponentMode()
