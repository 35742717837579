import {
  COMPONENT_IMAGE_TYPE,
  MANAGE_NODE_SIDEBAR_LABEL,
  MODAL_ACTION,
} from 'src/constants'
import {
  FORM_FIELD_NAMES,
  ManageNodeFormValues,
  ManageNodeModalConfigProps,
  ManageNodeViewProps,
} from './types'
import React, { FunctionComponent, useState } from 'react'
import {
  StyledSideContent,
  StyledSideEditor,
} from 'components/SideEditor/styles'
import AssetSection from './AssetSection'
import AttributesSection from './AttributesSection'
import ContentModalModule from 'components/ContentModalModule'
import { Formik } from 'formik'
import ManageNodeViewHeader from './ManageNodeViewHeader'
import { ModalType } from 'src/components/ContentModalModule/types'
import NodeInformationSection from './NodeInformationSection'
import theme from 'styles/theme'
const TRANSITION_MS = 1000

const validateManageNodeValues = (
  values: ManageNodeFormValues
): Record<string, string> => {
  const errors = {}
  const urlValue = values[FORM_FIELD_NAMES.url]
  if (urlValue) {
    // Putting the eslint disable line here as removing the escape characters result in the code not working as intended
    // eslint-disable-next-line no-useless-escape
    const invalidURLCharsRegex = /[\[\]{}\|\\"%~#<>]/g
    if (urlValue.includes(' ')) {
      errors[FORM_FIELD_NAMES.url] = MANAGE_NODE_SIDEBAR_LABEL.ERRORS.NO_SPACES
    } else if (urlValue.match(invalidURLCharsRegex)) {
      errors[FORM_FIELD_NAMES.url] =
        MANAGE_NODE_SIDEBAR_LABEL.ERRORS.INVALID_CHARS
    }
  }
  return errors
}

const ManageNodeView: FunctionComponent<ManageNodeViewProps> = ({
  node,
  visible = false,
  onSave,
  onCancel,
  locales,
  selectedLocale,
}) => {
  const {
    _id,
    url,
    name,
    attributes = [],
    images = [],
    rootNodeName,
    section,
  } = node
  const itemCategory = section ? 'section' : 'node'
  const [currentModalConfig, setCurrentModalConfig] = useState<
    ManageNodeModalConfigProps
  >(null)
  return (
    <StyledSideEditor zIndex={500}>
      <StyledSideContent
        right={visible ? '0' : theme.constants.sideEditorWidth}
        transitionTime={TRANSITION_MS}
      >
        <Formik
          initialValues={{
            [FORM_FIELD_NAMES.url]: url,
            [FORM_FIELD_NAMES.rootNodeName]: rootNodeName,
            [FORM_FIELD_NAMES.nodeName]: name,
            [FORM_FIELD_NAMES.attributes]: attributes,
            [FORM_FIELD_NAMES.images]: images,
            [FORM_FIELD_NAMES.nodeId]: _id,
          }}
          onSubmit={onSave}
          validate={validateManageNodeValues}
        >
          {formikProps => {
            const { handleSubmit } = formikProps
            return (
              <form onSubmit={handleSubmit}>
                <ManageNodeViewHeader
                  onCancel={onCancel}
                  onSave={handleSubmit}
                  title={MANAGE_NODE_SIDEBAR_LABEL.getTitle(itemCategory)}
                />
                <NodeInformationSection
                  title={`${itemCategory} selected`}
                  locales={locales}
                  selectedLocale={selectedLocale}
                  {...formikProps}
                />
                <AssetSection
                  setCurrentModalConfig={setCurrentModalConfig}
                  locales={locales}
                  {...formikProps}
                />
                <AttributesSection {...formikProps} />
              </form>
            )
          }}
        </Formik>
      </StyledSideContent>
      {currentModalConfig && (
        <ContentModalModule
          visibleModalId={currentModalConfig.visibleModalId}
          modalOptions={{
            [MODAL_ACTION.REMOVE_ASSET]: {
              title: COMPONENT_IMAGE_TYPE.CONFIRMATION.heading,
              size: 'small',
              modalType: ModalType.Alert,
              config: {
                content: COMPONENT_IMAGE_TYPE.CONFIRMATION.text,
                confirmButton: {
                  text: COMPONENT_IMAGE_TYPE.CONFIRMATION.submitText,
                  onClick: currentModalConfig.onConfirm,
                },
                cancelButton: {
                  text: COMPONENT_IMAGE_TYPE.CONFIRMATION.cancelText,
                  onClick: currentModalConfig.onCancel,
                },
              },
            },
          }}
        />
      )}
    </StyledSideEditor>
  )
}

export default ManageNodeView
