import { PUBLISH_BUTTON_CLASS_NAME, SAVE_BUTTON_CLASS_NAME } from './constants'
import styled from 'styled-components'
import theme from 'styles/theme'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledUpdatedAtWrapper = styled.div`
  ${themeUi.typography.h6};
  color: ${themeUi.palette.brand.primary.gray};
  margin: ${theme.space.space_3} 0;
  padding: 0 ${theme.space.space_2};
`
export const StyledFilterContainer = styled.div`
  padding-right: 10px;
`
export const StyledContentEditorCTAContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;

  > div {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;

    .${SAVE_BUTTON_CLASS_NAME} {
      height: 36px;
    }
  }
`

export const StyledDropdownContent = styled.ul`
  position: absolute;
  top: 40px;
  right: 0;
  width: 200px;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid ${themeUi.palette.ui.neutral.grey2};
  border-radius: ${props => props.theme.borderRadius.default};
  background: ${themeUi.palette.brand.primary.white};
  ${themeUi.shadows.light.level2}
  ${themeUi.typography.h6}
`

export const StyledDropdownItem = styled.li`
  padding: 0.8rem 1.2rem;
  font-family: ${props => props.theme.typography.fontFamily.sans};
  font-style: normal;
  font-weight: ${props => props.theme.typography.fontWeight.medium};
  cursor: pointer;

  &:hover {
    background: ${themeUi.palette.ui.neutral.grey5};
  }
`

export const StyledButtonWithIconWrapper = styled.div`
  position: relative;
  margin-left: ${theme.space.space_2};

  .${PUBLISH_BUTTON_CLASS_NAME} {
    padding-right: 1.75rem;
    border-color: transparent;
    ${themeUi.shadows.light.level1}
  }

  svg {
    cursor: pointer;
    position: absolute;
    fill: ${themeUi.palette.brand.primary.white};
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
`
