import styled from 'styled-components'
import theme from 'styles/theme'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr;
  align-items: start;
  padding: ${theme.space.space_3} ${theme.space.space_5};

  & > div:nth-child(2) {
    display: grid;
    place-items: center;
  }

  & > div:nth-child(3) {
    display: flex;
    justify-content: flex-end;
  }

  &.disabled {
    pointer-events: none;
  }
`

export const StyledContainerItem = styled.div`
  align-items: center;
  display: flex;
`

export const StyledSaveTimeDurationText = styled.div`
  ${props => props.theme.typography.bodySmall};
  color: ${themeUi.palette.brand.primary.gray};
  margin: ${theme.space.space_3} 0;
`

export const StyledUpdatedAtWrapper = styled.div`
  ${themeUi.typography.h6};
  color: ${themeUi.palette.brand.primary.gray};
  margin: ${theme.space.space_3} 0;
  padding: 0 ${theme.space.space_2};
`

export const StyledRightColumn = styled.div`
  display: flex;
  gap: ${theme.space.space_5};
`

export const StyledChannelItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
