import ErrorException from './errorException'
import extract from 'lib/extract'
import httpStatusCodes from 'http-status-codes'
import isEmpty from 'lodash/isEmpty'

class HandleResponse {
  success = (response, defaultValue = {}) => {
    const statusCode = extract.statusCode(response)
    const graphQLErrors = extract.graphQLError(response)

    if (!isEmpty(graphQLErrors)) {
      return this.error(graphQLErrors[0])
    } else if (
      statusCode >= httpStatusCodes.OK &&
      statusCode < httpStatusCodes.BAD_REQUEST
    ) {
      return extract.data(response)
    } else {
      return defaultValue
    }
  }

  error = err => {
    let errMessage
    // 401 Unauthorized error should not be propagated to UI
    // We already manage refreshing token automatically
    try {
      if (
        err.response &&
        err.response.status !== httpStatusCodes.UNAUTHORIZED
      ) {
        errMessage = extract.response(err)?.message ?? err.message
      } else {
        errMessage = extract.request(err)?.message ?? err.message
      }
    } catch (e) {
      console.error('Error fetching response status', e)
      errMessage = e
    }
    console.error(errMessage)
    throw new ErrorException(
      errMessage,
      err?.response?.status ??
        err?.extensions?.errorCode ??
        err?.status ??
        httpStatusCodes.INTERNAL_SERVER_ERROR
    )
  }
}

export default new HandleResponse()
