import { I18nLocale, I18nLocaleResponse } from './types'

export const updateListWithDefaultLabel = ({
  defaultLocale,
  locales,
}: I18nLocaleResponse): I18nLocale[] => {
  return locales.map(locale => {
    return {
      ...locale,
      isDefault: defaultLocale.code === locale.code,
    }
  })
}
