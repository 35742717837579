import { HandleUploadProps } from '../types'
import { Modal } from '@teamfabric/copilot-ui'
import React from 'react'
import { SETTINGS } from 'src/constants'
import { StyledDialogContent } from '../styles'
import { handleUpload } from '../utils'

interface ConfirmUploadProps extends HandleUploadProps {
  handleModalClose: () => void
}

const ConfirmUploadModal = ({
  handleModalClose,
  storedDescriptors,
  descriptorsToUpdate,
  descriptorsToCreate,
  createUniversalDescriptor,
  updateUniversalDescriptor,
}: ConfirmUploadProps): JSX.Element => {
  return (
    <Modal
      onBackdropClick={handleModalClose}
      onClose={handleModalClose}
      padding={SETTINGS.CONFIRM_UPLOAD_DIALOG.PADDING}
      showCloseButton={true}
      headerText={SETTINGS.CONFIRM_UPLOAD_DIALOG.HEADING}
      headerButtons={[]}
      footerButtons={[
        {
          onClick: handleModalClose,
          text: SETTINGS.CONFIRM_UPLOAD_DIALOG.CANCEL,
        },
        {
          onClick: () => {
            handleModalClose()
            void handleUpload({
              storedDescriptors,
              descriptorsToCreate,
              descriptorsToUpdate,
              createUniversalDescriptor,
              updateUniversalDescriptor,
            })
          },
          text: SETTINGS.CONFIRM_UPLOAD_DIALOG.SUBMIT,
          isPrimary: true,
        },
      ]}
      size='small'
      portalize={false}
      render={() => {
        return (
          <StyledDialogContent>
            <p>
              <strong>
                {storedDescriptors.length - descriptorsToUpdate.length}
              </strong>{' '}
              {SETTINGS.CONFIRM_UPLOAD_DIALOG.TO_UPLOAD}
            </p>
            <p>
              <strong>{descriptorsToUpdate.length}</strong>{' '}
              {SETTINGS.CONFIRM_UPLOAD_DIALOG.TO_UPDATE}
            </p>
          </StyledDialogContent>
        )
      }}
    />
  )
}

export default ConfirmUploadModal
