import { StyledList, StyledListItem } from './styles'
import PropTypes from 'prop-types'
import React from 'react'

const List = ({ children, ...rest }) => (
  <StyledList {...rest}>{children}</StyledList>
)

List.defaultProps = {
  children: '',
}

List.propTypes = {
  children: PropTypes.node.isRequired,
}

export default List

export const ListItem = ({ children, ...rest }) => (
  <StyledListItem {...rest}>{children}</StyledListItem>
)

ListItem.defaultProps = {
  children: '',
}

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
}
