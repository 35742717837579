import { ROW_CLASS_NAME, TABLE_COLUMN_OPTIONS } from './constants'
import { CHIPS_CLASS_NAME } from 'components/ChipsList/ChipsList'
import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const LOCALE_COLUMN_WIDTH = '250px'

export const StyledContentTableWrapper = styled.div`
  .${TABLE_COLUMN_OPTIONS.LOCALE}-column-class {
    width: ${LOCALE_COLUMN_WIDTH};
  }

  .${ROW_CLASS_NAME}:hover {
    .${CHIPS_CLASS_NAME} {
      background: ${themeUi.palette.ui.neutral.grey4 as string};
    }
  }
  .type-name {
    margin-top: 8px;
    ${themeUi.typography.caption.css};
    color: ${themeUi.palette.brand.primary.gray};
  }
`
