import { useEffect, useRef } from 'react'

// Implements a useEffect hook that does not run on initial render
const useDidMountEffect = (func: () => void, deps: unknown[]): void => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) func()
    else didMount.current = true

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export default useDidMountEffect
