import {
  LocalizedSeoFields,
  MetadataProps,
  SeoFieldActionFunctionProps,
} from '../types'
import { DEFAULT_METADATA } from './utils'

export const LocalizedSeoFieldAction = ({
  localizedSeoFields,
  selectedLocale,
}: {
  localizedSeoFields: LocalizedSeoFields
  selectedLocale: {
    id: string
    name: string
  }
}): SeoFieldActionFunctionProps => {
  const currLocalizedSeoField = localizedSeoFields[selectedLocale.id]

  return {
    isLocalized: () => true,
    getSeoTitle: () => ({
      name: `localizedSeoFields.${selectedLocale.id}.title`,
      value: currLocalizedSeoField?.title ?? '',
    }),
    getDescription: () => ({
      name: `localizedSeoFields.${selectedLocale.id}.description`,
      value: currLocalizedSeoField?.description ?? '',
    }),
    getMetadata: () => currLocalizedSeoField?.metadata ?? DEFAULT_METADATA,
    getMetadataFieldName: () =>
      `localizedSeoFields.${selectedLocale.id}.metadata`,
  }
}

export const SeoFieldAction = ({
  values,
}: {
  values: {
    title: string
    description: string
    metadata: MetadataProps[]
  }
}): SeoFieldActionFunctionProps => {
  const { title, description, metadata } = values
  return {
    isLocalized: () => false,
    getSeoTitle: () => ({
      name: 'title',
      value: title,
    }),
    getDescription: () => ({
      name: 'description',
      value: description,
    }),
    getMetadata: () => metadata,
    getMetadataFieldName: () => 'metadata',
  }
}
