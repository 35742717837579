import IconClose from 'assets/icons/IconClose'
import styled from 'styled-components'

export const StyledEditSettingForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  > div {
    margin-bottom: 0.5rem;
  }
  .input_label {
    font-size: ${props => props.theme.typography.fontSize.xs};
    color: ${({ theme }) => theme.colors.gray[500]} !important;
    margin-bottom: 0 !important;
  }
`

export const StyledLabel = styled.label`
  font-size: ${props => props.theme.typography.fontSize.xs};
  font-weight: ${props => props.theme.typography.fontWeight.semibold};
  line-height: ${props => props.theme.typography.lineHeight.normal};
  color: ${({ theme }) => theme.colors.gray[500]};
  margin-bottom: 0;
`

export const StyledInlineInput = styled.div`
  display: grid;
  grid-template-columns: 28px 1fr 1fr 28px;
  grid-gap: 15px;
`

export const StyledButtonWrapper = styled.div`
  margin: 0;
  margin-top: 1rem;
  display: flex !important;
  justify-content: flex-end;

  & > button:first-child {
    margin-right: ${props => props.theme.space.space_5};
  }
`

export const StyledRowLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const StyledSwitchWrapper = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
`

export const StyledIconClose = styled(IconClose)`
  cursor: pointer;
`
