import {
  // // Will be added back in XM-4441 and XM-4442
  // AutoComplete,
  // Button,
  ButtonWithIcon,
  Tab,
  TabItem,
} from '@teamfabric/copilot-ui'
import React, { ReactElement } from 'react'
import { StyledHeaderWrapper, StyledSpinnerWrapper } from './styles'
import { SETTINGS } from 'src/constants'
import { XM_EDIT_DESCRIPTORS_ROLE } from 'src/rbac-constants'
// import { ThemeProps } from 'store/types' // Add back in XM-4442
import cn from 'classnames'
import noop from 'lodash/noop'
import { useRbacPermission } from 'hooks/useRbacPermission'

// import { withTheme } from 'styled-components' // Add back in XM-4442

const Header = ({
  onFileInputChange,
  handleFetchDescriptors,
  handleClickAddComponent,
  loading,
}: // theme, // Add back in XM-4442
{
  onFileInputChange: ({ target }: { target: HTMLElement }) => void
  handleFetchDescriptors: () => void
  handleClickAddComponent: () => void
  loading: boolean
  // theme: ThemeProps //Add back in XM-4442
}): ReactElement => {
  const hasAccess = useRbacPermission(XM_EDIT_DESCRIPTORS_ROLE)
  const retrieveAddingComponent = () => {
    if (hasAccess) {
      return (
        <>
          <ButtonWithIcon
            emphasis='low'
            icon='Add'
            iconPosition='left'
            data-testid='add-component-label'
            isPrimary={false}
            onClick={handleClickAddComponent}
            text={HEADER.ADD_COMPONENT}
            theme='light'
          />
          <StyledSpinnerWrapper>
            <label
              htmlFor='bulk-upload-input-trigger'
              data-testid='bulk-upload-label'
              className={cn('facade-button', { disabled: loading })}
              onClick={handleFetchDescriptors}
            >
              {HEADER.BULK_UPLOAD}
            </label>
          </StyledSpinnerWrapper>
        </>
      )
    }
  }
  const { HEADER } = SETTINGS
  return (
    <StyledHeaderWrapper>
      <div className='tabs'>
        <h2>{HEADER.XM_SETTINGS}</h2>
        <Tab
          className='tab-developers'
          data-testid='settings-tab-wrapper'
          tabChangeHandler={noop}
        >
          <TabItem
            title={HEADER.DEVELOPERS}
            data-testid='settings-developers-tag'
          ></TabItem>
        </Tab>
        <div className='buttons'>
          {/* Add back in XM-4442 */}
          {/* <ButtonWithIcon
            icon='Add'
            iconPosition='left'
            isPrimary={false}
            isRoundIcon={false}
            text={HEADER.ADD_COMPONENTS}
            theme={theme.button.theme.light}
            onClick={noop}
          /> */}
          <input
            type='file'
            id='bulk-upload-input-trigger'
            data-testid='bulk-upload-input'
            className='hidden-file-input'
            onChange={onFileInputChange}
            accept={HEADER.ACCEPT_FILE_TYPES[0]}
          />
          {retrieveAddingComponent()}
        </div>
      </div>
      {/* Add back in XM-4441 */}
      {/* <div data-testid='settings-search' className='settings-search'>
        <AutoComplete
          inputProps={{
            icon: HEADER.SEARCH,
            className: 'settings-search-autocomplete',
            isFloatedLabel: false,
            inputProps: {
              placeholder: HEADER.INPUT_PLACEHOLDER,
              onChange: noop,
              value: '',
            },
          }}
          autoCompleteProps={{
            isLoading: false,
            show: false,
            onSelect: noop,
            data: {},
            onSearchAll: noop,
            toggleSearchAll: false,
            onClearSearch: noop,
            onEscPress: noop,
            onBodyClick: noop,
          }}
        />
        <Button
          data-testid='settings-search-button'
          text={HEADER.SEARCH}
          onClick={noop}
        />
      </div> */}
    </StyledHeaderWrapper>
  )
}

// export default withTheme(Header)
export default Header
