class Extract {
  data(response, defaultValue = {}) {
    return response?.data?.data ?? response.data ?? defaultValue
  }

  statusCode(response) {
    return (
      response?.data?.status_code ??
      response?.data?.statusCode ??
      response?.data?.status ??
      response.status ??
      NaN
    )
  }

  response(error, defaultValue = {}) {
    return error.response?.data ?? defaultValue
  }

  request(error, defaultValue = {}) {
    return error.request?.data ?? defaultValue
  }

  errorString(error) {
    return typeof error === 'string' ? error : error.message
  }

  graphQLError(response) {
    const { config, data } = response
    return config?.headers?.ReroutedToGraphQL && data?.errors
  }

  queryParams(queryString, isFullUrl = false) {
    let queries
    if (isFullUrl) {
      const urlObj = new URL(queryString)
      queries = new URLSearchParams(urlObj.search)
    } else {
      queries = new URLSearchParams(queryString)
    }
    return Object.fromEntries(queries.entries())
  }
}

export default new Extract()
