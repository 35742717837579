import { MESSAGES } from 'src/constants'

const PAGE = 'Page'
const PAGE_VERSION = 'Page version'
const GE_VERSION = 'Global element version'
const MENU_VERSION = 'Menu version'
const VERSION = 'Version'

export const EDITOR_TOAST_MESSAGES = {
  PAGE_VERSION_NOT_AVAILABLE:
    'Page / Version information not available. Redirecting to pages',
  GLOBAL_ELEMENT_VERSION_NOT_AVAILABLE:
    'Global Element / Version information not available. Redirecting to global elements',
  STOREFRONT_URL_NOT_VALID:
    'Please check the validity of storefront URL provided during setup',
  PAGE_PUBLISH_SUCCESS: MESSAGES.getPublishedSuccess(PAGE),
  GLOBAL_ELEMENT_PUBLISH_SUCCESS: MESSAGES.getPublishedSuccess(GE_VERSION),
  PAGE_VERSION_PUBLISH_SUCCESS: MESSAGES.getPublishedSuccess(PAGE_VERSION),
  MENU_VERSION_PUBLISH_SUCCESS: MESSAGES.getPublishedSuccess(MENU_VERSION),
  GLOBAL_ELEMENT_SCHEDULE_SUCCESS: MESSAGES.getScheduledSuccess(GE_VERSION),
  PAGE_VERSION_SCHEDULE_SUCCESS: MESSAGES.getScheduledSuccess(PAGE_VERSION),
  MENU_VERSION_SCHEDULE_SUCCESS: MESSAGES.getScheduledSuccess(MENU_VERSION),
  IMAGE_PROCESS_ERROR:
    'Error processing image url, please ensure it is valid and try again.',
  COMPONENTS_MAX_DEPTH:
    'You can add unlimited number of nested components. Please note that adding more than 7 may negatively affect your website performance.',
  COMPONENT_ADD_SUCCESS: 'Component added',
  PAGE_VERSION_EDIT_SUCCESS: MESSAGES.getSavedSuccess(PAGE_VERSION),
  PAGE_VERSION_EDIT_FAILURE:
    'Error while updating page version, please try again. If the issue persists please contact fabric support.',
  GLOBAL_ELEMENT_VERSION_CREATE_SUCCESS: MESSAGES.getCreatedSuccess(VERSION),
  GLOBAL_ELEMENT_VERSION_EDIT_SUCCESS: MESSAGES.getSavedSuccess(VERSION),
  MENU_VERSION_EDIT_FAILURE:
    'Error while updating menu version, please try again. If the issue persists please contact fabric support.',
}

export const VERSION_STATUS = {
  LIVE: 'LIVE',
  SCHEDULED: 'SCHEDULED',
  DRAFT: 'DRAFT',
}

export const DELETE_MODAL_CONTENT = {
  title: 'Deleting',
  content: 'Are you sure you would like to delete this component?',
  cancelText: 'Cancel',
  submitText: 'Delete',
}

export const DELAY_TO_DRAG = 200
