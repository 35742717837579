import { get } from 'lodash'

interface FormDataResponseProps {
  data: string
}

interface FormResponseProps {
  response: FormDataResponseProps
}

export const handleFormError = (errorObj: FormResponseProps): void => {
  const errMessage = get(errorObj, 'response.data', errorObj) as string
  console.error(errMessage)
  throw errMessage
}
