import { getDate, getMonth, getYear, set } from 'date-fns'
import { VALIDATION_ERRORS } from 'src/constants'

const {
  IS_PAST_DATE_ERROR_MESSAGE,
  NO_START_DATE_ERROR_MESSAGE,
  NO_START_TIME_ERROR_MESSAGE,
} = VALIDATION_ERRORS

const BUFFER_WINDOW_MS = 59000

const isPast = startTime =>
  startTime.getTime() + BUFFER_WINDOW_MS < new Date().getTime()

/**
 * Validates a schedule date (exlcuding time) and returns a corresponding error
 * message if invalid.
 * @param {Date} startDate The start date to validate.
 * @returns {String|Boolean} The error string if the schedule date is invalid,
 * otherwise falsey.
 */
export const getErrorMessageOnInvalidStartDate = startDate =>
  !startDate && NO_START_DATE_ERROR_MESSAGE

/**
 * Given a start date and start time, validates the calculated schedule time and
 * returns an error message if not valid.
 * @param {Date} startDate The start date to use when calculating the schedule
 * time. If no start time is specified, then this value is used as the final
 * schedule time.
 * @param {Date} [startTime] The start time to use, alongside the start date, to
 * calculate the schedule time.
 * @returns {String|Boolean|undefined} The error string if the schedule time is
 * invalid, otherwise falsey.
 */
export const getErrorMessageOnInvalidStartTime = function (
  startDate,
  startTime
) {
  if (arguments.length === 1) {
    return startDate && isPast(startDate) && IS_PAST_DATE_ERROR_MESSAGE
  }

  if (!startTime) {
    return NO_START_TIME_ERROR_MESSAGE
  }

  if (!startDate) {
    return
  }

  const fullDateAndTime = set(startTime, {
    year: getYear(startDate),
    month: getMonth(startDate),
    date: getDate(startDate),
  })

  return isPast(fullDateAndTime) && IS_PAST_DATE_ERROR_MESSAGE
}
