import styled from 'styled-components'
import theme from 'styles/theme'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledAddComponentForm = styled.div`
  .add-component {
    background-color: transparent;
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${themeUi.palette.brand.primary.gray};
      border: 2px solid transparent;
      border-radius: 10px;
      background-clip: padding-box;
    }
    .modal-dialog {
      padding: 0;
      border-radius: ${props => props.theme.borderRadius.default};
      height: 494px;
      position: absolute;
      top: 28px;
      left: 30px;
      ${themeUi.shadows.light.level1}
    }
    .modal-content {
      padding: 0;
    }
  }
`

export const StyledTitle = styled.div`
  padding: ${theme.space.space_6};
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${props => props.theme.typography.h3};
  color: ${themeUi.palette.brand.primary.charcoal};
  position: sticky;
  top: 0;
  background-color: ${themeUi.palette.brand.primary.white};
`

export const StyledCancelAction = styled.div`
  font-size: ${props => props.theme.typography.fontSize.sm};
  font-weight: ${props => props.theme.typography.fontWeight.semibold};
  color: ${themeUi.palette.brand.primary.charcoal};
  cursor: pointer;
  &:hover {
    color: ${themeUi.palette.ui.neutral.grey5};
  }
`

export const StyledComponent = styled.div`
  ${props => props.theme.typography.body};
  color: ${themeUi.palette.brand.primary.charcoal};
  padding: 1rem;
  padding-left: ${theme.space.space_6};
  cursor: pointer;

  &:hover {
    background-color: ${themeUi.palette.ui.neutral.grey5};
  }
`
