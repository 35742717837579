import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledSvg = styled.svg`
  display: flex;
  height: 100%;
  left: 0;
  opacity: 0.75;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;

  path {
    stroke: ${themeUi.palette.ui.neutral.grey2};
  }

  &.is-active {
    opacity: 1;

    path {
      stroke: ${themeUi.palette.brand.primary.gray};
    }
  }
`
