import React, { FunctionComponent } from 'react'
import { ButtonWithIcon } from '@teamfabric/copilot-ui'
import { ContentCTAProps } from './types'
import { StyledButtonWrapper } from './styles'
import isEmpty from 'lodash/isEmpty'

const ContentCTAModule: FunctionComponent<ContentCTAProps> = ({ entries }) => {
  return (
    !isEmpty(entries) && (
      <StyledButtonWrapper>
        {entries.map(entry => (
          <ButtonWithIcon
            disabled={entry.disabled ?? false}
            key={entry.id}
            text={entry.buttonText}
            onClick={entry.onClick}
            data-testid={entry?.dataTestId}
            icon='Add'
            iconPosition='left'
            theme='light'
            isPrimary={false}
          />
        ))}
      </StyledButtonWrapper>
    )
  )
}

export default ContentCTAModule
