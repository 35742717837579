import { Channel } from 'store/channels/types'
import { I18nLocale } from 'store/i18n/types'
import { MultiSelectTextChipsOptions } from './types'

export enum OptionsType {
  LOCALES,
  CHANNELS,
}

const getMultiSelectTextChipsOptions = (
  optionsList: Channel[] | I18nLocale[] | string[],
  optionType: OptionsType
): MultiSelectTextChipsOptions[] => {
  return (
    optionsList?.map(option => {
      let id = '',
        label = '',
        value = ''
      switch (optionType) {
        case OptionsType.LOCALES:
          id = label = value = (option?.code ?? option) as string
          break
        case OptionsType.CHANNELS:
          id = option.id as string
          label = option.label as string
          value = option.name as string
          break
        default:
          break
      }

      return {
        id,
        label,
        value,
      }
    }) ?? []
  )
}

export default getMultiSelectTextChipsOptions
