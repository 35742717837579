import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

interface ThemeProps {
  theme: {
    space: Record<string, string>
  }
}

export const StyledAddNewNode = styled.div`
  padding: ${({ theme }) => theme.space.space_2} 0;
  &.add-new-node-container {
    .new-node-blank {
      border: 2px solid transparent;
      color: ${themeUi.palette.brand.primary.gray};
      margin: ${({ theme }: ThemeProps) =>
        `${theme.space.space_8} 0 ${theme.space.space_4}`};

      &.no-margin {
        margin-top: 0;
      }

      input:focus {
        color: ${themeUi.palette.brand.primary.charcoal};
      }
    }
  }
`

export const StyledSubnodeButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledSubnodeButton = styled.button`
  align-items: center;
  display: flex;
  padding: 10px;
  font-size: ${({ theme }) => theme.typography.fontSize.sm};
  visibility: 'visible';

  &:focus {
    outline: none;
  }

  .icon-add {
    margin-right: ${({ theme }) => theme.space.space_3};
  }
`
