import styled from 'styled-components'

export const StyledAlert = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 0.8rem 1rem;
  margin: 0.5rem 0;
  color: ${props => props.theme.colors.red[900]};
  background-color: ${props => props.theme.colors.red[101]};
  border: 1px solid ${props => props.theme.colors.red[101]};
  border-radius: ${props => props.theme.borderRadius.default};
  font-family: ${props => props.theme.typography.fontFamily.sans};
`
