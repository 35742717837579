import { Modal } from '@teamfabric/copilot-ui'
import React from 'react'
import { SETTINGS } from 'src/constants'
import { StyledDialogContent } from '../styles'

interface DeleteProps {
  handleModalClose: () => void
  handleDelete: () => Promise<void>
}

const ConfirmDeleteModal = ({
  handleModalClose,
  handleDelete,
}: DeleteProps): JSX.Element => {
  return (
    <Modal
      onBackdropClick={handleModalClose}
      onClose={handleModalClose}
      padding={SETTINGS.CONFIRM_UPLOAD_DIALOG.PADDING}
      showCloseButton={true}
      headerText={SETTINGS.CONFIRM_DELETE_DIALOG.HEADING}
      headerButtons={[]}
      footerButtons={[
        {
          'onClick': handleModalClose,
          'text': SETTINGS.CONFIRM_UPLOAD_DIALOG.CANCEL,
          'data-testid': 'delete-descritpor-cancel',
        },
        {
          'onClick': () => {
            handleModalClose()
            void handleDelete()
          },
          'text': SETTINGS.CONFIRM_DELETE_DIALOG.SUBMIT,
          'isPrimary': true,
          'data-testid': 'delete-descritpor-button',
        },
      ]}
      size='small'
      portalize={false}
      render={() => {
        return (
          <StyledDialogContent>
            <p>{SETTINGS.CONFIRM_DELETE_DIALOG.SUMMARY}</p>
          </StyledDialogContent>
        )
      }}
    />
  )
}

export default ConfirmDeleteModal
