import { Dispatch, FormEvent, SetStateAction } from 'react'
import { FormikProps } from 'formik'
import { Names } from 'modules/browse-menu-tree-gql/types'
import { Node } from 'modules/browse-menu-tree-gql/components/Node/types'

export interface ManageNodeViewProps {
  node: Node
  onSave: (values: ManageNodeFormValues) => Promise<any>
  onCancel: () => void
  visible: boolean
  locales: string[]
  selectedLocale: string
}

export interface ManageNodeViewHeaderProps {
  onSave: (e: FormEvent) => void
  onCancel: () => void
  title: string
}

export interface ParamsProps {
  _id?: string
  kind: string
  value: string
}

export interface ImageProps {
  locales?: Array<string>
  url: string
  altText?: {
    default?: string
  }
  description?: string
  name?: string
}

export enum FORM_FIELD_NAMES {
  url = 'url',
  rootNodeName = 'rootNodeName',
  nodeName = 'nodeName',
  updatedAt = 'updatedAt',
  attributes = 'attributes',
  images = 'images',
  variantId = 'variantId',
  parentId = 'parentId',
  nodeId = 'nodeId',
}

export type ManageNodeFormValues = {
  [FORM_FIELD_NAMES.url]: string
  [FORM_FIELD_NAMES.rootNodeName]?: Names
  [FORM_FIELD_NAMES.nodeName]: Names
  [FORM_FIELD_NAMES.attributes]: Array<ParamsProps>
  [FORM_FIELD_NAMES.images]: Array<ImageProps>
  [FORM_FIELD_NAMES.variantId]?: string
  [FORM_FIELD_NAMES.parentId]?: string
  [FORM_FIELD_NAMES.nodeId]: string
}

export type ManageNodeGraphQLInputVariables = {
  input: {
    variantId: string
    parentId: string
    nodeId: string
    name: {
      default: string
    }
    url: string
    images: Array<{
      url: string
      altText: {
        default: string
      }
      name?: string
      description?: string
    }>
    attributes: Array<ParamsProps>
  }
}

export type AttributesSectionProps = FormikProps<ManageNodeFormValues>
export type NodeInformationSectionProps = FormikProps<ManageNodeFormValues>

export interface ApplyAttributeViewProps {
  onApply: () => void
  onCancel: () => void
  attribute: ParamsProps
  setAttribute: (attribute: ParamsProps) => void
  applyCTADisabled: boolean
}

export interface ManageNodeModalConfigProps {
  visibleModalId: string
  onConfirm: () => void
  onCancel: () => void
}

export type AssetSectionProps = FormikProps<ManageNodeFormValues> & {
  locales: string[]
  setCurrentModalConfig: Dispatch<SetStateAction<ManageNodeModalConfigProps>>
}
