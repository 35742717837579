import { ACTIONS } from 'components/ContentTableModule/useTableData'
import { FeaturesList } from 'data/types'
import { GEContent } from 'src/graphql-proxy/transformations/globalElements/types'

export enum GEModuleTabs {
  ALL = 0,
  ARCHIVED = 1,
}

export enum GE_MODAL_ID {
  ADD_GE = 'ADD_GLOBAL_ELEMENT',
  MAKE_A_COPY_VERSION = 'MAKE_A_COPY_VERSION',
  MAKE_A_COPY = 'MAKE_A_COPY',
  ARCHIVE = 'ARCHIVE_VERSION',
  UNARCHIVE = 'UNARCHIVE_VERSION',
  DELETE = 'DELETE_VERSION',
  DELETE_PARENT_GE = 'DELETE_PARENT_GE',
  CREATE_NEW_VERSION = 'CREATE_NEW_VERSION',
}

export type GEModalDetails = {
  visibleModalId: string
  targetItem?: { parentId: string; contentId?: string }
  globalElement?: GEContent
}

export type GEModuleContext = {
  tableModal: GEModalDetails
  moduleTab: GEModuleTabs
  featuresList: FeaturesList
  closeTableModal: () => void
  showTableModal: React.Dispatch<React.SetStateAction<GEModalDetails>>
  dispatchTableAction: (
    action: ACTIONS,
    variables?: { targetItemId: string; parentId?: string },
    refetchTime?: number
  ) => void
}
