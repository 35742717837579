import { MESSAGES, TOAST_MESSAGE_TYPES } from 'src/constants'
import { showToast } from 'components/ToastSnackbarContainer'

export const SPECIAL_CHARACTERS = [
  ';',
  '/',
  '?',
  ':',
  '@',
  '=',
  '#',
  '[',
  ']',
  '&',
]

export const hasInvalidCharacters = (name: string): boolean => {
  return SPECIAL_CHARACTERS.some(element => name.includes(element))
}

export const showSpecialCharactersToast = async (): Promise<void> => {
  await showToast({
    message: MESSAGES.ERROR_SPECIAL_CHARACTERS,
    kind: TOAST_MESSAGE_TYPES.ALERT,
  })
}
