import { Flyout, theme as themeUi } from '@teamfabric/copilot-ui'
import { STYLED_FLYOUT, STYLED_MENU_ICON } from './constants'
import styled from 'styled-components'

export const StyledMenuIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${STYLED_MENU_ICON.WIDTH}px;
  height: ${STYLED_MENU_ICON.HEIGHT}px;
  border-radius: ${STYLED_MENU_ICON.BORDER_RADIUS}px;
  cursor: pointer;
`

export const StyledFlyoutOption = styled.div`
  padding: ${STYLED_FLYOUT.PADDING_VERTICAL}px
    ${STYLED_FLYOUT.PADDING_HORIZONTAL}px;
  ${themeUi.typography.h6.css};
  cursor: pointer;

  :hover {
    background: ${STYLED_FLYOUT.HOVER_BG};
  }
`

export const StyledFlyout = styled(Flyout)`
  z-index: ${themeUi.zIndex.snackbar};
`
