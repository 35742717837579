import React, { useState } from 'react'
import {
  StyledCaretDownWrapper,
  StyledExpandedContent,
  StyledWrapper,
} from './styles'
import cn, { Argument } from 'classnames'
import { ExpandableCardProps } from './types'
import IconCaretDown from 'assets/icons/IconCaretDown'

const ExpandableCard = (props: ExpandableCardProps): JSX.Element => {
  const { heading, children } = props
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const iconClassNames: Argument = cn('expandable-caret-down', {
    'caret-rotate-180': isExpanded,
  })

  return (
    <>
      <StyledWrapper>
        <h4 onClick={() => setIsExpanded(expanded => !expanded)}>{heading}</h4>
        <StyledCaretDownWrapper>
          <IconCaretDown
            className={iconClassNames}
            data-testid='expandable-card-icon-caret-down'
            onClick={() => setIsExpanded(expanded => !expanded)}
          />
        </StyledCaretDownWrapper>
      </StyledWrapper>
      {isExpanded && <StyledExpandedContent>{children}</StyledExpandedContent>}
    </>
  )
}

export default ExpandableCard
