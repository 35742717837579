import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledModalContent = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
    input {
      width: 100%;
      padding: 0.75rem 0 !important;
      color: ${themeUi.palette.brand.primary.charcoal};
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 1px solid ${themeUi.palette.brand.primary.charcoal};
      ${themeUi.typography.h6}
    }
  }
`

export const StyledInputWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`

export const StyledDatePickerWrapper = styled.div`
  margin-top: 2rem;
  position: relative;
`

export const StyledTimePickerWrapper = styled.div`
  margin-top: 2rem;

  div {
    padding: 0;
  }
`

export const StyledLabel = styled.label`
  color: ${themeUi.palette.brand.primary.gray};
  ${themeUi.typography.h6}
`
