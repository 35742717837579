import React, { FunctionComponent } from 'react'
import { CONTEXT_MENU_TEST_ID } from './constants'
import ContextMenu from 'components/ContextMenu'
import { ContextMenuModuleProps } from './types'
import { StyledContextMenu } from 'src/modules/settings/descriptor-context-menu/styles' // TODO: Move this to a common component folder

const ContentContextMenuModule: FunctionComponent<ContextMenuModuleProps> = props => {
  const { menuOptions } = props

  if (!menuOptions.length) {
    return null
  }

  return (
    <ContextMenu
      className={CONTEXT_MENU_TEST_ID}
      data-testid={CONTEXT_MENU_TEST_ID}
      placement='bottomRight'
    >
      <StyledContextMenu>
        {menuOptions.map(option => (
          <ContextMenu.Item key={option.label} onClick={option.onClick}>
            {option.label}
          </ContextMenu.Item>
        ))}
      </StyledContextMenu>
    </ContextMenu>
  )
}

export default ContentContextMenuModule
