import React from 'react'

import { StyledAlert } from './styles'

interface AlertProps {
  children: React.ReactNode
}

const Alert = (props: AlertProps): JSX.Element => {
  const { children, ...rest } = props

  return (
    <StyledAlert data-testid='alert' {...rest}>
      {children}
    </StyledAlert>
  )
}

export default Alert
