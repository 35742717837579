import {
  GET_PAGE_TYPES,
  PAGE_TYPES_INIT,
  PAGE_TYPE_REQUEST_ERROR,
  PAGE_TYPE_REQUEST_SUBMIT,
  PAGE_TYPE_REQUEST_SUCCESS,
} from './actions'
import FormLifecycle from 'form-lifecycle'
import produce from 'immer'

const pageTypeLifecycleForm = FormLifecycle.atObjectPath(
  'pageTypeLifecycleForm'
)

const initialState = {
  pageTypes: [],
}

const init = (state, payload) => {
  return produce(state, draftState => {
    draftState.pageTypes = payload
  })
}

const pageTypeRequestSubmit = state => pageTypeLifecycleForm.submit(state)

const pageTypeRequestSuccess = (state, payload) => {
  const stateAfterSuccess = pageTypeLifecycleForm.success(state)

  const newState = { ...stateAfterSuccess }
  return produce(newState, draftState => {
    if (payload.archived) {
      draftState.archivedPages = payload.pages
      draftState.archivedQuery = payload.query
    } else {
      draftState.pages = payload.pages
      draftState.query = payload.query
    }
    return draftState
  })
}

const pageTypeRequestError = (state, action) =>
  pageTypeLifecycleForm.error(state, action.payload)

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PAGE_TYPES_INIT:
      return init(state, action.payload)
    case GET_PAGE_TYPES:
      return state.pageTypes
    case PAGE_TYPE_REQUEST_SUBMIT:
      return pageTypeRequestSubmit(state)
    case PAGE_TYPE_REQUEST_SUCCESS:
      return pageTypeRequestSuccess(state, action.payload)
    case PAGE_TYPE_REQUEST_ERROR:
      return pageTypeRequestError(state, action.payload)
    default:
      break
  }

  return state
}

export default reducer
