import React, { memo } from 'react'
import {
  StyledCaption,
  StyledErrorMessage,
  StyledInput,
  StyledLabel,
  StyledWrapper,
} from './styles'
import PropTypes from 'prop-types'
import Tippy from '@tippyjs/react'
import { noop } from 'lodash'

const Field = props => {
  const {
    id,
    className,
    type,
    value,
    size,
    name,
    placeholder,
    disabled,
    label,
    errorMessage,
    caption,
    checked,
    minLength,
    maxLength,
    pattern,
    required,
    'data-testid': dataTestId,
    onChange,
    onFocus,
    tooltip,
  } = props

  const isControlled = value !== null

  return (
    <StyledWrapper>
      {label && (
        <StyledLabel
          className='field-label'
          htmlFor={id}
          data-testid={`${dataTestId}-label`}
        >
          {tooltip?.text && tooltip?.position ? (
            <Tippy content={tooltip.text} arrow placement={tooltip.position}>
              <span data-testid='form-label-tooltip'>{label}</span>
            </Tippy>
          ) : (
            <span>{label}</span>
          )}
        </StyledLabel>
      )}
      <StyledInput
        id={id}
        className={`field-input ${className}`}
        name={name}
        type={type}
        size={size}
        {...(isControlled && {
          value,
          onChange,
          onFocus,
        })}
        placeholder={placeholder}
        disabled={disabled}
        {...(minLength && { minLength })}
        {...(maxLength && { maxLength })}
        {...(pattern && { pattern })}
        {...(required && { required })}
        {...(['checkbox', 'radio'].includes(type) && { checked })}
        data-testid={dataTestId}
      />
      {errorMessage && (
        <StyledErrorMessage
          className='field-error-message'
          data-testid={`${dataTestId}-error-message`}
        >
          {errorMessage}
        </StyledErrorMessage>
      )}
      {caption && !errorMessage && (
        <StyledCaption
          className='field-caption'
          data-testid={`${dataTestId}-caption`}
        >
          {caption}
        </StyledCaption>
      )}
    </StyledWrapper>
  )
}

Field.defaultProps = {
  'id': '',
  'type': 'text',
  'name': '',
  'className': '',
  'value': null,
  'size': 'medium',
  'placeholder': '',
  'disabled': false,
  'label': '',
  'errorMessage': '',
  'caption': '',
  'checked': false,
  'minLength': null,
  'maxLength': null,
  'pattern': null,
  'required': false,
  'data-testid': 'input',
  'onChange': noop,
  'onFocus': noop,
  'tooltip': {},
}

Field.propTypes = {
  'id': PropTypes.string.isRequired,
  'type': PropTypes.string.isRequired,
  'name': PropTypes.string.isRequired,
  'className': PropTypes.string,
  'placeholder': PropTypes.string.isRequired,
  'value': PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  'size': PropTypes.oneOf(['small', 'medium', 'large']),
  'disabled': PropTypes.bool.isRequired,
  'label': PropTypes.node.isRequired,
  'errorMessage': PropTypes.string.isRequired,
  'caption': PropTypes.string.isRequired,
  'checked': PropTypes.bool.isRequired,
  'minLength': PropTypes.number,
  'maxLength': PropTypes.number,
  'pattern': PropTypes.string,
  'required': PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  'data-testid': PropTypes.string.isRequired,
  'onChange': PropTypes.func.isRequired,
  'onFocus': PropTypes.func.isRequired,
  'tooltip': PropTypes.shape({
    text: PropTypes.string,
    position: PropTypes.string,
  }),
}

export default memo(Field)
