import { TOAST_CLASS_NAME } from './constants'

export const clearAllToasts = (): void => {
  const toastSnackbars = document.getElementsByClassName(TOAST_CLASS_NAME)
  while (toastSnackbars.length) {
    toastSnackbars[0]?.remove()
  }
}

export const removeToast = (index: number, timing: number): void => {
  setTimeout(() => {
    document.getElementsByClassName(TOAST_CLASS_NAME)?.[index]?.remove()
  }, timing)
}
