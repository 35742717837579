import { gql } from '@apollo/client'

export const GET_UNIVERSAL_DESCRIPTORS = gql`
  query GetUniversalDescriptors {
    universalDescriptors {
      id
      description
      attributes
      label
      allowNestedComponents
      type
    }
  }
`

export const GET_UNIVERSAL_DESCRIPTOR_BY_ID = gql`
  query GetUniversalDescriptorById($id: ID!) {
    universalDescriptorById(id: $id) {
      id
      description
      attributes
      label
      allowNestedComponents
      type
    }
  }
`

export const CREATE_UNIVERSAL_DESCRIPTOR = gql`
  mutation CreateUniversalDescriptor($input: UniversalDescriptorCreateInput!) {
    createUniversalDescriptor(input: $input) {
      id
      description
      attributes
      label
      allowNestedComponents
      type
    }
  }
`

export const UPDATE_UNIVERSAL_DESCRIPTOR_BY_ID = gql`
  mutation UpdateUniversalDescriptorById(
    $input: UniversalDescriptorUpdateInput!
  ) {
    updateUniversalDescriptor(input: $input) {
      id
      description
      attributes
      label
      allowNestedComponents
      type
    }
  }
`

export const DELETE_SINGLE_DESCRIPTOR_BY_ID = gql`
  mutation DeleteUniversalDescriptorById($id: ID!) {
    deleteUniversalDescriptor(id: $id) {
      id
      type
      label
      description
      attributes
      allowNestedComponents
    }
  }
`
