export const getPipelineTree = tree => {
  const pipelineTree = []

  for (let i = 0; i < tree.length; i++) {
    const levels = Array.from(
      document.querySelectorAll(`[data-pipelineid="pipeline-depth-${i}"]`)
    )
    pipelineTree.push(levels)
  }

  return pipelineTree
}

export const getPipelineTreeElements = pipelineTree => {
  const treeElementsList = []

  pipelineTree.forEach(level => {
    const levelRootId = level[0]?.dataset?.parent
    const parent = document.getElementById(levelRootId)
    if (!parent) return null

    const treeElements = {
      parent,
      pId: parent.dataset.nodeid,
      children: level,
    }
    treeElementsList.push(treeElements)
  })

  return treeElementsList
}

export const getCoordinates = treeElements => {
  const NODE_HEIGHT_HALF = 29

  return treeElements.reduce((acc, cur) => {
    cur.children.forEach(child => {
      const pRight = cur.parent.offsetWidth + cur.parent.offsetLeft
      const pTop = cur.parent.offsetTop + NODE_HEIGHT_HALF
      const cLeft = child.offsetLeft
      const cTop = child.offsetTop + NODE_HEIGHT_HALF
      const pId = cur.pId
      const cId = child.dataset.nodeid
      const pathData = {
        pRight,
        pTop,
        cLeft,
        cTop,
        pId,
        cId,
      }
      acc.push(pathData)
    })

    return acc
  }, [])
}

export const getOffsets = coordinates => {
  const DIVIDER = 20
  const yGap = coordinates.pTop - coordinates.cTop
  const yDiff = Math.abs(yGap)
  const smoother = yDiff / DIVIDER
  let smootherParent = 0
  let smootherChild = 0

  if (yGap > 0) {
    // upwards _/
    smootherParent = -smoother
    smootherChild = smoother
  } else if (yGap < 0) {
    // downwards \_
    smootherParent = smoother
    smootherChild = -smoother
  }

  const parentSide = [
    {
      x: 10,
      y: 0,
    },
    {
      x: 16,
      y: 0,
    },
    {
      x: 16,
      y: smootherParent,
    },
  ]

  const childSide = [
    {
      x: -16,
      y: smootherChild,
    },
    {
      x: -16,
      y: 0,
    },
    {
      x: -10,
      y: 0,
    },
  ]

  return {
    parentSide,
    childSide,
  }
}

export const buildCurveString = (coordinates, offsets) => {
  const parentSide = []
  const childSide = []
  coordinates.forEach((coordinate, i) => {
    let curveParent = ''
    let curveChild = ''

    offsets[i].parentSide.forEach(offset => {
      curveParent += `${coordinate.pRight + offset.x} ${
        coordinate.pTop + offset.y
      } `
    })
    parentSide.push(curveParent)

    offsets[i].childSide.forEach(offset => {
      curveChild += `${coordinate.cLeft + offset.x} ${
        coordinate.cTop + offset.y
      } `
    })
    childSide.push(curveChild)
  })

  return {
    parentSide,
    childSide,
  }
}

export const getSvgPaths = menuTree => {
  const pipelineTree = getPipelineTree(menuTree)
  if (pipelineTree && pipelineTree[0].length) {
    const coordinateList = getCoordinates(getPipelineTreeElements(pipelineTree))
    const offsetList = coordinateList.map(coordinates =>
      getOffsets(coordinates)
    )
    const curves = buildCurveString(coordinateList, offsetList)

    return coordinateList.reduce((acc, cur, i) => {
      const pathData = {
        pId: cur.pId,
        cId: cur.cId,
        draw: `M${cur.pRight} ${cur.pTop} C ${curves.parentSide[i]} C ${curves.childSide[i]} L${cur.cLeft} ${cur.cTop}`,
      }
      acc.push(pathData)

      return acc
    }, [])
  }
}
