import IconClose from 'assets/icons/IconClose'
import styled from 'styled-components'

export const StyledEditorPanel = styled.div`
  position: relative;
  padding: 25px 18px;
  .field-title {
    margin: 0 0 10px;
  }
`

export const StyledRichTextBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  cursor: ${({ hasEditAccess }) => hasEditAccess && 'pointer'};
  border: 1px solid ${({ theme }) => theme.colors.gray[200]};
  border-radius: ${props => props.theme.borderRadius.sm};
  background-color: ${({ theme }) => theme.colors.white};
  margin: 0.25rem 0;

  &:hover {
    border-color: ${({ theme, hasEditAccess }) =>
      hasEditAccess && theme.colors.gray[300]};
  }
`

export const StyledIconClose = styled(IconClose)`
  position: absolute;
  top: ${({ theme }) => theme.space.space_5};
  right: ${({ theme }) => theme.space.space_5};
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.gray[400]};
`
