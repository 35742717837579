import { EDITOR_TOAST_MESSAGES } from 'modules/editor/constants'

export const SCHEDULE_PAGE_TITLE = 'Schedule page'
export const SCHEDULE_GLOBAL_ELEMENT_TITLE = 'Schedule global element'
export const SCHEDULE_MENU_TITLE = 'Schedule Menu'

export const retrieveTitle = (isPage: boolean, isGlobal: boolean): string => {
  if (isPage) {
    return SCHEDULE_PAGE_TITLE
  } else if (isGlobal) {
    return SCHEDULE_GLOBAL_ELEMENT_TITLE
  } else {
    return SCHEDULE_MENU_TITLE
  }
}

export const retrieveToastMessage = (
  isPage: boolean,
  isGlobal: boolean
): string => {
  if (isPage) {
    return EDITOR_TOAST_MESSAGES.PAGE_VERSION_SCHEDULE_SUCCESS
  } else if (isGlobal) {
    return EDITOR_TOAST_MESSAGES.GLOBAL_ELEMENT_SCHEDULE_SUCCESS
  } else {
    return EDITOR_TOAST_MESSAGES.MENU_VERSION_SCHEDULE_SUCCESS
  }
}
