import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

const fontSize = {
  small: '13px',
}

export const StyledSlideEditorWrapper = styled.div``

export const StyledSideContent = styled.div`
  position: absolute;
  top: 0;
  right: -${({ right }) => right};
  padding: 1.5rem;
  background: ${themeUi.palette.brand.primary.white};
  width: ${({ theme }) => theme.constants.sideEditorWidth};
  min-height: 100%;
  transition: all ${({ transitionTime }) => transitionTime}ms;
  ${themeUi.shadows.light.level1}
`

const ORIGINAL_Z_INDEX = 100
export const StyledSideEditor = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  min-height: 100%;
  z-index: ${({ zIndex }) => (zIndex ? zIndex : ORIGINAL_Z_INDEX)};
  overflow: auto;
`

export const StyledHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space.space_7};

  .title {
    color: ${themeUi.palette.brand.primary.charcoal};
    font-size: ${({ theme }) => theme.typography.fontSize.lg};
  }

  .actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    button {
      border: 1px solid transparent;
      border-radius: 2rem;
      font-size: ${fontSize.small};
      height: 32px;
      padding: 0 28px;

      &:first-child {
        color: ${themeUi.palette.brand.primary.charcoal};
      }

      &.ui-blue {
        background: ${themeUi.palette.ui.states.active};
      }

      &:disabled {
        background: ${themeUi.palette.ui.neutral.grey4};
      }
    }
  }
`
