import { CHIPS_CLASS_NAME, LEFTOVER_CHIP_CLASS_NAME } from './ChipsList'
import { StyledChipItemProps } from './types'
import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const TOOLTIP_CLASS_NAME = 'more-chips-tooltip'

export const StyledChipsListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const StyledChipItem = styled.div<StyledChipItemProps>`
  margin-bottom: 10px;

  .${CHIPS_CLASS_NAME} {
    background: ${themeUi.palette.ui.neutral.grey5};
    color: ${themeUi.palette.brand.primary.gray};
    font-weight: 600;
  }

  ${props =>
    !!props.moreChips &&
    `.${LEFTOVER_CHIP_CLASS_NAME} {
      background: ${themeUi.palette.brand.primary.white as string};
      border: 1px solid ${themeUi.palette.ui.neutral.grey2 as string};
    }`}

  .${TOOLTIP_CLASS_NAME} {
    padding: 8px;
  }
`
