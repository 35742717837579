import React, { FunctionComponent } from 'react'
import { StyledStatus } from './styles'

const Status: FunctionComponent<{ status: string }> = ({ status }) => {
  return (
    <StyledStatus>
      <span className={`status-circle is-${status?.toLowerCase()}`}></span>
      {status}
    </StyledStatus>
  )
}

export default Status
