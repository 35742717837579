import React, { memo } from 'react'

const IconSettings = props => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={22}
      height={22}
      viewBox='0 0 22 22'
      {...props}
    >
      <defs>
        <linearGradient id='a' x1='0%' x2='101.952%' y1='0%' y2='100.952%'>
          <stop offset='0%' stopColor='#929292' />
          <stop offset='99.952%' stopColor='#929292' />
        </linearGradient>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <path fillRule='nonzero' d='M-1-1h24v24H-1z' />
        <path
          fill='url(#a)'
          d='M9.925 3.3l.386-1.543A1 1 0 0 1 11.28 1h1.438a1 1 0 0 1 .97.757l.386 1.544a1.757 1.757 0 0 0 2.609 1.08l1.364-.818a1 1 0 0 1 1.222.15l1.017 1.017a1 1 0 0 1 .15 1.222l-.818 1.364a1.757 1.757 0 0 0 1.08 2.609l1.544.386a1 1 0 0 1 .757.97v1.438a1 1 0 0 1-.757.97l-1.544.386a1.757 1.757 0 0 0-1.08 2.609l.818 1.364a1 1 0 0 1-.15 1.222l-1.017 1.017a1 1 0 0 1-1.222.15l-1.364-.818a1.757 1.757 0 0 0-2.609 1.08l-.386 1.544a1 1 0 0 1-.97.757h-1.438a1 1 0 0 1-.97-.757l-.386-1.544a1.757 1.757 0 0 0-2.609-1.08l-1.364.818a1 1 0 0 1-1.222-.15L3.713 19.27a1 1 0 0 1-.15-1.222l.818-1.364a1.757 1.757 0 0 0-1.08-2.609l-1.544-.386A1 1 0 0 1 1 12.72v-1.438a1 1 0 0 1 .757-.97l1.544-.386a1.757 1.757 0 0 0 1.08-2.609l-.818-1.364a1 1 0 0 1 .15-1.222L4.73 3.713a1 1 0 0 1 1.222-.15l1.364.818a1.757 1.757 0 0 0 2.609-1.08zM12 16a4 4 0 1 0 0-8 4 4 0 0 0 0 8z'
          transform='translate(-1 -1)'
          {...props}
        />
      </g>
    </svg>
  )
}

export default memo(IconSettings)
