import React, { ReactElement } from 'react'
import { SETTINGS } from 'src/constants'
import { StyledWrapper } from './styles'

const NoComponents = (): ReactElement => (
  <StyledWrapper>
    <div className='content'>
      <p>{SETTINGS.NO_COMPONENTS.SUBTITLE}</p>
      <h3>{SETTINGS.NO_COMPONENTS.TITLE}</h3>
    </div>
  </StyledWrapper>
)

export default NoComponents
