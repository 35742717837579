import {
  ADD_COMPONENTS_LABEL,
  MAXIMUM_GE_ADDED_TOOLTIP,
  MAX_ALLOWED_GE_COMPONENTS,
  TOAST_MESSAGE_TYPES,
} from 'src/constants'
import React, { Component, Fragment } from 'react'
import { StyledButton, StyledComponentButton } from './styles'
import { Tooltip, theme as themeUi } from '@teamfabric/copilot-ui'
import ComponentDialog from '../ComponentDialog'
import { EDITOR_ADD_COMPONENT } from 'modules/editor/actions'
import { EDITOR_TOAST_MESSAGES } from 'modules/editor/constants'
import IconPlus from 'assets/icons/IconPlus'
import PropTypes from 'prop-types'
import { SHOW_TOAST_MESSAGE } from 'modules/toast-messages/actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

class AddComponent extends Component {
  state = {
    isModalOpen: false,
  }

  static defaultProps = {
    buttonType: '',
    paths: ['curatedComponents'],
    curatedComponents: [],
    isGCEditorPagePath: false,
  }

  static propTypes = {
    addComponent: PropTypes.func.isRequired,
    selectedComponent: PropTypes.object,
    paths: PropTypes.array,
    level: PropTypes.number,
    buttonType: PropTypes.oneOf(['text-only']),
    showToastMessage: PropTypes.func.isRequired,
    curatedComponents: PropTypes.array,
    isGCEditorPagePath: PropTypes.bool.isRequired,
  }

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    })
  }

  render() {
    const { isModalOpen } = this.state
    const {
      showToastMessage,
      isGCEditorPagePath,
      curatedComponents,
    } = this.props

    const hasMaximumComponents =
      isGCEditorPagePath &&
      curatedComponents.length === MAX_ALLOWED_GE_COMPONENTS

    return (
      <Fragment>
        <div>
          <StyledComponentButton
            onClick={this.toggleModal}
            buttonType={this.props.buttonType}
            data-testid='add-component-button'
            disabled={hasMaximumComponents}
          >
            {this.props.buttonType !== 'text-only' && (
              <IconPlus
                width='18px'
                height='18px'
                fill={themeUi.palette.brand.primary.charcoal}
              />
            )}
            <StyledButton buttonType={this.props.buttonType}>
              {this.props.buttonType === 'text-only'
                ? `+ ${ADD_COMPONENTS_LABEL}`
                : ADD_COMPONENTS_LABEL}
            </StyledButton>
          </StyledComponentButton>
          {hasMaximumComponents && (
            <Tooltip
              position='right'
              className='custom-tooltip'
              data-testid='max-component-reached-tooltip'
            >
              {MAXIMUM_GE_ADDED_TOOLTIP}
            </Tooltip>
          )}
        </div>
        <ComponentDialog
          open={isModalOpen}
          onClose={() => this.toggleModal(false)}
          onChoose={async descriptor => {
            this.props.addComponent({
              id: descriptor.id,
              paths: this.props.paths,
            })

            showToastMessage({
              message: EDITOR_TOAST_MESSAGES.COMPONENT_ADD_SUCCESS,
              kind: TOAST_MESSAGE_TYPES.SUCCESS,
            })
          }}
        />
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addComponent: EDITOR_ADD_COMPONENT,
      showToastMessage: SHOW_TOAST_MESSAGE,
    },
    dispatch
  )

export default connect(null, mapDispatchToProps)(AddComponent)
