import { LEFT_SIDE_BAR_WIDTH } from './constants'
import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

interface StyledProps {
  bgColor?: string
}

export const StyledGrid = styled.div<{ ds4?: boolean }>`
  width: 100%;
  min-height: 100;
  display: grid;
  grid-template-columns: ${({ ds4 }) =>
    ds4 ? '1fr' : `${LEFT_SIDE_BAR_WIDTH}px 1fr`};
`

export const StyledContainer = styled.div<StyledProps>`
  background: ${props => props.bgColor || themeUi.palette.ui.neutral.grey5};
  min-height: 100vh;
`

export const StyledSidebar = styled.div<StyledProps>`
  background: ${props => props.bgColor || themeUi.palette.ui.neutral.grey5};
  z-index: 0;
`
