import { NODE_TYPE } from 'modules/browse-menu-tree-gql/types'
import { Node } from '../../components/Node/types'

export const handleCardMouseEnter = (
  node: Node,
  nodeType: NODE_TYPE,
  setHoveredSectionId: (id: string) => void,
  isDragging: boolean
): void | null => {
  if (isDragging || nodeType === NODE_TYPE.NODE) {
    return null
  }

  setHoveredSectionId(nodeType === NODE_TYPE.SECTION ? node._id : node.parent)
}

export const handleCardMouseLeave = (
  node: Node,
  nodeType: NODE_TYPE,
  setHoveredSectionId: (id: string) => void,
  isDragging: boolean
): void | null => {
  if (isDragging || nodeType === NODE_TYPE.NODE) {
    return null
  }

  setHoveredSectionId(null)
}

//check if destination is invalid, if dragged to different level, or dropped in the same spot
export const isDropInvalid = ({
  destination,
  destDroppableId,
  srcDroppableId,
  destIndex,
  srcIndex,
}: {
  destination: {
    droppableId: number
    index: number
  }
  destDroppableId: number
  srcDroppableId: number
  destIndex: number
  srcIndex: number
}): boolean => {
  return (
    !destination || destDroppableId !== srcDroppableId || destIndex === srcIndex
  )
}
