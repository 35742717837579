import { ContextOption, FeaturesList } from 'data/types'
import {
  PageNode,
  PageVariantGraphQLResponse,
} from 'src/graphql-proxy/transformations/page/types'
import { ACTIONS } from 'components/ContentTableModule/useTableData'
import { ContentTableItemProps } from 'components/ContentTableModule/types'
import { Filters } from 'components/Filters/types'
import { TABLE_COLUMN_OPTIONS } from 'components/ContentTableModule/constants'

export type Page = Partial<PageNode>

export type PageVersion = Partial<PageVariantGraphQLResponse>

export type PageModalDetails = {
  visibleModalId: string
  page: Page | Partial<PageType> | undefined
  targetItem: string | { pageId: string; versionId: string }
}

export enum PAGES_MODAL_ID {
  ADD_PAGE_TYPE = 'ADD_PAGE_TYPE',
  ADD_NEW_PAGE = 'ADD_NEW_PAGE',
  EDIT_DETAILS = 'EDIT_DETAILS',
  MAKE_A_COPY_TYPE = 'MAKE_A_COPY_TYPE',
  NEW_VERSION = 'NEW_VERSION',
  DELETE_PAGE = 'DELETE_PAGE',
  DELETE_VERSION = 'DELETE_VERSION',
  ACTIVATE_PAGE = 'ACTIVATE_PAGE',
  DEACTIVATE_PAGE = 'DEACTIVATE_PAGE',
  ARCHIVE_PAGE = 'ARCHIVE_PAGE',
  ARCHIVE_VERSION = 'ARCHIVE_VERSION',
  UNARCHIVE_PAGE = 'UNARCHIVE_PAGE',
  UNARCHIVE_VERSION = 'UNARCHIVE_VERSION',
  VIEW_DETAILS = 'VIEW_DETAILS',
  EDIT_PAGE_TYPE = 'EDIT_PAGE_TYPE',
  DELETE_PAGE_TYPE = 'DELETE_PAGE_TYPE',
  VIEW_PAGE_TYPE = 'VIEW_PAGE_TYPE',
}

export interface PageType {
  id: string
  name: string
  pageCount: number
  tags?: string
  urlPrefix: string
  updatedAt?: string
  createdAt?: string
  isDefault: boolean
  _id: string
}

export interface PageTypeResponseQuery {
  count?: number
  limit?: number
  offset?: number
}

export interface PageTypeResponse {
  query: PageTypeResponseQuery
  pageTypes: PageType[]
}

export interface PagesModuleTableData {
  columns: TABLE_COLUMN_OPTIONS[]
  totalCount: number
  entries: ContentTableItemProps[]
  fetchQuery: (limit: number, offset: number) => Promise<unknown>
  isLoading: boolean
}

export interface UsePageTablePropsInput {
  filters?: { isArchived?: boolean } & Filters
  features: FeaturesList
  getPageCtxOptions: (item: Partial<Page>) => ContextOption[]
  getPageVersionCtxOptions: (
    item: Partial<Page>,
    version: Partial<PageVersion>
  ) => ContextOption[]
  basepath: string
}

export enum PagesModuleTabs {
  ALL_PAGES = 0,
  PAGE_TYPES = 1,
  ARCHIVED = 2,
}

export interface PagesModuleContext {
  tableModal: PageModalDetails
  moduleTab: PagesModuleTabs
  featuresList: FeaturesList
  closeTableModal: () => void
  showTableModal: React.Dispatch<React.SetStateAction<PageModalDetails>>
  setModuleTab: React.Dispatch<React.SetStateAction<PagesModuleTabs>>
  dispatchTableAction: (
    action: ACTIONS,
    variables?: { targetItemId: string; parentId?: string },
    refetchTime?: number
  ) => void
}

export interface UseSearchResults {
  dropDownValues: Array<{
    name: string
    tags: string
  }>
  clearSearchDropdown: () => void
  isSearching: boolean
}

export interface SearchDropdown {
  name: string
  tags: string
  _id: string
}
