import { GraphQLSelectedRouteProps, SelectedRouteProps } from 'store/types'
import { PageVersionProps } from 'modules/editor/types'

export const GraphQLPageVersion = (
  selectedRoute: GraphQLSelectedRouteProps
): PageVersionProps => {
  const variant = selectedRoute.variants[0]
  return {
    getPageName: () => selectedRoute.name,
    getPageId: () => selectedRoute.id,
    getPageUrl: () => selectedRoute.url,
    getPageChannels: () => selectedRoute.channels,
    getIsPageActive: () => selectedRoute.isActive,
    getVersionId: () => variant.id,
    getVersionStatus: () => variant.status,
    getVersionName: () => variant.versionName,
    getVersionComponents: () => variant.components,
    getVersionDescription: () => variant.description,
    getLastUpdated: () => variant.updatedAt,
  }
}

export const RESTPageVersion = (
  selectedRoute: SelectedRouteProps
): PageVersionProps => ({
  getPageName: () => selectedRoute.pageName,
  getPageId: () => selectedRoute.pageId,
  getPageUrl: () => selectedRoute.pageUrl,
  getPageChannels: () => selectedRoute.page.channels,
  getIsPageActive: () => selectedRoute.page.isActive,
  getVersionId: () => selectedRoute.versionId,
  getVersionStatus: () => selectedRoute.versionStatus,
  getVersionName: () => selectedRoute.versionName,
  getVersionComponents: () => selectedRoute.components,
  getVersionDescription: () => selectedRoute.description,
  getLastUpdated: () => selectedRoute.updatedAt,
})
