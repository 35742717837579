import * as Yup from 'yup'
import {
  CommonElementProps,
  ContentTypeFunctionProps,
  DialogModalSectionProps,
  FormSubmitActionProps,
  FormikValuesObjectProps,
  GEParentElementProps,
  MenuParentElementProps,
  ModeActionFunctionProps,
  PageParentElementProps,
  VersionTypesEnum,
} from '../types'
import { EDITOR_TOAST_MESSAGES, VERSION_STATUS } from 'modules/editor/constants'
import {
  ExistingGEContentType,
  MenuContentType,
  NewGEContentType,
  PageContentType,
} from './ContentType'
import {
  MESSAGES,
  MODAL_ACTION,
  MODAL_SECTIONS,
  TOAST_MESSAGE_TYPES,
  VALIDATION_ERRORS,
} from 'src/constants'
import {
  MenuVersionCopyGraphQLResponse,
  MenuVersionCreateGraphQLResponse,
} from 'src/graphql-proxy/transformations/menu-version/types'
import {
  hasInvalidCharacters,
  showSpecialCharactersToast,
} from 'components/FormDialog/common/utils/validateSpecialCharacters'
import { EDITOR_UPDATE_SUCCESS } from 'src/modules/editor/actions.js'
import { Formik } from 'formik'
import { GetModeActions } from './ModeActions'
import { PageVersionCopyGraphQLResponse } from 'src/graphql-proxy/transformations/page-version/types'
import React from 'react'
import VersionDetailHeader from 'components/FormDialog/components/VersionDetailHeader'
import { getFormInputFields } from 'components/FormDialog/common/utils/getFormInputFields'
import { getModeConfigurationForHeader } from 'components/FormDialog/common/utils/getModeConfigurationForHeader'
import httpStatusCodes from 'http-status-codes'
import isEmpty from 'lodash/isEmpty'
import { showToast } from 'components/ToastSnackbarContainer'
import store from 'src/store'
import {
  getBasePath,
  useNewNavigation,
} from 'src/contexts/navigationLinksContext'

// GraphQL implementation is only done for Update in Pages. The rest should all use REST.
const evaluateGraphQLFF = ({
  shouldUseGraphQL,
  contentType,
  mode,
}: {
  shouldUseGraphQL: boolean
  contentType: VersionTypesEnum
  mode: string
}) => {
  let ffValue = shouldUseGraphQL

  if (
    contentType === VersionTypesEnum.GLOBAL_ELEMENTS ||
    (mode !== MODAL_ACTION.EDIT && mode !== MODAL_ACTION.COPY)
  ) {
    ffValue = false
  }

  return ffValue
}

// eslint-disable-next-line complexity
const handleFormSubmit = async ({
  values,
  contentElement,
  shouldUseGraphQL,
  modeAction,
  navigate,
  handleClose,
  setVersionName,
  setVersionDescription,
  setVersionStatus,
  setMenu,
}: {
  contentElement: ContentTypeFunctionProps
  shouldUseGraphQL: boolean
  values: FormikValuesObjectProps
  modeAction: ModeActionFunctionProps
  navigate: (redirectPath: string) => Promise<void>
  handleClose: () => void
  setVersionName: (versionName: string) => void
  setVersionDescription: (versionDescription: string) => void
  setVersionStatus?: (versionStatus: string) => void
  setMenu?: (
    menu: MenuVersionCreateGraphQLResponse['createMenuVariant']
  ) => void
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const parentId = contentElement.getParentId()
  const versionId = contentElement.getVersionId()
  const contentType = contentElement.getContentType()
  const {
    versionName = '',
    description = '',
    channels = null,
    name = '',
    locales = null,
  } = values
  try {
    const data: FormSubmitActionProps = {
      shouldUseGraphQL: evaluateGraphQLFF({
        shouldUseGraphQL,
        contentType: contentElement.getContentType(),
        mode: modeAction.getMode(),
      }),
      graphQLOperation:
        typeof contentElement.getGraphQLMutation === 'undefined'
          ? null
          : contentElement.getGraphQLMutation(contentType),
      restEndpoint: contentElement.getRESTEndpoint({
        parentId,
        versionId,
      }),
      versionName: versionName as string,
      description: description as string,
      versionId,
      parentId,
      parentName: name as string,
      contentType: contentElement.getContentType(),
    }

    if (hasInvalidCharacters(data.versionName)) {
      await showSpecialCharactersToast()
      return
    }

    if (channels) {
      data.channels = channels
    }

    if (locales) {
      data.locales = locales
    }

    const response = await modeAction.submit(data)

    switch (modeAction.getMode()) {
      case MODAL_ACTION.EDIT:
        if (
          data.contentType === VersionTypesEnum.GLOBAL_ELEMENTS ||
          data.contentType === VersionTypesEnum.PAGES
        ) {
          store.dispatch(EDITOR_UPDATE_SUCCESS(data))
        } else {
          setVersionName(data.versionName)
          setVersionDescription(data.description)
        }
        void showToast({
          message: MESSAGES.getSavedSuccess(data.versionName),
          kind: TOAST_MESSAGE_TYPES.SUCCESS,
        })
        break
      case MODAL_ACTION.COPY: {
        let copiedVersionId: string
        if (data.contentType === VersionTypesEnum.MENUS) {
          const {
            copyMenuVariant,
          } = response.data as MenuVersionCopyGraphQLResponse
          copiedVersionId = copyMenuVariant.id
          setVersionName(copyMenuVariant.versionName)
          setVersionDescription(copyMenuVariant.description)
          setVersionStatus(VERSION_STATUS.DRAFT)
        }
        if (
          data.contentType === VersionTypesEnum.GLOBAL_ELEMENTS ||
          (data.contentType === VersionTypesEnum.PAGES && !shouldUseGraphQL)
        ) {
          const { version } = response
          copiedVersionId = version?.versionId as string
        }
        if (data.contentType === VersionTypesEnum.PAGES && shouldUseGraphQL) {
          const {
            copyPageVariant,
          } = response.data as PageVersionCopyGraphQLResponse
          copiedVersionId = copyPageVariant?.id
        }
        void showToast({
          message: MESSAGES.getCreatedSuccess(data.versionName),
          kind: TOAST_MESSAGE_TYPES.SUCCESS,
        })
        const redirectPath = contentElement.getRedirectPath({
          parentId,
          versionId: copiedVersionId,
        })
        await navigate(redirectPath)
        break
      }
      case MODAL_ACTION.CREATE: {
        let createdVersionId: string
        void showToast({
          message: MESSAGES.getCreatedSuccess(data.versionName),
          kind: TOAST_MESSAGE_TYPES.SUCCESS,
        })
        if (contentType === VersionTypesEnum.MENUS) {
          const {
            createMenuVariant,
          } = response.data as MenuVersionCreateGraphQLResponse
          createdVersionId = createMenuVariant.id
          setVersionName(createMenuVariant.versionName)
          setVersionDescription(createMenuVariant.description)
          setVersionStatus(VERSION_STATUS.DRAFT)
          setMenu(createMenuVariant)
        } else {
          const { version } = response
          createdVersionId = version.versionId as string
        }
        const redirectPath = contentElement.getRedirectPath({
          parentId,
          versionId: createdVersionId,
        })
        await navigate(redirectPath)
        break
      }
      default:
        break
    }

    handleClose()
  } catch (err) {
    console.error(err)
    let errMessage =
      contentType !== VersionTypesEnum.MENUS
        ? EDITOR_TOAST_MESSAGES.PAGE_VERSION_EDIT_FAILURE
        : EDITOR_TOAST_MESSAGES.MENU_VERSION_EDIT_FAILURE

    const errorStatus = ((err.graphQLErrors &&
      err.graphQLErrors[0]?.extensions?.errorCode) ||
      err.status) as number

    if (errorStatus === httpStatusCodes.CONFLICT) {
      errMessage = VALIDATION_ERRORS.CONFLICT_VERSION_NAME
    }
    void showToast({
      message: errMessage,
      kind: TOAST_MESSAGE_TYPES.ALERT,
    })
  }
}

export const GetDialogModalSections = ({
  contentType,
  parentElement,
  versionElement,
  handleClose,
  navigate,
  shouldUseGraphQL,
  mode,
  i18n,
  setVersionName,
  setVersionDescription,
  setVersionStatus,
  setMenu,
}: {
  contentType: VersionTypesEnum
  parentElement:
    | PageParentElementProps
    | GEParentElementProps
    | MenuParentElementProps
  versionElement: CommonElementProps
  handleClose: () => void
  navigate: (redirectUrl: string) => Promise<void>
  shouldUseGraphQL: boolean
  mode: string
  i18n: boolean
  setVersionName: (versionName: string) => void
  setVersionDescription: (versionName: string) => void
  setVersionStatus?: (versionStatus: string) => void
  setMenu?: (
    menu: MenuVersionCreateGraphQLResponse['createMenuVariant']
  ) => void
}): DialogModalSectionProps => {
  const modeAction = GetModeActions(mode)

  const navNameChange = useNewNavigation()
  const basepath = getBasePath(navNameChange)

  let contentElement: ContentTypeFunctionProps
  const pageContentType = PageContentType(
    parentElement as PageParentElementProps,
    versionElement,
    modeAction,
    basepath
  )

  const menuContentType = MenuContentType(
    parentElement as MenuParentElementProps,
    versionElement,
    modeAction,
    navNameChange
  )

  if (contentType === VersionTypesEnum.PAGES) {
    switch (mode) {
      case MODAL_ACTION.COPY:
        contentElement = pageContentType
        break
      case MODAL_ACTION.VIEW:
      case MODAL_ACTION.EDIT:
        contentElement = pageContentType
        break
      default:
        break
    }
  }
  if (contentType === VersionTypesEnum.MENUS) {
    contentElement = menuContentType
  } else if (contentType === VersionTypesEnum.GLOBAL_ELEMENTS) {
    switch (mode) {
      case MODAL_ACTION.COPY:
      case MODAL_ACTION.VIEW:
      case MODAL_ACTION.EDIT:
        contentElement = ExistingGEContentType(
          parentElement as GEParentElementProps,
          versionElement,
          modeAction,
          basepath
        )
        break
      case MODAL_ACTION.CREATE:
        contentElement = NewGEContentType(
          parentElement as GEParentElementProps,
          modeAction,
          basepath
        )
        break
      default:
        break
    }
  }

  const validationSchema = Yup.object().shape({
    versionName: Yup.string().required(VALIDATION_ERRORS.EMPTY_VERSION_NAME),
    ...(i18n && {
      locales: Yup.array().min(1, VALIDATION_ERRORS.MIN_LOCALE_REQUIRED),
    }),
  })

  return {
    'general-details': {
      key: 'general-details',
      title: MODAL_SECTIONS.GENERAL_DETAILS,
      render: () => (
        <Formik
          initialValues={contentElement.getInitialValues()}
          validationSchema={validationSchema}
          onSubmit={values => {
            void handleFormSubmit({
              values,
              contentElement,
              shouldUseGraphQL,
              modeAction,
              navigate,
              handleClose,
              setVersionName,
              setVersionDescription,
              setVersionStatus,
              setMenu,
            })
          }}
        >
          {formikProps => (
            <>
              <VersionDetailHeader
                {...getModeConfigurationForHeader({
                  mode,
                  onSubmitHandler: formikProps.handleSubmit,
                  onCloseHandler: handleClose,
                  submitDisabled: !isEmpty(formikProps.errors),
                })}
              />
              <form onSubmit={formikProps.handleSubmit}>
                {getFormInputFields({
                  contentElement,
                  mode,
                  ...formikProps,
                })}
              </form>
            </>
          )}
        </Formik>
      ),
    },
  }
}
