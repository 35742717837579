import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledMenuContent = styled.div`
  width: 184px;
  li {
    ${props => props.theme.typography.h5};
    padding: 14px 20px;
    cursor: pointer;
    display: block;
    &:hover {
      background-color: ${themeUi.palette.ui.neutral.grey5};
    }
  }
`

export const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
`

export const StyledListItem = styled.li`
  display: ${props => (props.display ? props.display : 'inline-block')};
  padding: 0;
`

export const StyledMyAccount = styled.div``

export const StyledMyAccountItem = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledWrapper = styled.div`
  height: 55px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid ${themeUi.palette.ui.neutral.grey4};
  background: ${themeUi.palette.brand.primary.white};
  .title {
    ${props => props.theme.typography.h4.css};
    margin-left: 24px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    font-weight: ${props => props.theme.typography.fontWeight.semibold};
    font-size: ${props => props.theme.typography.fontSize.lg};
    .header-menu {
      margin-left: 8px;
    }
  }
  .icons_wrapper {
    margin-right: 24px;
  }
  .list_items {
    display: flex;
    list-style: none;
    li {
      margin-right: ${props => props.theme.space.space_5};
      width: 24px;
      height: 24px;
      :last-child {
        margin-right: 0px;
      }
      a {
        display: inline-block;
        height: 100%;
      }
    }
  }
`
export const StyledHeaderSpaceHolder = styled.div`
  height: 55px;
`
export const StyledHeader = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  .apps_wrapper {
    background-color: ${themeUi.palette.brand.primary.white};
    position: absolute;
    top: 40px;
    right: 70px;
    margin: 0px;
    border-radius: ${props => props.theme.borderRadius.lg};
    z-index: ${props => props.theme.zIndex.basic};
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    place-items: center;
    ${themeUi.shadows.light.level1}

    li {
      cursor: pointer;
      display: grid;
      place-items: center;
      flex-direction: column;
      align-items: center;
      .icon {
        display: grid;
        place-items: center;
        width: 56px;
        height: 56px;
      }
      .label {
        ${props => props.theme.typography.labelSmall};
        color: ${themeUi.palette.brand.primary.gray};
        text-transform: uppercase;
      }
    }
  }
`
