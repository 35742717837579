import {
  ALL_COMPONENTS,
  DESCRIPTOR_DETAILS,
  MESSAGES,
  TOAST_MESSAGE_TYPES,
} from 'src/constants'
import {
  CREATE_UNIVERSAL_DESCRIPTOR,
  GET_UNIVERSAL_DESCRIPTORS,
  GET_UNIVERSAL_DESCRIPTOR_BY_ID,
  UPDATE_UNIVERSAL_DESCRIPTOR_BY_ID,
} from 'services/graphql/universalDescriptors'
import {
  DescriptorByIdData,
  DescriptorByIdVars,
  DescriptorDetailsProps,
} from './types'
import { InvalidContextProps, emptyData } from '../types'
import React, { useEffect, useState } from 'react'
import {
  StyledBackButton,
  StyledContainer,
  StyledDescriptorWrapper,
  StyledIconBack,
} from './styles'
import { handleParseToJson, setInitialData } from './utils'
import { useLazyQuery, useMutation } from '@apollo/client'
import AddComponentMode from '../utils/AddComponentMode'
import DescriptorContent from './components/descriptor-content'
import DescriptorHeader from './components/descriptor-header'
import { DescriptorProps } from 'store/types'
import DuplicateDescriptorsModal from '../modals/DuplicateDescriptorsModal'
import EditComponentMode from '../utils/EditComponentMode'
import InvalidUploadDataModal from '../modals/InvalidUploadDataModal'
import Spinner from 'components/Spinner'
import { StyledWrapper } from '../styles'
import ViewComponentMode from 'modules/settings/utils/ViewComponentMode'
import isError from 'lodash/isError'
import omit from 'lodash/omit'
import { showToast } from 'components/ToastSnackbarContainer'
import { validateUploadData } from '../utils'

// eslint-disable-next-line sonarjs/cognitive-complexity
const DescriptorDetails: React.FunctionComponent<DescriptorDetailsProps> = props => {
  const {
    mode,
    setMode,
    id,
    onNavigateBack,
    descriptorIds,
    showDeleteModal,
  } = props
  const [updateUniversalDescriptor] = useMutation<DescriptorProps>(
    UPDATE_UNIVERSAL_DESCRIPTOR_BY_ID
  )
  const [createUniversalDescriptor] = useMutation(CREATE_UNIVERSAL_DESCRIPTOR, {
    update(cache, { data: updatedData }) {
      const {
        universalDescriptors,
      }: { universalDescriptors: DescriptorProps[] } = cache.readQuery({
        query: GET_UNIVERSAL_DESCRIPTORS,
      })
      cache.writeQuery({
        query: GET_UNIVERSAL_DESCRIPTORS,
        data: {
          universalDescriptors: [
            ...universalDescriptors,
            updatedData.createUniversalDescriptor,
          ],
        },
      })
    },
  })
  const [
    singleDescriptor,
    setSingleDescriptor,
  ] = useState<DescriptorProps | null>(null)

  const [labelChanged, setLabelChanged] = useState(false)
  const [idChanged, setIdChanged] = useState(false)

  const [
    invalidDataContext,
    setInvalidDataContext,
  ] = useState<InvalidContextProps | null>(null)

  const [isDuplicateIdModalVisible, setIsDuplicateIdModalVisible] = useState(
    false
  )
  const handleModalClose = () => {
    setInvalidDataContext(null)
    setIsDuplicateIdModalVisible(false)
  }

  const [getDescriptor, { data, loading, error }] = useLazyQuery<
    DescriptorByIdData,
    DescriptorByIdVars
  >(GET_UNIVERSAL_DESCRIPTOR_BY_ID)

  useEffect(() => {
    const fetchDescriptor = async () => {
      await getDescriptor({ variables: { id } })
    }

    id ? void fetchDescriptor() : setInitialData(emptyData, setSingleDescriptor)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async () => {
    const cleanedSingleDescriptor = omit(singleDescriptor, ['__typename'])
    const result = handleParseToJson(
      cleanedSingleDescriptor.attributes as string
    )

    if (isError(result.error)) {
      void showToast({
        message: DESCRIPTOR_DETAILS.MESSAGES.JSON_PARSE_ERROR,
        kind: TOAST_MESSAGE_TYPES.ALERT,
      })
      return
    }

    const descriptorToValidate = {
      ...cleanedSingleDescriptor,
      attributes: result.attributes,
    }

    const isUploadDataValid = validateUploadData(
      [descriptorToValidate],
      setInvalidDataContext
    )

    if (!isUploadDataValid) {
      return
    }

    if (
      mode === AddComponentMode &&
      descriptorIds &&
      descriptorIds.includes(cleanedSingleDescriptor.id)
    ) {
      setIsDuplicateIdModalVisible(true)
      return
    }

    try {
      const input = {
        ...cleanedSingleDescriptor,
        description: cleanedSingleDescriptor.description ?? '',
        attributes: result.attributes,
      }

      if (mode === EditComponentMode) {
        await updateUniversalDescriptor({
          variables: {
            input,
          },
        })
      } else {
        await createUniversalDescriptor({
          variables: {
            input,
          },
        })
      }

      void showToast({
        message: DESCRIPTOR_DETAILS.MESSAGES.SUCCESS,
        kind: TOAST_MESSAGE_TYPES.SUCCESS,
      })
      mode.setModeAfterSave(setMode)
    } catch (err) {
      console.error(err)
      void showToast({
        message: DESCRIPTOR_DETAILS.MESSAGES.ERROR,
        kind: TOAST_MESSAGE_TYPES.ALERT,
      })
    }
  }

  const handleCancel = () => {
    if (id) {
      setMode(ViewComponentMode)
      setInitialData(data, setSingleDescriptor)
    } else {
      onNavigateBack()
    }
  }

  useEffect(() => {
    if (data?.universalDescriptorById) {
      setInitialData(data, setSingleDescriptor)
    }
  }, [data])

  if (error) {
    void showToast({
      message: MESSAGES.getFetchingError(`"${id}"`),
      kind: TOAST_MESSAGE_TYPES.ALERT,
    })
    onNavigateBack()
  }

  if (loading) {
    return <Spinner variant='fullScreen' />
  }

  return (
    <StyledWrapper>
      <StyledContainer>
        <StyledBackButton data-testid='btn-go-back' onClick={onNavigateBack}>
          <StyledIconBack />
          {ALL_COMPONENTS}
        </StyledBackButton>
        <StyledDescriptorWrapper>
          <DescriptorHeader
            mode={mode}
            setMode={setMode}
            label={mode.retrieveHeader(data?.universalDescriptorById?.label)}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            submitDisabled={mode.shouldHideSubmitButton(
              singleDescriptor,
              idChanged
            )}
            showDeleteModal={showDeleteModal}
          />
          {singleDescriptor && (
            <DescriptorContent
              mode={mode}
              descriptor={singleDescriptor}
              setSingleDescriptor={setSingleDescriptor}
              isEditing={mode.isEditMode()}
              setLabelChanged={setLabelChanged}
              labelChanged={labelChanged}
              setIdChanged={setIdChanged}
              idChanged={idChanged}
            />
          )}
        </StyledDescriptorWrapper>
      </StyledContainer>

      {invalidDataContext && (
        <InvalidUploadDataModal
          onClose={handleModalClose}
          context={invalidDataContext}
        />
      )}
      {isDuplicateIdModalVisible && (
        <DuplicateDescriptorsModal
          onClose={handleModalClose}
          duplicateIds={new Set([singleDescriptor?.id])}
        />
      )}
    </StyledWrapper>
  )
}

export default DescriptorDetails
