import { useEffect, useState } from 'react'
import getHasEditAccessBasedOnVersionStatus from 'lib/getHasEditAccessBasedOnVersionStatus'
import { useUserPermissions } from 'contexts/userPermissions'

export const useVersionBasedEditAccess = (versionStatus: string): boolean => {
  const { hasPublisherPermissions, hasEditorPermissions } = useUserPermissions()
  const hasAccess = getHasEditAccessBasedOnVersionStatus(
    { hasEditorPermissions, hasPublisherPermissions },
    versionStatus
  )
  const [hasEditAccess, setHasEditAccess] = useState<boolean>(false)
  useEffect(() => {
    setHasEditAccess(hasAccess)
  }, [versionStatus, hasAccess])

  return hasEditAccess
}
