import styled from 'styled-components'
import theme from 'styles/theme'
import { theme as themeUi } from '@teamfabric/copilot-ui'

const getButtonTypeBackground = buttonType =>
  buttonType === 'text-only'
    ? 'transparent'
    : themeUi.palette.brand.primary.white

const space2 = theme.space.space_2
const space3 = theme.space.space_3

export const StyledDtContainer = styled.label`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 6px 0;
  position: relative;
`

export const StyledDtLabel = styled.div`
  ${props => props.theme.typography.labelSmall};
  font-family: ${props => props.theme.typography.fontFamily.sans};
  color: ${themeUi.palette.brand.primary.gray};
  margin-bottom: 8px;
`

export const StyledComponentButton = styled.div`
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: ${({ buttonType }) => getButtonTypeBackground(buttonType)};
  position: relative;
  cursor: pointer;
  border: 1px solid
    ${({ buttonType }) =>
      buttonType === 'text-only'
        ? 'transparent'
        : themeUi.palette.ui.neutral.grey4};
`

export const StyledButton = styled.div`
  ${props => props.theme.typography.label};
  background-color: ${({ buttonType }) => getButtonTypeBackground(buttonType)};
  color: ${({ buttonType }) =>
    buttonType === 'text-only'
      ? '#58687a'
      : themeUi.palette.brand.primary.charcoal};
  font-size: ${props =>
    props.buttonType === 'text-only'
      ? '12px'
      : props.theme.typography.fontSize.sm};
  font-weight: ${props => props.theme.typography.fontWeight.semibold};
  font-family: ${props => props.theme.typography.fontFamily.sans};
  line-height: 16px;
  letter-spacing: 0.2px;
  padding-left: 0.5rem;
`

export const StyledPlusIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const StyledWrapper = styled.div`
  display: block;
  width: 100%;
  padding: ${({ isImageType }) =>
    isImageType ? `${space2} 0` : `${space2} ${space3}`};

  input {
    background: ${themeUi.palette.brand.primary.white};
  }

  .label-padding {
    padding: 0 ${space3} ${space2};
  }
`

export const StyledLabel = styled.label`
  font-size: ${props => props.theme.typography.fontSize.xs};
  color: ${themeUi.palette.brand.primary.gray};
`

export const StyledLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
`

export const StyledErrorMessage = styled.div`
  font-weight: ${props => props.theme.typography.fontWeight.medium};
  font-size: ${props => props.theme.typography.fontSize.xs};
  letter-spacing: ${props => props.theme.typography.letterSpacing.wide};
  color: ${themeUi.palette.ui.cta.red};
  padding: ${space2} ${space3};
`
