import {
  ARCHIVE_GE_VERSION,
  COPY_GLOBAL_ELEMENT_ASYNC,
  CREATE_GLOBAL_ELEMENT_VARIANT,
  DELETE_GE_ASYNC_STRING,
  DELETE_GE_VARIANT_STRING,
  DUPLICATE_GE_VERSION_STRING,
  UNARCHIVE_GE_VERSION,
} from 'src/services/graphql/globalElements'
import { ApolloError, FetchResult, gql } from '@apollo/client/core'
import {
  GECopyGraphQLResponse,
  GEDeleteGraphQLResponse,
  GEVariantArchiveGraphQLResponse,
  GEVariantCreationGraphQLResponse,
  GEVariantDeleteGraphQLResponse,
  GEVariantDuplicateGraphQLResponse,
  GEVariantUnarchiveGraphQLResponse,
} from 'src/graphql-proxy/transformations/globalElements/types'
import { ACTIONS } from 'components/ContentTableModule/useTableData'
import { CREATE_GLOBAL_ELEMENT } from 'services/graphql'
import { CreatedGEResponse } from 'src/components/FormDialog/globalElement/CreateNewGEDialog/types'
import { GEModuleContext } from './types'
import { MESSAGES } from 'src/constants'
import { MultiSelectTextChipsOptions } from 'lib/getMultiSelectTextChipsOptions/types'
import { handleModalOperation } from 'src/data/services'
import { multiTextChipsOptionsToDTO } from 'data/utils'
import { useApolloClient } from '@apollo/client/react/hooks/useApolloClient'
import { useCallback } from 'react'
import { useNavigate } from '@reach/router'

type CreateGEOperationInput = {
  variables: {
    locales?: MultiSelectTextChipsOptions[]
    channels?: MultiSelectTextChipsOptions[]
    name: string
    versionName: string
  }
}

type CopyGEVariantInput = {
  variables: {
    originalVariantId: string
    globalElementId: string
    versionName: string
    description?: string
    locales?: MultiSelectTextChipsOptions[]
    channels?: MultiSelectTextChipsOptions[]
  }
}

type CopyGEInput = {
  variables: {
    originalId: string
    newGeName: string
    geVersions: string
  }
}

type CreateGEVariantInput = {
  variables: {
    locales?: MultiSelectTextChipsOptions[]
    channels?: MultiSelectTextChipsOptions[]
    globalElementId: string
    versionName: string
    description?: string
  }
}

type DeleteGEVariantInput = {
  variables: {
    variantId: string
    globalElementId: string
    versionName: string
  }
  context: {
    isLastVariant: boolean
  }
}

type ArchiveUnarchiveGEVariantInput = {
  variables: {
    variantId: string
    globalElementId: string
  }
  context: {
    isArchived: boolean
    isLastVariant: boolean
  }
}

type DeleteGlobalElementInput = {
  variables: {
    globalElementId: string
    globalElementName: string
  }
}

export interface GEModalOperations {
  createGlobalElement: (input: CreateGEOperationInput) => Promise<void>
  copyGlobalElementVariant: (input: CopyGEVariantInput) => Promise<void>
  copyGlobalElement: (input: CopyGEInput) => Promise<void>
  createGlobalElementVariant: (input: CreateGEVariantInput) => Promise<void>
  deleteGlobalElementVariant: (input: DeleteGEVariantInput) => Promise<void>
  deleteGlobalElement: (input: DeleteGlobalElementInput) => Promise<void>
  archiveUnarchiveGlobalElementVariant: (
    input: ArchiveUnarchiveGEVariantInput
  ) => Promise<void>
}

export const useGEModalOperations = ({
  context,
}: {
  context: GEModuleContext
}): GEModalOperations => {
  const navigate = useNavigate()
  const apolloClient = useApolloClient()
  const GE_REFETCH_TIME = 2000
  const createGlobalElement = useCallback(
    (input: CreateGEOperationInput) => {
      return handleModalOperation<
        FetchResult<CreatedGEResponse['data']>,
        ApolloError
      >({
        operation: () =>
          apolloClient.mutate({
            mutation: CREATE_GLOBAL_ELEMENT,
            variables: {
              input: {
                name: input.variables.name,
                versionName: input.variables.versionName,
                locales: multiTextChipsOptionsToDTO(input.variables.locales),
                channels: multiTextChipsOptionsToDTO(input.variables.channels),
              },
            },
          }),
        config: {
          onSuccess: response => {
            context.closeTableModal()
            const data = response?.data?.createGlobalElement
            const variantId = data?.versions?.[0]?.versionId
            const globalElementId = data?.id
            navigateToGEVariantEditor(
              globalElementId,
              variantId as string,
              navigate
            )
          },
          onError: console.error,
          snackbarProps: ({ data }) => {
            if (data) {
              return MESSAGES.getCreatedSuccess(input.variables.name)
            }
          },
        },
      })
    },
    [apolloClient, context, navigate]
  )

  const copyGlobalElementVariant = useCallback(
    (input: CopyGEVariantInput) => {
      return handleModalOperation<
        FetchResult<GEVariantDuplicateGraphQLResponse>
      >({
        operation: () =>
          apolloClient.mutate({
            mutation: gql(DUPLICATE_GE_VERSION_STRING),
            variables: {
              input: {
                name: input.variables.versionName,
                description: input.variables.description,
                originalContentVariantId: input.variables.originalVariantId,
                parentContentId: input.variables.globalElementId,
              },
            },
          }),
        config: {
          onSuccess: response => {
            context.closeTableModal()
            const data = response?.data?.copyGlobalElementVariant
            const variantId = data?.id
            navigateToGEVariantEditor(
              input.variables.globalElementId,
              variantId,
              navigate
            )
          },
          onError: console.error,
          snackbarProps: ({ data }) => {
            if (data) {
              return MESSAGES.getCreatedSuccess(input.variables.versionName)
            }
          },
        },
      })
    },
    [apolloClient, context, navigate]
  )

  const copyGlobalElement = useCallback(
    (input: CopyGEInput) => {
      return handleModalOperation<FetchResult<GECopyGraphQLResponse>>({
        operation: () =>
          apolloClient.mutate({
            mutation: gql(COPY_GLOBAL_ELEMENT_ASYNC),
            variables: {
              input: {
                originalId: input.variables.originalId,
                copiedName: input.variables.newGeName,
              },
            },
          }),
        config: {
          onSuccess: () => {
            context.closeTableModal()
            context.dispatchTableAction(ACTIONS.REFETCH, null, GE_REFETCH_TIME)
          },
          onError: console.error,
          snackbarProps: ({ data }) => {
            if (data) {
              return MESSAGES.getCreatedSuccess(input.variables.newGeName)
            }
          },
        },
      })
    },
    [apolloClient, context, navigate]
  )

  const createGlobalElementVariant = useCallback(
    (input: CopyGEVariantInput) => {
      return handleModalOperation<
        FetchResult<GEVariantCreationGraphQLResponse>
      >({
        operation: () =>
          apolloClient.mutate({
            mutation: CREATE_GLOBAL_ELEMENT_VARIANT,
            variables: {
              input: {
                name: input.variables.versionName,
                description: input.variables.description,
                parentContentId: input.variables.globalElementId,
                channels: multiTextChipsOptionsToDTO(input.variables.channels),
                locales: multiTextChipsOptionsToDTO(input.variables.locales),
              },
            },
          }),
        config: {
          onSuccess: response => {
            context.closeTableModal()
            const data = response?.data?.createGlobalElementVariant
            const variantId = data?.id
            navigateToGEVariantEditor(
              input.variables.globalElementId,
              variantId,
              navigate
            )
          },
          snackbarProps: ({ data }) => {
            const versionName = input.variables.versionName
            if (data) {
              return MESSAGES.getCreatedSuccess(versionName)
            }
          },
          onError: console.error,
        },
      })
    },
    [apolloClient, context, navigate]
  )

  const archiveUnarchiveGlobalElementVariant = useCallback(
    (input: ArchiveUnarchiveGEVariantInput) => {
      const isArchiveOperation = !input.context.isArchived
      return handleModalOperation<
        FetchResult<
          GEVariantArchiveGraphQLResponse | GEVariantUnarchiveGraphQLResponse
        >,
        ApolloError
      >({
        operation: () =>
          apolloClient.mutate({
            mutation: isArchiveOperation
              ? ARCHIVE_GE_VERSION
              : UNARCHIVE_GE_VERSION,
            variables: {
              contentVariantId: input.variables.variantId,
              parentContentId: input.variables.globalElementId,
            },
            fetchPolicy: 'no-cache',
          }),
        config: {
          onSuccess: () => {
            context.closeTableModal()
            if (input.context.isLastVariant) {
              context.dispatchTableAction(ACTIONS.DELETE, {
                targetItemId: input.variables.globalElementId,
              })
            }
            context.dispatchTableAction(ACTIONS.DELETE_VERSION, {
              targetItemId: input.variables.variantId,
              parentId: input.variables.globalElementId,
            })
          },
          onError: console.error,
          snackbarProps: ({ data, error }) => {
            if (data) {
              return isArchiveOperation
                ? MESSAGES.getArchivedSuccess(
                    (data.data as GEVariantArchiveGraphQLResponse)
                      ?.archiveContentVariant?.versionName
                  )
                : MESSAGES.getUnarchivedSuccess(
                    (data.data as GEVariantUnarchiveGraphQLResponse)
                      ?.unarchiveContentVariant?.versionName
                  )
            }
            return error.message
          },
        },
      })
    },
    [apolloClient, context]
  )

  const deleteGlobalElementVariant = useCallback(
    (input: DeleteGEVariantInput) => {
      return handleModalOperation<
        FetchResult<GEVariantDeleteGraphQLResponse>,
        ApolloError
      >({
        operation: () =>
          apolloClient.mutate({
            mutation: gql(DELETE_GE_VARIANT_STRING),
            variables: {
              parentContentId: input.variables.globalElementId,
              contentVariantId: input.variables.variantId,
            },
            fetchPolicy: 'no-cache',
          }),
        config: {
          onSuccess: () => {
            if (input.context.isLastVariant) {
              context.dispatchTableAction(ACTIONS.DELETE, {
                targetItemId: input.variables.globalElementId,
              })
            }
            context.dispatchTableAction(ACTIONS.DELETE_VERSION, {
              targetItemId: input.variables.variantId,
              parentId: input.variables.globalElementId,
            })
            context.closeTableModal()
          },
          onError: console.error,
          snackbarProps: ({ data, error }) => {
            if (data) {
              return MESSAGES.getDeletedSuccess(
                `"${input.variables.versionName}"`
              )
            }
            return error.message
          },
        },
      })
    },
    [apolloClient, context]
  )

  const deleteGlobalElement = useCallback(
    (input: DeleteGlobalElementInput) => {
      return handleModalOperation<
        FetchResult<GEDeleteGraphQLResponse>,
        ApolloError
      >({
        operation: () =>
          apolloClient.mutate({
            mutation: gql(DELETE_GE_ASYNC_STRING),
            variables: {
              contentMetaId: input.variables.globalElementId,
            },
            fetchPolicy: 'no-cache',
          }),
        config: {
          onSuccess: () => {
            context.closeTableModal()
            context.dispatchTableAction(
              ACTIONS.REFETCH,
              {
                targetItemId: input.variables.globalElementId,
              },
              GE_REFETCH_TIME
            )
          },
          snackbarProps: ({ data, error }) => {
            if (data) {
              return MESSAGES.getDeletedSuccess(
                input.variables.globalElementName
              )
            }
            return error.message
          },
        },
      })
    },
    [apolloClient, context]
  )

  return {
    copyGlobalElementVariant,
    createGlobalElement,
    deleteGlobalElementVariant,
    archiveUnarchiveGlobalElementVariant,
    deleteGlobalElement,
    createGlobalElementVariant,
    copyGlobalElement,
  }
}

export const navigateToGEVariantEditor = (
  globalElementId: string,
  variantId: string,
  n: ReturnType<typeof useNavigate>,
  {
    preview = false,
    reschedule = false,
  }: { preview?: boolean; reschedule?: boolean } = {}
): void => {
  if (globalElementId && variantId) {
    void n(
      `ge-editor?gcId=${globalElementId}&versionId=${variantId}${
        preview ? '&preview=true' : ''
      }${reschedule ? '&reschedule=true' : ''}`
    )
  }
}
