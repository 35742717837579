import React, { FunctionComponent, useState } from 'react'
import {
  StyledColumns,
  StyledMenu,
  StyledScrollContainer,
} from '../ModalSections/styles'
import { getFormComponents, mergeYupSchemas } from './utils'
import { AnyObjectSchema } from 'yup'
import { ContentModalOptionsProps } from './types'
import { Formik } from 'formik'
import { Modal } from '@teamfabric/copilot-ui'
import VersionDetailHeader from 'components/FormDialog/components/VersionDetailHeader'
import { getModeConfigurationForHeader } from 'components/FormDialog/common/utils/getModeConfigurationForHeader'
import isEmpty from 'lodash/isEmpty'

const FormModal: FunctionComponent<ContentModalOptionsProps> = ({
  title,
  size,
  mode,
  forms,
  onClose,
  onSubmit,
}) => {
  const [activeForm, setActiveForm] = useState(forms?.length && forms[0])

  if (!activeForm) {
    return null
  }

  /* Aggregate validation schemas from all the forms in the
    modal to make sure all of fields are validated before submit */
  const validationSchemas = mergeYupSchemas(
    forms?.length
      ? forms.map(form => form?.validationSchema as AnyObjectSchema)
      : []
  )

  return (
    <Modal
      headerText={null}
      size={size}
      onClose={onClose}
      padding='0'
      onBackdropClick={onClose}
      headerButtons={[]}
      footerButtons={[]}
      render={() => (
        <StyledColumns.Grid>
          <StyledColumns.MenuSection>
            <StyledMenu.Title>
              <p role='heading' data-testid='modal-title'>
                {title}
              </p>
            </StyledMenu.Title>
            {forms.map(form => {
              const { name } = form
              return (
                <StyledMenu.MenuItem
                  key={name}
                  onClick={() => {
                    setActiveForm(form)
                  }}
                  data-testid={`modal-section-${name}`}
                  {...{ active: name === activeForm.name }}
                >
                  {name}
                </StyledMenu.MenuItem>
              )
            })}
          </StyledColumns.MenuSection>
          <StyledColumns.ContentSection>
            <StyledScrollContainer>
              <Formik
                /* Aggregate all the initial values from all the forms in the
                modal to obtain the full list of submittable values in the modal
                to initialize */
                initialValues={forms.reduce(
                  (
                    modalInitialValues,
                    { initialValues: formInitialValues }
                  ) => ({
                    ...modalInitialValues,
                    ...formInitialValues,
                  }),
                  {}
                )}
                onSubmit={onSubmit}
                validationSchema={validationSchemas}
                validateOnMount={true}
              >
                {formikProps => (
                  <>
                    <VersionDetailHeader
                      {...getModeConfigurationForHeader({
                        mode,
                        onSubmitHandler: formikProps.handleSubmit,
                        onCloseHandler: onClose,
                        submitDisabled:
                          !isEmpty(formikProps.errors) ||
                          formikProps.isSubmitting,
                      })}
                    />
                    <form onSubmit={formikProps.handleSubmit}>
                      {getFormComponents({
                        components: activeForm.getComponents(
                          formikProps.values,
                          formikProps
                        ),
                        mode,
                        ...formikProps,
                      })}
                    </form>
                  </>
                )}
              </Formik>
            </StyledScrollContainer>
          </StyledColumns.ContentSection>
        </StyledColumns.Grid>
      )}
    />
  )
}

export default FormModal
