import {
  ADD_PAGE_LABEL,
  ADD_PAGE_TYPE_LABEL,
  EMPTY_PAGES_SCREEN,
  FF_NAMES,
  NAVIGATION,
  PAGE_MODAL_LABEL,
  TABS_PAGES,
} from 'src/constants'
import { Filters, PageTypes } from 'components/Filters/types'
import {
  PAGES_MODAL_ID,
  PageModalDetails,
  PagesModuleContext,
  PagesModuleTabs,
} from 'src/data/pages-list/types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  usePageCtxOptions,
  usePageTypesCtxOptions,
  usePageVersionCtxOptions,
} from 'src/data/pages-list/context-options'
import {
  usePageTypesTableProps,
  usePagesTableProps,
  usePopulateSearchResults,
} from 'src/data/pages-list'
import { Channel } from 'store/channels/types'
import ContentListModule from 'modules/content-list-module'
import ContentModalModule from 'components/ContentModalModule'
import { ContentSearchModuleProps } from 'components/ContentSearchModule/types'
import EmptyScreen from 'components/EmptyScreen'
import { StyledEmptyScreenWrapper } from 'data/browse-menu-gql/styles'
import { fetchAllPageTypes } from 'modules/pages/page-types/actions'
import { getLocales } from 'store/i18n/selectors'
import { getPageFilterEntries } from 'src/data/utils'
import isEqual from 'lodash/isEqual'
import some from 'lodash/some'
import { useFlag } from '@unleash/proxy-client-react'
import { usePagesModalOperations } from 'data/pages-list/modal-operations'
import { usePagesModalOptions } from 'data/pages-list/modal-options'
import { useSelector } from 'react-redux'
import useTableData from 'components/ContentTableModule/useTableData'
import { useUserPermissions } from 'contexts/userPermissions'
import {
  getBasePath,
  useNewNavigation,
} from 'src/contexts/navigationLinksContext'

const TABLE_MODAL_INITIAL_VALUE = {
  visibleModalId: null,
  targetItem: null,
  page: null,
}

const PagesList = ({ channels }: { channels: Channel[] }): JSX.Element => {
  const navNameChange = useNewNavigation()
  const basepath = getBasePath(navNameChange)
  const i18n = useFlag(FF_NAMES.unleashFFs.I18N) || false
  const locales = useSelector(getLocales)
  const userPermissions = useUserPermissions()
  const featuresList = useMemo(() => {
    return {
      i18n: { enabled: i18n, locales },
      multiChannel: { enabled: some(channels), channels },
    }
  }, [i18n, locales, channels])
  const [moduleTab, setModuleTab] = useState<PagesModuleTabs>(
    PagesModuleTabs.ALL_PAGES
  )

  const isArchivedTab = moduleTab === PagesModuleTabs.ARCHIVED
  const [tableModal, setTableModal] = useState<PageModalDetails>(
    TABLE_MODAL_INITIAL_VALUE
  )

  const [allPageTypes, setAllPageTypes] = useState<PageTypes[]>(null)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [pageTypeSeachTerm, setPageTypeSearchTerm] = useState<string | null>(
    null
  )

  const initialFilters: Filters = useMemo(() => {
    return {
      channels: [],
      pageTypes: [],
      status: [],
      locales: [],
      searchTerm: '',
    }
  }, [])
  const [filters, setFilters] = useState<Filters>(initialFilters)

  useEffect(() => {
    const fetchPageTypes = async () => {
      const pageTypesData = (await fetchAllPageTypes()) as PageTypes[]
      setAllPageTypes(pageTypesData)
    }

    void fetchPageTypes()
  }, [])

  const pagesTableProps = usePagesTableProps({
    filters: useMemo(
      () => ({
        isArchived: isArchivedTab,
        ...filters,
      }),
      [isArchivedTab, filters]
    ),
    getPageCtxOptions: usePageCtxOptions({
      handlers: { showTableModal: setTableModal },
      isArchivedTab,
    }),
    getPageVersionCtxOptions: usePageVersionCtxOptions({
      handlers: { showTableModal: setTableModal },
      features: featuresList,
      basepath,
    }),
    features: featuresList,
    basepath,
  })

  const pageTypesTableProps = usePageTypesTableProps({
    getPageTypeCtxOptions: usePageTypesCtxOptions({
      handlers: { showTableModal: setTableModal },
    }),
    searchTerm: pageTypeSeachTerm,
  })

  const tableProps =
    moduleTab === PagesModuleTabs.PAGE_TYPES
      ? pageTypesTableProps
      : pagesTableProps

  const pageFilterProps = useMemo(
    () => ({
      entries: getPageFilterEntries({
        locales,
        channels,
        filters,
        pageTypes: allPageTypes ?? [],
        onFilterChange: (selectedFilters, _filterName) =>
          setFilters(prevFilters => ({
            ...prevFilters,
            searchTerm: '',
            [_filterName]: selectedFilters,
          })),
      }),
    }),
    [locales, filters, channels, allPageTypes]
  )
  const [
    tableData,
    dispatchTableAction,
    pagination,
    handlePagination,
  ] = useTableData({
    entries: tableProps.entries,
    totalCount: tableProps.totalCount,
    fetchQuery: tableProps.fetchQuery,
    initialFilters,
    filters,
  })

  const moduleContext: PagesModuleContext = useMemo(() => {
    return {
      tableModal,
      moduleTab,
      closeTableModal: () => setTableModal(TABLE_MODAL_INITIAL_VALUE),
      showTableModal: setTableModal,
      setModuleTab,
      featuresList,
      dispatchTableAction,
    }
  }, [tableModal, moduleTab, featuresList, dispatchTableAction])

  const { modalOptions } = usePagesModalOptions({
    operations: usePagesModalOperations({ context: moduleContext, basepath }),
    context: moduleContext,
  })

  /* Props */
  const ctaProps = useMemo(() => {
    let entries = []
    if (userPermissions.hasEditorPermissions) {
      entries = [
        {
          id: 'add-new-page',
          buttonText: ADD_PAGE_LABEL,
          onClick: () => {
            setTableModal({
              visibleModalId: PAGES_MODAL_ID.ADD_NEW_PAGE,
              page: null,
              targetItem: null,
            })
          },
        },
        {
          id: 'add-new-page-type',
          buttonText: ADD_PAGE_TYPE_LABEL,
          onClick: () => {
            setTableModal({
              visibleModalId: PAGES_MODAL_ID.ADD_PAGE_TYPE,
              page: null,
              targetItem: null,
            })
          },
        },
      ]
    }
    return {
      entries,
    }
  }, [userPermissions])

  const tabsProps = useMemo(
    () => ({
      activeTabIndex: moduleTab,
      setActiveTabIndex: setModuleTab,
      tabs: [
        {
          id: 'page-list-tab',
          title: TABS_PAGES.ALL_PAGES,
        },
        {
          id: 'page-types-list-tab',
          title: TABS_PAGES.PAGE_TYPES,
        },
        {
          id: 'archived-list-tab',
          title: TABS_PAGES.ARCHIVED,
        },
      ],
    }),
    [moduleTab, setModuleTab]
  )

  const {
    dropDownValues,
    clearSearchDropdown,
    isSearching,
  } = usePopulateSearchResults(searchTerm)

  const onSearch = useCallback(() => {
    setFilters(() => ({
      channels: [],
      pageTypes: [],
      status: [],
      locales: [],
      searchTerm,
    }))
    setPageTypeSearchTerm(searchTerm)
    clearSearchDropdown()
  }, [clearSearchDropdown, searchTerm])

  const searchModuleProps: ContentSearchModuleProps = useMemo(() => {
    return {
      searchTerm: searchTerm,
      isSearching,
      dropdownItems: { results: dropDownValues },
      onSearch,
      onClearSearch: () => {
        setSearchTerm('')
        setPageTypeSearchTerm(null)
        setFilters(prevFilters => ({
          ...prevFilters,
          searchTerm: '',
        }))
      },
      onSelect: data => {
        if (data.tags === PAGE_MODAL_LABEL.PAGE_TYPE) {
          setModuleTab(PagesModuleTabs.PAGE_TYPES)
        } else if (data.tags === TABS_PAGES.ARCHIVED) {
          setModuleTab(PagesModuleTabs.ARCHIVED)
        }
        onSearch()
      },
      onInputChange: setSearchTerm,
    }
  }, [dropDownValues, isSearching, onSearch, searchTerm])
  const pagesModalProps = {
    visibleModalId: tableModal.visibleModalId,
    modalOptions,
  }

  const paginationProps = useMemo(
    () => ({
      ...pagination,
      showPagination: true,
      handlePagination,
    }),
    [pagination, handlePagination]
  )

  const noResults = useMemo(() => {
    return {
      searchTerm,
      onClearSearch: searchModuleProps.onClearSearch,
      isArchivedTab,
      contentType: NAVIGATION.PAGES,
    }
  }, [isArchivedTab, searchModuleProps.onClearSearch, searchTerm])

  const isAllPagesTab = moduleTab === PagesModuleTabs.ALL_PAGES
  const showEmptyScreen = useMemo(
    () =>
      isEqual(filters, initialFilters) &&
      !pagesTableProps.entries?.length &&
      !pagesTableProps.isLoading &&
      isAllPagesTab,
    [pagesTableProps, filters, initialFilters, isAllPagesTab]
  )

  if (showEmptyScreen) {
    return (
      <>
        <StyledEmptyScreenWrapper data-testid='pages-empty-screen'>
          <EmptyScreen
            buttonProps={{
              size: 'small',
              text: EMPTY_PAGES_SCREEN.buttonText,
              onClick: () =>
                setTableModal({
                  visibleModalId: PAGES_MODAL_ID.ADD_NEW_PAGE,
                  page: null,
                  targetItem: null,
                }),
              disabled: !userPermissions.hasEditorPermissions,
            }}
            data-testid='empty-pages'
            primaryText={EMPTY_PAGES_SCREEN.primaryText}
            secondaryText={EMPTY_PAGES_SCREEN.secondaryText}
          />
        </StyledEmptyScreenWrapper>
        <ContentModalModule {...pagesModalProps} />
      </>
    )
  }

  return (
    <ContentListModule
      ctaProps={ctaProps}
      tabProps={tabsProps}
      searchProps={searchModuleProps}
      filterProps={shouldShowFilters(moduleTab) ? pageFilterProps : null}
      modalProps={pagesModalProps}
      tableProps={{
        data: tableData,
        loading: tableProps.isLoading,
        columns: tableProps.columns,
        paginationProps,
        noResults,
      }}
    />
  )
}

const shouldShowFilters = (moduleTab: PagesModuleTabs) => {
  if (moduleTab === PagesModuleTabs.PAGE_TYPES) {
    return false
  }
  return true
}

export default PagesList
