import {
  GE_PATH,
  NEW_GE_PATH,
  NEW_NAV_PAGES_PATH,
  PAGES_PATH,
} from 'contexts/navigationLinksContext'
import { StyledFrame, StyledIframeWrapper } from './styles'
import { editorPreviewType, gcEditorPreviewType } from 'src/constants'
import { useLocation, useNavigate } from '@reach/router'
import IconArrowBack from 'assets/icons/IconArrowBack'
import PropTypes from 'prop-types'
import React from 'react'
import cn from 'classnames'
import extract from 'lib/extract'
import isEmpty from 'lodash/isEmpty'
import isPlainObject from 'lodash/isPlainObject'
import { noop } from 'lodash'
import { useNewNavigation } from 'src/contexts/navigationLinksContext'

const PreviewFrame = props => {
  const {
    configuration,
    iframe,
    handleIframeOnLoad,
    iframeSource,
    type,
  } = props
  const navNameChange = useNewNavigation()
  const navigate = useNavigate()
  const { search } = useLocation()
  const { preview } = extract.queryParams(search)
  const enablePreviewMode = preview === 'true'
  const path = {
    [editorPreviewType]: navNameChange ? NEW_NAV_PAGES_PATH : PAGES_PATH,
    [gcEditorPreviewType]: navNameChange ? NEW_GE_PATH : GE_PATH,
  }

  if (!isPlainObject(configuration) || isEmpty(configuration)) return null

  return (
    <StyledIframeWrapper
      className={cn({ 'enable-preview-mode': enablePreviewMode })}
      data-testid='iframe-wrapper'
    >
      <div
        className='overlay-icon'
        onClick={() => {
          navigate(path[type])
        }}
        title='Close view'
        data-testid='overlay-icon'
      >
        <IconArrowBack />
      </div>
      <StyledFrame
        id='previewIframe'
        src={iframeSource}
        width={iframe.width}
        height={iframe.height}
        loading='lazy'
        onLoad={handleIframeOnLoad}
        data-testid='preview-frame-iframe'
      />
    </StyledIframeWrapper>
  )
}

PreviewFrame.defaultProps = {
  configuration: {},
  handleIframeOnLoad: noop,
  iframeSource: 'about:blank',
}

PreviewFrame.propTypes = {
  configuration: PropTypes.shape({
    ecommerceAppUrl: PropTypes.string,
    loaded: PropTypes.bool,
  }).isRequired,
  iframe: PropTypes.shape({
    width: PropTypes.string,
    height: PropTypes.string,
  }),
  type: PropTypes.oneOf([editorPreviewType, gcEditorPreviewType]).isRequired,
  handleIframeOnLoad: PropTypes.func.isRequired,
  iframeSource: PropTypes.string,
}

export default PreviewFrame
