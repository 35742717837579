// func to split attributes into two categories/types i.e. array types and non array
export function splitAttributesByTypes(attributes) {
  const returnValue = [[], []]

  for (const [key, attribute] of Object.entries(attributes)) {
    if (attribute.type === 'Array') {
      returnValue[0].push(key)
    } else {
      returnValue[1].push(key)
    }
  }

  return returnValue
}
