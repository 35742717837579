const parseMenu = (menu = {}) => {
  return {
    id: menu.id || menu._id,
    label: menu.label,
    params: menu.params || [],
    path: menu.path || '/',
    parent: menu.parent || null,
    parentName: menu.parentName || null,
    isActive: menu.isActive || false,
    order: menu.order,
  }
}

export default parseMenu
