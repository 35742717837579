import { Link, useLocation } from '@reach/router'
import { Navigation } from '@teamfabric/copilot-ui'
import React from 'react'
import { SidebarContainer } from './styles'
import { useNavigationLinksContext } from 'contexts/navigationLinksContext'

const SidebarNavigation = () => {
  const { pathname } = useLocation()
  const navLinksContext = useNavigationLinksContext()

  const generateLinkComponent = item => {
    return (
      <Link
        key={item.link}
        to={item.link}
        data-testid={`sidebar-navigation-link-${item.link}`}
      >
        {item.label}
      </Link>
    )
  }

  return (
    <SidebarContainer>
      <Navigation
        orientation='vertical'
        links={navLinksContext.navMenuItems.map(navLink => ({
          active: navLink.link.includes(pathname),
          linkComponent: generateLinkComponent(navLink),
        }))}
      />
    </SidebarContainer>
  )
}

export default SidebarNavigation
