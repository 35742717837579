import {
  CHANGE_ACTIVE_LOCALE_SUCCESS,
  FETCH_I18N_LOCALES_SUCCESS,
  RESET_ACTIVE_LANGUAGE_SUCCESS,
} from './actions'
import { I18nLocale } from './types'

const initialState = {
  locales: null,
  activeLang: null,
}

const reducer = (
  state = initialState,
  action: {
    type: string
    locales: I18nLocale[]
    activeLang: string
  }
): { locales: I18nLocale[]; activeLang: string } => {
  switch (action.type) {
    case FETCH_I18N_LOCALES_SUCCESS:
      return {
        ...state,
        locales: action.locales,
        activeLang: action.activeLang,
      }
    case CHANGE_ACTIVE_LOCALE_SUCCESS:
      return {
        ...state,
        activeLang: action.activeLang,
      }
    case RESET_ACTIVE_LANGUAGE_SUCCESS:
      return {
        ...state,
        activeLang: null,
      }
    default:
      return state
  }
}

export default reducer
