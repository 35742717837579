import styled from 'styled-components'

export const StyledWrapper = styled.div`
  display: flex;
  padding: ${props => props.theme.space.space_6};
  width: 100%;
  height: 100%;
  overflow-x: auto;
`

export const StyledIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
