import { gql } from '@apollo/client'

export const GE_OPERATION_OPTIONS = {
  LIST_GE: 'ListGlobalElements',
  FILTER_GE: 'FilterGlobalElements',
  CREATE_GE_VERSION: 'CreateGlobalElementVariant',
  UPDATE_GE_VERSION: 'UpdateGlobalElementVariantDetails',
  ARCHIVE_GE_VERSION: 'ArchiveContentVariant',
  UNARCHIVE_GE_VERSION: 'UnarchiveContentVariant',
  DUPLICATE_GE_VERSION: 'DuplicateGlobalElementVariant',
  UPDATE_GE_COMPONENT: 'UpdateGlobalElementComponent',
}

export const UPDATE_GE_COMPONENT_STRING = `
  mutation UpdateGlobalElementComponent(
    $input: UpdateComponentsInput!
  ) {
    updateGlobalElementComponents(
      input: $input
    ) {
      id
      locales
      components
      updatedAt
    }
  }
`

export const UPDATE_GE_LOCALIZED_COMPONENTS = gql`
  mutation UpdateGlobalElementLocalizedComponent(
    $input: UpdateComponentsByLocaleInput!
  ) {
    updateGlobalElementLocalizedComponents(input: $input) {
      id
      versionName
      localizedComponents {
        locale
        components
      }
      updatedAt
    }
  }
`

export const LIST_GE_STRING = `
  query ListGlobalElements(
    $input: GlobalElementFilterInput!
    $offset: Int
    $limit: Int
    $orderBy: OrderByInput
  ) {
    globalElements(
      input: $input
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      totalCount
      edges {
        node {
          id
          name
          isArchived
          isActive
          updatedAt
          variants {
            id
            channels {
              id
              name
            }
            versionName
            status
            isArchived
            updatedAt
            startDate
            locales
          }
        }
      }
    }
  }
`

export const LIST_GE = gql`
  ${LIST_GE_STRING}
`

export const CREATE_GE_VERSION_STRING = `
  mutation CreateGlobalElementVariant($input: CreateContentVariantInput!) {
    createGlobalElementVariant(input: $input) {
      id
    }
  }
`

export const CREATE_GE_VERSION = gql`
  ${CREATE_GE_VERSION_STRING}
`

export const CREATE_GLOBAL_ELEMENT = gql`
  mutation CreateGlobalElement($input: GlobalElementCreateInput!) {
    createGlobalElement(input: $input) {
      name
      id
      channels
      versions
    }
  }
`

export const UPDATE_GE_VERSION_STRING = `
  mutation UpdateGlobalElementVariantDetails(
    $input: UpdateContentVariantInput!
  ) {
    updateGlobalElementVariantDetails(input: $input) {
      versionName
    }
  }
`

export const UPDATE_GE_VERSION = gql`
  ${UPDATE_GE_VERSION_STRING}
`

export const ARCHIVE_GE_VERSION_STRING = `
  mutation ArchiveContentVariant(
    $contentVariantId: ID!
    $parentContentId: ID!
  ) {
    archiveContentVariant(
      contentVariantId: $contentVariantId
      parentContentId: $parentContentId
    ) {
      id
      versionName
      description
      status
      startDate
      endDate
      updatedAt
      isArchived
    }
  }
`

export const ARCHIVE_GE_VERSION = gql`
  ${ARCHIVE_GE_VERSION_STRING}
`

export const UNARCHIVE_GE_VERSION_STRING = `
  mutation UnarchiveContentVariant(
    $contentVariantId: ID!
    $parentContentId: ID!
  ) {
    unarchiveContentVariant(
      contentVariantId: $contentVariantId
      parentContentId: $parentContentId
    ) {
      id
      versionName
      description
      status
      startDate
      endDate
      updatedAt
      isArchived
    }
  }
`

export const UNARCHIVE_GE_VERSION = gql`
  ${UNARCHIVE_GE_VERSION_STRING}
`

export const DUPLICATE_GE_VERSION_STRING = `
  mutation DuplicateGlobalElementVariant($input: CopyContentVariantInput!) {
    copyGlobalElementVariant(input: $input) {
      id
	    versionName
	    description
	    status
	    startDate
	    endDate
	    updatedAt
	    isArchived
	    components
	    channels {
        id
        name
        label
      }
	    locales
    }
  }
`

export const GET_GE_VERSION_BY_ID = gql`
  query GetGeVersionById(
    $parentContentId: ID!
    $contentVariantId: ID!
    $locale: String
  ) {
    globalElementVariantById(
      parentContentId: $parentContentId
      contentVariantId: $contentVariantId
      locale: $locale
    ) {
      id
      name
      updatedAt
      channels {
        id
        name
        label
      }
      variants {
        versionId: id
        name: versionName
        status
        channels {
          id
          name
          label
        }
        locales
        components
        description
        localizedComponents {
          locale
          components
        }
      }
    }
  }
`

export const DELETE_GE_ASYNC_STRING = `
  mutation DeleteGlobalElement($contentMetaId: ID!) {
    deleteGlobalElementAsync(
      contentMetaId: $contentMetaId
    ) {
        status
        message
    }
  }
`
export const DELETE_GE_VARIANT_STRING = `
  mutation DeleteGlobalElementVariant($parentContentId: ID! $contentVariantId: ID!) {
    deleteGlobalElementVariant(
      parentContentId: $parentContentId
      contentVariantId: $contentVariantId
    ) {
        id
        versionName
        status
        description
    }
  }
`

export const UPDATE_GLOBAL_COMPONENTS_AND_PUBLISH = gql`
  mutation UpdateGlobalComponentsAndPublish(
    $input: UpdateComponentsAndPublishInput!
  ) {
    updateGlobalElementComponentsAndPublish(input: $input) {
      contentId
      variantId
      status
      updatedAt
      startDate
      endDate
      channels
      components
      localizedComponents
    }
  }
`
export const UPDATE_GLOBAL_ELEMENTS_AND_SCHEDULE = gql`
  mutation UpdateGEComponentsAndSchedule(
    $input: UpdateComponentsAndScheduleInput!
  ) {
    updateGEComponentsAndSchedule(input: $input) {
      contentId
      variantId
      status
      updatedAt
      startDate
      endDate
      channels
      components
      locales
      localizedComponents
    }
  }
`

export const CREATE_GLOBAL_ELEMENT_VARIANT = gql`
  mutation CreateGlobalElementVariant($input: CreateContentVariantInput!) {
    createGlobalElementVariant(input: $input) {
      id
      versionName
      description
      status
      startDate
      endDate
      updatedAt
      isArchived
      components
      channels {
        id
        name
      }
      locales
    }
  }
`

export const COPY_GLOBAL_ELEMENT_ASYNC = `
  mutation CopyGlobalElementAsync($input: CopyGlobalElementInput!) {
    copyGlobalElementAsync(
      input: $input
    ) {
       message
       status
    }
  }
`
