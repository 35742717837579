import styled, { keyframes } from 'styled-components'
import IconDelete from 'assets/icons/IconDelete'
import { StyledItemOptionsProps } from './types'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const slideInLeft = keyframes`
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
`

export const StyledComponentList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  background: ${themeUi.palette.brand.primary.white};
  border-bottom: 1px solid ${themeUi.palette.ui.neutral.grey4};
  margin-bottom: ${props => props.theme.space.space_5};
  user-select: none;
  animation: ${slideInLeft} 200ms ease-out;
`

export const StyledComponentItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.space.space_5};
  background: ${themeUi.palette.brand.primary.white};
  cursor: pointer;
  border-top: 1px solid ${themeUi.palette.ui.neutral.grey4};
  border-left: 4px solid transparent;

  &.dragging-helper-class {
    list-style: none;
    cursor: grabbing;
    pointer-events: auto !important;
    border: 1px solid ${themeUi.palette.ui.neutral.grey4};
    ${themeUi.shadows.light.level1}
    border-left: 4px solid ${themeUi.palette.ui.states.active};
  }

  .component-context-menu {
    display: none;
  }

  &:hover {
    background: ${themeUi.palette.ui.neutral.grey5};
    opacity: 0.8;
    & svg.icon-delete {
      display: grid;
    }

    .component-context-menu {
      display: block;
    }
  }
`

export const StyledComponentItemOptions = styled.div<StyledItemOptionsProps>`
  display: grid;
  grid-template-columns: ${({ isGlobalComponentEditor }) =>
    isGlobalComponentEditor ? '24px' : `repeat(2, 24px)`};
`

// https://css-tricks.com/snippets/css/truncate-string-with-ellipsis/
export const StyledComponentItemLabel = styled.div`
  ${props => props.theme.typography.body};
  line-height: ${props => props.theme.typography.lineHeight.normal};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledIconDelete = styled(IconDelete)`
  display: none;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  fill: ${themeUi.palette.brand.primary.gray};
`
