import React, { memo } from 'react'

const IconCheckmark = props => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='12'
      viewBox='0 0 16 12'
      {...props}
    >
      <defs>
        <linearGradient id='a' x1='0%' x2='101.952%' y1='21.875%' y2='78.661%'>
          <stop offset='0%' stopColor='#929292' />
          <stop offset='99.952%' stopColor='#929292' />
        </linearGradient>
      </defs>
      <g fill='none' fillRule='evenodd'>
        <path fillRule='nonzero' d='M-4-6h24v24H-4z' />
        <path
          fill='url(#a)'
          d='M9 15.586l9.293-9.293a1 1 0 011.414 1.414l-10 10a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L9 15.586z'
          transform='translate(-4 -6)'
          {...props}
        />
      </g>
    </svg>
  )
}

export default memo(IconCheckmark)
