/* eslint-disable @typescript-eslint/no-unsafe-argument */
import Custom404 from 'pages/404'
import { FF_NAMES } from 'src/constants'
import React from 'react'
import Settings from 'modules/settings'
import { useFlag } from '@unleash/proxy-client-react'

const {
  unleashFFs: { USE_HYDRA },
} = FF_NAMES

const SettingsPage = (): JSX.Element => {
  const shouldShowSettingsUi = useFlag(USE_HYDRA) ?? false

  if (shouldShowSettingsUi) {
    return <Settings />
  }

  return <Custom404 />
}

export default SettingsPage
