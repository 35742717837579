import {
  CommonElementProps,
  ContentTypeFunctionProps,
  GEInitialValueProps,
  GEParentElementProps,
  MenuInitialValueProps,
  MenuParentElementProps,
  ModeActionFunctionProps,
  PageInitialValueProps,
  PageParentElementProps,
} from 'components/FormDialog/common/versions/VersionDetailsDialog/types'
import { FF_NAMES, FORM_CONSTANTS, MODAL_ACTION } from 'src/constants'
import getMultiSelectTextChipsOptions, {
  OptionsType,
} from 'lib/getMultiSelectTextChipsOptions'
import { BMT_PATH, NEW_BMT_PATH } from 'contexts/navigationLinksContext'
import { Channel } from 'store/channels/types'
import { VersionTypesEnum } from '../types'
import { getChannels } from 'store/channels/selectors'
import { getLocales } from 'store/i18n/selectors'
import { isNull } from 'lodash'
import some from 'lodash/some'
import { useFlag } from '@unleash/proxy-client-react'
import { useSelector } from 'react-redux'

const { VERSION_DETAILS_DIALOG } = FORM_CONSTANTS

export const PageContentType = (
  parentElement: PageParentElementProps,
  versionElement: CommonElementProps,
  modeAction: ModeActionFunctionProps,
  basepath: string
): ContentTypeFunctionProps => {
  return {
    getContentType: (): VersionTypesEnum => VersionTypesEnum.PAGES,
    getInitialValues: (): PageInitialValueProps => ({
      url: parentElement.pageUrl,
      name: parentElement.name,
      versionName: modeAction.getInputVersionName(versionElement.name),
      description: versionElement.description ?? '',
    }),
    getVersionId: (): string => versionElement.id,
    getParentId: (): string => parentElement.id,
    getInputFields: (values: PageInitialValueProps) => [
      {
        type: 'Input',
        label: VERSION_DETAILS_DIALOG.pageName,
        name: 'pageName',
        dataTestId: 'page-name-field',
        value: values.name,
        disabled: true,
      },
      {
        type: 'Input',
        label: VERSION_DETAILS_DIALOG.versionName,
        name: 'versionName',
        dataTestId: 'version-name-field',
        value: values.versionName,
        disabled: false,
      },
      {
        type: 'Input',
        label: VERSION_DETAILS_DIALOG.url,
        name: 'url',
        dataTestId: 'url-field',
        value: values.url,
        disabled: true,
      },
      {
        type: 'Textarea',
        label: VERSION_DETAILS_DIALOG.description,
        name: 'description',
        dataTestId: 'description-field',
        value: values.description,
        disabled: false,
      },
    ],
    getGraphQLMutation: () =>
      modeAction.getGraphQLMutation(VersionTypesEnum.PAGES),
    getRESTEndpoint: ({
      parentId,
      versionId,
    }: {
      parentId: string
      versionId: string
    }) =>
      modeAction.getRESTEndpoint({
        parentId,
        versionId,
        contentType: VersionTypesEnum.PAGES,
      }),
    getRedirectPath: ({ parentId, versionId }) =>
      `${basepath}/editor?pageId=${parentId}&versionId=${versionId}`,
  }
}

export const NewGEContentType = (
  parentElement: GEParentElementProps,
  modeAction: ModeActionFunctionProps,
  basepath: string
): ContentTypeFunctionProps => {
  const { channels } = useSelector(getChannels) as { channels?: Channel[] }
  const multiChannel = some(channels)
  const i18n = useFlag(FF_NAMES.unleashFFs.I18N) ?? false
  const locales = useSelector(getLocales)
  const channelsOptionList = multiChannel
    ? getMultiSelectTextChipsOptions(channels, OptionsType.CHANNELS)
    : null
  const localesOptionList = i18n
    ? getMultiSelectTextChipsOptions(locales, OptionsType.LOCALES)
    : null

  return {
    getContentType: (): VersionTypesEnum => VersionTypesEnum.GLOBAL_ELEMENTS,
    getInitialValues: (): GEInitialValueProps => ({
      locales: [],
      channels: [],
      name: parentElement.name,
      versionName: '',
      description: '',
    }),
    getVersionId: (): string => null,
    getParentId: (): string => parentElement.id,
    getInputFields: (values: GEInitialValueProps) => [
      {
        type: 'Input',
        label: VERSION_DETAILS_DIALOG.geName,
        name: 'globalElementName',
        dataTestId: 'ge-name-field',
        value: values.name,
        disabled: true,
      },
      multiChannel && {
        type: 'MultiSelectTextChips',
        label: VERSION_DETAILS_DIALOG.channels,
        name: 'channels',
        dataTestId: 'ge-channels-field',
        value: values.channels,
        dropdownOptions: channelsOptionList,
        disabled: false,
      },
      i18n && {
        type: 'MultiSelectTextChips',
        label: VERSION_DETAILS_DIALOG.locales,
        name: 'locales',
        dataTestId: 'ge-locales-field',
        value: values.locales,
        dropdownOptions: localesOptionList,
        disabled: false,
      },
      {
        type: 'Input',
        label: VERSION_DETAILS_DIALOG.versionName,
        name: 'versionName',
        dataTestId: 'ge-version-name-field',
        value: values.versionName,
        disabled: false,
      },
      {
        type: 'Textarea',
        label: VERSION_DETAILS_DIALOG.description,
        name: 'description',
        dataTestId: 'ge-description-field',
        value: values.description,
        disabled: false,
      },
    ],
    getRESTEndpoint: ({ parentId }: { parentId: string }) =>
      modeAction.getRESTEndpoint({
        parentId,
        contentType: VersionTypesEnum.GLOBAL_ELEMENTS,
      }),
    getRedirectPath: ({ parentId, versionId }) =>
      `${basepath}/ge-editor?gcId=${parentId}&versionId=${versionId}`,
  }
}

export const ExistingGEContentType = (
  parentElement: GEParentElementProps,
  versionElement: CommonElementProps,
  modeAction: ModeActionFunctionProps,
  basepath: string
): ContentTypeFunctionProps => {
  const { channels } = useSelector(getChannels) as { channels?: Channel[] }
  const i18n = useFlag(FF_NAMES.unleashFFs.I18N) ?? false
  const locales = useSelector(getLocales)
  const localesOptionList = i18n
    ? getMultiSelectTextChipsOptions(locales, OptionsType.LOCALES)
    : null
  const multiChannel = some(channels)
  const dropDownList =
    channels?.map(channel => ({
      ...channel,
      value: channel.name,
    })) || []

  return {
    getContentType: (): VersionTypesEnum => VersionTypesEnum.GLOBAL_ELEMENTS,
    getInitialValues: (): GEInitialValueProps => ({
      channels: versionElement.channels,
      name: parentElement.name,
      versionName: modeAction.getInputVersionName(versionElement.name),
      description: versionElement.description,
      locales:
        i18n &&
        getMultiSelectTextChipsOptions(
          versionElement.locales,
          OptionsType.LOCALES
        ),
    }),
    getVersionId: (): string => versionElement.id,
    getParentId: (): string => parentElement.id,
    getInputFields: (values: GEInitialValueProps) => [
      i18n && {
        type: 'MultiSelectTextChips',
        label: VERSION_DETAILS_DIALOG.locales,
        name: 'locales',
        dataTestId: 'ge-locales-field',
        value: values.locales,
        dropdownOptions: localesOptionList,
      },
      {
        type: 'Input',
        label: VERSION_DETAILS_DIALOG.geName,
        name: 'globalElementName',
        dataTestId: 'global-element-name-field',
        value: values.name,
        disabled: true,
      },
      multiChannel &&
        modeAction.getMode() !== MODAL_ACTION.COPY &&
        modeAction.getMode() !== MODAL_ACTION.VIEW && {
          type: 'MultiSelectTextChips',
          label: VERSION_DETAILS_DIALOG.channels,
          name: 'channels',
          dataTestId: 'channels-field',
          value: values.channels,
          dropdownOptions: dropDownList,
          disabled: false,
        },
      // TODO: Once CPU-6402 is complete, this field input can be removed and the modeAction.getMode() !== MODAL_ACTION.VIEW check in the field above can be removed
      multiChannel &&
        modeAction.getMode() === MODAL_ACTION.VIEW && {
          type: 'Input',
          label: VERSION_DETAILS_DIALOG.channels,
          name: 'channels',
          dataTestId: 'channels-field',
          value: values.channels.map(channel => channel.label).join(', '),
        },
      {
        type: 'Input',
        label: VERSION_DETAILS_DIALOG.versionName,
        name: 'versionName',
        dataTestId: 'version-name-field',
        value: values.versionName,
        disabled: false,
      },
      {
        type: 'Textarea',
        label: VERSION_DETAILS_DIALOG.description,
        name: 'description',
        dataTestId: 'description-field',
        value: values.description,
        disabled: false,
      },
    ],
    getRESTEndpoint: ({
      parentId,
      versionId,
    }: {
      parentId: string
      versionId: string
    }) =>
      modeAction.getRESTEndpoint({
        parentId,
        versionId,
        contentType: VersionTypesEnum.GLOBAL_ELEMENTS,
      }),
    getRedirectPath: ({ parentId, versionId }) =>
      `${basepath}/ge-editor?gcId=${parentId}&versionId=${versionId}`,
  }
}

export const MenuContentType = (
  parentElement: MenuParentElementProps,
  versionElement: CommonElementProps,
  modeAction: ModeActionFunctionProps,
  navNameChange: boolean
): ContentTypeFunctionProps => {
  const description = isNull(versionElement?.description)
    ? ''
    : versionElement?.description

  return {
    getContentType: (): VersionTypesEnum => VersionTypesEnum.MENUS,
    getInitialValues: (): MenuInitialValueProps => ({
      name: parentElement.name,
      versionName: modeAction.getInputVersionName(versionElement.name),
      description:
        modeAction.getMode() !== MODAL_ACTION.CREATE ? description : '',
    }),
    getVersionId: (): string => versionElement.id,
    getParentId: (): string => parentElement.id,
    getInputFields: (values: MenuInitialValueProps) => [
      {
        type: 'Input',
        label: VERSION_DETAILS_DIALOG.versionName,
        name: 'versionName',
        dataTestId: 'menu-version-name-field',
        value: values.versionName,
        disabled: false,
      },
      {
        type: 'Textarea',
        label: VERSION_DETAILS_DIALOG.description,
        name: 'description',
        dataTestId: 'menu-description-field',
        value: values.description,
        disabled: false,
      },
    ],
    getGraphQLMutation: () =>
      modeAction.getGraphQLMutation(VersionTypesEnum.MENUS),
    getRESTEndpoint: ({
      parentId,
      versionId,
    }: {
      parentId: string
      versionId: string
    }) =>
      modeAction.getRESTEndpoint({
        parentId,
        versionId,
        contentType: VersionTypesEnum.MENUS,
      }),
    getRedirectPath: ({ parentId, versionId }) =>
      `${
        navNameChange ? NEW_BMT_PATH : BMT_PATH
      }?menuId=${parentId}&versionId=${versionId}`,
  }
}
