import React, { useEffect } from 'react'
import AddComponentMode from 'modules/settings/utils/AddComponentMode'
import { DESCRIPTOR_DETAILS } from 'src/constants'
import { DescriptorContentProps } from './types'
import DescriptorInput from './DescriptorInput'
import { JsonValue } from 'src/types'
import { StyledContent } from './styles'
import { handleChange } from 'modules/settings/descriptor-details/utils'

const {
  HEADING: {
    COMPONENT_DETAILS,
    FORMAT,
    TYPE,
    ID,
    LABEL,
    ALLOW_NESTING,
    DESCRIPTION,
    COMPONENT_ATTRIBUTES,
    ATTRIBUTES,
  },
  MESSAGES: { MISSING_LABEL, INVALID_ID, MISSING_ID },
  ALLOW_NESTING_OPTIONS,
  INPUT_TYPES: { TEXT, DROPDOWN, TEXTAREA },
} = DESCRIPTOR_DETAILS

const DescriptorContent: React.FunctionComponent<DescriptorContentProps> = ({
  mode,
  descriptor,
  setSingleDescriptor: setDescriptor,
  isEditing,
  setLabelChanged,
  labelChanged,
  setIdChanged,
  idChanged,
}) => {
  useEffect(() => {
    if (descriptor?.label.length !== 0 && !labelChanged) {
      setLabelChanged(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptor?.label])

  useEffect(() => {
    if (descriptor?.id.length !== 0 && !idChanged) {
      setIdChanged(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptor?.id])

  const inputProperties = [
    {
      kind: TEXT,
      label: LABEL,
      value: descriptor?.label,
      onChange: handleChange,
      error: mode.showDescriptorLabelError(
        descriptor?.label.length,
        labelChanged
      ),
      errorMessage: MISSING_LABEL,
      descriptor,
      setDescriptor,
      isDisabled: !isEditing,
    },
    {
      kind: TEXT,
      label: FORMAT,
      value: 'JSON',
      isDisabled: true,
    },
    {
      kind: TEXT,
      label: TYPE,
      value: descriptor?.type,
      isDisabled: true,
    },
    {
      kind: TEXT,
      testId: 'descriptor-id-field',
      label: ID,
      onChange: handleChange,
      value: descriptor?.id,
      descriptor,
      setDescriptor,
      error: mode.showDescriptorIdError(descriptor?.id, idChanged),
      errorMessage:
        idChanged && descriptor?.id.length === 0 ? MISSING_ID : INVALID_ID,
      isDisabled: mode !== AddComponentMode,
    },
    {
      kind: DROPDOWN,
      testId: 'descriptor-nesting-field',
      className: 'descriptor-nesting-field',
      label: ALLOW_NESTING,
      value: {
        name: descriptor?.allowNestedComponents?.toString() ?? 'false',
      },
      options: ALLOW_NESTING_OPTIONS,
      onChange: handleChange,
      descriptor,
      setDescriptor,
      isDisabled: !isEditing,
    },
    {
      kind: TEXTAREA,
      innerTestId: 'descriptor-description-field',
      label: DESCRIPTION,
      value: descriptor?.description ?? '',
      onChange: handleChange,
      descriptor,
      setDescriptor,
      isDisabled: !isEditing,
    },
  ]

  return (
    <StyledContent>
      <div>
        <p>{COMPONENT_DETAILS}</p>
        {inputProperties.map(input => (
          <DescriptorInput key={input.label} {...input} />
        ))}
      </div>
      <div>
        <p>{COMPONENT_ATTRIBUTES}</p>
        <DescriptorInput
          key={ATTRIBUTES}
          className='descriptor-attributes'
          innerClassName='descriptor-attributes-textarea'
          testId='descriptor-attributes'
          innerTestId='descriptor-attributes-textarea'
          kind={TEXTAREA}
          label={ATTRIBUTES}
          value={(descriptor?.attributes as JsonValue) ?? '{}'}
          onChange={handleChange}
          descriptor={descriptor}
          setDescriptor={setDescriptor}
          isDisabled={!isEditing}
        />
      </div>
    </StyledContent>
  )
}

export default DescriptorContent
