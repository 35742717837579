import { gql } from '@apollo/client'

export const FETCH_ASSETS_LABELS_SECTIONS = gql`
  query(
    $assetInput: FilterInput
    $firstAssets: Int
    $afterAsset: String
    $lastAssets: Int
    $beforeAsset: String
    $firstSection: Int
    $afterSection: String
    $lastSection: Int
    $beforeSection: String
  ) {
    assets(
      input: $assetInput
      first: $firstAssets
      after: $afterAsset
      last: $lastAssets
      before: $beforeAsset
    ) {
      edges {
        node {
          id
          name
          url
          description
          altText
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
    labels {
      id
      name
      path
      position
      depth
    }
    sections(
      first: $firstSection
      after: $afterSection
      last: $lastSection
      before: $beforeSection
    ) {
      edges {
        node {
          id
          name
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const FETCH_ASSETS = gql`
  query FetchAssets($input: FilterInput, $first: Int, $after: String) {
    assets(input: $input, first: $first, after: $after) {
      edges {
        node {
          id
          name
          url
          description
          altText
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const FETCH_SECTIONS = gql`
  query FetchSections($first: Int, $after: String) {
    sections(first: $first, after: $after) {
      edges {
        node {
          id
          name
        }
        cursor
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`
