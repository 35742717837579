import { Button, ButtonWithIcon, Icon, Tooltip } from '@teamfabric/copilot-ui'
import { CONTENT_TYPE, CTAButtonProps, ContentEditorCTAProps } from './types'
import {
  DATE_FORMAT,
  PUBLISH_BUTTON_CLASS_NAME,
  SAVE_BUTTON_CLASS_NAME,
} from './constants'
import {
  PUBLISH_LABEL,
  RBAC_LIMITED_EDIT_ACCESS_TOOLTIP,
  SAVE_TEXT,
  SCHEDULE_LABEL,
} from 'src/constants'
import React, { FunctionComponent, useCallback, useRef, useState } from 'react'
import {
  StyledButtonWithIconWrapper,
  StyledContentEditorCTAContainer,
  StyledDropdownContent,
  StyledDropdownItem,
  StyledFilterContainer,
  StyledUpdatedAtWrapper,
} from './styles'
import {
  getLastUpdatedDateLabel,
  getPublishButtonTooltipText,
  getSaveButtonTooltipText,
} from './utils'
import LocaleFilter from 'components/EditorContentHeader/EditorFilters/LocaleFilter'
import LockIcon from 'components/LockIcon'
import format from 'date-fns/format'
import some from 'lodash/some'
import useClickOutside from 'hooks/useClickOutside'

const ContentEditorCTA: FunctionComponent<ContentEditorCTAProps> = ({
  contentType,
  isContentLive,
  lastUpdatedDateString,
  onScheduleOptionClicked,
  publishButtonProps,
  saveButtonProps,
  shouldShowLockIcon,
  locales,
  selectedLocale,
}): JSX.Element => {
  const formattedDate =
    lastUpdatedDateString?.length &&
    format(new Date(lastUpdatedDateString), DATE_FORMAT)
  return (
    <StyledContentEditorCTAContainer>
      <div>
        {some(locales) && (
          <StyledFilterContainer>
            <LocaleFilter locales={locales} activeLang={selectedLocale} />
          </StyledFilterContainer>
        )}
        <SaveButton contentType={contentType} {...saveButtonProps} />
        <PublishButton
          contentType={contentType}
          isContentLive={isContentLive}
          onScheduleOptionClicked={onScheduleOptionClicked}
          {...publishButtonProps}
        />
        {shouldShowLockIcon && (
          <LockIcon tooltipMessage={RBAC_LIMITED_EDIT_ACCESS_TOOLTIP} />
        )}
      </div>
      {lastUpdatedDateString?.length > 0 && (
        <StyledUpdatedAtWrapper>
          {getLastUpdatedDateLabel(formattedDate)}
        </StyledUpdatedAtWrapper>
      )}
    </StyledContentEditorCTAContainer>
  )
}

const SaveButton: FunctionComponent<
  CTAButtonProps & {
    contentType: CONTENT_TYPE
  }
> = ({
  contentType,
  hasTooltip,
  isDisabled,
  isVisible,
  onClick,
  isSavePrimary,
}): JSX.Element =>
  isVisible && (
    <div>
      <Button
        color={isSavePrimary ? 'primary' : 'secondary'}
        onClick={onClick}
        disabled={isDisabled}
        isPrimary={isSavePrimary}
        text={SAVE_TEXT}
        data-testid='save-button'
        size='small'
        className={SAVE_BUTTON_CLASS_NAME}
      />
      {hasTooltip && (
        <Tooltip
          position='bottom'
          text={getSaveButtonTooltipText(contentType)}
          data-testid='save-button-tooltip'
        />
      )}
    </div>
  )

const PublishButton: FunctionComponent<
  CTAButtonProps & {
    contentType: CONTENT_TYPE
    isContentLive: boolean
    onScheduleOptionClicked: () => void
  }
> = ({
  contentType,
  hasTooltip,
  isContentLive,
  isDisabled,
  isVisible,
  onClick,
  onScheduleOptionClicked,
}): JSX.Element => {
  const dropdownRef = useRef(null)
  const [shouldShowFlyout, setShouldShowFlyout] = useState(false)

  useClickOutside(dropdownRef, () => {
    setShouldShowFlyout(false)
  })

  const handleDropdownClick = useCallback(() => {
    !isDisabled &&
      setShouldShowFlyout((isFlyoutVisible: boolean) => !isFlyoutVisible)
  }, [isDisabled, setShouldShowFlyout])

  const renderFlyout = () => (
    <StyledDropdownContent>
      <StyledDropdownItem
        onClick={onScheduleOptionClicked}
        data-testid='schedule-option'
      >
        {SCHEDULE_LABEL}
      </StyledDropdownItem>
    </StyledDropdownContent>
  )

  return (
    isVisible && (
      <div>
        <StyledButtonWithIconWrapper ref={dropdownRef}>
          <ButtonWithIcon
            text={PUBLISH_LABEL}
            className={PUBLISH_BUTTON_CLASS_NAME}
            data-testid='publish-button'
            size='small'
            isPrimary
            iconPosition='right'
            onClick={onClick}
            disabled={isDisabled}
          />
          {!isContentLive && (
            <Icon
              iconName='DownCaret'
              data-testid='publish-dropdown'
              onClick={handleDropdownClick}
            />
          )}
          {shouldShowFlyout && renderFlyout()}
        </StyledButtonWithIconWrapper>
        {hasTooltip && (
          <Tooltip
            position='bottom'
            text={getPublishButtonTooltipText(contentType)}
          />
        )}
      </div>
    )
  )
}

export default ContentEditorCTA
