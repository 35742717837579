import { Icon, theme } from '@teamfabric/copilot-ui'
import { StyledSubnodeButton, StyledSubnodeButtonWrapper } from './styles'
import { ADD_SUBNODE_LABEL } from 'src/constants'
import ActionButton from '../ActionButton'
import { NODE_TYPE } from 'modules/browse-menu-tree-gql/types'
import React from 'react'
import { ThemeProps } from '../ActionButton/types'

const CreateNodeButton = ({
  nodeType,
  onClick,
  depth,
}: {
  nodeType: NODE_TYPE
  onClick: () => void
  depth: number
}): JSX.Element => {
  switch (nodeType) {
    case NODE_TYPE.SUBNODE:
      return (
        <StyledSubnodeButtonWrapper>
          <StyledSubnodeButton className='add-subnode' onClick={onClick}>
            <Icon
              iconName='Add'
              className='icon-add'
              size={14}
              fill={theme.palette.brand.primary.charcoal as ThemeProps}
            />
            {ADD_SUBNODE_LABEL}
          </StyledSubnodeButton>
        </StyledSubnodeButtonWrapper>
      )
    case NODE_TYPE.NODE:
    default:
      return (
        <ActionButton
          type='Add'
          depth={depth}
          handleOnClickActionButton={onClick}
        />
      )
  }
}

export default CreateNodeButton
