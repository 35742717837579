import { ALLOW_NESTED_COMPONENTS, DESCRIPTOR_DETAILS } from 'src/constants'
import { DescriptorInputProps, EventTargetProps } from './types'
import { Dropdown, Input, Textarea } from '@teamfabric/copilot-ui'
import React from 'react'
import { StyledInputField } from './styles'
import noop from 'lodash/noop'

const {
  HEADING: { FORMAT, TYPE, ID, LABEL, DESCRIPTION, ATTRIBUTES },
  INPUT_TYPES: { TEXT, DROPDOWN, TEXTAREA },
} = DESCRIPTOR_DETAILS

const DescriptorInput: React.FunctionComponent<DescriptorInputProps> = ({
  kind,
  className = '',
  innerClassName = '',
  testId = '',
  innerTestId = '',
  label,
  value,
  onChange = noop,
  isDisabled,
  error = false,
  errorMessage = '',
  descriptor = {},
  setDescriptor = noop,
  options = [],
}) => {
  const name = {
    [FORMAT]: 'format',
    [TYPE]: 'type',
    [ID]: 'id',
    [LABEL]: 'label',
    [DESCRIPTION]: 'description',
    [ATTRIBUTES]: 'attributes',
  }

  const renderTextInput = () => (
    <Input
      inputProps={{
        'value': value,
        'disabled': isDisabled,
        'data-testid': `descriptor-${name[label]}-field`,
        'name': name[label],
        'onChange': ({ target }) => onChange(target, descriptor, setDescriptor),
      }}
      isFloatedLabel
      label={label}
      error={error ?? false}
      errorMessage={errorMessage ?? ''}
    />
  )

  const renderDropdownInput = () => {
    return isDisabled ? (
      <Input
        inputProps={{
          'data-testid': testId,
          'value': value.name,
          'disabled': isDisabled,
        }}
        isFloatedLabel
        label={label}
      />
    ) : (
      <Dropdown
        titleLabel={label}
        data-testid={testId}
        className={className}
        options={options}
        onSelect={({ name: n }) => {
          const target = {
            name: ALLOW_NESTED_COMPONENTS,
            value: n === 'true',
          }
          onChange(target, descriptor, setDescriptor)
        }}
        value={value}
        width='100%'
        disabled={isDisabled}
      />
    )
  }

  const renderTextareaInput = () => (
    <Textarea
      label={label}
      className={className}
      data-testid={testId}
      disabled={isDisabled}
      textareaProps={{
        'value': value,
        'data-testid': innerTestId,
        'className': innerClassName,
        'name': name[label],
        'onChange': ({ target }: { target: EventTargetProps }) =>
          onChange(target, descriptor, setDescriptor),
      }}
    />
  )

  const descriptorInput = {
    [TEXT]: renderTextInput(),
    [DROPDOWN]: renderDropdownInput(),
    [TEXTAREA]: renderTextareaInput(),
  }

  return (
    <StyledInputField isDisabled={isDisabled}>
      {descriptorInput[kind]}
    </StyledInputField>
  )
}

export default DescriptorInput
