// https://www.30secondsofcode.org/js/s/pick-by/
const pickBy = (obj, fn) =>
  Object.keys(obj)
    .filter(k => fn(obj[k], k))
    .reduce((acc, key) => ((acc[key] = obj[key]), acc), {})

const filterBooleanObject = (obj = {}) => {
  try {
    if (typeof obj === 'object' && obj !== null) {
      return pickBy(obj, Boolean)
    }

    return {}
  } catch (err) {
    return {}
  }
}

export default filterBooleanObject
