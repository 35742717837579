export const filterName = {
  status: 'status',
  channels: 'channels',
  pageTypes: 'pageTypes',
  locales: 'locales',
}

export const defaultStatus = ['Live', 'Scheduled', 'Draft', 'Ended']

export const CHANNELS_FILTER_LABEL = 'Channels'
export const STATUS_FILTER_LABEL = 'Status'
export const PAGE_TYPE_FILTER_LABEL = 'Page type'
export const LOCALES_FILTER_LABEL = 'Locale'
