import { ApolloError, useLazyQuery, useMutation } from '@apollo/client'
import {
  BROWSE_MENU_TREE_MODAL_ID,
  BrowseMenuTreeModuleContext,
} from 'data/browse-menu-tree-gql/types'
import {
  CREATE_MENU_NODE,
  DELETE_MENU_NODE,
  LIST_MENUS,
  MENU_NODES_AS_LIST,
  MENU_VARIANT_AND_NODE_LIST,
  MENU_VARIANT_BY_ID,
  UPDATE_MENU_GRAPH,
} from 'services/graphql'
import {
  CreateMenuNodeResponse,
  DeleteMenuNodeResponse,
  HandleCreateProps,
  MenuNodesAsList,
  MenuNodesAsListResponse,
  MenuTreeNode,
  MenuVariantAndNodesListResponse,
  MenuVariantById,
  MenuVariantByIdResponse,
  NODE_TYPE,
  Names,
  UpdateMenuGraphResponse,
} from './types'
import {
  DEFAULT_LOCALE,
  REFETCH_MENU_LIST_WAIT_TIME,
  SUBNODE_ORDER_INCREMENT,
} from './constants'
import { EDITOR_RESET, EDITOR_SET_VERSION_STATUS } from 'modules/editor/actions'
import {
  EMPTY_STRING,
  MESSAGES,
  STATUS,
  TOAST_MESSAGE_TYPES,
} from 'src/constants'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { StyledContainer, StyledSpinner, StyledTreeContainer } from './styles'
import { getActiveLanguage, getLocales } from 'store/i18n/selectors'
import {
  getMenuTreeAfterDragAndDrop,
  getNodesToAppend,
  removeNodeFromTree,
  toggleNodeVisibility,
  toggleNodeVisibilityInGraph,
} from 'src/data/browse-menu-tree-gql/stateTransform'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { CHANGE_ACTIVE_LANGUAGE } from 'store/i18n/actions'
import ContentModalModule from 'components/ContentModalModule'
import Header from './components/Header'
import Level from './components/Level'
import { ListMenuResponse } from 'services/graphql/types'
import { MenuNode } from 'src/modules/browse-menu-tree-gql/types'
import { MenuVersionCreateGraphQLResponse } from 'src/graphql-proxy/transformations/menu-version/types'
import { Node } from './components/Node/types'
import Pipelines from './components/Pipelines'
import { constructInitialMenuTree } from './utils/constructInitialMenuTree'
import extract from 'lib/extract'
import { getCurrentMenuLocales } from 'data/browse-menu-tree-gql/utils'
import { getSvgPaths } from './utils/getSvgPaths'
import { handleMutation } from 'src/data/services'
import httpStatusCodes from 'http-status-codes'
import { isDropInvalid } from './utils/dragEnd'
import isEmpty from 'lodash/isEmpty'
import { menuNodesAsList as menuNodesAsListService } from 'src/data/browse-menu-tree-gql/service'
import rfdc from 'rfdc'
import { showToast } from 'components/ToastSnackbarContainer'
import size from 'lodash/size'
import { useBrowseMenuTreeModalOperations } from 'data/browse-menu-tree-gql/modal-operations'
import { useBrowseMenuTreeModalOptions } from 'data/browse-menu-tree-gql/modal-options'
import { useLocation } from '@reach/router'
import { useUserPermissions } from 'contexts/userPermissions'

const deepClone = rfdc()

const BrowseMenuTree = (): JSX.Element => {
  // Setters for useState hooks to be added as needed
  const [menuTree, setMenuTree] = useState<MenuTreeNode[][]>([])
  const [svgPaths, setSvgPaths] = useState([])
  const [activeNodes, setActiveNodes] = useState<MenuTreeNode[]>([])
  const [dragDisabled, setDragDisabled] = useState(false)
  const [draggingId] = useState('')
  const [refreshPipelines, setRefreshPipelines] = useState(false)
  const [serverSyncKey, setServerSyncKey] = useState<number>(null)
  const [showLoader, setShowLoader] = useState(false)
  const [menuVariant, setMenuVariant] = useState<MenuVariantById>(null)
  const [locales, setLocales] = useState<string[]>([])
  const [selectedLocale, setSelectedLocale] = useState(DEFAULT_LOCALE)
  const [visibleModalId, setVisibleModalId] = useState<string>(null)
  const activeLang = useSelector(getActiveLanguage)
  const applicationLocales = useSelector(getLocales)
  const userPermissions = useUserPermissions()
  const [menuNodes, setMenuNodes] = useState<MenuNodesAsList[]>(null)
  const dispatch = useDispatch()

  const { search } = useLocation()
  const { menuId, versionId } = extract.queryParams(search)

  const showModal = useCallback(
    (modalId: BROWSE_MENU_TREE_MODAL_ID) => setVisibleModalId(modalId),
    [setVisibleModalId]
  )

  const moduleContext: BrowseMenuTreeModuleContext = useMemo(
    () => ({
      menuId: menuId,
      versionId: versionId,
      closeModal: () => setVisibleModalId(null),
      updatedMenuVariant: updatedMenuVariant => {
        const { status, updatedAt, startDate } = updatedMenuVariant
        setMenuVariant({
          ...menuVariant,
          status,
          updatedAt,
          startDate,
        })
      },
    }),
    [menuId, setVisibleModalId, versionId, menuVariant]
  )

  const { modalOptions } = useBrowseMenuTreeModalOptions({
    moduleContext,
    operations: useBrowseMenuTreeModalOperations({ moduleContext }),
  })

  useEffect(() => {
    if (locales.length) {
      if (locales.includes(activeLang)) {
        setSelectedLocale(activeLang)
      } else {
        const firstLocale = locales[0]
        setSelectedLocale(firstLocale)
        dispatch((CHANGE_ACTIVE_LANGUAGE(firstLocale) as unknown) as AnyAction)
      }
    }
  }, [locales, activeLang, dispatch])

  useEffect(() => {
    setRefreshPipelines(true)
  }, [activeNodes])

  const handleErrorResponse = (error: ApolloError) => {
    if (
      error?.graphQLErrors?.[0]?.extensions?.errorCode ===
      httpStatusCodes.CONFLICT
    ) {
      setVisibleModalId(BROWSE_MENU_TREE_MODAL_ID.GRAPH_CONFLICT)
    }
  }

  useEffect(() => {
    const status = menuVariant?.status
    const hasOnlyViewerPermissions =
      userPermissions.hasViewerPermissions &&
      !userPermissions.hasEditorPermissions &&
      !userPermissions.hasPublisherPermissions

    const isEditorOnLiveOrScheduledVariant =
      !userPermissions.hasPublisherPermissions &&
      userPermissions.hasEditorPermissions &&
      (status === STATUS.SCHEDULED || status === STATUS.LIVE)

    const cannotDragNodes =
      hasOnlyViewerPermissions || isEditorOnLiveOrScheduledVariant
    setDragDisabled(cannotDragNodes)
  }, [menuVariant])

  const updateNodeInMenuTree = (newNode: MenuNode, depth: number) => {
    // Step 1: Find the updated node element in menuTree state
    // Step 2: Update node with new attributes confirmed by backend
    // Step 3: Update state

    setMenuTree(previousTree => {
      const updatedNode = previousTree[depth].find(
        node => node._id === newNode.id
      )

      const { attributes, images, url, name } = newNode
      updatedNode.images = images
      updatedNode.attributes = attributes
      updatedNode.url = url
      updatedNode.name = name

      // If this is a root node, we will need to update the root node names of all of its children
      if (depth === 0) {
        updatedNode.rootNodeName = name

        for (let i = 1; i < previousTree.length; ++i) {
          previousTree[i].forEach(node => {
            node.rootNodeName = name
          })
        }
      }

      return deepClone(previousTree)
    })
  }

  const handleOnClickCard = async ({
    node,
    depth,
    shouldShowChildren,
    shouldFetchNodes = true,
  }: {
    node: Node
    depth: number
    shouldShowChildren: boolean
    shouldFetchNodes?: boolean
  }) => {
    const { _id: nodeId, section: isSection, order: clickedNodeOrder } = node

    // Set active nodes, which renders the correct pipelines and blue bordered nodes
    const activeNodesClone = deepClone(activeNodes.slice(0, depth))
    activeNodesClone.push(node)
    setActiveNodes(activeNodesClone)

    isSection && setMenuTree(prev => prev.slice(0, depth + 1))

    if (shouldShowChildren) {
      /* If we don't need to fetch nodes, then the nodes are already available
      due to a previous query. For example, if a section was previously expanded,
     the subnodes have already been fetched */
      if (!shouldFetchNodes) {
        return
      }

      const { menuNodesAsList } = await menuNodesAsListService({
        menuNodesAsListQuery,
        parentId: menuId,
        variantId: versionId,
        nodeId,
      })

      const {
        data: {
          menuVariantById: { graph },
        },
      } = await menuVariantByIdQuery({
        variables: {
          parentId: menuId,
          variantId: versionId,
        },
      })

      const nodesToAppend = getNodesToAppend({
        menuNodesAsList,
        menuTree,
        nodeId,
        depth,
        graph,
      })

      //if expanding section for the first time
      if (isSection) {
        setMenuTree(prev => {
          const updatedLevel = deepClone(prev[depth])
          nodesToAppend.forEach(subnode => {
            const subnodeIndex = subnode.order
            subnode.order =
              clickedNodeOrder + (subnodeIndex + 1) * SUBNODE_ORDER_INCREMENT
          })
          updatedLevel.splice(clickedNodeOrder + 1, 0, ...nodesToAppend)
          prev[depth] = updatedLevel
          return [...prev]
        })
      } else {
        const nextLevel = depth + 1
        const sliceIndex = nextLevel + 1

        setMenuTree(previousTree => {
          previousTree[nextLevel] = nodesToAppend
          return previousTree.slice(0, sliceIndex)
        })
      }
      return
    }

    // We are collapsing a node that is currently active or we are collapsing a
    // section with an active subnode
    if (!isSection || activeNodes[depth]?.parent === nodeId) {
      setMenuTree(prevMenuTree => prevMenuTree.slice(0, depth + 1))

      //collapsing a section should keep the section card as the active card
      //collapsing a node should not keep the node card as the active card
      if (isSection) {
        setActiveNodes(prevActiveNodes => prevActiveNodes.slice(0, depth + 1))
      } else {
        setActiveNodes(prevActiveNodes => prevActiveNodes.slice(0, depth))
      }
    }
  }

  const [createNodeMutation] = useMutation<CreateMenuNodeResponse>(
    CREATE_MENU_NODE
  )
  const [deleteNodesMutation] = useMutation<DeleteMenuNodeResponse>(
    DELETE_MENU_NODE
  )

  const [updateMenuGraphMutation] = useMutation<UpdateMenuGraphResponse>(
    UPDATE_MENU_GRAPH
  )

  const [menuVariantAndNodeListQuery] = useLazyQuery<
    MenuVariantAndNodesListResponse
  >(MENU_VARIANT_AND_NODE_LIST)

  const [menuNodesAsListQuery] = useLazyQuery<MenuNodesAsListResponse>(
    MENU_NODES_AS_LIST
  )

  const [menuVariantByIdQuery] = useLazyQuery<MenuVariantByIdResponse>(
    MENU_VARIANT_BY_ID
  )

  const [listMenusQuery] = useLazyQuery<ListMenuResponse>(LIST_MENUS)

  useEffect(() => {
    void fetchAndConstructInitialMenuTree()
    void fetchAndSetLocales()

    return () => {
      const userDefaultLocale = applicationLocales?.find(
        localeElement => localeElement.isDefault
      )?.code

      dispatch(
        (CHANGE_ACTIVE_LANGUAGE(userDefaultLocale) as unknown) as AnyAction
      )
    }
  }, [])

  const setMenu = (
    menuVariantById: MenuVersionCreateGraphQLResponse['createMenuVariant']
  ) => {
    setServerSyncKey(menuVariantById?.version)
    setMenuTree(
      constructInitialMenuTree({
        menuNodesAsList: menuNodes,
        menuVariantById,
        versionId: menuVariantById.id,
      })
    )
    setMenuVariant(menuVariantById)
    setSvgPaths([])
  }

  const fetchAndSetLocales = async () => {
    const { data, refetch } = await listMenusQuery({
      variables: {
        input: {
          isArchived: false,
          filter: {
            searchTerm: '',
            channels: [],
            locales: [],
            status: [],
          },
        },
      },
    })
    const currentMenuLocales = getCurrentMenuLocales({ data, menuId })

    if (currentMenuLocales == null) {
      setShowLoader(true)
      setTimeout(() => {
        refetch()
          .then(({ data: refetchedData }) => {
            const refetchedCurrentMenuLocales = getCurrentMenuLocales({
              data: refetchedData,
              menuId,
            })
            refetchedCurrentMenuLocales &&
              setLocales(refetchedCurrentMenuLocales)
          })
          .catch(e => {
            console.error(e)
            void showToast({
              message: MESSAGES.ERROR_FETCHING_LOCALES,
              kind: TOAST_MESSAGE_TYPES.ALERT,
            })
          })
          .finally(() => {
            setShowLoader(false)
          })
      }, REFETCH_MENU_LIST_WAIT_TIME)
    } else {
      setLocales(currentMenuLocales)
    }
  }

  const fetchAndConstructInitialMenuTree = async () => {
    const {
      data: { menuNodesAsList, menuVariantById },
    } = await menuVariantAndNodeListQuery({
      variables: { variantId: versionId, parentId: menuId },
    })

    setMenuNodes(menuNodesAsList)
    setMenuVariant(menuVariantById)
    setServerSyncKey(menuVariantById.version)
    setMenuTree(
      constructInitialMenuTree({
        menuNodesAsList,
        menuVariantById,
        versionId,
      })
    )
    dispatch(EDITOR_SET_VERSION_STATUS(menuVariantById?.status))
  }

  useEffect(() => {
    if (menuTree.length && menuTree[0].length) {
      setSvgPaths(getSvgPaths(menuTree) as Record<string, unknown>[])
    }
    return () => setRefreshPipelines(false)
  }, [menuTree, refreshPipelines])

  const handleCreate = async ({
    depth,
    name,
    nodeParentId = versionId,
    nodeType,
    order,
  }: HandleCreateProps) => {
    const isSection = nodeType === NODE_TYPE.SECTION
    await handleMutation<CreateMenuNodeResponse>({
      mutation: createNodeMutation,
      mutationOptions: {
        variables: {
          input: {
            variantId: versionId,
            parentId: menuId,
            nodeParentId,
            section: isSection,
            locale: selectedLocale,
            name,
            version: serverSyncKey,
          },
        },
      },
      onSuccess: response => {
        const responseData = response?.data?.createNode
        const updatedGraph = responseData?.graph
        const addedNode = responseData?.updatedNode

        setServerSyncKey(responseData?.version)

        const isLocalizationEnabled = !isEmpty(locales)

        const nodeNames: Names = {}

        if (isLocalizationEnabled) {
          locales.forEach(
            localeCode =>
              (nodeNames[localeCode] =
                localeCode === selectedLocale ? name : EMPTY_STRING)
          )
        } else {
          nodeNames[DEFAULT_LOCALE] = name
        }

        const newNode: MenuTreeNode = {
          _id: addedNode.id,
          parent: nodeParentId,
          name: nodeNames,
          rootNodeName: depth === 0 ? nodeNames : activeNodes[0].name,
          section: isSection,
          url: addedNode.url,
          disabled: !updatedGraph[addedNode.id].isActive,
          order: order != null ? order : menuTree[depth].length,
          images: [],
          attributes: [],
        }

        setMenuTree(prev => {
          const updatedLevel = deepClone(prev[depth])
          updatedLevel.push(newNode)
          prev[depth] = updatedLevel
          return [...prev]
        })
      },
      onError: error => {
        handleErrorResponse(error)
      },
      snackbarProps: {
        successMessage: MESSAGES.getCreatedSuccess(name),
        errorMessage: MESSAGES.getCreatedError(name),
      },
    })
  }

  const handleRemoveItem = async (
    { _id: removalNodeId, name, section }: Node,
    depth: number
  ): Promise<void> => {
    const genericNodeLabel = section ? 'Section' : 'Node'

    await handleMutation<DeleteMenuNodeResponse>({
      mutation: deleteNodesMutation,
      mutationOptions: {
        variables: {
          input: {
            parentId: menuId,
            variantId: versionId,
            nodeId: removalNodeId,
          },
        },
      },
      onSuccess: response => {
        setServerSyncKey(response.data.deleteNodes.version)
        setMenuTree(
          removeNodeFromTree({
            menuTree,
            depth,
            removalNodeId,
            activeNodes,
            setActiveNodes,
          })
        )
      },
      onError: error => {
        handleErrorResponse(error)
      },
      snackbarProps: {
        successMessage: MESSAGES.getDeletedSuccess(
          name[selectedLocale] || genericNodeLabel
        ),
        errorMessage: MESSAGES.getDeletedError(
          name[selectedLocale] || genericNodeLabel
        ),
      },
    })
  }

  const handleNodeVisibility = async (
    { _id, section: isSection }: Node,
    isActive: boolean,
    depth: number
  ) => {
    //fetch current graph
    const {
      data: {
        menuVariantById: { graph, version },
      },
    } = await menuVariantByIdQuery({
      variables: {
        parentId: menuId,
        variantId: versionId,
      },
    })
    //generate updated graph and update graph BE
    const updatedGraph = toggleNodeVisibilityInGraph({
      graph,
      _id,
      toggleTo: !isActive,
    })
    await handleMutation({
      mutation: updateMenuGraphMutation,
      mutationOptions: {
        variables: {
          input: {
            parentId: menuId,
            variantId: versionId,
            graph: updatedGraph,
            version,
          },
        },
      },
      //update FE menuTree state
      onSuccess: response => {
        setServerSyncKey(response?.data?.updateMenuGraph?.version)
        const updatedMenuTree = toggleNodeVisibility({
          depth,
          menuTree,
          _id,
          toggleTo: isActive,
          isSection,
        })
        setMenuTree(updatedMenuTree)
        const toggleActiveNodeIndex = activeNodes.findIndex(
          activeNode => activeNode._id === _id
        )
        if (toggleActiveNodeIndex >= 0) {
          setActiveNodes(prevActiveNodes => {
            const activeNodesClone = deepClone(prevActiveNodes)
            activeNodesClone.forEach((activeNode, index) => {
              if (index >= toggleActiveNodeIndex) activeNode.disabled = isActive
            })
            return activeNodesClone
          })
        }
      },
      onError: error => {
        handleErrorResponse(error)
      },
    })
  }

  const onDragEnd = async ({
    source: { droppableId: srcDroppableId, index: srcIndex },
    destination,
  }: {
    destination: { droppableId: number; index: number }
    source: { droppableId: number; index: number }
  }) => {
    if (!destination) {
      return
    }
    const { droppableId: destDroppableId, index: destIndex } = destination
    if (
      isDropInvalid({
        destination,
        destDroppableId,
        srcDroppableId,
        destIndex,
        srcIndex,
      })
    )
      return

    const menuTreeBeforeUpdate = deepClone(menuTree)
    const { updatedMenuTree, newEdges } = getMenuTreeAfterDragAndDrop({
      menuTree,
      destDroppableId,
      srcIndex,
      destIndex,
    })

    setMenuTree(updatedMenuTree)

    //start loader and BE calls
    try {
      setShowLoader(true)
      //fetch current graph
      const {
        data: {
          menuVariantById: { graph, version },
        },
      } = await menuVariantByIdQuery({
        variables: {
          parentId: menuId,
          variantId: versionId,
        },
      })

      //updated graph and set new graph
      const updatedGraph = deepClone(graph)
      for (const [nodeId, updatedEdges] of Object.entries(newEdges)) {
        if (!updatedGraph[nodeId]) {
          setVisibleModalId(BROWSE_MENU_TREE_MODAL_ID.GRAPH_CONFLICT)
          setMenuTree(menuTreeBeforeUpdate)
          return
        }
        updatedGraph[nodeId].edges = updatedEdges
      }

      await handleMutation({
        mutation: updateMenuGraphMutation,
        mutationOptions: {
          variables: {
            input: {
              parentId: menuId,
              variantId: versionId,
              graph: updatedGraph,
              version,
            },
          },
        },
        onSuccess: response => {
          const responseData = response?.data?.updateMenuGraph
          setServerSyncKey(responseData?.version)
        },
      })
    } catch (err) {
      //if BE call fails, show error toast and revert FE render
      void showToast({
        message: extract.errorString(err) as string,
        kind: TOAST_MESSAGE_TYPES.ALERT,
      })
      setMenuTree(menuTreeBeforeUpdate)
    } finally {
      //stop loader
      setShowLoader(false)
    }
  }

  const rerenderPipelines = useCallback(() => {
    setRefreshPipelines(true)
  }, [])

  useEffect(() => {
    return () => {
      dispatch(EDITOR_RESET())
    }
  }, [dispatch])

  return (
    <>
      <ContentModalModule
        visibleModalId={visibleModalId}
        modalOptions={modalOptions}
      />
      <StyledContainer>
        {showLoader && <StyledSpinner />}
        <Header
          menuId={menuId}
          menuVersionId={versionId}
          locales={locales}
          menuVariant={menuVariant}
          selectedLocale={selectedLocale}
          showModal={showModal}
          setMenu={setMenu}
        />
        <StyledTreeContainer>
          {menuTree.map((level, depth) => (
            <Level
              key={`depth-${depth}`}
              level={level}
              depth={depth}
              handleCreate={handleCreate}
              handleOnClickCard={handleOnClickCard}
              handleRemoveItem={handleRemoveItem}
              activeNodes={activeNodes}
              handleNodeVisibility={handleNodeVisibility}
              dragDisabled={dragDisabled}
              draggingId={draggingId}
              onDragEnd={onDragEnd}
              onUpdateNode={updateNodeInMenuTree}
              refreshPipelines={rerenderPipelines}
              setMenuTree={setMenuTree}
              setActiveNodes={setActiveNodes}
              locales={locales}
              selectedLocale={selectedLocale}
            />
          ))}
          {Array.isArray(svgPaths) && !!size(svgPaths) && (
            <Pipelines svgPaths={svgPaths} activeNodes={activeNodes} />
          )}
        </StyledTreeContainer>
      </StyledContainer>
    </>
  )
}

export default BrowseMenuTree
