import React, { memo, useEffect, useRef } from 'react'
import {
  StyledBox,
  StyledBoxBody,
  StyledBoxHeader,
  StyledBoxLabel,
  StyledIconAdd,
  StyledInlineForm,
  StyledInput,
} from './styles'
import { MENU_HEADER } from 'src/constants'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import isFunction from 'lodash/isFunction'
import theme from 'styles/theme'

const { CATEGORY, DEPARTMENT } = MENU_HEADER

const DEBOUNCE_DURATION_MS = 1000

const CategoryCard = props => {
  const { children, level, parent, onSubmit, ...rest } = props

  const boxRef = useRef(null)
  const inputRef = useRef()

  useEffect(() => {
    if (boxRef.current && isFunction(boxRef.current.scrollIntoView)) {
      boxRef.current.scrollIntoView()
    }
  }, [])

  const onAdd = debounce(() => {
    if (!inputRef.current) {
      return
    }
    onSubmit({
      level,
      parent,
      label: inputRef.current.value,
      input: inputRef.current,
    })
  }, DEBOUNCE_DURATION_MS)

  return (
    <StyledBox ref={boxRef} {...rest}>
      <StyledBoxHeader selected={level !== 0}>
        <StyledBoxLabel data-testid='label'>
          {level === 0 ? DEPARTMENT : `${CATEGORY} ${level}`}
        </StyledBoxLabel>
        <StyledInlineForm
          data-parent={parent}
          data-level={level}
          onSubmit={onSubmit}
          autoComplete='off'
          data-testid='form'
        >
          <StyledIconAdd
            width='14px'
            height='14px'
            fill={theme.colors.gray[400]}
            data-testid='icon-add'
            onClick={onAdd}
          />
          <StyledInput
            ref={inputRef}
            type='text'
            name='name'
            placeholder='Add new'
            data-testid='input'
          />
        </StyledInlineForm>
      </StyledBoxHeader>
      <StyledBoxBody>{children}</StyledBoxBody>
    </StyledBox>
  )
}

CategoryCard.propTypes = {
  parent: PropTypes.any,
  level: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

export default memo(CategoryCard)
