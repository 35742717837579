import Button from 'components/Button'
import Dt from 'components/type-components/dt'
import Input from 'components/Input'
import styled from 'styled-components'

export const StyledModalHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 180px;
  justify-content: center;
  align-items: center;
  h5.modal-title {
    font-family: ${({ theme }) => theme.typography.fontFamily.sans};
    font-size: ${props => props.theme.typography.fontSize.sm};
    color: ${({ theme }) => theme.colors.black[500]};
    margin: 0;
    padding: 0;
  }
  .btn-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .cancel-btn {
      color: ${({ theme }) => theme.colors.gray[500]};
    }
    .submit-btn {
      color: ${({ theme }) => theme.colors.black[500]};
    }
  }
`

export const StyledImageUrlPreview = styled.div`
  display: grid;
  grid-template-columns: 1fr 248px;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: center;
  .image-preview {
    img {
      margin-top: 12px;
      height: 59px;
      width: 59px;
      object-fit: cover;
      border-radius: ${props => props.theme.borderRadius.sm};
    }
    label {
      margin: 0 0 6px 0;
    }
    svg {
      height: 30px;
      width: 30px;
      animation: spin 0.9s linear infinite;
    }
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`

export const StyledInput = styled(Input)`
  input[type='file'] {
    display: none;
  }
  input {
    width: auto;
  }
`
export const StyledCloseButton = styled.div`
  padding-right: 0;
  cursor: pointer;
`

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  & > button:first-child {
    margin-right: ${props => props.theme.space.space_5};
  }

  & > :last-child {
    width: 8em;
  }
`

export const StyledButton = styled(Button)`
  margin-top: 10px;
  width: 97px;
  height: 40px;
`

export const StyledButtonInput = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  overflow: hidden;
  border: 1px solid ${props => props.theme.colors.gray[300]};
  border-radius: ${props => props.theme.borderRadius.sm};
  height: 40px;

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.colors.blue[500]};
    border-bottom-left-radius: ${props => props.theme.borderRadius.sm};
    border-top-left-radius: ${props => props.theme.borderRadius.sm};
    color: ${props => props.theme.colors.white};
    padding-bottom: 2px; // needed to balance line height
    cursor: pointer;

    &:hover:not([disabled]) {
      background-color: ${({ theme, color }) =>
        color === 'secondary' ? theme.colors.white : theme.colors.blue[700]};
      color: ${({ theme, color }) =>
        color === 'secondary' ? theme.colors.black['500'] : theme.colors.white};
      border: 1px solid
        ${({ theme, color }) =>
          color === 'secondary' ? 'none' : theme.colors.blue[700]};
    }
  }
  input[type='file'] {
    display: none;
  }
  input {
    border: none;
  }

  .input-field {
    display: inherit;
    align-items: center;
    width: 300px;
    height: 30px;
    cursor: text;
  }
`

export const StyledDt = styled(Dt)`
  margin: 15px 0;
`
export const StyledSpinnerContainer = styled.div`
  height: 100px;
  width: 100px;
  border: 1px solid ${props => props.theme.colors.gray[100]};
  border-radius: ${props => props.theme.borderRadius.default};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledUploadChoiceContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 4px;
  display: flex;
  border-bottom: 1px solid ${props => props.theme.colors.gray[200]};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  .choice-button {
    cursor: pointer;
    color: ${props => props.theme.colors.gray.tabUnselectedGray};
    padding: 8px;
    border-bottom: 1px solid transparent;
    margin-right: 16px;

    &.active {
      border-color: ${props => props.theme.colors.blue[500]};
      color: black;
    }
  }
`
