import {
  StyledContainer,
  StyledErrorMessage,
  StyledHeading,
  StyledRow,
  StyledStatusCode,
} from './styles'
import { Button } from '@teamfabric/copilot-ui'
import { NEW_NAV_PAGES_PATH, PAGES_PATH } from 'contexts/navigationLinksContext'
import PropTypes from 'prop-types'
import React from 'react'
import { useNavigate } from '@reach/router'
import { useNewNavigation } from 'src/contexts/navigationLinksContext'

export default function ErrorPageTemplate(props) {
  const { statusCode, heading, paragraph } = props
  const navNameChange = useNewNavigation()
  const navigate = useNavigate()

  const handleOnClickHomeButton = () => {
    navNameChange ? navigate(NEW_NAV_PAGES_PATH) : navigate(PAGES_PATH)
  }
  return (
    <StyledContainer>
      <StyledRow>
        <StyledStatusCode>{statusCode}</StyledStatusCode>
        <StyledHeading>{heading ?? 'Something went wrong...'}</StyledHeading>
        {paragraph && <StyledErrorMessage>{paragraph}</StyledErrorMessage>}
        <Button
          size='small'
          isPrimary={true}
          text='Home'
          onClick={handleOnClickHomeButton}
        />
      </StyledRow>
    </StyledContainer>
  )
}

ErrorPageTemplate.propTypes = {
  statusCode: PropTypes.number.isRequired,
  heading: PropTypes.string.isRequired,
  paragraph: PropTypes.string,
}
