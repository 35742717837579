import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledSEOMetaDataLabel = styled.span`
  font-size: ${({ theme }) => theme.typography.fontSize.sm};
  margin: 8px 0;
  padding-bottom: 8px;
  color: ${themeUi.palette.brand.primary.gray};
`

const abSize = '50px'
export const StyledSEOMetaDataWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr ${abSize};
  align-items: center;
  grid-gap: ${({ theme }) => theme.space.space_5};
  margin-bottom: 1em;
  input.field-input {
    width: 100%;
  }
`
