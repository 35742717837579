import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledStatus = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  gap: ${({ theme }) => theme.space.space_3};

  .status-circle {
    border-radius: ${({ theme }) => theme.borderRadius.rounded};
    display: inline-block;
    height: ${({ theme }) => theme.space.space_3};
    width: ${({ theme }) => theme.space.space_3};

    &.is-active,
    &.is-live {
      background: ${themeUi.palette.ui.cta.green};
    }

    &.is-inactive,
    &.is-ended {
      background: ${themeUi.palette.ui.neutral.grey8};
    }

    &.is-scheduled {
      background: ${themeUi.palette.ui.cta.yellow};
    }

    &.is-draft {
      border: 2px solid ${themeUi.palette.ui.neutral.grey1};
    }
  }
`
