import { CANCEL_TEXT, SAVE_TEXT } from 'src/constants'
import React, { FunctionComponent } from 'react'
import { ApplyAttributeViewProps } from './types'
import { Button } from '@teamfabric/copilot-ui'
import Field from 'components/Field'
import { StyledAttributesSection } from './styles'

const ApplyAttributeView: FunctionComponent<ApplyAttributeViewProps> = props => {
  const { onApply, onCancel, attribute, applyCTADisabled, setAttribute } = props
  return (
    <StyledAttributesSection>
      <div className='attribute-buttons-wrapper'>
        <Button
          isPrimary
          onClick={onApply}
          text={SAVE_TEXT}
          data-testid='add-parameter-apply'
          disabled={applyCTADisabled}
        />
        <Button
          isPrimary={false}
          onClick={onCancel}
          data-testid='add-parameter-cancel'
          text={CANCEL_TEXT}
        />
      </div>
      <div className='attribute-field-section'>
        <div className='attribute-field'>
          <Field
            data-testid='attribute-parameter-input'
            placeholder='Parameter'
            value={attribute?.kind || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAttribute({
                ...attribute,
                kind: e.target.value,
              })
            }}
          />
        </div>
        <div className='attribute-field'>
          <Field
            data-testid='attribute-value-input'
            placeholder='Value'
            value={attribute?.value || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAttribute({
                ...attribute,
                value: e.target.value,
              })
            }}
          />
        </div>
      </div>
    </StyledAttributesSection>
  )
}

export default ApplyAttributeView
