import { theme as themeUi } from '@teamfabric/copilot-ui'

export const STYLED_MENU_ICON = {
  WIDTH: 20,
  HEIGHT: 20,
  BORDER_RADIUS: 20,
}
export const STYLED_FLYOUT = {
  PADDING_VERTICAL: 10,
  PADDING_HORIZONTAL: 15,
  HOVER_BG: themeUi.palette.ui.neutral.grey5,
}
export const FLYOUT_POSITION_OFFSET = 10
