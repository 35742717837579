import styled from 'styled-components'

// TODO: Move to components/ContentModalModule/ when ModalSections is no longer used.
// Type Interfaces
export interface StyledFormFieldProps {
  marginTop?: string
}

export const StyledFormField = styled.div<StyledFormFieldProps>`
  margin-top: ${props => props.marginTop ?? '1.5rem'};
`
