import styled from 'styled-components'

export const StyleContainer = styled.div`
  display: inline-flex;
  background: ${({ theme }) => theme.colors.black[500]};
  border-radius: 2px;
  user-select: none;
  position: absolute;
  right: 0;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  input:checked ~ div {
    background: ${({ theme }) => theme.colors.gray[500]};
    overflow: hidden;
  }

  div {
    padding: 9px 9px;
    cursor: pointer;
    align-items: center;

    svg {
      height: 10px;
      width: 10px;
    }
  }
  div:hover {
    background: ${({ theme }) => theme.colors.black[200]};
    overflow: hidden;
  }
`
