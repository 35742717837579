import {
  ALT_TEXT_LABEL_WIDTH_PX,
  INPUT_AND_LABEL_WIDTH_PX,
  INPUT_WIDTH_PX,
  LOCALE_DROPDOWN_LABEL_WIDTH_PX,
} from './constants'
import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledManageNodeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${themeUi.palette.ui.neutral.grey3};

  .manage-node-cta-buttons {
    button {
      display: inline;
      margin-right: 10px;
    }
  }

  padding-bottom: 25px;
`

export const StyledNodeInfoHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 17px;
  font-size: ${({ theme }) => theme.typography.fontSize.sm};

  color: ${themeUi.palette.brand.primary.gray};

  .node-name {
    letter-spacing: 0.9px;
  }
`

export const StyledNodeInfoSectionWrapper = styled.div`
  .menu-node-input {
    padding-top: 35px;
  }
`

export const StyledAddAttributeSection = styled.div`
  .manage-node-link-cta {
    ${themeUi.typography.link}
    color: ${themeUi.palette.ui.states.active};
    padding-bottom: 10px;

    &:hover {
      color: ${themeUi.palette.ui.states.hover};
      cursor: pointer;
    }
  }
`

export const StyledAttributesSection = styled.div`
  .attribute-field-section {
    display: flex;
    justify-content: space-between;

    .attribute-field {
      flex-basis: 48%;
    }
  }

  .attribute-buttons-wrapper {
    display: flex;
    padding-bottom: 15px;

    button {
      height: 32px;

      &:first-child {
        margin-right: 1rem;
      }
    }
  }
`

export const StyledParameterList = styled.div`
  padding: ${({ theme }) => theme.space.space_4} 0;
  .query-param-each {
    border-bottom: 1px solid ${themeUi.palette.brand.primary.gray};
    display: flex;
    font-size: ${({ theme }) => theme.typography.fontSize.sm};
    justify-content: space-between;
    padding-bottom: ${({ theme }) => theme.space.space_3};
  }
`

export const StyledAssetSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .asset-grid-images {
    max-width: 70%;
    div {
      display: inline-block;
    }
  }
  .add-asset {
    cursor: pointer;
  }
`

export const StyledAssetDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
`

export const StyledAssetDetailsCloseIcon = styled.div`
  padding: 0.375rem 0.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
  height: 100%;
`

export const StyledAssetDetailsTile = styled.div<{ hasLocales: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0.1875rem;
  border-bottom: 1px solid ${themeUi.palette.ui.neutral.grey4};

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 36px;
  }

  & ${StyledAssetDetailsCloseIcon} {
    visibility: hidden;
  }

  &:hover {
    background: ${themeUi.palette.ui.neutral.grey5};

    & ${StyledAssetDetailsCloseIcon} {
      visibility: visible;
    }
  }

  /* Overriding copilot CSS rules due to incompatibility between figma designs */

  .chips-label,
  .search-container {
    display: none;
  }

  .floating-dropdown-container {
    margin-bottom: 0;
    height: ${({ hasLocales }: { hasLocales: boolean }) =>
      hasLocales ? '0px' : '16px'};
    cursor: pointer;
    background: ${themeUi.palette.brand.primary.white};

    ~ div {
      max-height: 160px;
    }
  }

  .dropdown-arrow {
    padding-right: 0;
    right: 0;
    height: 16px;
  }

  .values-container {
    padding-bottom: 0;
    height: 16px;
    background: ${themeUi.palette.brand.primary.white};
  }

  .values-inner-container {
    height: 16px;
    white-space: nowrap;
  }
`

export const StyledAssetDetailsExpandIcon = styled.div`
  padding: 0 0.5rem;
  cursor: pointer;
`

export const StyledAssetDetailsLocaleDropdownLabel = styled.span`
  margin-right: 2rem;
  margin-left: 0.25rem;
  width: ${LOCALE_DROPDOWN_LABEL_WIDTH_PX};

  ${themeUi.typography.h6.css};
  color: ${themeUi.palette.brand.primary.gray};
`

export const StyledAssetDetailsAltTextListItem = styled.div`
  padding-left: 4rem;

  /* Overriding styles for the StyledFieldInputComponent in copilot */
  > div {
    background: inherit;
    max-width: ${INPUT_AND_LABEL_WIDTH_PX};
    box-shadow: none;

    /* The label for the copilot Input component */
    > label {
      width: ${ALT_TEXT_LABEL_WIDTH_PX};
      max-width: ${ALT_TEXT_LABEL_WIDTH_PX};
      white-space: nowrap;
      text-overflow: hidden;
    }

    /* Overriding styles for the StyledFieldInputContainer in copilot */
    > .floated-label {
      background: ${themeUi.palette.brand.primary.white};
      width: ${INPUT_WIDTH_PX};
      max-width: ${INPUT_WIDTH_PX};

      /* The div wrapping the input element for the copilot Input component */
      > div {
        padding-bottom: 0;

        /* The input element for the copilot Input component */
        > input {
          min-height: 15px;
          width: ${INPUT_WIDTH_PX};
        }
      }
    }
  }

  /* The locale label that is displayed next to the input field */
  > span {
    font-style: italic;
    font-weight: 500;
    line-height: 14px;
    font-size: 13px;
    color: ${themeUi.palette.brand.primary.gray};
    padding-left: 0.25rem;
  }
`
