import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: 'center';
  color: ${themeUi.palette.brand.primary.gray};
  gap: 3px;
  ${themeUi.typography.h6};
`
