import React, { Component } from 'react'
import { SortEndProps, SortableComponentProps } from './types'
import AttributeList from 'components/AttributeList'
import { DELAY_TO_DRAG } from 'modules/editor/constants'
import SortableList from './SortableList'
import arrayMove from 'array-move'
import findIndex from 'lodash/findIndex'

class SortableComponent extends Component<SortableComponentProps> {
  onSortEnd = ({ oldIndex, newIndex }: SortEndProps): void => {
    const nextCuratedComponents = [...this.props.curatedComponents]
    arrayMove.mutate(nextCuratedComponents, oldIndex, newIndex)
    this.props.handleOnReorderComponent({
      curatedComponents: nextCuratedComponents,
    })
  }

  render(): React.ReactNode {
    if (!Array.isArray(this.props.curatedComponents)) return null
    if (!this.props.curatedComponents.length) return null
    if (this.props.selectedComponent) {
      const key: string = this.props.selectedComponent.key
      const componentIdx: number = findIndex(this.props.curatedComponents, {
        key,
      })

      return (
        <AttributeList
          selectedComponent={this.props.selectedComponent}
          descriptor={this.props.descriptor}
          descriptors={this.props.descriptors}
          paths={this.props.paths?.concat(componentIdx)}
          value={this.props.value?.[componentIdx]}
          onChange={payload => {
            this.props.onChange(payload)
          }}
        />
      )
    }
    return (
      <SortableList
        lockAxis='y'
        helperClass='dragging-helper-class'
        pressDelay={DELAY_TO_DRAG as number}
        isGlobalComponentEditor={this.props.isGlobalComponentEditor || false}
        onSortEnd={this.onSortEnd}
        curatedComponents={this.props.curatedComponents}
        handleOnUpdateComponent={this.props.handleOnUpdateComponent}
      />
    )
  }
}

export default SortableComponent
