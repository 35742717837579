import React, { memo, useCallback, useMemo } from 'react'
import { StyledLabel, StyledWrapper } from 'components/type-components/styles'
import PropTypes from 'prop-types'
import { StyledSelect } from './styles'
import { VALUE_PROP_DEFAULTS } from '../constants'
import isEqual from 'react-fast-compare'

const EnumType = props => {
  const { descriptor, paths, value, onChange, hasEditAccess } = props

  const handleOnChange = useCallback(
    e => {
      onChange({
        paths,
        value: e.target.value,
      })
    },
    [paths, onChange]
  )

  const name = useMemo(() => paths.join('.'), [paths])

  if (!Object.keys(descriptor).length) return null

  return (
    <StyledWrapper data-testid='enum-type-wrapper'>
      <StyledLabel>{descriptor.label}</StyledLabel>
      <StyledSelect
        id={name}
        name={name}
        value={value ?? ''}
        onChange={handleOnChange}
        defaultValue={descriptor?.default ?? ''}
        data-testid='enum-type-select'
        disabled={!hasEditAccess}
      >
        {descriptor.children.map(choice => (
          <option key={choice} value={choice}>
            {choice}
          </option>
        ))}
      </StyledSelect>
    </StyledWrapper>
  )
}

EnumType.defaultProps = {
  descriptor: {},
  value: VALUE_PROP_DEFAULTS.Enum,
}

EnumType.propTypes = {
  descriptor: PropTypes.object.isRequired,
  paths: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  hasEditAccess: PropTypes.bool.isRequired,
}

export default memo(EnumType, isEqual)
