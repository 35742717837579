import { applyMiddleware, compose, createStore } from 'redux'
import combinedReducers from './reducer'
import isDOMavailable from 'lib/isDOMavailable'
import thunk from 'redux-thunk'

const composeEnhancers =
  (isDOMavailable && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
const middleware = [thunk]

const store = createStore(
  combinedReducers,
  composeEnhancers(applyMiddleware(...middleware))
)

export default store
