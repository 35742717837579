import { FormikConfig, FormikHelpers, FormikProps } from 'formik'
import { FormComponentListProps } from './utils'

export enum ModalType {
  Alert = 'Alert Modal',
  Form = 'Form Modal',
}

interface ActionButtonConfig {
  text: string
  onClick: (e: Event) => void
}

interface AlertModalConfig {
  content: string
  confirmButton: Omit<ActionButtonConfig, 'onClick'> & {
    onClick: (e: Event) => Promise<unknown> | void
  }
  cancelButton: ActionButtonConfig
}

// TODO: Create explicit Values type that can be used for initialValues,
// getComponents, onSubmit, etc.
export interface FormProps {
  name: string
  initialValues: { [key: string]: any }
  getComponents: (
    values: { [key: string]: any },
    formikProps?: FormikProps<{ [key: string]: any }>
  ) => FormComponentListProps
  validationSchema?: FormikConfig<Record<string, any>>['validationSchema']
}

type AlertModalProps = {
  modalType: ModalType.Alert
  config: AlertModalConfig
  mode?: never
  forms?: never
  onClose?: never
  onSubmit?: never
}

// TODO: Make 'mode' an enum once we transition to only using ContentModalModule
export type FormModalProps = {
  modalType: ModalType.Form
  config?: never
  mode: string
  forms: FormProps[]
  onClose?: () => void
  onSubmit?: (
    values: { [key: string]: any },
    actions: FormikHelpers<{ [key: string]: any }>
  ) => Promise<void> | void
}

type ModalContent = AlertModalProps | FormModalProps

export type ContentModalOptionsProps = ModalContent & {
  title: string
  size: 'small' | 'medium' | 'large'
}

export type ContentModalInfoProps = {
  visibleModalId: string
  modalOptions: { [key: string]: ContentModalOptionsProps }
}
