import { createActionPrefix } from 'redux-nano'

const createAction = createActionPrefix('TOAST_MESSAGES')

export const SHOW_TOAST_MESSAGE = createAction('SHOW_TOAST_MESSAGE')
export const DISMISS_TOAST_MESSAGE = createAction('DISMISS_TOAST_MESSAGE')
export const FADE_OUT_TOAST_MESSAGE = createAction('FADE_OUT_TOAST_MESSAGE')

export const ACTIONS = {
  SHOW_TOAST_MESSAGE,
  DISMISS_TOAST_MESSAGE,
  FADE_OUT_TOAST_MESSAGE,
}
