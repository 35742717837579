import API from 'src/business-layer-client'

export const saveComponents = async (
  pageId,
  versionId,
  payload,
  shouldUseGraphQL,
  updatePageVersionComponents
) => {
  let response
  if (shouldUseGraphQL) {
    const input = {
      parentContentId: pageId,
      contentVariantId: versionId,
      components: payload.components,
    }
    response = await updatePageVersionComponents({
      variables: {
        input,
      },
    })
  } else {
    response = await API.xpm.put(
      `page/${pageId}/version/${versionId}/components`,
      payload
    )
  }

  return response
}
