import isPlainObject from 'lodash/isPlainObject'

const PRIMITIVE_VALUE = -1

// function to sort out primitive values in given array
const sortPrimitiveValues = obj => {
  if (!isPlainObject(obj)) return []

  const primitiveTypes = ['string', 'number', 'boolean', 'undefined', 'bigint']

  return Object.keys(obj).sort((a, b) => {
    const aIsNull = obj[a] === null
    const aIsPrimitive = primitiveTypes.includes(typeof obj[a])

    const bIsArray = Array.isArray(obj[b])
    const bIsPrimitive = primitiveTypes.includes(typeof obj[b])

    if ((aIsPrimitive && bIsArray) || (aIsNull && bIsArray)) {
      return PRIMITIVE_VALUE
    } else if (Array.isArray(obj[a]) && bIsArray) {
      return 0
    } else if (aIsPrimitive && bIsPrimitive) {
      return 0
    } else {
      return 1
    }
  })
}

export default sortPrimitiveValues
