const statusMapping = {
  DRAFT: 'Draft',
  SCHEDULED: 'Scheduled',
  ARCHIVED: 'Archived',
  INACTIVE: 'Inactive',
  ACTIVE: 'Active',
  LIVE: 'Live',
  ENDED: 'Ended',
  REVIEW: 'To Review',
  PUBLISH: 'Approved',
  DEFAULT: 'In Progress',
}

export default statusMapping
