import React, { cloneElement, isValidElement } from 'react'
import { StyledContainer, StyledGrid, StyledSidebar } from './styles'

interface AppLayoutProps {
  bgColor?: string
  ds4?: boolean
  children: React.ReactNode
}

const AppLayout = (props: AppLayoutProps): JSX.Element => {
  const { children } = props
  return (
    <StyledGrid ds4={props?.ds4} data-testid='app-layout'>
      {React.Children.map(children, child => {
        if (isValidElement(child)) {
          return cloneElement(child)
        }
        return null
      })}
    </StyledGrid>
  )
}

AppLayout.Container = (props: AppLayoutProps) => {
  const { children, bgColor } = props
  return (
    <StyledContainer data-testid='app-layout-container' bgColor={bgColor}>
      {children}
    </StyledContainer>
  )
}

AppLayout.Sidebar = (props: AppLayoutProps) => {
  const { children, bgColor } = props
  return (
    <StyledSidebar data-testid='app-layout-sidebar' bgColor={bgColor}>
      {children}
    </StyledSidebar>
  )
}

export default AppLayout
