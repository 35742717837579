export const isKeyInCookieString = keyName => {
  return document.cookie.indexOf(keyName) >= 0
}

export const getCookieValue = cookieName => {
  let pair, name, value
  const cookies = document.cookie.split(';')
  for (const cookie of cookies) {
    pair = cookie.trim().split('=')
    name = pair[0]
    value = pair[1]
    if (name === cookieName) {
      return value
    }
  }
  return null
}

export const addNewCookie = (cookieName, cookieValue) => {
  const newCookie = `${cookieName}=${cookieValue}`
  document.cookie = newCookie
  return newCookie
}

export const getAccountId = () => {
  // Legacy where both accountId and account are set. They represent the same value
  const accountId = window.sessionStorage.getItem('accountId')
  const account = window.sessionStorage.getItem('account')

  return account ?? accountId ?? 'local'
}
