import {
  CANCEL_TEXT,
  DELETE_GE_MODAL,
  GLOBAL_ELEMENT,
  VERSION_ARCHIVE_MODAL,
  VERSION_DELETE_MODAL,
  VERSION_UNARCHIVE_MODAL,
} from 'src/constants'
import {
  ContentModalOptionsProps,
  ModalType,
} from 'components/ContentModalModule/types'
import { GEModuleContext, GE_MODAL_ID } from './types'
import {
  generateAddNewGEModal,
  generateAddNewGEVersionModal,
  generateCopyModal,
} from './modals'
import getMultiSelectTextChipsOptions, {
  OptionsType,
} from 'lib/getMultiSelectTextChipsOptions'
import { useCallback, useMemo } from 'react'
import { GEModalOperations } from './modal-operations'
import { ModalMode } from 'data/types'

type GEModalOptions = Record<string, ContentModalOptionsProps>

interface GEModalOptionsProps {
  context: GEModuleContext
  operations: GEModalOperations
}

export const useGEModalOptions = ({
  operations,
  context,
}: GEModalOptionsProps): { modalOptions: GEModalOptions } => {
  const modalContext = useMemo(() => {
    const { featuresList } = context

    const channelsOptionList = featuresList.multiChannel.enabled
      ? getMultiSelectTextChipsOptions(
          featuresList.multiChannel.channels,
          OptionsType.CHANNELS
        )
      : null
    const localesOptionList = featuresList.i18n.enabled
      ? getMultiSelectTextChipsOptions(
          featuresList.i18n.locales,
          OptionsType.LOCALES
        )
      : null

    return {
      localesChipsOptions: localesOptionList,
      channelsChipsOptions: channelsOptionList,
    }
  }, [context])

  const getGEVersionTableEntryModalOptions: () => GEModalOptions = useCallback(() => {
    const { tableModal, closeTableModal: onModalClose } = context
    const { globalElement, targetItem } = tableModal
    if (!globalElement) {
      return {}
    }
    const version = globalElement?.variants?.find(
      v => v.id === targetItem.contentId
    )
    if (!version) {
      return {}
    }
    const isLastVersion = globalElement.variants.length === 1

    return {
      [GE_MODAL_ID.MAKE_A_COPY_VERSION]: generateAddNewGEVersionModal({
        initialValues: {
          name: globalElement.name,
          versionName: `${version.versionName} copy`,
        },
        mode: ModalMode.COPY,
        onSubmit: values => {
          return operations.copyGlobalElementVariant({
            variables: {
              versionName: values.versionName,
              originalVariantId: targetItem.contentId,
              globalElementId: targetItem.parentId,
              description: values.description,
            },
          })
        },
        onModalClose,
        context: modalContext,
      }),
      [GE_MODAL_ID.DELETE]: {
        title: `${VERSION_DELETE_MODAL.title} "${version.versionName ?? ''}"`,
        size: 'small',
        modalType: ModalType.Alert,
        config: {
          content: isLastVersion
            ? VERSION_DELETE_MODAL.getLastVersionContent(GLOBAL_ELEMENT)
            : VERSION_DELETE_MODAL.getDefaultContent(GLOBAL_ELEMENT),
          cancelButton: {
            text: CANCEL_TEXT,
            onClick: onModalClose,
          },
          confirmButton: {
            text: VERSION_DELETE_MODAL.submitText,
            onClick: () => {
              return operations.deleteGlobalElementVariant({
                variables: {
                  globalElementId: targetItem.parentId,
                  variantId: targetItem.contentId,
                  versionName: version.versionName,
                },
                context: {
                  isLastVariant: isLastVersion,
                },
              })
            },
          },
        },
      },
      [GE_MODAL_ID.ARCHIVE]: {
        title: VERSION_ARCHIVE_MODAL.getTitle(GLOBAL_ELEMENT),
        size: 'small',
        modalType: ModalType.Alert,
        config: {
          content: VERSION_ARCHIVE_MODAL.getContent(GLOBAL_ELEMENT),
          cancelButton: {
            text: CANCEL_TEXT,
            onClick: onModalClose,
          },
          confirmButton: {
            text: VERSION_ARCHIVE_MODAL.submitText,
            onClick: () => {
              return operations.archiveUnarchiveGlobalElementVariant({
                variables: {
                  globalElementId: targetItem.parentId,
                  variantId: targetItem.contentId,
                },
                context: {
                  isArchived: false,
                  isLastVariant: isLastVersion,
                },
              })
            },
          },
        },
      },
      [GE_MODAL_ID.UNARCHIVE]: {
        title: VERSION_UNARCHIVE_MODAL.getTitle(GLOBAL_ELEMENT),
        size: 'small',
        modalType: ModalType.Alert,
        config: {
          content: VERSION_UNARCHIVE_MODAL.content,
          cancelButton: {
            text: CANCEL_TEXT,
            onClick: onModalClose,
          },
          confirmButton: {
            text: VERSION_UNARCHIVE_MODAL.submitText,
            onClick: () => {
              return operations.archiveUnarchiveGlobalElementVariant({
                variables: {
                  globalElementId: targetItem.parentId,
                  variantId: targetItem.contentId,
                },
                context: {
                  isArchived: true,
                  isLastVariant: isLastVersion,
                },
              })
            },
          },
        },
      },
    }
  }, [context, operations, modalContext])

  const getGEPageTableEntryModalOptions: () => GEModalOptions = useCallback(() => {
    const { tableModal, closeTableModal: onModalClose } = context
    const { globalElement, targetItem } = tableModal
    if (!globalElement) {
      return {}
    }
    return {
      [GE_MODAL_ID.DELETE_PARENT_GE]: {
        title: DELETE_GE_MODAL.title,
        size: 'small',
        modalType: ModalType.Alert,
        config: {
          content: DELETE_GE_MODAL.content,
          cancelButton: {
            text: CANCEL_TEXT,
            onClick: onModalClose,
          },
          confirmButton: {
            text: VERSION_DELETE_MODAL.submitText,
            onClick: () => {
              return operations.deleteGlobalElement({
                variables: {
                  globalElementId: targetItem.parentId,
                  globalElementName: globalElement.name,
                },
              })
            },
          },
        },
      },
      [GE_MODAL_ID.CREATE_NEW_VERSION]: generateAddNewGEVersionModal({
        initialValues: {
          name: globalElement.name,
          versionName: '',
        },
        mode: ModalMode.CREATE,
        onSubmit: values => {
          return operations.createGlobalElementVariant({
            variables: {
              versionName: values.versionName,
              globalElementId: targetItem.parentId,
              description: values.description,
              channels: modalContext?.channelsChipsOptions,
              locales: modalContext?.localesChipsOptions,
            },
          })
        },
        onModalClose,
        context: modalContext,
      }),
      [GE_MODAL_ID.MAKE_A_COPY]: generateCopyModal({
        initialValues: {
          name: globalElement.name,
          newGeName: '',
          geVersions: globalElement?.variants
            ?.map(variant => variant?.versionName)
            ?.join(', '),
        },
        mode: ModalMode.COPY,
        onSubmit: values => {
          return operations.copyGlobalElement({
            variables: {
              originalId: targetItem.parentId,
              newGeName: values.newGeName,
              geVersions: values.geVersions,
            },
          })
        },
        onModalClose,
        context: modalContext,
      }),
    }
  }, [context, operations, modalContext])

  const getCTAModals: () => GEModalOptions = useCallback(() => {
    const { closeTableModal: onModalClose } = context
    return {
      [GE_MODAL_ID.ADD_GE]: generateAddNewGEModal({
        onSubmit: values => {
          return operations.createGlobalElement({ variables: values })
        },
        onModalClose,
        context: modalContext,
      }),
    }
  }, [context, operations, modalContext])

  const modalOptions: GEModalOptions = useMemo(() => {
    return {
      ...getCTAModals(),
      ...getGEVersionTableEntryModalOptions(),
      ...getGEPageTableEntryModalOptions(),
    }
  }, [
    getCTAModals,
    getGEVersionTableEntryModalOptions,
    getGEPageTableEntryModalOptions,
  ])

  return { modalOptions }
}
