import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledComponentButton = styled.button`
  display: flex;
  align-items: center;
  padding: 1rem;
  position: relative;
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.3;
      pointer-events: none;
  `}

  & + .custom-tooltip {
    left: 80%;
    bottom: 20%;
  }
`

export const StyledButton = styled.div`
  ${props => props.theme.typography.label};
  color: ${themeUi.palette.brand.primary.charcoal};
  font-size: ${props => props.theme.typography.fontSize.sm};
  font-weight: ${props => props.theme.typography.fontWeight.normal};
  font-family: ${themeUi.typography.link};
  line-height: 16px;
  letter-spacing: 0.2px;
  padding-left: 0.5rem;
`
