import DescriptorContextMenu from './descriptor-context-menu'
import { DescriptorProps } from 'store/types'
import QuickLink from 'components/QuickLink'
import React from 'react'
import { SETTINGS } from 'src/constants'
import { SETTINGS_MENU } from './types'
import { UserPermissions } from 'contexts/userPermissions'

export const getColumns = ({
  onClickItem,
  userPermissions,
}: {
  onClickItem: (desc: DescriptorProps, mode: SETTINGS_MENU) => void
  userPermissions: UserPermissions
}): Record<string, unknown>[] => {
  const { hasPublisherPermissions } = userPermissions

  return [
    {
      accessor: SETTINGS.COLUMNS.NAME.ACCESSOR,
      title: SETTINGS.COLUMNS.NAME.TITLE,
      isSortable: true,
    },
    {
      isSortable: false,
      render: (descriptor: DescriptorProps) => {
        return (
          <div className='context-menu-column'>
            {hasPublisherPermissions ? (
              <DescriptorContextMenu
                descriptor={descriptor}
                onClickItem={onClickItem}
              />
            ) : (
              <QuickLink
                isChild
                title={SETTINGS.CONTEXT.VIEW}
                handler={() => onClickItem(descriptor, SETTINGS_MENU.VIEW)}
              />
            )}
          </div>
        )
      },
    },
  ]
}
