import get from 'lodash/get'
import isNil from 'lodash/isNil'
import { nanoid } from 'nanoid'

/* Backend saves only the params information. For everything else, we rely on
 * descriptos file. Which is where enrichCuratedComponents is usefull. If a
 * component id is not found in descriptors, it means the component no longer
 * exists in the fronend app, and must be deleted.
 */
const enrichSavedComponents = ({ descriptors = [], savedComponents = [] }) => {
  return savedComponents
    .map((item, idx) => {
      const match = descriptors.find(desc => desc.id === item.id)
      if (match) {
        item.params = combineDescriptorAndSavedData(match, item)
        item.key = item?.key ?? nanoid()
        item.order = item?.order ?? idx + 1
        return {
          ...item,
          label: match.label,
        }
      } else {
        return null
      }
    })
    .filter(Boolean)
}

const combineDescriptorAndSavedData = (descriptor, savedComponent) => {
  const descriptorAttributes = descriptor.attributes
  let savedAttributes = savedComponent.params
  const itemsInDescriptor = {}
  Object.keys(descriptorAttributes).forEach(key => {
    itemsInDescriptor[key] = descriptorAttributes[key].default
  })
  savedAttributes = { ...itemsInDescriptor, ...savedAttributes }
  const keys = Object.keys(savedAttributes)
  for (const key of keys) {
    if (Array.isArray(savedAttributes[key])) {
      savedAttributes[key] = recursivelyCombine(
        descriptorAttributes[key],
        savedAttributes[key]
      )
    }
  }
  return savedAttributes
}

const recursivelyCombine = (subDescriptor, subSavedAttr) => {
  subDescriptor = get(subDescriptor, 'children.children', {})
  const itemsInDescriptor = {}
  Object.keys(subDescriptor).forEach(key => {
    itemsInDescriptor[key] = subDescriptor[key].default
  })
  return subSavedAttr.map(item => {
    return processItem(item, JSON.stringify(itemsInDescriptor), subDescriptor)
  })
}

const processItem = (item, itemsInDescriptor, subDescriptor) => {
  const updatedItem = JSON.parse(itemsInDescriptor)
  if (isNil(item)) {
    return updatedItem
  }
  const keysInItem = Object.keys(item)
  for (const key of keysInItem) {
    if (!Array.isArray(item[key])) {
      updatedItem[key] = item[key]
    } else {
      updatedItem[key] = recursivelyCombine(subDescriptor[key], item[key])
    }
  }
  return updatedItem
}

export default enrichSavedComponents
