import React, { Component, Fragment } from 'react'
import {
  StyledCaption,
  StyledError,
  StyledInput,
  StyledInputContainer,
  StyledInputWrapper,
} from './styles'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { findDOMNode } from 'react-dom'
import isFunction from 'lodash/isFunction'

export default class Input extends Component {
  static propTypes = {
    '*': PropTypes.any, // react attributes
    'disabled': PropTypes.bool,
    'childrenBeforeInput': PropTypes.any, // react elements to put before the input`
    'childrenAfterInput': PropTypes.any,
    'label': PropTypes.string,
    'caption': PropTypes.string,
    'inputProps': PropTypes.shape({
      '*': PropTypes.any, // react attributes
      'defaultValue': PropTypes.string,
      'onBlur': PropTypes.func,
      'onFocus': PropTypes.func,
      'onChange': PropTypes.func,
      'type': PropTypes.string,
      'value': PropTypes.any,
      'placeholder': PropTypes.string,
    }),
    'className': PropTypes.string,
    'errorProps': PropTypes.shape({
      hasError: PropTypes.bool,
      errorMessage: PropTypes.string,
    }),
  }

  static defaultProps = {
    inputProps: {
      type: 'text',
    },
  }

  state = {
    value: '',
    isControlled: typeof this.props.inputProps.value === 'string',
    isFocused: false,
  }

  UNSAFE_componentWillMount() {
    if (this.state.isControlled === true) {
      return this.setInputValue(this.props.inputProps.value)
    }

    this.setInputValue(this.props.inputProps.defaultValue)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.inputProps.value !== this.props.inputProps.value) {
      this.setInputValue(nextProps.inputProps.value)
    }
  }

  handleRef = instance => {
    if (!instance || this.baseElement) return
    // eslint-disable-next-line react/no-find-dom-node
    this.baseElement = findDOMNode(instance)
  }

  handleInputRef = inputElement => {
    if (!inputElement) return
    this.inputElement = inputElement
  }

  setInputValue = (value = '') => this.setState({ value })

  handleBlur = event => {
    this.setState({
      isFocused: false,
    })

    if (isFunction(this.props.inputProps.onBlur)) {
      this.props.inputProps.onBlur(event)
    }
  }

  handleFocus = event => {
    this.setState({
      isFocused: true,
    })

    if (isFunction(this.props.inputProps.onFocus)) {
      this.props.inputProps.onFocus(event)
    }
  }

  handleChange = event => {
    // For "controlled" scenarios, updates to the cached input text should come
    // exclusively via props (cWRP) so it exactly mirrors the current application
    // state, otherwise a re-render will occur before the new text has completed its
    // feedback loop and the cursor position is lost
    if (this.state.isControlled === false) {
      this.setInputValue(event.target.value)
    }

    if (isFunction(this.props.inputProps.onChange)) {
      this.props.inputProps.onChange(event)
    }
  }

  // These are passed to children (icons/clear buttons/etc)
  // via react-broadcast so they can manipulate the input
  getStateAndHelpers() {
    return {
      handleChange: this.handleChange,
      baseElement: this.baseElement,
      inputElement: this.inputElement,
      ...this.state,
    }
  }

  render() {
    const {
      inputProps,
      errorProps,
      className: containerClassName,
      childrenBeforeInput,
      childrenAfterInput,
      label,
      caption,
      disabled,
      ...containerPropsRest
    } = this.props

    const { className: inputClassName, placeholder, ...inputPropsRest } =
      inputProps || {}

    const { hasError, errorMessage, ...errorPropsRest } = errorProps || {}
    return (
      <StyledInputWrapper
        className={cx(containerClassName, { disabled: disabled })}
      >
        <StyledInputContainer
          className={cx({
            containerError: hasError,
            containerFocus: this.state.isFocused,
          })}
          {...containerPropsRest}
        >
          <Fragment>
            {childrenBeforeInput}
            <div className='input_parent'>
              {label && <label className='input_label'>{label}</label>}
              <StyledInput
                placeholder={placeholder}
                ref={this.handleInputRef}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                onChange={this.handleChange}
                className={inputClassName}
                value={this.state.value}
                data-testid='input'
                {...inputPropsRest}
              />
            </div>
            {childrenAfterInput}
          </Fragment>
        </StyledInputContainer>
        {caption && !hasError && <StyledCaption>{caption}</StyledCaption>}
        {hasError && errorMessage && (
          <StyledError {...errorPropsRest}>
            <span>{errorMessage}</span>
          </StyledError>
        )}
      </StyledInputWrapper>
    )
  }
}
