import snakeCase from 'lodash/snakeCase'

export const mimeTypes = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/bmp',
  'image/x-icon',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
]

const threeDExtensions = [/\.usdz$/, /\.blend$/, /\.gltf$/]

export const isValidUploadType = (file, types) => {
  if (types.includes(file.type)) {
    return true
  }
  for (const extension of threeDExtensions) {
    if (extension.test(file.name)) {
      return true
    }
  }
  return false
}

export const isCompressible = filename => {
  const nonCompressibleExtensions = threeDExtensions.concat(
    [/\.gif$/],
    [/\.svg$/]
  )
  for (const extension of nonCompressibleExtensions) {
    if (extension.test(filename)) {
      return false
    }
  }
  return true
}

export const validFilename = filename => {
  const nameError = filename.match(/\s/)
  if (Array.isArray(nameError)) {
    const extensionIndex = filename.search(/\.\w+/) // we want to match from the first possible file extension
    const ext = filename.slice(extensionIndex)
    const name = filename.slice(0, extensionIndex)
    filename = snakeCase(name) + ext
  }
  return filename
}
