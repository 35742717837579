import API, { API_URL } from 'src/business-layer-client'
import { MESSAGES, TOAST_MESSAGE_TYPES } from 'src/constants'
import { get, isEmpty } from 'lodash'
import {
  getLocalizedComponents,
  getSelectedRouteFromGqlResponse,
} from './utils'
import { createActionPrefix } from 'redux-nano'
import descriptorService from 'services/api/descriptor'
import { showToast } from 'components/ToastSnackbarContainer'

const createAction = createActionPrefix('EDITOR')

export const EDITOR_INITIALIZE_SUBMIT = createAction('EDITOR_INITIALIZE_SUBMIT')

export const EDITOR_INITIALIZE_SUCCESS = createAction(
  'EDITOR_INITIALIZE_SUCCESS'
)
export const EDITOR_INITIALIZE_ERROR = createAction('EDITOR_INITIALIZE_ERROR')
export const EDITOR_CONTENT_EDIT = createAction('EDITOR_CONTENT_EDIT')
export const EDITOR_ADD_COMPONENT = createAction('EDITOR_ADD_COMPONENT')
export const EDITOR_UPDATE = createAction('EDITOR_UPDATE')
export const EDITOR_UPDATE_COMPONENT = createAction('EDITOR_UPDATE_COMPONENT')
export const EDITOR_REMOVE_COMPONENT = createAction('EDITOR_REMOVE_COMPONENT')
export const EDITOR_REORDER_COMPONENT = createAction('EDITOR_REORDER_COMPONENT')
export const EDITOR_ONCHANGE = createAction('EDITOR_ONCHANGE')
export const EDITOR_LOCALE_CHANGE = createAction('EDITOR_LOCALE_CHANGE')

export const CONTENT_EDIT = createAction('CONTENT_EDIT')
export const COMPONENT_SAVED = createAction('COMPONENT_SAVED')
export const EDITOR_META_DATA_SAVE = createAction('EDITOR_META_DATA_SAVE')
export const EDITOR_RESET = createAction('EDITOR_RESET')

export const EDITOR_IS_DELETING = createAction('EDITOR_IS_DELETING')
export const EDITOR_INSERT_COMPONENT = createAction('EDITOR_INSERT_COMPONENT')
export const EDITOR_UPDATE_CHANNEL_URL = createAction(
  'EDITOR_UPDATE_CHANNEL_URL'
)
export const EDITOR_UPDATE_SUCCESS = createAction('EDITOR_UPDATE_SUCCESS')
export const EDITOR_SET_VERSION_STATUS = createAction(
  'EDITOR_SET_VERSION_STATUS'
)
export const EDITOR_SET_LOCALIZED_ASSETS = createAction(
  'EDITOR_SET_LOCALIZED_ASSETS'
)
const getPageVersion = async ({
  shouldUseGraphQL,
  getPageVersionById,
  pageId,
  versionId,
  hasLocales,
}) => {
  let selectedRoute, curatedComponents, localizedCuratedComponents

  if (shouldUseGraphQL) {
    const pageVersionData = await getPageVersionById()
    selectedRoute = pageVersionData.data.pageVariantById
    if (hasLocales && !isEmpty(selectedRoute?.locales)) {
      localizedCuratedComponents = getLocalizedComponents(
        selectedRoute.variants[0].localizedComponents,
        selectedRoute.locales
      )
      curatedComponents = localizedCuratedComponents[0].components
    } else {
      curatedComponents = selectedRoute.variants[0].components
    }
  } else {
    const versionDetails = await API.xpm.get(
      API_URL.VERSION_DETAILS({ pageId, versionId })
    )
    selectedRoute = get(versionDetails, 'data.data', {})
    curatedComponents = get(selectedRoute, 'components', [])
  }

  return { selectedRoute, curatedComponents, localizedCuratedComponents }
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const EDITOR_INITIALIZE = payload => async dispatch => {
  try {
    let selectedRoute,
      curatedComponents,
      filteredDescriptors,
      localizedCuratedComponents
    const {
      pageId,
      gcId,
      versionId,
      ecommerceAppUrl,
      isGlobal,
      shouldConsumeUds,
      shouldUseGraphQL,
      hasLocales,
      getUniversalDescriptors,
      getPageVersionById,
      getGeVersionById,
    } = payload
    dispatch(EDITOR_INITIALIZE_SUBMIT())

    if (shouldConsumeUds) {
      const { data } = await getUniversalDescriptors()
      filteredDescriptors = data.universalDescriptors
    } else {
      filteredDescriptors = await descriptorService.get({
        appurl: ecommerceAppUrl,
      })
    }

    const descriptorsToSort = [...filteredDescriptors]
    descriptorsToSort.sort((a, b) => {
      const labelA = a?.label.toLowerCase()
      const labelB = b?.label.toLowerCase()
      if (labelA < labelB) {
        return -1
      }
      if (labelA > labelB) {
        return 1
      }
      return 0
    })

    filteredDescriptors = descriptorsToSort

    if (isGlobal) {
      // ge-editor
      const versionDetails = shouldUseGraphQL
        ? await getGeVersionById()
        : await API.xpm.get(
            API_URL.GLOBAL_COMPONENT_VERSION_DETAILS({ gcId, versionId })
          )

      selectedRoute = shouldUseGraphQL
        ? getSelectedRouteFromGqlResponse(versionDetails)
        : get(versionDetails, 'data.data.version', {})

      // stores components states of all locales
      if (shouldUseGraphQL && hasLocales && !isEmpty(selectedRoute?.locales)) {
        localizedCuratedComponents = getLocalizedComponents(
          selectedRoute.localizedComponents,
          selectedRoute.locales
        )
        curatedComponents = localizedCuratedComponents[0].components
      } else {
        curatedComponents = get(selectedRoute, 'components', []) ?? []
      }
    } else {
      // editor
      // @todo - Can be removed once graphql api is the default
      const pageVersionObj = await getPageVersion({
        getPageVersionById,
        pageId,
        shouldUseGraphQL,
        versionId,
        hasLocales,
      })
      selectedRoute = pageVersionObj.selectedRoute
      localizedCuratedComponents = pageVersionObj.localizedCuratedComponents
      curatedComponents = pageVersionObj.curatedComponents ?? []
    }

    dispatch(
      EDITOR_INITIALIZE_SUCCESS({
        // components data previously saved in the db for this version of page
        curatedComponents,
        localizedCuratedComponents,
        // descriptors received from the frontend app
        descriptors: filteredDescriptors,
        // all the information on this version of the page saved in DB
        selectedRoute,
        shouldConsumeUds,
      })
    )
  } catch (error) {
    console.error(error)
    showToast({
      message: MESSAGES.ERROR_FETCHING_PAGE,
      kind: TOAST_MESSAGE_TYPES.ALERT,
    })
    dispatch(EDITOR_INITIALIZE_ERROR(error))
  }
}
