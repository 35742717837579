import { isEmpty, isPlainObject, update } from 'lodash'
import getValueFromType from 'lib/getValueFromType'
import { nanoid } from 'nanoid'

const getCuratedComponent = descriptor => {
  if (isEmpty(descriptor)) {
    throw new Error('missing descriptor argument')
  }

  if (!isPlainObject(descriptor) || !descriptor?.attributes) {
    throw new Error('invalid descriptor received')
  }

  const params = { components: [] }

  for (const key in descriptor.attributes) {
    if (descriptor.attributes?.[key]) {
      const element = descriptor.attributes[key]
      const updater = () => {
        return getValueFromType(element)
      }

      update(params, key, updater)
    }
  }

  return {
    id: descriptor.id,
    key: nanoid(), // generate unique key for component
    label: descriptor.label,
    params: {
      ...params,
    },
  }
}

export default getCuratedComponent
