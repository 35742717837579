import { PAGE_NOT_FOUND_CONTENT, PAGE_NOT_FOUND_HEADING } from 'src/constants'
import ErrorPageTemplate from 'components/ErrorPageTemplate'
import PropTypes from 'prop-types'
import React from 'react'
export default function Custom404(props) {
  return (
    <ErrorPageTemplate
      statusCode={props.statusCode}
      heading={PAGE_NOT_FOUND_HEADING}
      paragraph={PAGE_NOT_FOUND_CONTENT}
    />
  )
}

Custom404.defaultProps = {
  statusCode: 400,
}

Custom404.propTypes = {
  statusCode: PropTypes.number.isRequired,
}
