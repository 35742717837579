import { SPACES } from 'styles/constants'
import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

const white = themeUi.palette.brand.primary.white as string

export const StyledHeaderWrapper = styled.nav`
  h2 {
    margin: 0 0 ${SPACES[3]};
    ${themeUi.typography.subtitle3.css}
  }

  .tabs {
    margin-bottom: ${SPACES[3]};
  }

  .tab-developers {
    margin-bottom: ${({ theme }) => theme.space.space_6};
  }

  .buttons {
    display: flex;
    gap: ${({ theme }) => theme.space.space_5};

    .hidden-file-input {
      display: none;
    }

    .facade-button {
      align-items: center;
      background: ${white};
      border-radius: ${({ theme }) => theme.borderRadius.huge};
      display: inline-flex;
      flex-flow: row nowrap;
      font-size: ${({ theme }) => theme.typography.fontSize.sm};
      height: 36px;
      justify-content: center;
      width: auto;
      padding-left: 30px;
      padding-right: 30px;

      &:hover {
        background: ${themeUi.palette.brand.primary.gray};
        color: ${white};
        cursor: pointer;
      }

      &.disabled {
        opacity: 0.5;
      }
    }
  }

  .settings-search {
    display: flex;
    gap: ${({ theme }) => theme.space.space_4};

    > div:first-child {
      max-width: 758px;
    }
  }
`

export const StyledSpinnerWrapper = styled.div`
  position: relative;
`
