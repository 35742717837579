export const PAGE_OPERATION_OPTIONS = {
  LIST_PAGES: 'ListPages',
  UPDATE_PAGE: 'UpdatePage',
  ARCHIVE_PAGE: 'ArchivePage',
  UNARCHIVE_PAGE: 'UnarchivePage',
  CREATE_PAGE: 'CreatePage',
  FILTER_PAGE: 'FilterPages',
  COPY_PAGE: 'CopyPage',
}

export const LIST_PAGES_STRING = `
  query ListPages(
    $input: PageFilterInput!
    $offset: Int
    $limit: Int
    $orderBy: OrderByInput
  ) {
    pages(input: $input, offset: $offset, limit: $limit, orderBy: $orderBy) {
      totalCount
      edges {
        node {
          id
          name
          url
          group {
            pageTypeId
            pageTypeName
          }
          updatedAt
          isArchived
          isActive
          channels {
            id
            name
            url
            label
          }
          locales
          seoFields
          localizedSeoFields
          variants {
            id
            versionName
            status
            startDate
            endDate
            updatedAt
            isArchived
          }
          locales
        }
      }
    }
  }
`

export const CREATE_PAGE_STRING = `
  mutation CreatePage(
    $input: CreatePageInput!
  ) {
    createPage(input: $input) {
      id
      name
      url
      updatedAt
      isArchived
      isActive
      channels {
        id
      }
      locales
      seoFields
      localizedSeoFields
      variants {
        id
      }
    }
  }
`

export const UPDATE_PAGE_STRING = `
mutation UpdatePage(
  $input: UpdatePageInput!
) {
  updatePageDetails(input: $input) {
    id
    name
    url
    group {
      pageTypeId
      pageTypeName
    }
    updatedAt
    isArchived
    isActive
    channels {
      id
      name
      url
      label
    }
    locales
    seoFields
    localizedSeoFields
    variants {
      id
      versionName
      status
      startDate
      endDate
      updatedAt
      isArchived
    }
  }
}`

export const ARCHIVE_PAGE_STRING = `
mutation ArchivePage(
  $contentMetaId: ID!
) {
  archiveContentMeta(contentMetaId: $contentMetaId) {
    id
    name
    updatedAt
    isArchived
    isActive
    segments
    locales
    channels
    variants {
      id
      versionName
      description
      status
      startDate
      endDate
      updatedAt
      isArchived
    }
  }
}`

export const UNARCHIVE_PAGE_STRING = `
mutation UnarchivePage(
  $contentMetaId: ID!
) {
  unarchiveContentMeta(contentMetaId: $contentMetaId) {
    id
    name
    updatedAt
    isArchived
    isActive
    segments
    locales
    channels
    variants {
      id
      versionName
      description
      status
      startDate
      endDate
      updatedAt
      isArchived
    }
  }
}`

export const CREATE_PAGE_VARIANT_STRING = `
mutation CreatePageVariant(
  $input: CreateContentVariantInput!
) {
  createPageVariant(input: $input) {
    id
    status
    versionName
    description
  }
}`

export const DELETE_PAGE_ASYNC_MUTATION = `
  mutation DeletePage ($contentMetaId: ID!)
  {  
    deletePageAsync(
      contentMetaId: $contentMetaId
    ) {
        message
        status
    }
}`

export const DELETE_PAGE_VARIANT_MUTATION = `
  mutation DeletePageVariant($parentContentId: ID! $contentVariantId: ID!)
  {  
    deletePageVariant(
      parentContentId: $parentContentId
      contentVariantId: $contentVariantId
    ) {
        id
        versionName
    }
}`

export const COPY_PAGE_STRING = `
 mutation CopyPage(
    $input: CopyPageInput!
  ) {
    copyPage(input: $input) {
      id
      name
      isArchived
      url
      updatedAt
      channels {
        id
        name
        url
        label
      }
    }
}`

export const COPY_PAGE_VARIANT_STRING = `
mutation CopyPageVariant(
  $input: CopyPageVariantInput!
) {
  copyPageVariant(input: $input) {
    id
    isArchived
    versionName
    updatedAt
    description
  }
}`

export const COPY_PAGE_ASYNC_STRING = `
 mutation CopyPageAsync(
    $input: CopyPageInput!
  ) {
    copyPageAsync(input: $input) {
      status
      message
    }
}`
