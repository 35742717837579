import IconAdd from 'assets/icons/IconAdd'
import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledBox = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  min-width: 250px;
  height: max-content;
  margin-top: 40px;
  background-color: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.borderRadius.default};
  ${themeUi.shadows.light.level1}
  user-select: none;
  box-sizing: border-box;
  margin-right: 1rem;
`

export const StyledBoxHeader = styled.div`
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.gray[200] : 'transparent'};
`

export const StyledBoxBody = styled.div`
  margin: 0;
`

export const StyledBoxLabel = styled.h4`
  ${props => props.theme.typography.h6};
  margin: 0;
  padding: 0;
  padding: ${({ theme }) => `${theme.space.space_4} ${theme.space.space_5}`};
  color: ${props => props.theme.colors.black[500]};
  padding-bottom: 0;
`

export const StyledInlineForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  margin: ${({ theme }) => `${theme.space.space_4} ${theme.space.space_5}`};
  margin-top: ${({ theme }) => `${theme.space.space_2}`};
`

export const StyledIconAdd = styled(IconAdd)`
  position: absolute;
  cursor: pointer;
  top: 25%;
  left: 10px;
  width: 14px;
  height: 14px;
  z-index: ${props => props.theme.zIndex.basic};
  svg {
    fill: ${props => props.theme.colors.gray[400]};
  }
`

export const StyledInput = styled.input`
  ${({ theme }) => theme.typography.body};
  height: 2rem;
  border: 1px solid ${props => props.theme.colors.gray[200]};
  background: ${props => props.theme.colors.gray[100]};
  padding: ${props => props.theme.space.space_3};
  padding-left: ${props => props.theme.space.space_6};
  outline: none;
  border-radius: ${props => props.theme.borderRadius.md};
  &:focus {
    border-color: ${props => props.theme.colors.gray[500]};
  }
`
