import { createSelector } from 'reselect'

export const getState = state => state.editor

export const getDescriptors = state => getState(state).descriptors

export const getSelectedUrl = state => getState(state).selectedUrl

export const getSelectedRoute = state => getState(state).selectedRoute || null

export const getCuratedComponents = state => {
  return getState(state).curatedComponents
}

export const getEditorMetaData = state => getState(state).editorMetaData || {}
export const getSelectedDevice = createSelector(
  getEditorMetaData,
  editorMetaData => editorMetaData.iframe.device
)

export const getSaveTime = state => getEditorMetaData(state).saveTime

export const getSelectedComponent = createSelector(
  getCuratedComponents,
  components => {
    return Array.isArray(components)
      ? components.find(({ isSelected }) => isSelected)
      : {}
  }
)

export const getSelectedDescriptor = createSelector(
  getDescriptors,
  getSelectedComponent,
  (descriptors, selectedComponent) => {
    if (!selectedComponent) return {}

    const findDescriptorById = descriptor =>
      descriptor.id === selectedComponent.id
    const [descriptor] = descriptors.filter(findDescriptorById)
    return descriptor
  }
)

export const getIsDeletingComponent = state =>
  getState(state).isDeletingComponent

export const getDeletePayload = state => getState(state).deletePayload

export const getChannelUrl = state => getState(state).channelUrl

export const getDescriptorMismatchState = state =>
  getState(state).hasDescriptorMismatch

export const getLocalizedCuratedComponents = state =>
  getState(state).localizedCuratedComponents
export const getVersionStatus = state => getState(state).versionStatus
