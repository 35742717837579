import {
  BROWSE_MENU_TREE_CONTEXT_SECTION,
  BROWSE_MENU_TREE_DEFAULT_NAME as DEFAULT_LABELS,
} from 'src/constants'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { LevelProps, RenderNodesProps } from './types'
import {
  NODE_TYPE,
  ReactDnDProvidedProps,
} from 'modules/browse-menu-tree-gql/types'
import React, { useCallback, useState } from 'react'
import {
  StyledLevel,
  StyledLevelHeader,
} from 'modules/browse-menu-tree-gql/components/Level/styles'
import AddNewNode from '../AddNewNode'
import { AddNewNodeSaveProps } from '../AddNewNode/types'
import ContextMenu from 'components/ContextMenu'
import Node from '../Node'
import { ParentNode } from '../Node/types'
import { StyledWrapper } from './styles'
import { getVersionStatus } from 'modules/editor/selectors'
import { useSelector } from 'react-redux'
import { useVersionBasedEditAccess } from 'hooks/useVersionBasedEditAccess'

const Level = ({
  activeNodes,
  depth,
  handleCreate,
  level,
  onDragEnd,
  refreshPipelines,
  setMenuTree,
  setActiveNodes,
  selectedLocale,
  ...propagatedProps
}: LevelProps): JSX.Element => {
  const [isAddingSection, setIsAddingSection] = useState(false)
  const [hoveredSectionId, setHoveredSectionId] = useState<string>()
  const [expandedSectionMap, setExpandedSectionMap] = useState<{
    [key: string]: boolean
  }>({})
  const isLevelEmpty = level.length === 0
  const parentNode = depth >= 1 ? activeNodes[depth - 1] : null
  const levelName =
    parentNode?.name[selectedLocale] || DEFAULT_LABELS.getLevel(depth)
  const menuVersionStatus = useSelector(getVersionStatus) as string
  const hasEditAccess = useVersionBasedEditAccess(menuVersionStatus)
  const setExpandedSection = useCallback(
    (sectionId: string, isExpanded: boolean) => {
      setExpandedSectionMap(prev => ({ ...prev, [sectionId]: isExpanded }))
    },
    [setExpandedSectionMap]
  )

  const setHoveredSection = useCallback(
    (sectionId: string) => {
      setHoveredSectionId(sectionId)
      refreshPipelines()
    },
    [setHoveredSectionId, refreshPipelines]
  )

  const saveNode = async ({
    depth: currentLevel,
    name,
    nodeParentId,
    nodeType,
  }: AddNewNodeSaveProps) => {
    const isSection = nodeType === NODE_TYPE.SECTION

    if (isSection) {
      setIsAddingSection(false)
    }

    await handleCreate({ depth: currentLevel, name, nodeParentId, nodeType })
  }

  const onDragStart = ({
    source,
  }: {
    source: { droppableId: number; index: number }
  }) => {
    const { index } = source
    const draggedNode = level[index]
    //if draggedNode is a section, collapse it
    if (draggedNode.section) {
      setExpandedSectionMap(prev => ({ ...prev, [draggedNode._id]: false }))

      //if activeNodes are in the dragged section, trim tree levels
      if (activeNodes[depth]?.parent === draggedNode._id) {
        setMenuTree(prevMenuTree => prevMenuTree.slice(0, depth + 1))
        setActiveNodes(prevActiveNodes => prevActiveNodes.slice(0, depth))
      }
    }
  }

  return (
    <StyledLevel>
      <StyledLevelHeader>
        <div className='level-name' data-testid={`level-${depth}-name`}>
          {levelName}
        </div>
        {hasEditAccess && (
          <div data-testid={`context-menu-add-section`}>
            <ContextMenu>
              <ContextMenu.Item
                onClick={() => {
                  setIsAddingSection(true)
                }}
              >
                {BROWSE_MENU_TREE_CONTEXT_SECTION.name}
              </ContextMenu.Item>
            </ContextMenu>
          </div>
        )}
      </StyledLevelHeader>
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Droppable droppableId={depth.toString()}>
          {(provided: ReactDnDProvidedProps) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              data-testid={`browse-menu-tree-level-${depth}`}
            >
              {renderNodes({
                activeNodes,
                expandedSectionMap,
                depth,
                handleCreate,
                hoveredSectionId,
                level,
                parentNode,
                selectedLocale,
                setExpandedSection,
                setHoveredSectionId: setHoveredSection,
                ...propagatedProps,
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {hasEditAccess && (
        <AddNewNode
          id={`new-node-${depth}`}
          depth={depth}
          nodeType={isAddingSection ? NODE_TYPE.SECTION : NODE_TYPE.NODE}
          isLevelEmpty={isLevelEmpty}
          onCancel={() => setIsAddingSection(false)}
          onSave={saveNode}
          parentNodeId={parentNode?._id}
          refreshPipelines={refreshPipelines}
        />
      )}
    </StyledLevel>
  )
}

const renderNodes = ({
  activeNodes,
  depth,
  level,
  parentNode,
  expandedSectionMap,
  selectedLocale,
  ...nodeProps
}: RenderNodesProps) => {
  let sectionChildParent

  const sortedLevel = level.sort((a, b) => a.order - b.order)

  return sortedLevel.map((node, index) => {
    const isSection = node.section
    const nodeId = node._id
    let nodeType = NODE_TYPE.NODE
    let isSectionTail = false

    if (isSection) {
      const hasSubnodes = sortedLevel[index + 1]?.parent === nodeId

      sectionChildParent = node
      nodeType = NODE_TYPE.SECTION
      isSectionTail = !hasSubnodes
    }

    const isSectionChild =
      sectionChildParent && sectionChildParent._id === node.parent

    const shouldShowSectionChild =
      isSectionChild && expandedSectionMap[sectionChildParent._id]

    if (isSectionChild) {
      const isLastSubnode = sortedLevel[index + 1]?.parent !== node.parent

      nodeType = NODE_TYPE.SUBNODE
      isSectionTail = isLastSubnode
    }

    return (
      <StyledWrapper key={`${nodeId}-${node.name[selectedLocale]}`}>
        <Node
          activeNodes={activeNodes}
          depth={depth}
          node={node}
          nodeType={nodeType}
          index={index}
          isActiveOrExpanded={
            activeNodes[depth]?._id === nodeId || expandedSectionMap[nodeId]
          }
          isSectionTail={isSectionTail}
          showNode={shouldShowSectionChild || !isSectionChild}
          parentNode={
            (isSectionChild ? sectionChildParent : parentNode) as ParentNode
          }
          selectedLocale={selectedLocale}
          shouldFetchNodesOnClick={
            !node.section || expandedSectionMap[nodeId] === undefined
          }
          {...nodeProps}
        />
      </StyledWrapper>
    )
  })
}

export default Level
