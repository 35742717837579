import { CLEAR_SEARCH, NO_RESULTS_FOUND } from 'src/constants'
import { EmptyTableView } from './EmptyTableView'
import { PropTypes } from 'prop-types'
import React from 'react'
import noop from 'lodash/noop'

export const NoSearchResults = ({ columns, resetSearch }) => (
  <EmptyTableView
    columns={columns}
    buttonProps={{
      size: 'small',
      text: CLEAR_SEARCH,
      onClick: e => {
        e.preventDefault()
        resetSearch()
      },
    }}
    primaryText={NO_RESULTS_FOUND}
    secondaryText={
      <>
        Try searching for something more general,
        <br /> change filters or
        <br />
        check for spelling mistakes.
      </>
    }
  />
)

NoSearchResults.defaultProps = {
  columns: [],
  resetSearch: noop,
}

NoSearchResults.propTypes = {
  columns: PropTypes.array.isRequired,
  resetSearch: PropTypes.func.isRequired,
}

export default NoSearchResults
