import {
  ADD_GLOBAL_ELEMENT_LABEL,
  DEFAULT_PAGINATION,
  GE_EMPTY_SCREEN,
  NAVIGATION,
  TABS_GE,
} from 'src/constants'
import {
  GEModalDetails,
  GEModuleContext,
  GEModuleTabs,
  GE_MODAL_ID,
} from 'data/global-elements-list/types'
import React, { useCallback, useMemo, useState } from 'react'
import {
  constructTableColumns,
  globalElementsDtoToTableEntries,
} from 'data/global-elements-list'
import { gql, useLazyQuery } from '@apollo/client'
import {
  useGEPageCtxOptions,
  useGEVersionCtxOptions,
} from 'data/global-elements-list/context-options'
import ContentListModule from 'modules/content-list-module'
import ContentModalModule from 'components/ContentModalModule'
import EmptyScreen from 'components/EmptyScreen'
import { FeaturesList } from 'data/types'
import { Filters } from 'components/Filters/types'
import { GEListGraphQlResponse } from 'src/graphql-proxy/transformations/globalElements/types'
import { LIST_GE_STRING } from 'services/graphql'
import { Link } from '@teamfabric/copilot-ui'
import { StyledEmptyScreenWrapper } from 'data/browse-menu-gql/styles'
import { getPageFilterEntries } from 'src/data/utils'
import isEqual from 'lodash/isEqual'
import { useGEModalOperations } from 'data/global-elements-list/modal-operations'
import { useGEModalOptions } from 'data/global-elements-list/modal-options'
import { useNavigate } from '@reach/router'
import useTableData from 'components/ContentTableModule/useTableData'
import { useUserPermissions } from 'contexts/userPermissions'

interface Props {
  featuresList: FeaturesList
}

const TABLE_MODAL_INITIAL_VALUE: GEModalDetails = {
  visibleModalId: null,
  targetItem: null,
  globalElement: null,
}

const GlobalElements = (props: Props): JSX.Element => {
  const { featuresList } = props
  const [moduleTab, setModuleTab] = useState<GEModuleTabs>(GEModuleTabs.ALL)
  const isArchivedTab = moduleTab === GEModuleTabs.ARCHIVED
  const navigate = useNavigate()

  const initialFilters: Filters = useMemo(() => {
    return {
      channels: [],
      status: [],
      locales: [],
    }
  }, [])
  const [filters, setFilters] = useState<Filters>(initialFilters)

  const [tableModal, setTableModal] = useState<GEModalDetails>(
    TABLE_MODAL_INITIAL_VALUE
  )

  const [
    fetchGlobalElements,
    { data: globalElementsData, loading: globalElementsLoading },
  ] = useLazyQuery<GEListGraphQlResponse>(gql(LIST_GE_STRING))

  const globalElementsFetchQuery = useCallback(
    (limit: number, offset: number) =>
      fetchGlobalElements({
        variables: {
          input: {
            isArchived: isArchivedTab,
            filter: {
              channels: filters?.channels ?? [],
              locales: filters?.locales ?? [],
              status: filters?.status.map(s => s.toUpperCase()) ?? [],
            },
          },
          limit,
          offset,
          orderBy: { field: 'updatedAt' },
        },
      }),
    [fetchGlobalElements, isArchivedTab, filters]
  )

  const globalElements = useMemo(
    () =>
      globalElementsData?.globalElements?.edges?.map(edge => edge?.node) ?? [],
    [globalElementsData]
  )

  const userPermissions = useUserPermissions()
  const getVersionCtxOptions = useGEVersionCtxOptions({
    handlers: {
      showTableModal: setTableModal,
    },
  })
  const getPageCtxOptions = useGEPageCtxOptions({
    handlers: { showTableModal: setTableModal },
  })
  const tableEntries = useMemo(
    () =>
      globalElementsDtoToTableEntries(globalElements, {
        archived: isArchivedTab,
        getVersionCtxOptions,
        getPageCtxOptions,
        userPermissions,
        navigate,
      }),
    [
      userPermissions,
      navigate,
      globalElements,
      isArchivedTab,
      getVersionCtxOptions,
      getPageCtxOptions,
    ]
  )
  const [
    tableData,
    dispatchTableAction,
    pagination,
    handlePagination,
  ] = useTableData({
    entries: tableEntries,
    totalCount:
      globalElementsData?.globalElements?.totalCount ??
      DEFAULT_PAGINATION.TOTAL_ITEMS,
    fetchQuery: globalElementsFetchQuery,
    filters,
    initialFilters,
  })

  const pageFilterProps = useMemo(
    () => ({
      entries: getPageFilterEntries({
        locales: featuresList.i18n.locales,
        channels: featuresList.multiChannel.channels,
        filters,
        onFilterChange: (selectedFilters, _filterName) =>
          setFilters(prevFilters => ({
            ...prevFilters,
            [_filterName]: selectedFilters,
          })),
      }),
    }),
    [featuresList, filters]
  )

  const moduleContext: GEModuleContext = useMemo(() => {
    return {
      tableModal,
      moduleTab,
      closeTableModal: () => setTableModal(TABLE_MODAL_INITIAL_VALUE),
      showTableModal: setTableModal,
      featuresList,
      dispatchTableAction,
    }
  }, [tableModal, moduleTab, featuresList, dispatchTableAction])

  const { modalOptions } = useGEModalOptions({
    operations: useGEModalOperations({ context: moduleContext }),
    context: moduleContext,
  })

  const globalElementsModalProps = {
    visibleModalId: tableModal.visibleModalId,
    modalOptions: modalOptions,
  }
  const tabsProps = useMemo(
    () => ({
      activeTabIndex: moduleTab,
      setActiveTabIndex: setModuleTab,
      tabs: [
        {
          id: TABS_GE.GLOBAL_ELEMENTS.id,
          title: TABS_GE.GLOBAL_ELEMENTS.value,
        },
        {
          id: TABS_GE.ARCHIVED.id,
          title: TABS_GE.ARCHIVED.value,
        },
      ],
    }),
    [moduleTab, setModuleTab]
  )

  const onAddNewGlobalElement = () => {
    setTableModal({
      visibleModalId: GE_MODAL_ID.ADD_GE,
    })
  }

  const noResults = useMemo(() => {
    return {
      isArchivedTab,
      contentType: NAVIGATION.GLOBAL_ELEMENTS,
    }
  }, [isArchivedTab])

  const ctaProps = useMemo(() => {
    let entries = []
    if (userPermissions.hasEditorPermissions) {
      entries = [
        {
          id: 'add-new-global-element',
          buttonText: ADD_GLOBAL_ELEMENT_LABEL,
          onClick: () => {
            onAddNewGlobalElement()
          },
          disabled: !userPermissions.hasEditorPermissions,
        },
      ]
    }
    return {
      entries,
    }
  }, [userPermissions])

  const paginationProps = useMemo(
    () => ({
      ...pagination,
      showPagination: true,
      handlePagination,
    }),
    [pagination, handlePagination]
  )
  const isAllGeTabs = moduleTab === GEModuleTabs.ALL
  const showEmptyScreen = useMemo(
    () =>
      isEqual(filters, initialFilters) &&
      !globalElements.length &&
      !globalElementsLoading &&
      isAllGeTabs,
    [
      filters,
      globalElements.length,
      globalElementsLoading,
      initialFilters,
      isAllGeTabs,
    ]
  )
  if (showEmptyScreen) {
    return (
      <>
        <StyledEmptyScreenWrapper data-testid='ge-empty-screen'>
          <EmptyScreen
            buttonProps={{
              size: 'small',
              text: GE_EMPTY_SCREEN.buttonText,
              onClick: () => {
                onAddNewGlobalElement()
              },
              disabled: !userPermissions.hasEditorPermissions,
            }}
            className='ge-empty-screen'
            primaryText={GE_EMPTY_SCREEN.primaryText}
            secondaryText={GE_EMPTY_SCREEN.secondaryText}
            footerContent={() => (
              <div className='ge-empty-screen-footer'>
                <p>{GE_EMPTY_SCREEN.footerText}</p>
                <Link
                  text={GE_EMPTY_SCREEN.footerLink}
                  href='https://knowledgebase.fabric.inc/knowledgebase/xm/global-elements'
                  target='_blank'
                />
              </div>
            )}
          />
        </StyledEmptyScreenWrapper>
        <ContentModalModule {...globalElementsModalProps} />
      </>
    )
  }
  return (
    <ContentListModule
      ctaProps={ctaProps}
      tabProps={tabsProps}
      searchProps={null}
      filterProps={pageFilterProps}
      modalProps={globalElementsModalProps}
      tableProps={{
        loading: globalElementsLoading,
        data: tableData,
        columns: constructTableColumns(featuresList),
        paginationProps,
        noResults,
      }}
    />
  )
}

export default GlobalElements
