import styled from 'styled-components'

const fontSize = {
  small: '13px',
}

export const StyledContextMenu = styled.div`
  [data-testid^='query-param-'] {
    font-size: ${fontSize.small};
  }
  .disabled {
    opacity: 0.4;
  }
`
