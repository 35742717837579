import { I18nLocale, I18nLocaleAction } from './types'
import i18nService from 'services/api/i18n'
import { updateListWithDefaultLabel } from './utils'

export const FETCH_I18N_LOCALES_SUCCESS = 'FETCH_I18N_LOCALES_SUCCESS'
export const CHANGE_ACTIVE_LOCALE_SUCCESS = 'CHANGE_ACTIVE_LOCALE_SUCCESS'
export const RESET_ACTIVE_LANGUAGE_SUCCESS = 'RESET_ACTIVE_LANGUAGE_SUCCESS'

export const FETCH_I18N_LOCALES = () => async (
  dispatch: (arg: { locales: I18nLocale[] }) => void
): Promise<void> => {
  const data = await i18nService.getDefaultChannel()
  dispatch(
    fetchLocalesSuccess({
      defaultLocale: data?.metadata?.defaultLocale,
      locales: data?.metadata?.supportedLocales ?? null,
    })
  )
}

const fetchLocalesSuccess = (payload: {
  defaultLocale: I18nLocale
  locales: I18nLocale[]
}): I18nLocaleAction => {
  return {
    type: FETCH_I18N_LOCALES_SUCCESS,
    locales: updateListWithDefaultLabel(payload),
    activeLang: payload.defaultLocale.code,
  }
}

export const CHANGE_ACTIVE_LANGUAGE = (activeLang: string) => (
  dispatch: (arg: { type: string; activeLang: string }) => void
): void => {
  dispatch({
    type: CHANGE_ACTIVE_LOCALE_SUCCESS,
    activeLang,
  })
}

export const RESET_ACTIVE_LANGUAGE = () => (
  dispatch: (arg: { type: string }) => void
): void => {
  dispatch({
    type: RESET_ACTIVE_LANGUAGE_SUCCESS,
  })
}
