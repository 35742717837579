import { AutoComplete, Button } from '@teamfabric/copilot-ui'
import { CONTENT_SEARCH_DATA_TESTID, SEARCH_TEXT } from 'src/constants'
import React, { FunctionComponent } from 'react'
import { ContentSearchModuleProps } from './types'
import { StyledWrapper } from './styles'
import isEmpty from 'lodash/isEmpty'

const ContentSearchModule: FunctionComponent<ContentSearchModuleProps> = props => {
  if (isEmpty(props)) {
    return null
  }

  const {
    searchTerm,
    isSearching,
    dropdownItems,
    onSearch,
    onClearSearch,
    onSelect,
    onInputChange,
  } = props

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLDivElement>
  ): void => {
    if (e.key === 'Enter') {
      return onSearch()
    }

    onInputChange(e.target.value as string)
  }

  const acInputProps = {
    icon: 'Search',
    showBorderWithFloatedLabel: false,
    inputProps: {
      'placeholder': SEARCH_TEXT,
      'onChange': handleChange,
      'type': 'search',
      'value': searchTerm,
      'name': 'content-search',
      'id': 'page-search',
      'data-testid': 'search-field',
    },
    onKeyUp: handleChange,
  }

  const acProps = {
    'isLoading': isSearching,
    'show': !!dropdownItems?.results?.length ?? false,
    onSelect,
    'data': dropdownItems,
    onClearSearch,
    'data-testid': CONTENT_SEARCH_DATA_TESTID,
  }

  return (
    <StyledWrapper data-testid='search-field-wrapper'>
      <div className='ac-padding'>
        <AutoComplete inputProps={acInputProps} autoCompleteProps={acProps} />
      </div>
      <div>
        <Button
          data-testid='button-content-search'
          text={SEARCH_TEXT}
          onClick={onSearch}
        />
      </div>
    </StyledWrapper>
  )
}

export default ContentSearchModule
