import {
  ARCHIVE_PAGE_STRING,
  COPY_PAGE_STRING,
  CREATE_PAGE_STRING,
  LIST_PAGES_STRING,
  PAGE_OPERATION_OPTIONS,
  UNARCHIVE_PAGE_STRING,
  UPDATE_PAGE_STRING,
} from 'services/graphql/page'
import {
  AllGraphQLResponseTypes,
  AllPageTransformOperationsTypes,
  AllVariableTypes,
  ChannelGraphQLResponse,
} from 'src/graphql-proxy/common'
import {
  PageArchiveGraphQLResponse,
  PageArchiveVariables,
  PageCopyGraphQLResponse,
  PageCopyRESTResponse,
  PageCopyRequestData,
  PageCopyVariables,
  PageCreateGraphQLResponse,
  PageCreateRESTResponse,
  PageCreateRequestData,
  PageCreateVariables,
  PageListGraphQLResponse,
  PageListRESTResponse,
  PageListVariables,
  PageUnarchiveGraphQLResponse,
  PageUnarchiveVariables,
  PageUpdateGraphQLResponse,
  PageUpdateVariables,
} from './types'
import { SegmentInput, SeoFieldInput } from 'services/graphql/types'
import { isEmpty, omit } from 'lodash'
import { LocalizedSeoFields } from 'components/FormDialog/common/content/ContentDetailsDialog/types'
import { nanoid } from 'nanoid'

export const pageRESTToGQLTransformer = {
  get: {
    queryParamAttributes: [
      'offset',
      'limit',
      'isArchived',
      'channels',
      'searchTerm',
    ],
    gqlTemplate: LIST_PAGES_STRING,
    generateVariables: ({
      queryParamValues,
    }: {
      queryParamValues: {
        isArchived: string
        channels: ChannelGraphQLResponse[]
        offset: string
        limit: string
        searchTerm: string
      }
    }): PageListVariables => {
      const {
        isArchived,
        channels,
        offset,
        limit,
        searchTerm,
      } = queryParamValues
      return {
        input: {
          isArchived: isArchived === 'true',
          filter: {
            ...(!isEmpty(channels) && { channels: channels }),
            ...(searchTerm && { searchTerm: searchTerm }),
          },
        },
        offset: parseInt(offset),
        limit: parseInt(limit),
        orderBy: {
          field: 'updatedAt',
        },
        transformOperationKey: PAGE_OPERATION_OPTIONS.LIST_PAGES, // for us to determine which operation was done in the response
      }
    },
  },
  put: {
    pathParamAttributes: ['pageId'],
    gqlTemplate: UPDATE_PAGE_STRING,
    bodyParamAttributes: [
      'name',
      'pageUrl',
      'channels',
      'seoFields',
      'typeId',
      'locales',
    ],
    generateVariables: ({
      bodyParamValues,
      pathParamValues,
    }: {
      bodyParamValues: {
        name: string
        pageUrl: string
        channels: [string]
        locales: [string]
        seoFields: SeoFieldInput
        typeId: string
        segments: [SegmentInput]
        localizedSeoFields: LocalizedSeoFields
      }
      pathParamValues: {
        pageId: string
      }
    }): PageUpdateVariables => {
      const { pageId } = pathParamValues
      const {
        name,
        pageUrl,
        channels,
        locales,
        seoFields,
        typeId,
        segments,
        localizedSeoFields,
      } = bodyParamValues
      return {
        input: {
          id: pageId,
          name,
          url: pageUrl,
          channels,
          seoFields,
          group: { pageTypeId: typeId },
          locales,
          localizedSeoFields,
          segments,
        },
        transformOperationKey: PAGE_OPERATION_OPTIONS.UPDATE_PAGE,
      }
    },
  },
  post: {
    gqlTemplate: CREATE_PAGE_STRING,
    generateVariables: ({
      bodyParamValues: {
        pageName,
        pageUrl,
        versionName,
        channels,
        locales,
        typeId,
        seoFields,
        localizedSeoFields,
      },
    }: {
      bodyParamValues: PageCreateRequestData
    }): PageCreateVariables => {
      if (seoFields && 'metadata' in seoFields) {
        seoFields.metadata.forEach(meta => {
          meta.id = nanoid()
        })
      }

      return {
        input: {
          name: pageName,
          url: pageUrl,
          versionName: versionName,
          channels: channels,
          locales,
          group: { pageTypeId: typeId },
          seoFields,
          localizedSeoFields,
        },
        orderBy: {
          field: 'updatedAt',
        },
        transformOperationKey: PAGE_OPERATION_OPTIONS.CREATE_PAGE,
      }
    },
  },
}

const getListPageTransformation = (
  responseData: PageListGraphQLResponse,
  requestVariables: PageListVariables
): PageListRESTResponse => {
  const { input, limit, offset } = requestVariables
  const {
    pages: { edges, totalCount },
  } = responseData
  return {
    pages: edges.map(e => {
      const {
        id,
        channels,
        name,
        updatedAt,
        url,
        isArchived,
        isActive,
        variants,
        seoFields,
        localizedSeoFields,
        locales,
        group,
      } = e.node
      return {
        name,
        seoFields,
        channels: channels.map(c => {
          const { url: channelURL, ...rest } = c
          return {
            ...rest,
            channelURL,
          }
        }),
        pageId: id,
        pageUrl: url,
        typeId: group?.pageTypeId ?? null,
        typeName: group?.pageTypeName ?? null,
        updatedAt,
        _id: id,
        isArchived,
        isActive,
        locales,
        localizedSeoFields,
        versions: variants.map(v => {
          const { id: versionId, versionName, ...rest } = v
          return {
            ...rest,
            name: versionName,
            versionId,
          }
        }),
      }
    }),
    query: {
      count: totalCount,
      isArchived: input.isArchived,
      limit: limit,
      offset: offset,
    },
  }
}

export const pageTransformOperations: Record<
  string,
  (
    arg1: AllGraphQLResponseTypes,
    arg2: AllVariableTypes
  ) => AllPageTransformOperationsTypes
> = {
  [PAGE_OPERATION_OPTIONS.LIST_PAGES]: getListPageTransformation,
  [PAGE_OPERATION_OPTIONS.FILTER_PAGE]: getListPageTransformation,
  [PAGE_OPERATION_OPTIONS.UPDATE_PAGE]: (
    responseData: PageUpdateGraphQLResponse
  ): PageUpdateGraphQLResponse => {
    return {
      updatePageDetails: responseData.updatePageDetails,
    }
  },
  [PAGE_OPERATION_OPTIONS.ARCHIVE_PAGE]: (
    responseData: PageArchiveGraphQLResponse
  ): PageArchiveGraphQLResponse => {
    return {
      archiveContentMeta: responseData.archiveContentMeta,
    }
  },
  [PAGE_OPERATION_OPTIONS.UNARCHIVE_PAGE]: (
    responseData: PageUnarchiveGraphQLResponse
  ): PageUnarchiveGraphQLResponse => {
    return {
      unarchiveContentMeta: responseData.unarchiveContentMeta,
    }
  },
  [PAGE_OPERATION_OPTIONS.CREATE_PAGE]: (
    responseData: PageCreateGraphQLResponse
  ): PageCreateRESTResponse => {
    const {
      id: pageId,
      isActive,
      isArchived,
      name,
      url: pageUrl,
      seoFields,
      variants,
      channels,
      updatedAt,
    } = responseData.createPage

    return {
      page: {
        name,
        pageId,
        pageUrl,
        isActive,
        isArchived,
        seoFields,
        channels: channels.map(c => omit({ ...c, channelURL: c.url }, 'url')),
        updatedAt,
        versions: variants.map(v =>
          omit({ ...v, versionId: v.id, name: v.versionName }, [
            'id',
            'versionName',
          ])
        ),
      },
      version: {
        versionId: variants[0].id,
      },
    }
  },
  [PAGE_OPERATION_OPTIONS.COPY_PAGE]: (
    responseData: PageCopyGraphQLResponse
  ): PageCopyRESTResponse => {
    return { page: { ...responseData.copyPage } }
  },
}

export const pageArchiveRESTToGQLTransformer = {
  put: {
    pathParamAttributes: ['pageId'],
    gqlTemplate: ARCHIVE_PAGE_STRING,
    generateVariables: ({
      pathParamValues,
    }: {
      pathParamValues: {
        pageId: string
      }
    }): PageArchiveVariables => {
      const { pageId } = pathParamValues
      return {
        contentMetaId: pageId,
        transformOperationKey: PAGE_OPERATION_OPTIONS.ARCHIVE_PAGE,
      }
    },
  },
}

export const pageUnarchiveRESTToGQLTransformer = {
  put: {
    pathParamAttributes: ['pageId'],
    gqlTemplate: UNARCHIVE_PAGE_STRING,
    generateVariables: ({
      pathParamValues,
    }: {
      pathParamValues: {
        pageId: string
      }
    }): PageUnarchiveVariables => {
      const { pageId } = pathParamValues
      return {
        contentMetaId: pageId,
        transformOperationKey: PAGE_OPERATION_OPTIONS.UNARCHIVE_PAGE,
      }
    },
  },
}

export const pageFilterRESTToGQLTransformer = {
  post: {
    gqlTemplate: LIST_PAGES_STRING,
    generateVariables: ({
      bodyParamValues,
    }: {
      bodyParamValues: {
        isArchived: boolean
        offset: number
        limit: number
        pageTypeIds: string[]
        channels: string[]
        status: string[]
        locales: string[]
      }
    }): PageListVariables => {
      const {
        isArchived,
        offset,
        limit,
        pageTypeIds,
        channels,
        status,
        locales,
      } = bodyParamValues

      return {
        input: {
          isArchived,
          filter: {
            channels,
            status,
            groups: pageTypeIds.map(pt => ({ pageTypeId: pt })),
            locales,
          },
        },
        offset,
        limit,
        orderBy: {
          field: 'updatedAt',
        },
        transformOperationKey: PAGE_OPERATION_OPTIONS.FILTER_PAGE,
      }
    },
  },
}
export const pageCopyRESTToGQLTransformer = {
  post: {
    pathParamAttributes: ['pageId'],
    gqlTemplate: COPY_PAGE_STRING,
    generateVariables: ({
      bodyParamValues,
      pathParamValues,
    }: {
      bodyParamValues: PageCopyRequestData
      pathParamValues: {
        pageId: string
      }
    }): PageCopyVariables => {
      return {
        input: {
          originalId: pathParamValues.pageId,
          copiedName: bodyParamValues.pageName,
          url: bodyParamValues.pageUrl,
          seoFields: bodyParamValues.seoFields,
          channels: bodyParamValues.channels,
          locales: bodyParamValues.locales,
          localizedSeoFields: bodyParamValues.localizedSeoFields,
          group: {
            pageTypeId: bodyParamValues.typeId,
          },
        },
        transformOperationKey: PAGE_OPERATION_OPTIONS.COPY_PAGE,
      }
    },
  },
}
