import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledModal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: ${props => props.theme.zIndex.modal};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`

export const StyledModalDialog = styled.div`
  width: 100%;
  max-height: 600px;
  max-width: ${props => `${props.width}`};
  box-sizing: border-box;
  background: ${themeUi.palette.brand.primary.white};
  position: relative;
  max-height: calc(100vh - 40px);
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  border-radius: ${props => props.theme.borderRadius.sm};
  ${themeUi.shadows.light.level1}
`

export const StyledModalClose = styled.div`
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
`

export const StyledModalBody = styled.div``

export const StyledModalContent = styled.div`
  padding: 32px;
`

export const StyledModalTitle = styled.h3`
  margin: 0;
  font-family: ${props => props.theme.typography.fontFamily.sans};
  ${props => props.theme.typography.h4};
  color: ${themeUi.palette.brand.primary.charcoal};
  margin-bottom: 16px;
`
