import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  // gap: 82px; Add back in XM-4441
  padding: 2.5rem ${({ theme }) => theme.space.space_6};

  .modal_content {
    width: 498px;
  }

  .context-menu-column {
    display: flex;
    justify-content: center;
  }

  table {
    // TODO: Can remove these width constraints once we
    //       add more metadata like Created At, Updated At, etc.
    width: 50%;
    min-width: 700px;
  }
`

export const StyledDialogContent = styled.div`
  strong {
    font-family: ${themeUi.typography.kicker.fontFamily};
  }
  p {
    font-size: ${themeUi.typography.h4.fontSize};
  }
`
