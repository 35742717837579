import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
} from '@apollo/client'
import { DescriptorProps } from 'store/types'

export interface ToCreateAndToUpdateType {
  toCreate: DescriptorProps[]
  toUpdate: DescriptorProps[]
}

export interface MutationType {
  (
    options?: MutationFunctionOptions<
      DescriptorProps,
      OperationVariables,
      DefaultContext,
      ApolloCache<unknown>
    >
  ): Promise<
    FetchResult<
      DescriptorProps,
      Record<string, unknown>,
      Record<string, unknown>
    >
  >
  (
    options?: MutationFunctionOptions<
      DescriptorProps,
      OperationVariables,
      DefaultContext,
      ApolloCache<unknown>
    >
  ): Promise<
    FetchResult<
      DescriptorProps,
      Record<string, unknown>,
      Record<string, unknown>
    >
  >
  (arg0: { variables: { input: DescriptorProps } }): unknown
}

export interface ReadDescriptorProps {
  descriptorFile: File
  setStoredDescriptors: (data: DescriptorProps[]) => void
  setInvalidDataContext: (ctx: InvalidContextProps) => void
}

export interface OnFileInputChangeProps {
  target: HTMLInputElement
  setStoredDescriptors: (data: DescriptorProps[]) => void
  setInvalidDataContext: (ctx: InvalidContextProps) => void
}
export interface Counter {
  success: number
  error: number
}

export interface UniversalDescriptorsProps {
  universalDescriptors: DescriptorProps[]
}

export interface HandleUploadProps {
  storedDescriptors: DescriptorProps[]
  descriptorsToCreate: DescriptorProps[]
  descriptorsToUpdate: DescriptorProps[]
  createUniversalDescriptor: MutationType
  updateUniversalDescriptor: MutationType
}

export interface InvalidContextProps {
  title: string
  description: string
  violations?: string[]
  footerText: string
}

export interface InvalidAttributesProps {
  attributes: Set<string>
  types: Set<string>
}

const emptyProps: DescriptorProps = {
  allowNestedComponents: false,
  attributes: {},
  description: '',
  id: '',
  label: '',
  type: 'Component',
}

export const emptyData = {
  universalDescriptorById: emptyProps,
}

export enum SETTINGS_MENU {
  VIEW = 'VIEW',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
}

export type SettingContextMenu = {
  desc: DescriptorProps
  mode: SETTINGS_MENU
}
