import PropTypes from 'prop-types'
import React from 'react'

import { StyledItem } from './styles'

export const BreadcrumbItem = ({ children, ...props }) => (
  <StyledItem data-testid='breadcrumb-item' {...props}>
    {children}
  </StyledItem>
)

BreadcrumbItem.defaultProps = {
  children: null,
}

BreadcrumbItem.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BreadcrumbItem
