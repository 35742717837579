import React, { FunctionComponent } from 'react'
import { ContentFilterProps } from './types'
import FilterBy from 'components/FilterBy'
import isEmpty from 'lodash/isEmpty'

export const FILTER_MODULE_ID = 'content-filter-module'

const ContentFilterModule: FunctionComponent<ContentFilterProps> = props => {
  const { entries } = props

  return (
    <div id={FILTER_MODULE_ID} data-testid={FILTER_MODULE_ID}>
      {!isEmpty(entries) &&
        entries.map(entry => (
          <FilterBy
            key={entry.id}
            chipsName={entry.label}
            multiSelectOptions={entry.multiSelectOptions}
            onChange={entry.onChange}
          />
        ))}
    </div>
  )
}

export default React.memo(ContentFilterModule)
