import { BACK_TO_GLOBAL_ELEMENTS, BACK_TO_PAGES } from 'src/constants'
import {
  GE_PATH,
  NEW_GE_PATH,
  NEW_NAV_PAGES_PATH,
  PAGES_PATH,
} from 'contexts/navigationLinksContext'
import { StyledHeader, StyledStatus, StyledTitle } from './styles'
import { noop, size } from 'lodash'
import EditorOptions from './EditorOptions'
import IconBack from 'assets/icons/IconBack'
import { Link } from '@reach/router'
import PropTypes from 'prop-types'
import React from 'react'
import { Tooltip } from '@teamfabric/copilot-ui/dist/atoms/tooltip/Tooltip'
import { theme as themeUi } from '@teamfabric/copilot-ui'
import { useNewNavigation } from 'src/contexts/navigationLinksContext'

const SidebarHeader = ({
  versionStatus,
  versionName,
  selectedRoute,
  onEditorOptionClick,
  isGCEditorPagePath,
}) => {
  // loading completed when selectedRoute has been populated
  const isEditorLoading = !size(selectedRoute)
  const navNameChange = useNewNavigation()

  const getNavLinkPathName = () => {
    if (isGCEditorPagePath) return navNameChange ? NEW_GE_PATH : GE_PATH
    return navNameChange ? NEW_NAV_PAGES_PATH : PAGES_PATH
  }

  return (
    <>
      <StyledHeader>
        <span className='title'>
          <Link
            to={getNavLinkPathName()}
            className='navBack'
            data-testid='sidebar-components-back-button'
          >
            <IconBack
              width={20}
              height={20}
              fill={themeUi.palette.brand.primary.charcoal}
            />
          </Link>
          {isGCEditorPagePath ? BACK_TO_GLOBAL_ELEMENTS : BACK_TO_PAGES}
        </span>
      </StyledHeader>
      <StyledTitle>
        {!isEditorLoading && versionName && (
          <div>
            <span>{versionName}</span>
            <Tooltip className='custom-tooltip' size='small'>
              {versionName}
            </Tooltip>
          </div>
        )}
        {!isEditorLoading && (
          <span
            className='editorOptions'
            data-testid='sidebar-components-editor-options'
          >
            <EditorOptions onEditorOptionClick={onEditorOptionClick} />
          </span>
        )}
      </StyledTitle>
      {versionStatus && (
        <StyledStatus>
          <span className={`circle ${versionStatus.toLowerCase()}`}></span>
          <span data-testid='version-status'>
            {versionStatus.toLowerCase()}
          </span>
        </StyledStatus>
      )}
    </>
  )
}

SidebarHeader.defaultProps = {
  versionStatus: '',
  versionName: '',
  selectedRoute: {},
  onEditorOptionClick: noop,
  isGCEditorPagePath: false,
}

SidebarHeader.propTypes = {
  versionStatus: PropTypes.string,
  versionName: PropTypes.string,
  selectedRoute: PropTypes.object.isRequired,
  onEditorOptionClick: PropTypes.func.isRequired,
  isGCEditorPagePath: PropTypes.bool.isRequired,
}

export default SidebarHeader
