import { ContextOption, FeaturesList } from 'data/types'
import { NavigateFn, useNavigate } from '@reach/router'
import {
  PAGES_MODAL_ID,
  Page,
  PageModalDetails,
  PageType,
  PageVersion,
} from './types'
import {
  getContentVariantEditorContextOptions,
  getIsScheduledOrLive,
  hasLiveOrScheduledVersion,
} from 'data/utils'
import { CONTEXT_MENU_OPTIONS } from 'components/ContentContextMenuModule/constants'
import { useCallback } from 'react'
import { useUserPermissions } from 'contexts/userPermissions'

export const navigateToEditor = (
  {
    pageId,
    versionId,
    preview = false,
    reschedule = false,
  }: {
    pageId: string
    versionId: string
    preview?: boolean
    reschedule?: boolean
  },
  navigate: NavigateFn,
  basepath: string
): void => {
  void navigate(
    `${basepath}/editor?pageId=${pageId}&versionId=${versionId}${
      preview ? '&preview=true' : ''
    }${reschedule ? '&reschedule=true' : ''}`
  )
}

export const usePageCtxOptions = ({
  handlers: { showTableModal },
  isArchivedTab,
}: {
  handlers: { showTableModal: (details: PageModalDetails) => void }
  isArchivedTab: boolean
}): ((item: Page) => ContextOption[]) => {
  const userPermissions = useUserPermissions()

  return useCallback(
    (item: Page) => {
      const targetItem = item.id
      const page = item
      const hasLiveOrScheduledVaraints = hasLiveOrScheduledVersion(
        item.variants
      )

      const showPagesModal = (id: string) => {
        return showTableModal({
          visibleModalId: id,
          targetItem,
          page,
        })
      }

      const unArchivePage = {
        label: CONTEXT_MENU_OPTIONS.UNARCHIVE,
        onClick: () => {
          showPagesModal(PAGES_MODAL_ID.UNARCHIVE_PAGE)
        },
      }

      const activatePage = {
        label: CONTEXT_MENU_OPTIONS.ACTIVATE,
        onClick: () => showPagesModal(PAGES_MODAL_ID.ACTIVATE_PAGE),
      }
      const newVersion = {
        label: CONTEXT_MENU_OPTIONS.NEW_VERSION,
        onClick: () => {
          showPagesModal(PAGES_MODAL_ID.NEW_VERSION)
        },
      }
      const makeACopy = {
        label: CONTEXT_MENU_OPTIONS.MAKE_A_COPY,
        onClick: () => {
          showPagesModal(PAGES_MODAL_ID.MAKE_A_COPY_TYPE)
        },
      }

      const editDetails = {
        label: CONTEXT_MENU_OPTIONS.EDIT_DETAILS,
        onClick: () => {
          showPagesModal(PAGES_MODAL_ID.EDIT_DETAILS)
        },
      }
      const deletePage = {
        label: CONTEXT_MENU_OPTIONS.DELETE,
        onClick: () => {
          showPagesModal(PAGES_MODAL_ID.DELETE_PAGE)
        },
      }

      const archivePage = {
        label: CONTEXT_MENU_OPTIONS.ARCHIVE,
        onClick: () => {
          showPagesModal(PAGES_MODAL_ID.ARCHIVE_PAGE)
        },
      }

      const deactivatePage = {
        label: CONTEXT_MENU_OPTIONS.DEACTIVATE,
        onClick: () => showPagesModal(PAGES_MODAL_ID.DEACTIVATE_PAGE),
      }

      if (!userPermissions.hasEditorPermissions) {
        return isArchivedTab
          ? []
          : [
              {
                label: CONTEXT_MENU_OPTIONS.VIEW_DETAILS,
                onClick: () => {
                  showPagesModal(PAGES_MODAL_ID.VIEW_DETAILS)
                },
              },
            ]
      }

      if (isArchivedTab) {
        return [unArchivePage, deletePage]
      }

      if (
        !userPermissions.hasPublisherPermissions &&
        hasLiveOrScheduledVaraints
      ) {
        return [editDetails, makeACopy, newVersion]
      }

      return [
        {
          ...(item?.isActive ? deactivatePage : activatePage),
        },
        editDetails,
        makeACopy,
        newVersion,
        archivePage,
        deletePage,
      ]
    },
    [userPermissions, isArchivedTab, showTableModal]
  )
}

export const usePageVersionCtxOptions = ({
  handlers: { showTableModal },
  basepath,
}: {
  handlers: { showTableModal: (details: PageModalDetails) => void }
  features: FeaturesList
  basepath: string
}): ((item: Page, version: PageVersion) => ContextOption[]) => {
  const navigate = useNavigate()
  const userPermissions = useUserPermissions()

  return useCallback(
    (page: Page, version: PageVersion) => {
      const pageId = page?.id
      const versionId = version?.id

      const deleteOption = {
        label: CONTEXT_MENU_OPTIONS.DELETE,
        onClick: () => {
          return showPagesModal(PAGES_MODAL_ID.DELETE_VERSION)
        },
      }

      const unarchiveOption = {
        label: CONTEXT_MENU_OPTIONS.UNARCHIVE,
        onClick: () => {
          return showPagesModal(PAGES_MODAL_ID.UNARCHIVE_VERSION)
        },
      }

      const archiveOption = {
        label: CONTEXT_MENU_OPTIONS.ARCHIVE,
        onClick: () => {
          return showPagesModal(PAGES_MODAL_ID.ARCHIVE_VERSION)
        },
      }

      const previewOption = {
        label: CONTEXT_MENU_OPTIONS.PREVIEW,
        onClick: () =>
          navigateToEditor(
            { pageId, versionId, preview: true },
            navigate,
            basepath
          ),
      }

      const editOption = {
        label: CONTEXT_MENU_OPTIONS.EDIT,
        onClick: () =>
          navigateToEditor({ pageId, versionId }, navigate, basepath),
      }
      const makeACopyOption = {
        label: CONTEXT_MENU_OPTIONS.MAKE_A_COPY,
        onClick: () => {
          showPagesModal(PAGES_MODAL_ID.NEW_VERSION)
        },
      }

      const showPagesModal = (id: string) => {
        return showTableModal({
          visibleModalId: id,
          targetItem: { pageId, versionId },
          page,
        })
      }

      if (!userPermissions.hasEditorPermissions) {
        return version.isArchived ? [] : [previewOption]
      }

      if (!userPermissions.hasPublisherPermissions) {
        return getContentVariantEditorContextOptions(version, {
          unarchiveOption,
          deleteOption,
          makeACopyOption,
          previewOption,
          archiveOption,
        })
      }

      return version.isArchived
        ? [unarchiveOption, deleteOption]
        : [
            ...(getIsScheduledOrLive(version?.status) ? [editOption] : []),
            makeACopyOption,
            previewOption,
            archiveOption,
            deleteOption,
          ]
    },
    [userPermissions, showTableModal, navigate]
  )
}

export const usePageTypesCtxOptions = ({
  handlers: { showTableModal },
}: {
  handlers: { showTableModal: (details: PageModalDetails) => void }
}): ((item: PageType) => ContextOption[]) => {
  const userPermissions = useUserPermissions()
  return useCallback(
    (page: Page) => {
      const showPagesModal = (id: string) => {
        return showTableModal({
          visibleModalId: id,
          page,
          targetItem: null,
        })
      }

      if (!userPermissions.hasEditorPermissions) {
        return [
          {
            label: CONTEXT_MENU_OPTIONS.VIEW,
            onClick: () => {
              showPagesModal(PAGES_MODAL_ID.VIEW_PAGE_TYPE)
            },
          },
        ]
      }

      return [
        {
          label: CONTEXT_MENU_OPTIONS.EDIT,
          onClick: () => {
            showPagesModal(PAGES_MODAL_ID.EDIT_PAGE_TYPE)
          },
        },
        {
          label: CONTEXT_MENU_OPTIONS.DELETE,
          onClick: () => {
            showPagesModal(PAGES_MODAL_ID.DELETE_PAGE_TYPE)
          },
        },
      ]
    },
    [userPermissions, showTableModal]
  )
}
