import React, { ReactElement } from 'react'
import { Loading } from '@teamfabric/copilot-ui'
import { SpinnerProps } from './types'
import { StyledSpinnerContainer } from './styles'
import { createPortal } from 'react-dom'
import theme from 'styles/theme'

const spinnerColors = {
  grey: theme.colors.gray[900],
  primary: theme.colors.black[500],
  secondary: theme.colors.blue[900],
  success: theme.colors.green[700],
  error: theme.colors.red[600],
}
const spinnerSizes = {
  small: 13,
  default: 44,
}

const renderSpinner = (props: SpinnerProps) => {
  const { small, ...rest } = props

  return (
    <StyledSpinnerContainer
      data-testid='spinner-wrapper'
      data-variant={props.variant}
      {...rest}
    >
      <Loading
        data-testid='spinner'
        className='global no-padding'
        strokeColor={
          ((props.color && spinnerColors[props.color]) ?? undefined) as
            | string
            | undefined
        }
        size={small ? spinnerSizes.small : spinnerSizes.default}
      />
    </StyledSpinnerContainer>
  )
}

const Spinner = (props: SpinnerProps): ReactElement => {
  return props.variant === 'fullScreen'
    ? createPortal(renderSpinner(props), document.body)
    : renderSpinner(props)
}

export default Spinner
