import { COLORS } from 'styles/constants'
import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledWrapper = styled.div`
  visibility: hidden;
  animation: visibilityDelay 0s 0.1s forwards; // prevents NoComponents from flashing on page load

  background: ${themeUi.palette.brand.primary.white};
  display: flex;
  height: calc(100vh - 400px);
  justify-content: center;
  padding-top: 160px;
  text-align: center;

  @media (max-height: 810px) {
    padding-top: 0;
    align-items: center;
  }

  .content {
    display: flex;
    flex-flow: column nowrap;
    gap: 40px;
    text-align: center;

    p,
    h3 {
      margin: 0;
    }

    p {
      ${themeUi.typography.kicker.css};
      color: ${COLORS.GRAY};
    }

    h3 {
      ${themeUi.typography.subtitle2.css};
      font-family: inherit;
    }
  }

  @keyframes visibilityDelay {
    0% {
      visibility: hidden;
    }
    100% {
      visibility: visible;
    }
  }
`
