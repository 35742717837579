import EmptyScreen from 'components/EmptyScreen'
import React from 'react'
import { SERVER_ERROR_LABEL } from 'src/constants'

const ServerErrorScreen = ({ status }: { status: number }): JSX.Element => {
  return (
    <EmptyScreen
      buttonProps={{
        size: 'small',
        text: SERVER_ERROR_LABEL.buttonText,
        onClick: () => history.back(),
      }}
      className='empty'
      primaryText={`${SERVER_ERROR_LABEL.primaryText} ${status}`}
      secondaryText={SERVER_ERROR_LABEL.secondaryText}
    />
  )
}

export default ServerErrorScreen
