import IconDotsVertical from 'assets/icons/IconDotsVertical'
import styled from 'styled-components'

export const StyledIconDotsVertical = styled(IconDotsVertical)`
  width: 15px;
  height: 15px;
`

export const StyledDropdownList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
`

export const StyledDropdownListItem = styled.li`
  display: grid;
  grid-template-columns: 24px 1fr;
  padding: ${({ theme }) => theme.space.space_3};
  svg {
    fill: ${({ theme }) => theme.colors.gray[500]};
  }
  &:hover {
    background: ${({ theme }) => theme.colors.gray[100]};
  }
`

export const StyledDropdownListItemLabel = styled.div`
  margin-left: ${({ theme }) => theme.space.space_2};
  ${props => props.theme.typography.bodySmall};
  text-transform: none;
`

export const StyledConfirmWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  & > button:first-child {
    margin-right: ${props => props.theme.space.space_5};
  }
`
