import styled from 'styled-components'
import { theme as themeUi } from '@teamfabric/copilot-ui'

export const StyledIframeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .overlay-icon {
    display: none;
  }

  &.enable-preview-mode {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: scroll;
    z-index: ${props => props.theme.zIndex.tooltip};
    background-color: ${themeUi.palette.brand.primary.white};

    iframe {
      width: 100%;
      height: 100%;
      max-width: 100vw;
      max-height: 100vh;
    }

    .overlay-icon {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background: ${themeUi.palette.brand.primary.charcoal};
      clip-path: polygon(0 0, 100% 0, 50% 50%, 0% 100%);
      width: 45px;
      height: 45px;
      cursor: pointer;

      svg {
        transform: rotate(225deg);
        fill: ${themeUi.palette.brand.primary.white};
        width: 18px;
        margin: 3px;
      }
    }
  }
`

export const StyledFrame = styled.iframe`
  ${themeUi.shadows.light.level1}
  border: none;
  outline: none;
  min-width: 320px;
  max-width: ${props => props.width || '360px'};
  height: ${props => props.height || '640px'};
  width: 100%;
  transition: all 0.5s ease;
`
