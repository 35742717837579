import { PATH_PARAMS_HEADER, PROXY_HEADER } from 'src/graphql-proxy/common'
import API from 'src/business-layer-client'
import { PAGE_OPERATION_OPTIONS } from 'services/graphql/page'
import { PAGE_VERSION_OPERATION_OPTIONS } from 'services/graphql/pageVersion'
import { filterName } from 'components/Filters/constants'
import getIsFilterEnabled from 'lib/getIsFilterEnabled'
import handleResponse from 'lib/handleResponse'

const DEFAULT_LIMIT = 20
class Page {
  async list({ offset, limit, filters, isArchived, isActive, channels = '' }) {
    try {
      let response
      const isFiltered = getIsFilterEnabled(filters)
      if (isFiltered) {
        const { pageTypes, channels: _channels, status, locales } = filterName
        response = await API.xpm.post(
          `/page/filter`,
          {
            isArchived,
            isActive,
            offset,
            limit,
            pageTypeIds: filters[pageTypes],
            channels: filters[_channels],
            status: filters[status].map(s => s.toUpperCase()),
            locales: filters[locales],
          },
          {
            headers: {
              [PROXY_HEADER]: PAGE_OPERATION_OPTIONS.FILTER_PAGE,
            },
          }
        )

        return handleResponse.success(response)
      }

      response = await API.xpm.get(
        `page?offset=${offset}&limit=${limit}&isArchived=${isArchived}&channels=${channels}`,
        {
          headers: {
            [PROXY_HEADER]: PAGE_OPERATION_OPTIONS.LIST_PAGES,
          },
        }
      )
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }

  async create(payload) {
    try {
      const response = await API.xpm.post(`/page`, payload, {
        headers: {
          [PROXY_HEADER]: PAGE_OPERATION_OPTIONS.CREATE_PAGE,
        },
      })
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }

  async update({ pageId }, payload) {
    const isArchivedFlag = payload?.isArchived
    let pageOperation = PAGE_OPERATION_OPTIONS.UPDATE_PAGE
    if (isArchivedFlag === true) {
      pageOperation = PAGE_OPERATION_OPTIONS.ARCHIVE_PAGE
    } else if (isArchivedFlag === false) {
      pageOperation = PAGE_OPERATION_OPTIONS.UNARCHIVE_PAGE
    } else if ('isActive' in payload) {
      // TODO: XM-8088: Activate / deactivate is not implemented in GraphQL yet
      pageOperation = null
    }
    try {
      const response = await API.xpm.put(`/page/${pageId}`, payload, {
        headers: {
          [PROXY_HEADER]: pageOperation,
          [PATH_PARAMS_HEADER]: {
            pageId,
          },
        },
      })
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }

  async duplicate({ pageId }, payload) {
    try {
      const response = await API.xpm.post(`/page/${pageId}/copy`, payload, {
        headers: {
          [PROXY_HEADER]: PAGE_OPERATION_OPTIONS.COPY_PAGE,
          [PATH_PARAMS_HEADER]: {
            pageId,
          },
        },
      })
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }

  async delete({ pageId }) {
    try {
      const response = await API.xpm.delete(`/page/${pageId}`)
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }

  async getVersionList({ pageId, isArchived = false }) {
    try {
      const response = await API.xpm.get(
        `/page/${pageId}/versions?isArchived=${isArchived}`
      )
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }

  async updateVersion({ pageId, versionId }, payload) {
    const isArchivedFlag = payload.isArchived
    let pageVersionOperation
    //NOTE: this does not use ternary because isArchivedFlag must be strictly false and not falsy for us to make the unarchive page version call
    if (isArchivedFlag === true) {
      pageVersionOperation = PAGE_VERSION_OPERATION_OPTIONS.ARCHIVE_PAGE_VERSION
    } else if (isArchivedFlag === false) {
      pageVersionOperation =
        PAGE_VERSION_OPERATION_OPTIONS.UNARCHIVE_PAGE_VERSION
    }
    try {
      const response = await API.xpm.put(
        `/page/${pageId}/version/${versionId}`,
        payload,
        {
          headers: {
            [PROXY_HEADER]: pageVersionOperation,
            [PATH_PARAMS_HEADER]: {
              pageId,
              versionId,
            },
          },
        }
      )
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }

  async deleteVersion({ pageId, versionId }) {
    try {
      const response = await API.xpm.delete(
        `/page/${pageId}/version/${versionId}`
      )
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }

  async getVersion({ pageId, versionId }) {
    try {
      const response = await API.xpm.get(`/page/${pageId}/version/${versionId}`)
      return handleResponse.success(response)
    } catch (err) {
      return handleResponse.error(err)
    }
  }

  async createVersion({ pageId, payload }) {
    try {
      const response = await API.xpm.post(`/page/${pageId}`, payload, {
        headers: {
          [PROXY_HEADER]: PAGE_VERSION_OPERATION_OPTIONS.CREATE_PAGE_VERSION,
          [PATH_PARAMS_HEADER]: {
            pageId,
          },
        },
      })
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }

  async filter(payload) {
    const {
      isArchived = false,
      isActive = true,
      offset = 0,
      limit = DEFAULT_LIMIT,
      pageTypeIds,
      channels,
      pageIds,
      versionNames,
      status,
    } = payload

    try {
      const response = await API.xpm.post(`/page/filter`, {
        isArchived,
        isActive,
        offset,
        limit,
        pageTypeIds,
        channels,
        pageIds,
        versionNames,
        status,
      })

      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }

  async search({ isArchived, searchTerm, limit = DEFAULT_LIMIT, offset = 0 }) {
    try {
      const response = await API.xpm.get(
        `page/search?isArchived=${isArchived}&searchTerm=${encodeURI(
          searchTerm
        )}&limit=${limit}&offset=${offset}`,
        {
          headers: {
            [PROXY_HEADER]: PAGE_OPERATION_OPTIONS.LIST_PAGES,
          },
        }
      )
      return handleResponse.success(response)
    } catch (err) {
      handleResponse.error(err)
    }
  }
}

export default new Page()
